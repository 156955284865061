/**
 * GRAPHQL MUTATION
 */

export default {
  deleteTutors: (tutor_id) => `
  mutation {
    delete_tutors(where: {guardian_id: {_eq: ${tutor_id}}}) {
      returning {
        patientByPatientId {
          id
        }
      }
    }
  }
  `,
  linkTutors: () => `
    mutation LinkTutors($guardianId: Int!, $links: [tutors_insert_input!]!) {
      delete_tutors(where: {guardian_id: {_eq: $guardianId}}) {
        returning {
          patientByPatientId {
            id
          }
        }
      }
      insert_tutors(objects: $links){
        returning {
          signatory {
            id
          }
          patientByPatientId {
            id
          }
        }
      }
    }
  `,
  patientsCreate: () => `
    mutation PatientsCreate($objects: [patientPayload!]!) {
      insert_patients(objects: $patientPayload) {
        returning {
          id
          gender
          firstname
          lastname
          use_name
          email
          birthdate
          birthplace
          birthplace_id
          birthplace_postcode
          cityByBirthplaceId {
            id
            name
            postcode
          }
          phone_number
          address
          postcode
          city
          city_id
          cityByCityId {
            id
            name
            postcode
          }
          internal_insi
          insis{
            id
            insi_full
          }
          ipp
          security_number
          medic_id
          user_id
          medical_teams_id
          updated_at
          created_at
          user {
            username
          }
          is_tutor
          tutors {
            guardian_id
            patient_id
          }
          tutorsByPatientId {
            signatory {
              firstname
              lastname
              is_tutor
              id
            }
          }
        }
      }
    }
  `,
  patientsUpdate: () => `
    mutation PatientsUpdate($id: Int!, $patientPayload: patients_set_input) {
      update_patients(where: { id: { _eq: $id } }, _set: $patientPayload) {
        returning {
          id
          gender
          firstname
          lastname
          use_name
          email
          birthdate
          birthplace
          birthplace_id
          birthplace_postcode
          cityByBirthplaceId {
            id
            name
            postcode
          }
          phone_number
          address
          postcode
          city
          city_id
          cityByCityId {
            id
            name
            postcode
          }
          internal_insi
          insis {
            id
            insi_full
          }
          ipp
          security_number
          medic_id
          user_id
          medical_teams_id
          updated_at
          created_at
          tutorsByPatientId {
            signatory {
              id
              firstname
              lastname
              email
              birthdate
              address
              city
              phone_number
              postcode
              security_number
              gender
              use_name
              user {
                username
              }
            }
          }
        }
      }
    }
  `,
  patientsDelete: () => `
    mutation PatientsDelete($_patientsIds: [Int!]!) {
      delete_questions(where: { step: { survey: { patient_id: { _in: $_patientsIds } } } }) {
        returning {
          id
        }
      }
      delete_steps(where: { survey: { patient_id: { _in: $_patientsIds } } }) {
        returning {
          id
        }
      }
      delete_survey_pdfs(where: { survey: { patient_id: { _in: $_patientsIds } } }) {
        returning {
          id
        }
      }
      delete_surveys(where: { patient_id: { _in: $_patientsIds } }) {
        returning {
          id
        }
      }
      delete_patients(where: { id: { _in: $_patientsIds } }) {
        returning {
          id
        }
      }
    }
  `,
  guardiansDelete: () => `
    mutation GuardiansDelete($_guardiansIds: [Int!]!) {
      delete_tutors(where: { guardian_id: { _in: $_guardiansIds } }) {
        returning {
          guardian_id
          patient_id
        }
      }
      delete_questions(where: { step: { survey: { tutored_id: { _in: $_guardiansIds } } } }) {
        returning {
          id
        }
      }
      delete_steps(where: { survey: { tutored_id: { _in: $_guardiansIds } } }) {
        returning {
          id
        }
      }
      delete_survey_pdfs(where: { survey: { tutored_id: { _in: $_guardiansIds } } }) {
        returning {
          id
        }
      }
      delete_surveys(where: { tutored_id: { _in: $_guardiansIds } }) {
        returning {
          id
        }
      }
      delete_patients(where: { id: { _in: $_guardiansIds } }) {
        returning {
          id
        }
      }
    }
  `,
  produceMail: (mail) => `
    mutation {
      produce(
        arg1: {
          topic: "messenger_mail",
          message: "{\\"template\\":\\"${mail.template}\\",\\"value\\": ${JSON.stringify(mail.value).replace(new RegExp('"', 'g'), '\\"')} }"
      }) {
        message
      }
    }
  `,
  updatePassword: () => `
    mutation UdatePatientPassword($_eq: Int!, $password: String!) {
      update_users(where: {id: {_eq: $_eq}}, _set: {password: $password}) {
        returning {
          id
        }
      }
    }
  `,
  // If update_columns is an empty array then on conflict the changes are ignored.
  selectOrCreateInsi: () => `
    mutation InsiCreate($insiPayload: [insis_insert_input!]!) {
      insert_insis(
        objects: $insiPayload
        on_conflict: { constraint: insis_insi_full_unique, update_columns: [insi_full] }
      ) {
        returning {
          id
          created_at
          updated_at
          gender
          firstname
          lastname
          birthdate
          insi_full
          nir
          key
          insi
        }
      }
    }
  `,
};

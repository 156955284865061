import { createReducer } from 'reduxsauce';

import { types } from './actions';

const initialState = {
  list: {},
  loading: false,
  hasMore: true,
};

const startLoader = (state = initialState) => ({ ...state, loading: true });

const stopLoader = (state = initialState) => ({ ...state, loading: false });

const medicalTeamsSuccess = (state = initialState, action) => {
  const { medicalTeams } = action;
  const { list } = state;
  const data = { ...list };
  medicalTeams.medical_teams.forEach((medicalTeam) => {
    data[medicalTeam.id] = medicalTeam;
  });
  return { ...state, list: data, loading: false, hasMore: medicalTeams.length > 0 };
};

const medicalTeamDeleteSuccess = (state = initialState, action) => {
  const { id } = action;
  const { list } = state;
  delete list[id];

  const medicalTeams = Object.values(list);
  const data = {};

  medicalTeams.forEach((medicalTeam) => {
    data[medicalTeam.id] = medicalTeam;
  });
  return { ...state, list: data, loading: false, hasMore: medicalTeams.length > 0 };
};

export default createReducer(initialState, {
  [types.MEDICALTEAMS_CREATE_REQUEST]: startLoader,
  [types.MEDICALTEAMS_CREATE_SUCCESS]: stopLoader,

  [types.MEDICALTEAMS_UPDATE_REQUEST]: startLoader,
  [types.MEDICALTEAMS_UPDATE_SUCCESS]: stopLoader,

  [types.MEDICALTEAMS_REQUEST]: startLoader,
  [types.MEDICALTEAMS_SUCCESS]: medicalTeamsSuccess,
  [types.MEDICALTEAMS_FAILURE]: stopLoader,

  [types.MEDICALTEAMS_DELETE_REQUEST]: startLoader,
  [types.MEDICALTEAMS_DELETE_SUCCESS]: medicalTeamDeleteSuccess,
});

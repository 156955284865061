import { Divider, Tag } from 'antd';
import React from 'react';

function SurveyTypesKeys() {
  return (
    <div>
      <Divider type="horizontal">Mots-clés</Divider>
      <Tag color="default">%sexe_patient%</Tag>
      <Tag color="default">%nom_patient%</Tag>
      <Tag color="default">%prenom_patient%</Tag>
      <Tag color="default">%date_naissance_patient%</Tag>
      <Tag color="default">%date_naissance_patient_MM_AAAA%</Tag>
      {/* // ville_patient is not possible as if patient got a tutor then he got no city */}
      {/* <Tag color="default">%ville_patient%</Tag> */}
      <Tag color="default">%nom_docteur%</Tag>
      <Tag color="default">%prenom_docteur%</Tag>
      <Tag color="default">%titre_docteur%</Tag>
      <Tag color="default">%date_operation%</Tag>
      <Tag color="default">%rayon%</Tag>
      <Tag color="default">%rayon_details%</Tag>
      <Tag color="default">%cote%</Tag>
      <Tag color="default">%nb_questions%</Tag>
      <Tag color="default">%genre_pathos%</Tag>

      <Divider type="horizontal">Tuteur</Divider>
      <Tag color="default">
        <p className="ec-question-basics-keyword">tutor%Texte visible par le tuteur%tutor</p>
      </Tag>

      <Divider type="horizontal">Signataire</Divider>
      <Tag color="default">%sexe_signataire%</Tag>
      <Tag color="default">%nom_signataire%</Tag>
      <Tag color="default">%prenom_signataire%</Tag>
      <Tag color="default">%date_naissance_signataire%</Tag>
      <Tag color="default">%ville_signataire%</Tag>

      <Divider type="horizontal">Délai de réfléxion</Divider>
      <Tag color="default">
        <p className="ec-question-basics-keyword ec-question-basics-tutor">%raison_delai_praticien%</p>
      </Tag>
      <Divider type="horizontal" />

      <Divider type="horizontal">Style du texte</Divider>
      <Tag color="default">
        <p className="ec-question-basics-keyword font-weight-bold">bold%Votre texte%bold</p>
      </Tag>
      <Tag color="default">
        <p className="text-red ec-question-basics-keyword">red%Votre texte%red</p>
      </Tag>

      <Divider type="horizontal" />
    </div>
  );
}

export default SurveyTypesKeys;

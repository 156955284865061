import { connect } from 'react-redux';

import { AuthActions } from '../../../redux/auth';
import SideMenu from './SideMenu';

const mapDispatchToProps = (dispatch) => ({
  setSideMenuRoutes: (sideMenuRoutes) => dispatch(AuthActions.setSideMenuRoutes(sideMenuRoutes)),
});

export default connect(null, mapDispatchToProps)(SideMenu);

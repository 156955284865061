import { createAction } from '../../utils';

// Types
export const types = {
  QUESTIONS_REQUEST: 'QUESTIONS_REQUEST',
  QUESTIONS_FAILURE: 'QUESTIONS_FAILURE',
  QUESTIONS_SUCCESS: 'QUESTIONS_SUCCESS',

  QUESTIONS_UPDATE_REQUEST: 'QUESTIONS_UPDATE_REQUEST',
  QUESTIONS_UPDATE_FAILURE: 'QUESTIONS_UPDATE_FAILURE',
  QUESTIONS_UPDATE_SUCCESS: 'QUESTIONS_UPDATE_SUCCESS',

  QUESTIONS_CREATE_REQUEST: 'QUESTIONS_CREATE_REQUEST',
  QUESTIONS_CREATE_FAILURE: 'QUESTIONS_CREATE_FAILURE',
  QUESTIONS_CREATE_SUCCESS: 'QUESTIONS_CREATE_SUCCESS',

  QUESTIONS_DELETE_REQUEST: 'QUESTIONS_DELETE_REQUEST',
  QUESTIONS_DELETE_SUCCESS: 'QUESTIONS_DELETE_SUCCESS',

  QUESTION_CLEAR: 'QUESTION_CLEAR',
};

// Actions
export default {
  /* CREATE */
  questionsCreateRequest: (filters = {}) => createAction(types.QUESTIONS_CREATE_REQUEST, { filters }),
  questionsCreateSuccess: (questions) => createAction(types.QUESTIONS_CREATE_SUCCESS, { questions }),

  /* UPDATE */
  questionsUpdateRequest: (question = {}) => createAction(types.QUESTIONS_UPDATE_REQUEST, { question }),
  questionsUpdateSuccess: (questions) => createAction(types.QUESTIONS_UPDATE_SUCCESS, { questions }),

  /** REQUEST */
  questionsRequest: (filters = {}) => createAction(types.QUESTIONS_REQUEST, { filters }),
  questionsSuccess: (questions) => createAction(types.QUESTIONS_SUCCESS, { questions }),
  questionsFailure: () => createAction(types.QUESTIONS_FAILURE),

  questionsDeleteRequest: (id) => createAction(types.QUESTIONS_DELETE_REQUEST, { id }),
  questionsDeleteSuccess: (id) => createAction(types.QUESTIONS_DELETE_SUCCESS, { id }),

  questionClear: () => createAction(types.QUESTION_CLEAR),
};

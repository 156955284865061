import './medicalTeams.css';

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { filterAndOrderList } from '../../../utils/utils';
import Filter from '../../components/filter';
import { Tags } from '../../components/tags';
import DataTable from './DataTable';

const MEDICAL_TEAMS_FILTER_KEYS = ['title', 'medical_teams_medics.medic.firstname', 'medical_teams_medics.medic.lastname'];

const MedicalTeams = ({ medicalTeamsRequest, medicsRequest, list, medics, loading, medicalTeamsDeleteRequest }) => {
  const navigate = useNavigate();
  const [filtered, setFiltered] = useState();
  const [reset, setReset] = useState(false);

  useEffect(() => {
    if (medicalTeamsRequest) {
      medicalTeamsRequest({});
    }
    if (medicsRequest) {
      medicsRequest();
    }
  }, []);

  const onFilter = (result) => {
    if (!result) setFiltered(null);
    else {
      setFiltered(result.map((r) => r.item.id));
      setReset(!reset);
    }
  };

  const onModify = (medicalTeam) => {
    navigate(`/medicalTeams/${medicalTeam.id}`, medicalTeam);
  };

  const onDelete = (medicalTeam) => {
    medicalTeamsDeleteRequest(medicalTeam.id);
  };

  const exportDataToXls = () => exportMapper(Array.isArray(filtered) ? list.filter((r) => filtered.includes(r.id)) : list);

  const exportMapper = (toExport) =>
    toExport.map((e) => {
      const owner = medics[e.owner_id];
      const ret = {
        id: e.id,
        Nom: e.title,
        Description: e.description,
        Propriétaire: owner.firstname + ' ' + (owner.lastname ? owner.lastname : ''),
      };
      e.medical_teams_medics.forEach((m, index) => {
        const medic = medics[m.medic_id];
        ret['Praticien ' + (index + 1)] = medic.firstname + ' ' + (medic.lastname ? medic.lastname : '');
      });
      return ret;
    });

  return (
    <>
      <Tags exportFct={exportDataToXls} />
      <Filter list={list} onFilter={onFilter} keys={MEDICAL_TEAMS_FILTER_KEYS} />
      <DataTable
        items={filterAndOrderList(list, filtered)}
        reset={reset}
        loading={loading}
        itemClick={onModify}
        onDelete={onDelete}
        onModify={onModify}
      />
    </>
  );
};

export default MedicalTeams;

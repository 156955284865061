import './surveyTypes.css';

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { filterAndOrderList } from '../../../utils/utils';
import Filter from '../../components/filter';
import { Tags } from '../../components/tags';
import DataTable from './DataTable';

const TYPE_FILTER_KEYS = ['translation.title'];

const SurveyTypes = ({ surveyTypesRequest, list, loading, surveyTypesDeleteRequest, userLanguageId }) => {
  const navigate = useNavigate();
  const [filtered, setFiltered] = useState();
  const [reset, setReset] = useState(false);

  useEffect(() => {
    surveyTypesRequest({});
  }, []);

  const surveyTypes = Object.values(list);

  const onFilter = (result) => {
    if (!result) setFiltered(null);
    else {
      setFiltered(result.map((r) => r.item.id));
      setReset(!reset);
    }
  };

  const onModify = (surveyType) => {
    navigate(`/surveyTypes/${surveyType.id}`, surveyType);
  };

  const onDelete = (surveyType) => {
    surveyTypesDeleteRequest(surveyType.id);
  };

  const exportDataToXls = () => {
    return Array.isArray(filtered) ? surveyTypes.filter((r) => filtered.includes(r.Id)) : surveyTypes;
  };

  return (
    <>
      <Tags exportFct={exportDataToXls} />
      <Filter list={surveyTypes} onFilter={onFilter} keys={TYPE_FILTER_KEYS} />

      <DataTable
        items={filterAndOrderList(surveyTypes, filtered)}
        reset={reset}
        loading={loading}
        itemClick={onModify}
        onDelete={onDelete}
        onModify={onModify}
        userLanguageId={userLanguageId}
      />
    </>
  );
};

export default SurveyTypes;

import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ConsentsForm from './ConsentsForm';

const ConsentsCreate = ({
  surveyTypesRequest,
  consentsRequest,
  medicsRequest,
  medicalTeamsRequest,
  specialitiesRequest,
  listSurveyTypes,
  patientsRequest,
  listPatients,
  listSurveys,
  surveysRequest,
  clinicsRequest,
  listClinics,
  listMedics,
  listMedicTeams,
  userLanguageId,
  consentsList,
}) => {
  let { consentId } = useParams();
  const navigate = useNavigate();
  const id = consentId && !['consents', 'add'].includes(consentId) ? consentId : null;
  const [clinicsWithMedics, setClinicsWithMedics] = useState([]);
  const form = useMemo(() => {
    return (consentsList && consentsList[id]) || {};
  }, [id, consentsList]);

  useEffect(() => {
    surveysRequest();
    clinicsRequest({});
    if (medicsRequest) {
      medicsRequest();
    }
    if (medicalTeamsRequest) {
      medicalTeamsRequest({});
    }
    if (specialitiesRequest) {
      specialitiesRequest({});
    }
    surveyTypesRequest({});
    patientsRequest();
    consentsRequest({});
  }, []);

  useEffect(() => {
    setClinicsWithMedics(listClinics.filter((c) => c.clinic_medics && c.clinic_medics.length));
  }, [listClinics && listClinics.length]);

  const onPrevClick = () => {
    navigate('/consents');
  };

  return (
    <>
      <ConsentsForm
        values={form}
        // eslint-disable-next-line no-console
        onError={(err) => console.error(err)}
        surveyTypeOptions={listSurveyTypes.map((m) => ({
          value: m.id,
          label: m?.translation?.title,
        }))}
        patients={listPatients}
        surveys={listSurveys.map((s) => {
          const translation =
            (Array.isArray(s?.medic_templates_translations) && s.medic_templates_translations.find((st) => st.language_id === userLanguageId)) ||
            s.medic_templates_translations.find((st) => st.language_id === 1) ||
            s.medic_templates_translations[0];
          return {
            ...s,
            translation: translation,
          };
        })}
        clinics={clinicsWithMedics}
        teams={listMedicTeams}
        medics={listMedics}
        edition={!form ? false : true}
        onPrevClick={onPrevClick}
      />
    </>
  );
};

export default ConsentsCreate;

import { authenticatedMutation, authenticatedQuery } from './middleware';
/**
 * GRAPHQL QUERIES
 */
const queries = {
  steps: () => `
    query {
      steps(where: {survey: {id: {_eq: $id}}}) {
        id
        number
        title
        survey_id
      }
    }
  `,
};
/**
 * GRAPHQL MUTATION
 *
 */
const mutations = {
  stepsCreate: () => `
    mutation StepsCreate($number: Int!, $title: String!, $survey_id: Int = null) {
      insert_steps(objects: {number: $number, title: $title, survey_id: $survey_id}) {
        returning {
          number
          id
          created_at
          title
          survey_id
        }
      }
    }
  `,
  stepsUpdate: () => `
    mutation StepsUpdate($number: Int!, $title: String!, $survey_id: Int = null) {
      update_steps(
        where: {id: {_eq: $id}}, 
        _set: {number: $number, title: $title, survey_id: $survey_id}
      ) {
        returning {
          id
          survey_id
          number
          title
          updated_at
        }
      }
    }
  `,
  stepsDelete: () => `
    mutation {
      delete_questions(where: {step: {id: {_eq: $id}}}) {
        returning {
          step {
            id
          }
        }
      }
      delete_steps(where: {id: {_eq: $id}}) {
        returning {
          id
        }
      }
    }
  `,
};
export default {
  steps: (id) => authenticatedQuery(queries.steps(), {}, { id }),
  stepsCreate: (step) =>
    authenticatedMutation(
      mutations.stepsCreate(),
      {},
      {
        number: step.number,
        title: step.title,
        survey_id: step.survey_id,
      },
    ),
  stepsUpdate: (step) =>
    authenticatedMutation(
      mutations.stepsUpdate(),
      {},
      {
        id: step.id,
        number: step.number,
        title: step.title,
        survey_id: step.survey_id,
      },
    ),
  stepsDelete: (id) => authenticatedMutation(mutations.stepsDelete(), {}, { id }),
};

import _ from 'lodash';
import { connect } from 'react-redux';

import { SurveyModelFilesActions } from '../../../../../redux/surveyModelFiles';
import { addDisplayTranslation } from '../../../../../utils/utils';
import ConsentHeader from './ConsentHeader';

const mapStateToProps = ({ auth, surveyTypes, specialities, medicalTeams, medics, surveyModelFiles, language }) => {
  const surveyTypesOptions = Object.values(surveyTypes.list || {})
    .map((el) => addDisplayTranslation(el, auth.languageId))
    .map((el) => ({ value: el.id, label: el?.displayTranslation?.title }));

  const specialitiesOptions = Object.values(specialities.list || {})
    .map((el) => addDisplayTranslation(el, auth.languageId))
    .map((el) => ({ value: el.id, label: el?.displayTranslation?.value }));

  const languageList = language.list;

  return {
    surveyTypesOptions,
    specialitiesOptions,
    medicalTeams: Object.values(medicalTeams.list || {}),
    medics: Object.values(medics.list || {}),
    displayLanguageId: auth.languageId,
    surveyModelFileOptions: Object.values(surveyModelFiles.list || {}).map((el) => ({
      label: `${el.name}, ${_.get(languageList, [el.language_id, 'name'])}`,
      value: el.id,
    })),
  };
};

const mapDispatchToProps = (dispatch) => ({
  surveyModelFilesRequestAll: () => dispatch(SurveyModelFilesActions.request()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConsentHeader);

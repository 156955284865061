import { call, put, takeLatest } from 'redux-saga/effects';

import { UserService } from '../../services';
import { LoaderActions } from '../loader';
import { SnackActions } from '../snackBar';
import { UserActions } from '.';
import { types } from './actions';

function* updatePassword({ id, password }) {
  if (!id || !password) {
    yield put(SnackActions.displayError('user_pswd_updated_error'));
    yield put(UserActions.updatePasswordFailure());
    return;
  }
  const [error, response] = yield call(UserService.update, id, { password, renew_pwd: true });
  if (response && !error) {
    yield put(SnackActions.displayInfo('user_pswd_updated_success'));
    yield put(UserActions.updatePasswordSuccess());
  } else {
    yield put(SnackActions.displayError('user_pswd_updated_error'));
    yield put(UserActions.updatePasswordFailure());
  }
}

function* request() {
  yield put(LoaderActions.loading());
  const [error, data] = yield call(UserService.getAll);
  if (!error && data) {
    yield put(UserActions.requestAllSuccess(data.users));
  } else {
    yield put(SnackActions.displayError('credential_request_error'));
  }
  yield put(LoaderActions.loaded());
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [takeLatest(types.UPDATE_PASSWORD, updatePassword), takeLatest(types.REQUEST_ALL, request)];

import './surveys.css';

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { TEMPLATE_TYPE } from '../../../utils/calimed-enum';
import { filterAndOrderList } from '../../../utils/utils';
import Filter from '../../components/filter';
import { TAGS, Tags } from '../../components/tags';
import DataTable from './DataTable';

const SURVEYS_FILTER_KEYS = ['title'];

const Surveys = ({
  surveyTypes,
  surveyTypesRequest,
  getTemplates,
  getSpecialities,
  getMedicTemplates,
  deleteTemplates,
  updatePublishedStatus,
  resetStatusChanged,
  specialities,
  deleteMedicTemplates,
  loading,
  stepClear,
  questionClear,
  templates,
  medicTemplates,
  statusChanged,
  duplicateTemplates,
  duplicateMedicTemplates,
}) => {
  const navigate = useNavigate();
  const [filtered, setFiltered] = useState();
  const [reset, setReset] = useState(false);
  const [valueList, setValueList] = useState([]);
  const [assigneds, setAssigneds] = useState([]);
  const [activeFilters, setActiveFilters] = useState({
    [TAGS.surveys.OWNERS]: { order: 0 },
    [TAGS.surveys.TYPES]: { order: 0 },
    [TAGS.surveys.SPECIALITIES]: { order: 0 },
  });
  const [tags, setTags] = useState([]);
  const [initialSurveyList, setInitialSurveyList] = useState([]);

  useEffect(() => {
    stepClear();
    questionClear();
    /*surveysRequest({})*/
    surveyTypesRequest({});
    getTemplates();
    getSpecialities();
    getMedicTemplates();
  }, []);

  useEffect(() => {
    const surveys = templates ? templates.concat(medicTemplates ? medicTemplates : []) : [];
    if (surveys && Array.isArray(surveys)) {
      setInitialSurveyList(
        surveys,
        // .map((el) => ({ id: `${el.id}_${el.__typename}`, ...el }))
      );
    } else {
      setInitialSurveyList([]);
    }
  }, [medicTemplates, templates]);

  useEffect(() => {
    if (initialSurveyList && Array.isArray(initialSurveyList)) {
      const tmp = initialSurveyList
        .filter((v) => v && v.medic && v.medic.id)
        .map((v) => v.medic)
        .reduce((acc, val) => {
          if (!acc.find((el) => el.id === val.id)) {
            acc.push(val);
          }
          return acc;
        }, [])
        .sort((a, b) => a.id - b.id);
      if (tmp.map((el) => el.id).join('.') !== assigneds.map((el) => el.id).join('.')) {
        setAssigneds(Object.values(tmp));
        setValueList(initialSurveyList);
      }
    }
  }, [initialSurveyList]);

  useEffect(() => {
    if (statusChanged === true) {
      setValueList(initialSurveyList);
      if (resetStatusChanged) {
        resetStatusChanged();
      }
    }
  }, [statusChanged, initialSurveyList]);

  useEffect(() => {
    const newTags = tags;
    const index = tags.findIndex((t) => t.tagIndex === TAGS.surveys.TYPES);
    const surveyTypeOptions = surveyTypes.map((el) => ({ id: el.id, value: el.value }));
    if (index >= 0) {
      newTags[index] = { ...newTags[index], list: surveyTypeOptions };
    } else {
      newTags.push({
        list: surveyTypeOptions,
        tagIndex: TAGS.surveys.TYPES,
        title: 'Choix du type de formulaire :',
        placeholder: 'Type du formulaire',
      });
    }
    setTags(newTags);
  }, [surveyTypes && surveyTypes.length]);

  useEffect(() => {
    const newTags = tags;
    const index = tags.findIndex((t) => t.tagIndex === TAGS.surveys.SPECIALITIES);
    const specialitieOptions = specialities.map((el) => ({ id: el.id, value: el.value }));
    if (index >= 0) {
      newTags[index] = { ...newTags[index], list: specialitieOptions };
    } else {
      newTags.push({
        list: specialitieOptions,
        tagIndex: TAGS.surveys.SPECIALITIES,
        title: 'Choix de la spécialité de formulaire :',
        placeholder: 'Spécialité du formulaire',
      });
    }
    setTags(newTags);
  }, [specialities && specialities.length]);

  useEffect(() => {
    const newTags = tags;
    const index = tags.findIndex((t) => t.tagIndex === TAGS.surveys.OWNERS);
    const formatedMedics = [{ id: -1, firstname: 'Administrateur', lastname: '', value: 'Administrateur' }].concat(
      assigneds.map((m) => ({ ...m, value: m.firstname + ' ' + m.lastname })),
    );
    const formatedMedicOptions = formatedMedics.map((el) => ({ id: el.id, value: el.value }));
    if (index >= 0) {
      newTags[index] = { ...newTags[index], list: formatedMedicOptions };
    } else {
      newTags.push({
        list: formatedMedicOptions,
        tagIndex: TAGS.surveys.OWNERS,
        title: 'Choix du propriétaire de formulaire :',
        placeholder: 'Propriétaire du formulaire',
      });
    }
    setTags(newTags);
  }, [assigneds && assigneds.length]);

  const onFilter = (result) => {
    if (!result) {
      handleTags([-1], 'none');
    } else {
      const newFiltered =
        Array.isArray(filtered) && filtered.length ? result.map((r) => r.item.id).filter((r) => filtered.includes(r)) : result.map((r) => r.item.id);
      setFiltered(newFiltered.length ? newFiltered : [-1]);
      setReset(!reset);
    }
  };

  const onModify = (survey) => {
    stepClear();
    questionClear();
    navigate(`/surveys/${survey.id}-${survey.store_id === undefined || survey.storeID > 0 ? TEMPLATE_TYPE.STORE : TEMPLATE_TYPE.MEDIC}`, survey);
  };

  const handleDuplicate = async (survey) => {
    const [storeIDs, medicIDs] = separateMedicAndStoreTemplates(survey);
    if (storeIDs.length && deleteTemplates) {
      duplicateTemplates([survey.id]);
    }
    if (medicIDs.length && deleteMedicTemplates) {
      duplicateMedicTemplates([survey.id]);
    }
  };

  const separateMedicAndStoreTemplates = (templates) => {
    if (Array.isArray(templates)) {
      const surveys = templates.map((text) => {
        const [__typename, id] = text.split('#');
        return valueList.find((s) => s && s.__typename === __typename && parseInt(s.id, 10) === parseInt(id, 10));
      });
      return [
        surveys.filter((s) => s && s.__typename === 'templates').map((s) => s.id),
        surveys.filter((s) => s && s.__typename === 'medic_templates').map((s) => s.id),
      ];
    } else {
      if (templates.__typename === 'templates') {
        return [[templates.id], []];
      }
      if (templates.__typename === 'medic_templates') {
        return [[], [templates.id]];
      }
    }
  };

  const onDelete = (list) => {
    // list is array with key name if multiple selected or object if line selected
    let [storeIDs, medicIDs] = separateMedicAndStoreTemplates(list);
    if (storeIDs.length && deleteTemplates) {
      deleteTemplates(storeIDs);
    }
    if (medicIDs.length && deleteMedicTemplates) {
      deleteMedicTemplates(medicIDs);
    }
    /*surveysDeleteRequest(survey)*/
  };

  const onPublished = (IDs, published) => {
    let [storeIDs, medicIDs] = separateMedicAndStoreTemplates(IDs);
    if (storeIDs.length && updatePublishedStatus) {
      updatePublishedStatus(storeIDs, published);
    }
  };

  const handleTags = (value, mode) => {
    const surveyTags = TAGS['surveys'];
    // const { types, specialities, owners } = activeFilters;
    const types = activeFilters[surveyTags.TYPES];
    const specialities = activeFilters[surveyTags.SPECIALITIES];
    const owners = activeFilters[surveyTags.OWNERS];
    let newActivesFilters = { ...activeFilters };
    if (!value || !value.length) {
      setReset(true);
      newActivesFilters[mode] = { order: 0 };
    } else {
      switch (mode) {
        case surveyTags.TYPES:
          newActivesFilters[mode].order = Math.max(specialities.order, owners.order) + 1;
          newActivesFilters[mode].filter = (values) => values.filter((s) => s && s.survey_type && value.includes(s.survey_type.id));
          break;
        case surveyTags.SPECIALITIES:
          newActivesFilters[mode].order = Math.max(types.order, owners.order) + 1;
          newActivesFilters[mode].filter = (values) => values.filter((s) => value.includes(s.speciality_id));
          break;
        case surveyTags.OWNERS: {
          newActivesFilters[mode].order = Math.max(types.order, specialities.order) + 1;
          newActivesFilters[mode].filter = (templates) =>
            templates.filter((t) => {
              if (value.includes(-1) && t.__typename === 'templates') {
                return true;
              }
              if (value.map((v) => `${v}`).includes(`${t.medic_id}`) && t.__typename === 'medic_templates') {
                return true;
              }
              return false;
            });
          break;
        }
        default:
          break;
      }
    }
    let newFiltered = [...initialSurveyList];
    // apply filters for suveey list
    Object.values(newActivesFilters)
      .filter((f) => f.order > 0)
      .sort((a, b) => a.order - b.order)
      .forEach((f) => {
        newFiltered = f.filter && f.filter(newFiltered);
      });

    // setFiltered(Array.isArray(newFiltered) && newFiltered.length ? newFiltered.map((f) => f.id) : [-1]);
    setActiveFilters(newActivesFilters);
  };

  const filterFromFilters = (templates) => {
    let newFiltered = [...initialSurveyList];
    Object.values(activeFilters)
      .filter((f) => f.order > 0)
      .sort((a, b) => a.order - b.order)
      .forEach((f) => {
        newFiltered = f.filter && f.filter(newFiltered);
      });
    return newFiltered;
  };

  const exportMapper = (toExport) =>
    toExport.map((e) => ({
      id: e.id,
      Titre: e.title,
      Type: e.survey_type.value,
      Spécialité: e.speciality && e.speciality.value,
      'Crée par': e.store_id > 0 || !e.medic ? 'Store' : 'Praticien',
      'Attribué à': e.medic ? e.medic.lastname + ' ' + e.medic.firstname : null,
    }));

  const exportDataToXls = () =>
    exportMapper(Array.isArray(filtered) && filtered.length ? valueList.filter((r) => filtered.includes(r.id)) : valueList);

  return (
    <>
      <Tags data={tags} tagIndex={'surveys'} onTags={handleTags} exportFct={exportDataToXls} />
      <Filter
        list={valueList.map((l) => ({
          ...l,
          firstname: l.patient && l.patient.firstname ? l.patient.firstname : '',
          lastname: l.patient && l.patient.lastname ? l.patient.lastname : '',
        }))}
        onFilter={onFilter}
        keys={SURVEYS_FILTER_KEYS}
      />
      <DataTable
        items={filterAndOrderList(filterFromFilters(valueList), filtered)}
        reset={reset}
        loading={loading}
        itemClick={onModify}
        onDelete={onDelete}
        onModify={onModify}
        onDuplicate={handleDuplicate}
        onPublished={onPublished}
        resetRowKeys={statusChanged}
      />
    </>
  );
};

export default Surveys;

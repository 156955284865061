import PropTypes from 'prop-types';
import React from 'react';

import { Button } from '../styledComponent';

const CustomPDFButton = ({ disabled, onClick, label, isPrevious = false }) => {
  return (
    <Button className="ec-pdf-viewer-navigation-text" onClick={onClick} disabled={disabled} type="button" variant="link">
      <span className="d-none d-sm-block">{label}</span>
      <span className="d-block d-sm-none">{isPrevious ? '<' : '>'}</span>
    </Button>
  );
};

CustomPDFButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

export default CustomPDFButton;

import './questionTypes.css';

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { filterAndOrderList } from '../../../utils/utils';
import Filter from '../../components/filter';
import { Tags } from '../../components/tags';
import DataTable from './DataTable';

const FILTER_KEYS = ['value'];

const QuestionTypes = ({ questionTypesRequest, list, loading, questionTypesDeleteRequest }) => {
  const navigate = useNavigate();
  const [filtered, setFiltered] = useState();
  const [reset, setReset] = useState(false);

  useEffect(() => {
    questionTypesRequest({});
  }, []);

  const questionTypes = Object.values(list);

  const onFilter = (result) => {
    if (!result) setFiltered(null);
    else {
      setFiltered(result.map((r) => r.item.id));
      setReset(!reset);
    }
  };

  const onModify = (questionType) => {
    navigate(`/questionTypes/${questionType.id}`, questionType);
  };

  const onDelete = (questionType) => {
    questionTypesDeleteRequest(questionType.id);
  };

  const exportDataToXls = () => {
    return Array.isArray(filtered) ? questionTypes.filter((r) => filtered.includes(r.Id)) : questionTypes;
  };

  return (
    <>
      <Tags exportFct={exportDataToXls} />
      <Filter list={questionTypes} onFilter={onFilter} keys={FILTER_KEYS} />

      <DataTable
        items={filterAndOrderList(questionTypes, filtered)}
        reset={reset}
        loading={loading}
        itemClick={onModify}
        onDelete={onDelete}
        onModify={onModify}
      />
    </>
  );
};

export default QuestionTypes;

import './style.css';

import _ from 'lodash';
import React, { useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

const Keys = {
  TAB: 9,
  SPACE: 32,
  COMMA: 188,
  BACKSPACE: 8,
};

const delimiters = [Keys.TAB, Keys.COMMA];

// component InputTags depending on react-tagsinput
const InputTags = ({
  name,
  rules = { required: false },
  defaultValue,
  disabled,
  className,
  placeholder,
  tagClassName,
  inputClassName,
  suggestions,
  ...extraInputProps
}) => {
  const [input, setInput] = useState('');
  const [isKeyReleased, setIsKeyReleased] = useState(false);
  const { control, register } = useFormContext(); // retrieve all hook methods
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: name, // unique name for your Field Array
    // keyName: "id", default to "id", you can change the key name
  });

  const onChange = ({ target: { value } }) => {
    setInput(value);
  };

  const onBlur = (event) => {
    const {
      target: { value },
    } = event;
    const trimmedInput = value.trim();
    if (trimmedInput.length && !fields.find((t) => t.value === trimmedInput)) {
      append({ value: trimmedInput });
    }
    setInput('');
  };

  const deleteTag = (index) => {
    remove(index);
  };

  const onKeyDown = (event) => {
    const { keyCode } = event;
    const trimmedInput = input.trim();

    if (delimiters.includes(keyCode) && trimmedInput.length) {
      // if delimiters is typed then add tag to array
      event.preventDefault();
      if (!fields.find((t) => t.value === trimmedInput)) {
        append({ value: trimmedInput });
        setInput('');
      }
    }

    if (keyCode === Keys.BACKSPACE && !input.length && fields.length && isKeyReleased) {
      // if BACKSPACE remove tag if no input
      const tagsCopy = [...fields];
      const poppedTag = tagsCopy.pop();
      event.preventDefault();
      // remove last tag if no input and insert last tag value as input
      remove(fields.length - 1);
      setInput(_.get(poppedTag, 'value'));
    }

    setIsKeyReleased(false);
  };

  const onKeyUp = () => {
    setIsKeyReleased(true);
  };

  return (
    <div className={className || 'container'}>
      {fields.map((tag, index) => {
        return (
          <div key={tag.id} className={tagClassName || 'ec-consentheader-description-tag'}>
            {/* <input
              key={tag.id} // important to include key with field's id
              {...register(`${name}.${index}.value`)}
              defaultValue={tag.value} // make sure to include defaultValue
              disabled
              className="d-none"
            /> */}
            {tag.value}
            <button onClick={() => deleteTag(index)} className="ec-consentheader-description-tag-remove" />
          </div>
        );
      })}
      <input
        value={input}
        placeholder={placeholder}
        onKeyDown={onKeyDown}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        onChange={onChange}
        onBlur={onBlur}
        style={{ color: fields.find((t) => t.value === input) ? 'red' : input.length ? 'green' : 'inherit' }}
        className={inputClassName || 'ec-consentheader-description-tag-input'}
        {...extraInputProps}
      />
    </div>
  );
};

export default InputTags;

function array_move(arr, old_index, new_index) {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr; // for testing
}

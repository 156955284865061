import { connect } from 'react-redux';

import { MedicalTeamActions } from '../../../redux/medicalTeams';
import { MedicActions } from '../../../redux/medics';
import MedicalTeams from './MedicalTeams';

const mapStateToProps = ({ medicalTeams, medics }) => ({
  list: Object.values(medicalTeams.list || {}),
  medics: medics.list || {},
  loading: medicalTeams.loading,
});

const mapDispatchToProps = (dispatch) => ({
  medicalTeamsRequest: (filter) => dispatch(MedicalTeamActions.medicalTeamsRequest(filter)),
  medicalTeamsDeleteRequest: (medicalTeamId) => dispatch(MedicalTeamActions.medicalTeamsDeleteRequest(medicalTeamId)),
  medicsRequest: (filter) => dispatch(MedicActions.medicsRequest(filter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MedicalTeams);

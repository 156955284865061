import _ from 'lodash';

import { authenticatedMutation, authenticatedQuery } from './middleware';

/**
 * GRAPHQL QUERIES
 */
const queries = {
  getAll: () => `
  query GetSms {
    sms_templates {
      id
      type
      sms_templates_translations {
        id
        sms_template_id
        message
        language_id
        language {
          language_code
          country_code_flag
          name
        }
      }
    }
  }
  `,
  getById: () => `
    query Sms($id: Int) {
      sms_templates(where: { id: { _eq: $id } }) {
        id
        type
        sms_templates_translations {
            id
            sms_template_id
            message
            language_id
            language {
              language_code
              country_code_flag
              name
            }
        }
      }
    }
  `,
};
/**
 * GRAPHQL MUTATION
 *
 */

const mutations = {
  smsUpdate: () => `
    mutation UpdateSms($id: Int!, $smsTemplatesTranslations: [sms_templates_translations_insert_input!]!, $smsTemplates: sms_templates_set_input!) {
      update_sms_templates(where: {id: {_eq: $id}}, _set: $smsTemplates) {
        returning {
          id
        }
      }
      insert_sms_templates_translations(objects: $smsTemplatesTranslations, on_conflict: {constraint: sms_templates_translations_pkey, update_columns: [created_at, updated_at, message, language_id, sms_template_id]}) {
        returning {
          id
          __typename
        }
        __typename
      }
    }
  `,
  smsDelete: () => `
    mutation DeleteSms($id: Int) {
      delete_sms_templates_translations(where: {id: {_eq: $id}}) {
        returning {
          id
        }
      }
      delete_sms_templates(where: {id: {_in: $ids}}) {
        returning {
          id
        }
      }
    }
  `,
};

export default {
  getAll: () => authenticatedQuery(queries.getAll(), {}, {}),
  getById: (id = null) => authenticatedQuery(queries.getById(), {}, { id }),
  smsUpdate: (sms) =>
    authenticatedMutation(
      mutations.smsUpdate(),
      {},
      { id: sms.id, smsTemplatesTranslations: sms.sms_templates_translations, smsTemplates: _.omit(sms, ['id', 'sms_templates_translations']) },
    ),
};

import { connect } from 'react-redux';

import { CityActions } from '../../../redux/city';
import { ClinicActions } from '../../../redux/clinics';
import { MedicActions } from '../../../redux/medics';
import ClinicsCreate from './ClinicsCreate';

const mapStateToProps = ({ clinics, medics, city }) => {
  return {
    medicList: Object.values(medics?.list || {}),
    clinicsList: clinics.list,
    cityList: Object.values(city?.cities || {}),
  };
};

const mapDispatchToProps = (dispatch) => ({
  clinicsCreateRequest: (creation) => dispatch(ClinicActions.clinicsCreateRequest(creation)),
  medicsRequest: (filter) => dispatch(MedicActions.medicsRequest(filter)),
  clinicsUpdateRequest: (clinic) => dispatch(ClinicActions.clinicsUpdateRequest(clinic)),
  clinicsRequest: (filter) => dispatch(ClinicActions.clinicsRequest(filter)),
  fetchCityList: (q, cityType = 'city') => dispatch(CityActions.fetchCityList(q, cityType)),
  clearCityList: () => dispatch(CityActions.clearCityList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClinicsCreate);

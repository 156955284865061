import './patients.css';

import { Collapse, Radio } from 'antd';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { parseDate } from '../../../services/datefns';
import { filterAndOrderList } from '../../../utils/utils';
import Filter from '../../components/filter';
import { Tags } from '../../components/tags';
import DataTable from './DataTable';

const PATIENT_FILTER_KEYS = ['full_name', 'firstname', 'lastname', 'use_name', 'phone_number', 'email', 'local_phone_number'];

const Patients = ({ patientsRequest, list, loading, patientsDeleteMultipleRequest }) => {
  const navigate = useNavigate();
  const [valueList, setValueList] = useState();
  const [filtered, setFiltered] = useState();
  const [reset, setReset] = useState(false);

  useEffect(() => {
    patientsRequest();
  }, []);

  useEffect(() => {
    setList([...list]);
  }, [list]);

  const onFilter = (result) => {
    if (!result) setFiltered(null);
    else {
      setFiltered(result.map((r) => r.item.id));
      setReset(!reset);
    }
  };

  const onModify = (patient) => {
    navigate(`/patients/${patient.id}`, patient);
  };

  const onDelete = (ids) => {
    patientsDeleteMultipleRequest(Array.isArray(ids) ? ids : [ids]);
  };

  const setList = (list) => setValueList((list || []).map((el) => ({ ...el, full_name: [el.firstname, el.lastname, el.use_name].join(' ') })));

  const onAll = () => {
    setList(list || []);
  };

  const onTutor = () => {
    setList((list || []).filter((p) => p.is_tutor));
  };

  const onNotTutor = () => {
    setList((list || []).filter((p) => !p.is_tutor));
  };

  const { Panel } = Collapse;

  const exportMapper = (toExport) =>
    toExport.map((e) => {
      const hasTutors = e.tutorsByPatientId.length;
      return {
        id: e.id,
        Prénom: e.firstname,
        'Nom de famille': e.lastname,
        "Nom d'usage": e.use_name,
        'Numéro de sécurité sociale': e.security_number,
        'Date de naissance': e.birthdate ? format(parseDate(e.birthdate), 'dd/MM/yyyy') : '',
        Ville: e.city,
        'Code postale': e.postcode,
        Téléphone: hasTutors ? 'xx' : e.phone_number,
        Tuteur: e.is_tutor ? 'Oui' : 'Non',
        'Tuteur 1':
          hasTutors && e.tutorsByPatientId[0] ? e.tutorsByPatientId[0].signatory.firstname + ' ' + e.tutorsByPatientId[0].signatory.lastname : '',
        'Tuteur 2':
          hasTutors && e.tutorsByPatientId[1] ? e.tutorsByPatientId[1].signatory.firstname + ' ' + e.tutorsByPatientId[1].signatory.lastname : '',
      };
    });

  const exportDataToXls = () => exportMapper(Array.isArray(filtered) ? valueList.filter((r) => filtered.includes(r.id)) : valueList);

  return (
    <>
      <Tags exportFct={exportDataToXls} />
      <Collapse style={{ marginBottom: 9 }}>
        <Panel header="Filtres" key="1">
          <Radio.Group defaultValue="all" buttonStyle="solid">
            <Radio.Button value="all" onClick={() => onAll()}>
              Tous
            </Radio.Button>
            <Radio.Button value="tutor" onClick={() => onTutor()}>
              Tuteur
            </Radio.Button>
            <Radio.Button value="notTutor" onClick={() => onNotTutor()}>
              Non tuteur
            </Radio.Button>
          </Radio.Group>
        </Panel>
      </Collapse>

      <Filter list={valueList} onFilter={onFilter} keys={PATIENT_FILTER_KEYS} useExtendedSearch />

      <DataTable
        items={filterAndOrderList(valueList, filtered) || []}
        reset={reset}
        loading={loading}
        itemClick={onModify}
        onDelete={onDelete}
        onModify={onModify}
      />
    </>
  );
};

export default Patients;

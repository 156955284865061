import { Button, Card, Divider, Form, Input, Select } from 'antd';
import React, { useEffect } from 'react';

import { normalizeString } from '../../../utils';
import { regexHttpUrl } from '../../../utils/regex';

const CredentialForm = ({ onValidate, initialValues, appKeyOptions = [], userOptions = [] }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (initialValues) {
      form.resetFields();
    }
  }, [initialValues]);

  const onFinish = (values) => {
    onValidate && onValidate(values);
  };

  return (
    <>
      <Card style={{ margin: 25, width: '80%' }}>
        <Form onFinish={onFinish} initialValues={initialValues} layout="vertical" form={form} name="basic" autoComplete="off">
          <Divider type="horizontal">Info</Divider>
          <Form.Item name="id" label="id">
            <Input disabled />
          </Form.Item>
          <Form.Item name="description" label="Description" rules={[{ required: true, message: 'Description Obligatoire', whitespace: true }]}>
            <Input placeholder="Description" maxLength={50} />
          </Form.Item>
          <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Name Obligatoire', whitespace: true }]}>
            <Input placeholder="Name" maxLength={50} />
          </Form.Item>
          <Form.Item name="type" label="Type" rules={[{ required: true, message: 'Type Obligatoire', whitespace: true }]}>
            <Select
              style={{ width: '100%' }}
              showSearch
              filterOption={(input, option) => {
                if (option.label) {
                  return normalizeString(option.label).includes(normalizeString(input));
                } else {
                  return false;
                }
              }}
              allowClear
              options={['login'].map((t) => ({
                value: t,
                label: t,
              }))}
            />
          </Form.Item>

          <Form.Item
            name="auth_url"
            label="Authentication url"
            rules={[
              { required: true, message: 'Authentication url Obligatoire', whitespace: true },
              {
                pattern: regexHttpUrl,
                message: 'Please enter a valid http(s) url',
              },
            ]}
          >
            <Input placeholder="Authentication url" maxLength={50} />
          </Form.Item>
          <Form.Item
            name="api_url"
            label="API url"
            rules={[
              { required: true, message: 'API url Obligatoire', whitespace: true },
              {
                pattern: regexHttpUrl,
                message: 'Please enter a valid http(s) url',
              },
            ]}
          >
            <Input placeholder="API url" maxLength={50} />
          </Form.Item>
          <Form.Item
            name="details"
            label="details de connection : body"
            rules={[{ required: true, message: 'details Obligatoire', whitespace: true }]}
          >
            <Input placeholder="details de connection : body" maxLength={50} autoComplete="off" />
          </Form.Item>

          <Form.Item name="user_id" label="User">
            <Select
              style={{ width: '100%' }}
              showSearch
              filterOption={(input, option) => {
                if (option.label) {
                  return normalizeString(option.label).includes(normalizeString(input));
                } else {
                  return false;
                }
              }}
              allowClear
              options={userOptions}
            />
          </Form.Item>

          <Form.Item name="app_key_id" label="App Key">
            <Select
              style={{ width: '100%' }}
              showSearch
              filterOption={(input, option) => {
                if (option.label) {
                  return normalizeString(option.label).includes(normalizeString(input));
                } else {
                  return false;
                }
              }}
              allowClear
              options={appKeyOptions}
            />
          </Form.Item>
          <Divider type="horizontal" />
          <Form.Item style={{ textAlign: 'center' }}>
            <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
              Enregistrer
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};

export default CredentialForm;

import { call, put, takeLatest } from 'redux-saga/effects';

import { SurveysService } from '../../services';
import { AuthActions } from '../auth';
import { LoaderActions } from '../loader';
import { SnackActions } from '../snackBar';
import { default as SurveyActions, types } from './actions';

function* surveyCurrentRequest({ id }) {
  const [error, response] = yield call(SurveysService.currentSurvey, id);
  if (error) {
    yield put(SurveyActions.surveyCurrentFailure());
    yield put(SnackActions.displayError('survey_current_error'));
    return;
  }
  const surveys = response && response.surveys;
  if (surveys && surveys[0]) {
    const survey = surveys[0];
    survey.locked = true;
    if (!survey.medic_surveys || !survey.medic_surveys.length) {
      const [medicSurveyError, medicSurveyResponse] = yield call(SurveysService.currentMedicSurvey, id);
      if (medicSurveyError) {
        yield put(SurveyActions.surveyCurrentFailure());
        yield put(SnackActions.displayError('survey_current_error'));
        return;
      }
      survey.medic_surveys = medicSurveyResponse.medic_surveys;
      survey.locked = false;
    }

    yield put(SurveyActions.surveyCurrentSuccess(survey));
    return;
  }
  yield put(SurveyActions.surveyCurrentFailure());
  yield put(SnackActions.displayError('survey_current_error'));
}

function* surveysRequest({ filters }) {
  const id = (filters && filters.id) || null;
  const [error, response] = id ? yield call(SurveysService.survey, id) : yield call(SurveysService.surveys);
  if (error) {
    yield put(SnackActions.displayError('survey_list_error'));
    yield put(SurveyActions.surveysFailure());
    return;
  }
  const { surveys } = response || {};
  if (surveys) yield put(SurveyActions.surveysSuccess(surveys));
  else {
    yield put(SnackActions.displayError('survey_list_error'));
    yield put(SurveyActions.surveysFailure());
  }
}

function* surveysUpdateRequest({ survey }) {
  yield put(LoaderActions.loading());
  const result = yield call(SurveysService.surveysUpdate, survey);
  if (result) {
    yield put(LoaderActions.loaded());
    yield put(SnackActions.displayInfo('survey_modify_success'));
  } else {
    yield put(LoaderActions.loaded());
    yield put(SnackActions.displayError('survey_modify_error'));
  }
  yield put(AuthActions.setRedirectUrl('/surveys'));
  yield put(SurveyActions.surveysUpdateSuccess({}));
  yield put(SurveyActions.surveysRequest({}));
  yield put(LoaderActions.loaded());
}

function* surveysCreateRequest({ filters }) {
  yield put(LoaderActions.loading());
  const result = yield call(SurveysService.surveysCreate, filters);
  if (result) {
    yield put(LoaderActions.loaded());
    yield put(SnackActions.displayInfo('survey_create_success'));
    yield put(AuthActions.setRedirectUrl('/surveys'));
  } else {
    yield put(LoaderActions.loaded());
    yield put(SnackActions.displayError('survey_create_error'));
  }
  yield put(AuthActions.setRedirectUrl('/surveys'));
  yield put(SurveyActions.surveysCreateSuccess({}));
  yield put(SurveyActions.surveysRequest({ id: filters.surveys }));
  yield put(LoaderActions.loaded());
}

function* surveysDeleteRequest({ survey }) {
  yield put(LoaderActions.loading());
  const result = Array.isArray(survey)
    ? yield survey.map((i) => call(SurveysService.surveysDelete, i))
    : yield call(SurveysService.surveysDelete, survey.id);

  if (result) {
    yield put(LoaderActions.loaded());
    yield put(SnackActions.displayInfo('survey_delete_success'));
  } else {
    yield put(LoaderActions.loaded());
    yield put(SnackActions.displayError('survey_delete_error'));
  }
  yield put(SurveyActions.surveysDeleteSuccess(survey));
  yield put(SurveyActions.surveysRequest({}));
  yield put(LoaderActions.loaded());
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  takeLatest(types.SURVEYS_REQUEST, surveysRequest),
  takeLatest(types.SURVEYS_UPDATE_REQUEST, surveysUpdateRequest),
  takeLatest(types.SURVEYS_CREATE_REQUEST, surveysCreateRequest),
  takeLatest(types.SURVEYS_DELETE_REQUEST, surveysDeleteRequest),
  takeLatest(types.SURVEY_CURRENT_REQUEST, surveyCurrentRequest),
];

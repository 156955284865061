import '../style.scss';

import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';

function InputCheckbox({ name, label, rules = { required: false }, options, defaultValue, isVertical, className, ...rest }) {
  const { control } = useFormContext(); // retrieve all hook methods
  const {
    field: { value, ...inputProps }, // field : { onChange, onBlur, value, name: fieldName, ref, ...inputProps },
    fieldState: { error }, // fieldState: { invalid, isTouched, isDirty, error },
    // formState: { errors, isDirty, isSubmitting, touched, submitCount },
  } = useController({
    name,
    control,
    rules: { ...rules },
    defaultValue: defaultValue || _.get(control, ['defaultValuesRef', 'current', name]) || '',
  });

  return (
    <div className={'row'}>
      <label htmlFor={`${name}`} className={`${className || ''} col${isVertical ? '-12' : ''}`}>
        <input
          type="checkbox"
          aria-invalid={error ? 'true' : 'false'}
          id={`${name}`}
          {...rest}
          {...inputProps}
          checked={value}
          key={`inputCheckbox_${name}`}
        />
        <span>{label}</span>
      </label>
    </div>
  );
}

InputCheckbox.propTypes = {
  register: PropTypes.func,
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
};

export default InputCheckbox;

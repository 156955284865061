import _ from 'lodash';
import { createReducer } from 'reduxsauce';

import { types } from './actions';

const initialState = {
  list: {},
  questionBasicsTypesSurveys: [],
  questionBasicsText: [],
  loading: false,
  hasMore: true,
};

const startLoader = (state = initialState) => ({ ...state, loading: true });

const stopLoader = (state = initialState) => ({ ...state, loading: false });

const questionBasicsTypesSuccess = (state = initialState, action) => {
  const { questionBasics } = action;
  const { list } = state;
  const addToList = !_.isEmpty(questionBasics) && questionBasics.reduce((accumulator, q) => ({ ...accumulator, [q.id]: q }), {});
  return {
    ...state,
    list: { ...list, ...addToList },
    loading: false,
    hasMore: questionBasics.length > 0,
  };
};

const questionBasicsSuccess = (state = initialState, action) => {
  const { questionBasics } = action;
  const { questionBasicsText } = state;
  const addToQuestionBasicsText = !_.isEmpty(questionBasics) && questionBasics.reduce((accumulator, q) => ({ ...accumulator, [q.id]: q }), {});
  return {
    ...state,
    questionBasicsText: { ...questionBasicsText, ...addToQuestionBasicsText },
    loading: false,
    hasMore: questionBasics.length > 0,
  };
};

const questionBasicsTypesSurveysSuccess = (state = initialState, action) => {
  const { questionBasics } = action;
  return {
    ...state,
    questionBasicsTypesSurveys: questionBasics,
    loading: false,
    hasMore: questionBasics.length > 0,
  };
};

export default createReducer(initialState, {
  [types.QUESTION_BASICS_TYPES_SURVEY_UPDATE_REQUEST]: startLoader,
  [types.QUESTION_BASICS_TYPES_SURVEY_UPDATE_SUCCESS]: stopLoader,

  [types.QUESTION_BASICS_TYPES_REQUEST]: startLoader,
  [types.QUESTION_BASICS_TYPES_SUCCESS]: questionBasicsTypesSuccess,
  [types.QUESTION_BASICS_TYPES_FAILURE]: stopLoader,

  [types.QUESTION_BASICS_REQUEST]: startLoader,
  [types.QUESTION_BASICS_SUCCESS]: questionBasicsSuccess,
  [types.QUESTION_BASICS_FAILURE]: stopLoader,

  [types.QUESTION_BASICS_TYPES_SURVEY_REQUEST]: startLoader,
  [types.QUESTION_BASICS_TYPES_SURVEY_SUCCESS]: questionBasicsTypesSurveysSuccess,
  [types.QUESTION_BASICS_TYPES_SURVEY_FAILURE]: stopLoader,
});

import { connect } from 'react-redux';

import { AuthActions } from '../../../redux/auth';
import { SnackActions } from '../../../redux/snackBar';
import Authentication from './Authentication';

const mapStateToProps = ({ locale }) => ({});

const mapDispatchToProps = (dispatch) => ({
  loginRequest: (username, password) => dispatch(AuthActions.loginRequest(username, password)),
  fieldsMissing: () => dispatch(SnackActions.displayWarning('fields_missing')),
});

export default connect(mapStateToProps, mapDispatchToProps)(Authentication);

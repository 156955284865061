import _ from 'lodash';
import { createReducer } from 'reduxsauce';

import { types } from './actions';

const initialState = {
  list: [],
  loading: false,
};

const startLoader = (state = initialState) => ({ ...state, loading: true });

const stopLoader = (state = initialState) => ({ ...state, loading: false });

const clear = () => {
  return { ...initialState };
};

const fetchAllSuccess = (state = initialState, action) => {
  const { languages, toBeMerged } = action;
  const { list } = state;
  const keyLanguage = _.keyBy(languages, 'id');
  return { ...state, list: toBeMerged ? { ...(list || {}), ...keyLanguage } : keyLanguage, loading: false };
};

const deleteSuccess = (state = initialState, action) => {
  const { ids } = action;
  const { list } = state;
  return { ...state, list: _.omit(list, ids), loading: false };
};

export default createReducer(initialState, {
  [types.START_LOADER]: startLoader,
  [types.STOP_LOADER]: stopLoader,

  [types.FETCH_ALL_SUCCESS]: fetchAllSuccess,
  [types.DELETE_SUCCESS]: deleteSuccess,
  [types.CLEAR]: clear,
});

export default function emptyCache() {
  const currentCacheVersion = localStorage.getItem('cacheVersion');
  if (`${currentCacheVersion}` !== `${process.env.REACT_APP_TAG_VERSION}`) {
    if ('caches' in window) {
      caches.keys().then((names) => {
        // Delete all the cache files
        names.forEach((name) => {
          caches.delete(name);
        });
      });
      // alert('Suite à une mise à jour, nous venons d\'effacer le cache. Cliquer OK pour raffraichir');
      // Makes sure the page reloads. Changes are only visible after you refresh.
      window.location.reload(true);
    }
    localStorage.clear();
    localStorage.setItem('cacheVersion', `${process.env.REACT_APP_TAG_VERSION}`);
  }
}

import './style.css';

import React from 'react';

const Small = () => (
  <div className="small-loader">
    <div />
    <div />
    <div />
  </div>
);

export default Small;

import { Button, Card, Divider, Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Editor from '../../components/Editor/Editor';
import { getCircularReplacer } from '../SurveysCreateV2/CreateForm/CreateForm';
import MedicTags from './MedicTags';
import PatientTags from './PatientTags';
import PswdForgotTags from './PswdForgotTags';
import SurveyTags from './SurveyTags';

const { TextArea } = Input;

const EmailsForm = ({ onValidate, initialValues, onError }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [valueRichTextEditor, setValueRicheTextEditor] = useState();

  useEffect(() => {
    if (initialValues) {
      if (initialValues?.email_templates_translations.body) {
        setValueRicheTextEditor(initialValues.email_templates_translations.body);
      }
      form.resetFields();
    }
  }, [form, initialValues]);

  const onChangeValueEditor = (value) => {
    setValueRicheTextEditor(value);
  };

  const onFinish = (values) => {
    if (!values?.email_templates_translations?.sms_template_id) {
      values = {
        ...values,
        email_templates_translations: {
          ...(values?.email_templates_translations || {}),
          email_template_id: initialValues.id,
        },
      };
    }
    onValidate(values);
  };

  return (
    <Card style={{ margin: 25, width: '80%' }}>
      <PatientTags type={initialValues.type} />
      <MedicTags type={initialValues.type} />
      <PswdForgotTags type={initialValues.type} />
      <SurveyTags type={initialValues.type} />
      <Form onFinish={onFinish} initialValues={initialValues} layout="vertical" form={form} onFinishFailed={onError}>
        <Form.Item name={['email_templates_translations', 'language_id']} hidden={true}>
          <Input disabled />
        </Form.Item>
        <Form.Item name={['email_templates_translations', 'id']} hidden={true}>
          <Input disabled />
        </Form.Item>
        <Form.Item name={['email_templates_translations', 'email_template_id']} hidden={true}>
          <Input disabled />
        </Form.Item>
        <Form.Item name="type" label={t('emailForm.type.label')} rules={[{ required: true, message: t('emailForm.rules.required') }]}>
          <Input disabled={!!initialValues.type} />
        </Form.Item>
        <Form.Item
          name={['email_templates_translations', 'subject']}
          label={t('emailForm.subject.label')}
          rules={[{ required: false, message: t('emailForm.subject.required') }]}
        >
          <Input placeholder={t('emailForm.subject.placeholder')} maxLength={160} />
        </Form.Item>

        <Form.Item name={['email_templates_translations', 'hidden_text']} label={t('emailForm.hidden_text.label')}>
          <TextArea rows={4} placeholder={t('emailForm.hidden_text.placeholder')} maxLength={160} />
        </Form.Item>
        <Form.Item
          name={['email_templates_translations', 'intro_paragraph']}
          label={t('emailForm.intro_paragraph.label')}
          rules={[{ required: false, message: t('emailForm.intro_paragraph.rules.required') }]}
        >
          <Editor
            options={{ height: 800 }}
            placeholder={t('emailForm.intro_paragraph.placeholder')}
            value={valueRichTextEditor}
            onChange={onChangeValueEditor}
          />
        </Form.Item>
        <Form.Item name={['email_templates_translations', 'btn_title']} label={t('emailForm.btn_title.label')}>
          <Input placeholder={t('emailForm.btn_title.placeholder')} />
        </Form.Item>
        <Form.Item name={['email_templates_translations', 'btn_href']} label={t('emailForm.btn_href.label')}>
          <Input placeholder={t('emailForm.btn_href.placeholder')} />
        </Form.Item>
        <Form.Item name={['email_templates_translations', 'link_title']} label={t('emailForm.link_title.label')}>
          <Input placeholder={t('emailForm.link_title.placeholder')} />
        </Form.Item>
        <Form.Item name={['email_templates_translations', 'link_href']} label={t('emailForm.link_href.label')}>
          <Input placeholder={t('emailForm.link_href.placeholder')} />
        </Form.Item>
        <Form.Item name={['email_templates_translations', 'before_sign_paragraph']} label={t('emailForm.before_sign_paragraph.label')}>
          <Editor
            options={{ height: 800 }}
            placeholder={t('emailForm.before_sign_paragraph.placeholder')}
            value={valueRichTextEditor}
            onChange={onChangeValueEditor}
          />
        </Form.Item>

        <Form.Item name={['email_templates_translations', 'body']} label={t('emailForm.body.label')} hidden>
          <Editor
            options={{ height: 800 }}
            placeholder={t('emailForm.body.placeholder')}
            value={valueRichTextEditor}
            onChange={onChangeValueEditor}
          />
        </Form.Item>
        <Divider type="horizontal" />
        <Form.Item style={{ textAlign: 'center' }}>
          <Button type="primary" htmlType="submit" style={{ width: '55%' }}>
            {t('emailForm.save')}
          </Button>
        </Form.Item>
      </Form>
      {!!process.env.REACT_APP_DEBUG_FORM && <pre>{JSON.stringify(form.getFieldsValue(true), getCircularReplacer(), 2)}</pre>}
    </Card>
  );
};

export default EmailsForm;

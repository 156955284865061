import { Button, Card, Checkbox, Divider, Form, Input, Select } from 'antd';
import axios from 'axios';
import { PhoneNumberType } from 'google-libphonenumber';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { userQueries } from '../../../services/GraphQLRequest';
import { getValuesDoesEmailExists } from '../../../services/userService';
import { normalizeString } from '../../../utils';
import { TRIAL_ROLES } from '../../../utils/calimed-enum';
import { MEDIC_TITLE } from '../../../utils/dataMappers';
import { rppsRegex, validEmailRegex } from '../../../utils/regex';
import CountrySelect from '../../components/countrySelect';
import PhoneInput from '../../components/formV3/controllers/PhoneInput';
import ReactSelect from '../../components/ReactSelect/ReactSelect';
import { getCircularReplacer } from '../SurveysCreateV2/CreateForm/CreateForm';

const MedicsForm = ({ onValidate, values, specialities, clinics, medicalTeams, rolesOptions, token, languageList }) => {
  const [form] = Form.useForm();
  const [initialValues, setInitialValues] = useState();
  const [medicMedicalTeams, setMedicMedicalTeams] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    let newInitialValues;
    if (values) {
      newInitialValues = {
        ...values,
        user: { role: values.user.role, language_id: values.user.language.id, users_roles: values.user.users_roles[0].role.id },
        email: values.email,
        speciality_id: (values?.medic_specialities || []).map((m) => m.speciality_id),
        clinic_id: (values?.clinic_medics || []).map((c) => c.clinic_id),
        medical_teams_id: (values?.medical_teams_medics || []).map((c) => c.medical_team.id),
        is_secretary: values.is_secretary,
      };
    } else {
      newInitialValues = {
        ...values,
        user: { role: 'medic' },
        country: 'FR',
        is_secretary: true,
      };
    }
    setInitialValues(newInitialValues);
  }, [values]);

  useEffect(() => {
    if (values) {
      setMedicMedicalTeams(
        medicalTeams.filter((el) => (_.get(values, 'medical_teams_medics', []).map((m) => _.get(m, 'medical_team.id')) || []).includes(el.id)),
      );
    }
  }, [values, medicalTeams]);

  useEffect(() => {
    if (initialValues) {
      form.resetFields();
    }
  }, [initialValues]);

  const onFinish = (finishValues) => {
    onValidate(finishValues);
  };

  const DSC = 'descending';

  function sortByText(a, b, order = DSC) {
    const diff = _.deburr((a?.label || '').toLowerCase()).localeCompare(_.deburr((b?.label || '').toLowerCase()));

    if (order === DSC) {
      return diff;
    }

    return -1 * diff;
  }

  const onEmailVerify = async (email) => {
    const res = await axios.post(
      process.env.REACT_APP_HASURA_URL,
      {
        query: userQueries.doesEmailExist,
        variables: getValuesDoesEmailExists(email, initialValues && initialValues.userId),
      },
      { headers: { Authorization: `Bearer ${token}` } },
    );
    const {
      data: { data },
    } = res;
    if (data) {
      const countPatients = _.get(data, 'patients_aggregate.aggregate.count', 0);
      const countMedics = _.get(data, 'medics_aggregate.aggregate.count', 0);
      return countPatients + countMedics === 0;
    }
    return true;
  };

  const onValuesChange = (changedValues, allValues) => {
    if ('medical_teams_id' in changedValues) {
      if (!changedValues['medical_teams_id']) {
        form.setFieldsValue({
          connector_medical_teams_id: null,
        });
        setMedicMedicalTeams([]);
      } else {
        const newMedicMedicalTeams =
          Array.isArray(changedValues['medical_teams_id']) && medicalTeams.filter((c) => changedValues['medical_teams_id'].includes(c.id));
        setMedicMedicalTeams(newMedicMedicalTeams);
        if (Array.isArray(newMedicMedicalTeams)) {
          if (!newMedicMedicalTeams.map((el) => el.id).includes(allValues.connector_medical_teams_id)) {
            form.setFieldsValue({
              connector_medical_teams_id: null,
            });
          }
          if (newMedicMedicalTeams.length === 1) {
            form.setFieldsValue({
              connector_medical_teams_id: newMedicMedicalTeams[0].id,
            });
          }
        }
      }
    }
  };

  return (
    <>
      <Card style={{ margin: 25, width: '80%' }}>
        <Form onFinish={onFinish} initialValues={initialValues} onValuesChange={onValuesChange} layout="vertical" form={form} name="basic">
          <Form.Item name="is_premium" valuePropName="checked">
            <Checkbox>Compte Premium ?</Checkbox>
          </Form.Item>
          <Form.Item name="is_secretary" valuePropName="checked">
            <Checkbox>Compte secretaire ?</Checkbox>
          </Form.Item>
          <Form.Item name={['user', 'username']} label="Username" rules={[{ required: false, message: 'Nom Utilisateur' }]}>
            <Input placeholder="Nom Utilisateur" maxLength={50} disabled />
          </Form.Item>
          <Form.Item name={['user', 'language_id']} label="Langue" rules={[{ required: true, message: 'Langue non sélectionné' }]}>
            <Select
              placeholder="Langue"
              showSearch
              options={(languageList || []).map((lng) => ({
                value: lng.id,
                label: lng.name,
              }))}
            />
          </Form.Item>
          <Form.Item name={['user', 'role']} label="Type de compte" rules={[{ required: true, message: 'Type de compte non sélectionné' }]}>
            <Select
              placeholder="Type de compte"
              showSearch
              options={[
                { value: 'medic', label: 'Medic (default)' },
                { value: 'calimed', label: 'Connecteur Calimed Actif' },
                { value: 'tester', label: 'Compte testeur' },
              ]}
            />
          </Form.Item>
          <Form.Item name={'role'} label="Medic role (pour etudes cliniques)">
            <Select placeholder="Role medic" showSearch options={Object.values(TRIAL_ROLES).map((el) => ({ value: el, label: el }))} allowClear />
          </Form.Item>

          <Form.Item
            name="title"
            label="Civilité"
            rules={[
              { required: true, whitespace: true },
              () => ({
                validator: (_rule, value) => {
                  if (value && Object.values(MEDIC_TITLE).includes(value)) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(new Error('Civilité incorrecte'));
                  }
                },
              }),
            ]}
          >
            <Select
              placeholder="Civilité"
              showSearch
              options={[
                { value: 'dr', label: t('medic_title.dr') },
                { value: 'pr', label: t('medic_title.pr') },
                { value: 'mr', label: t('medic_title.mr') },
                { value: 'ms', label: t('medic_title.ms') },
              ]}
            />
          </Form.Item>
          <Form.Item name="firstname" label="Prénom" rules={[{ required: true, message: 'Prénom Incorrect', whitespace: true }]}>
            <Input placeholder="Prénom" maxLength={50} />
          </Form.Item>
          <Form.Item name="lastname" label="Nom" rules={[{ required: true, message: 'Nom Incorrect' }]}>
            <Input placeholder="Nom" maxLength={50} />
          </Form.Item>
          <Form.Item name="gender" label="Sexe" rules={[{ required: false, message: 'Sexe non sélectionné', whitespace: true }]}>
            <Select
              showSearch
              placeholder="Sexe"
              options={[
                { value: 'male', label: 'Homme' },
                { value: 'female', label: 'Femme' },
              ]}
            />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              { required: true },
              {
                message: "n'est pas un email valide",
                pattern: validEmailRegex,
                whitespace: false,
              },
              () => ({
                validator: async (_rule, value) => {
                  if (!value || (values && values['email'] === value)) {
                    return Promise.resolve();
                  }
                  return (await onEmailVerify(value)) ? Promise.resolve() : Promise.reject(new Error('Email déjà utilisé'));
                },
              }),
            ]}
          >
            <Input placeholder="Email" type={'email'} />
          </Form.Item>
          <Form.Item
            name="rpps_number"
            label="Numéro RPPS"
            type="regexp"
            rules={[
              {
                required: false,
                message: 'Nombre RPPS Incorrect',
                pattern: rppsRegex,
                whitespace: true,
              },
            ]}
          >
            <Input placeholder="Numéro RPPS" maxLength={50} />
          </Form.Item>
          <Form.Item name="country" label="Pays" rules={[{ required: true, message: 'Pays Incorrect' }]}>
            <CountrySelect />
          </Form.Item>
          <Form.Item
            name="phone_number"
            label="Téléphone mobile"
            type="regexp"
            rules={[
              {
                required: true,
                message: 'Numéro Portable Incorrect',
                whitespace: true,
              },
              ({ getFieldValue }) => ({
                validator(tt, value) {
                  if (!value || value.length <= 3) {
                    // empty field
                    return Promise.resolve();
                  } else {
                    return axios
                      .post(
                        `${process.env.REACT_APP_BASE_API_URL}/V2/phone-number/is-valid-phone-number`,
                        { phoneNumber: value },
                        { headers: { Authorization: `Bearer ${token}` } },
                      )
                      .then((res) => res.data?.data || {})
                      .then((result) => {
                        const { isValidNumber, numberType } = result;
                        if (isValidNumber && PhoneNumberType.MOBILE === numberType) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(new Error('Numéro Portable Incorrect'));
                        }
                      })
                      .catch(() => Promise.reject(new Error('Erreur lors de la verification')));
                  }
                },
              }),
            ]}
          >
            <PhoneInput placeholder="Téléphone mobile" />
          </Form.Item>
          <Form.Item name="speciality_id" label="Quel est sa spécialité ?" rules={[{ required: false, message: 'Spécialité non spécifiée' }]}>
            <ReactSelect
              placeholder="Quel est sa spécialité ?"
              isMulti
              closeMenuOnSelect={true}
              name="speciality_id"
              isSearchable
              options={(specialities || [])
                .map((s) => ({
                  value: s?.id,
                  label: s?.translation?.value,
                }))
                .sort((a, b) => sortByText(a, b, DSC))}
            />
          </Form.Item>
          <Form.Item name="clinic_id" label="A quel établissement est-il rattaché ?">
            <ReactSelect
              placeholder="A quel établissement est-il rattaché ?"
              isMulti
              closeMenuOnSelect={true}
              isSearchable
              options={clinics
                .sort((a, b) => {
                  const nameA = normalizeString(a.name);
                  const nameB = normalizeString(b.name);
                  return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
                })
                .map((m) => ({
                  value: m && m.id,
                  label: `${m.name}, ${m.postcode}`,
                }))}
            />
          </Form.Item>
          <Form.Item name="medical_teams_id" label="A quelles équipes médicales est-il rattaché ?">
            <ReactSelect
              placeholder="A quelles équipes médicales est-il rattaché ?"
              isMulti
              closeMenuOnSelect={true}
              isSearchable
              options={(medicalTeams || []).map((m) => ({
                value: m && m.id,
                label: m.title,
              }))}
            />
          </Form.Item>
          <Form.Item name="connector_medical_teams_id" label="Connecteur : Via quelle équipe médicale le connecteur fonctionne-t-il ?">
            <ReactSelect
              placeholder="Connecteur : Via quelle équipe médicale le connecteur fonctionne-t-il ?"
              closeMenuOnSelect={true}
              isSearchable
              options={(medicMedicalTeams || []).map((m) => ({
                value: m && m.id,
                label: m.title,
              }))}
            />
          </Form.Item>
          <Form.Item name={['user', 'users_roles']} label="Détail du role" rules={[{ required: true, message: 'role non spécifiée' }]}>
            <Select
              style={{ width: '100%' }}
              // mode="multiple"
              options={rolesOptions}
            />
          </Form.Item>
          <Divider type="horizontal" />
          <Form.Item style={{ textAlign: 'center' }}>
            <Button type="primary" htmlType="submit" style={{ width: '55%' }}>
              Enregistrer
            </Button>
          </Form.Item>
        </Form>
      </Card>
      {!!process.env.REACT_APP_DEBUG_FORM && <pre>{JSON.stringify(form.getFieldsValue(true), getCircularReplacer(), 2)}</pre>}
    </>
  );
};

export default MedicsForm;

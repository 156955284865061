import { introductionQuestionsMutations } from './GraphQLRequest';
import { authenticatedQuery } from './middleware';

/*
 *  SERVICES
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  deleteNotIn: (surveyTypeId, introductionQuestionIds) =>
    authenticatedQuery(introductionQuestionsMutations.deleteNotIn, {}, { surveyTypeId, introductionQuestionIds }),
  insertIntroductionQuestions: (introductionQuestion) =>
    authenticatedQuery(introductionQuestionsMutations.insertIntroductionQuestions, {}, { introductionQuestion }),
  updateIntroductionQuestions: (id, introductionQuestion) =>
    authenticatedQuery(introductionQuestionsMutations.updateIntroductionQuestions, {}, { id, introductionQuestion }),
  updateIntroductionQuestionTranslation: (id, introductionQuestionTranslation) =>
    authenticatedQuery(introductionQuestionsMutations.updateIntroductionQuestionTranslation, {}, { id, introductionQuestionTranslation }),
  insertIntroductionQuestionTranslations: (introductionQuestionTranslation) =>
    authenticatedQuery(introductionQuestionsMutations.insertIntroductionQuestionTranslations, {}, { introductionQuestionTranslation }),
};

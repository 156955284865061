import { createAction } from '../../utils';

// Types
export const types = {
  REQUEST: 'surveyModelFiles_REQUEST',
  REQUEST_SUCCESS: 'surveyModelFiles_REQUEST_SUCCESS',
  REQUEST_FAILURE: 'surveyModelFiles_REQUEST_FAILURE',
  /** DELETE */
  DELETE: 'surveyModelFiles_DELETE',
  DELETE_SUCCESS: 'surveyModelFiles_DELETE_SUCCESS',
  DELETE_FAILURE: 'surveyModelFiles_DELETE_FAILURE',

  /** UPSERT */
  UPSERT: 'surveyModelFiles_UPSERT',
  UPSERT_SUCCESS: 'surveyModelFiles_UPSERT_SUCCESS',
  UPSERT_FAILURE: 'surveyModelFiles_UPSERT_FAILURE',

  /** MAKE DEFAULT */
  MAKE_DEFAULT: 'surveyModelFiles_MAKE_DEFAULT',
  MAKE_DEFAULT_SUCCESS: 'surveyModelFiles_MAKE_DEFAULT_SUCCESS',
  MAKE_DEFAULT_FAILURE: 'surveyModelFiles_MAKE_DEFAULT_FAILURE',
};

// Actions
export default {
  /** REQUEST */
  request: (id = null) => createAction(types.REQUEST, { id }),
  requestSuccess: (newList) => createAction(types.REQUEST_SUCCESS, { newList }),
  requestFailure: () => createAction(types.REQUEST_FAILURE),
  /** UPSERT */
  upsert: (payload) => createAction(types.UPSERT, { payload }),
  upsertSuccess: (newItem) => createAction(types.UPSERT_SUCCESS, { newItem }),
  upsertFailure: () => createAction(types.UPSERT_FAILURE),
  /** DELETE */
  delete: (id) => createAction(types.DELETE, { id }),
  deleteSuccess: (id) => createAction(types.DELETE_SUCCESS, { id }),
  deleteFailure: () => createAction(types.DELETE_FAILURE),
  /** MAKE DEFAULT */
  makeDefault: (id) => createAction(types.MAKE_DEFAULT, { id }),
  makeDefaultSuccess: (id) => createAction(types.MAKE_DEFAULT_SUCCESS, { id }),
  makeDefaultFailure: () => createAction(types.MAKE_DEFAULT_FAILURE),
};

import './style.scss';

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { filterAndOrderList } from '../../../utils/utils';
import Filter from '../../components/filter';
import { Tags } from '../../components/tags';
import DataTable from './DataTable';

const ROLES_FILTER_KEYS = ['name'];

const AppKeys = ({ appKeysRequest, list, loading, appKeysDeleteRequest }) => {
  const navigate = useNavigate();
  const [filtered, setFiltered] = useState();
  const [reset, setReset] = useState(false);

  useEffect(() => {
    appKeysRequest && appKeysRequest({});
  }, []);

  const appKeys = list && Object.values(list);

  const onFilter = (result) => {
    if (!result) setFiltered(null);
    else {
      setFiltered(result.map((r) => r.item.id));
      setReset(!reset);
    }
  };

  const onModify = (appKey) => {
    navigate(`/app_keys/${appKey.id}`, appKey);
  };

  const onDelete = (appKey) => {
    appKeysDeleteRequest(Array.isArray(appKey) ? appKey : appKey.id);
  };

  const exportDataToXls = () => {
    return Array.isArray(filtered) ? appKeys.filter((r) => filtered.includes(r.Id)) : appKeys;
  };

  return (
    <>
      <Tags exportFct={exportDataToXls} />
      <Filter list={appKeys} onFilter={onFilter} keys={ROLES_FILTER_KEYS} />
      <DataTable
        items={filterAndOrderList(appKeys, filtered)}
        reset={reset}
        loading={loading}
        itemClick={onModify}
        onDelete={onDelete}
        onModify={onModify}
      />
    </>
  );
};

export default AppKeys;

import { createAction } from '../../utils';

// Types
export const types = {
  QUESTION_BASICS_TYPES_REQUEST: 'QUESTION_BASICS_TYPES_REQUEST',
  QUESTION_BASICS_TYPES_FAILURE: 'QUESTION_BASICS_TYPES_FAILURE',
  QUESTION_BASICS_TYPES_SUCCESS: 'QUESTION_BASICS_TYPES_SUCCESS',

  QUESTION_BASICS_TYPES_SURVEY_UPDATE_REQUEST: 'QUESTION_BASICS_TYPES_SURVEY_UPDATE_REQUEST',
  QUESTION_BASICS_TYPES_SURVEY_UPDATE_FAILURE: 'QUESTION_BASICS_TYPES_SURVEY_UPDATE_FAILURE',
  QUESTION_BASICS_TYPES_SURVEY_UPDATE_SUCCESS: 'QUESTION_BASICS_TYPES_SURVEY_UPDATE_SUCCESS',

  QUESTION_BASICS_TYPES_SURVEY_REQUEST: 'QUESTION_BASICS_TYPES_SURVEY_REQUEST',
  QUESTION_BASICS_TYPES_SURVEY_FAILURE: 'QUESTION_BASICS_TYPES_SURVEY_FAILURE',
  QUESTION_BASICS_TYPES_SURVEY_SUCCESS: 'QUESTION_BASICS_TYPES_SURVEY_SUCCESS',

  QUESTION_BASICS_REQUEST: 'QUESTION_BASICS_REQUEST',
  QUESTION_BASICS_SUCCESS: 'QUESTION_BASICS_SUCCESS',
  QUESTION_BASICS_FAILURE: 'QUESTION_BASICS_FAILURE',
};

// Actions
export default {
  /* UPDATE */
  questionBasicsTypesSurveysUpdateRequest: (questionBasics = {}, questionBasicsText = {}) =>
    createAction(types.QUESTION_BASICS_TYPES_SURVEY_UPDATE_REQUEST, {
      questionBasics,
      questionBasicsText,
    }),
  questionBasicsTypesSurveyUpdateSuccess: () => createAction(types.QUESTION_BASICS_TYPES_SURVEY_UPDATE_SUCCESS),

  /** REQUEST */
  questionBasicsTypesRequest: (questionBasics = {}) => createAction(types.QUESTION_BASICS_TYPES_REQUEST, { questionBasics }),
  questionBasicsTypesSuccess: (questionBasics) => createAction(types.QUESTION_BASICS_TYPES_SUCCESS, { questionBasics }),
  questionBasicsTypesFailure: () => createAction(types.QUESTION_BASICS_TYPES_FAILURE),

  questionBasicsTypesSurveysRequest: (questionBasics = {}) => createAction(types.QUESTION_BASICS_TYPES_SURVEY_REQUEST, { questionBasics }),
  questionBasicsTypesSurveysSuccess: (questionBasics) => createAction(types.QUESTION_BASICS_TYPES_SURVEY_SUCCESS, { questionBasics }),
  questionBasicsTypesSurveysFailure: () => createAction(types.QUESTION_BASICS_TYPES_SURVEY_FAILURE),

  questionBasicsRequest: (questionBasics = {}) => createAction(types.QUESTION_BASICS_REQUEST, { questionBasics }),
  questionBasicsSuccess: (questionBasics) => createAction(types.QUESTION_BASICS_SUCCESS, { questionBasics }),
  questionBasicsFailure: () => createAction(types.QUESTION_BASICS_FAILURE),
};

export const TEMPLATES = [
  /*'counter-sign',
  'basic-mail',
  'create-medic',
  'create-patient',
  'migration-user-email-to-username',
  'medic-denied-consent',
  'patient-denied-consent',*/
  'resume-started-survey-mail',
  'warning-survey-medic-mail',
  'resume-survey-mail',
  'signed-consent-relaunch',
];

export const RECIPIENT_PATIENT_VALUE = 2;

export const CONFIG_KEY = {
  communication_modes: 'COMMUNICATION',
  event_date_refs: 'DATE',
  recipient_event_refs: 'RECIPIENT',
};

export const DATA_KEY = {
  COMMUNICATION: 'mode',
  DATE: 'event_date_path',
  RECIPIENT: 'event_recipient_path',
};

import './consents.css';

import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Badge, Button, Divider, Table, Typography } from 'antd';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';

import { parseDate } from '../../../services/datefns';
import { SURVEY_STATUS } from '../../../utils/calimed-enum';
import { surveyStatusSorterMapper } from '../../../utils/dataMappers';
import { DeleteButton, ModifyButton } from '../../components/button';
import EyeButton from '../../components/button/EyeButton';

const { Text } = Typography;

const DataTable = ({ items, reset, loading, onArchived, itemClick, onDelete, onModify, resumeSurvey }) => {
  const [key, setKey] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const index = 1;
  const [pagination, setPagination] = useState({
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50', '100', '200', '500'],
    current: index,
  });

  const data = items || [];

  if (data.length !== key) {
    setKey(data.length);
    setSelectedRowKeys([]);
  }

  useEffect(() => {
    setPagination({ current: index });
  }, [reset]);

  const handleResumeSurvey = (item) => {
    resumeSurvey(item.id);
  };

  const columns = [
    {
      title: 'Titre',
      dataIndex: 'title',
      sorter: (a, b) => (a.title || '').localeCompare(b.title || ''),
      align: 'center',
      render: (text) => <span>{text}</span>,
    },
    {
      title: 'Prénom',
      dataIndex: ['patient', 'firstname'],
      sorter: (a, b) => ((a.patient && a.patient.firstname) || '').localeCompare((b.patient && b.patient.firstname) || ''),
      align: 'center',
      render: (text) => <span>{text}</span>,
    },
    {
      title: 'Nom',
      dataIndex: ['patient', 'lastname'],
      sorter: (a, b) => ((a.patient && a.patient.lastname) || '').localeCompare((b.patient && b.patient.lastname) || ''),
      align: 'center',
      render: (text) => <span>{text}</span>,
    },
    {
      title: 'Téléphone',
      dataIndex: ['patient', 'phone_number'],
      align: 'center',
      render: (text) => <span>{text}</span>,
    },
    {
      title: 'Date du rendez-vous',
      dataIndex: ['intervention', 'date_consult'],
      sorter: (a, b) =>
        new Date((a.intervention && a.intervention.date_consult) || '') - new Date((b.intervention && b.intervention.date_consult) || ''),
      align: 'center',
      render: (text) => <span>{text && format(parseDate(text), 'dd/MM/yyyy')}</span>,
    },
    {
      title: "Date d'intervention",
      dataIndex: ['intervention', 'date'],
      sorter: (a, b) => new Date((a.intervention && a.intervention.date) || '') - new Date((b.intervention && b.intervention.date) || ''),
      align: 'center',
      render: (text) => <span>{text && format(parseDate(text), 'dd/MM/yyyy')}</span>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      align: 'center',
      sorter: (a, b) => surveyStatusSorterMapper[a.status] - surveyStatusSorterMapper[b.status],
      render: (text) => {
        let badgeColour;
        switch (text) {
          case SURVEY_STATUS.DRAFT:
          case SURVEY_STATUS.SENT:
            badgeColour = 'orange';
            break;
          case SURVEY_STATUS.REFUSED:
            badgeColour = 'red';
            break;
          case SURVEY_STATUS.ACCEPTED:
            badgeColour = 'green';
            break;
          case SURVEY_STATUS.COUNTERSIGNED:
            badgeColour = 'purple';
            break;
          default:
          // this gets called
        }
        return badgeColour && <Badge color={badgeColour} />;
      },
    },
    {
      title: 'Archivé',
      dataIndex: 'is_archived',
      align: 'center',
      sorter: (a, b) => a.is_archived > b.is_archived,
      render: (text, record) => (
        <span>
          <EyeButton confirm eye={!text} onClick={() => handleArchived([record.id], !record.is_archived)} />
        </span>
      ),
    },
    {
      title: '',
      key: 'action',
      width: 200,
      render: (item) => {
        const { is_archived } = item;
        return (
          <span className="ButtonSurveys">
            <ModifyButton modify={() => onModify(item)} />
            <Divider type="vertical" />
            <DeleteButton deletion={() => onDelete(item)} />
            {!is_archived && (
              <>
                <Divider type="vertical" />
                <Button onClick={() => handleResumeSurvey(item)}> Relance </Button>
              </>
            )}
          </span>
        );
      },
    },
  ];

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleArchived = (elements, archived) => {
    if (onArchived) {
      onArchived(elements, archived);
    }
  };

  const handleOnRowClick = (rest, index) => {
    if (itemClick) itemClick(rest);
  };

  const handleReset = () => {
    setSelectedRowKeys([]);
  };

  const actionsRender = () => {
    return (
      <>
        <span>
          <span style={{ marginRight: 6 }}>
            <DeleteButton title={'Supprimer'} onCancel={() => handleReset()} deletion={() => onDelete(selectedRowKeys)} />
          </span>
          <Text strong>{selectedRowKeys.length} consentement(s)</Text>
        </span>
        <br />
        <br />
        <span>
          <span style={{ marginRight: 6 }}>
            <Button shape="circle" type="primary" onClick={() => handleArchived(selectedRowKeys, true)} icon={<EyeOutlined />} />
            <Divider type="vertical" />
            <Button shape="circle" onClick={() => handleArchived(selectedRowKeys, false)} icon={<EyeInvisibleOutlined />} />
          </span>
          <Text strong> {selectedRowKeys.length} consentement(s) </Text>
        </span>
        <br />
        <br />
      </>
    );
  };

  return (
    <div>
      {selectedRowKeys.length > 0 && actionsRender()}
      <Table
        size={'small'}
        showSorterTooltip={false}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data.map((item) => ({ ...item, key: item.id }))}
        loading={loading}
        pagination={pagination}
        onRow={(rest, rowIndex) => {
          return {
            onDoubleClick: (event) => {
              handleOnRowClick(rest, rowIndex);
            },
          };
        }}
        onChange={(page) => {
          setPagination({ current: page.current });
        }}
      />
    </div>
  );
};

export default DataTable;

import _ from 'lodash';
import { createReducer } from 'reduxsauce';

import { editLetterCaseUsers } from '../../utils/redux-helper';
import { types } from './actions';

const initialState = {
  list: {},
  loading: false,
  hasMore: true,
  isEmailAvailable: null,
  emailLoading: false,
};

const startLoader = (state = initialState) => ({ ...state, loading: true });

const stopLoader = (state = initialState) => ({ ...state, loading: false });

const patientsSuccess = (state = initialState, action) => {
  const { patients, shouldMerge } = action;
  const { list } = state;
  const newList = _.keyBy(
    patients.map((p) => editLetterCaseUsers(p)),
    'id',
  );
  return {
    ...state,
    list: shouldMerge ? _.merge(list, newList) : newList,
    loading: false,
    hasMore: patients.length > 0,
  };
};

const resetList = (state = initialState) => {
  return { ...state, list: {} };
};

const patientDeleteSuccess = (state = initialState, action) => {
  const { ids } = action;
  const { list } = state;

  const patients = Object.values(list);
  return { ...state, list: _.omit(list, ids), loading: false, hasMore: patients.length > 0 };
};

/**
 *
 * @param state
 * @returns {{list: {}, loading: boolean}}
 */
const startEmailLoader = (state = initialState) => ({ ...state, emailLoading: true });

const patientsEmailFailure = (state = initialState) => {
  return { ...state, isEmailAvailable: false, emailLoading: false };
};

const patientsEmailSuccess = (state = initialState, action) => {
  const { message } = action;
  return {
    ...state,
    available: { isAvailable: message },
    isEmailAvailable: message,
    emailLoading: false,
  };
};

export default createReducer(initialState, {
  [types.PATIENTS_CREATE_REQUEST]: startLoader,
  [types.PATIENTS_CREATE_SUCCESS]: stopLoader,

  [types.PATIENTS_UPDATE_REQUEST]: startLoader,
  [types.PATIENTS_UPDATE_SUCCESS]: stopLoader,

  [types.PATIENTS_REQUEST]: startLoader,
  [types.PATIENTS_SUCCESS]: patientsSuccess,
  [types.PATIENTS_FAILURE]: stopLoader,

  [types.PATIENTS_DELETE_REQUEST]: startLoader,
  [types.PATIENTS_DELETE_MULTIPLE_REQUEST]: startLoader,
  [types.PATIENTS_DELETE_SUCCESS]: patientDeleteSuccess,

  [types.RESET_LIST]: resetList,

  [types.PATIENTS_EMAIL_REQUEST]: startEmailLoader,
  [types.PATIENTS_EMAIL_SUCCESS]: patientsEmailSuccess,
  [types.PATIENTS_EMAIL_FAILURE]: patientsEmailFailure,
});

import { Button, Card, Divider, Form, Input } from 'antd';
import React, { useEffect } from 'react';

import { getCircularReplacer } from '../SurveysCreateV2/CreateForm/CreateForm';
const { TextArea } = Input;

const SmsForm = ({ onValidate, initialValues, onError }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (initialValues) {
      form.resetFields();
    }
  }, [initialValues]);

  const onFinish = (values) => {
    if (!values.sms_templates_translations.sms_template_id) {
      values = {
        ...values,
        sms_templates_translations: {
          ...values.sms_templates_translations,
          sms_template_id: initialValues.id,
        },
      };
    }
    onValidate(values);
  };

  return (
    <Card style={{ margin: 25, width: '80%' }}>
      <Form onFinish={onFinish} initialValues={initialValues} layout="vertical" form={form} onFinishFailed={onError}>
        <Form.Item name={['sms_templates_translations', 'language_id']} label="language_id" hidden={true}>
          <Input disabled />
        </Form.Item>
        <Form.Item name={['sms_templates_translations', 'id']} hidden={true}>
          <Input disabled />
        </Form.Item>
        <Form.Item name={['sms_templates_translations', 'sms_template_id']} hidden={true}>
          <Input disabled />
        </Form.Item>
        <Form.Item name="type" label="Type" rules={[{ required: true, message: 'Type Incorrect' }]}>
          <Input disabled maxLength={50} />
        </Form.Item>
        <Form.Item name={['sms_templates_translations', 'message']} label="Message" rules={[{ required: false, message: 'Message incorrect' }]}>
          <TextArea rows={4} placeholder="Message..." maxLength={250} />
        </Form.Item>
        <Divider type="horizontal" />
        <Form.Item style={{ textAlign: 'center' }}>
          <Button type="primary" htmlType="submit" style={{ width: '55%' }}>
            Enregistrer
          </Button>
        </Form.Item>
      </Form>
      {!!process.env.REACT_APP_DEBUG_FORM && <pre>{JSON.stringify(form.getFieldsValue(true), getCircularReplacer(), 2)}</pre>}
    </Card>
  );
};

export default SmsForm;

import { connect } from 'react-redux';

import { SurveyModelFilesActions } from '../../../redux/surveyModelFiles';
import MsWordTemplateCreate from './MsWordTemplateCreate';

const mapStateToProps = ({ surveyModelFiles }) => {
  return {
    surveyModelFilesList: surveyModelFiles.list,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getDetail: (id) => dispatch(SurveyModelFilesActions.request(id)),
  upsert: (payload) => dispatch(SurveyModelFilesActions.upsert(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MsWordTemplateCreate);

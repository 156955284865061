import { createAction } from '../../utils';

// Types
export const types = {
  QUESTION_TYPES_REQUEST: 'QUESTION_TYPES_REQUEST',
  QUESTION_TYPES_FAILURE: 'QUESTION_TYPES_FAILURE',
  QUESTION_TYPES_SUCCESS: 'QUESTION_TYPES_SUCCESS',

  QUESTION_TYPES_UPDATE_REQUEST: 'QUESTION_TYPES_UPDATE_REQUEST',
  QUESTION_TYPES_UPDATE_FAILURE: 'QUESTION_TYPES_UPDATE_FAILURE',
  QUESTION_TYPES_UPDATE_SUCCESS: 'QUESTION_TYPES_UPDATE_SUCCESS',

  QUESTION_TYPES_CREATE_REQUEST: 'QUESTION_TYPES_CREATE_REQUEST',
  QUESTION_TYPES_CREATE_FAILURE: 'QUESTION_TYPES_CREATE_FAILURE',
  QUESTION_TYPES_CREATE_SUCCESS: 'QUESTION_TYPES_CREATE_SUCCESS',

  QUESTION_TYPES_DELETE_REQUEST: 'QUESTION_TYPES_DELETE_REQUEST',
  QUESTION_TYPES_DELETE_SUCCESS: 'QUESTION_TYPES_DELETE_SUCCESS',
};

// Actions
export default {
  /* CREATE */
  questionTypesCreateRequest: (filters = {}) => createAction(types.QUESTION_TYPES_CREATE_REQUEST, { filters }),
  questionTypesCreateSuccess: () => createAction(types.QUESTION_TYPES_CREATE_SUCCESS),

  /* UPDATE */
  questionTypesUpdateRequest: (questionType = {}) => createAction(types.QUESTION_TYPES_UPDATE_REQUEST, { questionType }),
  questionTypesUpdateSuccess: () => createAction(types.QUESTION_TYPES_UPDATE_SUCCESS),

  /** REQUEST */
  questionTypesRequest: (filters = {}) => createAction(types.QUESTION_TYPES_REQUEST, { filters }),
  questionTypesSuccess: (questionTypes) => createAction(types.QUESTION_TYPES_SUCCESS, { questionTypes }),
  questionTypesFailure: () => createAction(types.QUESTION_TYPES_FAILURE),

  questionTypesDeleteRequest: (id) => createAction(types.QUESTION_TYPES_DELETE_REQUEST, { id }),
  questionTypesDeleteSuccess: (id) => createAction(types.QUESTION_TYPES_DELETE_SUCCESS, { id }),
};

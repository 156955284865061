import { BrowserRouter, Route, Routes } from 'react-router-dom';

import AuthLayoutOutlet from '../ui/layouts/AuthLayoutOutlet';
import {
  AppKeys,
  AppKeysCreate,
  Authentication,
  Clinics,
  ClinicsCreate,
  ClinicsGroups,
  ClinicsGroupsCreate,
  Consents,
  ConsentsCreate,
  Credential,
  CredentialCreate,
  Emails,
  EmailsCreate,
  Home,
  Language,
  LanguageCreate,
  Legal,
  MedicalTeams,
  MedicalTeamsCreate,
  Medics,
  MedicsCreate,
  MsWordTemplate,
  MsWordTemplateCreate,
  Patients,
  PatientsCreate,
  QuestionTypes,
  QuestionTypesCreate,
  Roles,
  RolesCreate,
  Sftp,
  SftpCreate,
  Sms,
  SmsCreate,
  Specialities,
  SpecialitiesCreate,
  SurveysCreateV2,
  SurveyTypes,
  SurveyTypesCreate,
  Templates,
} from '../ui/screens';
import { LEGAL_PAGE_TYPES } from '../ui/screens/legal/Legal';
import NoMatch from '../ui/screens/NoMatch';
import PublicRoutes from './PublicRoutes';
import Redirect from './Redirect';
import RequireAuth from './RequireAuth';

// Main router which handle user navigation
const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Redirect />}>
          <Route element={<PublicRoutes />}>
            <Route path={'/login'} element={<Authentication />} />
          </Route>
          <Route element={<RequireAuth />}>
            <Route element={<AuthLayoutOutlet />}>
              <Route path={'/'} element={<Home />} />
              <Route path={'/medics'} element={<Medics />} />
              <Route path={'/medics/:medicId'} element={<MedicsCreate />} />
              <Route path={'/clinics'} element={<Clinics />} />
              <Route path={'/clinics/:clinicId'} element={<ClinicsCreate />} />
              <Route path={'/clinics-groups'} element={<ClinicsGroups />} />
              <Route path={'/clinics-groups/:clinicsGroupId'} element={<ClinicsGroupsCreate />} />
              <Route path={'/medicalTeams'} element={<MedicalTeams />} />
              <Route path={'/medicalTeams/:medicalTeamId'} element={<MedicalTeamsCreate />} />
              <Route path={'/patients'} element={<Patients />} />
              <Route path={'/patients/:patientId'} element={<PatientsCreate />} />
              <Route path={'/surveyTypes'} element={<SurveyTypes />} />
              <Route path={'/surveyTypes/:surveyTypeId'} element={<SurveyTypesCreate />} />
              <Route path={'/msWordTemplates'} element={<MsWordTemplate />} />
              <Route path={'/msWordTemplates/:msWordTemplateId'} element={<MsWordTemplateCreate />} />
              <Route path={'/questionTypes'} element={<QuestionTypes />} />
              <Route path={'/questionTypes/:questionTypeId'} element={<QuestionTypesCreate />} />
              <Route path={'/templates'} element={<Templates isStoreTemplate />} />
              <Route path={'/templates/:templateId'} element={<SurveysCreateV2 isStoreTemplate />} />
              <Route path={'/medicTemplates'} element={<Templates />} />
              <Route path={'/medicTemplates/:templateId'} element={<SurveysCreateV2 />} />
              <Route path={'/consents'} element={<Consents />} />
              <Route path={'/consents/:consentId'} element={<ConsentsCreate />} />
              <Route path={'/specialities'} element={<Specialities />} />
              <Route path={'/specialities/:specialityid'} element={<SpecialitiesCreate />} />
              <Route path={'/cgu'} element={<Legal pageType={LEGAL_PAGE_TYPES.CGU} />} />
              <Route path={'/rgpd'} element={<Legal pageType={LEGAL_PAGE_TYPES.RGPD} />} />
              <Route path={'/roles'} element={<Roles />} />
              <Route path={'/roles/:roleId'} element={<RolesCreate />} />
              <Route path={'/sms'} element={<Sms />} />
              <Route path={'/sms/:smsId'} element={<SmsCreate />} />
              <Route path={'/emails'} element={<Emails />} />
              <Route path={'/emails/:emailId'} element={<EmailsCreate />} />
              <Route path={'/app_keys'} element={<AppKeys />} />
              <Route path={'/app_keys/:id'} element={<AppKeysCreate />} />
              <Route path={'/sftpCredentials'} element={<Sftp />} />
              <Route path={'/sftpCredentials/:sftpCredentialId'} element={<SftpCreate />} />
              <Route path={'/credentials'} element={<Credential />} />
              <Route path={'/credentials/:credentialId'} element={<CredentialCreate />} />
              <Route path={'/languages'} element={<Language />} />
              <Route path={'/languages/:languageId'} element={<LanguageCreate />} />
              <Route path={'*'} element={<NoMatch />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;

import { connect } from 'react-redux';

import { CronActions } from '../../../redux/cron';
import { LanguageActions } from '../../../redux/languages';
import { SurveyTypesActions } from '../../../redux/surveyTypes';
import SurveyTypesCreate from './SurveyTypesCreate';

const mapStateToProps = ({ surveyTypes, language, cron }) => {
  return {
    surveyTypesList: surveyTypes.list,
    languageList: Object.values(language.list || {}),
    cronConfig: cron.config,
  };
};

const mapDispatchToProps = (dispatch) => ({
  surveyTypesRequest: (id) => dispatch(SurveyTypesActions.surveyTypesRequest({ id })),
  surveyTypesCreateRequest: (creation) => dispatch(SurveyTypesActions.surveyTypesCreateRequest(creation)),
  surveyTypesUpdateRequest: (surveyType) => dispatch(SurveyTypesActions.surveyTypesUpdateRequest(surveyType)),
  fetchAllLanguages: () => dispatch(LanguageActions.fetchAll()),
  cronConfigRequest: () => dispatch(CronActions.cronConfigRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SurveyTypesCreate);

import { createAction } from '../../utils';

// Types
export const types = {
  CONSENTS_REQUEST: 'CONSENTS_REQUEST',
  CONSENTS_FAILURE: 'CONSENTS_FAILURE',
  CONSENTS_SUCCESS: 'CONSENTS_SUCCESS',

  CONSENTS_DELETE_SUCCESS: 'CONSENTS_DELETE_SUCCESS',
  CONSENTS_ARCHIVE_SUCCESS: 'CONSENTS_ARCHIVE_SUCCESS',
  RESUME_SURVEY: 'RESUME_SURVEY',

  CONSENTS_CLEAR: 'CONSENTS_CLEAR',
};

// Actions
export default {
  /** REQUEST */
  consentsRequest: (filters = {}) => createAction(types.CONSENTS_REQUEST, { filters }),
  consentsSuccess: (consents) => createAction(types.CONSENTS_SUCCESS, { consents }),
  consentsFailure: () => createAction(types.CONSENTS_FAILURE),

  consentsDeleteSuccess: (IDs) => createAction(types.CONSENTS_DELETE_SUCCESS, { IDs }),
  consentsArchiveSuccess: (IDs, archived) => createAction(types.CONSENTS_ARCHIVE_SUCCESS, { IDs, archived }),

  resumeSurvey: (id) => createAction(types.RESUME_SURVEY, { id }),

  /* CLEAR */
  consentsClear: () => createAction(types.CONSENTS_CLEAR),
};

/**
 * GRAPHQL QUERIES
 */

// eslint-disable-next-line import/no-anonymous-default-export

export default {
  doesEmailExist: `
    query DoesEmailExist($wherePatient: patients_bool_exp!, $whereMedic: medics_bool_exp) {
      patients_aggregate(where: $wherePatient) {
        aggregate {
          count
        }
      }
      medics_aggregate(where: $whereMedic) {
        aggregate {
          count
        }
      }
    }
  `,
  doesEmailExist_old: `
    query DoesEmailExist($_email: String!, $_userId: Int) {
      patients_aggregate(where: {
        email: {_ilike: $_email},
        user_id: {_neq: $_userId},
      }) {
        aggregate {
          count
        }
      }
      medics_aggregate(where: {
        email: {_ilike: $_email}, 
        user_id: {_neq: $_userId}
      }) {
        aggregate {
          count
        }
      }
    }
  `,
  getAll: `
    query GetAllUsers {
      users {
        id
        username
      }
    }
  `,
};

import { DISPLAYED_IF_MAPPER } from '../../../utils/dataMappers';

export const SurveyTypesFeeder = {
  Identity: {
    title: 'Vérification de votre identité',
    first_button_label: 'NON',
    second_button_label: 'OUI',
    value: `Bonjour, 
Merci de nous confirmer votre identité, 

Êtes-vous bien :
bold%%nom_signataire%, 
né(e) le %date_naissance_signataire%,
domicilié(e) à %ville_signataire%%bold.`,
    text_success: `%nom_signataire%, 

Dans le cadre des procédures d'identitovigilance, vous nous avez confirmé votre identité. 

bold%La procédure de recueil du consentement libre et éclairé peut donc commencer.%bold`,
    text_error: `Vous avez déclaré ne pas être %nom_signataire% et par là même occasion ne pas être la personne concernée par ce consentement. 

Nous informons de ce problème le %nom_docteur% par courrier électronique. 

Néanmoins, nous vous conseillons vivement de le recontacter par vous-même au plus vite. `,
  },
  WhyConsent: {
    title: 'Pourquoi le consentement ?',
    first_button_label: null,
    second_button_label: 'Continuer',
    value: `bold%%nom_signataire%, avant de commencer, il faut que vous sachiez que le consentement du malade aux soins est une obligation consécutive au caractère contractuel de la relation praticien-malade.
Votre consentement ne constitue pas une décharge de responsabilité pour le praticien ou l'établissement de santé.
Il s'agit simplement de recueillir votre accord sur la réalisation des gestes et traitements tutor%concernant %nom_patient%%tutor. %bold
tutor%
bold%ATTENTION, les questions suivantes sont adressées au patient,%bold %nom_patient%, mais c'est vous, %nom_signataire%, en qualité de représentant légal, qui répondez et vous engagez.
%tutor
La notion de consentement éclairé (1), qui implique que le praticien est tenu de présenter clairement au patient tous les risques d'une conduite thérapeutique, est pourtant relativement récente.
La loi du 29 juillet 1994 relative au respect du corps et modifiée par l'article 70 de la loi 99-641 du 27 juillet 1998 dit qu'il ne peut être porté atteinte à l'intégrité du corps humain qu'en cas de nécessité médicale pour la personne. Le consentement de l'intéressé doit être recueilli préalablement hors le cas où son état rend nécessaire une intervention thérapeutique à laquelle il n'est pas à même de consentir" (code civil article 16-3).

bold%Le consentement doit être libre, c'est-à-dire en l'absence de contrainte, et éclairé, c'est-à-dire précédé par une information. C'est pourquoi, %nom_signataire%, il est essentiel pour vous et pour le Dr %nom_docteur%, que vous lisiez attentivement chaque question et que vous y répondiez de façon objective.%bold`,
    text_success: '',
    text_error: '',
  },
  Introduction: {
    title: 'Introduction',
    first_button_label: 'NON',
    second_button_label: 'OUI',
    value: `%nom_signataire%,

Confirmez-vous avoir été informé par votre chirurgien, le Dr %nom_docteur%, d'un acte chirurgical programmé le %date_operation% tutor%pour %nom_patient% %tutor ?`,
    text_success: `%nom_signataire%,

Vous avez bien été informé(e) par le Dr %nom_docteur% d'un acte chirurgical tutor%pour %nom_patient%%tutor.    `,
    text_error: "Il semble que vous n'ayez pas été informé(e) d'un acte chirurgical tutor%pour %nom_patient%%tutor.",
  },
  DelaiReflex: {
    title: 'Délai de réflexion',
    displayed_if: DISPLAYED_IF_MAPPER.date_warning.value,
    first_button_label: 'NON',
    second_button_label: 'OUI',
    value: `%nom_signataire%, 

le Dr %nom_docteur% a programmé pour vous une intervention chirurgicale le %date_operation%, soit dans un délai inférieur à seize jours par rapport à la date de votre consultation.
Nous vous rappelons qu'il est juridiquement habituel d'accorder aux patients un délai de réflexion de 15 jours entre la date de la consultation et celle de l'intervention chirurgicale. Ce délai de convenance correspond au temps de réflexion considéré comme nécessaire pour une adhésion complète du patient et lui laisser le temps nécessaire à la prise éventuelle d'un autre avis médical.
Cependant, dans votre cas, le Dr %nom_docteur% a déterminé qu'il s'agissait bold%%raison_delai_praticien%%bold.

Confirmez-vous avoir été informé de la date précise de l'intervention et de la raison pour laquelle elle a été fixée dans un délai de moins de seize jours par rapport à la date de votre consultation ?`,
    text_success: `%nom_signataire%, 

Vous êtes d'accord avec le motif pour lequel la date de votre intervention a été fixée dans un délai de moins de seize jours par rapport à la date de votre consultation, à savoir :  %raison_delai_praticien%.

Vous pouvez poursuivre votre consentement.`,
    text_error:
      "En effet, vous n'avez pas compris ou vous n'êtes pas d'accord avec le motif pour lequel votre intervention chirurgicale a été programmé dans un délai de moins de seize jours après la date de votre consultation.",
  },
  SideQuestion: {
    title: 'Zone intervention',
    displayed_if: DISPLAYED_IF_MAPPER.side.value,
    first_button_label: 'NON',
    second_button_label: 'OUI',
    value: `%nom_signataire%,

Avez-vous bien programmé avec votre chirurgien, le Dr %nom_docteur%, une opération du bold%%cote%%bold ?`,
    text_success: '',
    text_error: 'En effet, il existe clairement une divergence entre vous et le Dr %nom_docteur% sur le côté à opérer.',
  },
  RadiusQuestion: {
    title: 'Zone intervention',
    displayed_if: DISPLAYED_IF_MAPPER.radius.value,
    first_button_label: 'NON',
    second_button_label: 'OUI',
    value: `%nom_signataire%,

Avez-vous bien programmé avec le Dr %nom_docteur% une intervention %rayon%, sur le(s) : %rayon_details% ?`,
    text_success: '',
    text_error: 'En effet, il existe clairement une divergence entre vous et le Dr %nom_docteur% sur le ou les zone(s) %rayon% à opérer.',
  },
  ExplainProcess: {
    title: 'Explication de la procédure',
    first_button_label: 'Plus tard',
    second_button_label: 'Maintenant',
    value: `bold%%nom_signataire%, le Dr %nom_docteur% a programmé tutor%pour %nom_patient%%tutor une intervention le %date_operation%. A ce titre, il doit impérativement recueillir avant votre consentement libre et éclairé, c'est le but de cette procédure.%bold

bold%Avant de commencer, merci de prendre connaissance des informations suivantes :%bold
Ce questionnaire comporte %nb_questions% questions.
Elles vous sont posées les unes à la suite des autres.

bold%tutor%Pour rappel, les questions suivantes sont adressées au patient, %nom_patient%, mais c'est vous,  %nom_signataire%, en qualité de représentant légal, qui répondez et vous engagez.%tutor
%bold
Certaines des questions peuvent vous sembler évidentes, mais elles ont toutes une utilité pour valider votre consentement.

bold%Certaines de vos réponses peuvent interrompre votre consentement%bold, car elles démontrent que vous n'avez clairement pas compris des points essentiels.

bold%D'autres réponses déclenchent automatiquement une information à votre chirurgien%bold afin de le tenir informé que certains points, certes moins importants, ne sont pas encore clairs pour vous. Il pourra de ce fait compléter votre information avant votre intervention.
Pour la plupart des questions bold%une information complémentaire vous est proposée. Au moindre doute n'hésitez pas à utiliser cette possibilité.%bold

Attention, ce questionnaire peut durer entre 10 et 20 minutes, bold%red%il est indispensable de vous assurer de disposer du temps nécessaire avant de commencer.%red%bold Dans le cas contraire n'hésitez pas à appuyer sur la touche "bold%Plus tard%bold".
bold%red%En effet, ce questionnaire doit se dérouler d'une seule traite.%red%bold Si vous l'interrompez, il sera annulé.
bold%Vous souhaitez commencer :%bold`,
    text_success: '',
    text_error: '',
  },
  Conclusion: {
    title: 'Conclusion',
    first_button_label: 'NON',
    second_button_label: 'OUI',
    value: `%nom_signataire%,

Considérez-vous que l'information qui vous a été délivrée dans le cadre d'un entretien individuel par le Dr %nom_docteur%, et complétée si besoin par ce questionnaire, a été suffisamment complète, claire et loyale pour vous permettre d'arrêter votre choix sur la pratique de l'intervention proposée ?`,
    text_success: `%nom_signataire%,

Vous considérez que l'information qui vous a été délivrée dans le cadre d'un entretien individuel par le Dr %nom_docteur%, et complétée si besoin par ce questionnaire, a été suffisamment complète, claire et loyale pour vous permettre d'arrêter votre choix sur la pratique de l'intervention proposée.

bold%Vous pouvez poursuivre votre consentement.%bold`,
    text_error:
      "En effet, malgré l'information donnée par votre praticien et les explications complémentaires qui étaient à votre disposition tout au long de ce questionnaire, vous considérez que vous n'avez pas les éléments nécessaires pour donner votre consentement libre et éclairé.",
  },
};

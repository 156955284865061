import { connect } from 'react-redux';

import { EmailsActions } from '../../../redux/emails';
import Emails from './Emails';

const mapStateToProps = ({ emails }) => ({
  list: emails.list || {},
  loading: emails.loading,
});

const mapDispatchToProps = (dispatch) => ({
  emailsRequest: (id) => dispatch(EmailsActions.emailsRequest(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Emails);

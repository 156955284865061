import _ from 'lodash';
import { call, put, takeLatest } from 'redux-saga/effects';

import { MedicalTeamsService } from '../../services';
import { AuthActions } from '../auth';
import { LoaderActions } from '../loader';
import { SnackActions } from '../snackBar';
import { default as MedicalTeamActions, types } from './actions';

function* medicalTeamsRequest({ filters }) {
  const id = (filters && filters.id) || null;
  const [error, data] = id ? yield call(MedicalTeamsService.medicalTeam, id) : yield call(MedicalTeamsService.medicalTeams);
  if (error || !data) {
    yield put(SnackActions.displayError('medicalTeam_list_error'));
    yield put(MedicalTeamActions.medicalTeamsFailure());
    return;
  }
  if (data) {
    yield put(MedicalTeamActions.medicalTeamsSuccess(data));
  }
}

function* medicalTeamsUpdateRequest({ medicalTeam }) {
  const medicIds = medicalTeam.medic_id;
  const medicTeamId = medicalTeam.id;
  const medicalTeamData = _.omit(medicalTeam, ['medic_id', 'medical_teams_settings']);
  const medicalTeamSettings = _.omit(medicalTeam.medical_teams_settings[0], 'id');
  const medicalTeamMedics = medicIds.map((medicId) => ({ medic_id: medicId, medical_teams_id: medicTeamId }));
  yield put(LoaderActions.loading());
  const [error, response] = yield call(MedicalTeamsService.medicalTeamsUpdate, medicalTeamData, medicalTeamMedics, medicalTeamSettings);
  if (!error && response) {
    yield put(SnackActions.displayInfo('medicalTeam_modify_success'));
    yield put(MedicalTeamActions.medicalTeamsUpdateSuccess());
    yield put(MedicalTeamActions.medicalTeamsRequest({}));
    yield put(AuthActions.setRedirectUrl('/medicalTeams'));
  } else {
    yield put(SnackActions.displayError('medicalTeam_modify_error'));
  }
  yield put(LoaderActions.loaded());
}

function* medicalTeamsCreateRequest({ filters }) {
  const newFilter = {
    ..._.omit(filters, 'medic_id'),
    description: filters.description || '',
    medical_teams_medics: { data: filters.medic_id.map((medicId) => ({ medic_id: medicId })) },
    medical_teams_settings: { data: filters.medical_teams_settings[0] },
  };
  yield put(LoaderActions.loading());
  const [error, data] = yield call(MedicalTeamsService.medicalTeamsCreate, newFilter);
  if (data && !error) {
    yield put(SnackActions.displayInfo('medicalTeam_create_success'));
    yield put(MedicalTeamActions.medicalTeamsCreateSuccess());
    yield put(MedicalTeamActions.medicalTeamsRequest({ id: filters.medicalTeams }));
    yield put(AuthActions.setRedirectUrl('/medicalTeams'));
  } else {
    yield put(SnackActions.displayError('medicalTeam_create_error'));
  }
  yield put(LoaderActions.loaded());
}

function* medicalTeamsDeleteRequest({ id }) {
  yield put(LoaderActions.loading());
  const [error, response] = yield call(MedicalTeamsService.medicalTeamsDelete, Array.isArray(id) ? id : [id]);
  if (response && !error) {
    yield put(SnackActions.displayInfo('medicalTeam_delete_success'));
    yield put(MedicalTeamActions.medicalTeamsDeleteSuccess(id));
    yield put(MedicalTeamActions.medicalTeamsRequest({}));
    yield put(AuthActions.setRedirectUrl('/medicalTeams'));
  } else {
    yield put(SnackActions.displayError('medicalTeam_delete_error'));
  }
  yield put(LoaderActions.loaded());
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  takeLatest(types.MEDICALTEAMS_REQUEST, medicalTeamsRequest),
  takeLatest(types.MEDICALTEAMS_UPDATE_REQUEST, medicalTeamsUpdateRequest),
  takeLatest(types.MEDICALTEAMS_CREATE_REQUEST, medicalTeamsCreateRequest),
  takeLatest(types.MEDICALTEAMS_DELETE_REQUEST, medicalTeamsDeleteRequest),
];

import './style.css';

import PropTypes from 'prop-types';
import React from 'react';

import { InputArea, InputText, SurveyFormController } from '../../../../components/formV3';
import QuestionHeader from '../QuestionHeader';

const TextAnswer = ({ question, onDelete, prefixName, index, nestIndex, can }) => {
  const prefix = prefixName ? `${prefixName}.` : '';
  const translationPrefix = `${prefix}translation.`;
  return (
    <QuestionHeader question={question} onDelete={onDelete} prefixName={translationPrefix} index={index} nestIndex={nestIndex} can={can}>
      <SurveyFormController name={`${prefix}type_id`} isRequiredField={true} className={'d-none'}>
        <InputText disabled={true} />
      </SurveyFormController>
      <SurveyFormController name={`${translationPrefix}data.answer`}>
        <InputArea className="question-type-text-answer-input-area" disabled={!can.steps.question.canAnswer} />
      </SurveyFormController>
    </QuestionHeader>
  );
};

export default TextAnswer;

TextAnswer.propTypes = {
  question: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  prefixName: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  nestIndex: PropTypes.number,
  can: PropTypes.object.isRequired,
};

import _ from 'lodash';

import { authenticatedMutation, authenticatedQuery } from './middleware';

/**
 * GRAPHQL QUERIES
 */
const queries = {
  getSpecialities: () => `
    query GetSpecialities {
      specialities {
        id
        specialities_translations {
          id
          language_id
          value
          desc
        }
      }
    }
  `,
  specialities: () => `
    query Specialities($id: Int) {
      specialities(where: { id: { _eq: $id } }) {
        id
        specialities_translations {
          id
          language_id
          value
          desc
        }
      }
    }
  `,
};
/**
 * GRAPHQL MUTATION
 *
 */

const mutations = {
  specialitiesCreate: () => `
    mutation SpecialitiesCreate($specialities: [specialities_insert_input!]!) {
      insert_specialities(objects: $specialities) {
        returning {
          id
        }
      }
    }
  `,
  specialitiesUpdate: () => `
    mutation UpdateSpecialities($specialityId: Int!, $specialitiesTranslations: [specialities_translations_insert_input!]!,  $specialities: specialities_set_input!) {
      update_specialities(where: {id: {_eq: $specialityId}}, _set: $specialities) {
        returning {
          id
        }
      }
      insert_specialities_translations(objects: $specialitiesTranslations, on_conflict: {constraint: specialities_translations_pkey, update_columns: [desc, value]}) {
        returning {
          id
          __typename
        }
        __typename
      }
    }
  `,
  specialitiesDelete: () => `
    mutation deleteSpecialities($ids: [Int!]!) {
      delete_specialities_translations(where: {speciality_id: {_in: $ids}}) {
        returning {
          id
        }
      }
      delete_specialities(where: {id: {_in: $ids}}) {
        returning {
          id
        }
      }
    }
  `,
};

export default {
  getSpecialities: () => authenticatedQuery(queries.getSpecialities(), {}, {}),
  specialities: (id = null) => authenticatedQuery(queries.specialities(), {}, { id }),
  specialitiesCreate: (specialities) =>
    authenticatedMutation(
      mutations.specialitiesCreate(),
      {},
      {
        specialities,
      },
    ),
  specialitiesUpdate: (specialityId, specialities, specialitiesTranslations) =>
    authenticatedMutation(mutations.specialitiesUpdate(), {}, { specialityId, specialities, specialitiesTranslations }),
  specialitiesTranslationsCreate: (speciality) =>
    authenticatedMutation(mutations.specialitiesUpdate(), {}, { id: speciality.id, speciality: _.omit(speciality, ['id', '__typename']) }),
  specialitiesTranslationsUpdate: (speciality) =>
    authenticatedMutation(mutations.specialitiesUpdate(), {}, { id: speciality.id, speciality: _.omit(speciality, ['id', '__typename']) }),
  specialitiesDelete: (ids) => authenticatedMutation(mutations.specialitiesDelete(), {}, { ids: Array.isArray(ids) ? ids : [ids] }),
};

import './style.css';

import React from 'react';

const PrimaryButton = ({ active, image, text, onClick, center, type = 'button', disabled = false, hidden = false }) => {
  const handleOnClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (onClick && !disabled) onClick(event);
  };

  const centerStyle = () => {
    return center === 'left' ? 'ec-primary-button-left' : 'ec-primary-button-center';
  };

  return (
    <button
      type={type}
      className={`${active ? 'ec-primary-button-active' : 'ec-primary-button'} ${centerStyle()} ${hidden ? 'ec-primary-button-hidden' : ''}`}
      onClick={handleOnClick}
      disabled={disabled}
    >
      <img src={image} alt={text} title={text} />
      <p className={'ec-primary-button-text'}>{text}</p>
    </button>
  );
};

export default PrimaryButton;

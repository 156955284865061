import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import reactSelectStyle from './reactSelectStyle';

function ReactSelect({
  name,
  id,
  placeholder,
  value,
  options,
  isMulti,
  isSearchable,
  onChange,
  onChangeListener,
  disabled,
  closeMenuOnSelect,
  isLoading,
  ...props
}) {
  const [val, setVal] = useState();

  useEffect(() => {
    let newVal = value;
    if (typeof value === 'boolean' && value !== val) {
      newVal = options && options.filter((op) => op.value === value);
    } else if (value && options) {
      let newOptions = options;
      if (options.length && 'options' in options[0]) {
        newOptions = options.reduce((acc, el) => [...acc, ...el.options], []);
      }
      if (Array.isArray(value)) {
        newVal = newOptions.filter((op) => value.includes(op.value));
      } else {
        newVal = newOptions.find((op) => value === op.value);
      }
    }
    setVal(newVal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, options]);

  const handleChange = (selectedOptions) => {
    const selectedIds =
      selectedOptions && Array.isArray(selectedOptions) ? selectedOptions.map((el) => el.value) : selectedOptions && selectedOptions.value;
    onChange?.(selectedIds);
    onChangeListener?.(selectedIds);
  };

  return (
    <Select
      id={id}
      styles={reactSelectStyle({})}
      placeholder={placeholder}
      isMulti={isMulti}
      closeMenuOnSelect={closeMenuOnSelect ? closeMenuOnSelect : isMulti ? !isMulti : true}
      name={name}
      value={val}
      isSearchable={isSearchable}
      options={options}
      onChange={handleChange}
      isDisabled={disabled}
      menuPortalTarget={document.querySelector('body')}
      isLoading={isLoading}
      {...props}
    />
  );
}

ReactSelect.propTypes = {
  id: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.bool),
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  options: PropTypes.array,
  name: PropTypes.string,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  closeMenuOnSelect: PropTypes.bool,
};

export default ReactSelect;

import { INTERVENTIONS_MINIMAL_FIELDS } from './interventionsFragments';
import { SURVEY_ATTACHMENT_MINIMAL_FIELDS, SURVEY_DRAWINGS_MINIMAL_FIELDS } from './survey_pdfsFragments';
import { SURVEYS_TYPES_MINIMAL_FIELDS } from './survey_typesFragments';

export const SURVEYS_MINIMAL_FIELDS = `
  fragment surveysMinimalFields on surveys {
    id
    username
    role 
    renew_pwd
    edit_info
    accepted_cgu
    accepted_rgpd
  }
`;

export const SURVEYS_CORE_FIELDS = `
  ${SURVEYS_TYPES_MINIMAL_FIELDS}
  ${INTERVENTIONS_MINIMAL_FIELDS}
  ${SURVEY_ATTACHMENT_MINIMAL_FIELDS}
  ${SURVEY_DRAWINGS_MINIMAL_FIELDS}
  fragment surveysCoreFields on surveys {
    id
    title
    is_signed
    status
    tutored_id
    patient_id
    survey_type {
      ...surveyTypesMinimalFields
    }
    survey_drawing_id
    survey_drawing {
      ...surveyDrawingsMinimalFields
    }
    survey_attachment_id
    survey_attachment {
      ...surveyAttachmentMinimalFields
    }
    intervention {
      ...interventionsMinimalFields
    }
    referent {
      id
      firstname
      lastname
    }
  }
`;

export const SURVEYS_CORE_FIELDS_FOR_PATIENT = `
  ${SURVEYS_TYPES_MINIMAL_FIELDS}
  ${INTERVENTIONS_MINIMAL_FIELDS}
  ${SURVEY_ATTACHMENT_MINIMAL_FIELDS}
  ${SURVEY_DRAWINGS_MINIMAL_FIELDS}
  fragment surveysCoreFieldsForPatient on surveys {
    id
    title
    is_signed
    status
    tutored_id
    patient_id
    survey_type {
      ...surveyTypesMinimalFields
    }
    survey_drawing_id
    survey_drawing {
      ...surveyDrawingsMinimalFields
    }
    survey_attachment_id
    survey_attachment {
      ...surveyAttachmentMinimalFields
    }
    intervention {
      ...interventionsMinimalFields
    }
    referent {
      firstname
      lastname
    }
  }
`;

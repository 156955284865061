import './style.css';

import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { IconContext } from 'react-icons';
import { AiOutlineDelete, AiOutlineMenu } from 'react-icons/ai';
import { BsPlus } from 'react-icons/bs';

import { InputArea, InputText } from '../../../../components/formV3';
import ErrorMessage from '../../../../components/formV3/errors/ErrorMessage';

const ArrayList = ({ control, prefixName, sufixNameInput = 'label', name, defaultNewItem, deletableItems, disabled, isTextArea = false }) => {
  // React Hook Form
  const prefix = `${prefixName ? `${prefixName}.` : ''}`;
  const fieldArrayName = `${prefix}${name}`;
  const [canFocus, setCanFocus] = useState(false);

  const formContext = useFormContext(); // retrieve all hook methods
  const { fields, append, remove, move } = useFieldArray({
    control: control || formContext.control, // control props comes from useForm (optional: if you are using FormContext)
    name: `${fieldArrayName}`, // unique name for your Field Array
    // keyName: "id", default to "id", you can change the key name
  });

  useEffect(() => {
    if (Array.isArray(fields) && fields.length > 1) {
      canFocus && formContext.setFocus(`${fieldArrayName}.${fields.length - 1}.${sufixNameInput}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields.length]);

  const handleKeyDown = (index, event) => {
    const {
      target: { value },
      key,
    } = event;
    setCanFocus(true);
    // allowing user to press enter to add a line in text area input mode
    const keys = isTextArea ? ['Tab'] : ['Enter', 'Tab'];
    if (keys.includes(key)) {
      event.preventDefault();
      // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
      if (index >= fields.length - 1 && value && value !== '') {
        handleAddItem();
      } else if (index < fields.length - 1) {
        formContext.setFocus(`${fieldArrayName}.${index + 1}.${sufixNameInput}`);
      }
    }
  };

  const handleDrag = ({ source, destination }) => {
    if (destination) {
      move(source.index, destination.index);
    }
  };

  const onDeleteItem = (index) => {
    remove(index);
  };

  const handleAddItem = () => {
    append({ ...defaultNewItem });
  };

  return (
    <>
      <DragDropContext onDragEnd={handleDrag}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div className="ec-sortable-list-items-container" {...provided.droppableProps} ref={provided.innerRef}>
              {fields.map((field, index) => {
                return (
                  <Draggable key={field.value} draggableId={`draggable-${index + 1}`} index={index} isDragDisabled={disabled}>
                    {(provided2) => (
                      <>
                        <div
                          className="d-flex flex-row justify-content-start mb-2"
                          key={`draggable-container[${index}]`}
                          ref={provided2.innerRef}
                          {...provided2.draggableProps}
                          {...provided2.dragHandleProps}
                        >
                          <div className="mt-1">
                            <IconContext.Provider value={{ color: '#00aecb', size: '20px' }}>
                              <div>
                                <AiOutlineMenu />
                              </div>
                            </IconContext.Provider>
                          </div>
                          {isTextArea ? (
                            <InputArea
                              name={`${fieldArrayName}.${index}.${sufixNameInput}`}
                              id={field.value}
                              type={'text'}
                              defaultValue={_.get(field, sufixNameInput)} // make sure to set up defaultValue
                              containerClassname="ec-sortable-item"
                              className="ec-sortable-item-bordered-textarea"
                              onKeyDown={(event) => handleKeyDown(index, event)}
                              disabled={disabled}
                              maxLength={400}
                            />
                          ) : (
                            <InputText
                              name={`${fieldArrayName}.${index}.${sufixNameInput}`}
                              defaultValue={_.get(field, sufixNameInput)} // make sure to set up defaultValue
                              containerClassName="ec-sortable-item ec-sortable-item-bordered"
                              onKeyDown={(event) => handleKeyDown(index, event)}
                              readOnly={disabled}
                            />
                          )}
                          {!disabled && deletableItems && (
                            <IconContext.Provider value={{ color: 'red', size: '1.5em' }}>
                              <button type="button" className="ec-sortable-list-button-icons" onClick={() => onDeleteItem(index)}>
                                <AiOutlineDelete />
                              </button>
                            </IconContext.Provider>
                          )}
                        </div>
                        <ErrorMessage name={`${fieldArrayName}.${index}.${sufixNameInput}`} />
                      </>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div className="ec-sortable-item-separator" />
      {!disabled && (
        <div className="ec-sortable-item ec-sortable-item-bordered">
          <IconContext.Provider value={{ color: '#00aecb', size: '2em' }}>
            <div className="ec-sortable-list-icons" onClick={handleAddItem}>
              <BsPlus /> Ajouter
            </div>
          </IconContext.Provider>
        </div>
      )}
      {<ErrorMessage name={`${fieldArrayName}`} />}
    </>
  );
};

ArrayList.propTypes = { prefixName: PropTypes.string, name: PropTypes.string.isRequired };

export default ArrayList;

import { ExpandAltOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select, Upload } from 'antd';
import axios from 'axios';
import cryptoJs from 'crypto-js';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import signApi from '../../../../services/axios/signApi';
import { CONTENT_TYPE_MAPPER } from '../../../../utils/calimed-enum';

function arrayBufferToWordArray(ab) {
  var i8a = new Uint8Array(ab);
  var a = [];
  for (var i = 0; i < i8a.length; i += 4) {
    a.push((i8a[i] << 24) | (i8a[i + 1] << 16) | (i8a[i + 2] << 8) | i8a[i + 3]);
  }
  return cryptoJs.lib.WordArray.create(a, i8a.length);
}

const getHashFromFile = async (file) => {
  return new Promise((resolve) => {
    var reader = new FileReader();
    reader.onloadend = function (event) {
      var data = event.target.result;
      if (event.target.readyState === FileReader.DONE) {
        var hash = cryptoJs.SHA256(arrayBufferToWordArray(data));
        return resolve(hash.toString());
      }
    };
    if (file) {
      reader.readAsArrayBuffer(file);
    } else {
      return resolve();
    }
  });
};

function MsWordTemplateForm({ initialValues, onFinish, authorizationToken, languageList }) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [lastUploadedFile, setLastUploadedFile] = useState();
  const [currentLoid, setCurrentLoid] = useState();
  const [fileName, setFileName] = useState();

  useEffect(() => {
    if (initialValues) {
      form.resetFields();
      setCurrentLoid(initialValues?.loid);
      setFileName(initialValues?.name);
    }
  }, [initialValues]);

  const handleFinish = (values) => {
    onFinish(values);
  };

  const handleDownload = () => {
    downloadFile(lastUploadedFile.file, lastUploadedFile.name);
  };

  const downloadFile = (file, name) => {
    var link = document.createElement('a'); //creating an invisible element
    link.setAttribute('href', window.URL.createObjectURL(file));
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    setTimeout(function () {
      window.URL.revokeObjectURL(link);
    }, 200);
  };

  const uploadProps = {
    maxCount: 1,
    accept: CONTENT_TYPE_MAPPER.DOCX,
    action: '/file',
    beforeUpload: (file) => {
      const isGoodFileType = CONTENT_TYPE_MAPPER.DOCX.split(',').includes(file.type);
      if (!isGoodFileType) {
        // eslint-disable-next-line no-console
        console.error(`${file.name} is not a doc,docx file`);
      }
      return isGoodFileType || Upload.LIST_IGNORE;
    },
    onChange: ({ file }, tt) => {
      setLastUploadedFile({
        file: file?.originFileObj,
      });
    },
    headers: {
      Authorization: authorizationToken,
    },
    customRequest: (options) => {
      const data = new FormData();
      data.append('file', options.file);
      data.append('file_insert_mode', 'surveyModelFiles');
      const config = {
        headers: options.headers,
      };
      signApi
        .post(options.action, data, config)
        .then((res) => {
          options.onSuccess(res.data.oid);
          form.setFieldsValue({ name: options.file.name });
          form.setFieldsValue({ loid: res.data.oid });
          form.setFieldsValue({ mime_type: options.file.type });
          getHashFromFile(options.file).then((hash) => form.setFieldsValue({ hash: hash }));
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
        });
    },
  };

  const handlePreview = async () => {
    let url = `${process.env.REACT_APP_SIGN_API_URL}/file/loids/${currentLoid}`;
    const responseType = 'blob'; // 'arraybuffer' || 'blob';
    url = /^https?:\/\//gi.test(url) ? url : `${process.env.REACT_APP_BASE_API_URL}/${url}`;
    const res = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: authorizationToken,
      },
      params: {
        ext: 'docx',
      },
      responseType,
    });
    const { data } = res;
    downloadFile(data, `${fileName}.docx`);
  };

  const handleValuesChange = (changedValues) => {
    if ('name' in changedValues) {
      setFileName(changedValues?.name);
    }
  };

  return (
    <>
      <Form onFinish={handleFinish} onValuesChange={handleValuesChange} initialValues={initialValues} layout="vertical" form={form}>
        <Form.Item name={['language_id']} label="Langue" rules={[{ required: true, message: 'Langue non sélectionné' }]}>
          <Select
            placeholder="Langue"
            showSearch
            options={(languageList || []).map((lng) => ({
              value: lng.id,
              label: lng.name,
            }))}
          />
        </Form.Item>
        <Form.Item name={'file'} label={'docx'}>
          <Form.Item name="id" label="Id" hidden={true}>
            <Input placeholder="Id" maxLength={70} disabled />
          </Form.Item>
          <Upload {...uploadProps}>
            <Button icon={<UploadOutlined />} htmlType="button">
              {t('attached_a_pdf')}
            </Button>
          </Upload>
          {(lastUploadedFile || currentLoid) && (
            <button type="button" onClick={lastUploadedFile ? handleDownload : currentLoid && handlePreview}>
              Télécharger
            </button>
          )}
          <Form.Item name="loid" label="Loid" rules={[{ required: true, message: 'Loid Obligatoire' }]}>
            <Input placeholder="Loid" maxLength={70} disabled />
          </Form.Item>
          <Form.Item name="name" label="Nom" rules={[{ required: true, message: 'Nom Obligatoire' }]}>
            <Input placeholder="Loid" maxLength={300} />
          </Form.Item>
        </Form.Item>
        <Form.Item name="hash" label="Hash" rules={[{ required: true, message: 'Hash Obligatoire' }]}>
          <Input placeholder="Hash" maxLength={300} disabled />
        </Form.Item>
        <Form.Item name="mime_type" label="Mime" rules={[{ required: true, message: 'Mime Obligatoire' }]}>
          <Input placeholder="Mime" maxLength={300} disabled />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: '55%' }}>
            Enregistrer
          </Button>
        </Form.Item>
      </Form>

      <Button type="dashed" className="dynamic-delete-button" onClick={() => handlePreview()} htmlType="button" icon={<ExpandAltOutlined />}>
        {t('preview')}
      </Button>
    </>
  );
}

MsWordTemplateForm.propTypes = {};

export default MsWordTemplateForm;

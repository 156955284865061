import { connect } from 'react-redux';

import { ClinicActions } from '../../../redux/clinics';
import { ConsentActions } from '../../../redux/consents';
import { InterventionActions } from '../../../redux/interventions';
import { MedicalTeamActions } from '../../../redux/medicalTeams';
import { MedicActions } from '../../../redux/medics';
import { SpecialityActions } from '../../../redux/specialities';
import { SurveyTypesActions } from '../../../redux/surveyTypes';
import Consents from './Consents';

const mapStateToProps = ({ consents, medics, medicalTeams, clinics, surveyTypes, specialities }) => ({
  consents: Object.values(consents.list) || {},
  medics: Object.values(medics.list || {}),
  medicalTeams: Object.values(medicalTeams.list || {}),
  clinics: Object.values(clinics.list || {}),
  types: Object.values(surveyTypes.list || {}),
  specialities: Object.values(specialities.list),
  loading: consents.loading,
});

const mapDispatchToProps = (dispatch) => ({
  consentsRequest: (filter) => dispatch(ConsentActions.consentsRequest(filter)),
  medicsRequest: (filter) => dispatch(MedicActions.medicsRequest(filter)),
  clinicsRequest: (filter) => dispatch(ClinicActions.clinicsRequest(filter)),
  surveyTypesRequest: (filter) => dispatch(SurveyTypesActions.surveyTypesRequest(filter)),
  medicalTeamsRequest: (filter) => dispatch(MedicalTeamActions.medicalTeamsRequest(filter)),
  surveySpecialitiesRequest: () => dispatch(SpecialityActions.specialitiesRequest()),
  interventionsClear: () => dispatch(InterventionActions.interventionsClear()),
  interventionsDeleteRequest: (id) => dispatch(InterventionActions.interventionsDeleteRequest(id)),
  interventionsArchivedRequest: (IDs, archived) => dispatch(InterventionActions.interventionsArchiveRequest(IDs, archived)),
  resumeSurvey: (id) => dispatch(ConsentActions.resumeSurvey(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Consents);

import { format } from 'date-fns';
import _ from 'lodash';
import { call, put, takeLatest } from 'redux-saga/effects';

import { PatientService, UserService } from '../../services';
import { phoneNumberMapper } from '../../utils/dataMappers';
import { getGender } from '../../utils/redux-helper';
import { isNullOrWhitespace } from '../../utils/utils';
import { AuthActions } from '../auth';
import { LoaderActions } from '../loader';
import { SnackActions } from '../snackBar';
import { default as PatientActions, types } from './actions';

function* patientsRequest({ patientsIds }) {
  const [error, response] = patientsIds ? yield call(PatientService.patients, patientsIds) : yield call(PatientService.getPatients);
  if (error || (response && !response.patients)) {
    yield put(PatientActions.patientsFailure());
    yield put(SnackActions.displayError('patient_list_error'));
    return;
  }
  const { patients } = response;
  const shouldMerge = patientsIds ? true : false;
  yield put(PatientActions.patientsSuccess(patients, shouldMerge));
}

function* patientsUpdateRequest({ patient }) {
  yield put(LoaderActions.loading());
  patient.address = `${patient.city ? `${patient.city}, ` : ''}${patient.postcode || ''}`;
  patient.birthdate = !isNullOrWhitespace(patient.birthdate) ? format(patient.birthdate, 'yyyy-MM-dd') : null;

  let queryVariables = _.pick(patient, [
    'medic_id',
    'gender',
    'firstname',
    'lastname',
    'use_name',
    'phone_number',
    'email',
    'is_tutor',
    'security_number',
    'internal_insi',
    'ipp',
    'birthdate',
    'birthplace_id',
    'birthplace',
    'birthplace_postcode',
    'city_id',
    'city',
    'postcode',
    'address',
    'medical_teams_id',
    'language_id',
  ]);
  queryVariables = {
    ...queryVariables,
    ...phoneNumberMapper(queryVariables.phone_number && queryVariables.phone_number.replace(/\s/g, '')),
  };
  let error, response;
  if (patient.insi && patient.insi !== '') {
    const { insi } = patient;
    const insiPayload = {
      nir: insi.length === 15 ? insi.substring(0, 13) : null,
      key: insi.length === 15 ? insi.slice(-2) : null,
      gender: getGender(queryVariables.gender),
      lastname: queryVariables.lastname.toUpperCase(),
      firstname: queryVariables.firstname,
      birthdate: queryVariables.birthdate,
      insi_full: insi,
    };
    [error, response] = yield call(PatientService.selectOrCreateInsi, insiPayload);
    if (!error) {
      queryVariables['insi_id'] = _.get(response, 'data.returning.0.id');
    }
  }

  [error, response] = yield call(PatientService.patientsUpdate, {
    id: patient.id,
    patientPayload: _.omit(queryVariables, ['id']),
  });
  if (!error && response) {
    const links = [
      {
        guardian_id: patient.id,
        patient_id: patient.patient_id,
      },
    ];

    if (links.length > 0 && patient.is_tutor) {
      //TODO: doit on supprimer les patients si erreur ? ou les laisser créé et permettre une édition pour modifier / créer des tuteurs ?
      yield call(PatientService.linkTutors, links, patient.id);
    } else {
      yield call(PatientService.deleteTutors, patient.id);
    }
    yield put(LoaderActions.loaded());
    yield put(SnackActions.displayInfo('patient_modify_success'));
  } else {
    yield put(LoaderActions.loaded());
    yield put(SnackActions.displayError('patient_modify_error'));
  }
  yield put(PatientActions.patientsUpdateSuccess());
  yield put(PatientActions.patientsRequest());
  yield put(LoaderActions.loaded());
  yield put(AuthActions.setRedirectUrl('/patients'));
}

function* patientsCreateRequest({ patientData }) {
  yield put(LoaderActions.loading());

  const birthdate = !isNullOrWhitespace(patientData.birthdate) ? format(patientData.birthdate, 'yyyy-MM-dd') : null;
  const p = {
    is_tutor: _.get(patientData, 'is_tutor', false),
    gender: _.get(patientData, 'gender'),
    firstname: _.get(patientData, 'firstname'),
    lastname: _.get(patientData, 'lastname'),
    use_name: _.get(patientData, 'use_name'),
    security_number: _.get(patientData, 'security_number', ''),
    birthdate: birthdate,
    birthplace_id: _.get(patientData, 'birthplace_id.value') || _.get(patientData, 'birthplace_id'),
    birthplace: _.get(patientData, 'birthplace'),
    birthplace_postcode: _.get(patientData, 'birthplace_postcode'),
    city_id: _.get(patientData, 'city_id.value') || _.get(patientData, 'city_id'),
    city: _.get(patientData, 'city'),
    postcode: _.get(patientData, 'postcode'),
    phone_number: _.get(patientData, 'phone_number'),
    email: _.get(patientData, 'email'),
    medic_id: _.get(patientData, 'medic_id'),
    medical_teams_id: _.get(patientData, 'medical_teams_id'),
    language_id: _.get(patientData, 'language_id'),
  };

  const [error, response] = yield call(PatientService.create, p);
  if (error || !_.get(response, 'data')) {
    yield put(LoaderActions.loaded());
    yield put(SnackActions.displayError('create_patients_error'));
    return;
  }

  const { patients: resPatients } = response && response.data;
  const patientIdsList = resPatients.filter((p) => p.is_tutor === false).map((p) => p.id);

  yield put(PatientActions.patientsRequest(patientIdsList));

  yield put(PatientActions.patientsCreateSuccess());
  yield put(SnackActions.displayInfo('create_patient_success'));
  yield put(LoaderActions.loaded());
  yield put(AuthActions.setRedirectUrl('/patients'));
}

function* patientsDeleteRequest({ id }) {
  yield put(LoaderActions.loading());
  const result = yield call(PatientService.patientsDelete, id);
  if (result) {
    yield put(LoaderActions.loaded());
    yield put(SnackActions.displayInfo('patient_delete_success'));
  } else {
    yield put(LoaderActions.loaded());
    yield put(SnackActions.displayError('patient_delete_error'));
  }
  yield put(PatientActions.patientsDeleteSuccess(id));
  yield put(PatientActions.resetList());
  yield put(PatientActions.patientsRequest());
  yield put(LoaderActions.loaded());
}

function* patientsDeleteMultipleRequest({ ids }) {
  yield put(LoaderActions.loading());
  const [getError, getResponse] = yield call(PatientService.patients, ids);
  if (getError || !getResponse) {
    yield put(SnackActions.displayError('patient_delete_error'));
    return;
  }
  const { patients } = getResponse;
  let guardiansIdsToBeDeleted = patients.filter((el) => el.is_tutor === true).map((el) => el.id);
  let patientsIdsToBeDeleted = patients.filter((el) => el.is_tutor === false).map((el) => el.id);
  patients.forEach((p) => {
    if (p.is_tutor === true) {
      if (Array.isArray(p.tutors) && p.tutors.length) {
        // patient is a tutor
        // are patients' child's parents listed to be delted ?
        const tutors = p.tutors[0].patientByPatientId.tutorsByPatientId
          .map((el) => el.signatory.id)
          .filter((el) => !guardiansIdsToBeDeleted.includes(el));
        if (Array.isArray(tutors) && tutors.length === 0) {
          // there will be no more tutors after deleting tutor so delte the tutored patient
          const tutoredId = _.get(p, ['tutors', '0', 'patient_id']);
          if (!guardiansIdsToBeDeleted.includes(tutoredId)) {
            guardiansIdsToBeDeleted.push(tutoredId);
          }
        }
      }
    } else if (Array.isArray(p.tutorsByPatientId) && p.tutorsByPatientId.length) {
      // patient is tutored and attached to tutors so delete tutors
      const additionalGuardians = p.tutorsByPatientId.map((el) => el.signatory.id);
      guardiansIdsToBeDeleted = _.uniq([...guardiansIdsToBeDeleted, ...additionalGuardians]);
    }
  });
  let error, response;
  // delete patient infos
  if (guardiansIdsToBeDeleted && guardiansIdsToBeDeleted.length) {
    [error, response] = yield call(PatientService.guardiansDelete, guardiansIdsToBeDeleted);
  }

  // delete guardian infos
  if (patientsIdsToBeDeleted && patientsIdsToBeDeleted.length) {
    [error, response] = yield call(PatientService.patientsDelete, patientsIdsToBeDeleted);
  }

  // delete user not attached to patient

  if (error || !response) {
    yield put(SnackActions.displayError('patient_delete_error'));
    return;
  }
  if (response) {
    yield put(SnackActions.displayInfo('patient_delete_success'));
    yield put(PatientActions.patientsDeleteSuccess([...patientsIdsToBeDeleted, ...guardiansIdsToBeDeleted]));
  }
  yield put(LoaderActions.loaded());
}

function* checkIfEmailExistsInDb({ email, userId }) {
  const [error, response] = yield call(UserService.doesEmailExist, email, userId);
  if (error || !response) {
    yield put(PatientActions.patientsEmailFailure());
    return;
  }
  if (response) {
    const countPatients = _.get(response, 'patients_aggregate.aggregate.count', 0);
    const countMedics = _.get(response, 'medics_aggregate.aggregate.count', 0);
    yield put(PatientActions.patientsEmailSuccess(countPatients + countMedics === 0));
  }
}

function* patientDetailRequest({ id }) {
  const [error, response] = yield call(PatientService.patient, id);
  if (error) {
    yield put(SnackActions.displayError('patient_fetch_error'));
  } else {
    const { patients } = response;
    yield put(PatientActions.patientsSuccess(patients, true));
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  takeLatest(types.PATIENTS_REQUEST, patientsRequest),
  takeLatest(types.PATIENTS_UPDATE_REQUEST, patientsUpdateRequest),
  takeLatest(types.PATIENTS_CREATE_REQUEST, patientsCreateRequest),
  takeLatest(types.PATIENTS_DELETE_REQUEST, patientsDeleteRequest),
  takeLatest(types.PATIENTS_DELETE_MULTIPLE_REQUEST, patientsDeleteMultipleRequest),
  takeLatest(types.PATIENTS_EMAIL_REQUEST, checkIfEmailExistsInDb),
  takeLatest(types.PATIENT_DETAIL_REQUEST, patientDetailRequest),
];

import { countries } from 'countries-list';
import PropTypes from 'prop-types';
import React from 'react';

function CountryFlag({ countryCode, hideText = false, title }) {
  const localized = require('localized-countries')(require('localized-countries/data/fr'));
  const countryLabel = title || localized.get(countryCode);
  const text = !hideText ? countryLabel : '';
  return countryCode && countries[countryCode] ? (
    <div title={countryLabel}>
      {countries[countryCode].emoji} {text}
    </div>
  ) : (
    <div />
  );
}

CountryFlag.propTypes = { countryCode: PropTypes.string, title: PropTypes.string, hideText: PropTypes.bool };

export default CountryFlag;

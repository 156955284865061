import _ from 'lodash';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { LegalService } from '../../services';
import { LEGAL_PAGE_TYPES } from '../../ui/screens/legal/Legal';
import { MEDIC_ROLES, USER_ROLE } from '../../utils/calimed-enum';
import { LoaderActions } from '../loader';
import { SnackActions } from '../snackBar';
import { default as LegalsActions, types } from './actions';

function* rgpdRequest() {
  const [error, response] = yield call(LegalService.rgpd);
  if (response && !error) {
    const res = (response && response.rgpd) || {};
    yield put(LegalsActions.rgpdSuccess(res));
  } else {
    yield put(SnackActions.displayError('cgu_list_error'));
    yield put(LegalsActions.rgpdFailure());
  }
}

function* cguRequest({ cgu }) {
  const id = (cgu && cgu.id) || null;
  const [error, response] = id ? yield call(LegalService.cgu, id) : yield call(LegalService.cgu);
  if (error) {
    yield put(SnackActions.displayError('cgu_list_error'));
    yield put(LegalsActions.cguFailure());
    return;
  }
  const res = (response && response.cgu) || {};
  if (res) yield put(LegalsActions.cguSuccess(res));
  else {
    yield put(SnackActions.displayError('cgu_list_error'));
    yield put(LegalsActions.cguFailure());
  }
}

function* rgpdInsertRequest({ rgpdList }) {
  yield put(LoaderActions.loading());
  // isnert new CGU
  const resp = yield all(
    rgpdList
      .filter((c) => c.isNew)
      .map((c) => {
        const payload = {
          ..._.pick(c, ['loid', 'type', 'language_id', 'version']),
          version: c.file?.uid ? c.file?.uid.replace(/^rc-upload-/i, '') : c.version,
          roles: c.type === USER_ROLE.PATIENT ? [USER_ROLE.PATIENT] : MEDIC_ROLES,
        };
        return call(LegalService.insertRGPD, payload);
      }),
  );

  if (resp.find(([error, response]) => !response && !!error)) {
    yield put(SnackActions.displayError('rgpd_modify_error'));
    yield put(LoaderActions.loaded());
  } else {
    yield put(SnackActions.displayInfo('rgpd_modify_success'));
    yield put(LoaderActions.loaded());
    yield put(LegalsActions.RGPDRequest());
  }
}

function* cguInsertRequest({ cguList }) {
  yield put(LoaderActions.loading());
  // isnert new CGU
  const resp = yield all(
    cguList
      .filter((c) => c.isNew)
      .map((c) => {
        const payload = {
          ..._.pick(c, ['loid', 'type', 'language_id', 'version']),
          version: c.file?.uid ? c.file?.uid.replace(/^rc-upload-/i, '') : c.version,
          roles: c.type === USER_ROLE.PATIENT ? [USER_ROLE.PATIENT] : MEDIC_ROLES,
        };
        return call(LegalService.insertCGU, payload);
      }),
  );

  if (resp.find(([error, response]) => !response && !!error)) {
    yield put(SnackActions.displayError('cgu_modify_error'));
    yield put(LoaderActions.loaded());
  } else {
    yield put(SnackActions.displayInfo('cgu_modify_success'));
    yield put(LoaderActions.loaded());
    yield put(LegalsActions.cguRequest());
  }
}

function* legalsReset({ roles, type }) {
  yield put(LoaderActions.loading());
  const [error, response] = yield call(type === LEGAL_PAGE_TYPES.CGU ? LegalService.resetCGU : LegalService.resetRGPD, roles);
  if (response && !error) {
    yield put(SnackActions.displayInfo('proof_modify_success'));
  } else {
    yield put(SnackActions.displayError('proof_modify_error'));
  }
  yield put(LoaderActions.loaded());
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  takeLatest(types.CGU_REQUEST, cguRequest),
  takeLatest(types.RGPD_REQUEST, rgpdRequest),

  takeLatest(types.CGU_INSERT_REQUEST, cguInsertRequest),

  takeLatest(types.LEGALS_RESET, legalsReset),
  takeLatest(types.RGPD_INSERT_REQUEST, rgpdInsertRequest),
];

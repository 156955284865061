import { format } from 'date-fns';

import { methods } from '../utils';
import { patientQueries, patientsMutations } from './GraphQLRequest';
import { authenticatedMutation, authenticatedQuery, authenticatedService } from './middleware';

const { POST } = methods;

const routes = {
  createPatientsUsers: process.env.REACT_APP_BASE_API_URL + '/V2/patients',
};

export default {
  getPatients: () => authenticatedQuery(patientQueries.getPatients),
  patient: (id) => authenticatedQuery(patientQueries.patient, {}, { id, _gte: format(new Date(), 'yyyy-MM-dd') }),
  patients: (ids) =>
    authenticatedQuery(patientQueries.patients, {}, { ids: ids ? (Array.isArray(ids) ? ids : [ids]) : null, _gte: format(new Date(), 'yyyy-MM-dd') }),
  linkTutors: (links, tutor_id) =>
    authenticatedMutation(
      patientsMutations.linkTutors(),
      {},
      { links: Array.isArray(links) && links.map((l) => ({ guardian_id: l.guardian_id, patient_id: l.patient_id })), guardianId: tutor_id },
    ),
  deleteTutors: (tutor_id) => authenticatedMutation(patientsMutations.deleteTutors(tutor_id)),
  create: (payload) => authenticatedService(POST, routes.createPatientsUsers, { payload }, {}, {}),
  patientsUpdate: (patient) => authenticatedMutation(patientsMutations.patientsUpdate(), {}, patient),
  patientsDelete: (_patientsIds) => authenticatedMutation(patientsMutations.patientsDelete(), {}, { _patientsIds }),
  guardiansDelete: (_guardiansIds) => authenticatedMutation(patientsMutations.guardiansDelete(), {}, { _guardiansIds }),
  produceMail: (mail) => authenticatedMutation(patientsMutations.produceMail(mail)),
  updatePassword: (patient) =>
    authenticatedMutation(patientsMutations.updatePassword(), {}, { id: patient.user_id, password: patient.user.password }),
  selectOrCreateInsi: (insiPayload) => authenticatedMutation(patientsMutations.selectOrCreateInsi(), {}, { insiPayload }),
};

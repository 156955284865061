import { createAction } from '../../utils';

// Types
export const types = {
  CLINICS_GROUPS_REQUEST: 'CLINICS_GROUPS_REQUEST',
  CLINICS_GROUPS_FAILURE: 'CLINICS_GROUPS_FAILURE',
  CLINICS_GROUPS_SUCCESS: 'CLINICS_GROUPS_SUCCESS',

  CLINICS_GROUP_UPDATE_REQUEST: 'CLINICS_GROUP_UPDATE_REQUEST',
  CLINICS_GROUP_UPDATE_FAILURE: 'CLINICS_GROUP_UPDATE_FAILURE',
  CLINICS_GROUP_UPDATE_SUCCESS: 'CLINICS_GROUP_UPDATE_SUCCESS',

  CLINICS_GROUP_CREATE_REQUEST: 'CLINICS_GROUP_CREATE_REQUEST',
  CLINICS_GROUP_CREATE_FAILURE: 'CLINICS_GROUP_CREATE_FAILURE',
  CLINICS_GROUP_CREATE_SUCCESS: 'CLINICS_GROUP_CREATE_SUCCESS',

  CLINICS_GROUP_DELETE_REQUEST: 'CLINICS_GROUP_DELETE_REQUEST',
  CLINICS_GROUPS_DELETE_REQUEST: 'CLINICS_GROUPS_DELETE_REQUEST',
  CLINICS_GROUPS_DELETE_SUCCESS: 'CLINICS_GROUPS_DELETE_SUCCESS',
};

// Actions
export default {
  /* CREATE */
  clinicsGroupCreateRequest: (clinicsGroup) => createAction(types.CLINICS_GROUP_CREATE_REQUEST, { clinicsGroup }),
  clinicsGroupCreateSuccess: () => createAction(types.CLINICS_GROUP_CREATE_SUCCESS),

  /* UPDATE */
  clinicsGroupUpdateRequest: (clinicsGroup = {}) => createAction(types.CLINICS_GROUP_UPDATE_REQUEST, { clinicsGroup }),
  clinicsGroupUpdateSuccess: () => createAction(types.CLINICS_GROUP_UPDATE_SUCCESS),

  /** REQUEST */
  clinicsGroupsRequest: (id = null) => createAction(types.CLINICS_GROUPS_REQUEST, id),
  clinicsGroupsSuccess: (clinicsGroup) => createAction(types.CLINICS_GROUPS_SUCCESS, { clinicsGroup }),
  clinicsGroupsFailure: () => createAction(types.CLINICS_GROUPS_FAILURE),

  clinicsGroupDeleteRequest: (id) => createAction(types.CLINICS_GROUP_DELETE_REQUEST, { id }),
  clinicsGroupsDeleteRequest: (listIds) => createAction(types.CLINICS_GROUPS_DELETE_REQUEST, { listIds }),
  clinicsGroupsDeleteSuccess: (id) => createAction(types.CLINICS_GROUPS_DELETE_SUCCESS, { id }),
};

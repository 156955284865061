import _ from 'lodash';
import { call, put, takeLatest } from 'redux-saga/effects';

import { ClinicsService } from '../../services';
import { AuthActions } from '../auth';
import { LoaderActions } from '../loader';
import { SnackActions } from '../snackBar';
import { default as ClinicActions, types } from './actions';

function* clinicsRequest({ filters }) {
  const id = (filters && filters.id) || null;
  const [error, data] = id ? yield call(ClinicsService.clinic, id) : yield call(ClinicsService.clinics);
  if (error && !data.clinics) {
    yield put(SnackActions.displayError('clinic_list_error'));
    yield put(ClinicActions.clinicsFailure());
  } else {
    yield put(ClinicActions.clinicsSuccess(data.clinics));
  }
}

function* clinicsUpdateRequest({ clinic }) {
  yield put(LoaderActions.loading());
  const idMedic = clinic.medic_id;
  const idClinic = clinic.id;
  const clinicPayload = {
    id: idClinic,
    clinicPayload: _.pick(clinic, ['name', 'address', 'postcode', 'city', 'city_id', 'created_at', 'updated_at', 'clinic_medics', 'finess']),
    clinicMedics:
      (idMedic &&
        idMedic.map((idMap) => ({
          medic_id: idMap,
          clinic_id: idClinic,
        }))) ||
      undefined,
  };
  const result = yield call(ClinicsService.clinicsUpdate, clinicPayload);
  if (result) {
    yield put(SnackActions.displayInfo('clinic_modify_success'));
    yield put(ClinicActions.clinicsUpdateSuccess({}));
    yield put(ClinicActions.clinicsRequest({}));
    yield put(AuthActions.setRedirectUrl('/clinics'));
  } else {
    yield put(SnackActions.displayError('clinic_modify_error'));
  }
  yield put(LoaderActions.loaded());
}

function* clinicsCreateRequest({ filters }) {
  yield put(LoaderActions.loading());
  const idMedic = filters.medic_id;
  const clinicPayload = {
    ..._.pick(filters, ['name', 'address', 'postcode', 'city', 'city_id', 'created_at', 'updated_at', 'clinic_medics', 'finess']),
    clinic_medics: idMedic &&
      idMedic.length && {
        data: idMedic.map((idMap) => ({
          medic_id: idMap,
        })),
      },
  };

  const result = yield call(ClinicsService.clinicsCreate, { clinicPayload });
  if (result) {
    yield put(SnackActions.displayInfo('clinic_create_success'));
    yield put(ClinicActions.clinicsCreateSuccess({}));
    yield put(ClinicActions.clinicsRequest({ id: filters.clinics }));
    yield put(AuthActions.setRedirectUrl('/clinics'));
  } else {
    yield put(SnackActions.displayError('clinic_create_error'));
  }
  yield put(LoaderActions.loaded());
}

function* clinicsDeleteRequest({ id }) {
  yield put(LoaderActions.loading());
  const result = yield call(ClinicsService.clinicsDelete, id);
  if (result) {
    yield put(LoaderActions.loaded());
    yield put(SnackActions.displayInfo('clinic_delete_success'));
  } else {
    yield put(LoaderActions.loaded());
    yield put(SnackActions.displayError('clinic_delete_error'));
  }
  yield put(ClinicActions.clinicsDeleteSuccess(id));
  yield put(ClinicActions.clinicsRequest({}));
  yield put(LoaderActions.loaded());
}

function* clinicsDeleteMultipleRequest({ id }) {
  yield put(LoaderActions.loading());
  const result = yield id.map((i) => call(ClinicsService.clinicsDelete, i));
  if (result) {
    yield put(LoaderActions.loaded());
    yield put(SnackActions.displayInfo('clinic_delete_success'));
  } else {
    yield put(LoaderActions.loaded());
    yield put(SnackActions.displayError('clinic_delete_error'));
  }
  yield id.map((i) => put(ClinicActions.clinicsDeleteSuccess(i)));
  yield put(LoaderActions.loaded());
  yield put(ClinicActions.clinicsRequest({}));
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  takeLatest(types.CLINICS_REQUEST, clinicsRequest),
  takeLatest(types.CLINICS_UPDATE_REQUEST, clinicsUpdateRequest),
  takeLatest(types.CLINICS_CREATE_REQUEST, clinicsCreateRequest),
  takeLatest(types.CLINICS_DELETE_REQUEST, clinicsDeleteRequest),
  takeLatest(types.CLINICS_DELETE_MULTIPLE_REQUEST, clinicsDeleteMultipleRequest),
];

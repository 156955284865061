import { format, startOfMonth, subMonths } from 'date-fns';

import { SURVEY_STATUS } from '../utils/calimed-enum';
import { authenticatedMutation, authenticatedQuery } from './middleware';

/**
 * GRAPHQL QUERIES
 */
const queries = {
  clinic: `
    query GetClinic($id: Int!) {
      clinics(where: {id: {_eq: $id}}) {
        id
        name
        finess
        address
        postcode
        city
        city_id
        created_at
        updated_at
        cityByCityId {
          id
          name
          postcode
        }
        clinic_medics {
          medic_id
          clinic_id
          medic {
            id
            firstname
            lastname
          }
        }
        surveys_aggregate(where: {
          status: {_in: ["${SURVEY_STATUS.ACCEPTED}", "${SURVEY_STATUS.COUNTERSIGNED}"]}, 
          is_signed: {_eq: true}, 
          updated_at: {
            _gte: "${format(subMonths(startOfMonth(new Date()), 1), 'yyyy/MM/dd')}", 
            _lt: "${format(startOfMonth(new Date()), 'yyyy/MM/dd')}"
          }
        }
      ) {
          aggregate {
            count
          }
        }
      }
    }
  `,
  clinics: `
    query GetClinics {
      clinics {
        id
        updated_at
        created_at
        name
        finess
        address
        postcode
        city
        cityByCityId {
          id
          name
          postcode
        }
        clinic_medics {
          medic_id
          clinic_id
          medic {
            id
            firstname
            lastname
          }
        }
        surveys_aggregate(
          where: {
            status: {_in: ["${SURVEY_STATUS.ACCEPTED}", "${SURVEY_STATUS.COUNTERSIGNED}"]},
            is_signed: {_eq: true}, 
            updated_at: {
              _gte: "${format(subMonths(startOfMonth(new Date()), 1), 'yyyy/MM/dd')}", 
              _lt: "${format(startOfMonth(new Date()), 'yyyy/MM/dd')}"
            }
          }
        ) {
          aggregate {
            count
          }
        }
      }
    }
  `,
};
/**
 * GRAPHQL MUTATION
 */

const mutations = {
  clinicsCreate: `
    mutation ClinicsCreate($clinicPayload: [clinics_insert_input!]!) {
      insert_clinics(objects: $clinicPayload) {
        returning {
          id
          updated_at
          created_at
          name
          finess
          address
          postcode
          city
          cityByCityId {
            id
            name
            postcode
          }
        }
      }
    }
  `,
  clinicsUpdate: `
    mutation ClinicsUpdate(
      $id: Int
      $clinicPayload: clinics_set_input
      $clinicMedics: [clinic_medics_insert_input!]!
    ) {
      update_clinics(where: { id: { _eq: $id } }, _set: $clinicPayload) {
        returning {
          id
          updated_at
          created_at
          name
          finess
          address
          postcode
          city
          cityByCityId {
            id
            name
            postcode
          }
        }
      }
      delete_clinic_medics(where: { clinic: { id: { _eq: $id } } }) {
        returning {
          medic_id
        }
      }
      insert_clinic_medics(objects: $clinicMedics) {
        returning {
          medic_id
          clinic_id
        }
      }
    }
  `,
  clinicsUpdateGroupId: `
    mutation clinicsUpdateGroupId(
      $groupId: Int!
      $clinicsIds: [Int]
    ) {
      update_clinics(where: { clinic_group_id: { _eq: $groupId } }, _set: { clinic_group_id: null }) {
        returning {
          id
          clinic_group_id
        }
      }
      update2: update_clinics(where: { id: { _in: $clinicsIds } }, _set: {clinic_group_id: $groupId}) {
        returning {
          id
          clinic_group_id
        }
      }
    }
  `,
  clinicsDelete: `
    mutation ClinicDelete($id: Int!) {
      delete_clinic_medics(where: { clinic_id: { _eq: $id } }) {
        returning {
          medic_id
        }
      }
      delete_questions(where: { step: { survey: { clinic_id: { _eq: $id } } } }) {
        returning {
          step_id
        }
      }
      delete_steps(where: { survey: { clinic_id: { _eq: $id } } }) {
        returning {
          survey_id
        }
      }
      delete_surveys(where: { clinic_id: { _eq: $id } }) {
        returning {
          clinic_id
        }
      }
      delete_clinics(where: { id: { _eq: $id } }) {
        returning {
          id
        }
      }
    }
  `,
};

export default {
  clinic: (id) => authenticatedQuery(queries.clinic, {}, { id }),
  clinics: () => authenticatedQuery(queries.clinics),
  clinicsCreate: (clinic) => {
    return authenticatedMutation(mutations.clinicsCreate, {}, clinic);
  },
  clinicsUpdate: (clinic) => {
    return authenticatedMutation(mutations.clinicsUpdate, {}, clinic);
  },
  clinicsDelete: (id) => {
    return authenticatedMutation(mutations.clinicsDelete, {}, { id });
  },
  clinicsUpdateGroupId: (payload) => {
    return authenticatedMutation(mutations.clinicsUpdateGroupId, {}, { clinicsIds: payload.clinics, groupId: payload.groupId });
  },
};

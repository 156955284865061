/**
 * GRAPHQL QUERIES
 */
export default {
  fetchAll: `
    query GetLanguages {
      languages(order_by: { id: asc }) {
        id
        language_code 
        name
        date_format 
        country_code_flag
      }
    }
  `,
  request: `
    query GetLanguageById($id: Int!) {
      languages(order_by: {id: asc}, where: {id: {_eq: $id}}) {
        id
        language_code
        name
        name_in_native_language
        date_format
        currency
        country_code_flag
      }
    }  
`,
};

import _ from 'lodash';

import { authenticatedMutation, authenticatedQuery } from './middleware';
/**
 * GRAPHQL QUERIES
 */
const queries = {
  request: `
    query RequestSurveyModelFiles {
      survey_model_files {
        id
        created_at
        updated_at
        loid
        is_default
        name
        language_id
      }
    }
  `,
  requestOne: `
    query RequestOneSurveyModelFiles($id: Int!) {
      survey_model_files(where: {id: {_eq: $id}}) {
        id
        created_at
        updated_at
        loid
        is_default
        hash
        name
        mime_type
        language_id
      }
    }
`,
};
/**
 * GRAPHQL MUTATION
 *
 */
const mutations = {
  insert: `
    mutation InsertSurveyModelFiles($payload: [survey_model_files_insert_input!]!) {
      insert_survey_model_files(objects: $payload) {
        returning {
          id
        }
      }
    }
  `,
  update: `
    mutation UpdateSurveyModelFiles( $id: Int!, $payload: survey_model_files_set_input!) {
      update_survey_model_files(where: {id: {_eq: $id}}, _set: $payload) {
        returning {
          id
        }
      }
    }
  `,
  delete: `
    mutation DeleteSurveyModelFiles($id: [Int!]!) {
      delete_survey_model_files(where: {id: {_in: $id}}) {
        returning {
          id
        }
      }
    }
  `,
  makeDefault: `
    mutation MakeDefaultSurveyModelFiles($id: Int!) {
      removeDefault: update_survey_model_files(where: {}, _set: {is_default: false}) {
        returning {
          id
        }
      }
      update_survey_model_files(where: {id: {_eq: $id}}, _set: {is_default: true}) {
        returning {
          id
        }
      }
    }
  `,
};

export default {
  request: () => authenticatedQuery(queries.request),
  requestOne: (id) => authenticatedQuery(queries.requestOne, {}, { id }),
  insert: (payload) => authenticatedMutation(mutations.insert, {}, { payload }),
  update: (payload) => authenticatedMutation(mutations.update, {}, { id: payload.id, payload: _.omit(payload, ['id']) }),
  delete: (id) => authenticatedMutation(mutations.delete, {}, { id }),
  makeDefault: (id) => authenticatedMutation(mutations.makeDefault, {}, { id }),
};

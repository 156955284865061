import { connect } from 'react-redux';

import { QuestionTypesActions } from '../../../redux/questionTypes';
import QuestionTypesCreate from './QuestionTypesCreate';

const mapStateToProps = ({ questionTypes }) => ({
  questionTypesList: questionTypes.list,
});

const mapDispatchToProps = (dispatch) => ({
  questionTypesCreateRequest: (creation) => dispatch(QuestionTypesActions.questionTypesCreateRequest(creation)),
  questionTypesUpdateRequest: (questionType) => dispatch(QuestionTypesActions.questionTypesUpdateRequest(questionType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuestionTypesCreate);

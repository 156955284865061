import bcrypt from 'bcryptjs';
import _ from 'lodash';

import { userMutations, userQueries } from './GraphQLRequest';
import { authenticatedQuery } from './middleware';

/*
 *  SERVICES
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  doesEmailExist: (email, userId = null) => authenticatedQuery(userQueries.doesEmailExist, {}, getValuesDoesEmailExists(email, userId)),
  update: (id, userData) => authenticatedQuery(userMutations.update, {}, { id, userData: mapUserdata(userData) }),
  getAll: () => authenticatedQuery(userQueries.getAll, {}, {}),
};

export const getValuesDoesEmailExists = (email, userId) => {
  let wherePatient = { email: { _ilike: email } };
  let whereMedic = { email: { _ilike: email } };
  if (userId && userId !== null) {
    wherePatient['user_id'] = { _neq: userId };
    whereMedic['user_id'] = { _neq: userId };
  }
  return {
    wherePatient,
    whereMedic,
  };
};

const mapUserdata = (userData) => {
  let newUser = { ...userData };
  if (!!newUser.password) {
    const pwdSalt = process.env.PWD_SALT || '10';
    newUser.password = bcrypt.hashSync(newUser.password, parseInt(pwdSalt));
  }
  newUser = _.pick(newUser, [
    'username',
    'password',
    'renew_pwd',
    'accepted_cgu',
    'accepted_rgpd',
    'edit_info',
    'failed_login_counter',
    'last_login_attempt_date',
    'last_login_success_date',
    'role',
  ]);
  return newUser;
};

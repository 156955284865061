import { call, put, takeLatest } from 'redux-saga/effects';

import { QuestionBasicsService } from '../../services';
import questionBasicsService from '../../services/questionBasicsService';
import { AuthActions } from '../auth';
import { LoaderActions } from '../loader';
import { SnackActions } from '../snackBar';
import { default as QuestionBasicsActions, types } from './actions';

function* questionBasicsTypesRequest({ questionBasics }) {
  const id = (questionBasics && questionBasics.id) || null;
  const [error, response] = id ? yield call(QuestionBasicsService.questionBasic, id) : yield call(QuestionBasicsService.questionBasicsTypes);
  if (error) {
    yield put(SnackActions.displayError('question_basics_list_error'));
    yield put(QuestionBasicsActions.questionBasicsFailure());
    return;
  }
  const { question_basics_types } = response || {};
  if (question_basics_types) yield put(QuestionBasicsActions.questionBasicsTypesSuccess(question_basics_types));
  else {
    yield put(SnackActions.displayError('question_basics_list_error'));
  }
}

function* questionBasicsRequest({ questionBasics }) {
  const id = (questionBasics && questionBasics.id) || null;
  const [error, response] = id ? yield call(QuestionBasicsService.questionBasic, id) : yield call(QuestionBasicsService.questionBasics);
  if (error) {
    yield put(SnackActions.displayError('question_basics_list_error'));
    yield put(QuestionBasicsActions.questionBasicsFailure());
    return;
  }
  const { question_basics } = response || {};
  if (question_basics) yield put(QuestionBasicsActions.questionBasicsSuccess(question_basics));
  else {
    yield put(SnackActions.displayError('question_basics_list_error'));
  }
}

function* questionBasicsTypesSurveysRequest({ questionBasics }) {
  const id = (questionBasics && questionBasics.id) || null;
  const [error, response] = id ? yield call(QuestionBasicsService.questionBasic, id) : yield call(QuestionBasicsService.questionBasicsTypesSurveys);
  if (error) {
    yield put(SnackActions.displayError('question_basics_list_error'));
    yield put(QuestionBasicsActions.questionBasicsFailure());
    return;
  }
  const { question_basic_types_surveys } = response || {};
  if (question_basic_types_surveys) yield put(QuestionBasicsActions.questionBasicsTypesSurveysSuccess(question_basic_types_surveys));
  else {
    yield put(SnackActions.displayError('question_basics_list_error'));
  }
}

function* questionBasicsTypesSurveysUpdateRequest({ questionBasics, questionBasicsText }) {
  yield put(LoaderActions.loading());
  try {
    if (questionBasicsText.length) {
      const { id, initialTypes, survey_type_id } = questionBasics;
      const [clearSomeError, clearSomeResponse] =
        initialTypes && survey_type_id && initialTypes.length > survey_type_id.length
          ? yield call(
              questionBasicsService.questionBasicsClearSome,
              id,
              initialTypes.filter((i) => !survey_type_id.includes(i)),
            )
          : [null, 'ok'];
      if (clearSomeError || !clearSomeResponse) {
        throw new Error('Error');
      }
      const [updateError, updateResponse] = yield call(
        questionBasicsService.questionBasicsUpdate,
        questionBasics.id,
        questionBasicsText.filter((q) => q !== null),
      );
      if (updateError || !updateResponse) {
        throw new Error('Error');
      }
    } else {
      const [clearError, clearResponse] = yield call(QuestionBasicsService.questionBasicsClear, questionBasics.id);
      if (clearError || !clearResponse) {
        throw new Error('Error');
      }
    }

    yield call(questionBasicsTypesSurveysRequest, {});
    yield call(questionBasicsRequest, {});
    yield put(SnackActions.displayInfo('question_basics_modify_success'));
    yield put(LoaderActions.loaded());
    yield put(AuthActions.setRedirectUrl('/basics'));
  } catch (e) {
    yield put(SnackActions.displayError('question_basics_modify_error'));
    yield put(QuestionBasicsActions.questionBasicsTypesSurveysFailure());
    yield put(LoaderActions.loaded());
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  takeLatest(types.QUESTION_BASICS_REQUEST, questionBasicsRequest),
  takeLatest(types.QUESTION_BASICS_TYPES_REQUEST, questionBasicsTypesRequest),
  takeLatest(types.QUESTION_BASICS_TYPES_SURVEY_REQUEST, questionBasicsTypesSurveysRequest),
  takeLatest(types.QUESTION_BASICS_TYPES_SURVEY_UPDATE_REQUEST, questionBasicsTypesSurveysUpdateRequest),
];

import './medicalTeams.css';

import { Avatar, Card, List, Row } from 'antd';
import React, { useState } from 'react';

import { DeleteButton, ModifyButton } from '../../components/button';

export const ACTIONS = {
  PUBLISH: 'PUBLISH',
  HIDE: 'HIDE',
  DELETE: 'DELETE',
  RESTORE: 'RESTORE',
};

const DataTable = ({ items, onDelete, onModify }) => {
  const [key, setKey] = useState(0);

  const data = items || [];

  if (data.length !== key) {
    setKey(data.length);
  }

  const renderCard = (item) => {
    const newItem = item.medical_teams_medics.map((m, index) => (
      <span key={`medical-teams-item-${index}`} style={{ marginRight: 7 }}>
        <Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf', marginBottom: 3 }} title={`${m.medic.firstname} ${m.medic.lastname}`}>
          {m.medic.firstname.charAt(0)}
          {m.medic.lastname.charAt(0)}
        </Avatar>
      </span>
    ));

    if (newItem.length > 3) {
      return (
        <Row style={{ alignItems: 'center' }}>
          {newItem[0]}
          {newItem[1]}
          {newItem[2]}
          {
            <span>
              <Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf', marginBottom: 3 }}>+{item.medical_teams_medics.length - 3}</Avatar>
            </span>
          }
        </Row>
      );
    } else {
      return <Row style={{ alignItems: 'center' }}>{newItem}</Row>;
    }
  };

  return (
    <List
      grid={{
        gutter: 16,
        xs: 1,
        sm: 2,
        md: 3,
        lg: 4,
        xl: 4,
        xxl: 5,
      }}
      dataSource={data.map((item) => ({ ...item, key: item.id }))}
      renderItem={(item, index) => (
        <List.Item key={`medical-team-list-item-${index}`}>
          <Card title={item.title} actions={[<ModifyButton modify={() => onModify(item)} />, <DeleteButton deletion={() => onDelete(item)} />]}>
            {renderCard(item)}
          </Card>
        </List.Item>
      )}
    />
  );
};

export default DataTable;

import { connect } from 'react-redux';

import { LanguageActions } from '../../../../redux/languages';
import MsWordTemplateForm from './MsWordTemplateForm';

const mapStateToProps = ({ auth, language }) => {
  return {
    authorizationToken: `${auth.token_type} ${auth.token}`,
    languageList: Object.values(language.list || {}),
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchAllLanguages: () => dispatch(LanguageActions.fetchAll()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MsWordTemplateForm);

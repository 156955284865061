import { connect } from 'react-redux';

import { AuthActions } from '../../../redux/auth';
import { ClinicActions } from '../../../redux/clinics';
import { LanguageActions } from '../../../redux/languages';
import { MedicalTeamActions } from '../../../redux/medicalTeams';
import { MedicActions } from '../../../redux/medics';
import { PatientActions } from '../../../redux/patients';
import { RoleActions } from '../../../redux/roles';
import { SpecialityActions } from '../../../redux/specialities';
import { USER_ROLE } from '../../../utils/calimed-enum';
import MedicsCreate from './MedicsCreate';

const mapStateToProps = ({ auth, medics, specialities, clinics, medicalTeams, roles, language }) => ({
  token: auth.token || '',
  medicsList: medics.list || {},
  specialities: Object.values(specialities.list || {}),
  clinics: Object.values(clinics.list),
  medicalTeams: Object.values(medicalTeams.list || {}),
  rolesOptions: Object.values(roles.list || {}).map((el) => ({ value: el.id, key: el.value, label: el.name })),
  languageList: Object.values(language.list || {}),
});

const mapDispatchToProps = (dispatch) => ({
  patientsRequest: (filter) => dispatch(PatientActions.patientsRequest(filter)),
  medicsRequest: (filter) => dispatch(MedicActions.medicsRequest(filter)),
  rolesRequest: (filter) => dispatch(RoleActions.rolesRequest(filter)),
  specialitiesRequest: (filter) => dispatch(SpecialityActions.specialitiesRequest(filter)),
  medicsCreateRequest: (creation) => dispatch(MedicActions.medicsCreateRequest(creation)),
  medicsUpdateRequest: (medic) => dispatch(MedicActions.medicsUpdateRequest(medic)),
  clinicsRequest: (filter) => dispatch(ClinicActions.clinicsRequest(filter)),
  medicalTeamsRequest: () => dispatch(MedicalTeamActions.medicalTeamsRequest()),
  fetchAllLanguages: () => dispatch(LanguageActions.fetchAll()),
  resetUserPswdAttemptCounter: (id) => dispatch(AuthActions.updateUser({ id: id, failed_login_counter: 0 }, null, USER_ROLE.MEDIC)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MedicsCreate);

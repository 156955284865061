import { Popover, Space, Tag } from 'antd';
import React from 'react';

export default function SurveyTags({ type }) {
  return (
    [
      'counter-sign',
      'resume-survey-mail',
      'resume-started-survey-mail',
      'patient-denied-consent',
      'signed-consent-patient',
      'signed-consent-relaunch',
      'warning-survey-medic-mail',
      'medic-denied-consent',
      'withdrawal-consent-medic',
      'withdrawal-consent-delete',
      'withdrawal-consent-patient',
      'withdrawal-consent-relaunch',
    ].includes(type) && (
      <Space size={[0, 8]} wrap>
        <Popover content={'True si le patient est rattachés à une équipe clinical study'}>
          <Tag color="red">isClinicalStudy</Tag>
        </Popover>
        <Popover content={"Nom de l'étude. ex: Toolbar"}>
          <Tag color="red">clinicalTrial.name</Tag>
        </Popover>
        <Popover content={'Dr LASTNAME'}>
          <Tag color="magenta">referentMedic.titledName</Tag>
        </Popover>
        <Popover content={'06 12 34 56 78'}>
          <Tag color="magenta">referentMedic.local_phone_number</Tag>
        </Popover>
        <Popover content={'tuteur ou patient'}>
          <Tag color="red">signatory.userType</Tag>
        </Popover>
        <Popover content={'Madame USE_NAME'}>
          <Tag color="magenta">signatory.titledName</Tag>
        </Popover>
        <Popover content={'Madame Isabelle USE_NAME'}>
          <Tag color="magenta">signatory.fullTitledName</Tag>
        </Popover>
        <Popover content={'06 12 34 56 78'}>
          <Tag color="magenta">signatory.local_phone_number</Tag>
        </Popover>
        <Popover content={'true if tutor'}>
          <Tag color="magenta">signatory.is_tutor </Tag>
        </Popover>
        <Popover content={'Monsieur USE_NAME'}>
          <Tag color="volcano">patient.titledName</Tag>
        </Popover>
        <Popover content={'Monsieur Pierre USE_NAME'}>
          <Tag color="volcano">patient.fullTitledName</Tag>
        </Popover>
        <Popover content={'tuteur ou patient'}>
          <Tag color="red">patient.userType</Tag>
        </Popover>
        <Popover content={'Consentement éclairé'}>
          <Tag color="lime">survey.title</Tag>
        </Popover>
        <Popover content={"Message d'erreur em cas de refus"}>
          <Tag color="lime">survey.error_message</Tag>
        </Popover>
        <Popover content={'Date de signature'}>
          <Tag color="lime">survey.signatureDate</Tag>
        </Popover>
        <Popover content={'Date de retrait (Etude clinique)'}>
          <Tag color="lime">survey.withdrawalDate</Tag>
        </Popover>
        <Popover content={'Nb de jours avant suppression si retrait etude clinique'}>
          <Tag color="lime">survey.daysBeforeDelete</Tag>
        </Popover>
        <Popover content={"Date de l'intervention. Ex: 23/02/2023"}>
          <Tag color="lime">survey.intervention.date</Tag>
        </Popover>
        <Popover content={'type de consentement avec votre. ex: votre acte chirurgical'}>
          <Tag color="lime">survey.survey_type.label_your</Tag>
        </Popover>
        <Popover content={'type de consentement avec un. ex: un acte chirurgical'}>
          <Tag color="lime">survey.survey_type.label_a</Tag>
        </Popover>
        <Popover content={"Nom de l'etablissement. ex: Clinique"}>
          <Tag color="lime">survey.clinic.name</Tag>
        </Popover>
        <Popover content={"Addresse de l'etablissement. ex: 120 avenue des Champs Elysée"}>
          <Tag color="lime">survey.clinic.address</Tag>
        </Popover>
        <Popover content={"Code postale de l'etablissement. ex: 75000"}>
          <Tag color="lime">survey.clinic.postcode</Tag>
        </Popover>
        <Popover content={"Ville de l'etablissement. ex: Paris"}>
          <Tag color="lime">survey.clinic.city</Tag>
        </Popover>

        <Popover content={"url permettant d'arriver sur la page login avec le username du patient"}>
          <Tag color="orange">patientLoginUrl</Tag>
        </Popover>
        <Popover content={"url permettant au referent medic d'arriver sur la page du patient"}>
          <Tag color="orange">medicCounterSignUrl</Tag>
        </Popover>
      </Space>
    )
  );
}

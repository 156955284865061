import { call, put, takeLatest } from 'redux-saga/effects';

import cronService from '../../services/cronService';
import { LoaderActions } from '../loader';
import { SnackActions } from '../snackBar';
import { default as CronActions, types } from './actions';

function* cronConfigRequest() {
  yield put(LoaderActions.loading());

  const [error, response] = yield call(cronService.getConfig);
  if (!error && response) yield put(CronActions.cronConfigSuccess(response));
  else yield put(SnackActions.displayError('cron_config_error'));
  yield put(LoaderActions.loaded());
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [takeLatest(types.CRON_CONFIG_REQUEST, cronConfigRequest)];

import { createAction } from '../../utils';

// Types
export const types = {
  STEPS_REQUEST: 'STEPS_REQUEST',
  STEPS_FAILURE: 'STEPS_FAILURE',
  STEPS_SUCCESS: 'STEPS_SUCCESS',

  STEPS_UPDATE_REQUEST: 'STEPS_UPDATE_REQUEST',
  STEPS_UPDATE_FAILURE: 'STEPS_UPDATE_FAILURE',
  STEPS_UPDATE_SUCCESS: 'STEPS_UPDATE_SUCCESS',

  STEPS_CREATE_REQUEST: 'STEPS_CREATE_REQUEST',
  STEPS_CREATE_FAILURE: 'STEPS_CREATE_FAILURE',
  STEPS_CREATE_SUCCESS: 'STEPS_CREATE_SUCCESS',

  STEPS_DELETE_REQUEST: 'STEPS_DELETE_REQUEST',
  STEPS_DELETE_SUCCESS: 'STEPS_DELETE_SUCCESS',

  STEP_CLEAR: 'STEP_CLEAR',
};

// Actions
export default {
  /* CREATE */
  stepsCreateRequest: (filters = {}) => createAction(types.STEPS_CREATE_REQUEST, { filters }),
  stepsCreateSuccess: (steps) => createAction(types.STEPS_CREATE_SUCCESS, { steps }),

  /* UPDATE */
  stepsUpdateRequest: (step = {}) => createAction(types.STEPS_UPDATE_REQUEST, { step }),
  stepsUpdateSuccess: (steps) => createAction(types.STEPS_UPDATE_SUCCESS, { steps }),

  /** REQUEST */
  stepsRequest: (filters = {}) => createAction(types.STEPS_REQUEST, { filters }),
  stepsSuccess: (steps) => createAction(types.STEPS_SUCCESS, { steps }),
  stepsFailure: () => createAction(types.STEPS_FAILURE),

  stepsDeleteRequest: (id) => createAction(types.STEPS_DELETE_REQUEST, { id }),
  stepsDeleteSuccess: (id) => createAction(types.STEPS_DELETE_SUCCESS, { id }),

  stepClear: () => createAction(types.STEP_CLEAR),
};

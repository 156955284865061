import './style.css';

import classNames from 'classnames';
import React from 'react';
import { IconContext } from 'react-icons';
import { AiOutlineCloseCircle } from 'react-icons/ai';

const Modal = ({ isOpen = true, modalClosed, title, disable, scrollable, children, titleClassName, fullWidth, fullHeight }) => {
  const handleOnExit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (modalClosed) modalClosed(event);
  };

  return (
    <div className={`ec-backdrop ${isOpen ? '' : 'd-none'}`}>
      <div
        className={classNames('ec-modal', {
          'full-width': fullWidth,
          'full-height': fullHeight,
        })}
      >
        <div className={classNames({ 'ec-modal-scrollable': scrollable })}>
          <div className={titleClassName || 'ec-modal-contain'}>
            {title && <h1> {title} </h1>}
            {!disable && (
              <button style={{ cursor: 'pointer' }} onClick={handleOnExit} type="button">
                <IconContext.Provider value={{ color: '#00aecb', size: '30px' }}>
                  <div>
                    <AiOutlineCloseCircle />
                  </div>
                </IconContext.Provider>
              </button>
            )}
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;

import { connect } from 'react-redux';

import { QuestionTypesActions } from '../../../redux/questionTypes';
import QuestionTypes from './QuestionTypes';

const mapStateToProps = ({ questionTypes }) => ({
  list: questionTypes.list || {},
  loading: questionTypes.loading,
});

const mapDispatchToProps = (dispatch) => ({
  questionTypesRequest: (filter) => dispatch(QuestionTypesActions.questionTypesRequest(filter)),
  questionTypesDeleteRequest: (questionType) => dispatch(QuestionTypesActions.questionTypesDeleteRequest(questionType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuestionTypes);

import React, { useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import FormLanguageSelector from '../../components/FormLanguageSelector';
import EmailsForm from './EmailsForm';

const EmailsCreate = ({ emailsList, emailsRequest, emailsUpdateRequest, languageList }) => {
  const { emailId } = useParams();
  const id = emailId && !['emails', 'add'].includes(emailId) ? emailId : null;
  const [searchParams] = useSearchParams();
  const [urlParams, setUrlParams] = useState();

  const form = useMemo(() => {
    return (emailsList && emailsList[id]) || {};
  }, [id, emailsList]);

  useEffect(() => {
    if (id) {
      emailsRequest({ id });
    }
  }, [id]);

  useEffect(() => {
    setUrlParams(Object.fromEntries([...searchParams]));
  }, [searchParams]);

  const selectedLngId = useMemo(() => {
    if (urlParams && urlParams.editLocale && Array.isArray(languageList)) {
      return languageList.find((lng) => lng.language_code === urlParams.editLocale)?.id;
    }
    return languageList && languageList[0]?.id;
  }, [languageList, urlParams]);

  const initialData = useMemo(() => {
    if (form) {
      if (form?.email_templates_translations) {
        return {
          ...form,
          email_templates_translations: { language_id: selectedLngId, ...(form?.email_templates_translations[selectedLngId] || {}) },
        };
      } else {
        return {
          ...form,
          email_templates_translations: { language_id: selectedLngId },
        };
      }
    } else {
      return { email_templates_translations: { language_id: selectedLngId } };
    }
  }, [selectedLngId, form]);

  useEffect(() => {
    emailsRequest();
  }, []);

  const onValidate = (value) => {
    value.id = form.id;
    emailsUpdateRequest(value);
  };

  const handleError = (err) => {
    // eslint-disable-next-line no-console
    console.error(err);
  };

  return (
    <>
      <FormLanguageSelector />
      <EmailsForm onValidate={onValidate} initialValues={initialData} onError={handleError} emails={Object.values(emailsList || {})} />
    </>
  );
};

export default EmailsCreate;

import { connect } from 'react-redux';

import { AppKeyActions } from '../../../redux/appKeys';
import AppKeys from './AppKeys';

const mapStateToProps = ({ auth, appKeys }) => ({
  list: appKeys.list || {},
  loading: appKeys.loading,
});

const mapDispatchToProps = (dispatch) => ({
  appKeysRequest: (id) => dispatch(AppKeyActions.request(id)),
  appKeysDeleteRequest: (id) => dispatch(AppKeyActions.deleteRequest(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppKeys);

import _ from 'lodash';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { SmsService } from '../../services';
import { AuthActions } from '../auth';
import { LoaderActions } from '../loader';
import { SnackActions } from '../snackBar';
import { default as SmsActions, types } from './actions';

function* smsRequest({ filters }) {
  const { languageId } = yield select((state) => state.auth);
  const id = (filters && filters.id) || null;
  const [error, data] = id ? yield call(SmsService.getById, id) : yield call(SmsService.getAll);
  if (!error && data) {
    const sms = data.sms_templates.map((st) => {
      const selectedUserLanguage =
        (Array.isArray(st.sms_templates_translations) && st.sms_templates_translations.find((s) => s.language_id === languageId)) ||
        st.sms_templates_translations.find((s) => s.language_id === 1) ||
        st.sms_templates_translations[0];
      return {
        ...st,
        sms_templates_translations: _.keyBy(st.sms_templates_translations, 'language_id'),
        translation: selectedUserLanguage,
      };
    });
    yield put(SmsActions.smsSuccess(sms));
  } else {
    yield put(SnackActions.displayError('sms_list_error'));
    yield put(SmsActions.smsFailure());
  }
}

function* smsUpdateRequest({ sms }) {
  yield put(LoaderActions.loading());
  const [error, data] = yield call(SmsService.smsUpdate, sms);
  if (!error && data) {
    yield put(SnackActions.displayInfo('sms_modify_success'));
    yield put(SmsActions.smsUpdateSuccess({}));
    yield put(SmsActions.smsRequest({}));
    yield put(AuthActions.setRedirectUrl('/sms'));
  } else {
    yield put(SnackActions.displayError('sms_modify_error'));
  }
  yield put(LoaderActions.loaded());
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [takeLatest(types.SMS_REQUEST, smsRequest), takeLatest(types.SMS_UPDATE_REQUEST, smsUpdateRequest)];

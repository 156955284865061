import { createAction } from '../../utils';

// Types
export const types = {
  APP_KEYS_REQUEST: 'APP_KEYS_REQUEST',
  APP_KEYS_FAILURE: 'APP_KEYS_FAILURE',
  APP_KEYS_SUCCESS: 'APP_KEYS_SUCCESS',

  APP_KEYS_UPDATE_REQUEST: 'APP_KEYS_UPDATE_REQUEST',
  APP_KEYS_UPDATE_FAILURE: 'APP_KEYS_UPDATE_FAILURE',
  APP_KEYS_UPDATE_SUCCESS: 'APP_KEYS_UPDATE_SUCCESS',

  APP_KEYS_CREATE_REQUEST: 'APP_KEYS_CREATE_REQUEST',
  APP_KEYS_CREATE_FAILURE: 'APP_KEYS_CREATE_FAILURE',
  APP_KEYS_CREATE_SUCCESS: 'APP_KEYS_CREATE_SUCCESS',

  APP_KEYS_DELETE_REQUEST: 'APP_KEYS_DELETE_REQUEST',
  APP_KEYS_DELETE_MULTIPLE_REQUEST: 'APP_KEYS_DELETE_MULTIPLE_REQUEST',
  APP_KEYS_DELETE_SUCCESS: 'APP_KEYS_DELETE_SUCCESS',
};

// Actions
export default {
  /* CREATE */
  createRequest: (appKey = {}) => createAction(types.APP_KEYS_CREATE_REQUEST, { appKey }),
  createSuccess: () => createAction(types.APP_KEYS_CREATE_SUCCESS),

  /* UPDATE */
  updateRequest: (appKey = {}) => createAction(types.APP_KEYS_UPDATE_REQUEST, { appKey }),
  updateSuccess: (appKey) => createAction(types.APP_KEYS_UPDATE_SUCCESS, { appKey }),

  /** REQUEST */
  request: (filters = {}) => createAction(types.APP_KEYS_REQUEST, { filters }),
  requestSuccess: (appKeys) => createAction(types.APP_KEYS_SUCCESS, { appKeys }),
  requestFailure: () => createAction(types.APP_KEYS_FAILURE),

  deleteRequest: (id) => createAction(types.APP_KEYS_DELETE_REQUEST, { id }),
  deleteSuccess: (id) => createAction(types.APP_KEYS_DELETE_SUCCESS, { id }),
};

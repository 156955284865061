import { authenticatedMutation, authenticatedQuery } from './middleware';

/**
 * GRAPHQL QUERIES
 */
const queries = {
  clinicsGroups: `
    query GetClinicsGroups {
      clinic_groups {
        id
        created_at
        updated_at
        name
        logo
      }
    }
  `,
  clinicsGroup: `
    query GetClinicsGroup($id: Int!) {
      clinic_groups(where: {id: {_eq: $id}}) {
        id
        created_at
        updated_at
        name
        logo
      }
    }
  `,
  clinicsGroupDetails: `
    query GetClinicsGroupDetails($id: Int! = 1) {
      clinic_groups(where: {id: {_eq: $id}}) {
        id
        created_at
        updated_at
        name
        logo
        clinics {
          id
          created_at
          updated_at
          city
          postcode
          name
          address
          city_id
          finess
          external_id
          centers {
            id
          }
          clinic_medics {
            id
          }
        }
      }
    }
  `,
};
/**
 * GRAPHQL MUTATION
 */

const mutations = {
  clinicsGroupCreate: ` 
    mutation ClinicsGroupCreate($clinicsGroupPayload: [clinic_groups_insert_input!]!) {
      insert_clinic_groups(objects: $clinicsGroupPayload) {
        returning {
          id
          updated_at
          created_at
          name
          logo
        }
      }
    }
  `,
  clinicsGroupUpdate: `
    mutation ClinicsGroupUpdate(
      $id: Int!
      $clinicsGroupPayload: clinic_groups_set_input
    ) {
      update_clinic_groups(where: { id: { _eq: $id } }, _set: $clinicsGroupPayload ) {
        returning {
          id
          updated_at
          created_at
          name
          logo
        }
      }
    }
  `,
  clinicsGroupDelete: `
    mutation ClinicsGroupDelete($id: Int!) {
      delete_clinic_groups(where: { id: { _eq: $id } }) {
        returning {
          id
        }
      }
    }
  `,
};

export default {
  clinicsGroups: () => authenticatedQuery(queries.clinicsGroups),
  clinicsGroup: (clinicsGroupId) => authenticatedQuery(queries.clinicsGroup, {}, { clinicsGroupId }),
  clinicsGroupDetails: (clinicsGroupId) => authenticatedQuery(queries.clinicsGroupDetails, {}, { clinicsGroupId }),
  clinicsGroupCreate: (clinicsGroupPayload) => {
    return authenticatedMutation(mutations.clinicsGroupCreate, {}, clinicsGroupPayload);
  },
  clinicsGroupUpdate: (payload) => {
    return authenticatedMutation(mutations.clinicsGroupUpdate, {}, { id: payload.id, clinicsGroupPayload: payload.clinicsGroupPayload });
  },
  clinicsGroupDelete: (id) => {
    return authenticatedMutation(mutations.clinicsGroupDelete, {}, { id });
  },
};

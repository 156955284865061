import { connect } from 'react-redux';

import { FormsActions } from '../../../../redux/forms';
import Form from './Form';

const mapStateToProps = ({ locale }, props) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  updateData: (key, data) => dispatch(FormsActions.updateData(key, data)),
  omitData: (key) => dispatch(FormsActions.omitData(key)),
  //
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);

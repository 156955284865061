import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import createVideoPlugin from '@draft-js-plugins/video';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const videoPlugin = createVideoPlugin();

export const RichTextWithDraft = ({ name: propName, disabled, editableOnClick, className, control }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();

  const methods = useFormContext();

  const {
    field,
    fieldState: { error },
  } = useController({
    control: control || methods.control,
    name: propName,
  });

  useEffect(() => {
    if (isLoading && field.value) {
      const contentBlock = htmlToDraft(field.value);

      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
      }
      setIsLoading(false);
    }
  }, [field.value, isLoading]);

  /**
   * Handle editor change
   * @param {EditorState} state
   * @returns {void}
   */
  const handleEditorChange = (state) => {
    setEditorState(state);
    field.onChange(draftToHtml(convertToRaw(state.getCurrentContent())));
  };

  /**
   * Upload callback for images
   * @param {File} file
   * @return {Promise<unknown>|boolean}
   */
  const uploadCallback = (file) => {
    if (!file.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG|gif)$/)) {
      return false;
    }

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const dataURL = reader.result;
        const truncatedDataURL = dataURL.substring(10, 30) + '...'; // set the maximum length of the truncated string
        resolve({ data: { link: dataURL }, link: { url: truncatedDataURL } });
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const ariaInvalid = error ? 'true' : 'false';

  return (
    <div className={`${className || ''} ${editableOnClick ? 'border-on-click' : ''}`}>
      <Editor
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
        placeholder={editableOnClick ? t('richtext.click_here') : ''}
        editorState={editorState}
        onEditorStateChange={handleEditorChange}
        disabled={disabled}
        localization={{ locale: 'fr' }}
        aria-invalid={ariaInvalid}
        toolbar={{
          image: {
            uploadCallback,
            previewImage: true,
            alt: { present: true, mandatory: false },
          },
        }}
        plugins={[videoPlugin]}
      />
    </div>
  );
};

RichTextWithDraft.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
  editableOnClick: PropTypes.bool,
  className: PropTypes.string,
  control: PropTypes.object,
};

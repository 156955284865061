import { createReducer } from 'reduxsauce';

import { types } from './actions';

const initialState = {
  list: {},
  loading: false,
  hasMore: true,
};

const startLoader = (state = initialState) => ({ ...state, loading: true });

const stopLoader = (state = initialState) => ({ ...state, loading: false });

const interventionsClear = (state = initialState, action) => {
  return initialState;
};

export default createReducer(initialState, {
  [types.INTERVENTIONS_CLEAR]: interventionsClear,

  [types.INTERVENTIONS_UPDATE_REQUEST]: startLoader,
  [types.INTERVENTIONS_UPDATE_SUCCESS]: stopLoader,

  [types.INTERVENTIONS_CREATE_REQUEST]: startLoader,
  [types.INTERVENTIONS_CREATE_SUCCESS]: stopLoader,

  [types.INTERVENTIONS_DELETE_REQUEST]: startLoader,
  [types.INTERVENTIONS_DELETE_SUCCESS]: stopLoader,
});

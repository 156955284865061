import './style.css';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';

import { IconDelete } from '../../../../../../assets/img/icons';
import Card from '../../../../../components/card';
import { InputText, SurveyFormController } from '../../../../../components/formV3';
import ErrorMessage from '../../../../../components/formV3/errors/ErrorMessage';
import { INITIAL_QUESTION } from '../../../FormModel/formInitialValues';
import QuestionComponent from '../../../questionTypes/QuestionComponent';
import Dropzone from '../Dropzone';
import styles from './style.module.css';

/*
  disabled is tricky because medics and patients can update respectiv part of the form
  so disabled = false means medic can edit it, = true means patient can edit it
*/
const Step = ({ control, onDeleteStep, prefixName, index, step, can, questionTypes, localId = 1 }) => {
  const prefix = prefixName ? `${prefixName}.` : '';
  const translationPrefix = `${prefix}translation.`;
  const { fields, remove, append } = useFieldArray({
    // const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control: control, // control props comes from useForm (optional: if you are using FormContext)
    name: `${prefix}questions`, // unique name for your Field Array
    keyName: 'key', // When your array field contains objects with the key name id, useFieldArray will overwrite and remove it. If you want to keep the id field in your array of objects, you must use keyName prop to change to other name.
  });
  const methods = useFormContext();
  const { setFocus } = methods;
  const titleName = `${translationPrefix}title`;
  const [editOnClick, setEditOnClick] = useState(false);

  const titleWatch = useWatch({
    control: control || methods.control,
    name: titleName,
  });

  const onAddQuestion = (type) => {
    type = JSON.parse(type);
    const newQ = INITIAL_QUESTION(type, fields.length + 1, step.id);
    append({ ...newQ });
  };

  useEffect(() => {
    if (editOnClick) setFocus(titleName);
  }, [editOnClick]);

  const handleEditOnClick = () => {
    setEditOnClick(!editOnClick);
  };

  const handleTitleKeyPress = (event) => {
    event && event.preventDefault();
    if (event && event.key === 'Enter') {
      handleEditOnClick();
    }
  };

  const handleDeleteQuestion = (index) => {
    remove(index);
  };

  const handleDeleteStep = () => {
    if (onDeleteStep) onDeleteStep(index);
  };

  return (
    <div className="consent-step-card-container">
      <Card>
        <SurveyFormController name={`${translationPrefix}language_id`} isRequiredField={true} className={'d-none'}>
          <InputText disabled={true} id={`consent-step-card-language_id-${index || -1}`} />
        </SurveyFormController>
        <div className="d-flex justify-content-between align-items-center">
          <SurveyFormController name={titleName} isRequiredField={true}>
            <InputText
              className={editOnClick ? styles.title : 'd-none'}
              disabled={!can.steps.editTitle}
              id={`consent-step-card-title-${index || -1}`}
              onBlur={handleEditOnClick}
            />
            <div
              id={`consent-step-card-title-${index || -1}`}
              className={!editOnClick ? styles.title : 'd-none'}
              onClick={handleEditOnClick}
              onKeyPress={handleTitleKeyPress}
              role="link"
              tabIndex="0"
            >
              {titleWatch}
            </div>
          </SurveyFormController>
          {can.steps.delete && (
            <button type="button" className="btn btn-link consent-step-card-image" onClick={handleDeleteStep}>
              <img className="" src={IconDelete} alt={'delete step'} />
            </button>
          )}
        </div>
        {fields
          .sort((a, b) => a.number - b.number)
          .map((question, k) => {
            return (
              <div key={question.key}>
                <QuestionComponent
                  questionTypes={questionTypes}
                  question={question}
                  onDelete={() => handleDeleteQuestion(k)}
                  prefixName={`${prefix}questions.${k}`}
                  index={k}
                  nestIndex={index}
                  can={can}
                  localId={localId}
                />
                <ErrorMessage name={`${prefix}questions.${k}`} />
              </div>
            );
          })}
        {can.steps.add && <Dropzone onChange={onAddQuestion} />}
      </Card>
      <ErrorMessage name={`${prefix}questions`} />
    </div>
  );
};

Step.propTypes = {
  can: PropTypes.object,
};

export default Step;

const colors = {
  red: '#db4040',
  darkRed: '#db2222',
};

export const theme = {
  colors: {
    ghost: '#212529',
    primary: '#fff',
    custom: '#444',
    yellow: 'yellow',
  },
  hoverColors: {
    ghost: '#212529',
    primary: '#eeeef1',
    custom: '#eeeef1',
    yellow: 'eeeef1',
  },
  hoverBgColors: {
    ghost: '#212529',
    primary: '#eeeef1',
    custom: '#eeeef1',
    yellow: 'eeeef1',
  },
  buttons: {
    primary: {
      color: '#fff',
      background: 'linear-gradient(252deg, #00d7e7, #00aecb)',
      hover: {
        color: '#fff',
        background: 'linear-gradient(252deg, #00aecb, #00d7e7)',
      },
    },
    secondary: {
      color: '#fff',
      background: '#db4040;',
      hover: {
        color: '#fff',
        background: 'green',
      },
    },
    ghost: {
      color: '#212529',
      background: 'transparent',
      hover: {
        color: '#212529',
        background: '#eeeef1',
      },
    },
    danger: {
      color: '#fff',
      background: colors.red,
      hover: {
        color: '#000',
        background: colors.darkRed,
      },
    },
    warning: {
      color: '#fff',
      background: 'linear-gradient(255deg, #e67417, #df9558)',
      hover: {
        color: '#fff',
        background: 'linear-gradient(255deg, #df9558, #e67417)',
      },
    },
    icon: {
      color: '#fff',
      background: '#1d2a61',
      hover: {
        color: '#00aecb',
        background: '#1d2a61',
      },
    },
  },
  qrCode: {
    primary: { color: '#ed695a' },
    secondary: { color: '#00aecb' },
  },
};

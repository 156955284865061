import _ from 'lodash';
import { createReducer } from 'reduxsauce';

import { types } from './actions';

const initialState = {
  list: {},
  loading: false,
  hasMore: true,
};

const startLoader = (state = initialState) => ({ ...state, loading: true });

const stopLoader = (state = initialState) => ({ ...state, loading: false });

const smsSuccess = (state = initialState, action) => {
  const { sms } = action;
  const { list } = state;
  return { ...state, list: { ...list, ..._.keyBy(sms, 'id') }, loading: false, hasMore: sms.length > 0 };
};

export default createReducer(initialState, {
  [types.SMS_UPDATE_REQUEST]: startLoader,
  [types.SMS_UPDATE_SUCCESS]: stopLoader,

  [types.SMS_REQUEST]: startLoader,
  [types.SMS_SUCCESS]: smsSuccess,
  [types.SMS_FAILURE]: stopLoader,
});

import { connect } from 'react-redux';

import { EmailsActions } from '../../../redux/emails';
import EmailsCreate from './EmailsCreate';

const mapStateToProps = ({ language, emails }) => ({
  emailsList: emails.list,
  languageList: Object.values(language.list || {}),
});

const mapDispatchToProps = (dispatch) => ({
  emailsRequest: (filter) => dispatch(EmailsActions.emailsRequest(filter)),
  emailsUpdateRequest: (email) => dispatch(EmailsActions.emailsUpdateRequest(email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailsCreate);

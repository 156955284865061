import _ from 'lodash';
import { createReducer } from 'reduxsauce';

import { types } from './actions';

const initialState = {
  list: {},
  loading: false,
  hasMore: true,
  generatedURL: null,
  selectedTemplate: null,
};

const startLoader = (state = initialState) => ({
  ...state,
  loading: true,
});

const stopLoader = (state = initialState) => ({
  ...state,
  loading: false,
});

const templateStepMapper = (templates) => {
  if (!templates) return;
  return _.keyBy(
    (Array.isArray(templates) ? templates : [templates]).map((t) => ({
      ..._.omit(t, 'medic_template_steps'),
      translations: _.keyBy(t.medic_templates_translations, 'language_id'),
      steps: Array.isArray(t.medic_template_steps)
        ? t.medic_template_steps
            .map((s) => s.step)
            .map((s) => ({
              ...s,
              steps_translations: _.keyBy(s.steps_translations, 'language_id'),
              questions: Array.isArray(s.questions)
                ? s.questions.map((q) => ({
                    ...q,
                    questions_translations: _.keyBy(q.questions_translations, 'language_id'),
                  }))
                : [],
            }))
        : [],
    })),
    'id',
  );
};

const medicTemplateGetSuccess = (state = initialState, action) => {
  const { templates } = action;
  let { list } = state;

  return { ...state, list: _.merge(list, templateStepMapper(templates)), loading: false };
};

const medicTemplateDetailSuccess = (state = initialState, action) => {
  const { templates } = action;
  let { list } = state;
  const newTemplates = templateStepMapper(templates);

  return { ...state, list: _.merge(list, newTemplates), selectedTemplate: _.get(newTemplates, _.get(templates, '0.id')), loading: false };
};

const clearSelectedTemplate = (state = initialState) => {
  return { ...state, selectedTemplate: null, loading: false };
};

const medicTemplateDeleteSuccess = (state = initialState, action) => {
  const { IDs } = action;
  const { list } = state;

  return { ...state, list: _.omit(list, IDs), loading: false };
};

const medicTemplateGeneratePDFSUccess = (state = initialState, action) => {
  const { url } = action;
  return { ...state, generatedURL: url };
};

const medicTemplateResetGeneratedURL = (state = initialState, action) => ({
  ...state,
  generatedURL: null,
});

export default createReducer(initialState, {
  [types.MEDIC_TEMPLATE_GET_REQUEST]: startLoader,
  [types.MEDIC_TEMPLATE_GET_SUCCESS]: medicTemplateGetSuccess,
  [types.MEDIC_TEMPLATE_GET_FAILURE]: stopLoader,

  [types.MEDIC_TEMPLATE_GET_DETAIL_REQUEST]: startLoader,
  [types.MEDIC_TEMPLATE_GET_DETAIL_SUCCESS]: medicTemplateDetailSuccess,
  [types.MEDIC_TEMPLATE_GET_DETAIL_FAILURE]: stopLoader,

  [types.MEDIC_TEMPLATE_DELETE_REQUEST]: startLoader,
  [types.MEDIC_TEMPLATE_DELETE_SUCCESS]: medicTemplateDeleteSuccess,
  [types.MEDIC_TEMPLATE_DELETE_FAILURE]: stopLoader,

  [types.MEDIC_TEMPLATE_GENERATE_PDF_SUCCESS]: medicTemplateGeneratePDFSUccess,
  [types.MEDIC_TEMPLATE_RESET_GENERATED_URL]: medicTemplateResetGeneratedURL,

  [types.CLEAR_SELECTED_TEMPLATE]: clearSelectedTemplate,
});

import { countries } from 'countries-list';
import React, { useEffect, useState } from 'react';

import CountryFlag from '../formV3/controllers/CountrySelect/CountryFlag';
import ReactSelect from '../ReactSelect/ReactSelect';

const CountrySelect = ({ value, onChange }) => {
  const [selectedCountry, setSelectedCountry] = useState(value);

  useEffect(() => {
    setSelectedCountry(value);
  }, [value]);

  function handleChange(val) {
    setSelectedCountry(val);
    onChange(val);
  }

  const countryOption = Object.keys(countries);

  return (
    <ReactSelect
      placeholder="Pays"
      isSearchable
      value={value}
      options={countryOption.map((countryCode) => ({
        value: countryCode,
        label: <CountryFlag countryCode={countryCode} />,
      }))}
      onChange={handleChange}
    />
  );
};

export default CountrySelect;

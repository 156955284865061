import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { PRESET } from '../preset/preset';
import Input from './Input';

const InputText = ({ name, inputPreset, defaultValue, type: typeProps, onBlur, readOnly, focusOnload, ...props }) => {
  const type = useMemo(() => {
    return typeProps && type !== typeProps ? typeProps : 'text';
  }, [typeProps]);
  const methods = useFormContext();
  const preset = PRESET[inputPreset] || {};
  const { setFocus } = methods;

  useEffect(() => {
    if (focusOnload) setFocus(name);
  }, [focusOnload]);

  return <Input {...props} name={name} preset={preset.preset} type={type} defaultValue={defaultValue} readOnly={readOnly} onBlur={onBlur} />;
};

const areEqual = (prevProps, nextProps) => {
  return prevProps.name !== nextProps.name;
};
/*
  return true if passing nextProps to render would return the same result as passing prevProps to render, otherwise return false
*/

InputText.propTypes = {
  register: PropTypes.func,
  name: PropTypes.string,
  control: PropTypes.object,
  defaultValue: PropTypes.string,
  rules: PropTypes.object,
  inputProps: PropTypes.object,
  inputPreset: PropTypes.oneOf(['security_number', 'password', 'email', 'postcode', 'rpps_number']),
  hidden: PropTypes.bool,
  isLoading: PropTypes.bool,
  error: PropTypes.bool,
  success: PropTypes.bool,
  type: PropTypes.oneOf([
    'button',
    'checkbox',
    'color',
    'date',
    'datetime-local',
    'email',
    'file',
    'hidden',
    'image',
    'month',
    'number',
    'password',
    'radio',
    'range',
    'reset',
    'search',
    'submit',
    'tel',
    'text',
    'time',
    'url',
    'week',
  ]),
};

export default React.memo(InputText, areEqual);

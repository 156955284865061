import './surveyTypes.css';

import { Divider, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';

import { DeleteButton, ModifyButton } from '../../components/button';

const { Text } = Typography;

export const ACTIONS = {
  PUBLISH: 'PUBLISH',
  HIDE: 'HIDE',
  DELETE: 'DELETE',
  RESTORE: 'RESTORE',
};

const DataTable = ({ items, reset, loading, actionClick, itemClick, onDelete, onModify, userLanguageId = 1 }) => {
  const [key, setKey] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [data, setData] = useState([]);
  const index = 1;
  const [pagination, setPagination] = useState({
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50', '100', '200', '500'],
    current: index,
  });

  useEffect(() => {
    setData(items || []);
  }, [items]);

  useEffect(() => {
    if (data.length !== key) {
      setKey(data.length);
      setSelectedRowKeys([]);
    }
  }, [data]);

  useEffect(() => {
    setPagination({ current: index });
  }, [reset]);

  const columns = [
    {
      title: 'Catégorie',
      dataIndex: ['translations', userLanguageId, 'title'],
      sorter: (a, b) => a.translations[userLanguageId].title.localeCompare(b.translations[userLanguageId].title),
      align: 'center',
      render: (text) => <span>{text}</span>,
    },
    {
      title: '',
      key: 'action',
      width: 100,
      render: (item) => {
        return item.value !== 'Archivé' ? (
          <span className="ButtonSurveyTypes">
            <ModifyButton modify={() => onModify(item)} />
            <Divider type="vertical" />
            <DeleteButton deletion={() => onDelete(item)} />
          </span>
        ) : (
          <> </>
        );
      },
    },
  ];

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleAction = (actionType) => {
    if (actionClick) {
      actionClick(actionType, selectedRowKeys);
      setSelectedRowKeys([]);
    }
  };

  const handleOnRowClick = (rest, index) => {
    if (itemClick) itemClick(rest);
  };

  const handleReset = () => {
    setSelectedRowKeys([]);
  };

  const actionsRender = () => {
    return (
      <>
        <span>
          <span style={{ marginRight: 6 }}>
            <DeleteButton title={'Supprimer'} onClick={() => handleAction(ACTIONS.DELETE)} onCancel={() => handleReset()} />
          </span>
          <Text strong>{selectedRowKeys.length} catégorie(s)</Text>
        </span>
        <br />
        <br />
      </>
    );
  };

  return (
    <div>
      {selectedRowKeys.length > 0 && actionsRender()}
      <Table
        size={'small'}
        showSorterTooltip={false}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data.map((item) => ({ ...item, key: item.id }))}
        loading={loading}
        pagination={pagination}
        onRow={(rest, rowIndex) => {
          return rest.value !== 'Archivé'
            ? {
                onDoubleClick: (event) => {
                  handleOnRowClick(rest, rowIndex);
                },
              }
            : {};
        }}
        onChange={(page) => {
          setPagination({ current: page.current });
        }}
      />
    </div>
  );
};

export default DataTable;

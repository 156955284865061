import { Button } from 'antd';
import _ from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { parseDate } from '../../../services/datefns';
import PatientsForm from './PatientsForm';

const PatientsCreate = ({
  patientsCreateRequest,
  patientsUpdateRequest,
  medicList,
  medicsRequest,
  patients,
  checkIfEmailExistsInDb,
  cityList,
  birthplaceList,
  fetchCityList,
  clearCityList,
  medicalTeamsRequest,
  medicalTeams,
  patientDetailRequest,
  resetUserPswdAttemptCounter,
  fetchAllLanguages,
  languageList,
  token,
  patientsList,
}) => {
  const { t } = useTranslation();
  const { patientId } = useParams();
  const id = patientId && !['patients', 'add'].includes(patientId) ? patientId : null;

  const patient = useMemo(() => {
    const patient = id && patientsList && patientsList[id];
    return patient;
  }, [id, patientsList && patientsList[id]]);

  const initialValues = useMemo(() => {
    return patient
      ? {
          ...patient,
          birthdate: parseDate(patient.birthdate),
          birthplace_id: patient.cityByBirthplaceId && patient.cityByBirthplaceId.id,
          city_id: patient.cityByCityId && patient.cityByCityId.id,
        }
      : {};
  }, [patient, JSON.stringify(patient || {})]);

  useEffect(() => {
    medicsRequest();
    medicalTeamsRequest();
    fetchAllLanguages();
  }, []);

  useEffect(() => {
    if (id && !['patients', 'add'].includes(id) && patientDetailRequest) patientDetailRequest(id);
  }, [id]);

  const onValidate = (patient) => {
    if (initialValues === null || (typeof initialValues === 'object' && Object.keys(initialValues).length === 0)) {
      patientsCreateRequest(patient);
    } else {
      patientsUpdateRequest({ ...patient, id: initialValues.id });
    }
  };

  const handleResetUserPswdAttemptCounter = (event) => {
    event && event.preventDefault();
    const userId = _.get(initialValues, 'user.id');
    if (userId && resetUserPswdAttemptCounter) {
      resetUserPswdAttemptCounter(userId);
    }
  };

  return (
    <>
      <Button onClick={handleResetUserPswdAttemptCounter} htmlType="button" data-testid="PatientsCreate-reset-button">
        {t('patientCreate.resetPswdCountBtn.title')}
      </Button>
      <PatientsForm
        onValidate={onValidate}
        initialValues={initialValues}
        // eslint-disable-next-line no-console
        onError={(err) => console.error(err)}
        medics={medicList}
        patients={patients && patients.list ? Object.values(patients.list || {}).filter((v) => !v.is_tutor && v.tutorsByPatientId.length !== 2) : []}
        checkIfEmailExistsInDb={checkIfEmailExistsInDb}
        isEmailAvailable={patients && patients.isEmailAvailable}
        cityList={cityList}
        birthplaceList={birthplaceList}
        fetchCityList={fetchCityList}
        clearCityList={clearCityList}
        medicalTeams={medicalTeams}
        languageList={languageList}
        token={token}
      />
    </>
  );
};

export default PatientsCreate;

import { connect } from 'react-redux';

import { SurveyModelFilesActions } from '../../../redux/surveyModelFiles';
import MsWordTemplate from './MsWordTemplate';

const mapStateToProps = ({ surveyModelFiles }) => {
  return {
    list: Object.values(surveyModelFiles.list || {}),
  };
};

const mapDispatchToProps = (dispatch) => ({
  surveyModelFilesRequestAll: () => dispatch(SurveyModelFilesActions.request()),
  surveyModelFilesUpsert: (payload) => dispatch(SurveyModelFilesActions.upsert(payload)),
  surveyModelFilesDelete: (payload) => dispatch(SurveyModelFilesActions.delete(payload)),
  surveyModelFilesMakeDefault: (id) => dispatch(SurveyModelFilesActions.makeDefault(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MsWordTemplate);

import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { SURVEY_TYPES } from '../../../utils/dataMappers';
import FormLanguageSelector from '../../components/FormLanguageSelector';
import CreateForm from './CreateForm';

const REFRESH_TOKEN_INTERVAL = 9 * 60 * 1000;

const SurveysCreate = ({
  currentForm,
  surveyTypesRequest,
  questionTypesRequest,
  specialitiesRequest,
  currentTemplateDetail,
  medicTemplateDetail,
  isStoreTemplate,
  medicalTeamsRequest,
  loginRefresh,
  medicsRequest,
  languageList,
  questionTypes,
  indexRoute,
}) => {
  let { templateId } = useParams();
  const id = templateId && !['templates', 'medicTemplates', 'add'].includes(templateId) ? templateId : null;

  const [searchParams] = useSearchParams();
  const [initialValues, setInitialValues] = useState();
  const [urlParams, setUrlParams] = useState();

  useEffect(() => {
    setUrlParams(Object.fromEntries([...searchParams]));
  }, [searchParams]);

  const selectedLngId = useMemo(() => {
    if (urlParams && urlParams.editLocale && Array.isArray(languageList)) {
      return languageList.find((lng) => lng.language_code === urlParams.editLocale)?.id;
    }
    return languageList && languageList[0]?.id;
  }, [languageList, urlParams]);

  useEffect(() => {
    const interval = setInterval(() => {
      loginRefresh();
    }, REFRESH_TOKEN_INTERVAL);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, []);

  useEffect(() => {
    if (surveyTypesRequest) surveyTypesRequest({});
    if (questionTypesRequest) questionTypesRequest();
    if (specialitiesRequest) specialitiesRequest();
    if (medicalTeamsRequest) medicalTeamsRequest();
    if (medicsRequest) medicsRequest();
  }, []);

  useEffect(() => {
    if (_.isObject(currentForm)) {
      const template = _.get(currentForm, ['translations', selectedLngId]);
      let description = template && template.description && JSON.parse(template.description);

      const newInitialValues = {
        language_id: selectedLngId,
        id: currentForm.id,
        medical_teams_id: currentForm.medical_teams_id,
        translation: {
          ...template,
          description: Array.isArray(description) ? description.map((tag) => ({ value: tag })) : [],
        },
        isRadius: currentForm.radius === 'on',
        isSide: currentForm.side === 'on',
        is_published: currentForm.is_published || false,
        medic_id: currentForm.medic_id,
        speciality_id: currentForm.speciality_id,
        type_id: currentForm.type_id,
        steps: (currentForm.steps || []).map((step) => {
          const stepTranslation = _.get(step, ['steps_translations', selectedLngId], {});
          return {
            id: step.id,
            number: step.number,
            translation: {
              id: stepTranslation.id || '',
              language_id: stepTranslation.language_id || '',
              title: stepTranslation.title || '',
            },
            questions: (step.questions || [])
              .filter((el) => !!el.type_id)
              .map((q) => {
                const questionTranslation = _.get(q, ['questions_translations', selectedLngId], {});
                const type = _.get(q, 'question_type.value');
                const questionTypeName = _.get(questionTypes, [q.type_id, 'value']);
                return {
                  id: q.id,
                  number: q.number,
                  type_id: q.type_id,
                  type_name: questionTypeName,
                  translation: {
                    id: questionTranslation.id || '',
                    language_id: questionTranslation.language_id || '',
                    desc: questionTranslation.desc || '',
                    incompris: questionTranslation.incompris || '',
                    question: questionTranslation.question || '',
                    rewording: questionTranslation.rewording || '',
                    texte_pdf: questionTranslation.texte_pdf || '',
                    data: {
                      ...questionTranslation.data,
                      list: [SURVEY_TYPES.multiple_choice, SURVEY_TYPES.checkbox_full].includes(type)
                        ? questionTranslation?.data?.list?.map((el) => {
                            return _.isObject(el) ? el : { value: el || '' };
                          })
                        : questionTranslation?.data?.list,
                    },
                  },
                };
              }),
          };
        }),
      };
      // if (!_.isEqual(newInitialValues, initialValues))
      setInitialValues(newInitialValues);
    } else {
      setInitialValues();
    }
    return function cleanup() {
      setInitialValues();
    };
  }, [currentForm, questionTypes, selectedLngId]);

  useEffect(() => {
    if (id && selectedLngId) {
      if (isStoreTemplate) {
        currentTemplateDetail(id, selectedLngId);
      } else {
        medicTemplateDetail(id, selectedLngId);
      }
    }
  }, [id, isStoreTemplate, selectedLngId]);

  const can = {
    publish: true,
    editTitle: true,
    editDescription: true,
    editSpecialty: true,
    editSurveyType: true,
    side: {
      canEdit: true,
      canAnswer: false,
    },
    radius: {
      canEdit: true,
      canAnswer: false,
    },
    steps: {
      add: true,
      delete: true,
      canAnswer: false,
      editTitle: true,
      reOrder: true,
      question: {
        add: true,
        delete: true,
        editQuestion: true,
        editDesc: true,
        rewording: {
          canEdit: true,
          canRead: false,
        },
        data: {
          canEdit: true,
          canAnswer: false,
        },
        completeData: true,
      },
    },
  };

  return (
    <>
      <FormLanguageSelector />
      <CreateForm initialValues={initialValues} can={can} isStoreTemplate={isStoreTemplate} localId={selectedLngId} />
    </>
  );
};

export default SurveysCreate;

SurveysCreate.propTypes = {
  form: PropTypes.object,
  surveyTypesRequest: PropTypes.func.isRequired,
  questionTypesRequest: PropTypes.func.isRequired,
  specialitiesRequest: PropTypes.func.isRequired,
};

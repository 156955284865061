import './clinicsGroups.css';

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { filterAndOrderList } from '../../../utils/utils';
import DataTable from './DataTable';

const ClinicsGroups = ({
  clinicsGroupsRequest,
  clinicsGroupDeleteRequest,
  clinicsGroupsDeleteRequest,
  clinicsGroups,
  loading,
  authorizationToken,
}) => {
  let navigate = useNavigate();
  const [logo, setLogo] = useState();

  useEffect(() => {
    const getLogo = async (oid) => {
      const responseType = 'blob'; // 'arraybuffer' || 'blob';
      const url = `${process.env.REACT_APP_BASE_API_URL}/file/loids${oid}`;
      const res = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/image',
          Authorization: `Bearer ${authorizationToken}`,
        },
        responseType,
      });
      const { data } = res;
      //const newFileType = res.headers['content-type'];
      setLogo(data);
      // const objectUrl = window.URL.createObjectURL(data);
      // setPdfUrl(newFileType === CONTENT_TYPE_MAPPER.PDF ? objectUrl : null);
    };
    if (logo) {
      getLogo(logo);
    }
  }, [authorizationToken, logo, clinicsGroups]);

  useEffect(() => {
    clinicsGroupsRequest({});
  }, [clinicsGroupsRequest]);

  const onModify = (clinicsGroup) => {
    navigate(`/clinics-groups/${clinicsGroup.id}`, clinicsGroup);
  };

  const onDelete = (clinicsGroup) => {
    if (Array.isArray(clinicsGroup)) {
      clinicsGroupsDeleteRequest(clinicsGroup);
    } else {
      clinicsGroupDeleteRequest(clinicsGroup.id);
    }
  };

  return (
    <>
      {/* <Tags exportFct={exportDataToXls} />
      <Filter list={clinics} onFilter={onFilter} keys={CLINIC_FILTER_KEYS} /> */}

      <DataTable items={filterAndOrderList(clinicsGroups)} loading={loading} itemClick={onModify} onDelete={onDelete} onModify={onModify} />
    </>
  );
};

export default ClinicsGroups;

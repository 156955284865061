export const SURVEY_PDFS_MINIMAL_FIELDS = `
  fragment surveyPdfMinimalFields on survey_pdfs {
    loid
    ext
  }
`;

export const SURVEY_ATTACHMENT_MINIMAL_FIELDS = `
  fragment surveyAttachmentMinimalFields on survey_attachments {
    loid 
    ext 
  }
`;

export const SURVEY_DRAWINGS_MINIMAL_FIELDS = `
  fragment surveyDrawingsMinimalFields on survey_drawings {
    loid
    ext
  }
`;

import {
  ApiOutlined,
  BankOutlined,
  FileDoneOutlined,
  FileProtectOutlined,
  FolderOpenOutlined,
  FormOutlined,
  HomeOutlined,
  MailOutlined,
  MedicineBoxOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';

export const matomoUrl = process.env.REACT_APP_MATOMO_URL;

export const ENVIRONMENT = process.env.NODE_ENV;
export const isProduction = ENVIRONMENT === 'production';
export const isTest = ENVIRONMENT === 'test';

export const LOGIN_PATH = '/login';
export const HOME_PATH = '/';

export const RoutesMapper = (path) => {
  switch (path) {
    case '/medics':
      return { title: 'sideMenu.title.pro.root', icon: MedicineBoxOutlined, showCreateBtn: true, btnTitle: 'sideMenu.title.pro.btnTitle' };
    case '/medics/:medicId':
      return { title: 'sideMenu.title.pro.management' };
    case '/clinics':
      return { title: 'sideMenu.title.clinic.root', icon: BankOutlined, showCreateBtn: true, btnTitle: 'sideMenu.title.clinic.btnTitle' };
    case '/clinics-groups':
      return {
        title: 'sideMenu.title.clinicsGroups.root',
        icon: BankOutlined,
        showCreateBtn: true,
        btnTitle: 'sideMenu.title.clinicsGroups.btnTitle',
      };
    case '/clinics/:clinicId':
      return { title: 'sideMenu.title.clinic.management' };
    case '/medicalTeams':
      return {
        title: 'sideMenu.title.medicalTeams.root',
        icon: TeamOutlined,
        showCreateBtn: true,
        btnTitle: 'sideMenu.title.medicalTeams.btnTitle',
      };
    case '/medicalTeams/:medicalTeamId':
      return {
        title: 'sideMenu.title.medicalTeams.management',
      };
    case '/patients':
      return {
        title: 'sideMenu.title.patient.root',
        icon: UserOutlined,
        showCreateBtn: true,
        btnTitle: 'sideMenu.title.patient.btnTitle',
      };
    case '/patients/:patientId':
      return {
        title: 'sideMenu.title.patient.management',
      };
    case '/surveyTypes':
      return {
        title: 'sideMenu.title.surveyTypes.root',
        icon: FolderOpenOutlined,
        showCreateBtn: true,
        btnTitle: 'sideMenu.title.surveyTypes.btnTitle',
      };
    case '/surveyTypes/:surveyTypeId':
      return {
        title: 'sideMenu.title.surveyTypes.management',
      };
    case '/msWordTemplates':
      return {
        title: 'sideMenu.title.msWordTemplates.root',
        icon: FolderOpenOutlined,
        showCreateBtn: true,
        btnTitle: 'sideMenu.title.msWordTemplates.btnTitle',
      };
    case '/msWordTemplates/:msWordTemplateId':
      return {
        title: 'sideMenu.title.msWordTemplates.management',
      };
    case '/questionTypes':
      return {};
    case '/questionTypes/:questionTypeId':
      return {};
    case '/templates':
      return {
        title: 'sideMenu.title.templates.root',
        icon: FormOutlined,
        showCreateBtn: true,
        btnTitle: 'sideMenu.title.templates.btnTitle',
      };
    case '/templates/:templateId':
      return { title: 'sideMenu.title.templates.management' };
    case '/medicTemplates':
      return {
        title: 'sideMenu.title.medicTemplates.root',
        icon: FormOutlined,
        showCreateBtn: true,
        btnTitle: 'sideMenu.title.medicTemplates.btnTitle',
      };
    case '/medicTemplates/:templateId':
      return { title: 'sideMenu.title.medicTemplates.management' };
    case '/consents':
      return {
        title: 'sideMenu.title.consents.root',
        icon: FileDoneOutlined,
        showCreateBtn: false,
        btnTitle: 'sideMenu.title.consents.btnTitle',
      };
    case '/consents/:consentId':
      return { title: 'sideMenu.title.consents.management' };
    case '/specialities':
      return {
        title: 'sideMenu.title.specialities.root',
        icon: MedicineBoxOutlined,
        showCreateBtn: true,
        btnTitle: 'sideMenu.title.specialities.btnTitle',
      };
    case '/specialities/:specialityid':
      return { title: 'sideMenu.title.specialities.management' };
    case '/cgu':
      return { title: 'sideMenu.title.cgu.root', icon: FileProtectOutlined };
    case '/rgpd':
      return { title: 'sideMenu.title.rgpd.root', icon: FileProtectOutlined };
    case '/roles':
      return { title: 'sideMenu.title.roles.root', icon: UserOutlined, showCreateBtn: true, btnTitle: 'sideMenu.title.roles.btnTitle' };
    case '/roles/:roleId':
      return { title: 'sideMenu.title.roles.management' };
    case '/sms':
      return { title: 'sideMenu.title.sms.root', icon: MailOutlined };
    case '/sms/:smsId':
      return { title: 'sideMenu.title.sms.management' };
    case '/emails':
      return { title: 'sideMenu.title.emails.root', icon: MailOutlined };
    case '/emails/:emailId':
      return { title: 'sideMenu.title.emails.management' };
    case '/app_keys':
      return { title: 'sideMenu.title.app_keys.root', icon: ApiOutlined, showCreateBtn: true, btnTitle: 'sideMenu.title.app_keys.btnTitle' };
    case '/app_keys/:id':
      return { title: 'sideMenu.title.app_keys.management' };
    case '/sftpCredentials':
      return {
        title: 'sideMenu.title.sftpCredentials.root',
        icon: ApiOutlined,
        showCreateBtn: true,
        btnTitle: 'sideMenu.title.sftpCredentials.btnTitle',
      };
    case '/sftpCredentials/:sftpCredentialId':
      return { title: 'sideMenu.title.sftpCredentials.management' };
    case '/credentials':
      return {
        title: 'sideMenu.title.credentials.root',
        icon: ApiOutlined,
        showCreateBtn: true,
        btnTitle: 'sideMenu.title.credentials.btnTitle',
      };
    case '/credentials/:credentialId':
      return { title: 'sideMenu.title.credentials.management' };
    case '/languages':
      return {
        title: 'sideMenu.title.languages.root',
        icon: ApiOutlined,
        showCreateBtn: true,
        btnTitle: 'sideMenu.title.languages.btnTitle',
      };
    case '/languages/:languageId':
      return { title: 'sideMenu.title.languages.management' };
    case '/':
    default:
      return { title: 'sideMenu.title.home.root', icon: HomeOutlined };
  }
};

import { Button } from 'antd';
import _ from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import PaswdForm from '../PaswdForm';
import MedicsForm from './MedicsForm';

const MedicsCreate = ({
  rolesRequest,
  medicsCreateRequest,
  medicsRequest,
  medicsUpdateRequest,
  patientsRequest,
  clinicsRequest,
  specialitiesRequest,
  specialities,
  clinics,
  medicalTeams,
  medicalTeamsRequest,
  rolesOptions,
  token,
  resetUserPswdAttemptCounter,
  fetchAllLanguages,
  languageList,
  medicsList,
}) => {
  const { t } = useTranslation();
  const { medicId } = useParams();
  const id = medicId && !['medics', 'add'].includes(medicId) ? medicId : null;

  const form = useMemo(() => {
    return id && medicsList && medicsList[id];
  }, [id, medicsList && medicsList[id] && JSON.stringify(medicsList[id])]);

  useEffect(() => {
    rolesRequest({ isBackend: false });
    patientsRequest();
    medicsRequest();
    specialitiesRequest({});
    clinicsRequest({});
    medicalTeamsRequest();
    fetchAllLanguages();
  }, []);

  useEffect(() => {
    if (id && medicsRequest) medicsRequest({ id });
  }, [id]);

  const handleResetUserPswdAttemptCounter = (event) => {
    event && event.preventDefault();
    const userId = _.get(form, 'user_id');
    if (userId && resetUserPswdAttemptCounter) {
      resetUserPswdAttemptCounter(userId);
    }
  };

  const handleValidate = (value) => {
    value.user.users_roles = Array.isArray(value.user.users_roles) ? value.user.users_roles : [value.user.users_roles];
    if (form == null) {
      medicsCreateRequest(value);
    } else {
      value.id = form.id;
      value.user_id = form.user_id;
      medicsUpdateRequest(value);
    }
  };

  // eslint-disable-next-line no-console
  const handleError = (err) => console.error(err);

  return (
    <>
      {id && id !== '' && (
        <div className="my-2">Consentement signé(s) le mois dernier : {form && form?.surveysByReferentMedicId_aggregate?.aggregate.count}</div>
      )}
      <Button onClick={handleResetUserPswdAttemptCounter} htmlType="button" data-testid="medicCreate-reset-button">
        {t('patientCreate.resetPswdCountBtn.title')}
      </Button>
      <MedicsForm
        data-testid="medicCreate-MedicsForm"
        specialities={specialities}
        onValidate={handleValidate}
        values={
          form && {
            ..._.omit(form, ['surveysByReferentMedicId_aggregate']),
            user: {
              ...form.user,
              users_roles: Array.isArray(form?.user?.users_roles) ? form.user.users_roles : null,
            },
          }
        }
        valuesPwd={form}
        onError={handleError}
        clinics={clinics}
        medicalTeams={medicalTeams}
        rolesOptions={rolesOptions}
        token={token}
        languageList={languageList}
      />
      <PaswdForm data-testid="medicCreate-PaswdForm" initialValues={{ id: form?.user_id }} />
    </>
  );
};

export default MedicsCreate;

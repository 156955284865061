import AES from 'crypto-js/aes';
import _ from 'lodash';
import { call, put, takeLatest } from 'redux-saga/effects';

import { SftpCredentialService } from '../../services';
import { AuthActions } from '../auth';
import { LoaderActions } from '../loader';
import { SnackActions } from '../snackBar';
import { default as SftpCredentialActions, types } from './actions';

function* request({ filters }) {
  const id = (filters && filters.id) || null;
  const [error, data] = id ? yield call(SftpCredentialService.getById, id) : yield call(SftpCredentialService.getAll);
  if (!error && data && data.sftp_credentials) {
    const sftpCredential = data.sftp_credentials.map((aKey) => ({
      ..._.omit(aKey, 'clinics'),
      clinicsId: (aKey?.clinics || []).map((c) => c?.id),
    }));
    yield put(SftpCredentialActions.requestSuccess(sftpCredential));
  } else {
    yield put(SnackActions.displayError('sftp_credentials_list_error'));
    yield put(SftpCredentialActions.requestFailure());
  }
}

function* upsertRequest({ sftpCredential }) {
  const isNewKey = !sftpCredential.id;
  yield put(LoaderActions.loading());
  if (sftpCredential.password && sftpCredential.password !== '') {
    sftpCredential.password = AES.encrypt(sftpCredential.password, process.env.REACT_APP_AES_SECRET_KEY).toString();
  }
  const [error, data] = isNewKey
    ? yield call(SftpCredentialService.create, _.omit(sftpCredential, ['clinics', 'id']))
    : yield call(SftpCredentialService.update, sftpCredential);
  if (!error && data) {
    yield put(SnackActions.displayInfo('sftp_create_success'));
    const newSftp = _.get(data, isNewKey ? 'insert_sftp_credentials.returning.0' : 'update_sftp_credentials.returning.0');
    yield put(SftpCredentialActions.upsertSuccess({ id: newSftp.id, ...sftpCredential }));
    yield put(LoaderActions.loaded());
    if (isNewKey) {
      yield put(AuthActions.setRedirectUrl(`/sftpCredentials/${newSftp.id}`));
    }
    yield put(AuthActions.setRedirectUrl('/sftpCredentials'));
  } else {
    yield put(SnackActions.displayError('sftp_create_error'));
    yield put(LoaderActions.loaded());
  }
}

function* deleteRequest({ id }) {
  yield put(LoaderActions.loading());
  const [error, data] = Array.isArray(id) ? yield call(SftpCredentialService.deleteMulti, id) : yield call(SftpCredentialService.delete, id);
  if (!error && data) {
    yield put(SnackActions.displayInfo('sftp_delete_success'));
    yield put(SftpCredentialActions.deleteSuccess(id));
  } else {
    yield put(SnackActions.displayError('sftp_delete_error'));
  }
  yield put(LoaderActions.loaded());
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  takeLatest(types.SFTP_REQUEST, request),
  takeLatest(types.SFTP_UPSERT_REQUEST, upsertRequest),
  takeLatest(types.SFTP_DELETE_REQUEST, deleteRequest),
];

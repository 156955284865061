import { connect } from 'react-redux';

import { SftpCredentialActions } from '../../../redux/sftpCredential';
import SftpCredential from './SftpCredential';

const mapStateToProps = ({ sftp }) => ({
  list: sftp.list || {},
  loading: sftp.loading,
});

const mapDispatchToProps = (dispatch) => ({
  sftpCredentialsRequest: () => dispatch(SftpCredentialActions.request()),
  sftpCredentialsDeleteRequest: (id) => dispatch(SftpCredentialActions.deleteRequest(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SftpCredential);

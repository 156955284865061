import { call, put, takeLatest } from 'redux-saga/effects';

import { StepsService } from '../../services';
import { LoaderActions } from '../loader';
import { SnackActions } from '../snackBar';
import { default as StepActions, types } from './actions';

function* stepsRequest({ filters }) {
  const id = (filters && filters.id) || null;
  const [error, response] = yield call(StepsService.steps, id);
  if (error) {
    yield put(SnackActions.displayError('step_list_error'));
    yield put(StepActions.stepsFailure());
    return;
  }
  const { steps } = response || {};
  if (steps) {
    yield put(StepActions.stepsSuccess(steps));
  } else {
    yield put(SnackActions.displayError('step_list_error'));
    yield put(StepActions.stepsFailure());
  }
}

function* stepsUpdateRequest({ step }) {
  yield put(LoaderActions.loading());
  const result = yield call(StepsService.stepsUpdate, step);
  if (result) {
    yield put(LoaderActions.loaded());
    yield put(SnackActions.displayInfo('step_modify_success'));
  } else {
    yield put(LoaderActions.loaded());
    yield put(SnackActions.displayError('step_modify_error'));
  }

  const update = result[1].update_steps.returning[0] || {};
  if (update) yield put(StepActions.stepsUpdateSuccess(update));
  yield put(LoaderActions.loaded());
}

function* stepsCreateRequest({ filters }) {
  yield put(LoaderActions.loading());
  const result = yield call(StepsService.stepsCreate, filters);
  if (result) {
    yield put(LoaderActions.loaded());
    yield put(SnackActions.displayInfo('step_create_success'));
  } else {
    yield put(LoaderActions.loaded());
    yield put(SnackActions.displayError('step_create_error'));
  }

  const creation = result[1].insert_steps.returning[0] || {};
  if (creation) yield put(StepActions.stepsCreateSuccess(creation));
  yield put(LoaderActions.loaded());
}

function* stepsDeleteRequest({ id }) {
  yield put(LoaderActions.loading());
  const result = yield call(StepsService.stepsDelete, id.id);
  if (result) {
    yield put(LoaderActions.loaded());
    yield put(SnackActions.displayInfo('step_delete_success'));
  } else {
    yield put(LoaderActions.loaded());
    yield put(SnackActions.displayError('step_delete_error'));
  }
  yield put(StepActions.stepsDeleteSuccess(id.id));
  yield put(LoaderActions.loaded());
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  takeLatest(types.STEPS_REQUEST, stepsRequest),
  takeLatest(types.STEPS_UPDATE_REQUEST, stepsUpdateRequest),
  takeLatest(types.STEPS_CREATE_REQUEST, stepsCreateRequest),
  takeLatest(types.STEPS_DELETE_REQUEST, stepsDeleteRequest),
];

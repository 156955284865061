export default {
  fetchAllCities: () => `
    query {
      cities {
        id
        name
        postcode
      }
    }
  `,
  fetchCityList: () => `
    query Cities($postcode: String!, $name: String!) {
      cities(
        where: { _or: { postcode: { _like: $postcode }, slug: { _ilike: $name } } }
        limit: 50
        order_by: { name: asc, postcode: asc }
      ) {
        id
        name
        postcode
      }
    }
  `,
  fetchCityListFromPostcode: () => `
    query Cities($postcode: String!) {
      cities(
        where: { postcode: { _like: $postcode } }
        limit: 50
        order_by: { name: asc, postcode: asc }
      ) {
        id
        name
        postcode
      }
    }
  `,
};

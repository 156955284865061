import { connect } from 'react-redux';

import { ClinicActions } from '../../../redux/clinics';
import { SftpCredentialActions } from '../../../redux/sftpCredential';
import { normalizeString } from '../../../utils';
import SftpCredentialCreate from './SftpCredentialCreate';

const mapStateToProps = ({ auth, sftp, clinics }) => {
  return {
    sftpList: sftp.list,
    token: auth.token,
    clinicOptions: Object.values(clinics.list)
      .map((m) => ({
        value: m.id,
        label: m.name,
      }))
      .sort((a, b) => normalizeString(a.label) - normalizeString(b.label)),
  };
};

const mapDispatchToProps = (dispatch) => ({
  sftpRequest: (id) => dispatch(SftpCredentialActions.request(id)),
  sftpUpsertRequest: (val) => dispatch(SftpCredentialActions.upsertRequest(val)),
  clinicsRequest: () => dispatch(ClinicActions.clinicsRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SftpCredentialCreate);

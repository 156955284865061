import { LockOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, Modal } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

function PaswdForm({ initialValues, updatePassword }) {
  const [form] = Form.useForm();
  const [pwdVisible, setPwdVisible] = useState(false);

  useEffect(() => {
    if (initialValues) {
      form.resetFields();
    }
  }, [initialValues]);

  const showModalPwd = () => {
    setPwdVisible(true);
  };

  const handleCancelPwd = () => {
    setPwdVisible(false);
  };

  const onFinishPwd = (values) => {
    updatePassword(values.id, values.password);
    handleCancelPwd();
  };

  return (
    <Card style={{ margin: 25, width: '80%', textAlign: 'center' }}>
      <Button type="primary" onClick={showModalPwd} style={{ width: '55%' }}>
        Modifier le mot de passe
      </Button>
      <Modal title={'Gestion de compte'} open={pwdVisible} onCancel={handleCancelPwd} footer={null}>
        <Form onFinish={onFinishPwd} initialValues={initialValues} layout="vertical" style={{ textAlign: 'center' }} form={form}>
          <Form.Item name={'id'} hidden={true}>
            <Input placeholder="id" maxLength={50} disabled />
          </Form.Item>
          <Form.Item name="password" rules={[{ required: true, message: 'Mot de passe incorrect' }]}>
            <Input prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="Nouveau mot de passe" />
          </Form.Item>
          <Form.Item
            name="passwordConfirm"
            dependencies={['password']}
            rules={[
              { required: true, message: 'Mot de passe incorrect' },
              ({ getFieldValue }) => ({
                validator(_rule, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject('Les mots de passe ne sont pas identiques');
                },
              }),
            ]}
          >
            <Input prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="Confirmer le mot de passe" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ width: '55%' }}>
              Enregistrer
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Card>
  );
}

PaswdForm.propTypes = { initialValue: PropTypes.object, updatePassword: PropTypes.func };

export default PaswdForm;

const primaryColor = '#00aecb';
const secondaryColor = '#f8f8f8';
const primaryFont = 'NunitoSans Regular';

const contains = (target, pattern) => {
  var value = 0;
  pattern.forEach(function (word) {
    value = value + target.includes(word);
  });
  return value >= 1;
};

const style = ({
  container,
  control,
  option,
  singleValue,
  menu,
  dropdownIndicator,
  indicatorSeparator,
  clearIndicator,
  multiValueLabel,
  multiValueRemove,
}) => ({
  container: (provided) => ({
    ...provided,
    ...container,
    width: '100%',
  }),
  control: (provided, state) => ({
    ...provided,
    '&:hover': { borderColor: primaryColor },
    boxShadow: 'none',
    borderColor: state.isFocused ? primaryColor : provided.borderColor,
    borderRadius: '8px',
    border: '1px solid #cfcfcf',
    ...control,
  }),
  option: (provided, state) => {
    const hand = ['Auriculaire', 'Annulaire', 'Majeur', 'Index', 'Pouce'];
    const foot = ['Hallux', 'Secundus', 'Tertius', 'Quartus', 'Quintus'];
    var strV = JSON.stringify(state.selectProps.value);
    var disabled = false;
    if (contains(strV, hand)) {
      if (contains(state.label, foot)) {
        disabled = true;
      } else {
        disabled = false;
      }
    }
    if (contains(strV, foot)) {
      if (contains(state.label, hand)) {
        disabled = true;
      } else {
        disabled = false;
      }
    }
    return {
      ...provided,
      opacity: state.isDisabled || disabled ? 0.5 : 1,
      transition: 'opacity 300ms',
      '&:hover': { color: state.isDisabled || disabled ? null : primaryColor },
      cursor: 'pointer',
      color: state.isSelected ? primaryColor : null,
      backgroundColor: state.isSelected ? secondaryColor : null,
      ...option,
    };
  },
  singleValue: (provided, state) => {
    return {
      ...provided,
      // visibility: state.isDisabled ? 'visible' : 'hidden',
      fontFamily: primaryFont,
      margin: '0',
      fontWeight: 600,
      outline: 'none',
      color: '#393d52',
      padding: '16px',
      fontSize: '20px',
      lineHeight: '1.7',
      ...singleValue,
    };
  },
  menu: (provided, state) => ({
    ...provided,
    fontFamily: primaryFont,
    fontWeight: 'Regular',
    ...menu,
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: primaryColor,
    '&:hover': { color: primaryColor },
    ...dropdownIndicator,
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    backgroundColor: primaryColor,
    ...indicatorSeparator,
  }),
  clearIndicator: (provided, state) => ({
    ...provided,
    color: primaryColor,
    '&:hover': { color: primaryColor },
    ...clearIndicator,
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: primaryColor,
    fontFamily: primaryFont,
    fontWeight: 'Regular',
    ...multiValueLabel,
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    color: primaryColor,
    '&:hover': { color: primaryColor },
    ...multiValueRemove,
  }),
  input: (provided, state) => ({
    ...provided,
    // visibility: state.isDisabled ? 'visible' : 'hidden',
    fontFamily: primaryFont,
    fontWeight: 600,
    color: '#393d52',
    padding: '16px',
    fontSize: '20px',
    lineHeight: '1.7',
    margin: '0',
    outline: 'none',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: '0',
    margin: '0',
  }),
});

export default style;

import _ from 'lodash';

import { languageMutations, languageQueries } from './GraphQLRequest';
import { authenticatedQuery } from './middleware';

/*
 *  SERVICES
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  fetchAll: () => authenticatedQuery(languageQueries.fetchAll),
  request: (id) => authenticatedQuery(languageQueries.request, {}, { id }),
  insert: (payload) => authenticatedQuery(languageMutations.insert, {}, { payload: _.omit(payload, 'id') }),
  update: (payload) => payload.id && authenticatedQuery(languageMutations.update, {}, { id: payload.id, payload: _.omit(payload, 'id') }),
  delete: (ids) => authenticatedQuery(languageMutations.delete, {}, { ids: Array.isArray(ids) ? ids : [ids] }),
};

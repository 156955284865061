import _ from 'lodash';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { QuestionsService } from '../../services';
import { LoaderActions } from '../loader';
import { SnackActions } from '../snackBar';
import { default as QuestionActions, types } from './actions';

function* questionsRequest({ filters }) {
  const id = (filters && filters.id) || null;
  const [error, response] = yield call(QuestionsService.questions, id);
  if (error) {
    yield put(SnackActions.displayError('question_list_error'));
    yield put(QuestionActions.questionsFailure());
    return;
  }
  const { questions } = response || {};
  if (questions) yield put(QuestionActions.questionsSuccess(questions));
  else {
    yield put(SnackActions.displayError('question_list_error'));
    yield put(QuestionActions.questionsFailure());
  }
}

function* questionsUpdateRequest({ question }) {
  const { list } = yield select((state) => state.getQuestionTypes);

  const key = _.findKey(list, { value: question.question_type });

  question.type_id = key;

  yield put(LoaderActions.loading());
  const result = yield call(QuestionsService.questionsUpdate, question);
  if (result) {
    yield put(LoaderActions.loaded());
    yield put(SnackActions.displayInfo('question_modify_success'));
  } else {
    yield put(LoaderActions.loaded());
    yield put(SnackActions.displayError('question_modify_error'));
  }

  const update = result[1].update_questions.returning[0] || {};
  if (update) yield put(QuestionActions.questionsUpdateSuccess(update));
  yield put(LoaderActions.loaded());
}

function* questionsCreateRequest({ filters }) {
  const { list } = yield select((state) => state.getQuestionTypes);

  const key = _.findKey(list, { value: filters.question_type });

  filters.type_id = key;

  yield put(LoaderActions.loading());
  const result = yield call(QuestionsService.questionsCreate, filters);
  if (result) {
    yield put(LoaderActions.loaded());
    yield put(SnackActions.displayInfo('question_create_success'));
  } else {
    yield put(LoaderActions.loaded());
    yield put(SnackActions.displayError('question_create_error'));
  }

  const creation = result[1].insert_questions.returning[0] || {};
  if (creation) yield put(QuestionActions.questionsCreateSuccess(creation));
  yield put(LoaderActions.loaded());
}

function* questionsDeleteRequest({ id }) {
  yield put(LoaderActions.loading());
  const result = yield call(QuestionsService.questionsDelete, id);
  if (result) {
    yield put(LoaderActions.loaded());
    yield put(SnackActions.displayInfo('question_delete_success'));
  } else {
    yield put(LoaderActions.loaded());
    yield put(SnackActions.displayError('question_delete_error'));
  }
  yield put(QuestionActions.questionsDeleteSuccess(id));
  yield put(LoaderActions.loaded());
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  takeLatest(types.QUESTIONS_REQUEST, questionsRequest),
  takeLatest(types.QUESTIONS_UPDATE_REQUEST, questionsUpdateRequest),
  takeLatest(types.QUESTIONS_CREATE_REQUEST, questionsCreateRequest),
  takeLatest(types.QUESTIONS_DELETE_REQUEST, questionsDeleteRequest),
];

import _ from 'lodash';
import { call, put, takeLatest } from 'redux-saga/effects';

import languageService from '../../services/languageService';
import { AuthActions } from '../auth';
import { default as LanguageActions, types } from './actions';

function* fetchAll() {
  yield put(LanguageActions.startLoader());
  const [error, data] = yield call(languageService.fetchAll);
  if (!error && data) {
    yield put(LanguageActions.fetchAllSuccess(_.get(data, 'languages')));
  }
}

function* request({ id }) {
  yield put(LanguageActions.startLoader());
  const [error, data] = yield call(languageService.request, id);
  if (!error && data) {
    yield put(LanguageActions.fetchAllSuccess(_.get(data, 'languages'), true));
  }
}

function* upsert({ payload }) {
  yield put(LanguageActions.startLoader());
  const [error, data] = payload.id ? yield call(languageService.update, payload) : yield call(languageService.insert, payload);
  if (!error && data) {
    const id = _.get(data, [payload.id ? 'update_languages' : 'insert_languages', 'returning', 0, 'id']);
    yield put(LanguageActions.fetchAllSuccess({ ...payload, id }, true));
    yield put(AuthActions.setRedirectUrl('/languages'));
  }
}

function* deleteById({ ids }) {
  yield put(LanguageActions.startLoader());
  const [error, data] = yield call(languageService.delete, ids);
  if (!error && data) {
    yield put(LanguageActions.deleteSuccess(ids));
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  takeLatest(types.FETCH_ALL, fetchAll),
  takeLatest(types.REQUEST, request),
  takeLatest(types.UPSERT, upsert),
  takeLatest(types.DELETE, deleteById),
];

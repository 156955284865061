import './templates.css';

import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { filterAndOrderList } from '../../../utils/utils';
import Filter from '../../components/filter';
import { TAGS, Tags } from '../../components/tags';
import DataTable from './DataTable';

const MEDIC_TEAMPLATE_FILTER_KEYS = ['translation.title', 'medic.lastname', 'medical_team.title', 'medical_team.title'];
const STORE_TEAMPLATE_FILTER_KEYS = ['translation.title'];

function updateObjectInArray(array, payload) {
  const index = array.findIndex((t) => t.tagIndex === payload.tagIndex);
  if (index < 0) {
    return [...array, payload];
  } else {
    return array.map((item, i) => {
      return index !== i
        ? item
        : {
            ...item,
            ...payload,
          };
    });
  }
}

const Templates = ({
  surveyTypes,
  surveyTypesRequest,
  getTemplates,
  getSpecialities,
  getMedicTemplates,
  deleteTemplates,
  updatePublishedStatus,
  resetStatusChanged,
  specialities,
  deleteMedicTemplates,
  loading,
  stepClear,
  questionClear,
  templates,
  medicTemplates,
  statusChanged,
  duplicateTemplates,
  duplicateMedicTemplates,
  isStoreTemplate,
  displayLanguageId,
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [urlParams, setUrlParams] = useState();
  useEffect(() => {
    setUrlParams(Object.fromEntries([...searchParams]));
  }, [searchParams]);
  const templateConst = {
    type: isStoreTemplate ? 'templates' : 'medicTemplates',
    title: isStoreTemplate ? 'Store Templates' : 'Medic Templates',
  };
  const [filtered, setFiltered] = useState();
  const [reset, setReset] = useState(false);
  const [valueList, setValueList] = useState([]);
  const [assigneds, setAssigneds] = useState([]);
  const [activeFilters, setActiveFilters] = useState({
    [TAGS[templateConst.type].OWNERS]: { order: 0 },
    [TAGS[templateConst.type].TYPES]: { order: 0 },
    [TAGS[templateConst.type].SPECIALITIES]: { order: 0 },
  });
  const [initialSurveyList, setInitialSurveyList] = useState([]);

  useEffect(() => {
    stepClear();
    questionClear();
    surveyTypesRequest({});
    getSpecialities();
  }, []);

  useEffect(() => {
    if (isStoreTemplate) {
      getTemplates();
    } else {
      getMedicTemplates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStoreTemplate]);

  useEffect(() => {
    const list = isStoreTemplate ? [...(templates || [])] : [...(medicTemplates || [])];
    if (Array.isArray(list)) {
      setInitialSurveyList(list);
    } else {
      setInitialSurveyList([]);
    }
  }, [isStoreTemplate, medicTemplates, templates]);

  useEffect(() => {
    if (Array.isArray(initialSurveyList)) {
      if (isStoreTemplate) {
        setValueList(initialSurveyList);
      } else {
        const tmp = initialSurveyList
          .filter((v) => v && v.medic && v.medic.id)
          .map((v) => v.medic)
          .reduce((acc, val) => {
            if (!acc.find((el) => el.id === val.id)) {
              acc.push(val);
            }
            return acc;
          }, [])
          .sort((a, b) => a.id - b.id);
        if (tmp.map((el) => el.id).join('.') !== assigneds.map((el) => el.id).join('.')) {
          setAssigneds(Object.values(tmp));
          setValueList(initialSurveyList);
        }
      }
    }
  }, [initialSurveyList]);

  useEffect(() => {
    if (statusChanged === true) {
      setValueList(initialSurveyList);
      if (resetStatusChanged) {
        resetStatusChanged();
      }
    }
  }, [statusChanged, initialSurveyList]);

  const tags = useMemo(() => {
    let newTags = [];
    // types
    if (TAGS[templateConst.type].TYPES) {
      const surveyTypeOptions = surveyTypes.map((el) => ({
        id: el.id,
        value: displayLanguageId ? _.get(el, ['translations', displayLanguageId, 'title']) : _.get(el, ['translation', 'title']),
      }));
      const payloadType = {
        list: surveyTypeOptions,
        tagIndex: TAGS[templateConst.type].TYPES,
        title: 'Choix du type de formulaire :',
        placeholder: 'Type du formulaire',
      };
      newTags = updateObjectInArray(newTags, payloadType);
    }

    // specialities
    if (TAGS[templateConst.type].SPECIALITIES) {
      const specialitieOptions = specialities.map((el) => ({
        id: el.id,
        value: displayLanguageId ? _.get(el, ['translations', displayLanguageId, 'value']) : _.get(el, ['translation', 'title']),
      }));
      const payloadSpe = {
        list: specialitieOptions,
        tagIndex: TAGS[templateConst.type].SPECIALITIES,
        title: 'Choix de la spécialité de formulaire :',
        placeholder: 'Spécialité du formulaire',
      };
      newTags = updateObjectInArray(newTags, payloadSpe);
    }

    if (TAGS[templateConst.type].OWNERS) {
      const formatedMedics = [{ id: -1, firstname: 'Administrateur', lastname: '', value: 'Administrateur' }].concat(
        assigneds.map((m) => ({ ...m, value: m.firstname + ' ' + m.lastname })),
      );
      const formatedMedicOptions = formatedMedics.map((el) => ({ id: el.id, value: el.value }));
      const payloadOwner = {
        list: formatedMedicOptions,
        tagIndex: TAGS[templateConst.type].OWNERS,
        title: 'Choix du propriétaire de formulaire :',
        placeholder: 'Propriétaire du formulaire',
      };
      newTags = updateObjectInArray(newTags, payloadOwner);
    }

    return newTags;
  }, [surveyTypes, displayLanguageId, specialities, assigneds, templateConst.type]);

  const handleFilter = (result) => {
    if (!result) {
      handleTags([-1], 'none');
      setFiltered();
    } else {
      const newFiltered =
        Array.isArray(filtered) && filtered.length ? result.map((r) => r.item.id).filter((r) => filtered.includes(r)) : result.map((r) => r.item.id);
      setFiltered(newFiltered.length ? newFiltered : [-1]);
      setReset(!reset);
    }
  };

  const onModify = (survey) => {
    stepClear();
    questionClear();
    navigate(`/${templateConst.type}/${survey.id}`, survey);
  };

  const handleDuplicate = async (survey) => {
    if (isStoreTemplate) {
      duplicateTemplates(survey.id);
    } else {
      duplicateMedicTemplates(survey.id);
    }
  };

  const onDelete = (ids) => {
    if (!Array.isArray(ids)) {
      ids = [ids.id];
    }
    if (isStoreTemplate) {
      deleteTemplates(ids);
    } else {
      deleteMedicTemplates(ids);
    }
  };

  const onPublished = (IDs, published) => {
    if (isStoreTemplate) {
      updatePublishedStatus(IDs, published);
    }
  };

  const handleTags = (value, mode) => {
    const surveyTags = TAGS[templateConst.type];
    const types = activeFilters[surveyTags.TYPES];
    const specialities = activeFilters[surveyTags.SPECIALITIES];
    const owners = activeFilters[surveyTags.OWNERS];
    let newActivesFilters = { ...activeFilters };
    if (!value || !value.length) {
      setReset(true);
      newActivesFilters[mode] = { order: 0 };
    } else {
      switch (mode) {
        case surveyTags.TYPES:
          newActivesFilters[mode].order = Math.max(specialities.order, owners.order) + 1;
          newActivesFilters[mode].filter = (values) => values.filter((s) => s && value.includes(s.type_id));
          break;
        case surveyTags.SPECIALITIES:
          newActivesFilters[mode].order = Math.max(types.order, owners.order) + 1;
          newActivesFilters[mode].filter = (values) => values.filter((s) => value.includes(s.speciality_id));
          break;
        case surveyTags.OWNERS: {
          newActivesFilters[mode].order = Math.max(types.order, specialities.order) + 1;
          newActivesFilters[mode].filter = (tList) =>
            tList.filter((t) => {
              if (value.includes(-1) && t.__typename === 'templates') {
                return true;
              }
              return !!(value.map((v) => `${v}`).includes(`${t.medic_id}`) && t.__typename === 'medic_templates');
            });
          break;
        }
        default:
          break;
      }
    }
    let newFiltered = [...initialSurveyList];
    // apply filters for suveey list
    Object.values(newActivesFilters)
      .filter((f) => f.order > 0)
      .sort((a, b) => a.order - b.order)
      .forEach((f) => {
        newFiltered = f.filter && f.filter(newFiltered);
      });
    setActiveFilters(newActivesFilters);
  };

  const filterFromFilters = (templates) => {
    let newFiltered = [...initialSurveyList];
    Object.values(activeFilters)
      .filter((f) => f.order > 0)
      .sort((a, b) => a.order - b.order)
      .forEach((f) => {
        newFiltered = f.filter && f.filter(newFiltered);
      });
    return newFiltered;
  };

  const exportMapper = (toExport) =>
    toExport.map((e) => ({
      id: e.id,
      Titre: e.title,
      Type: e.survey_type.value,
      Spécialité: e.speciality && e.speciality.value,
      'Crée par': e.store_id > 0 || !e.medic ? 'Store' : 'Praticien',
      'Attribué à': e.medic ? e.medic.lastname + ' ' + e.medic.firstname : null,
    }));

  const exportDataToXls = () =>
    exportMapper(Array.isArray(filtered) && filtered.length ? valueList.filter((r) => filtered.includes(r.id)) : valueList);

  return (
    <>
      <Tags data={tags} tagIndex={templateConst.type} onTags={handleTags} exportFct={exportDataToXls} />
      <Filter list={[...valueList]} onFilter={handleFilter} keys={isStoreTemplate ? STORE_TEAMPLATE_FILTER_KEYS : MEDIC_TEAMPLATE_FILTER_KEYS} />
      <DataTable
        items={filterAndOrderList(filterFromFilters(valueList), filtered)}
        reset={reset}
        loading={loading}
        itemClick={onModify}
        onDelete={onDelete}
        onModify={onModify}
        onDuplicate={handleDuplicate}
        onPublished={onPublished}
        resetRowKeys={statusChanged}
        surveyTypes={surveyTypes}
        displayLanguageId={displayLanguageId}
        isStoreTemplate={isStoreTemplate}
        urlParams={urlParams}
      />
    </>
  );
};

export default Templates;

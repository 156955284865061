/**
 * GRAPHQL FRAGMENT
 */

import { CITY_MINIMAL_FIELDS } from './cityFragments';
import { USER_CORE_FIELDS } from './userFragments';

// eslint-disable-next-line import/no-anonymous-default-export
export const PATIENT_MINIMAL_FIELDS = `
  fragment patientsMinimalFields on patients {
    id
    gender
    firstname
    lastname
    use_name
    security_number
    email
    birthdate
    phone_number
    local_phone_number
    is_tutor
    medical_teams_id
    medic_id
    language_id
  }
`;

export const PATIENT_CORE_FIELDS = `
  ${USER_CORE_FIELDS}
  ${CITY_MINIMAL_FIELDS}
  fragment patientsCoreFields on patients {
    created_at
    updated_at
    is_tutor
    id
    gender
    firstname
    lastname
    use_name
    security_number
    language_id
    insis{
      id
      insi_full
    }
    phone_number
    local_phone_number
    email
    birthdate
    birthplace
    birthplace_postcode
    cityByBirthplaceId {
      ...cityMinimalFields
    }
    address
    city
    postcode
    cityByCityId {
      ...cityMinimalFields
    }
    medical_teams_id
    medic_id
    user {
      ...usersCoreFields
    }
  }
`;

export const PATIENT_DETAILED_FIELDS = `
  ${PATIENT_CORE_FIELDS}
  fragment patientsDetailedFields on patients {
    ...patientsCoreFields
    tutorsByPatientId {
      signatory {
        ...patientsCoreFields
      }
    }
    surveys {
      id
      title
      is_signed
      status
      tutored_id
      survey_type {
        id
        value
      }
      survey_drawing_id
      survey_drawing {
        loid
        ext
      }
      survey_attachment_id
      survey_attachment {
        loid
        ext
      }
      intervention {
          id
          date
          number
          type
      }
    }
  }
`;

import { createAction } from '../../utils';

// Types
export const types = {
  CITIES_FETCH_ALL: 'CITIES_FETCH_ALL',
  CITIES_FETCH_QUERY: 'CITIES_FETCH_QUERY',
  CITIES_FETCH_BEGIN: 'CITIES_FETCH_BEGIN',
  CITIES_FETCH_SUCCESS: 'CITIES_FETCH_SUCCESS',
  CITIES_BIRTHPLACE_FETCH_SUCCESS: 'CITIES_BIRTHPLACE_FETCH_SUCCESS',
  CITIES_FETCH_FAILURE: 'CITIES_FETCH_FAILURE',
  CITIES_CLEAR_LIST: 'CITIES_CLEAR_LIST',
};

export default {
  fetchCityList: (q, cityType) => createAction(types.CITIES_FETCH_QUERY, { q, cityType }),
  fetchBegin: () => createAction(types.CITIES_FETCH_BEGIN),
  fetchCityListSuccess: (cities) => createAction(types.CITIES_FETCH_SUCCESS, { cities }),
  fetchBirthplaceListSuccess: (cities) => createAction(types.CITIES_BIRTHPLACE_FETCH_SUCCESS, { cities }),
  fetchCityListFailure: () => createAction(types.CITIES_FETCH_FAILURE),

  clearCityList: () => createAction(types.CITIES_CLEAR_LIST),
  fetchAllCities: () => createAction(types.CITIES_FETCH_ALL),
};

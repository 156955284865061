import _ from 'lodash';
import { createReducer } from 'reduxsauce';

import { types } from './actions';

const initialState = {
  list: {},
  loading: false,
  hasMore: true,
};

const startLoader = (state = initialState) => ({ ...state, loading: true });

const stopLoader = (state = initialState) => ({ ...state, loading: false });

const rolesCreateSuccess = (state = initialState, action) => {
  const { list } = state;
  const { role } = action;
  return { ...state, list: { ...list, [role.id]: role }, loading: false };
};

const rolesSuccess = (state = initialState, action) => {
  const { roles } = action;
  const newList = _.keyBy(roles, 'id');
  return { ...state, list: newList, loading: false };
};

const specialityDeleteSuccess = (state = initialState, action) => {
  const { id } = action;
  const { list } = state;
  return { ...state, list: _.omit(list, Array.isArray(id) ? id : [id]), loading: false };
};

export default createReducer(initialState, {
  [types.ROLES_CREATE_REQUEST]: startLoader,
  [types.ROLES_CREATE_SUCCESS]: rolesCreateSuccess,

  [types.ROLES_UPDATE_REQUEST]: startLoader,
  [types.ROLES_UPDATE_SUCCESS]: stopLoader,

  [types.ROLES_REQUEST]: startLoader,
  [types.ROLES_SUCCESS]: rolesSuccess,
  [types.ROLES_FAILURE]: stopLoader,

  [types.ROLES_DELETE_REQUEST]: startLoader,
  [types.ROLES_DELETE_SUCCESS]: specialityDeleteSuccess,
});

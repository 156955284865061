import _ from 'lodash';

import { authenticatedMutation, authenticatedQuery } from './middleware';

/**
 * GRAPHQL QUERIES
 */
const queries = {
  getAll: () => `
    query GetRoles {
      roles {
        id
        name
        is_backend
      }
    }
  `,
  getById: () => `
    query Roles($id: Int) {
      roles(where: { id: { _eq: $id } }) {
        id
        name
        permissions
        is_backend
        is_clinical_study
      }
    }
  `,
};
/**
 * GRAPHQL MUTATION
 *
 */

const mutations = {
  rolesCreate: () => `
    mutation InsertRole($roles: [roles_insert_input!]!) {
      insert_roles(objects: $roles) {
        returning {
          id
        }
      }
    }
  `,
  rolesUpdate: () => `
    mutation UpdateRole($id: Int!, $roles: roles_set_input!) {
      update_roles(where: {id: {_eq: $id}}, _set: $roles) {
        returning {
          id
        }
      }
    }
  `,
  rolesDelete: () => `
    mutation DeleteRole($id: Int) {
      delete_roles(where: {id: {_eq: $id}}) {
        returning {
          id
        }
      }
    }
  `,
  rolesDeleteMulti: () => `
    mutation DeleteMultiRole($ids: [Int!]!) {
      delete_roles(where: {id: {_in: $ids}}) {
        returning {
          id
        }
      }
    }
  `,
};

export default {
  getAll: () => authenticatedQuery(queries.getAll(), {}, {}),
  getById: (id = null) => authenticatedQuery(queries.getById(), {}, { id }),
  rolesCreate: (roles) => authenticatedMutation(mutations.rolesCreate(), {}, { roles }),
  rolesUpdate: (roles) => authenticatedMutation(mutations.rolesUpdate(), {}, { id: roles.id, roles: _.omit(roles, ['id']) }),
  rolesDelete: (id) => authenticatedMutation(mutations.rolesDelete(id), {}, { id }),
  rolesDeleteMulti: (ids) => authenticatedMutation(mutations.rolesDeleteMulti(), {}, { ids }),
};

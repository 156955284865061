import React from 'react';
import { useTranslation } from 'react-i18next';

import { CustomFormController } from '../../../components/formV3';
import { RichTextWithDraft } from '../../../components/formV3/controllers/RichTextWithDraft/RichTextWithDraft';
import Modal from '../../../components/modal';
import { Button } from '../../../components/styledComponent';

const Rewording = ({ prefixName, disabled, onExit, isOpen }) => {
  const prefix = prefixName ? `${prefixName}.` : '';
  const { t } = useTranslation();
  const handleOnExit = () => {
    if (onExit) {
      onExit();
    }
  };

  return (
    <Modal title={t('rewording_card_title')} modalClosed={handleOnExit} scrollable isOpen={isOpen}>
      <CustomFormController name={`${prefix}rewording`}>
        <RichTextWithDraft editableOnClick={false} disabled={disabled} />
      </CustomFormController>
      <Button onClick={handleOnExit} type="button">
        {t('ok')}
      </Button>
    </Modal>
  );
};

export default Rewording;

import { createAction } from '../../utils';

// Types
export const types = {
  SURVEYS_REQUEST: 'SURVEYS_REQUEST',
  SURVEYS_FAILURE: 'SURVEYS_FAILURE',
  SURVEYS_SUCCESS: 'SURVEYS_SUCCESS',

  SURVEY_CURRENT_DELETE: 'SURVEY_CURRENT_DELETE',

  SURVEYS_UPDATE_REQUEST: 'SURVEYS_UPDATE_REQUEST',
  SURVEYS_UPDATE_FAILURE: 'SURVEYS_UPDATE_FAILURE',
  SURVEYS_UPDATE_SUCCESS: 'SURVEYS_UPDATE_SUCCESS',

  SURVEYS_CREATE_REQUEST: 'SURVEYS_CREATE_REQUEST',
  SURVEYS_CREATE_FAILURE: 'SURVEYS_CREATE_FAILURE',
  SURVEYS_CREATE_SUCCESS: 'SURVEYS_CREATE_SUCCESS',

  SURVEYS_DELETE_REQUEST: 'SURVEYS_DELETE_REQUEST',
  SURVEYS_DELETE_SUCCESS: 'SURVEYS_DELETE_SUCCESS',

  SURVEY_CURRENT_REQUEST: 'SURVEY_CURRENT_REQUEST',
  SURVEY_CURRENT_SUCCESS: 'SURVEY_CURRENT_SUCCESS',
  SURVEY_CURRENT_FAILURE: 'SURVEY_CURRENT_FAILURE',
};

// Actions
export default {
  /* CREATE */
  surveysCreateRequest: (filters = {}) => createAction(types.SURVEYS_CREATE_REQUEST, { filters }),
  surveysCreateSuccess: () => createAction(types.SURVEYS_CREATE_SUCCESS),

  /* UPDATE */
  surveysUpdateRequest: (survey = {}) => createAction(types.SURVEYS_UPDATE_REQUEST, { survey }),
  surveysUpdateSuccess: () => createAction(types.SURVEYS_UPDATE_SUCCESS),

  /** REQUEST */
  surveysRequest: (filters = {}) => createAction(types.SURVEYS_REQUEST, { filters }),
  surveysSuccess: (surveys) => createAction(types.SURVEYS_SUCCESS, { surveys }),
  surveysFailure: () => createAction(types.SURVEYS_FAILURE),

  surveyCurrentDelete: () => createAction(types.SURVEY_CURRENT_DELETE),

  surveysDeleteRequest: (survey) => createAction(types.SURVEYS_DELETE_REQUEST, { survey }),
  surveysDeleteSuccess: (survey) => createAction(types.SURVEYS_DELETE_SUCCESS, { survey }),

  surveyCurrentRequest: (id) => createAction(types.SURVEY_CURRENT_REQUEST, { id }),
  surveyCurrentSuccess: (surveys) => createAction(types.SURVEY_CURRENT_SUCCESS, { surveys }),
  surveyCurrentFailure: () => createAction(types.SURVEY_CURRENT_FAILURE),
};

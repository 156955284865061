import { call, put, takeLatest } from 'redux-saga/effects';

import { QuestionTypesService } from '../../services';
import { AuthActions } from '../auth';
import { LoaderActions } from '../loader';
import { SnackActions } from '../snackBar';
import { default as QuestionTypesActions, types } from './actions';

function* questionTypesRequest({ filters }) {
  const id = (filters && filters.id) || null;
  const [error, response] = id ? yield call(QuestionTypesService.questionType, id) : yield call(QuestionTypesService.getQuestionTypes);
  if (error) {
    yield put(SnackActions.displayError('questionType_list_error'));
    yield put(QuestionTypesActions.questionTypesFailure());
    return;
  }
  if (response) yield put(QuestionTypesActions.questionTypesSuccess(response));
  else {
    yield put(SnackActions.displayError('questionType_list_error'));
    yield put(QuestionTypesActions.questionTypesFailure());
  }
}

function* questionTypesUpdateRequest({ questionType }) {
  yield put(LoaderActions.loading());
  const result = yield call(QuestionTypesService.questionTypesUpdate, questionType);
  if (result) {
    yield put(LoaderActions.loaded());
    yield put(SnackActions.displayInfo('questionType_modify_success'));
  } else {
    yield put(LoaderActions.loaded());
    yield put(SnackActions.displayError('questionType_modify_error'));
  }
  yield put(QuestionTypesActions.questionTypesUpdateSuccess({}));
  yield put(QuestionTypesActions.questionTypesRequest({}));
  yield put(LoaderActions.loaded());
  yield put(AuthActions.setRedirectUrl('/questionTypes'));
}

function* questionTypesCreateRequest({ filters }) {
  yield put(LoaderActions.loading());
  const result = yield call(QuestionTypesService.questionTypesCreate, filters);
  if (result) {
    yield put(SnackActions.displayInfo('questionType_create_success'));
  } else {
    yield put(SnackActions.displayError('questionType_create_error'));
  }
  yield put(QuestionTypesActions.questionTypesCreateSuccess({}));
  yield put(QuestionTypesActions.questionTypesRequest({ id: filters.questionTypes }));
  yield put(LoaderActions.loaded());
  yield put(AuthActions.setRedirectUrl('/questionTypes'));
}

function* questionTypesDeleteRequest({ id }) {
  yield put(LoaderActions.loading());
  const result = yield call(QuestionTypesService.questionTypesDelete, id);
  if (result) {
    yield put(LoaderActions.loaded());
    yield put(SnackActions.displayInfo('questionType_delete_success'));
  } else {
    yield put(LoaderActions.loaded());
    yield put(SnackActions.displayError('questionType_delete_error'));
  }
  yield put(QuestionTypesActions.questionTypesDeleteSuccess(id));
  yield put(QuestionTypesActions.questionTypesRequest({}));
  yield put(LoaderActions.loaded());
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  takeLatest(types.QUESTION_TYPES_REQUEST, questionTypesRequest),
  takeLatest(types.QUESTION_TYPES_UPDATE_REQUEST, questionTypesUpdateRequest),
  takeLatest(types.QUESTION_TYPES_CREATE_REQUEST, questionTypesCreateRequest),
  takeLatest(types.QUESTION_TYPES_DELETE_REQUEST, questionTypesDeleteRequest),
];

import { connect } from 'react-redux';

import { SurveyActions } from '../../../../../../redux/surveys';
import Step from './Step';

const mapStateToProps = ({ questionTypes }) => {
  return { questionTypes: Object.values((questionTypes && questionTypes.list) || {}) };
};

const mapDispatchToProps = (dispatch) => ({ questionTypesRequest: () => dispatch(SurveyActions.questionTypesRequest()) });

export default connect(mapStateToProps, mapDispatchToProps)(Step);

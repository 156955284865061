import _ from 'lodash';
import { connect } from 'react-redux';

import SurveyDocumentViewer from './SurveyDocumentViewer';

const mapStateToProps = ({ auth, survey, medicTemplates, templates }, props) => {
  const { survey: surveyProps } = props;
  const { currentSurvey } = props;
  const currentSurveyRedux = _.get(survey, ['currentSurvey']) || medicTemplates.selectedTemplate || templates.selectedTemplate;
  return {
    token: auth.token || null,
    survey: surveyProps || currentSurvey || currentSurveyRedux,
  };
};

export default connect(mapStateToProps, null)(SurveyDocumentViewer);

import { Button, Form, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import SurveyTypesPane from './SurveyTypesPane';
import { CONFIG_KEY, DATA_KEY, TEMPLATES } from './surveyTypesPaneConfig';
const { TabPane } = Tabs;

const SurveyTypesTabs = ({ formMethods, config, name }) => {
  const { t } = useTranslation();
  const [formattedConfig, setFormattedConfig] = useState(null);
  const [activeTab, setActiveTab] = useState('0');

  useEffect(() => {
    if (config && !formattedConfig) {
      const keys = Object.keys(config);
      if (Array.isArray(keys) && keys.length) {
        const newConfig = {};
        Array.isArray(keys) &&
          keys.forEach((key) => {
            const current = config[key];
            const dataKey = DATA_KEY[CONFIG_KEY[key]];
            if (dataKey) newConfig[key] = current.map((curr) => ({ value: curr.id, label: t(curr[dataKey].replaceAll('.', '_')) }));
          });
        newConfig['templates'] = TEMPLATES.map((t, index) => ({ value: index + 1, label: t }));
        setFormattedConfig(newConfig);
      }
    }
  }, [config]);

  const addCron = (add, size) => {
    add();
    setActiveTab(size);
  };

  const removeCron = (remove, index) => {
    remove(index);
    setActiveTab(0);
  };

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <Form.List name={name}>
      {(fields, { add, remove }) => (
        <>
          <Tabs activeKey={`${activeTab}`} onTabClick={handleTabClick}>
            {(fields || []).map((field, index) => (
              <TabPane tab={`Rappel n° ${index + 1}`} key={`${index}`} forceRender>
                <SurveyTypesPane
                  index={index}
                  name={name}
                  field={field}
                  formMethods={formMethods}
                  onDeletePane={(i) => removeCron(remove, i)}
                  config={config}
                />
              </TabPane>
            ))}
          </Tabs>
          <Button onClick={() => addCron(add, fields.length)} htmlType="button">
            {t('add_a_cron')}
          </Button>
        </>
      )}
    </Form.List>
  );
};

export default SurveyTypesTabs;

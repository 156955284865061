import './style.css';

import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { SURVEY_TYPES } from '../../../../../utils/dataMappers';
import { CheckboxFull, DropdownList, MultipleChoice, PDF, TextAnswer, Validation } from '..';

const components = (typeId, questionTypes) => {
  const type = (questionTypes || []).find((el) => el.id === typeId);
  const value = (type && type.value) || '';
  if (value) {
    switch (value) {
      case SURVEY_TYPES.multiple_choice:
        return MultipleChoice;
      case SURVEY_TYPES.text_answer:
        return TextAnswer;
      case SURVEY_TYPES.dropdown_list:
        return DropdownList;
      case SURVEY_TYPES.pdf:
        return PDF;
      case SURVEY_TYPES.checkbox_full:
        return CheckboxFull;
      case SURVEY_TYPES.validation:
        return Validation;
      default:
        break;
    }
  }
  return null;
};

const QuestionComponent = ({ question, questionTypes, ...props }) => {
  const QuestionC = useMemo(() => components(question.type_id, questionTypes), [question?.type_id, questionTypes]);

  return QuestionC && <QuestionC question={question} questionTypes={questionTypes} {...props} />;
};

QuestionComponent.propTypes = {
  question: PropTypes.object,
  questionTypes: PropTypes.array,
};

export default QuestionComponent;

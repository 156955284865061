import { connect } from 'react-redux';

import { PatientActions } from '../../../redux/patients';
import Patients from './Patients';

const mapStateToProps = ({ patients }) => ({
  list: Object.values(patients.list) || {},
  loading: patients.loading,
});

const mapDispatchToProps = (dispatch) => ({
  patientsRequest: (filter) => dispatch(PatientActions.patientsRequest(filter)),
  patientsDeleteRequest: (patient) => dispatch(PatientActions.patientsDeleteRequest(patient)),
  patientsDeleteMultipleRequest: (ids) => dispatch(PatientActions.patientsDeleteMultipleRequest(ids)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Patients);

import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import ClinicsForm from '../../components/clinicsForm/ClinicsForm';

const ClinicsCreate = ({
  clinicsList,
  clinicsRequest,
  clinicsCreateRequest,
  clinicsUpdateRequest,
  medicsRequest,
  medicList,
  cityList,
  fetchCityList,
  clearCityList,
}) => {
  const { clinicId } = useParams();
  const id = clinicId && !['clinics', 'add'].includes(clinicId) ? clinicId : null;
  const [initialValues, setInitialValues] = useState();
  const form = useMemo(() => {
    return id && clinicsList && clinicsList[id];
  }, [id, clinicsList]);

  useEffect(() => {
    medicsRequest();
  }, []);

  useEffect(() => {
    id ? clinicsRequest({ id }) : clinicsRequest();
  }, [id]);

  useEffect(() => {
    if (form?.postcode) {
      fetchCityList(form?.postcode);
    }
  }, [form?.postcode]);

  useEffect(() => {
    if (form) {
      setInitialValues({ ..._.omit(form, ['surveys_aggregate', 'clinic_medics']), medic_id: (form?.clinic_medics || []).map((m) => m.medic_id) });
    }
  }, [form]);

  const onValidate = (value) => {
    if (form == null) {
      clinicsCreateRequest(value);
    } else {
      clinicsUpdateRequest({ ...value, id: form.id });
    }
  };

  return (
    <>
      {id && id !== '' && <div>Consentement signé(s) le mois dernier : {form && form.surveys_aggregate.aggregate.count}</div>}
      <ClinicsForm
        onValidate={onValidate}
        initialValues={initialValues}
        medics={medicList}
        cityList={cityList}
        fetchCityList={fetchCityList}
        clearCityList={clearCityList}
      />
    </>
  );
};

export default ClinicsCreate;

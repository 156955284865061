import _ from 'lodash';
import { createReducer } from 'reduxsauce';

import { types } from './actions';

const initialState = {
  list: {},
};

const requestSuccess = (state = initialState, action) => {
  const { list } = state;
  const { data } = action;

  return { ...state, list: { ...list, ..._.keyBy(data, 'id') } };
};

const upsertSuccess = (state = initialState, action) => {
  const { list } = state;
  const { data } = action;

  return { ...state, list: { ...list, ..._.keyBy(data, 'id') } };
};
const deleteSuccess = (state = initialState, action) => {
  const { list } = state;
  const { ids } = action;

  return { ...state, list: _.omit(list, ids) };
};

export default createReducer(initialState, {
  [types.REQUEST_SUCCESS]: requestSuccess,
  [types.UPSERT_SUCCESS]: upsertSuccess,
  [types.DELETE_SUCCESS]: deleteSuccess,
});

import { connect } from 'react-redux';

import { SmsActions } from '../../../redux/sms';
import Sms from './Sms';

const mapStateToProps = ({ sms }) => ({
  list: sms.list || {},
  loading: sms.loading,
});

const mapDispatchToProps = (dispatch) => ({
  smsRequest: (id) => dispatch(SmsActions.smsRequest(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sms);

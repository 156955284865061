import { HomeOutlined, LogoutOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-layout';
import { Breadcrumb, Button, Col, Layout, Row } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useParams } from 'react-router-dom';

import { RoutesMapper } from '../../../utils/const';
import SideMenu from '../../components/sideMenu';
import Extra from './Extra';

const { Header, Content, Footer } = Layout;

/**
 * Function converts path like /user/123 to /user/:id
 */
const getRoutePath = (location, params) => {
  const { pathname } = location;

  if (!Object.keys(params).length) {
    return pathname; // we don't need to replace anything
  }

  let path = pathname;
  Object.entries(params).forEach(([paramName, paramValue]) => {
    if (paramValue) {
      path = path.replace(paramValue, `:${paramName}`);
    }
  });
  return path;
};

const AuthLayoutOutlet = ({ logout, subtitle, tags, footer, content, extraContent, fetchAllLanguages, sideMenuRoutes }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const params = useParams();
  const [collapsed, setCollapsed] = React.useState(false);
  const path = getRoutePath(location, params);
  const parentPath = location.pathname.split('/').length > 2 ? location.pathname.split('/').slice(0, 2).join('/') : null;

  React.useEffect(() => {
    fetchAllLanguages && fetchAllLanguages();
  }, []);

  React.useEffect(() => {}, [location]);

  const current = useMemo(() => {
    const route = sideMenuRoutes ? [...sideMenuRoutes].find((el) => el.path === (parentPath || location.pathname)) : {};
    return route?.key || 0;
  }, [sideMenuRoutes, location?.pathname]);

  const route = useMemo(() => RoutesMapper(path), [location?.pathname, path]);
  const bread = useMemo(
    () => `${parentPath ? `${t(RoutesMapper(parentPath).title)}:${parentPath};` : ''}${t(RoutesMapper(path).title)}:${location.pathname}`,
    [parentPath, path],
  );
  const title = useMemo(() => t(RoutesMapper(path).title), [path]);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const handleLogout = () => {
    logout();
  };

  return (
    <SideMenu current={current} inlineCollapsed={collapsed}>
      <Header
        style={{
          background: '#fff',
          padding: 0,
          zIndex: 2,
          boxShadow: '0 1px 4px rgba(0, 21, 41, 0.08)',
        }}
      >
        <Row type="flex" justify="space-between">
          <Col span={4}>
            {collapsed ? (
              <MenuUnfoldOutlined onClick={toggleCollapsed} style={{ marginLeft: 15 }} />
            ) : (
              <MenuFoldOutlined onClick={toggleCollapsed} style={{ marginLeft: 15 }} />
            )}
          </Col>
          <Col span={1}>
            <Button onClick={handleLogout} icon={<LogoutOutlined />} shape="circle"></Button>
          </Col>
        </Row>
      </Header>
      <div style={{ background: '#fff', padding: 0 }}>
        <Breadcrumb
          style={{ margin: '16px 16px' }}
          items={[
            {
              title: (
                <a href="/">
                  <HomeOutlined />
                </a>
              ),
            },
            ...bread.split(';').map((b) => ({
              title: (
                <a href={`${b.split(':')[1]}`} disabled={b.split(':')[1] === undefined}>
                  {b.split(':')[0]}
                </a>
              ),
            })),
          ]}
        >
          {}
        </Breadcrumb>
      </div>
      <PageHeader
        title={title}
        subTitle={subtitle}
        tags={tags}
        extra={route.showCreateBtn ? <Extra urlToManagementPage={`${location.pathname}/add`} btnTitle={route.btnTitle} /> : null}
        footer={footer}
      >
        <div className="wrap">
          <div className="content">{content}</div>
          <div className="extraContent">{extraContent}</div>
        </div>
      </PageHeader>
      <Content style={{ margin: '16px 16px' }}>
        <div>
          <Outlet />
        </div>
      </Content>
      <Footer style={{ textAlign: 'center' }}></Footer>
    </SideMenu>
  );
};

export default AuthLayoutOutlet;

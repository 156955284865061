import { Button, List, Popover, Space, Table } from 'antd';
import parse from 'html-react-parser';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { filterAndOrderList } from '../../../utils/utils';
import Filter from '../filter';
import Modal from '../modal';

const FILTER_TEMPLATES_KEYS = ['translations.title'];
const FILTER_QUESTIONS_KEYS = ['translations.desc', 'translations.title', 'translations.question'];
const VIEWS = { TEMPLATE: 'templates', QUESTION: 'questions' };

const FindQuestionModal = ({
  isOpen,
  onClose,
  onSelected,
  templateGetRequest,
  clearFindQuestion,
  templateGetDetailRequest,
  selectedTemplate,
  templates,
  localId,
}) => {
  const { t } = useTranslation();
  const [filtered, setFiltered] = useState();
  const [filteredQuestions, setFilteredQuestions] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [questions, setQuestions] = useState();
  const [reset, setReset] = useState(false);
  const [view, setView] = useState(VIEWS.TEMPLATE);

  useEffect(() => {
    templateGetRequest();
  }, []);

  useEffect(() => {
    if (selectedTemplate && selectedTemplate.steps && Array.isArray(selectedTemplate.steps)) {
      const newQuestions = selectedTemplate.steps.reduce((acc, step) => {
        const stepQuestions = (step.questions || []).map((q) => ({
          key: q.id,
          id: q.id,
          translation: _.omit(q.questions_translations[localId], 'id'),
          number: q.number,
          type_id: q.type_id,
          step_id: step.id,
          step,
        }));
        return acc.concat(stepQuestions);
      }, []);
      setQuestions(newQuestions);
      setView(VIEWS.QUESTION);
    }
  }, [selectedTemplate]);

  const handleSelectTemplate = (t) => {
    if (t && t.id) {
      templateGetDetailRequest(t.id, localId);
    } else {
      setQuestions();
      setView(VIEWS.TEMPLATE);
    }
    setFiltered();
  };

  const handleAddSelection = () => {
    const newQ = (questions || []).filter((q) => selectedRowKeys.includes(q.id)).map((q) => _.omit(q, ['id', 'key', 'step_id', 'step']));

    onSelected && onSelected(newQ);
    setView(VIEWS.TEMPLATE);
    clearFindQuestion && clearFindQuestion();
  };

  const onFilter = (result) => {
    if (!result) setFiltered();
    else {
      setFiltered(result.map((r) => r.item.id));
      setReset(!reset);
    }
  };

  const onFilterQuestions = (result) => {
    if (!result) setFilteredQuestions();
    else {
      setFilteredQuestions(result.map((r) => r.item.id));
      setReset(!reset);
    }
  };

  const columns = [
    {
      title: 'Step',
      dataIndex: ['step', 'translation', 'title'],
    },
    {
      title: 'Questions',
      dataIndex: ['translation', 'question'],
    },
    {
      title: 'Action',
      key: 'action',
      render: (_text, question) => (
        <Space size="middle">
          {question?.translation?.desc && (
            <Popover
              placement="topLeft"
              content={parse(question?.translation?.desc)} //  html-react-parser
              title="Title"
              trigger="hover"
            >
              <Button>Description</Button>
            </Popover>
          )}
          {question?.translation?.rewording && (
            <Popover placement="topLeft" content={parse(question?.translation?.rewording)} title="Reformulation" trigger="hover">
              <Button>Reformulation</Button>
            </Popover>
          )}
        </Space>
      ),
    },
  ];

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    type: 'checkbox',
    selectedRowKeys: selectedRowKeys || [],
    onChange: setSelectedRowKeys,
    selections: true,
  };

  return (
    <>
      <Modal isOpen={isOpen} modalClosed={onClose} title={t('search_for_questions')} scrollable fullWidth fullHeight>
        <Filter
          list={!_.isObject(selectedTemplate) ? templates : questions}
          onFilter={!_.isObject(selectedTemplate) ? onFilter : onFilterQuestions}
          keys={!_.isObject(selectedTemplate) ? FILTER_TEMPLATES_KEYS : FILTER_QUESTIONS_KEYS}
        />
        {view === VIEWS.TEMPLATE ? (
          <>
            <List
              bordered
              dataSource={filterAndOrderList(templates, filtered)}
              renderItem={(t) => (
                <List.Item
                  actions={[
                    <Button type="link" key="list-loadmore-edit" onClick={() => handleSelectTemplate(t)}>
                      Selectionner
                    </Button>,
                  ]}
                >
                  {t?.translation?.title || ''}
                </List.Item>
              )}
            />
          </>
        ) : (
          <>
            <List
              bordered
              dataSource={[selectedTemplate]}
              renderItem={(t) => (
                <List.Item
                  actions={[
                    <Button type="link" key="list-loadmore-edit" onClick={() => handleSelectTemplate()}>
                      Annuler selection
                    </Button>,
                    <Button type="link" key="list-loadmore-edit" onClick={handleAddSelection}>
                      Ajouter la selection
                    </Button>,
                  ]}
                >
                  {t?.translation.title || ''}
                </List.Item>
              )}
            />
            <Table columns={columns} dataSource={filterAndOrderList(questions, filteredQuestions)} rowSelection={rowSelection} />
          </>
        )}
      </Modal>
    </>
  );
};

FindQuestionModal.propTypes = {
  onClose: PropTypes.func,
};

export default FindQuestionModal;

import i18n from '../services/i18n/i18n';

const firstLetterRegex = /(^|\s|[^a-zA-Z\u00C0-\u017F])([a-zA-Z\u00C0-\u017F])/g;
const womanRegex = /^(women|woman|female|femme)$/i;
const manRegex = /^(men|man|male|homme)$/i;
const unkownRegex = /^(unkown|other|autre|inconnu)$/i;

/**
 * Search for obj at a given path in state obj
 * @param {string} path
 * @param {object} obj
 * @returns {object}
 */
export const pathResolver = (path, obj) => {
  return path.split('.').reduce((prev, curr) => {
    return prev ? prev[curr] : null;
  }, obj);
};

/**
 * Validate data and return a simple action object
 * @param {string} type
 * @param {object} [data]
 */
export const createAction = (type, data) => {
  if (data && data.type) {
    throw new Error('Type is a reserved name !');
  }

  if (data) {
    Object.keys(data).forEach((key) => {
      if (data[key] === undefined) {
        throw new Error(`${key} is undefined !`);
      }
    });
  }

  return { type, ...data };
};

/**
 * Remove key properties from obj
 * @param {[string]} propertiesList
 * @param {object} [obj]
 */
export const removePropertiesFromObj = (propertiesList, obj) => {
  if (Array.isArray(propertiesList)) {
    propertiesList.forEach((property) => obj.hasOwnProperty(property) && delete obj[property]);
  } else {
    obj.hasOwnProperty(propertiesList) && delete obj[propertiesList];
  }
  return obj;
};

export const editLetterCaseUsers = ({ firstname, lastname, use_name, ...prevUser }) => {
  let newUser = {};
  if (firstname) newUser.firstname = firstname.toLowerCase().replace(firstLetterRegex, (l) => l.toUpperCase());
  if (lastname) newUser.lastname = lastname.toUpperCase();
  if (use_name && use_name !== '') newUser.use_name = use_name.toUpperCase();
  return { ...prevUser, ...newUser };
};

export const getGender = (gender, maleString = 'male', femaleString = 'female', unkownString = 'unknown') => {
  if (!gender) return gender;
  gender = gender.trim();
  let newGender;
  switch (true) {
    case womanRegex.test(gender):
      newGender = femaleString;
      break;
    case manRegex.test(gender):
      newGender = maleString;
      break;
    case unkownRegex.test(gender):
      newGender = unkownString;
      break;
    default:
      newGender = gender;
      break;
  }
  return newGender;
};

export const getUserProperty = (user, type) => {
  if (!user) return user;
  user = editLetterCaseUsers(user);
  const isDoctor = 'rpps_number' in user;
  const isWoman = 'gender' in user && user.gender.match(womanRegex);
  const title = isDoctor
    ? i18n.t(`medic_title.${user.title}`)
    : getGender(user.gender, i18n.t('mister_abbreviation'), i18n.t('miss_abbreviation'), null);
  switch (type) {
    case 'title':
      return title;
    case 'titledName':
      return `${title ? `${title} ` : ''}${user.use_name ? `${user.use_name}` : user.lastname || ''}`;
    case 'fullContactName':
      return isDoctor
        ? `${user.firstname ? `${user.firstname} ` : ''}${user.lastname || ''}`
        : `${user.firstname ? `${user.firstname} ` : ''}${user.use_name ? `${user.use_name}` : user.lastname || ''}`;
    case 'contactNameWithTitle':
      return isDoctor
        ? `${title ? `${title} ` : ''}${user.firstname ? `${user.firstname} ` : ''}${user.lastname || ''}`
        : `${title ? `${title} ` : ''}${user.firstname ? `${user.firstname} ` : ''}${user.use_name ? `${user.use_name}` : user.lastname || ''}`;
    case 'fullDisplayName':
      return isDoctor
        ? `${user.firstname ? `${user.firstname} ` : ''}${user.lastname || ''}`
        : `${user.firstname ? `${user.firstname} ` : ''}${user.use_name ? `${user.use_name} ${i18n.t(isWoman ? 'born_she' : 'born_he')} ` : ''}${
            user.lastname || ''
          }`;
    case 'birthName':
      return user.lastname;
    case 'birthNameLabel':
      return user.use_name && `${i18n.t(isWoman ? 'born_she' : 'born_he')} ${user.lastname}`;
    default:
      return '';
  }
};

const string_to_slug = (str) => {
  str = str.replace(/(^\s{1,100}|\s{1,100}$)/g, ''); // trim
  str = str.toLowerCase();
  // remove accents, swap ñ for n, etc
  var from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
  var to = 'aaaaeeeeiiiioooouuuunc------';
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }
  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes
  return str;
};

export { string_to_slug };

import _ from 'lodash';
import { createReducer } from 'reduxsauce';

import { types } from './actions';

const INITIAL_STATE = { data: null };

const updateData = (state = { ...INITIAL_STATE }, action) => {
  const { data } = state;
  const { data: actionData, key } = action;
  const newData = key && key !== '' ? _.set(data || {}, key, actionData) : _.merge(data || {}, actionData);
  return {
    ...state,
    data: { ...newData },
  };
};

const omitData = (state = { ...INITIAL_STATE }, action) => {
  const { data } = state;
  const { key } = action;
  if (!key || key === '' || !data) return state;
  const newData = { ...data };
  _.unset(newData, key);
  return {
    ...state,
    data: newData,
  };
};

const resetReducers = (state = { ...INITIAL_STATE }) => {
  return { ...INITIAL_STATE };
};

export default createReducer(
  { ...INITIAL_STATE },
  {
    [types.RESET_REDUCED]: resetReducers,

    [types.UPDATE_DATA]: updateData,
    [types.OMIT_DATA]: omitData,
    [types.CLEAR_DATA]: resetReducers,
  },
);

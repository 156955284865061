import React from 'react';
import { useTranslation } from 'react-i18next';

import { CustomFormController, InputArea } from '../../../components/formV3';
import Modal from '../../../components/modal';
import { Button } from '../../../components/styledComponent';

const TextPdf = ({ prefixName, disabled, onExit, isOpen }) => {
  const prefix = prefixName ? `${prefixName}.` : '';
  const { t } = useTranslation();
  const handleOnExit = () => {
    if (onExit) {
      onExit();
    }
  };

  return (
    <Modal title={t('texte_pdf_modal_title')} modalClosed={handleOnExit} scrollable isOpen={isOpen}>
      <CustomFormController name={`${prefix}texte_pdf`}>
        <InputArea className={'w-100'} />
      </CustomFormController>
      <Button onClick={handleOnExit} type="button">
        {t('ok')}
      </Button>
    </Modal>
  );
};

export default TextPdf;

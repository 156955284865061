import { Popover, Space, Tag } from 'antd';
import React from 'react';

export default function MedicTags({ type }) {
  return (
    ['create-medic'].includes(type) && (
      <Space size={[0, 8]} wrap>
        <Popover content={'Dr LASTNAME'}>
          <Tag color="magenta">medic.titledName</Tag>
        </Popover>
        <Popover content={'06 12 34 56 78'}>
          <Tag color="magenta">medic.local_phone_number</Tag>
        </Popover>
        <Popover content={'yqhvoUjMU6'}>
          <Tag color="magenta">medic.user.username</Tag>
        </Popover>
        <Popover content={"url permettant d'arriver sur la page login avec le username du pro"}>
          <Tag color="orange">medicLoginUrl</Tag>
        </Popover>
      </Space>
    )
  );
}

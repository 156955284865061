import { createAction } from '../../utils';

// Types
export const types = {
  EMAILS_REQUEST: 'EMAILS_REQUEST',
  EMAILS_FAILURE: 'EMAILS_FAILURE',
  EMAILS_SUCCESS: 'EMAILS_SUCCESS',

  EMAILS_UPDATE_REQUEST: 'EMAILS_UPDATE_REQUEST',
  EMAILS_UPDATE_FAILURE: 'EMAILS_UPDATE_FAILURE',
  EMAILS_UPDATE_SUCCESS: 'EMAILS_UPDATE_SUCCESS',
};

// Actions
export default {
  /* UPDATE */
  emailsUpdateRequest: (email = {}) => createAction(types.EMAILS_UPDATE_REQUEST, { email }),
  emailsUpdateSuccess: () => createAction(types.EMAILS_UPDATE_SUCCESS),

  /** REQUEST */
  emailsRequest: (filters = {}) => createAction(types.EMAILS_REQUEST, { filters }),
  emailsSuccess: (email) => createAction(types.EMAILS_SUCCESS, { email }),
  emailsFailure: () => createAction(types.EMAILS_FAILURE),
};

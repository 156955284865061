import './sideMenuImage.css';

import React, { useEffect } from 'react';
// import defaultProfil from '../../../assets/images/defaultProfil.png'

const SideMenuImage = ({}) => {
  useEffect(() => {}, []);

  return <div className="logo" style={{ color: 'white', textAlign: 'center' }}></div>;
};

export default SideMenuImage;

import { createAction } from '../../utils';

// Types
export const types = {
  START_LOADER: 'START_LOADER',
  STOP_LOADER: 'STOP_LOADER',
  UPDATE_PASSWORD: 'USERS_UPDATE_PASSWORD',
  UPDATE_PASSWORD_SUCCESS: 'USERS_UPDATE_PASSWORD_SUCCESS',
  UPDATE_PASSWORD_FAILURE: 'USERS_UPDATE_PASSWORD_FAILURE',

  REQUEST_ALL: 'users_REQUEST_ALL',
  REQUEST_ALL_SUCCESS: 'users_REQUEST_ALL_SUCCESS',
};

// Actions
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  startLoader: () => createAction(types.START_LOADER),
  stopLoader: () => createAction(types.STOP_LOADER),
  /* CREATE */
  updatePassword: ({ id, password }) => createAction(types.UPDATE_PASSWORD, { id, password }),
  updatePasswordSuccess: () => createAction(types.UPDATE_PASSWORD_SUCCESS),
  updatePasswordFailure: () => createAction(types.USERS_UPDATE_PASSWORD_FAILURE),

  requestAll: () => createAction(types.REQUEST_ALL),
  requestAllSuccess: (data) => createAction(types.REQUEST_ALL_SUCCESS, { data }),
};

import { createAction } from '../../utils';

// Types
export const types = {
  LOGIN_REFRESH: 'LOGIN_REFRESH',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGOUT: 'LOGOUT',
  RENEW_TOKEN_SUCCESS: 'RENEW_TOKEN_SUCCESS',
  RENEW_TOKEN: 'RENEW_TOKEN',
  SET_SIDE_MENU_ROUTES: 'SET_SIDE_MENU_ROUTES',
  UPDATE_USER: 'UPDATE_USER',

  SET_LOGIN_REDIRECT_URL: 'auth_SET_LOGIN_REDIRECT_URL',
  CLEAR_LOGIN_REDIRECT_URL: 'auth_CLEAR_LOGIN_REDIRECT_URL',

  SET_REDIRECT_URL: 'auth_SET_REDIRECT_URL',
  CLEAR_REDIRECT_URL: 'auth_CLEAR_REDIRECT_URL',
};

// Actions
export default {
  loginRefresh: () => createAction(types.LOGIN_REFRESH),

  loginRequest: (username, password) => createAction(types.LOGIN_REQUEST, { username, password }),

  loginSuccess: (token_type, token, refresh_token = null, authorization) =>
    createAction(types.LOGIN_SUCCESS, { token_type, token, refresh_token, authorization }),

  renewTokenSuccess: (token, refresh_token = null) => createAction(types.RENEW_TOKEN_SUCCESS, { token, refresh_token }),
  loginFailure: () => createAction(types.LOGIN_FAILURE),

  logout: () => createAction(types.LOGOUT),
  renewToken: () => createAction(types.RENEW_TOKEN),

  setSideMenuRoutes: (routes) => createAction(types.SET_SIDE_MENU_ROUTES, { routes }),

  updateUser: (userData, email, role) => createAction(types.UPDATE_USER, { userData, email, role }),

  setLoginRedirectUrl: (url) => createAction(types.SET_LOGIN_REDIRECT_URL, { url }),
  clearLoginRedirectUrl: () => createAction(types.CLEAR_LOGIN_REDIRECT_URL),

  setRedirectUrl: (url) => createAction(types.SET_REDIRECT_URL, { url }),
  clearRedirectUrl: () => createAction(types.CLEAR_REDIRECT_URL),
};

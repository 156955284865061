import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { HOME_PATH } from '../../utils/const';

const PublicRoutes = ({ isUserLoggedIn, redirect, element }) => {
  if (!isUserLoggedIn) {
    return element || <Outlet />;
  }

  return <Navigate to={redirect || HOME_PATH} />;
};

export default PublicRoutes;

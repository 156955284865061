import { authenticatedMutation } from './middleware';

/**
 * GRAPHQL QUERIES
 */
// const queries = {};
/**
 * GRAPHQL MUTATION
 *
 */

const mutations = {
  linkUserRoleToMedic: () => `
    mutation LinkUserRoleToMedic(
      $userId: Int!,
      $userRoles: [users_roles_insert_input!]!
    ) {
      delete_users_roles(where: {user_id: {_eq: $userId}}) {
        returning {
          id
        }
      }
      insert_users_roles(objects: $userRoles) {
        returning {
          id
        }
      }
    }
  `,
};

export default {
  linkUserRoleToMedic: (userId, userRoles) => authenticatedMutation(mutations.linkUserRoleToMedic(), {}, { userId, userRoles }),
};

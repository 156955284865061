/**
 * Check if the given error is a GraphQLError. return the first graphQLError if it is, null otherwise
 * @param error the error to check
 */
export const isGraphQLError = (error) => {
  let err = error;
  if (Array.isArray(error)) {
    err = error[0];
  }

  if (err && err.extensions && err.extensions.code) {
    return err;
  } else {
    return null;
  }
};

/**
 * retreive the code for a graphQLError
 * @param error the GraphQL code
 */
export const extractGraphQLCode = (error) => {
  let err = isGraphQLError(error);
  if (err == null) return '';

  const code = (err && err.extensions && err.extensions.code) || '';
  return code;
};

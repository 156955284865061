import React, { useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import FormLanguageSelector from '../../components/FormLanguageSelector';
import SmsForm from './SmsForm';

const SmsCreate = ({ smsRequest, smsUpdateRequest, languageList, smsList }) => {
  const { smsId } = useParams();
  const id = smsId && !['sms', 'add'].includes(smsId) ? smsId : null;
  const [searchParams] = useSearchParams();
  const [urlParams, setUrlParams] = useState();
  const form = useMemo(() => {
    return (smsList && smsList[id]) || {};
  }, [id, smsList]);

  useEffect(() => {
    setUrlParams(Object.fromEntries([...searchParams]));
  }, [searchParams]);

  const selectedLngId = useMemo(() => {
    if (urlParams && urlParams.editLocale && Array.isArray(languageList)) {
      return languageList.find((lng) => lng.language_code === urlParams.editLocale)?.id;
    }
    return languageList && languageList[0]?.id;
  }, [languageList, urlParams]);

  const initialData = useMemo(() => {
    if (form) {
      return { ...form, sms_templates_translations: { language_id: selectedLngId, ...form?.sms_templates_translations[selectedLngId] } };
    } else {
      return { sms_templates_translations: { language_id: selectedLngId } };
    }
  }, [selectedLngId, form]);

  useEffect(() => {
    smsRequest();
  }, []);

  const onValidate = (value) => {
    value.id = form.id;
    smsUpdateRequest(value);
  };

  const handleError = (err) => {
    // eslint-disable-next-line no-console
    console.error(err);
  };

  return (
    <>
      <FormLanguageSelector />
      <SmsForm onValidate={onValidate} initialValues={initialData} onError={handleError} sms={Object.values(smsList || {})} />
    </>
  );
};

export default SmsCreate;

import { Button, message, Typography, Upload } from 'antd';
import React from 'react';
import XLSX from 'xlsx';

const XLSExport = ({ loading, toExport, exportName }) => {
  const uploadRest = (XLSRest) => {
    // const filtered = XLSRest.filter(r => r.Ville && r.Nom && r["Code postal"] && r.Adresse && r["CountryID (66 for France)"] )
    // const restaurants = filtered
    //     .map( r => {
    //     return {
    //         "RName": r.Nom,
    //         "RCity": r.Ville,
    //         "RDescription": r.Description,
    //         "RPostalCode": r["Code postal"],
    //         "MapIcon": r["Sous-catégorie principale (MapIcon)"],
    //         "RAddress": r.Adresse,
    //         "CountryId": r["CountryID (66 for France)"] || -1,
    //         "RLongitude": r.Longitude || -1,
    //         "RLatitude": r.Latitude || -1
    //     }
    // })
    // restaurantsCreate(restaurants)
  };

  const props = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    onChange(info) {
      const { status } = info.file;
      if (status === 'done') {
        /* Boilerplate to set up FileReader */
        const reader = new FileReader();
        reader.onload = function (e) {
          let data = new Uint8Array(e.target.result);
          let workbook = XLSX.read(data, { type: 'array' });
          /* Get first worksheet */
          const wsname = workbook.SheetNames[0];
          const ws = workbook.Sheets[wsname];
          /* Convert array of arrays */
          const restJSON = XLSX.utils.sheet_to_json(ws);
          uploadRest(restJSON);
        };
        reader.readAsArrayBuffer(info.file.originFileObj);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  /* generate a download */
  function s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  const onClick = (e) => {
    let elements = toExport();
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(elements);
    XLSX.utils.book_append_sheet(wb, ws, exportName || 'exports');
    XLSX.writeFile(wb, (exportName || 'export') + '.xlsx');
  };

  return (
    <Button onClick={onClick}>
      Export XLS
      {/* <DownloadOutlined /> Export */}
    </Button>
  );
};

export default XLSExport;

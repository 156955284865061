import { connect } from 'react-redux';

import { AuthActions } from '../../../redux/auth';
import { CityActions } from '../../../redux/city';
import { LanguageActions } from '../../../redux/languages';
import { MedicalTeamActions } from '../../../redux/medicalTeams';
import { MedicActions } from '../../../redux/medics';
import { PatientActions } from '../../../redux/patients';
import { USER_ROLE } from '../../../utils/calimed-enum';
import PatientsCreate from './PatientsCreate';

const mapStateToProps = ({ auth, city, patients, medics, medicalTeams, language }) => ({
  patientsList: patients.list || {},
  medicList: Object.values(medics.list || {}),
  token: auth.token || '',
  patients: patients,
  cityList: city && city.cities && Object.values(city.cities),
  birthplaceList: city && city.birthplaceList && Object.values(city.birthplaceList),
  medicalTeams: Object.values(medicalTeams.list),
  languageList: Object.values(language.list || {}),
});

const mapDispatchToProps = (dispatch) => ({
  patientsRequest: (filter) => dispatch(PatientActions.patientsRequest(filter)),
  patientsCreateRequest: (patient) => dispatch(PatientActions.patientsCreateRequest(patient)),
  patientsUpdateRequest: (patient) => dispatch(PatientActions.patientsUpdateRequest(patient)),
  patientDetailRequest: (id) => dispatch(PatientActions.patientDetailRequest(id)),
  medicsRequest: (filter) => dispatch(MedicActions.medicsRequest(filter)),

  resetUserPswdAttemptCounter: (id) => dispatch(AuthActions.updateUser({ id: id, failed_login_counter: 0 }, null, USER_ROLE.PATIENT)),

  checkIfEmailExistsInDb: (email, userId) => dispatch(PatientActions.checkIfEmailExistsInDb(email, userId)),
  fetchCityList: (q, cityType) => dispatch(CityActions.fetchCityList(q, cityType)),
  clearCityList: () => dispatch(CityActions.clearCityList()),
  medicalTeamsRequest: () => dispatch(MedicalTeamActions.medicalTeamsRequest()),
  fetchAllLanguages: () => dispatch(LanguageActions.fetchAll()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientsCreate);

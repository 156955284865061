import './sideMenu.css';

import Icon, { BankOutlined, MailOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { RoutesMapper } from '../../../utils/const';
import SideMenuImage from '../sideMenuImage/index';

const { Sider } = Layout;
const { SubMenu } = Menu;

const routesListe = [
  { path: '/', title: RoutesMapper('/').title, icon: RoutesMapper('/').icon },
  { path: '/medics', title: RoutesMapper('/medics').title, icon: RoutesMapper('/medics').icon },
  { path: '/patients', title: RoutesMapper('/patients').title, icon: RoutesMapper('/patients').icon },
  { path: '/clinics', title: RoutesMapper('/clinics').title, icon: RoutesMapper('/clinics').icon },
  // {
  //   title: 'sideMenu.title.clinic.root',
  //   icon: RoutesMapper('/clinics').icon,
  //   sub: [
  //     { path: '/clinics', title: RoutesMapper('/clinics').title, icon: RoutesMapper('/clinics').icon },
  //     { path: '/clinics-groups', title: RoutesMapper('/clinics-groups').title, icon: RoutesMapper('/clinics-groups').icon },
  //   ],
  // },
  { path: '/medicalTeams', title: RoutesMapper('/medicalTeams').title, icon: RoutesMapper('/medicalTeams').icon },
  {
    title: 'sideMenu.title.forms.root',
    icon: BankOutlined,
    sub: [
      { path: '/templates', title: RoutesMapper('/templates').title, icon: RoutesMapper('/templates').icon },
      { path: '/medicTemplates', title: RoutesMapper('/medicTemplates').title, icon: RoutesMapper('/medicTemplates').icon },
      { path: '/surveyTypes', title: RoutesMapper('/surveyTypes').title, icon: RoutesMapper('/surveyTypes').icon },
      { path: '/msWordTemplates', title: RoutesMapper('/msWordTemplates').title, icon: RoutesMapper('/msWordTemplates').icon },
    ],
  },
  { path: '/consents', title: RoutesMapper('/consents').title, icon: RoutesMapper('/consents').icon },
  { path: '/specialities', title: RoutesMapper('/specialities').title, icon: RoutesMapper('/specialities').icon },
  { path: '/cgu', title: RoutesMapper('/cgu').title, icon: RoutesMapper('/cgu').icon },
  { path: '/rgpd', title: RoutesMapper('/rgpd').title, icon: RoutesMapper('/rgpd').icon },
  { path: '/roles', title: RoutesMapper('/roles').title, icon: RoutesMapper('/roles').icon },
  { path: '/app_keys', title: RoutesMapper('/app_keys').title, icon: RoutesMapper('/app_keys').icon },
  { path: '/sftpCredentials', title: RoutesMapper('/sftpCredentials').title, icon: RoutesMapper('/sftpCredentials').icon },
  { path: '/credentials', title: RoutesMapper('/credentials').title, icon: RoutesMapper('/credentials').icon },
  { path: '/languages', title: RoutesMapper('/languages').title, icon: RoutesMapper('/languages').icon },
  {
    title: 'sideMenu.title.emailsSms.root',
    icon: MailOutlined,
    sub: [
      { path: '/emails', title: RoutesMapper('/emails').title, icon: RoutesMapper('/emails').icon },
      { path: '/sms', title: RoutesMapper('/sms').title, icon: RoutesMapper('/sms').icon },
    ],
  },
];

function SideMenu({ children, inlineCollapsed, current, setSideMenuRoutes }) {
  const { t } = useTranslation();
  let navigate = useNavigate();

  const [routes, setRoutes] = useState([]);
  useEffect(() => {
    let index = 0;
    const newRoutes = routesListe.map((r) => {
      index = index + 1;
      r.key = `${index}`;
      if ('sub' in r) {
        const sub = r.sub.map((s) => {
          index = index + 1;
          s.key = `${index}`;
          return { ...s };
        });
        return { ...r, sub };
      } else {
        return { ...r };
      }
    });
    setRoutes(newRoutes);
    setSideMenuRoutes(newRoutes);
  }, [routesListe]);

  const handleClick = ({ key }) => {
    const route = routes.find((r) => {
      return r.sub ? r.sub.find((s) => s.key === key) : r.key === key;
    });

    if (route.sub) {
      let subRoute = route.sub.find((s) => s.key === key);
      navigate(subRoute.path);
    } else {
      navigate(route.path);
    }
  };
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const items = routes.map((r) => {
    return r.sub
      ? getItem(
          t(r.title),
          r.key,
          <Icon component={r.icon} />,
          r.sub.map((s) => getItem(t(s.title), s.key, <Icon component={s.icon} />)),
        )
      : getItem(t(r.title), r.key, <Icon component={r.icon} />);
  });

  return (
    <div id={'home'} className={'screen-wrapper'}>
      <Layout style={{ minHeight: '100vh' }}>
        <Sider collapsed={inlineCollapsed}>
          <SideMenuImage />
          <Menu theme="dark" defaultSelectedKeys={[current]} mode="inline" onClick={handleClick} items={items} />
        </Sider>
        <Layout>{children}</Layout>
      </Layout>
    </div>
  );
}

SideMenu.propTypes = {};

export default SideMenu;

import _ from 'lodash';
import { createReducer } from 'reduxsauce';

import { types } from './actions';

const initialState = {
  list: {},
  cgu: {},
  rgpd: {},
  loading: false,
  hasMoreCgu: true,
  hasMoreRgpd: true,
  updateSuccess: false,
};

const startLoader = (state = initialState) => ({ ...state, loading: true });

const stopLoader = (state = initialState) => ({ ...state, loading: false });

const cguSuccess = (state = initialState, action) => {
  const { cgu } = action;
  return { ...state, loading: false, cgu: _.keyBy(cgu, 'id'), hasMoreCgu: cgu.length > 0 };
};

const rgpdSuccess = (state = initialState, action) => {
  const { rgpd } = action;
  return { ...state, loading: false, rgpd: _.keyBy(rgpd, 'id'), hasMoreRgpd: rgpd.length > 0 };
};

export default createReducer(initialState, {
  [types.CGU_REQUEST]: startLoader,
  [types.CGU_SUCCESS]: cguSuccess,
  [types.CGU_FAILURE]: stopLoader,

  [types.RGPD_SUCCESS]: rgpdSuccess,
});

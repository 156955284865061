import './style.css';

import React, { useEffect, useRef, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { IconContext } from 'react-icons';
import { AiOutlineDelete, AiOutlineMenu } from 'react-icons/ai';

const DraggableItem = ({ item, index, itemsLength, onKeyDown, onDelete, deletableItems, onChange }) => {
  const myRefname = useRef(null);
  const [onOpen, setOnOpen] = useState(false);

  useEffect(() => {
    if (!onOpen && deletableItems) {
      if (itemsLength === 1) {
        myRefname.current.focus();
        myRefname.current.select();
        setOnOpen(true);
      }
    }
  }, [onOpen]);

  const handleOnDelete = () => {
    if (onDelete) {
      onDelete(item.id);
    }
  };
  const [name, setName] = useState(item.content);

  const onNameChange = (e) => {
    setName(e.target.value);
    if (onChange) {
      onChange(e.target.value, item.id);
    }
  };

  return (
    <Draggable draggableId={item.id} index={index}>
      {(dragProvided, dragSnapshot) => (
        <div
          className={dragSnapshot.isDragging ? 'ec-sortable-item ec-sortable-item-dragged' : 'ec-sortable-item'}
          {...dragProvided.draggableProps}
          ref={dragProvided.innerRef}
        >
          <div {...dragProvided.dragHandleProps} className="ec-sortable-list-icons">
            <IconContext.Provider value={{ color: '#00aecb', size: deletableItems ? '1.5em' : '1em' }}>
              <div>
                <AiOutlineMenu />
              </div>
            </IconContext.Provider>
          </div>

          {deletableItems ? (
            <>
              <input ref={myRefname} className="ec-item-bigger-text" value={name} onChange={(e) => onNameChange(e)} onKeyDown={onKeyDown} />
              <div className="ec-sortable-item-delete-icon">
                <IconContext.Provider value={{ color: 'red', size: '1.5em' }}>
                  <button className="ec-sortable-list-button-icons" onClick={handleOnDelete}>
                    <AiOutlineDelete />
                  </button>
                </IconContext.Provider>
              </div>
            </>
          ) : (
            <p> {item.title} </p>
          )}
        </div>
      )}
    </Draggable>
  );
};

export default DraggableItem;

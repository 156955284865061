import { useEffect, useState } from 'react';

export function push(args) {
  window._paq.push(args);
}

const useMatomo = () => {
  window._paq = window._paq || [];
  const [script, setScript] = useState(null);
  const url = process.env.REACT_APP_MATOMO_URL;

  useEffect(() => {
    if (url) {
      const siteId = process.env.REACT_APP_MATOMO_SITE_ID;
      const jsTrackerFile = 'matomo.js';
      const phpTrackerFile = 'matomo.php';
      push(['setTrackerUrl', `${url}/${phpTrackerFile}`]);
      push(['setSiteId', siteId]);
      const scriptElement = document.createElement('script');
      const refElement = document.getElementsByTagName('script')[0];
      scriptElement.type = 'text/javascript';
      scriptElement.async = true;
      scriptElement.defer = true;
      scriptElement.src = `${url}/${jsTrackerFile}`;
      if (refElement.parentNode) {
        refElement.parentNode.insertBefore(scriptElement, refElement);
      }
      if (!script || scriptElement !== script) {
        setScript(scriptElement);
      }
    }
    return function clean() {
      if (script && document.body && document.body.contains(script)) {
        document.body.removeChild(script);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);
};

export default useMatomo;

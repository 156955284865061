import './style.css';

import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import shortid from 'shortid';

import { arrayToFormName } from '..';
import ArrayList from '../ArrayList';
import QuestionHeader from '../QuestionHeader';

const DropdownList = ({ question, onDelete, prefixName, index, nestIndex, can, localId }) => {
  const prefix = prefixName ? `${prefixName}.` : '';
  const translationPrefix = `${prefix}translation.`;
  return (
    <QuestionHeader question={question} onDelete={onDelete} prefixName={translationPrefix} index={index} nestIndex={nestIndex} can={can}>
      <ArrayList
        prefixName={arrayToFormName([prefixName, 'translation', 'data'])}
        name={'list'}
        sufixNameInput={'label'}
        defaultNewItem={{ value: shortid.generate(), label: '' }}
        deletableItems
      />
    </QuestionHeader>
  );
};

export default DropdownList;

DropdownList.propTypes = {
  question: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  prefixName: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  nestIndex: PropTypes.number,
  can: PropTypes.object.isRequired,
};

import './roles.css';

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { filterAndOrderList } from '../../../utils/utils';
import Filter from '../../components/filter';
import { Tags } from '../../components/tags';
import DataTable from './DataTable';

const ROLES_FILTER_KEYS = ['name'];

const Roles = ({ rolesRequest, list, loading, rolesDeleteRequest }) => {
  const navigate = useNavigate();
  const [filtered, setFiltered] = useState();
  const [reset, setReset] = useState(false);

  useEffect(() => {
    rolesRequest();
  }, []);

  const roles = Object.values(list);

  const onFilter = (result) => {
    if (!result) setFiltered(null);
    else {
      setFiltered(result.map((r) => r.item.id));
      setReset(!reset);
    }
  };

  const onModify = (role) => {
    navigate(`/roles/${role.id}`, role);
  };

  const onDelete = (role) => {
    rolesDeleteRequest(Array.isArray(role) ? role : role.id);
  };

  const exportDataToXls = () => {
    return Array.isArray(filtered) ? roles.filter((r) => filtered.includes(r.Id)) : roles;
  };

  return (
    <>
      <Tags exportFct={exportDataToXls} />
      <Filter list={roles} onFilter={onFilter} keys={ROLES_FILTER_KEYS} />

      <DataTable
        items={filterAndOrderList(roles, filtered)}
        reset={reset}
        loading={loading}
        itemClick={onModify}
        onDelete={onDelete}
        onModify={onModify}
      />
    </>
  );
};

export default Roles;

import CheckboxFull from './CheckboxFull';
import DropdownList from './DropdownList';
import MultipleChoice from './MultipleChoice';
import PDF from './PDF';
import TextAnswer from './TextAnswer';
import Validation from './Validation';

export { Validation, TextAnswer, MultipleChoice, DropdownList, PDF, CheckboxFull };

export const arrayToFormName = (arr) => {
  return Array.isArray(arr) ? arr.filter((el) => !!el).join('.') : arr;
};

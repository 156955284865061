import { createAction } from '../../utils';

// Types
export const types = {
  STOP_LOADER: 'STOP_LOADER',
  MEDICS_REQUEST: 'MEDICS_REQUEST',
  MEDICS_FAILURE: 'MEDICS_FAILURE',
  MEDICS_SUCCESS: 'MEDICS_SUCCESS',

  MEDICS_UPDATE_REQUEST: 'MEDICS_UPDATE_REQUEST',
  MEDICS_UPDATE_FAILURE: 'MEDICS_UPDATE_FAILURE',
  MEDICS_UPDATE_SUCCESS: 'MEDICS_UPDATE_SUCCESS',

  MEDICS_CREATE_REQUEST: 'MEDICS_CREATE_REQUEST',
  MEDICS_CREATE_FAILURE: 'MEDICS_CREATE_FAILURE',
  MEDICS_CREATE_SUCCESS: 'MEDICS_CREATE_SUCCESS',

  MEDICS_DELETE_REQUEST: 'MEDICS_DELETE_REQUEST',
  MEDICS_DELETE_MULTIPLE_REQUEST: 'MEDICS_DELETE_MULTIPLE_REQUEST',
  MEDICS_DELETE_SUCCESS: 'MEDICS_DELETE_SUCCESS',

  MEDICS_EMAIL_REQUEST: 'MEDICS_EMAIL_REQUEST',
  MEDICS_EMAIL_SUCCESS: 'MEDICS_EMAIL_SUCCESS',
  MEDICS_EMAIL_FAILURE: 'MEDICS_EMAIL_FAILURE',
};

// Actions
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  stopLoader: () => createAction(types.STOP_LOADER),
  /* CREATE */
  medicsCreateRequest: (filters = {}) => createAction(types.MEDICS_CREATE_REQUEST, { filters }),
  medicsCreateSuccess: () => createAction(types.MEDICS_CREATE_SUCCESS),

  /* UPDATE */
  medicsUpdateRequest: (medic = {}) => createAction(types.MEDICS_UPDATE_REQUEST, { medic }),
  medicsUpdateSuccess: () => createAction(types.MEDICS_UPDATE_SUCCESS),

  /** REQUEST */
  medicsRequest: (filters = null) => createAction(types.MEDICS_REQUEST, { filters }),
  medicsSuccess: (medics) => createAction(types.MEDICS_SUCCESS, { medics }),
  medicsFailure: () => createAction(types.MEDICS_FAILURE),

  medicsDeleteRequest: (medicIds) => createAction(types.MEDICS_DELETE_REQUEST, { medicIds }),
  medicsDeleteMultipleRequest: (id, archivedID) => createAction(types.MEDICS_DELETE_MULTIPLE_REQUEST, { id, archivedID }),
  medicsDeleteSuccess: (medicIds) => createAction(types.MEDICS_DELETE_SUCCESS, { medicIds }),

  checkIfEmailExistsInDb: (email, userId = null) => createAction(types.MEDICS_EMAIL_REQUEST, { email, userId }),
  startEmailLoader: () => createAction(types.MEDICS_EMAIL_REQUEST),
  medicsEmailSuccess: (message) => createAction(types.MEDICS_EMAIL_SUCCESS, { message }),
  medicsEmailFailure: () => createAction(types.MEDICS_EMAIL_FAILURE),
};

import { all, call, put, takeLatest } from 'redux-saga/effects';

import { MedicTemplatesService } from '../../services';
import { AuthActions } from '../auth';
import { LoaderActions } from '../loader';
import { SnackActions } from '../snackBar';
import { default as MedicTemplateActions, types } from './actions';

const prepareFormData = (template, sourceOfTruth) => {
  const form = new FormData();
  template.steps = template.steps.map((s, sIndex) => {
    s.questions = s.questions.map((q, qIndex) => {
      if (q.translation?.data?.pdf) {
        form.append(`file-question-${sIndex}-${qIndex}`, q.translation.data.pdf);
        return {
          ...q,
          translation: {
            ...q.translation,
            data: { isPDFType: true },
          },
        };
      }
      return q;
    });
    return s;
  });

  // JSON stringify and split keeps types as boolean
  const payload = JSON.stringify(template).match(/.{1,900000}/g);
  Array.isArray(payload) && payload.forEach((chunk, index) => form.append(`payload_${index}`, chunk));
  form.append('upsert_mode', 'medic_template');
  form.append('source_of_truth', sourceOfTruth);

  return form;
};

function* medicTemplateGet() {
  yield put(LoaderActions.loading());
  const [error, data] = yield call(MedicTemplatesService.getAll);
  if (data && !error) {
    yield put(MedicTemplateActions.medicTemplateGetSuccess(data.medic_templates));
  } else {
    yield put(SnackActions.displayError('medic_template_get_error'));
  }
  yield put(LoaderActions.loaded());
}

function* medicTemplateDetail({ id }) {
  yield put(LoaderActions.loading());
  const [error, response] = yield call(MedicTemplatesService.getOne, id);

  if (error || !response) {
    yield put(SnackActions.displayError('current_medic_template_get_error'));
    yield put(LoaderActions.loaded());
    return;
  }

  yield put(MedicTemplateActions.medicTemplateDetailSuccess(response.medic_templates));
  yield put(LoaderActions.loaded());
}

function* medicTemplateUpsert({ template }) {
  yield put(LoaderActions.loading());
  const form = prepareFormData(template, template.id > 0);
  const [error, response] = yield call(MedicTemplatesService.upsertTemplate, form);
  if (!error && response && response.data?.success) {
    yield put(SnackActions.displayInfo('medic_template_upsert_success'));
    yield put(LoaderActions.loaded());
    if (!template.id) {
      yield put(AuthActions.setRedirectUrl(`/medicTemplates/${response.data.medicTemplate?.id}`));
    }
    yield put(AuthActions.setRedirectUrl('/medicTemplates'));
  } else {
    yield put(SnackActions.displayError('medic_template_upsert_error'));
    yield put(LoaderActions.loaded());
  }
}

function* medicTemplateDelete({ IDs }) {
  yield put(LoaderActions.loading());
  const response = yield all(IDs.map((medicTemplateId) => call(MedicTemplatesService.delete, medicTemplateId)));

  if (response.some((el) => !!el[0])) {
    yield put(SnackActions.displayError('medic_template_delete_error'));
    yield put(LoaderActions.loaded());
  } else {
    yield put(MedicTemplateActions.medicTemplateDeleteSuccess(IDs));
    yield put(SnackActions.displayInfo('medic_template_delete_success'));
    yield put(LoaderActions.loaded());
  }
}

function* medicTemplatePDF({ survey }) {
  yield put(LoaderActions.loading());
  const [error, response] = yield call(MedicTemplatesService.generatePDF, survey.id);
  if (error) {
    yield put(SnackActions.displayError('medic_template_generate_pdf_failure'));
  } else {
    const pdfURL = response && response.data && response.data.pdf_url;
    yield put(MedicTemplateActions.medicGeneratePDFSuccess(pdfURL));
    yield put(SnackActions.displayInfo('medic_template_generate_pdf_success'));
  }
  yield put(LoaderActions.loaded());
}

function* medicTemplateDuplicate({ id }) {
  yield put(LoaderActions.loading());
  const [error, response] = yield call(MedicTemplatesService.duplicateTemplate, id);
  if (error || !response) {
    yield put(SnackActions.displayError('survey_duplicate_error'));
    yield put(LoaderActions.loaded());
  } else {
    yield put(AuthActions.setRedirectUrl(`/medicTemplates/${response?.data?.medicTemplate?.id || ''}`));
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  takeLatest(types.MEDIC_TEMPLATE_GET_REQUEST, medicTemplateGet),
  takeLatest(types.MEDIC_TEMPLATE_GET_DETAIL_REQUEST, medicTemplateDetail),
  takeLatest(types.MEDIC_TEMPLATE_UPSERT_REQUEST, medicTemplateUpsert),
  takeLatest(types.MEDIC_TEMPLATE_DELETE_REQUEST, medicTemplateDelete),
  takeLatest(types.MEDIC_TEMPLATE_GENERATE_PDF_REQUEST, medicTemplatePDF),
  takeLatest(types.MEDIC_TEMPLATE_DUPLICATE_REQUEST, medicTemplateDuplicate),
];

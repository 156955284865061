import { Button, Card, Divider, Form, Input } from 'antd';
import React, { useEffect } from 'react';

import { getCircularReplacer } from '../SurveysCreateV2/CreateForm/CreateForm';
const { TextArea } = Input;

const SpecialitiesForm = ({ onValidate, initialValues, onError }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (initialValues) {
      form.resetFields();
    }
  }, [initialValues]);

  const onFinish = (values) => {
    onValidate(values);
  };

  return (
    <Card style={{ margin: 25, width: '80%' }}>
      <Form onFinish={onFinish} initialValues={initialValues} layout="vertical" form={form} onFinishFailed={onError}>
        <Form.Item name={['translation', 'language_id']} label="language_id" hidden={true}>
          <Input placeholder="language_id" disabled />
        </Form.Item>
        <Form.Item name={['translation', 'id']} hidden={true}>
          <Input disabled />
        </Form.Item>
        <Form.Item name={['translation', 'value']} label="Spécialité" rules={[{ required: true, message: 'Spécialité Incorrecte' }]}>
          <Input placeholder="Spécialité" maxLength={50} />
        </Form.Item>
        <Form.Item
          name={['translation', 'desc']}
          label="Description de la spécialité"
          rules={[{ required: false, message: 'Description incorrecte' }]}
        >
          <TextArea rows={4} placeholder="Description de la spécialité..." />
        </Form.Item>
        <Divider type="horizontal" />
        <Form.Item style={{ textAlign: 'center' }}>
          <Button type="primary" htmlType="submit" style={{ width: '55%' }}>
            Enregistrer
          </Button>
        </Form.Item>
      </Form>
      {!!process.env.REACT_APP_DEBUG_FORM && <pre>{JSON.stringify(form.getFieldsValue(true), getCircularReplacer(), 2)}</pre>}
    </Card>
  );
};

export default SpecialitiesForm;

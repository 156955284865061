import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { HOME_PATH, LOGIN_PATH } from '../../../utils/const';

const NoMatch = ({ displayWarning, isUserLoggedIn, setLoginRedirectUrl, clearLoginRedirectUrl }) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    displayWarning(`No match for ${location.pathname}. ${isUserLoggedIn}`);
    const fromUrl = `${location.pathname}${location.search || ''}`;
    if (isUserLoggedIn) {
      navigate(HOME_PATH, { state: { fromUrl } });
      clearLoginRedirectUrl();
    } else {
      setLoginRedirectUrl(`${location.pathname}${location.search || ''}`);
      navigate(LOGIN_PATH, { state: { fromUrl } });
    }
  }, [location?.pathname]);

  return (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
};

export default NoMatch;

/**
 * GRAPHQL MUTATION
 */
export default {
  insert: `
    mutation insertLanguage($payload: [languages_insert_input!]!) {
      insert_languages(objects: $payload) {
        returning {
          id
        }
      }
    }
  `,
  update: `
    mutation updateLanguage($id: Int = 10, $payload: languages_set_input!) {
      update_languages(where: {id: {_eq: $id}}, _set: $payload) {
        returning {
          id
        }
      }
    }
  `,
  delete: `
    mutation deleteLanguage($ids: [Int!]!) {
      delete_languages(where: {id: {_in: $ids}}) {
        returning {
          id
        }
      }
    }
  `,
};

import { connect } from 'react-redux';

import { AppKeyActions } from '../../../redux/appKeys';
import { CredentialActions } from '../../../redux/credential';
import { UserActions } from '../../../redux/users';
import { normalizeString } from '../../../utils';
import CredentialCreate from './CredentialCreate';

const mapStateToProps = ({ auth, users, appKeys, credential }) => {
  return {
    credentialList: credential.list,
    token: auth.token,
    userOptions: Object.values(users.list)
      .map((m) => ({
        value: m.id,
        label: m.username,
      }))
      .sort((a, b) => normalizeString(a.label) - normalizeString(b.label)),
    appKeyOptions: Object.values(appKeys.list)
      .map((m) => ({
        value: m.id,
        label: m.name,
      }))
      .sort((a, b) => normalizeString(a.label) - normalizeString(b.label)),
  };
};

const mapDispatchToProps = (dispatch) => ({
  usersRequest: () => dispatch(UserActions.requestAll()),
  appKeysRequest: () => dispatch(AppKeyActions.request()),

  credentialsRequest: (id) => dispatch(CredentialActions.request(id)),
  credentialsUpsert: (payload) => dispatch(CredentialActions.upsert(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CredentialCreate);

import { Button, Card, Checkbox, Divider, Form, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';

import { normalizeString } from '../../../utils';
import { regexFilepath } from '../../../utils/regex';

const SftpCredentialForm = ({ onValidate, initialValues, clinicOptions = [] }) => {
  const [form] = Form.useForm();
  const [isPswdRequired, setIsPswdRequired] = useState(false);

  useEffect(() => {
    if (initialValues) {
      setIsPswdRequired(!initialValues.id);
      form.resetFields();
    } else {
      setIsPswdRequired(true);
    }
  }, [initialValues]);

  const onFinish = (values) => {
    onValidate && onValidate(values);
  };

  return (
    <>
      <Card style={{ margin: 25, width: '80%' }}>
        <Form onFinish={onFinish} initialValues={initialValues} layout="vertical" form={form} name="basic" autoComplete="off">
          <Divider type="horizontal">Info</Divider>
          <Form.Item name="id" label="id">
            <Input disabled />
          </Form.Item>
          <Form.Item name="is_active" label="Actif ?" valuePropName="checked">
            <Checkbox />
          </Form.Item>
          <Form.Item name="name" label="Nom" rules={[{ required: true, message: 'Nom Obligatoire', whitespace: true }]}>
            <Input placeholder="Nom" maxLength={50} />
          </Form.Item>
          <Form.Item name="host" label="host" rules={[{ required: true, message: 'host Obligatoire', whitespace: true }]}>
            <Input placeholder="host" maxLength={50} />
          </Form.Item>
          <Form.Item name="port" label="port" rules={[{ required: true, message: 'port Obligatoire', whitespace: true }]}>
            <Input placeholder="port" maxLength={50} />
          </Form.Item>
          <Form.Item name="username" label="username" rules={[{ required: true, message: 'username Obligatoire', whitespace: true }]}>
            <Input placeholder="username" maxLength={50} autoComplete="off" />
          </Form.Item>
          <Form.Item name="password" label="password" rules={[{ required: isPswdRequired, message: 'password Obligatoire', whitespace: true }]}>
            <Input placeholder="password" type="password" maxLength={50} autoComplete="new-password" />
          </Form.Item>
          <Form.Item
            name="remote_directory"
            label="Chemin d'accès sur le serveur SFTP par default"
            rules={[
              { required: true, message: 'host Obligatoire' },
              {
                pattern: regexFilepath,
                message: 'Please enter a valid path',
              },
            ]}
          >
            <Input placeholder="remote_directory" maxLength={50} />
          </Form.Item>
          <Form.Item name="cdar2_template_filename" label="Template CDAR 2">
            <Select
              style={{ width: '100%' }}
              showSearch
              filterOption={(input, option) => normalizeString(option?.children).indexOf(normalizeString(input)) >= 0}
              allowClear
            >
              {['template_13579.ejs', 'template_exolis.ejs'].map((el, i) => (
                <Select.Option value={el} key={`${i}_${el.value}`}>
                  {el}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="fetch_hl7" label="Doit on récupérer des fichiers HL7 ADT ?" valuePropName="checked">
            <Checkbox />
          </Form.Item>
          <Form.Item
            name="hl7_remote_directory"
            label="Chemin d'accès sur le serveur SFTP pour récupérer les fichiers HL7 ADT"
            rules={[
              {
                pattern: regexFilepath,
                message: 'Please enter a valid path',
              },
            ]}
          >
            <Input placeholder="hl7_remote_directory" maxLength={50} />
          </Form.Item>
          <Form.Item name="push_oru" label="Doit on pousser des fichiers HL7 ORU ?" valuePropName="checked">
            <Checkbox />
          </Form.Item>
          <Form.Item
            name="oru_remote_directory"
            label="Chemin d'accès sur le serveur SFTP pour déposer les fichiers HL7 ORU"
            rules={[
              {
                pattern: regexFilepath,
                message: 'Please enter a valid path',
              },
            ]}
          >
            <Input placeholder="oru_remote_directory" maxLength={50} />
          </Form.Item>
          <Form.Item name="clinic_id" label="Cliniques rattachées à cette connexion SFTP">
            <Select
              style={{ width: '100%' }}
              showSearch
              filterOption={(input, option) => normalizeString(option?.children).indexOf(normalizeString(input)) >= 0}
              allowClear
              // mode="multiple"
            >
              {clinicOptions.map((el, i) => (
                <Select.Option value={el.value} key={el.value}>
                  {el.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Divider type="horizontal" />
          <Form.Item style={{ textAlign: 'center' }}>
            <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
              Enregistrer
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};

export default SftpCredentialForm;

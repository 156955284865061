import _ from 'lodash';
import { createReducer } from 'reduxsauce';

import { types } from './actions';

const initialState = {
  list: {},
  loading: false,
  hasMore: true,
};

const startLoader = (state = initialState) => ({ ...state, loading: true });

const stopLoader = (state = initialState) => ({ ...state, loading: false });

const emailsSuccess = (state = initialState, action) => {
  const { email } = action;
  const { list } = state;
  return { ...state, list: { ...list, ..._.keyBy(email, 'id') }, loading: false, hasMore: email.length > 0 };
};

export default createReducer(initialState, {
  [types.EMAILS_UPDATE_REQUEST]: startLoader,
  [types.EMAILS_UPDATE_SUCCESS]: stopLoader,

  [types.EMAILS_REQUEST]: startLoader,
  [types.EMAILS_SUCCESS]: emailsSuccess,
  [types.EMAILS_FAILURE]: stopLoader,
});

import React, { useMemo } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { LOGIN_PATH } from '../../utils/const';

function RequireAuth({ isUserLoggedIn, redirectTo }) {
  const location = useLocation();
  const fromUrl = useMemo(() => `${location.pathname}${location.search || ''}`, [location.pathname]);

  if (!isUserLoggedIn) {
    return <Navigate to={redirectTo || LOGIN_PATH} state={{ fromUrl }} />;
  }

  return <Outlet />;
}

export default RequireAuth;

import { connect } from 'react-redux';

import { AuthActions } from '../../../redux/auth';
import { LanguageActions } from '../../../redux/languages';
import AuthLayoutOutlet from './AuthLayoutOutlet';

const mapStateToProps = ({ auth }) => ({
  sideMenuRoutes: auth.sideMenuRoutes,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(AuthActions.logout()),
  fetchAllLanguages: () => dispatch(LanguageActions.fetchAll()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthLayoutOutlet);

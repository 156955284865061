import './style.css';

import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import Select from 'react-select';

import reactSelectStyle from './reactSelectStyle';

const defaultValue = (isMulti) => {
  return !isMulti ? '' : [];
};
// !isMulti ? {} : []

const InputSelectV2 = ({
  name,
  options,
  isMulti = false,
  placeholder,
  disabled,
  isClearable,
  changeTheme,
  customStyles,
  isLoading,
  components,
  className,
  onChange: onChangeProps,
  control,
  displayAsText,
  readOnly,
  ...extraInputProps
}) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue(isMulti));
  // const [selectedValue, setSelectedValue] = useState();
  const selectRef = useRef(null);
  const methods = useFormContext(); // retrieve all hook methods
  const {
    field: { onChange, value, ref, ...field }, // field : { onChange, onBlur, value, name: fieldName, ref, ...field },
    // field : { onChange, onBlur, value, name: fieldName, ref, ...field },
    fieldState: { error }, // fieldState: { invalid, isTouched, isDirty, error },
    // formState: { errors, isDirty, isSubmitting, touched, submitCount },
  } = useController({
    name,
    control: control || methods.control,
  });

  useEffect(() => {
    let newSelected;
    if (!value) {
      setSelectedValue(defaultValue(isMulti));
    } else if (Array.isArray(options) && options.length) {
      if (isMulti) {
        const val = Array.isArray(value) ? value : [value];
        newSelected = options.filter((o) => val.includes(o.value));
        setSelectedValue(newSelected);
      } else {
        const val = Array.isArray(value) ? value[0] || '' : value;
        newSelected = options.find((o) => `${o.value}` === `${val}`) || {};
        setSelectedValue(newSelected);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, options, isMulti, ref]);

  const formatGroupLabel = (data) => {
    return (
      <div
        className={data.isDisabled ? 'ec-select-group_style_disabled' : 'ec-select-group_style'}
        onClick={() => {
          if (isMulti) {
            if (data.isDisabled === false) handleChange(data.options);
            selectRef.current.blur();
          }
        }}
        aria-hidden="true"
      >
        <span>{data.label}</span>
        <span className={data.isDisabled ? 'ec-group-badge-styles_disabled' : 'ec-group-badge-styles'}>{data.options.length}</span>
      </div>
    );
  };

  const handleChange = (newValue) => {
    const valueToReturn = Array.isArray(newValue) ? newValue.map((el) => el.value) : newValue?.value || '';
    if (onChangeProps) onChangeProps(valueToReturn);
    onChange(valueToReturn);
  };

  return (
    <div className={`${className || ''} reactSelect`}>
      {displayAsText && <div className="selected-text-disabled">{_.get(value, 'label')}</div>}
      <Select
        name={name}
        ref={(e) => {
          ref(e);
          selectRef.current = e; // you can still assign to ref
        }}
        value={selectedValue}
        onChange={handleChange}
        // onBlur={onBlur}
        className={`${displayAsText ? 'd-none' : 'visible'}`}
        options={options}
        isMulti={isMulti}
        styles={reactSelectStyle(customStyles || {})}
        formatGroupLabel={_.get(options, '0.options') ? formatGroupLabel : undefined}
        placeholder={placeholder || ''}
        isClearable={isClearable}
        theme={changeTheme}
        isOptionDisabled={(option) => option.disabled || option.isDisabled}
        noOptionsMessage={() => 'Pas de choix disponible.'}
        closeMenuOnSelect={true}
        {...extraInputProps}
        isLoading={isLoading}
        components={components}
        aria-invalid={error ? 'true' : 'false'}
        menuPosition="fixed"
        menuPlacement="auto"
        readOnly={readOnly}
        isDisabled={readOnly || disabled || false}
        {...field}
        // search input
        isSearchable={true}
        // inputId={inputId}
        // inputValue={inputValue || ''}
      />
    </div>
  );
};

InputSelectV2.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
    }),
  ).isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
  extraInputProps: PropTypes.object,
  isMulti: PropTypes.bool,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  isClearable: PropTypes.bool,
  changeTheme: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      borderRadius: PropTypes.number.isRequired,
      colors: PropTypes.object.isRequired,
      spacing: PropTypes.shape({
        baseUnit: PropTypes.number.isRequired,
        controlHeight: PropTypes.number.isRequired,
        menuGutter: PropTypes.number.isRequired,
      }).isRequired,
    }),
    PropTypes.shape({
      borderRadius: PropTypes.number.isRequired,
      colors: PropTypes.object.isRequired,
      spacing: PropTypes.shape({
        baseUnit: PropTypes.number.isRequired,
        controlHeight: PropTypes.number.isRequired,
        menuGutter: PropTypes.number.isRequired,
      }).isRequired,
    }),
    PropTypes.shape({
      borderRadius: PropTypes.number.isRequired,
      colors: PropTypes.object.isRequired,
      spacing: PropTypes.shape({
        baseUnit: PropTypes.number.isRequired,
        controlHeight: PropTypes.number.isRequired,
        menuGutter: PropTypes.number.isRequired,
      }),
    }),
    PropTypes.shape({
      borderRadius: PropTypes.number.isRequired,
      colors: PropTypes.object.isRequired,
      spacing: PropTypes.shape({
        baseUnit: PropTypes.number.isRequired,
        controlHeight: PropTypes.number.isRequired,
        menuGutter: PropTypes.number.isRequired,
      }),
    }),
  ]),
};

export default InputSelectV2;

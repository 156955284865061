import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Divider, Form, Input, Select } from 'antd';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { defaultRoles } from '../defautValues';

export const PERMISSIONS = {
  MANAGE: 'manage', // manage represents any action
  CREATE: 'create',
  READ: 'read',
  UPDATE: 'update',
  DELETE: 'delete',
  ACCESS: 'access',
};
export const MODEL_NAMES = {
  ALL: 'all', // all represents any subject.
  PATIENT: 'patients',
  MEDICS: 'medics',
  FORM: 'FORM',
  FORM_STEP: 'FORM_STEP',
  FORM_REWORDING: 'FORM_REWORDING',
  FORM_QUESTION: 'FORM_QUESTION',
  CONSENT: 'CONSENT',
  STORE_PAGE: 'STORE_PAGE',
  PROFIL_PAGE: 'PROFIL_PAGE',
  MEDIC_TEMPLATES: 'medic_templates',
  STORE_TEMPLATES: 'templates',
  QUESTION_TYPE: 'question_types',
  PATIENT_SECURITY_NUMBER: 'security_number',
  HOME_FILTER: 'HOME_FILTER',
  PATIENT_FORM_FIELD: 'PATIENT_FORM_FIELD',
  CONSENT_COMPONENT: 'CONSENT_COMPONENT',
  SURVEY_TYPES: 'survey_types',
  HOSTNAME_PATH: 'HOSTNAME_PATH',
};

const patientFields = [
  // 'gender',
  // 'firstname',
  // 'lastname',
  // 'use_name',
  'security_number',
  // 'phone_number',
  // 'address',
  // 'city_id',
  // 'city',
  // 'postcode',
  // 'birthdate',
  // 'ipp',
  // 'internal_insi',
  // 'birthplace_id',
  // 'birthplace',
  // 'birthplace_postcode',
  // 'email',
];

const defInit = {
  name: '',
  permissions: [],
};

const RolesForm = ({ onValidate, initialValues, questionTypesOptions }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    if (initialValues) {
      form.resetFields();
    }
  }, [initialValues]);

  const onFinish = (values) => {
    values.permissions.push({
      inverted: true,
      action: PERMISSIONS.READ,
      subject: MODEL_NAMES.QUESTION_TYPE,
      conditions: { value: { $in: values.disallowedQuestionTypes || [] } },
      desc: 'disallowedQuestionTypes',
    });

    values.permissions.push({
      inverted: false,
      action: values.can_medic_templates,
      subject: MODEL_NAMES.MEDIC_TEMPLATES,
      desc: 'can_medic_templates',
    });

    values.permissions.push({
      inverted: values.can_access_profile,
      action: PERMISSIONS.ACCESS,
      subject: MODEL_NAMES.MEDIC_TEMPLATES,
      conditions: null,
      desc: 'can_access_profile',
    });

    values.permissions.push({
      inverted: true,
      action: PERMISSIONS.ACCESS,
      subject: MODEL_NAMES.HOSTNAME_PATH,
      conditions: { value: { $in: values.cannot_access_urls || [] } },
      desc: 'cannot_access_urls',
    });

    values.permissions.push({
      inverted: true,
      action: values.can_owner_medic_templates,
      subject: MODEL_NAMES.HOSTNAME_PATH,
      conditions: { medic_id: '${medic.id}' },
      desc: 'cannot_access_urls',
    });

    onValidate && onValidate(_.omit(values, ['disallowedQuestionTypes']));
  };

  const handleChange = (value) => {
    form.setFieldsValue({
      permissions: value ? defaultRoles[value].permissions : [],
    });
  };

  return (
    <>
      <Card style={{ margin: 25, width: '80%' }}>
        <Divider type="horizontal">Use a Template</Divider>
        <Select style={{ width: '100%' }} onChange={handleChange}>
          {Object.keys(defaultRoles).map((el, i) => (
            <Select.Option value={el} key={el}>
              {el}
            </Select.Option>
          ))}
        </Select>
        <Form onFinish={onFinish} initialValues={initialValues} form={form} name="basic">
          <Divider type="horizontal">Info</Divider>
          <Form.Item name="name" label={t('roleForm.fields.name.label')} rules={[{ required: true, message: 'Nom  Obligatoire', whitespace: true }]}>
            <Input placeholder={t('roleForm.fields.name.placeholder')} maxLength={50} />
          </Form.Item>
          {/* <Form.Item name={'is_backend'} valuePropName="checked">
            <Checkbox>Cocher si ce role est reservé au backend</Checkbox>
          </Form.Item> */}
          <Form.Item name={'scope'} required label={t('roleForm.fields.scope.label')}>
            <Select style={{ width: '100%' }} defaultValue={'user'}>
              {[
                { label: 'APP Key', value: 'app_keys' },
                { label: 'User', value: 'user' },
                { label: 'Medical Team', value: 'medical_teams' },
              ].map((el) => (
                <Select.Option value={el.value} key={`scope${el.value}`}>
                  {el.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name={'can_medic_templates'} label={t('roleForm.fields.can_medic_templates.label')}>
            <Select style={{ width: '100%' }} mode="multiple">
              {[
                { label: 'Créer', value: PERMISSIONS.CREATE },
                { label: 'Lire', value: PERMISSIONS.READ },
              ].map((el) => (
                <Select.Option value={el.value} key={`cannot_access_urls${el.value}`}>
                  {el.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name={'can_owner_medic_templates'} label={t('roleForm.fields.can_owner_medic_templates.label')}>
            <Select style={{ width: '100%' }} mode="multiple">
              {[
                { label: 'Modifier', value: PERMISSIONS.UPDATE },
                { label: 'Supprimer', value: PERMISSIONS.DELETE },
              ].map((el) => (
                <Select.Option value={el.value} key={`cannot_access_urls${el.value}`}>
                  {el.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name={'cannot_access_urls'} label={t('roleForm.fields.cannot_access_urls.label')}>
            <Select style={{ width: '100%' }} defaultValue={'user'}>
              {[
                { label: 'Home medical team', value: '/teams/:teamId' },
                { label: 'Détail patient', value: '/teams/:teamId/patients/:patientId' },
                { label: 'Modèles', value: '/teams/:teamId/forms' },
                { label: 'Store', value: '/teams/:teamId/store' },
                { label: 'Création de modèle', value: '/teams/:teamId/createform' },
                { label: 'Edition de modèle', value: '/teams/:teamId/createform/:templateId' },
                { label: 'Profile', value: '/profil' },
                { label: "Prévisualisation du consentement lors de l'attribution", value: '/preview/consent' },
                { label: "Page d'acceuil (Patient)", value: '/patients' },
                { label: 'Signature du consentement (Patient)', value: '/consent/:consentId' },
                { label: 'Récap avant Signature du consentement (Patient)', value: '/consent/:consentId/recap' },
              ].map((el) => (
                <Select.Option value={el.value} key={`cannot_access_urls${el.value}`}>
                  {el.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Divider type="horizontal">Etude Clinique</Divider>
          {/* <Form.Item name={'is_clinical_study'} valuePropName="checked">
            <Checkbox>Cocher si ce role est pour les études cliniques</Checkbox>
          </Form.Item> */}

          <Form.Item name={'disallowedQuestionTypes'} required label={t('roleForm.fields.disallowedQuestionTypes.label')}>
            <Select style={{ width: '100%' }} mode="multiple" options={questionTypesOptions} />
          </Form.Item>

          <Divider type="horizontal">permissions</Divider>

          <Divider type="horizontal">permissions personalisee</Divider>
          <Form.List name={'permissions'}>
            {(fields, { add, remove }) => (
              <div>
                {fields.map((field, index) => (
                  <div key={`permissions${field.key}`}>
                    <Form.Item name={[index, 'inverted']} valuePropName="checked">
                      <Checkbox>Inversé</Checkbox>
                    </Form.Item>
                    <Form.Item name={[index, 'action']} required label="action">
                      <Select style={{ width: '100%' }} mode="multiple">
                        {Object.values(PERMISSIONS).map((el) => (
                          <Select.Option value={el} key={`permissions${field.key}_${el}`}>
                            {el}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item name={[index, 'subject']} required label="subject">
                      <Select style={{ width: '100%' }} mode="multiple">
                        {Object.values(MODEL_NAMES).map((el, i) => (
                          <Select.Option value={el} key={`permissions${field.key}_${el}`}>
                            {el}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item name={[index, 'conditions']} label="conditions">
                      <Input placeholder="conditions" />
                    </Form.Item>
                    <Form.Item name={[index, 'desc']} label="description">
                      <Input placeholder="description" />
                    </Form.Item>
                    <Button type="danger" onClick={() => remove(field.name)} style={{ marginTop: 16 }} icon={<MinusCircleOutlined />}>
                      Supprimer
                    </Button>
                    <Divider type="horizontal" />
                  </div>
                ))}
                <Button
                  type="dashed"
                  onClick={() => {
                    add();
                  }}
                  style={{ width: '60%' }}
                >
                  <PlusOutlined /> Add permissions
                </Button>
              </div>
            )}
          </Form.List>
          <Divider type="horizontal" />
          <Form.Item style={{ textAlign: 'center' }}>
            <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
              Enregistrer
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};

export default RolesForm;

import { connect } from 'react-redux';

import { LanguageActions } from '../../../redux/languages';
import { LegalsActions } from '../../../redux/legal';
import Legal from './Legal';

const mapStateToProps = ({ auth, legals, language }) => ({
  authorizationToken: `${auth.token_type} ${auth.token}`,
  languageList: Object.values(language.list || {}),
  cguList: Object.values(legals.cgu || {}),
  rgpdList: Object.values(legals.rgpd || {}),
});

const mapDispatchToProps = (dispatch) => ({
  fetchAllLanguages: () => dispatch(LanguageActions.fetchAll()),
  cguRequest: () => dispatch(LegalsActions.cguRequest()),
  cguReset: (roles) => dispatch(LegalsActions.cguReset(roles)),
  cguInsertRequest: (cguList) => dispatch(LegalsActions.cguInsertRequest(cguList)),
  rgpdRequest: () => dispatch(LegalsActions.rgpdRequest()),
  rgpdReset: (roles) => dispatch(LegalsActions.rgpdReset(roles)),
  rgpdInsertRequest: (rgpdList) => dispatch(LegalsActions.rgpdInsertRequest(rgpdList)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Legal);

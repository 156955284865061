import _ from 'lodash';
import { call, put, takeLatest } from 'redux-saga/effects';

import { AppKeysService } from '../../services';
import { AuthActions } from '../auth';
import { LoaderActions } from '../loader';
import { SnackActions } from '../snackBar';
import { default as AppKeyActions, types } from './actions';

function* request({ filters }) {
  const id = (filters && filters.id) || null;
  const [error, data] = id ? yield call(AppKeysService.getById, id) : yield call(AppKeysService.getAll);
  if (!error && data && data.app_keys) {
    const appKey = data.app_keys.map((aKey) => ({
      ..._.omit(aKey, 'app_keys_medical_teams'),
      medicTeamIds: (aKey?.app_keys_medical_teams || []).map((el) => el?.medical_team?.id),
    }));
    yield put(AppKeyActions.requestSuccess(appKey));
  } else {
    yield put(SnackActions.displayError('appKeys_list_error'));
    yield put(AppKeyActions.appKeysFailure());
  }
}

function* updateRequest({ appKey }) {
  yield put(LoaderActions.loading());
  const [error, data] = yield call(AppKeysService.update, appKey);
  if (!error && data) {
    yield put(SnackActions.displayInfo('appKey_modify_success'));
    yield put(AppKeyActions.updateSuccess(appKey));
    yield put(AuthActions.setRedirectUrl('/app_keys'));
  } else {
    yield put(SnackActions.displayError('appKey_modify_error'));
  }
  yield put(LoaderActions.loaded());
}

function* createRequest({ appKey }) {
  yield put(LoaderActions.loading());
  const appKeyPayload = {
    ..._.omit(appKey, ['id', 'medicTeamIds', 'appkeys_roles', 'app_keys_settings', 'credentials']),
    appkeys_roles: {
      data: appKey.appkeys_roles
        .filter((el) => !!el)
        .map((id) => ({
          role_id: id,
        })),
    },
    app_keys_medical_teams: {
      data: (appKey.medicTeamIds || []).map((id) => ({
        medical_teams_id: id,
      })),
    },
    app_keys_settings: {
      data: appKey.app_keys_settings
        .map((el) => _.omitBy(el, _.isNil))
        .map((el) => {
          return _.size(el.default_medicalteam_settings) > 0 ? el : _.omit(el, 'default_medicalteam_settings');
        })
        .filter((el) => _.size(el) > 0)
        .map((el) => _.omit(el, 'id')),
    },
    credentials: {
      data: appKey.credentials
        .map((el) => _.omitBy(el, _.isNil))
        .filter((el) => _.size(el) > 0)
        .map((el) => _.omit(el, 'id')),
    },
  };
  const [error, data] = yield call(AppKeysService.create, appKeyPayload);
  if (!error && data) {
    yield put(SnackActions.displayInfo('appKey_create_success'));
    const newAppKey = _.get(data, 'insert_app_keys.returning.0');
    // const appKeyMedicalTeams = (appKey.medicTeamIds || []).map((id) => ({
    //   app_key_id: newAppKey.id,
    //   medical_teams_id: id,
    // }));
    // yield call(AppKeysService.createAppKeyMedicalTeams, appKeyMedicalTeams);
    yield put(AppKeyActions.createSuccess({ id: newAppKey.id, appKey }));
    yield put(AuthActions.setRedirectUrl(`/app_keys/${newAppKey.id}`));
    yield put(AuthActions.setRedirectUrl('/app_keys/'));
    // yield put(AppKeyActions.appKeysRequest({ id: data.appKeys }));
  } else {
    yield put(SnackActions.displayError('appKey_create_error'));
  }
  yield put(LoaderActions.loaded());
}

function* deleteRequest({ id }) {
  yield put(LoaderActions.loading());
  const [error, data] = Array.isArray(id) ? yield call(AppKeysService.deleteMulti, id) : yield call(AppKeysService.delete, id);
  if (!error && data) {
    yield put(SnackActions.displayInfo('appKey_delete_success'));
    yield put(AppKeyActions.deleteSuccess(id));
  } else {
    yield put(SnackActions.displayError('appKey_delete_error'));
  }
  yield put(LoaderActions.loaded());
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  takeLatest(types.APP_KEYS_REQUEST, request),
  takeLatest(types.APP_KEYS_UPDATE_REQUEST, updateRequest),
  takeLatest(types.APP_KEYS_CREATE_REQUEST, createRequest),
  takeLatest(types.APP_KEYS_DELETE_REQUEST, deleteRequest),
];

import _ from 'lodash';
import { call, put, takeLatest } from 'redux-saga/effects';

import { RolesService } from '../../services';
import { AuthActions } from '../auth';
import { LoaderActions } from '../loader';
import { SnackActions } from '../snackBar';
import { default as RoleActions, types } from './actions';

function* rolesRequest({ filters }) {
  const id = (filters && filters.id) || null;
  const [error, data] = id ? yield call(RolesService.getById, id) : yield call(RolesService.getAll);
  if (!error && data && data.roles) {
    if (filters && 'isBackend' in filters) {
      data.roles = data.roles.filter((el) => el.is_backend === filters.isBackend);
    }
    yield put(RoleActions.rolesSuccess(data.roles));
  } else {
    yield put(SnackActions.displayError('roles_list_error'));
    yield put(RoleActions.rolesFailure());
  }
}

function* rolesUpdateRequest({ role }) {
  yield put(LoaderActions.loading());
  const [error, data] = yield call(RolesService.rolesUpdate, role);
  if (!error && data) {
    yield put(SnackActions.displayInfo('role_modify_success'));
    yield put(RoleActions.rolesUpdateSuccess({}));
    yield put(RoleActions.rolesRequest());
    yield put(LoaderActions.loaded());
    yield put(AuthActions.setRedirectUrl('/roles'));
  } else {
    yield put(SnackActions.displayError('role_modify_error'));
    yield put(LoaderActions.loaded());
  }
}

function* rolesCreateRequest({ role }) {
  yield put(LoaderActions.loading());
  const [error, data] = yield call(RolesService.rolesCreate, role);
  if (!error && data) {
    yield put(SnackActions.displayInfo('role_create_success'));
    const newRole = _.get(data, 'insert_roles.returning.0');
    yield put(RoleActions.rolesCreateSuccess(newRole));
    yield put(LoaderActions.loaded());
    yield put(AuthActions.setRedirectUrl('/roles'));
  } else {
    yield put(SnackActions.displayError('role_create_error'));
    yield put(LoaderActions.loaded());
  }
}

function* rolesDeleteRequest({ id }) {
  yield put(LoaderActions.loading());
  const [error, data] = Array.isArray(id) ? yield call(RolesService.rolesDeleteMulti, id) : yield call(RolesService.rolesDelete, id);
  if (!error && data) {
    yield put(SnackActions.displayInfo('role_delete_success'));
    yield put(RoleActions.rolesDeleteSuccess(id));
  } else {
    yield put(SnackActions.displayError('role_delete_error'));
  }
  yield put(LoaderActions.loaded());
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  takeLatest(types.ROLES_REQUEST, rolesRequest),
  takeLatest(types.ROLES_UPDATE_REQUEST, rolesUpdateRequest),
  takeLatest(types.ROLES_CREATE_REQUEST, rolesCreateRequest),
  takeLatest(types.ROLES_DELETE_REQUEST, rolesDeleteRequest),
];

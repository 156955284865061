import { connect } from 'react-redux';

import { MedicTemplateActions } from '../../../redux/medicTemplates';
import { QuestionActions } from '../../../redux/questions';
import { SpecialityActions } from '../../../redux/specialities';
import { StepActions } from '../../../redux/steps';
import { SurveyActions } from '../../../redux/surveys';
import { SurveyTypesActions } from '../../../redux/surveyTypes';
import { TemplatesActions } from '../../../redux/templates';
import Surveys from './Surveys';

const mapStateToProps = ({ surveys, templates, specialities, medicTemplates, surveyTypes }) => ({
  /*list: Object.values(surveys.list) || {},*/
  surveyTypes: Object.values(surveyTypes.list) || {},
  loading: surveys.loading,
  templates: Object.values(templates.list).map((el) => ({ ...el, medic_id: -1 })),
  specialities: Object.values(specialities.list),
  medicTemplates: Object.values(medicTemplates.list),
  statusChanged: templates.statusChanged,
});

const mapDispatchToProps = (dispatch) => ({
  stepClear: () => dispatch(StepActions.stepClear()),
  questionClear: () => dispatch(QuestionActions.questionClear()),
  surveysRequest: (filter) => dispatch(SurveyActions.surveysRequest(filter)),
  surveysDeleteRequest: (survey) => dispatch(SurveyActions.surveysDeleteRequest(survey)),
  surveyTypesRequest: (filter) => dispatch(SurveyTypesActions.surveyTypesRequest(filter)),
  getTemplates: () => dispatch(TemplatesActions.templateGetRequest()),
  getSpecialities: () => dispatch(SpecialityActions.specialitiesRequest()),
  getMedicTemplates: () => dispatch(MedicTemplateActions.medicTemplateGet()),
  deleteTemplates: (IDs) => dispatch(TemplatesActions.templateDeleteRequest(IDs)),
  deleteMedicTemplates: (IDs) => dispatch(MedicTemplateActions.medicTemplateDelete(IDs)),
  duplicateTemplates: (IDs) => dispatch(TemplatesActions.templateDuplicateRequest(IDs)),
  duplicateMedicTemplates: (IDs) => dispatch(MedicTemplateActions.medicTemplateDuplicateRequest(IDs)),
  updatePublishedStatus: (IDs, status) => dispatch(TemplatesActions.templatesUpdatePublishedRequest(IDs, status)),
  resetStatusChanged: () => dispatch(TemplatesActions.templatesResetStatusChanged()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Surveys);

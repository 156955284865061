import { call, put, takeLatest } from 'redux-saga/effects';

import { InterventionsService } from '../../services';
import { validEmailRegex } from '../../utils/utils';
import { AuthActions } from '../auth';
import ConsentActions from '../consents/actions';
import { LoaderActions } from '../loader';
import { SnackActions } from '../snackBar';
import { types } from './actions';

function* produceMail({ mail }) {
  const {
    value: { email },
  } = mail;
  if (email && !validEmailRegex.test(email)) {
    yield put(LoaderActions.loaded());
    yield put(SnackActions.displayError('mail_error_invalid_email'));
  } else {
    const result = yield call(InterventionsService.produceMail, mail);
    if (result) {
      yield put(LoaderActions.loaded());
      yield put(SnackActions.displayInfo('intervention_mail_success'));
    } else {
      yield put(LoaderActions.loaded());
      yield put(SnackActions.displayError('intervention_mail_error'));
    }
  }
}

function* interventionsUpdateRequest({ intervention }) {
  yield put(LoaderActions.loading());
  const result = yield call(InterventionsService.interventionsUpdate, intervention);
  if (result) {
    yield put(LoaderActions.loaded());
    yield put(SnackActions.displayInfo('intervention_modify_success'));
  } else {
    yield put(LoaderActions.loaded());
    yield put(SnackActions.displayError('intervention_modify_error'));
  }
  yield put(LoaderActions.loaded());
  yield put(AuthActions.setRedirectUrl('/consents'));
}

function* interventionsDeleteRequest({ IDs }) {
  yield put(LoaderActions.loading());
  const [error, result] = yield call(InterventionsService.interventionsDelete, IDs);
  if (error || !result) {
    yield put(SnackActions.displayError('intervention_delete_error'));
    yield put(LoaderActions.loaded());
    return;
  }

  yield put(ConsentActions.consentsDeleteSuccess(IDs));
  yield put(SnackActions.displayInfo('intervention_delete_success'));
  yield put(LoaderActions.loaded());
}

function* interventionsArchiveRequest({ IDs, archived }) {
  yield put(LoaderActions.loading());
  const [error, result] = yield call(InterventionsService.interventionsArchive, IDs, archived);
  if (error || !result) {
    yield put(SnackActions.displayError(`intervention_${archived ? 'archived' : 'unarchived'}_error`));
  } else {
    yield put(ConsentActions.consentsArchiveSuccess(IDs, archived));
    yield put(SnackActions.displayInfo(`intervention_${archived ? 'archived' : 'unarchived'}_success`));
  }
  yield put(LoaderActions.loaded());
}
// eslint-disable-next-line import/no-anonymous-default-export
export default [
  takeLatest(types.INTERVENTIONS_DELETE_REQUEST, interventionsDeleteRequest),
  takeLatest(types.INTERVENTIONS_UPDATE_REQUEST, interventionsUpdateRequest),
  takeLatest(types.INTERVENTIONS_ARCHIVE_REQUEST, interventionsArchiveRequest),
  takeLatest(types.INTERVENTIONS_PRODUCE_MAIL, produceMail),
];

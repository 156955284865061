import { endOfYear, subYears, toDate } from 'date-fns';

import { regexSecu } from '../../../../utils/regex';
import { isValidSecuKey, isValidSecuNumFromRegex } from '../../form/rule';

const PRESET = {
  birthdate: {
    preset: {
      maxDate: toDate(new Date()),
      minDate: toDate(subYears(endOfYear(new Date()), 100)),
    },
  },
  security_number: {
    preset: {
      autoCorrect: 'off',
      autoCapitalize: 'characters',
    },
    mask: {
      formatChars: {
        9: '[0-9]',
        a: '[A-Za-z]',
        '*': '[A-Za-z0-9]',
        s: '[1-2]',
        c: '[0-9]|[ABab]',
      },
      mask: 's 99 99 9c 999 999 99',
    },
    rules: {
      required: true,
      maxLength: 22,
      minLength: 22,
      pattern: regexSecu,
      validate: {
        isValidSecuNum: (v) => isValidSecuNumFromRegex(v),
        isValidSecuKey: (v) => isValidSecuKey(v),
      },
    },
  },
  rpps_number: {
    preset: {
      // maxLength:'7'
      autoCorrect: 'off',
      autoCapitalize: 'characters',
    },
  },
  postcode: {
    preset: {
      // maxLength:'7'
      autoCorrect: 'off',
      autoCapitalize: 'characters',
    },
    mask: {
      formatChars: { 9: '[0-9]' },
      mask: '99 999',
    },
    rules: {
      required: true,
      maxLength: 7,
      minLength: 7,
      // pattern: regexSecu,
      validate: {
        // isValidSecuNum: v => isValidSecuNum(v),
        // isValidSecuKey: v => isValidSecuKey(v),
      },
    },
  },
};

export { PRESET };

import './templates.css';

import { EyeOutlined } from '@ant-design/icons';
import { Button, Divider, Table, Typography } from 'antd';
import { format, parseISO } from 'date-fns';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { normalizeString } from '../../../utils';
import { DeleteButton, DuplicateButton, ModifyButton } from '../../components/button';
import EyeButton from '../../components/button/EyeButton';

const { Text } = Typography;

const DataTable = ({
  items,
  reset,
  surveyTypes,
  displayLanguageId,
  loading,
  itemClick,
  onDelete,
  onModify,
  onDuplicate,
  onPublished,
  resetRowKeys,
  isStoreTemplate,
  urlParams,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [key, setKey] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [pagination, setPagination] = useState({
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50', '100', '200', '500'],
    current: 1,
  });

  const data = useMemo(() => {
    if (items.length !== key) {
      setKey(items.length);
      setSelectedRowKeys([]);
    }
    return items || [];
  }, [items]);

  useEffect(() => {
    if (urlParams && urlParams.page) {
      setPagination({ ...pagination, current: parseInt(urlParams.page, 10) });
    }
  }, [urlParams && urlParams.page]);

  useEffect(() => {
    if (reset) {
      setPagination({ ...pagination, current: 1 });
    }
  }, [reset]);

  useEffect(() => {
    if (resetRowKeys) {
      setSelectedRowKeys([]);
    }
  }, [resetRowKeys]);

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const columns = [
    {
      title: 'Titre',
      dataIndex: ['translations', displayLanguageId, 'title'],
      sorter: (a, b) => {
        const aTitle = _.get(a, ['translations', displayLanguageId, 'title'], '');
        const bTitle = _.get(b, ['translations', displayLanguageId, 'title'], '');
        return aTitle.localeCompare(bTitle);
      },
      align: 'center',
      render: (text) => <span>{text}</span>,
    },
    {
      title: 'Type du formulaire',
      dataIndex: 'type_id',
      sorter: (a, b) => {
        const typeA = surveyTypes.find((t) => t.id === a.type_id);
        const typeB = surveyTypes.find((t) => t.id === b.type_id);
        const valueA = normalizeString(typeA?.survey_types_translations?.[displayLanguageId]?.title || '');
        const valueB = normalizeString(typeB?.survey_types_translations?.[displayLanguageId]?.title || '' || '');

        return valueA < valueB;
      },
      align: 'center',
      render: (text) => {
        const type = surveyTypes.find((t) => t.id === text);
        if (text == null || text === undefined) {
          return <span>Non spécifié</span>;
        } else {
          return <span>{type?.survey_types_translations?.[displayLanguageId]?.title || ''}</span>;
        }
      },
    },
    {
      title: 'Assigné',
      dataIndex: 'medical_team',
      render: (assigned) => {
        return <span>{assigned && assigned.title ? `${assigned.title}` : ''}</span>;
      },
    },
    {
      title: 'Crée par',
      dataIndex: 'medic',
      render: (storeID, row) => {
        if (row.__typename === 'medic_templates') {
          const medicDisplay = [row?.medic?.firstname || '', row?.medic?.lastname || ''].join(' ');
          return <span>{`${medicDisplay}${storeID > 0 ? ' (Store)' : ''}`}</span>;
        }
      },
    },
    {
      title: 'Template',
      dataIndex: 'store_id',
      render: (storeID, row) => {
        if (row.__typename === 'medic_templates') {
          return storeID ? <a href={`/#/templates/${storeID}`}>{storeID}</a> : '';
        }
      },
    },
    {
      title: "Date d'ajout",
      dataIndex: 'created_at',
      sorter: (a, b) => parseISO(a.created_at).getTime() - parseISO(b.created_at).getTime(),
      align: 'center',
      render: (text) => <span>{text ? format(parseISO(text), 'dd-MM-yyyy') : ''}</span>,
    },
    {
      title: 'Publié',
      dataIndex: 'is_published',
      sorter: (a, b) => a.is_published > b.is_published,
      align: 'center',
      render: (text, record) =>
        isStoreTemplate && (
          <span>
            <EyeButton confirm eye={text} onClick={() => handleOnPublished([record.id], !record.is_published)} />
          </span>
        ),
    },
    {
      title: 'Actions',
      key: 'action',
      width: 150,
      render: (item) => {
        return (
          <span className="ButtonSurveys">
            <ModifyButton modify={() => onModify(item)} tooltipTitle={'Modifier'} />
            <Divider type="vertical" />
            <DeleteButton deletion={() => onDelete(item)} tooltipTitle={'Supprimer'} />
            <Divider type="vertical" />
            <DuplicateButton onClick={() => onDuplicate(item)} tooltipTitle={'Dupliquer'} />
          </span>
        );
      },
    },
  ].filter((el) => {
    return isStoreTemplate ? !['medic', 'store_id', 'medical_team'].includes(el.dataIndex) : !['is_published'].includes(el.dataIndex);
  });

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleOnRowClick = (rest, index) => {
    if (itemClick) itemClick(rest);
  };

  const handleReset = () => {
    setSelectedRowKeys([]);
  };

  const handleOnPublished = (elements, published) => {
    if (onPublished) {
      onPublished(elements, published);
    }
  };

  const actionsRender = () => {
    return (
      <>
        <span>
          <span style={{ marginRight: 6 }}>
            <DeleteButton title={'Supprimer'} onCancel={() => handleReset()} deletion={() => onDelete(selectedRowKeys)} />
          </span>
          <Text strong>{selectedRowKeys.length} formulaire(s)</Text>
        </span>
        <br />
        <br />
        <span>
          <span style={{ marginRight: 6 }}>
            <Button shape="circle" type="primary" onClick={() => onPublished(selectedRowKeys, true)} icon={<EyeOutlined />} />
          </span>
          <Text strong> {data.filter((d) => !d.store_id && selectedRowKeys.includes(d.id)).length} formulaire(s) </Text>
        </span>
        <br />
        <br />
      </>
    );
  };

  return (
    <div>
      {selectedRowKeys.length > 0 && actionsRender()}
      <Table
        size={'small'}
        showSorterTooltip={false}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data.map((item) => ({ ...item, key: item.id }))}
        loading={loading}
        pagination={pagination}
        onRow={(rest, rowIndex) => {
          return {
            onDoubleClick: (event) => {
              handleOnRowClick(rest, rowIndex);
            },
          };
        }}
        onChange={(page) => {
          const key = 'page';
          const urlParams = new URLSearchParams(`${location.search}`);
          if (urlParams.has(key)) {
            if (urlParams.get(key) !== page.current) {
              urlParams.set(key, page.current);
            }
          } else {
            urlParams.set(key, page.current);
          }
          const newPath = `${location.pathname}?${urlParams}`;
          navigate(newPath);

          setPagination({ ...pagination, current: page.current });
        }}
      />
    </div>
  );
};

export default DataTable;

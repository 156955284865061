import { authenticatedMutation, authenticatedQuery } from './middleware';

/**
 * GRAPHQL QUERIES
 */
const queries = {
  questionBasicsTypes: () => `
    query {
      question_basics_types {
        id
        value
      }
    }
  `,
  questionBasics: () => `
    query {
      question_basics {
        id
        value
        text_error
        text_success
      }
    }
  `,
  questionBasicsTypesSurveys: () => `
    query {
      question_basic_types_surveys {
        survey_type_id
        question_basic_type_id
        question_basic_id
      }
    }
  `,
};
/**
 * GRAPHQL MUTATION
 */

const mutations = {
  questionBasicsClear: () => `
    mutation QuestionBasicsClear($id: Int!) {
      delete_question_basic_types_surveys(where: {question_basic_type_id: {_eq: $id}}) {
        returning {
          question_basic_id
        }
      }
      delete_question_basics(where: {question_basic_types_surveys: {question_basic_type_id: {_eq: $id}}}) {
        returning {
          id
        }
      }
    }
  `,
  questionBasicsClearSome: () => `
  mutation QuestionBasicsClearSome($id: Int!, $typeId: Int!) {
    delete_question_basic_types_surveys(where: {question_basic_type_id: {_eq: $id}, _and: {survey_type_id: {_eq: $typeId}}}) {
      returning {
        question_basic_id
      }
    }
  }`,
  questionBasicsUpdate: (basicID, questionBasics) => `
  mutation QuestionBasicsUpdate($id: Int!) {
    ${questionBasics
      .map((qb, index) => {
        const tmp = {
          value: qb.text_value, //.replace(/"+/g, '').replace(/\n/g, '\\\\n'),//.replace(/\\n/g, '\\\\n'), //split('\\n').join('\\\\n'),//.split('\n').join('\\\\n'),
          text_success: qb.text_success, //.replace(/"+/g, ''),//.split('\\n').join('\\\\n'),
          text_error: qb.text_error, //.replace(/"+/g, ''),//.split('\\n').join('\\\\n'),
        };
        const stringified = JSON.stringify(tmp);
        const parsed = stringified.substring(1, stringified.length - 1).replace(/"(\w+)"\s*:/g, '$1:');
        const mut = `
        delete_question_basic_types_surveys_${index}: delete_question_basic_types_surveys(where: {question_basic_type_id: {_eq: $id}, _and: {survey_type_id: {_eq: "${qb.survey_type_id}"}}}) {
          returning {
            question_basic_id
          }
        }
        delete_question_basics_${index}: delete_question_basics(where: {question_basic_types_surveys: {question_basic_type_id: {_eq: $id}, survey_type_id: {_eq: "${qb.survey_type_id}"}}}) {
          returning {
            id
          }
        }
        insert_questions_basics_${index}: insert_question_basics(objects: {
          ${parsed}
          question_basic_types_surveys: {data: {question_basic_type_id: "${qb.question_basic_type_id}", survey_type_id: "${qb.survey_type_id}"}}}) {
            returning {
              id
            }
          }
      `;
        return mut;
      })
      .join(' ')}
  }`,
};

export default {
  questionBasicsClear: (id) => authenticatedMutation(mutations.questionBasicsClear(), {}, { id }),
  questionBasicsClearSome: (id, typeId) => authenticatedMutation(mutations.questionBasicsClearSome(), {}, { id, typeId }),
  questionBasicsUpdate: (id, questionBasics) => authenticatedMutation(mutations.questionBasicsUpdate(), {}, { id, questionBasics }),
  questionBasics: () => authenticatedQuery(queries.questionBasics()),
  questionBasicsTypes: () => authenticatedQuery(queries.questionBasicsTypes()),
  questionBasicsTypesSurveys: () => authenticatedQuery(queries.questionBasicsTypesSurveys()),
};

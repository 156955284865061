import { createReducer } from 'reduxsauce';

import { types } from './actions';

const initialState = {
  list: {},
  loading: false,
  hasMore: true,
};

const startLoader = (state = initialState) => ({ ...state, loading: true });

const stopLoader = (state = initialState) => ({ ...state, loading: false });

const stepsSuccess = (state = initialState, action) => {
  const { steps } = action;
  const data = {};
  steps.forEach((step) => {
    data[step.id] = step;
  });
  return { ...state, list: data, loading: false, hasMore: steps.length > 0 };
};

const stepDeleteSuccess = (state = initialState, action) => {
  const { id } = action;
  const { list } = state;

  const steps = Object.values(list);
  const data = {};

  steps.forEach((step) => {
    data[step.id] = step;
  });

  delete data[id];

  return { ...state, list: data, loading: false, hasMore: steps.length > 0 };
};

const stepsCreateSuccess = (state = initialState, action) => {
  const { steps } = action;
  const { list } = state;

  const tmp = Object.values(list);
  const data = {};
  tmp.push(steps);
  tmp.forEach((step) => {
    data[step.id] = step;
  });

  return { ...state, list: data, loading: false, hasMore: steps.length > 0 };
};

const stepsUpdateSuccess = (state = initialState, action) => {
  const { steps } = action;
  const { list } = state;

  const data = { ...list };

  data[steps.id] = steps;

  return { ...state, list: data, loading: false, hasMore: steps.length > 0 };
};

const stepClear = () => {
  return initialState;
};

export default createReducer(initialState, {
  [types.STEP_CLEAR]: stepClear,

  [types.STEPS_CREATE_REQUEST]: startLoader,
  [types.STEPS_CREATE_SUCCESS]: stepsCreateSuccess,

  [types.STEPS_UPDATE_REQUEST]: startLoader,
  [types.STEPS_UPDATE_SUCCESS]: stepsUpdateSuccess,

  [types.STEPS_REQUEST]: startLoader,
  [types.STEPS_SUCCESS]: stepsSuccess,
  [types.STEPS_FAILURE]: stopLoader,

  [types.STEPS_DELETE_REQUEST]: startLoader,
  [types.STEPS_DELETE_SUCCESS]: stepDeleteSuccess,
});

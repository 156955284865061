import _ from 'lodash';
import { createReducer } from 'reduxsauce';

import { types } from './actions';

const initialState = {
  list: {},
  loading: false,
  hasMore: true,
  statusChanged: false,
  findQuestions: null,
  selectedTemplate: null,
};

const startLoader = (state = initialState) => ({ ...state, loading: true });

const stopLoader = (state = initialState) => ({ ...state, loading: false });

export const templateStepMapper = (templates) => {
  if (!templates) return;
  return _.keyBy(
    (Array.isArray(templates) ? templates : [templates]).map((t) => {
      return {
        ..._.omit(t, 'step_templates'),
        translations: _.keyBy(t.templates_translations, 'language_id'),
        steps: Array.isArray(t.step_templates)
          ? t.step_templates
              .map((s) => s.step)
              .map((s) => ({
                ...s,
                steps_translations: _.keyBy(s.steps_translations, 'language_id'),
                questions: Array.isArray(s.questions)
                  ? s.questions.map((q) => ({
                      ...q,
                      questions_translations: _.keyBy(q.questions_translations, 'language_id'),
                    }))
                  : [],
              }))
          : [],
      };
    }),
    'id',
  );
};

const templateGetSuccess = (state = initialState, action) => {
  const { templates } = action;
  let { list } = state;

  return { ...state, list: _.merge(list, templateStepMapper(templates)), loading: false };
};

const clearFindQuestion = (state = initialState) => {
  return { ...state, findQuestions: null };
};

const templateGetDetailSuccess = (state = initialState, action) => {
  const { templates, keyDestination } = action;
  let { list } = state;
  const newTemplates = templateStepMapper(templates);
  const key = keyDestination === 'findQuestions' ? 'findQuestions' : 'selectedTemplate';
  return { ...state, list: _.merge(list, newTemplates), [key]: _.get(newTemplates, templates[0].id), loading: false };
};

const templateCreateSuccess = (state = initialState, action) => {
  const { template } = action;
  const { list } = state;
  return { ...state, list: _.merge(list, templateStepMapper(template)), loading: false };
};

const templateUpdateSuccess = (state = initialState, action) => {
  const { template } = action;
  const { list } = state;
  return { ...state, list: _.merge(list, templateStepMapper(template)), loading: false };
};

const upsertSuccess = (state = initialState, action) => {
  const { template } = action;
  const { list } = state;
  return { ...state, list: _.merge(list, templateStepMapper(template)), loading: false };
};

const templatePublishedUpdateSuccess = (state = initialState, action) => {
  const { IDs, status } = action;
  const { list: data } = state;

  IDs.forEach((id) => {
    data[id] = { ...data[id], is_published: status };
  });

  return { ...state, list: data, statusChanged: true };
};

const templateDeleteSuccess = (state = initialState, action) => {
  const { IDs } = action;
  const { list } = state;

  return { ...state, list: _.omit(list, IDs), loading: false };
};

const templateResetStatusChanged = (state = initialState) => ({
  ...state,
  statusChanged: false,
});

const clearSelectedTemplate = (state = initialState) => {
  return { ...state, selectedTemplate: null, loading: false };
};

export default createReducer(initialState, {
  [types.TEMPLATE_GET_REQUEST]: startLoader,
  [types.TEMPLATE_GET_SUCCESS]: templateGetSuccess,
  [types.TEMPLATE_GET_FAILURE]: stopLoader,

  [types.TEMPLATE_GET_DETAIL_REQUEST]: startLoader,
  [types.TEMPLATE_GET_DETAIL_SUCCESS]: templateGetDetailSuccess,
  [types.TEMPLATE_GET_DETAIL_FAILURE]: stopLoader,

  [types.TEMPLATE_CREATE_REQUEST]: startLoader,
  [types.TEMPLATE_CREATE_SUCCESS]: templateCreateSuccess,
  [types.TEMPLATE_CREATE_FAILURE]: stopLoader,

  [types.UPSERT_SUCCESS]: upsertSuccess,
  [types.UPSERT_FAILURE]: stopLoader,

  [types.TEMPLATE_UPDATE_REQUEST]: startLoader,
  [types.TEMPLATE_UPDATE_SUCCESS]: templateUpdateSuccess,
  [types.TEMPLATE_UPDATE_FAILURE]: stopLoader,

  [types.TEMPLATE_UPDATE_PUBLISHED_SUCCESS]: templatePublishedUpdateSuccess,

  [types.TEMPLATE_DELETE_REQUEST]: startLoader,
  [types.TEMPLATE_DELETE_SUCCESS]: templateDeleteSuccess,
  [types.TEMPLATE_DELETE_FAILURE]: stopLoader,

  [types.TEMPLATES_RESET_STATUS_CHANGED]: templateResetStatusChanged,

  [types.TEMPLATES_CLEAR_FIND_QUESTION]: clearFindQuestion,

  [types.CLEAR_SELECTED_TEMPLATE]: clearSelectedTemplate,
});

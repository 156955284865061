import _ from 'lodash';
import { connect } from 'react-redux';

import PDF from './PDF';

const mapStateToProps = ({ survey, medicTemplates, templates }, props) => {
  const { currentSurvey } = props;
  const currentSurveyRedux = _.get(survey, ['currentSurvey']) || medicTemplates.selectedTemplate || templates.selectedTemplate;
  return { currentSurvey: currentSurvey || currentSurveyRedux };
};

export default connect(mapStateToProps, null)(PDF);

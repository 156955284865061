import { Switch as AntdSwitch } from 'antd';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';

const Switch = ({ name, rules = { required: false }, label, defaultValue, disabled, ...extraInputProps }) => {
  const { control } = useFormContext(); // retrieve all hook methods
  const {
    field: { value, ...inputProps }, // : { ref, value, onChange, ...inputProps }, // field : { onChange, onBlur, value, name: fieldName, ref, ...inputProps },
    // field : { onChange, onBlur, value, name: fieldName, ref, ...inputProps },
    fieldState: { error }, // fieldState: { invalid, isTouched, isDirty, error },
    // formState: { errors, isDirty, isSubmitting, touched, submitCount },
  } = useController({
    name,
    control,
    rules: { ...rules },
    defaultValue: defaultValue || _.get(control, ['defaultValuesRef', 'current', name]) || null,
  });

  return (
    <label htmlFor={`${name}`} key={`${name}`}>
      <AntdSwitch checked={value} {...inputProps} {...extraInputProps} disabled={disabled} aria-invalid={error ? 'true' : 'false'} />
      {label && <span>{label}</span>}
    </label>
  );
};

Switch.propTypes = {
  name: PropTypes.string.isRequired,
  rules: PropTypes.object,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  extraInputProps: PropTypes.object,
  label: PropTypes.string,
};

export default Switch;

import './style.css';

import PropTypes from 'prop-types';
import React from 'react';
import { useLocation, useNavigate } from 'react-router';

import CountryFlag from '../formV3/controllers/CountrySelect/CountryFlag';
import Button from '../styledComponent/Button';

const URL_PARAMS_KEY = 'editLocale';
function FormLanguageSelector({ languageList, LanguageIds }) {
  let navigate = useNavigate();
  const location = useLocation();
  const currentLng = React.useMemo(() => {
    const urlParams = new URLSearchParams(`${location.search}`);
    return urlParams.get(URL_PARAMS_KEY) || (Array.isArray(languageList) && languageList.length && languageList[0].language_code);
  }, [location, languageList]);

  const list = React.useMemo(() => {
    if (LanguageIds) {
      return languageList.filter((lng) => LanguageIds.includes(lng.id));
    } else {
      return languageList || [];
    }
  }, [languageList, LanguageIds]);

  const handlClick = (lng) => {
    const urlParams = new URLSearchParams(`${location.search}`);
    if (urlParams.has(URL_PARAMS_KEY)) {
      if (urlParams.get(URL_PARAMS_KEY) !== lng.language_code) {
        urlParams.set(URL_PARAMS_KEY, lng.language_code);
      }
    } else {
      urlParams.set(URL_PARAMS_KEY, lng.language_code);
    }
    const newPath = `${location.pathname}?${urlParams}`;
    navigate(newPath);
  };

  return (
    <div className="FormLanguageSelector d-flex flex-column flex-sm-row">
      {list.map((lng) => (
        <Button
          key={lng.id}
          onClick={() => handlClick(lng)}
          type="button"
          variant="link"
          className={currentLng === lng.language_code ? 'active' : ''}
          disabled={currentLng === lng.language_code}
        >
          <CountryFlag countryCode={lng?.country_code_flag} hideText title={lng?.name} />
        </Button>
      ))}
    </div>
  );
}

FormLanguageSelector.propTypes = {
  languageList: PropTypes.array,
  LanguageIds: PropTypes.array,
};

export default FormLanguageSelector;

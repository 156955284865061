import _ from 'lodash';
import { createReducer } from 'reduxsauce';

import { types } from './actions';

const initialState = {
  list: {},
  loading: false,
  hasMore: true,
};

const startLoader = (state = initialState) => ({ ...state, loading: true });

const stopLoader = (state = initialState) => ({ ...state, loading: false });

const requestSuccess = (state = initialState, action) => {
  const { sftpCredentials } = action;
  return { ...state, list: { ..._.keyBy(sftpCredentials, 'id') }, loading: false };
};

const upsertSuccess = (state = initialState, action) => {
  const { sftpCredentials } = action;
  const { list } = state;
  return { ...state, list: { ...list, ..._.keyBy(sftpCredentials, 'id') }, loading: false };
};

const deleteSuccess = (state = initialState, action) => {
  const { id } = action;
  const { list } = state;
  return { ...state, list: _.omit(list, Array.isArray(id) ? id : [id]), loading: false };
};

export default createReducer(initialState, {
  [types.SFTP_UPSERT_REQUEST]: startLoader,
  [types.SFTP_UPSERT_SUCCESS]: upsertSuccess,

  [types.SFTP_REQUEST]: startLoader,
  [types.SFTP_SUCCESS]: requestSuccess,
  [types.SFTP_FAILURE]: stopLoader,

  [types.SFTP_DELETE_REQUEST]: startLoader,
  [types.SFTP_DELETE_SUCCESS]: deleteSuccess,
});

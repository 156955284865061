import { connect } from 'react-redux';

import { MedicTemplateActions } from '../../../redux/medicTemplates';
import { QuestionActions } from '../../../redux/questions';
import { SpecialityActions } from '../../../redux/specialities';
import { StepActions } from '../../../redux/steps';
import { SurveyActions } from '../../../redux/surveys';
import { SurveyTypesActions } from '../../../redux/surveyTypes';
import { TemplatesActions } from '../../../redux/templates';
import Templates from './Templates';

const mapStateToProps = ({ auth, surveys, templates, specialities, medicTemplates, surveyTypes }) => {
  const displayLanguageId = auth.languageId;
  const templateList = Object.values(templates.list || {}).map((t) => ({
    ...t,
    translation: t.translations[displayLanguageId || 1],
  }));
  const medicTemplateList = Object.values(medicTemplates.list || {}).map((t) => ({
    ...t,
    translation: t.translations[displayLanguageId || 1],
  }));
  return {
    /*list: Object.values(surveys.list) || {},*/
    surveyTypes: Object.values(surveyTypes.list || {}),
    loading: surveys.loading,
    templates: templateList, // .map((el) => ({ ...el, medic_id: -1 })),
    specialities: Object.values(specialities.list || {}),
    medicTemplates: medicTemplateList,
    statusChanged: templates.statusChanged,
    displayLanguageId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  stepClear: () => dispatch(StepActions.stepClear()),
  questionClear: () => dispatch(QuestionActions.questionClear()),
  surveysRequest: (filter) => dispatch(SurveyActions.surveysRequest(filter)),
  surveysDeleteRequest: (survey) => dispatch(SurveyActions.surveysDeleteRequest(survey)),
  surveyTypesRequest: (filter) => dispatch(SurveyTypesActions.surveyTypesRequest(filter)),
  getTemplates: () => dispatch(TemplatesActions.templateGetRequest()),
  getSpecialities: () => dispatch(SpecialityActions.specialitiesRequest()),
  getMedicTemplates: () => dispatch(MedicTemplateActions.medicTemplateGet()),
  deleteTemplates: (IDs) => dispatch(TemplatesActions.templateDeleteRequest(IDs)),
  deleteMedicTemplates: (IDs) => dispatch(MedicTemplateActions.medicTemplateDelete(IDs)),
  duplicateTemplates: (id) => dispatch(TemplatesActions.templateDuplicateRequest(id)),
  duplicateMedicTemplates: (id) => dispatch(MedicTemplateActions.medicTemplateDuplicateRequest(id)),
  updatePublishedStatus: (IDs, status) => dispatch(TemplatesActions.templatesUpdatePublishedRequest(IDs, status)),
  resetStatusChanged: () => dispatch(TemplatesActions.templatesResetStatusChanged()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Templates);

import { Popover, Space, Tag } from 'antd';
import React from 'react';

export default function PswdForgotTags({ type }) {
  return (
    ['email-on-pswd-forgot'].includes(type) && (
      <Space size={[0, 8]} wrap>
        <Popover content={'liste des noms utlisateurs'}>
          <Tag color="magenta">username</Tag>
        </Popover>
        <Popover content={'si un ou plusieurs noms'}>
          <Tag color="orange">plurality</Tag>
        </Popover>
        <Popover content={"url permettant d'arriver sur la page d'acceuil : https://sante.easy-consent.fr/patients"}>
          <Tag color="orange">frontendHomePage</Tag>
        </Popover>
      </Space>
    )
  );
}

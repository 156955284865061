import './style.css';

import { ExpandAltOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Upload } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import signApi from '../../../services/axios/signApi';
import { CONTENT_TYPE_MAPPER, MEDIC_ROLES, USER_ROLE } from '../../../utils/calimed-enum';
import ConfirmButton from '../../components/button/ConfirmButton';
import PDFViewer from '../../components/PDFViewer';
import { Tags } from '../../components/tags';
import { getCircularReplacer } from '../SurveysCreateV2/CreateForm/CreateForm';

export const LEGAL_PAGE_TYPES = {
  RGPD: 'rgpd',
  CGU: 'cgu',
};

const Legal = ({
  fetchAllLanguages,
  languageList,
  cguRequest,
  cguList,
  cguReset,
  cguInsertRequest,
  authorizationToken,
  pageType,
  rgpdRequest,
  rgpdList,
  rgpdReset,
  rgpdInsertRequest,
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [lastUploadedFile, setLastUploadedFile] = useState();
  const [legal, setLegal] = useState({});

  useEffect(() => {
    fetchAllLanguages && fetchAllLanguages();
  }, []);

  useEffect(() => {
    if (pageType === LEGAL_PAGE_TYPES.CGU) {
      cguRequest && cguRequest();
    } else if (pageType === LEGAL_PAGE_TYPES.RGPD) {
      rgpdRequest && rgpdRequest();
    }
  }, [pageType]);

  useEffect(() => {
    if (pageType === LEGAL_PAGE_TYPES.CGU) {
      setLegal({
        ...legal,
        list: cguList,
      });
    }
  }, [cguList]);

  useEffect(() => {
    if (pageType === LEGAL_PAGE_TYPES.RGPD) {
      setLegal({
        ...legal,
        list: rgpdList,
      });
    }
  }, [rgpdList]);

  const initialValues = useMemo(
    () => ({
      lng: (languageList || []).map((lng) => ({
        language_id: lng.id,
        language_code: lng.language_code,
        types: [USER_ROLE.PATIENT, USER_ROLE.MEDIC].map((type) => {
          const sLegal = ((pageType === LEGAL_PAGE_TYPES.CGU ? cguList : rgpdList) || []).find((c) => c.type === type && c.language_id === lng.id);
          return {
            type: type,
            loid: sLegal?.loid,
            file: null,
            version: sLegal?.version,
            id: sLegal?.id,
          };
        }),
      })),
    }),
    [languageList, legal && legal.list],
  );

  useEffect(() => {
    if (initialValues) {
      form.resetFields();
    }
  }, [initialValues]);

  const onReset = (roles) => {
    if (pageType === LEGAL_PAGE_TYPES.CGU) {
      cguReset && cguReset(roles);
    } else if (pageType === LEGAL_PAGE_TYPES.RGPD) {
      rgpdReset && rgpdReset(roles);
    }
  };

  const uploadProps = {
    maxCount: 1,
    accept: CONTENT_TYPE_MAPPER.PDF,
    action: '/file',
    beforeUpload: (file) => {
      const isGoodFileType = file.type === CONTENT_TYPE_MAPPER.PDF;
      if (!isGoodFileType) {
        // eslint-disable-next-line no-console
        console.error(`${file.name} is not a pdf file`);
      }
      return isGoodFileType || Upload.LIST_IGNORE;
    },
    onChange: ({ file }) => {
      setLastUploadedFile({
        file: file?.originFileObj,
      });
    },
    headers: {
      Authorization: authorizationToken,
    },
    customRequest: (options) => {
      const data = new FormData();
      data.append('file', options.file);
      data.append('file_insert_mode', 'pdf');
      const config = {
        headers: options.headers,
      };
      signApi
        .post(options.action, data, config)
        .then((res) => {
          options.onSuccess(res.data, options.file);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
        });
    },
  };

  const onFinishFailed = ({ errorFields }) => {
    // eslint-disable-next-line no-console
    console.error('errorFields', errorFields);
  };

  const onFinish = (values) => {
    const { lng } = values;
    const payload = lng.reduce((acc, l) => {
      return [
        ...acc,
        ...l.types.map((t) => ({
          type: t.type,
          language_id: l.language_id,
          loid: t.file?.file?.response?.oid || t.loid,
          isNew: !!t.file?.file,
          file: t.file?.file,
        })),
      ];
    }, []);
    if (pageType === LEGAL_PAGE_TYPES.CGU) {
      cguInsertRequest(payload);
    } else if (pageType === LEGAL_PAGE_TYPES.RGPD) {
      rgpdInsertRequest(payload);
    }
  };

  const handlePreview = (path) => {
    const key = 'lng';
    const values = form.getFieldValue([key, ...path]);
    let documentData;
    if (values?.file?.originFileObj) {
      documentData = {
        file: values?.file?.originFileObj,
      };
    } else if (values?.loid) {
      documentData = {
        url: `${process.env.REACT_APP_SIGN_API_URL}/file/loids/${values.loid}`,
      };
    }
    setLastUploadedFile(documentData);
  };

  const extra = (
    <>
      <ConfirmButton confirm title={t(`${pageType.toLowerCase()}_btn_reset_patient`)} onClick={() => onReset([USER_ROLE.PATIENT])} />
      <ConfirmButton confirm type={'primary'} title={t(`${pageType.toLowerCase()}_btn_reset_pro`)} onClick={() => onReset(MEDIC_ROLES)} />
      <ConfirmButton
        confirm
        type={'primary'}
        title={t(`${pageType.toLowerCase()}_btn_reset_all`)}
        onClick={() => onReset([USER_ROLE.PATIENT, ...MEDIC_ROLES])}
      />
    </>
  );

  return (
    <>
      <Tags extra={extra} />
      <Form onFinish={onFinish} onFinishFailed={onFinishFailed} initialValues={initialValues} layout="vertical" form={form}>
        <Form.List name={'lng'}>
          {(lngs) => (
            <div>
              {lngs.map((lng, index) => (
                <Row gutter={[12, 12]} key={lng.key} className="my-4">
                  <Col sm={6} xs={24}>
                    <Form.Item name={[index, 'language_code']} label={'Language'}>
                      <Input placeholder="Language" disabled />
                    </Form.Item>
                  </Col>
                  <Form.List name={[index, 'types']}>
                    {(types) =>
                      types.map((type, indexT) => (
                        <Col sm={9} xs={24} key={type.key}>
                          <Form.Item name={[indexT, 'file']} label={indexT === 0 ? 'Patient' : 'Pro'}>
                            <Upload {...uploadProps}>
                              <Button icon={<UploadOutlined />} htmlType="button">
                                {t('attached_a_pdf')}
                              </Button>
                            </Upload>
                          </Form.Item>
                          <Form.Item name={[indexT, 'id']} hidden>
                            <Input placeholder="id" disabled />
                          </Form.Item>
                          <Form.Item name={[indexT, 'loid']}>
                            <Input placeholder="loid" disabled />
                          </Form.Item>
                          <Form.Item name={[indexT, 'version']}>
                            <Input placeholder="version" disabled />
                          </Form.Item>
                          <Button
                            type="dashed"
                            className="dynamic-delete-button"
                            onClick={() => handlePreview([index, 'types', indexT])}
                            htmlType="button"
                            icon={<ExpandAltOutlined />}
                          >
                            {t('preview')}
                          </Button>
                        </Col>
                      ))
                    }
                  </Form.List>
                </Row>
              ))}
            </div>
          )}
        </Form.List>
        <Button type="primary" htmlType="submit">
          {t('save')}
        </Button>
      </Form>
      {lastUploadedFile && <PDFViewer documentData={lastUploadedFile} />}
      {!!process.env.REACT_APP_DEBUG_FORM && <pre>{JSON.stringify(form.getFieldsValue(true), getCircularReplacer(), 2)}</pre>}
    </>
  );
};

export default Legal;

import { createReducer } from 'reduxsauce';

import { types } from './actions';

const initialState = {
  list: {},
  loading: false,
  hasMore: true,
};

const startLoader = (state = initialState) => ({ ...state, loading: true });

const stopLoader = (state = initialState) => ({ ...state, loading: false });

const questionTypesSuccess = (state = initialState, action) => {
  const { questionTypes } = action;
  const { list } = state;
  const data = list;
  questionTypes.question_types.forEach((questionType) => {
    data[questionType.id] = questionType;
  });
  return { ...state, list: data, loading: false, hasMore: questionTypes.length > 0 };
};

const questionTypeDeleteSuccess = (state = initialState, action) => {
  const { id } = action;
  const { list } = state;
  delete list[id];

  const questionTypes = Object.values(list);
  const data = {};

  questionTypes.forEach((questionType) => {
    data[questionType.id] = questionType;
  });
  return { ...state, list: data, loading: false, hasMore: questionTypes.length > 0 };
};

export default createReducer(initialState, {
  [types.QUESTION_TYPES_CREATE_REQUEST]: startLoader,
  [types.QUESTION_TYPES_CREATE_SUCCESS]: stopLoader,

  [types.QUESTION_TYPES_UPDATE_REQUEST]: startLoader,
  [types.QUESTION_TYPES_UPDATE_SUCCESS]: stopLoader,

  [types.QUESTION_TYPES_REQUEST]: startLoader,
  [types.QUESTION_TYPES_SUCCESS]: questionTypesSuccess,
  [types.QUESTION_TYPES_FAILURE]: stopLoader,

  [types.QUESTION_TYPES_DELETE_REQUEST]: startLoader,
  [types.QUESTION_TYPES_DELETE_SUCCESS]: questionTypeDeleteSuccess,
});

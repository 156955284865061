import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

function Redirect({ redirectUrl, clearRedirectUrl }) {
  let navigate = useNavigate();

  useEffect(() => {
    if (redirectUrl) {
      const url = redirectUrl;
      clearRedirectUrl();
      navigate(url, { replace: true });
    }
  }, [redirectUrl]);

  return <Outlet />;
}

Redirect.propTypes = { redirectUrl: PropTypes.string, clearRedirectUrl: PropTypes.func.isRequired };

export default Redirect;

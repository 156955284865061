import { Button, Card, Divider, Form, Input, Select } from 'antd';
import { countries } from 'countries-list';
import React, { useEffect } from 'react';

import { normalizeString } from '../../../utils';

const LanguageForm = ({ onValidate, initialValues }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (initialValues) {
      form.resetFields();
    }
  }, [initialValues]);

  const onFinish = (values) => {
    onValidate && onValidate(values);
  };

  const handleValuesChange = (changedValues) => {
    if ('language_code' in changedValues) {
      if (changedValues.language_code.includes('-')) {
        const [lng, cntry] = changedValues.language_code.split('-');
        const localized = countries[cntry];
        form.setFieldsValue({
          currency: localized.currency,
          country_code_flag: cntry,
        });
      }
    }
  };

  const languageCodeOptions = Object.keys(countries).reduce((acc, countryKey) => {
    const newLng = Array.isArray(countries[countryKey].languages)
      ? countries[countryKey].languages
          .map((languageKey) => `${languageKey}-${countryKey}`)
          .reduce((acc, val) => {
            return acc.includes(val) ? acc : [...acc, val];
          }, [])
          .map((code) => ({
            value: code,
            label: code,
          }))
      : null;
    return newLng ? [...acc, ...newLng] : acc;
  }, []);

  const countryOptions = Object.keys(countries).map((k) => ({
    ...countries[k],
    countryCode: k,
  }));

  const currencyOptions = countryOptions
    .map((c) => c.currency)
    .reduce((acc, val) => {
      return acc.includes(val) ? acc : [...acc, val];
    }, [])
    .map((currency) => ({
      value: currency,
      label: currency,
    }));

  return (
    <>
      <Card style={{ margin: 25, width: '80%' }}>
        <Form
          onFinish={onFinish}
          initialValues={initialValues}
          onValuesChange={handleValuesChange}
          layout="vertical"
          form={form}
          name="basic"
          autoComplete="off"
        >
          <Divider type="horizontal">Info</Divider>
          <Form.Item name="id" label="id">
            <Input disabled />
          </Form.Item>
          <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Name Obligatoire', whitespace: true }]}>
            <Input placeholder="Name" maxLength={50} />
          </Form.Item>
          <Form.Item name="language_code" label="Language Code" rules={[{ required: true, message: 'Language Code Obligatoire', whitespace: true }]}>
            <Select
              style={{ width: '100%' }}
              showSearch
              filterOption={(input, option) => {
                if (option.label) {
                  return normalizeString(option.label).includes(normalizeString(input));
                } else {
                  return false;
                }
              }}
              allowClear
              options={languageCodeOptions}
            />
          </Form.Item>
          <Form.Item
            name="name_in_native_language"
            label="Name in native language"
            rules={[{ required: false, message: 'Name Obligatoire', whitespace: true }]}
          >
            <Input placeholder="Name in native language" maxLength={50} />
          </Form.Item>
          <Form.Item name="date_format" label="Date Format" rules={[{ required: false, message: 'Date Format Obligatoire', whitespace: true }]}>
            <Input placeholder="Date Format" maxLength={50} />
          </Form.Item>

          <Form.Item name="country_code_flag" label="Flag" rules={[{ required: true, message: 'Flag Obligatoire', whitespace: true }]}>
            <Select
              style={{ width: '100%' }}
              showSearch
              filterOption={(input, option) => {
                if (option.label) {
                  return normalizeString(option.label).includes(normalizeString(input));
                } else {
                  return false;
                }
              }}
              allowClear
              options={countryOptions.map((t) => {
                return {
                  value: t.countryCode,
                  label: `${t.emoji} ${t.name}`,
                };
              })}
            />
          </Form.Item>
          <Form.Item name="currency" label="Currency" rules={[{ required: true, message: 'Currency Obligatoire', whitespace: true }]}>
            <Select
              style={{ width: '100%' }}
              showSearch
              filterOption={(input, option) => {
                if (option.label) {
                  return normalizeString(option.label).includes(normalizeString(input));
                } else {
                  return false;
                }
              }}
              allowClear
              options={currencyOptions}
            />
          </Form.Item>

          <Divider type="horizontal" />
          <Form.Item style={{ textAlign: 'center' }}>
            <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
              Enregistrer
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};

export default LanguageForm;

import { authenticatedMutation, authenticatedQuery } from './middleware';
/**
 * GRAPHQL QUERIES
 */
const queries = {
  surveys: () => `
    query {
      surveys(where: { is_template: { _eq: true } }) {
        id
        description
        is_template
        title
        type_id
        assigned {
          id
          firstname
          lastname
        }
        creator_id
        steps(order_by: {number: asc}) {
          id
          questions(order_by: {number: asc}) {
            id
          }
        }
        survey_type {
          id
          value
        }
        medic_surveys {
          medic {
            firstname
            lastname
          }
        }
        created_at
      }
    }
  `,
  medicSurveys: () => `
    query MedicSurveys($templateID: Int) {
      medic_surveys(
        where: {
          survey: { is_template: { _eq: true }, _and: { template_id: { _eq: $templateID } } }
        }
      ) {
        medic_id
        survey_id
        survey {
          id
          template_id
        }
      }
    }
  `,
  currentSurvey: () => `
    query CurrentSurvey($id: Int) {
      surveys(where: { id: { _eq: $id } }) {
        id
        description
        title
        type_id
        speciality_id
        comment
        side
        radius
        is_template
        progress_index
        steps(order_by: {number: asc}) {
          id
          title
          number
          questions(order_by: {number: asc}) {
            id
            question
            type_id
            number
            data
            desc
            rewording
          }
        }
        clinic {
          name
          address
        }
        medic_surveys {
          medic_id
          survey {
            id
            template_id
          }
        }
        intervention {
          number
          date
        }
      }
    }
  `,
};
/**
 * GRAPHQL MUTATION
 *
 */
const mutations = {
  updateMedicSurveys: (value) => `
    mutation {
      ${value.map(
        (v) => `
        delete_${v.survey_id}: delete_medic_surveys(
          where: {
            survey_id: {_eq: "${v.survey_id}"}
          }
        ) {
          returning {
            survey_id
          }
        }`,
      )}
    }
  `,
  surveysCreate: () => `
    mutation SurveysCreate($description: String!, $title: String!, $type_id: Int!) {
      insert_surveys(
        objects: { description: $description, title: $title, is_template: true, type_id: $type_id }
      ) {
        returning {
          description
          created_at
          title
          type_id
          is_template
          speciality_id
          survey_type {
            id
            value
          }
        }
      }
    }
  `,
  surveysUpdate: () => `
    mutation MyMutation($_id: Int!, $description: String!, $title: String!, $type_id: Int!) {
      update_surveys(
        where: { id: { _eq: $_id } }
        _set: { description: $description, title: $title, type_id: $type_id }
      ) {
        returning {
          id
          description
          title
          updated_at
          type_id
          speciality_id
          survey_type {
            id
            value
          }
        }
      }
    }
  `,
  surveysDelete: () => `
    mutation SurveysDelete($id: Int!) {
      delete_medic_surveys(where: { survey: { id: { _eq: $id } } }) {
        returning {
          survey {
            id
          }
        }
      }
      delete_questions(where: { step: { survey: { id: { _eq: $id } } } }) {
        returning {
          step {
            survey {
              id
            }
          }
        }
      }
      delete_steps(where: { survey: { id: { _eq: $id } } }) {
        returning {
          survey {
            id
          }
        }
      }
      delete_surveys(where: { id: { _eq: $id } }) {
        returning {
          id
        }
      }
    }
  `,
};

export default {
  surveys: () => authenticatedQuery(queries.surveys()),
  surveysCreate: (surveys) =>
    authenticatedQuery(queries.surveysCreate(), {
      description: surveys.description,
      title: surveys.title,
      type_id: surveys.type_id,
    }),
  surveysUpdate: (surveys) =>
    authenticatedQuery(queries.surveysUpdate(), {
      _id: surveys.id,
      description: surveys.description,
      title: surveys.title,
      type_id: surveys.type_id,
    }),
  currentSurvey: (id) => authenticatedQuery(queries.currentSurvey(), {}, { id }),
  currentMedicSurvey: (templateID) => authenticatedQuery(queries.medicSurveys(), {}, { templateID }),
  updateMedicSurveys: (value) => authenticatedMutation(mutations.updateMedicSurveys(value)),
  surveysDelete: (id) => authenticatedMutation(mutations.surveysDelete(), {}, { id }),
};

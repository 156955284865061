import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import FormLanguageSelector from '../../components/FormLanguageSelector';
import SpecialitiesForm from './SpecialitiesForm';

const SpecialitiesCreate = ({
  specialitiesRequest,
  specialitiesCreateRequest,
  specialitiesUpdateRequest,
  languageList,
  specialities,
  specialitiesList,
}) => {
  const { specialityid } = useParams();
  const id = specialityid && !['specialities', 'add'].includes(specialityid) ? specialityid : null;
  const [searchParams] = useSearchParams();
  const [urlParams, setUrlParams] = useState();
  const form = useMemo(() => {
    return id ? (specialitiesList && specialitiesList[id]) || null : null;
  }, [id, specialitiesList]);

  useEffect(() => {
    setUrlParams(Object.fromEntries([...searchParams]));
  }, [searchParams]);

  const selectedLngId = useMemo(() => {
    if (urlParams && urlParams.editLocale && Array.isArray(languageList)) {
      return languageList.find((lng) => lng.language_code === urlParams.editLocale)?.id;
    }
    return languageList && languageList[0]?.id;
  }, [languageList, urlParams]);

  const initialData = useMemo(() => {
    if (form) {
      return {
        ..._.pick(form, ['id']),
        translation: {
          language_id: selectedLngId,
          ...(form?.translations ? form?.translations[selectedLngId] : null),
        },
      };
    } else {
      return { translation: { language_id: selectedLngId } };
    }
  }, [selectedLngId, form]);

  useEffect(() => {
    specialitiesRequest();
  }, []);

  const onValidate = (value) => {
    if (form == null) {
      specialitiesCreateRequest(value);
    } else {
      value.id = form.id;
      specialitiesUpdateRequest(value);
    }
  };

  const handleError = (err) => {
    // eslint-disable-next-line no-console
    console.error(err);
  };

  return (
    <>
      <FormLanguageSelector />
      <SpecialitiesForm onValidate={onValidate} initialValues={initialData} onError={handleError} specialities={specialities} />
    </>
  );
};

export default SpecialitiesCreate;

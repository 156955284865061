import './emails.css';

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { filterAndOrderList } from '../../../utils/utils';
import Filter from '../../components/filter';
import DataTable from './DataTable';

const EMAILS_FILTER_KEYS = ['name'];

const Emails = ({ emailsRequest, list, loading }) => {
  const navigate = useNavigate();
  const [filtered, setFiltered] = useState();
  const [reset, setReset] = useState(false);

  useEffect(() => {
    emailsRequest({});
  }, []);

  const emails = Object.values(list);

  const onFilter = (result) => {
    if (!result) setFiltered(null);
    else {
      setFiltered(result.map((s) => s.item.id));
      setReset(!reset);
    }
  };

  const onModify = (email) => {
    navigate(`/emails/${email.id}`, email);
  };

  return (
    <>
      <Filter list={emails} onFilter={onFilter} keys={EMAILS_FILTER_KEYS} />

      <DataTable items={filterAndOrderList(emails, filtered)} reset={reset} loading={loading} itemClick={onModify} onModify={onModify} />
    </>
  );
};

export default Emails;

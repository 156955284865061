import _ from 'lodash';
import { call, put, takeLatest } from 'redux-saga/effects';

import { ClinicsGroupService, ClinicsService } from '../../services';
import { AuthActions } from '../auth';
import { default as ClinicsActions } from '../clinics/actions';
import { LoaderActions } from '../loader';
import { SnackActions } from '../snackBar';
import { default as ClinicsGroupsActions, types } from './actions';

function* clinicsGroupsRequest({ filters }) {
  const id = (filters && filters.id) || null;
  const [error, data] = id ? yield call(ClinicsGroupService.clinicsGroup, id) : yield call(ClinicsGroupService.clinicsGroups);
  if (error && !data.clinic_groups) {
    yield put(SnackActions.displayError('clinic_groups_list_error'));
    yield put(ClinicsGroupsActions.clinicsGroupsFailure());
  } else {
    yield put(ClinicsGroupsActions.clinicsGroupsSuccess(data.clinic_groups));
  }
}

function* clinicsGroupUpdateRequest({ clinicsGroup }) {
  yield put(LoaderActions.loading());
  const payload = {
    id: clinicsGroup.id,
    clinicsGroupPayload: _.pick(clinicsGroup.value, ['name', 'logo']),
  };
  const result = yield call(ClinicsGroupService.clinicsGroupUpdate, payload);
  if (result) {
    if (clinicsGroup.id && clinicsGroup) {
      const clinicsPayload = {
        groupId: clinicsGroup.id,
        ..._.pick(clinicsGroup.value, ['clinics']),
      };
      const resultUpdateClinics = yield call(ClinicsService.clinicsUpdateGroupId, clinicsPayload);
      if (resultUpdateClinics) {
        yield put(SnackActions.displayInfo('clinics_group_update_success'));
        yield put(ClinicsActions.clinicsUpdateSuccess({}));
        yield put(ClinicsActions.clinicsRequest({}));
      } else {
        yield put(SnackActions.displayInfo('clinics_group_update_error'));
      }
    }
    yield put(SnackActions.displayInfo('clinics_group_modify_success'));
    yield put(ClinicsGroupsActions.clinicsGroupUpdateSuccess({}));
    yield put(ClinicsGroupsActions.clinicsGroupsRequest({}));

    yield put(AuthActions.setRedirectUrl('/clinics-groups'));
  } else {
    yield put(SnackActions.displayError('clinics_group_modify_error'));
  }
  yield put(LoaderActions.loaded());
}

function* clinicsGroupCreateRequest({ clinicsGroup }) {
  yield put(LoaderActions.loading());
  const clinicsGroupPayload = {
    clinicsGroupPayload: { ..._.pick(clinicsGroup, ['updated_at', 'created_at', 'name', 'logo']) },
  };
  const result = yield call(ClinicsGroupService.clinicsGroupCreate, clinicsGroupPayload);
  const { id: groupId } = result[1].insert_clinic_groups.returning[0] || null;

  if (result) {
    if (groupId && clinicsGroup.clinics) {
      const clinicsPayload = {
        groupId,
        ..._.pick(clinicsGroup, ['clinics']),
      };
      const resultUpdateClinics = yield call(ClinicsService.clinicsUpdateGroupId, clinicsPayload);
      if (resultUpdateClinics) {
        yield put(SnackActions.displayInfo('clinics_group_update_success'));
        yield put(ClinicsActions.clinicsUpdateSuccess({}));
      } else {
        yield put(SnackActions.displayInfo('clinics_group_update_error'));
      }
    }
    yield put(SnackActions.displayInfo('clinics_group_create_success'));
    yield put(ClinicsGroupsActions.clinicsGroupCreateSuccess({}));
    yield put(AuthActions.setRedirectUrl('/clinics-groups'));
  } else {
    yield put(SnackActions.displayError('clinics_groups_create_error'));
  }
  yield put(LoaderActions.loaded());
}

function* clinicsGroupsDeleteRequest({ id }) {
  yield put(LoaderActions.loading());
  const result = yield call(ClinicsGroupService.clinicsGroupDelete, id);
  if (result) {
    yield put(LoaderActions.loaded());
    yield put(SnackActions.displayInfo('clinics_group_delete_success'));
  } else {
    yield put(LoaderActions.loaded());
    yield put(SnackActions.displayError('clinics_group_delete_error'));
  }
  yield put(ClinicsGroupsActions.clinicsGroupsDeleteSuccess(id));
  yield put(ClinicsGroupsActions.clinicsGroupsRequest({}));
  yield put(LoaderActions.loaded());
}

function* clinicsGroupsDeleteMultipleRequest({ id }) {
  yield put(LoaderActions.loading());
  const result = yield id.map((i) => call(ClinicsGroupService.clinicsGroupDelete, i));
  if (result) {
    yield put(LoaderActions.loaded());
    yield put(SnackActions.displayInfo('clinics_group_delete_success'));
  } else {
    yield put(LoaderActions.loaded());
    yield put(SnackActions.displayError('clinics__group_delete_error'));
  }
  yield id.map((i) => put(ClinicsGroupsActions.clinicsGroupsDeleteSuccess(i)));
  yield put(LoaderActions.loaded());
  yield put(ClinicsGroupsActions.clinicsGroupsRequest({}));
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  takeLatest(types.CLINICS_GROUPS_REQUEST, clinicsGroupsRequest),
  takeLatest(types.CLINICS_GROUP_UPDATE_REQUEST, clinicsGroupUpdateRequest),
  takeLatest(types.CLINICS_GROUP_CREATE_REQUEST, clinicsGroupCreateRequest),
  takeLatest(types.CLINICS_GROUP_DELETE_REQUEST, clinicsGroupsDeleteRequest),
  takeLatest(types.CLINICS_GROUPS_DELETE_REQUEST, clinicsGroupsDeleteMultipleRequest),
];

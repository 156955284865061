import { createAction } from '../../utils';

// Types
export const types = {
  DELETE: 'credential_DELETE',
  DELETE_SUCCESS: 'credential_DELETE_SUCCESS',
  REQUEST: 'credential_REQUEST',
  REQUEST_SUCCESS: 'credential_REQUEST_SUCCESS',
  DELETE_REQUEST: 'credential_DELETE_REQUEST',
  UPSERT: 'credential_UPSERT',
  UPSERT_SUCCESS: 'credential_UPSERT_SUCCESS',
};

// Actions
export default {
  request: (id = null) => createAction(types.REQUEST, { id }),
  requestSuccess: (data) => createAction(types.REQUEST_SUCCESS, { data }),

  deleteRequest: (ids) => createAction(types.DELETE, { ids }),
  deleteSuccess: (ids) => createAction(types.DELETE_SUCCESS, { ids }),

  upsert: (payload) => createAction(types.UPSERT, { payload }),
  upsertSuccess: (payload) => createAction(types.UPSERT_SUCCESS, { payload }),
};

import _ from 'lodash';
import { createReducer } from 'reduxsauce';

import { types } from './actions';

const initialState = {
  list: {},
  loading: false,
};

const startLoader = (state = initialState) => ({ ...state, loading: true });

const stopLoader = (state = initialState) => ({ ...state, loading: false });

const requestAllSuccess = (state = initialState, action) => {
  const { list } = state;
  const { data } = action;

  return { ...state, list: { ...list, ..._.keyBy(data, 'id') } };
};

export default createReducer(initialState, {
  [types.START_LOADER]: startLoader,
  [types.STOP_LOADER]: stopLoader,
  [types.REQUEST_ALL_SUCCESS]: requestAllSuccess,
});

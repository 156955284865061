import './style.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import axios from 'axios';
import _ from 'lodash';
import * as pdfjs from 'pdfjs-dist/legacy/build/pdf';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Document, Page } from 'react-pdf';

import { CONTENT_TYPE_MAPPER } from '../../../utils/calimed-enum';
import { SmallLoader } from '../loaders';
import { Button } from '../styledComponent';
import CustomNavigation from './CustomNavigation';
import DynamicScale from './DynamicScale';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const INITIAL_SCALE = 1;

const PDFViewer = ({
  token,
  documentData,
  scale = INITIAL_SCALE,
  dynamicScale,
  onLastClick,
  disabled = false,
  hidden = false,
  showBtn,
  onDocumentLoadSuccess,
}) => {
  const { t } = useTranslation();
  const docRef = React.useRef();
  const [scaleValue, setScaleValue] = useState(scale);
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdf, setPdf] = useState();
  const [pdfUrl, setPdfUrl] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (onLastClick && !isLoading && pageNumber >= numPages) {
      onLastClick();
    }
  }, [pageNumber, numPages, isLoading]);

  useEffect(() => {
    const fetchData = async (url) => {
      const responseType = 'blob'; // 'arraybuffer' || 'blob';

      url = /^https?:\/\//gi.test(url) ? url : `${process.env.REACT_APP_BASE_API_URL}/${url}`;
      const res = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/pdf',
          Authorization: `Bearer ${token}`,
        },
        responseType,
      });
      const { data } = res;
      const newFileType = res.headers['content-type'];
      setPdf(data);
      const objectUrl = window.URL.createObjectURL(data);
      setPdfUrl(newFileType === CONTENT_TYPE_MAPPER.PDF ? objectUrl : null);
    };
    if (documentData && documentData.url) {
      fetchData(documentData.url);
    }
  }, [documentData && documentData.url]);

  const handleDownload = () => {
    var link = document.createElement('a'); //creating an invisible element
    link.setAttribute('href', pdfUrl || window.URL.createObjectURL(documentData.file));
    link.setAttribute('download', documentData.filename || 'test.pdf');
    document.body.appendChild(link);
    link.click();
    setTimeout(function () {
      window.URL.revokeObjectURL(link);
    }, 200);
  };

  const increaseScale = () => {
    setScaleValue(Number((scaleValue + 0.1).toFixed(1)));
  };

  const decreaseScale = () => {
    setScaleValue(Number((scaleValue - 0.1).toFixed(1)));
  };

  function handleDocumentLoadSuccess({ numPages: nextNumPages }) {
    onDocumentLoadSuccess && onDocumentLoadSuccess();
    setIsLoading(false);
    setNumPages(nextNumPages);
  }

  function handlePrevClick() {
    changePage(-1);
  }

  function handleNextClick() {
    changePage(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  const handlePageLoaded = () => {
    if (pageNumber > 1) {
      docRef?.current?.scrollIntoView({ behavior: 'instant', block: 'center', inline: 'center' });
    }
  };

  return !documentData || _.isEmpty(documentData) ? null : (
    <>
      <div className={`d-flex ${isLoading ? '' : 'd-none'}`}>
        <SmallLoader />
      </div>
      <div className={`ec-pdf-viewer-container d-flex flex-column mb-1 ${hidden || isLoading ? 'd-none' : ''}`}>
        {dynamicScale && <DynamicScale decreaseScale={decreaseScale} increaseScale={increaseScale} scaleLabel={scaleValue} />}
        {documentData && (
          <Document
            inputRef={docRef}
            onLoadSuccess={handleDocumentLoadSuccess}
            file={pdf || documentData.file || documentData}
            renderMode={'canvas'}
            options={{
              cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps`,
              cMapPacked: true,
            }}
          >
            <Page pageNumber={pageNumber} scale={scaleValue} onLoadSuccess={handlePageLoaded} renderTextLayer={false} />
          </Document>
        )}
        <CustomNavigation
          page={pageNumber}
          pages={numPages}
          onPrevClick={() => !disabled && handlePrevClick()}
          onNextClick={() => !disabled && handleNextClick()}
        />
        {showBtn && (
          <div className="d-flex justify-content-end">
            <Button onClick={handleDownload} type="button">
              {t('download')}
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default PDFViewer;

import { createAction } from '../../utils';

// Types
export const types = {
  SPECIALITIES_REQUEST: 'SPECIALITIES_REQUEST',
  SPECIALITIES_FAILURE: 'SPECIALITIES_FAILURE',
  SPECIALITIES_SUCCESS: 'SPECIALITIES_SUCCESS',

  SPECIALITIES_UPDATE_REQUEST: 'SPECIALITIES_UPDATE_REQUEST',
  SPECIALITIES_UPDATE_FAILURE: 'SPECIALITIES_UPDATE_FAILURE',
  SPECIALITIES_UPDATE_SUCCESS: 'SPECIALITIES_UPDATE_SUCCESS',

  SPECIALITIES_CREATE_REQUEST: 'SPECIALITIES_CREATE_REQUEST',
  SPECIALITIES_CREATE_FAILURE: 'SPECIALITIES_CREATE_FAILURE',
  SPECIALITIES_CREATE_SUCCESS: 'SPECIALITIES_CREATE_SUCCESS',

  SPECIALITIES_DELETE_REQUEST: 'SPECIALITIES_DELETE_REQUEST',
  SPECIALITIES_DELETE_MULTIPLE_REQUEST: 'SPECIALITIES_DELETE_MULTIPLE_REQUEST',
  SPECIALITIES_DELETE_SUCCESS: 'SPECIALITIES_DELETE_SUCCESS',
};

// Actions
export default {
  /* CREATE */
  specialitiesCreateRequest: (speciality = {}) => createAction(types.SPECIALITIES_CREATE_REQUEST, { speciality }),
  specialitiesCreateSuccess: () => createAction(types.SPECIALITIES_CREATE_SUCCESS),

  /* UPDATE */
  specialitiesUpdateRequest: (speciality = {}) => createAction(types.SPECIALITIES_UPDATE_REQUEST, { speciality }),
  specialitiesUpdateSuccess: () => createAction(types.SPECIALITIES_UPDATE_SUCCESS),

  /** REQUEST */
  specialitiesRequest: (filters = {}) => createAction(types.SPECIALITIES_REQUEST, { filters }),
  specialitiesSuccess: (specialities) => createAction(types.SPECIALITIES_SUCCESS, { specialities }),
  specialitiesFailure: () => createAction(types.SPECIALITIES_FAILURE),

  specialitiesDeleteRequest: (ids) => createAction(types.SPECIALITIES_DELETE_REQUEST, { ids }),
  specialitiesDeleteSuccess: (id) => createAction(types.SPECIALITIES_DELETE_SUCCESS, { id }),
};

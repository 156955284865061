import { encode } from 'base-64';

import { methods } from '../utils';
import { authenticatedService, service } from './middleware';

const { POST, GET } = methods;
const routes = {
  login: process.env.REACT_APP_BASE_API_URL + '/auth/authorize',
  getToken: process.env.REACT_APP_BASE_API_URL + '/auth/token',
  renewToken: process.env.REACT_APP_BASE_API_URL + '/auth/get_new_token',
};

export default {
  login: ({ username, password }) =>
    service(
      GET,
      routes.login,
      {},
      {
        prompt: 'none',
        response_type: 'code',
        redirect_uri: process.env.REACT_APP_BASE_API_URL,
        client_id: 'easy_consent_BO',
      },
      {
        Authorization: 'Basic ' + encode(username + ':' + password),
      },
    ),
  getToken: ({ username, password, code }) =>
    service(
      POST,
      routes.getToken,
      `grant_type=authorization_code&code=${code}&redirect_uri=${process.env.REACT_APP_BASE_API_URL}`,
      {},
      {
        Authorization: 'Basic ' + encode(username + ':' + password),
      },
    ),
  refreshToken: ({ authorization, refresh_token }) =>
    service(
      POST,
      routes.getToken,
      'grant_type=refresh_token&refresh_token=' + refresh_token,
      {},
      {
        Authorization: 'Basic ' + authorization,
      },
    ),
  renewToken: () => authenticatedService(POST, routes.renewToken),
};

import { connect } from 'react-redux';

import { MedicTemplateActions } from '../../../../redux/medicTemplates';
import { SurveyActions } from '../../../../redux/surveys';
import { TemplatesActions } from '../../../../redux/templates';
import CreateForm from './CreateForm';

const mapDispatchToProps = (dispatch) => ({
  templateUpsertRequest: (template) => dispatch(TemplatesActions.upsertRequest(template)),
  surveyCurrentDelete: () => dispatch(SurveyActions.surveyCurrentDelete()),
  medicTemplateUpsert: (template) => dispatch(MedicTemplateActions.medicTemplateUpsert(template)),
  clearSelectedMedicTemplate: () => dispatch(MedicTemplateActions.clearSelectedTemplate()),
  clearSelectedTemplate: () => dispatch(TemplatesActions.clearSelectedTemplate()),
});

export default connect(null, mapDispatchToProps)(CreateForm);

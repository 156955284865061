import { connect } from 'react-redux';

import { ClinicActions } from '../../../redux/clinics';
import { ConsentActions } from '../../../redux/consents';
import { InterventionActions } from '../../../redux/interventions';
import { MedicalTeamActions } from '../../../redux/medicalTeams';
import { MedicActions } from '../../../redux/medics';
import { MedicTemplateActions } from '../../../redux/medicTemplates';
import { PatientActions } from '../../../redux/patients';
import { SpecialityActions } from '../../../redux/specialities';
import { SurveyTypesActions } from '../../../redux/surveyTypes';
import ConsentsCreate from './ConsentsCreate';

const mapStateToProps = ({ auth, consents, surveyTypes, patients, clinics, medicTemplates, medics, medicalTeams }) => ({
  listSurveyTypes: Object.values(surveyTypes.list || {}),
  listPatients: Object.values(patients.list || {}),
  listSurveys: Object.values(medicTemplates.list),
  listClinics: Object.values(clinics.list || {}),
  listMedics: Object.values(medics.list || {}),
  listMedicTeams: Object.values(medicalTeams.list || {}),
  consentsList: consents.list,
  userLanguageId: auth.languageId,
});

const mapDispatchToProps = (dispatch) => ({
  clinicsRequest: (filter) => dispatch(ClinicActions.clinicsRequest(filter)),
  surveysRequest: () => dispatch(MedicTemplateActions.medicTemplateGet()),
  medicsRequest: (filter) => dispatch(MedicActions.medicsRequest(filter)),
  medicalTeamsRequest: (filter) => dispatch(MedicalTeamActions.medicalTeamsRequest(filter)),
  specialitiesRequest: (filter) => dispatch(SpecialityActions.specialitiesRequest(filter)),
  surveyTypesRequest: (filter) => dispatch(SurveyTypesActions.surveyTypesRequest(filter)),
  patientsRequest: (filter) => dispatch(PatientActions.patientsRequest(filter)),
  interventionsUpdateRequest: (consent) => dispatch(InterventionActions.interventionsUpdateRequest(consent)),
  consentsRequest: (filter) => dispatch(ConsentActions.consentsRequest(filter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConsentsCreate);

import { connect } from 'react-redux';

import { ClinicActions } from '../../../redux/clinics';
import { ClinicsGroupsActions } from '../../../redux/clinicsGroups';
import ClinicsGroupsCreate from './ClinicsGroupsCreate';

const mapStateToProps = ({ clinicsGroups, clinics, auth }) => {
  return {
    clinicsGroupsList: Object.values(clinicsGroups?.list || {}),
    clinicsList: Object.values(clinics?.list || {}),
    authorizationToken: `${auth.token_type} ${auth.token}`,
  };
};

const mapDispatchToProps = (dispatch) => ({
  clinicsGroupCreateRequest: (clinicsGroup) => dispatch(ClinicsGroupsActions.clinicsGroupCreateRequest(clinicsGroup)),
  clinicsGroupUpdateRequest: (clinicsGroup) => dispatch(ClinicsGroupsActions.clinicsGroupUpdateRequest(clinicsGroup)),
  clinicsGroupsRequest: (filter) => dispatch(ClinicsGroupsActions.clinicsGroupsRequest(filter)),
  clinicsUpdateRequest: (clinic) => dispatch(ClinicActions.clinicsUpdateRequest(clinic)),
  clinicsRequest: (filter) => dispatch(ClinicActions.clinicsRequest(filter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClinicsGroupsCreate);

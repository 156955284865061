import { authenticatedMutation, authenticatedQuery } from './middleware';

/**
 * GRAPHQL QUERIES
 */
const queries = {
  getQuestionTypes: () => `
    query QuestionTypes {
      question_types {
        value
        id
      }
    }
  `,
  questionTypes: () => `
    query QuestionTypes($id: Int) {
      question_types (where: {id: {_eq: $id}}) {
        value
        id
      }
    }
  `,
};
/**
 * GRAPHQL MUTATION
 *
 */

const mutations = {
  questionTypesCreate: () => `
    mutation QuestionTypesCreate($value: String!) {
      insert_question_types(objects: {value: $value}) {
        returning {
          id
          created_at
          updated_at
          value
        }
      }
    }
  `,
  questionTypesUpdate: () => `
    mutation QuestionsUpdate($id: Int!, $value: String!) {
      update_question_types(
        where: {id: {_eq: $id}}, 
        _set: {value: $value}
      ) {
        returning {
          id
          value
          updated_at
        }
      }
    }
  `,
  questionTypesDelete: () => `
    mutation {
      delete_question_types(where: {id: {_eq: $id}}) {
        returning {
          id
        }
      }
    }
  `,
};

export default {
  getQuestionTypes: () => authenticatedQuery(queries.getQuestionTypes(), {}, {}),
  questionTypes: (id = null) => authenticatedQuery(queries.questionTypes(), {}, { id }),
  questionTypesCreate: (questionTypes) => authenticatedMutation(mutations.questionTypesCreate(), {}, { value: questionTypes.value }),
  questionTypesUpdate: (questionType) =>
    authenticatedMutation(mutations.questionTypesUpdate(), {}, { id: questionType.id, value: questionType.value }),
  questionTypesDelete: (id) => authenticatedMutation(mutations.questionTypesDelete(), {}, { id }),
};

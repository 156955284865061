import './style.css';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconFindMore, IconMoreOptions, IconPlus } from '../../../../assets/img/icons';
import { PrimaryButton } from '../../../components/button';
import Card from '../../../components/card';
import { Switch } from '../../../components/formV3';
import Question from './Question';

const Menu = ({ questionTypes, onAddStep, onReorder, onSearchQuestion, can }) => {
  const divRef = React.useRef();
  const { t } = useTranslation();
  const [scrollPosition, setScrollPosition] = React.useState(0);
  const [divStyle, setDivStyle] = React.useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  React.useEffect(() => {
    if (document) {
      window.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (document) {
        window.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  React.useEffect(() => {
    if (divRef?.current && scrollPosition > divRef.current.offsetTop) {
      setDivStyle({ position: 'fixed', top: '50px' });
    } else {
      setDivStyle();
    }
  }, [scrollPosition]);

  const handleOnAddStep = () => {
    if (onAddStep) onAddStep();
  };

  const handleOnReorder = () => {
    if (onReorder) onReorder();
  };

  const handleOpenSearchModal = () => {
    if (onSearchQuestion) onSearchQuestion();
  };

  return (
    <div className="createform-menu-container" ref={divRef}>
      <div className="createform-menu-cards-container " style={{ ...divStyle }}>
        <Card>
          <p className="createform-menu-card-title"> {'Action'} </p>
          <PrimaryButton center={'left'} active={false} image={IconPlus} text={t('a_step_translation')} onClick={handleOnAddStep} />
          <PrimaryButton center={'left'} active={false} image={IconMoreOptions} text={t('reorder_translation')} onClick={handleOnReorder} />
          <PrimaryButton center={'left'} active={false} image={IconFindMore} text={t('search_for_questions')} onClick={handleOpenSearchModal} />
        </Card>
        {can.publish && (
          <div className="d-flex flex-column">
            <p className="createform-published-switch-label"> Publié </p>
            <Switch name="is_published" />
          </div>
        )}
        <Card>
          <p className="createform-menu-card-title">{t('question_types')}</p>
          {questionTypes.map((type) => {
            const { id, value } = type;
            return <Question key={`question_type_${id}_${value}`} type={type} text={t(`question_type_${value}`)} />;
          })}
        </Card>
      </div>
    </div>
  );
};

export default Menu;

import { connect } from 'react-redux';

import { AuthActions } from '../../../redux/auth';
import { MedicalTeamActions } from '../../../redux/medicalTeams';
import { MedicActions } from '../../../redux/medics';
import { MedicTemplateActions } from '../../../redux/medicTemplates';
import { QuestionTypesActions } from '../../../redux/questionTypes';
import { SpecialityActions } from '../../../redux/specialities';
import { SurveyTypesActions } from '../../../redux/surveyTypes';
import { TemplatesActions } from '../../../redux/templates';
import SurveysCreate from './SurveysCreate';

const mapStateToProps = ({ templates, medicTemplates, language, questionTypes }, { isStoreTemplate }) => {
  const selectedTemplate = isStoreTemplate ? templates.selectedTemplate : medicTemplates.selectedTemplate;
  return {
    currentForm: selectedTemplate,
    languageList: Object.values(language.list || {}),
    questionTypes: (questionTypes && questionTypes.list) || {},
  };
};

const mapDispatchToProps = (dispatch) => ({
  loginRefresh: () => dispatch(AuthActions.loginRefresh()),
  surveyTypesRequest: (filter) => dispatch(SurveyTypesActions.surveyTypesRequest(filter)),
  questionTypesRequest: (filter) => dispatch(QuestionTypesActions.questionTypesRequest(filter)),
  specialitiesRequest: () => dispatch(SpecialityActions.specialitiesRequest()),
  currentTemplateDetail: (id, lngId) => dispatch(TemplatesActions.templateGetDetailRequest(id, lngId)),
  medicTemplateDetail: (id) => dispatch(MedicTemplateActions.medicTemplateDetail(id)),
  medicalTeamsRequest: (filter) => dispatch(MedicalTeamActions.medicalTeamsRequest(filter)),
  medicsRequest: () => dispatch(MedicActions.medicsRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SurveysCreate);

/* eslint-disable jsx-a11y/no-onchange */
import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.css';

import { eachDayOfInterval, eachMonthOfInterval, endOfWeek, endOfYear, format, getYear, startOfWeek, startOfYear } from 'date-fns';
import { fr } from 'date-fns/locale';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useController, useFormContext } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';

import { parseDate } from '../../../../../services/datefns';
import { PRESET } from '../../preset/preset';

const now = new Date();

const months = eachMonthOfInterval({
  start: startOfYear(now),
  end: endOfYear(now),
}).map((d) => format(d, 'LLLL', { locale: fr }));

const days = eachDayOfInterval({
  start: startOfWeek(now),
  end: endOfWeek(now),
}).map((d) => format(d, 'EEEEEE', { locale: fr }));

const DatePicker = ({
  name,
  rules = { required: false },
  inputPreset,
  labelClassName,
  className,
  disabled,
  minDate: minDateProps,
  maxDate: maxDateProps,
  defaultValue,
}) => {
  const [selectedDate, setSelectedDate] = useState();
  const [minDate, setMinDate] = useState(minDateProps);
  const [maxDate, setMaxDate] = useState(maxDateProps);
  const [yearsRange, setYearsRange] = useState([]);
  const datePickerRef = useRef(null);
  const { control } = useFormContext(); // retrieve all hook methods
  const {
    field: { ref, value, onChange, ...inputProps }, // field : { onChange, onBlur, value, name: fieldName, ref, ...inputProps },
    fieldState: { error }, // fieldState: { invalid, isTouched, isDirty, error },
    // formState: { errors, isDirty, isSubmitting, touched, submitCount },
  } = useController({
    name,
    control,
    rules: { ...rules },
    defaultValue: defaultValue || '',
  });

  useImperativeHandle(ref, () => ({
    focus: () => {
      datePickerRef.current.setOpen(true);
      datePickerRef.current.input.focus();
    },
  }));

  useEffect(() => {
    const preset = PRESET[inputPreset] || {};
    // default values
    if (_.isObject(preset)) {
      setMinDate(minDateProps || _.get(preset, 'preset.minDate'));
      setMaxDate(maxDateProps || _.get(preset, 'preset.maxDate'));
    }
  }, [inputPreset, minDateProps, maxDateProps]);

  useEffect(() => {
    let newDateValue = value;
    if (typeof newDateValue === 'string' || newDateValue instanceof String) {
      newDateValue = newDateValue.length ? parseDate(value) : undefined;
    }
    if (newDateValue !== selectedDate) {
      setSelectedDate(newDateValue);
      onChange(newDateValue);
    }
  }, [value]);

  useEffect(() => {
    const newYearsRange = range(getYear(minDate), getYear(maxDate), 1);
    if (yearsRange.join() !== newYearsRange.join()) {
      setYearsRange(newYearsRange);
    }
  }, [minDate, maxDate]);

  const locale = {
    ...fr,
    localize: {
      day: (n) => days[n],
      month: (n) => months[n],
    },
  };

  return (
    <div className={className}>
      <ReactDatePicker
        minDate={minDate}
        maxDate={maxDate}
        showDisabledMonthNavigation
        adjustDateOnChange
        dateFormat="dd/MM/yyyy"
        selected={selectedDate}
        locale={locale}
        disabled={disabled}
        isClearable={!disabled}
        onChange={onChange}
        {...inputProps}
        renderCustomHeader={customHeader(yearsRange, months)}
        ref={(e) => {
          ref(e);
          datePickerRef.current = e; // you can still assign to ref
        }}
        customInput={<CustomInput name={name} error={error} />}
      />
    </div>
  );
};

const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

const CustomInput = React.forwardRef(({ name, error, ...props }, ref) => {
  const customInputRef = useRef(null);
  return (
    <ReactInputMask
      mask="d9/m9/ab99"
      formatChars={{
        a: '[1-2]',
        b: '[90]',
        m: '[0-1]',
        d: '[0-3]',
        9: '[0-9]',
      }}
      name={name}
      {...props}
    >
      {(inputMaskProps) => (
        <input
          type="text"
          className="react-datepicker-ignore-onclickoutside"
          ref={(e) => {
            ref(e);
            customInputRef.current = e; // you can still assign to ref
          }}
          aria-invalid={error ? 'true' : 'false'}
          key={`inputDatePicker_${name}`}
          {...inputMaskProps}
        />
      )}
    </ReactInputMask>
  );
});

const customHeader =
  (yearsRange, months) =>
  ({ date, changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }) =>
    (
      <div
        style={{
          margin: 10,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
          {'<'}
        </button>
        <select value={date.getFullYear()} onChange={({ target: { value } }) => changeYear(value)}>
          {yearsRange.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
        <select value={months[date.getMonth()]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
          {months.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
        <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
          {'>'}
        </button>
      </div>
    );

DatePicker.propTypes = {
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  rules: PropTypes.object,
  inputPreset: PropTypes.string,
  labelClassName: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
};

export default DatePicker;

import _ from 'lodash';
import PropTypes from 'prop-types';
import { useFormContext, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const Message = ({ message, classNameError, name }) => {
  const { t } = useTranslation();
  return message ? (
    <div
      className={`custom-formV2-control-error-text ${!message && 'invisible'} ${classNameError || ''}`}
      key={`custom-formV2-control-error_${name}`}
    >
      <span role="alert">{(message && (message.includes(' ') ? message : t(message))) || 'message erreur'}</span>
    </div>
  ) : null;
};

const getMessage = (value, index, classNameError) => {
  if (Array.isArray(value)) {
    return value.map((v, vi) => getMessage(v, `${index}-${vi}`, classNameError));
  } else if ('message' in value) {
    const { message } = value;
    return (
      <li key={`ErrorMessage${index}`}>
        <Message message={message} classNameError={classNameError} />
      </li>
    );
  } else {
    return _.map(value, function (v, n) {
      return getMessage(v, n, classNameError);
    });
  }
};

const ErrorMessage = ({ name, classNameError }) => {
  // const [ message, seMessage ] = useState();
  const { control } = useFormContext(); // retrieve all hook methods
  const { errors } = useFormState({ control });
  if (!name && !_.isEmpty(errors)) {
    return (
      <ul>
        {_.map(errors, function (value, n) {
          return getMessage(value, n, classNameError);
        })}
      </ul>
    );
  } else {
    const message = _.get(errors, `${name}.message`);
    return <Message message={message} classNameError={classNameError} name={name} />;
  }
};

ErrorMessage.propTypes = {
  name: PropTypes.string,
  classNameError: PropTypes.string,
};

export default ErrorMessage;

import { Button } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import shortid from 'shortid';

function Extra({ urlToManagementPage, btnTitle }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handlClick = () => {
    urlToManagementPage && navigate(urlToManagementPage);
  };

  return (
    <>
      <Button key={shortid.generate()} type="primary" onClick={handlClick} htmlType="button">
        {btnTitle ? t(btnTitle) : 'Ajouter'}
      </Button>
    </>
  );
}

Extra.propTypes = {
  btnTitle: PropTypes.string,
  urlToManagementPage: PropTypes.string,
};

export default Extra;

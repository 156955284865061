export default {
  updateOffsetFromEventId: `
    mutation UpdateOffsetFromEventId($eventId: Int!, $surveyTypeId: Int!, $offset: String!) {
      update_offset_event_alerts(where: {event_alerts: {id: {_eq: $eventId}, survey_type_id: {_eq: $surveyTypeId}}}, _set: {offset: $offset}) {
        returning {
          id
        }
      }
    }
  `,
  deleteFromSurveyTypeId: `
    mutation DeleteFromSurveyTypeId($surveyTypeId: Int!) {
      delete_communication_mode_event_alerts(where: {event_alert: {survey_type_id: {_eq: $surveyTypeId}}}) {
        returning {
          id
        }
      }
      delete_event_alerts(where: {survey_type_id: {_eq: $surveyTypeId}}) {
        returning {
          id
        }
      }
    }
  `,
  deleteNotIn: `
    mutation DeleteNotIn($ids: [Int!]!, $surveyTypeId: Int!) {
      delete_communication_mode_event_alerts(where: {event_alert: {id: {_nin: $ids}, survey_type_id: {_eq: $surveyTypeId}}}) {
        returning {
          id
        }
      }
      delete_event_alerts(where: {id: {_nin: $ids}, survey_type_id: {_eq: $surveyTypeId}}) {
        returning {
          id
        }
      }
    }
  `,
  deleteSome: `
    mutation DeleteSome($ids: [Int!]!) {
      delete_communication_mode_event_alerts(where: {event_alert_id: {_in: $ids}}) {
        returning {
          id
        }
      }
      delete_event_alerts(where: {id: {_in: $ids}}) {
        returning {
          id
        }
      }
    }
  `,
  addOneEvent: `
    mutation AddOne($event: [event_alerts_insert_input!]!) {
      insert_event_alerts(objects: $event) {
        returning {
          id
        }
      }
    }
  `,
  updateOneEvent: `
    mutation updateOne($eventId: Int!, $addedCommunicationMode: [communication_mode_event_alerts_insert_input!]!, $event: event_alerts_set_input!) {
      delete_communication_mode_event_alerts(where: {event_alert_id: {_eq: $eventId}}) {
        returning {
          id
        }
      }
      insert_communication_mode_event_alerts(objects: $addedCommunicationMode) {
        returning {
          id
        }
      }
      update_event_alerts(
        where: {id: {_eq: $eventId}}, 
        _set: $event
      ) {
        returning {
          id
        }
      }
    } 
  `,
  createOffsets: `
    mutation createOffsets($offsets: [offset_event_alerts_insert_input!]!) {
      insert_offset_event_alerts(objects: $offsets) {
        returning {
          id
          offset
        }
      }
    }
  `,
};

import './clinics.css';

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { filterAndOrderList } from '../../../utils/utils';
import Filter from '../../components/filter';
import { Tags } from '../../components/tags';
import DataTable from './DataTable';

const CLINIC_FILTER_KEYS = ['name', 'city', 'address', 'postcode'];

const Clinics = ({ clinicsRequest, list, countries, loading, clinicsDeleteRequest, clinicsDeleteMultipleRequest }) => {
  let navigate = useNavigate();
  const [filtered, setFiltered] = useState();
  const [reset, setReset] = useState(false);

  useEffect(() => {
    clinicsRequest({});
  }, []);

  const clinics = Object.values(list);

  const onFilter = (result) => {
    if (!result) setFiltered(null);
    else {
      setFiltered(result.map((r) => r.item.id));
      setReset(!reset);
    }
  };

  const onModify = (clinic) => {
    navigate(`/clinics/${clinic.id}`, clinic);
  };

  const onDelete = (clinic) => {
    if (Array.isArray(clinic)) {
      clinicsDeleteMultipleRequest(clinic);
    } else {
      clinicsDeleteRequest(clinic.id);
    }
  };

  const exportMapper = (toExport) =>
    toExport.map((e) => {
      return {
        id: e.id,
        Nom: e.name,
        Adresse: e.address,
        Ville: e.city,
        'Code postale': e.postcode,
      };
    });

  const exportDataToXls = () => exportMapper(Array.isArray(filtered) ? clinics.filter((r) => filtered.includes(r.id)) : clinics);

  return (
    <>
      <Tags exportFct={exportDataToXls} />
      <Filter list={clinics} onFilter={onFilter} keys={CLINIC_FILTER_KEYS} />

      <DataTable
        countries={countries}
        items={filterAndOrderList(clinics, filtered)}
        reset={reset}
        loading={loading}
        itemClick={onModify}
        onDelete={onDelete}
        onModify={onModify}
      />
    </>
  );
};

export default Clinics;

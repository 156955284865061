import { createAction } from '../../utils';

// Types
export const types = {
  TEMPLATE_GET_REQUEST: 'TEMPLATE_GET_REQUEST',
  TEMPLATE_GET_SUCCESS: 'TEMPLATE_GET_SUCCESS',
  TEMPLATE_GET_FAILURE: 'TEMPLATE_GET_FAILURE',

  TEMPLATE_GET_DETAIL_REQUEST: 'TEMPLATE_GET_DETAIL_REQUEST',
  TEMPLATE_GET_DETAIL_SUCCESS: 'TEMPLATE_GET_DETAIL_SUCCESS',
  TEMPLATE_GET_DETAIL_FAILURE: 'TEMPLATE_GET_DETAIL_FAILURE',

  TEMPLATE_CREATE_REQUEST: 'TEMPLATE_CREATE_REQUEST',
  TEMPLATE_CREATE_SUCCESS: 'TEMPLATE_CREATE_SUCCESS',
  TEMPLATE_CREATE_FAILURE: 'TEMPLATE_CREATE_FAILURE',

  TEMPLATE_UPDATE_REQUEST: 'TEMPLATE_UPDATE_REQUEST',
  TEMPLATE_UPDATE_SUCCESS: 'TEMPLATE_UPDATE_SUCCESS',
  TEMPLATE_UPDATE_FAILURE: 'TEMPLATE_UPDATE_FAILURE',

  TEMPLATE_UPDATE_PUBLISHED_REQUEST: 'TEMPLATE_UPDATE_PUBLISHED_REQUEST',
  TEMPLATE_UPDATE_PUBLISHED_SUCCESS: 'TEMPLATE_UPDATE_PUBLISHED_SUCCESS',
  TEMPLATE_UPDATE_PUBLISHED_FAILURE: 'TEMPLATE_UPDATE_PUBLISHED_FAILURE',

  TEMPLATE_DELETE_REQUEST: 'TEMPLATE_DELETE_REQUEST',
  TEMPLATE_DELETE_SUCCESS: 'TEMPLATE_DELETE_SUCCESS',
  TEMPLATE_DELETE_FAILURE: 'TEMPLATE_DELETE_FAILURE',

  TEMPLATE_DUPLICATE_REQUEST: 'TEMPLATE_DUPLICATE_REQUEST',
  TEMPLATE_DUPLICATE_SUCCESS: 'TEMPLATE_DUPLICATE_SUCCESS',
  TEMPLATE_DUPLICATE_FAILURE: 'TEMPLATE_DUPLICATE_FAILURE',

  TEMPLATES_RESET_STATUS_CHANGED: 'TEMPLATES_RESET_STATUS_CHANGED',

  TEMPLATES_CLEAR_FIND_QUESTION: 'TEMPLATES_CLEAR_FIND_QUESTION',

  UPSERT_REQUEST: 'template_UPSERT_REQUEST',
  UPSERT_SUCCESS: 'template_UPSERT_SUCCESS',
  UPSERT_FAILURE: 'template_UPSERT_FAILURE',

  CLEAR_SELECTED_TEMPLATE: 'medicTemplates_CLEAR_SELECTED_TEMPLATE',
};

export default {
  templateGetRequest: () => createAction(types.TEMPLATE_GET_REQUEST),
  templateGetSuccess: (templates) => createAction(types.TEMPLATE_GET_SUCCESS, { templates }),
  templateGetFailure: () => createAction(types.TEMPLATE_GET_FAILURE),

  templateGetDetailRequest: (id, lngId = 1, keyDestination = null) => createAction(types.TEMPLATE_GET_DETAIL_REQUEST, { id, lngId, keyDestination }),
  templateGetDetailSuccess: (templates, keyDestination = null) => createAction(types.TEMPLATE_GET_DETAIL_SUCCESS, { templates, keyDestination }),
  templateGetDetailFailure: () => createAction(types.TEMPLATE_GET_DETAIL_FAILURE),

  templateCreateRequest: (template) => createAction(types.TEMPLATE_CREATE_REQUEST, { template }),
  templateCreateSuccess: (template) => createAction(types.TEMPLATE_CREATE_SUCCESS, { template }),
  templateCreateFailure: () => createAction(types.TEMPLATE_CREATE_FAILURE),

  upsertRequest: (template) => createAction(types.UPSERT_REQUEST, { template }),
  upsertSuccess: (template) => createAction(types.UPSERT_SUCCESS, { template }),
  upsertFailure: () => createAction(types.UPSERT_FAILURE),

  templateUpdateRequest: (template) => createAction(types.TEMPLATE_UPDATE_REQUEST, { template }),
  templatesUpdateSuccess: (template) => createAction(types.TEMPLATE_UPDATE_SUCCESS, { template }),
  templatesUpdateFailure: () => createAction(types.TEMPLATE_UPDATE_FAILURE),

  templatesUpdatePublishedRequest: (IDs, status) => createAction(types.TEMPLATE_UPDATE_PUBLISHED_REQUEST, { IDs, status }),
  templatesUpdatePublishedSuccess: (IDs, status) => createAction(types.TEMPLATE_UPDATE_PUBLISHED_SUCCESS, { IDs, status }),
  templatesUpdatePublishedFailure: () => createAction(types.TEMPLATE_UPDATE_PUBLISHED_FAILURE),

  templateDeleteRequest: (templates) => createAction(types.TEMPLATE_DELETE_REQUEST, { templates }),
  templateDeleteSuccess: (IDs) => createAction(types.TEMPLATE_DELETE_SUCCESS, { IDs }),
  templateDeleteFailure: () => createAction(types.TEMPLATE_DELETE_FAILURE),

  templatesResetStatusChanged: () => createAction(types.TEMPLATES_RESET_STATUS_CHANGED),

  templateDuplicateRequest: (id) => createAction(types.TEMPLATE_DUPLICATE_REQUEST, { id }),
  templateDuplicateSuccess: (IDs) => createAction(types.TEMPLATE_DUPLICATE_SUCCESS, { IDs }),
  templateDuplicateFailure: () => createAction(types.TEMPLATE_DUPLICATE_FAILURE),

  clearFindQuestion: () => createAction(types.TEMPLATES_CLEAR_FIND_QUESTION),

  clearSelectedTemplate: () => createAction(types.CLEAR_SELECTED_TEMPLATE),
};

import { LEGAL_PAGE_TYPES } from '../../ui/screens/legal/Legal';
import { createAction } from '../../utils';

// Types
export const types = {
  CGU_REQUEST: 'CGU_REQUEST',
  CGU_FAILURE: 'CGU_FAILURE',
  CGU_SUCCESS: 'CGU_SUCCESS',

  CGU_INSERT_REQUEST: 'CGU_INSERT_REQUEST',

  LEGALS_RESET: 'LEGALS_RESET',

  RGPD_REQUEST: 'RGPD_REQUEST',
  RGPD_SUCCESS: 'RGPD_SUCCESS',
  RGPD_FAILURE: 'RGPD_FAILURE',

  RGPD_INSERT_REQUEST: 'RGPD_INSERT_REQUEST',
};

// Actions
export default {
  /** CGU */
  cguInsertRequest: (cguList) => createAction(types.CGU_INSERT_REQUEST, { cguList }),
  cguReset: (roles, type = LEGAL_PAGE_TYPES.CGU) => createAction(types.LEGALS_RESET, { roles, type }),

  cguRequest: (cgu = {}) => createAction(types.CGU_REQUEST, { cgu }),
  cguSuccess: (cgu) => createAction(types.CGU_SUCCESS, { cgu }),
  cguFailure: () => createAction(types.CGU_FAILURE),

  /** RGPD */
  rgpdInsertRequest: (rgpdList) => createAction(types.RGPD_INSERT_REQUEST, { rgpdList }),
  rgpdReset: (roles, type = LEGAL_PAGE_TYPES.RGPD) => createAction(types.LEGALS_RESET, { roles, type }),

  rgpdRequest: () => createAction(types.RGPD_REQUEST),
  rgpdSuccess: (rgpd) => createAction(types.RGPD_SUCCESS, { rgpd }),
  rgpdFailure: () => createAction(types.RGPD_FAILURE),
};

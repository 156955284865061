import PropTypes from 'prop-types';
import React from 'react';

import { IconCheckBox, IconCheckBoxFull, IconDropDownList, IconPDF, IconTextAnswerSmall, IconValidation } from '../../../../../assets/img/icons';
import { SURVEY_TYPES } from '../../../../../utils/dataMappers';

const Question = ({ type, text }) => {
  const { value } = type;

  const onDragStart = (event) => {
    // UTILISER react-dnd POUR MEILLEUR EXPÉRIENCE
    event.dataTransfer.setData('text/plain', JSON.stringify(type));
  };

  const ImageForType = () => {
    switch (value) {
      case SURVEY_TYPES.validation:
        return IconValidation;
      case SURVEY_TYPES.text_answer:
        return IconTextAnswerSmall;
      case SURVEY_TYPES.multiple_choice:
        return IconCheckBox;
      case SURVEY_TYPES.dropdown_list:
        return IconDropDownList;
      case SURVEY_TYPES.pdf:
        return IconPDF;
      case SURVEY_TYPES.checkbox_full:
        return IconCheckBoxFull;
      default:
        return IconValidation;
    }
  };

  return (
    <div className="createform-menu-single-question-container" draggable onDragStart={(e) => onDragStart(e)}>
      <div className="createform-menu-single-question-image-container">
        <img src={ImageForType()} />
      </div>
      <p className="createform-menu-single-question-text">{text}</p>
    </div>
  );
};

Question.propTypes = {
  type: PropTypes.shape({
    id: PropTypes.number.isRequired,
    value: PropTypes.string.isRequired,
  }),
  text: PropTypes.string.isRequired,
};

export default Question;

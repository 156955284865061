import { createAction } from '../../utils';

// Types
export const types = {
  SMS_REQUEST: 'SMS_REQUEST',
  SMS_FAILURE: 'SMS_FAILURE',
  SMS_SUCCESS: 'SMS_SUCCESS',

  SMS_UPDATE_REQUEST: 'SMS_UPDATE_REQUEST',
  SMS_UPDATE_FAILURE: 'SMS_UPDATE_FAILURE',
  SMS_UPDATE_SUCCESS: 'SMS_UPDATE_SUCCESS',
};

// Actions
export default {
  /* UPDATE */
  smsUpdateRequest: (sms = {}) => createAction(types.SMS_UPDATE_REQUEST, { sms }),
  smsUpdateSuccess: () => createAction(types.SMS_UPDATE_SUCCESS),

  /** REQUEST */
  smsRequest: (filters = {}) => createAction(types.SMS_REQUEST, { filters }),
  smsSuccess: (sms) => createAction(types.SMS_SUCCESS, { sms }),
  smsFailure: () => createAction(types.SMS_FAILURE),
};

import './specialities.css';

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { filterAndOrderList } from '../../../utils/utils';
import Filter from '../../components/filter';
import { Tags } from '../../components/tags';
import DataTable from './DataTable';

const SPECIALITIES_FILTER_KEYS = ['translation.value'];

const Specialities = ({ specialitiesRequest, specialities, loading, specialitiesDeleteRequest, userLanguageId }) => {
  const navigate = useNavigate();
  const [filtered, setFiltered] = useState();
  const [reset, setReset] = useState(false);
  const [list, setList] = useState([]);

  useEffect(() => {
    specialitiesRequest();
  }, []);

  useEffect(() => {
    if (Array.isArray(specialities)) {
      const newSpecialities = specialities.map((sp) => {
        const { specialities_translations } = sp;
        if (specialities_translations[userLanguageId]) {
          return {
            ...sp,
            value: specialities_translations[userLanguageId].value,
          };
        } else {
          return {
            ...sp,
            value: Object.values(specialities_translations)[0]?.value,
          };
        }
      });
      setList(newSpecialities);
    }
  }, [specialities]);

  const onFilter = (result) => {
    if (!result) setFiltered(null);
    else {
      setFiltered(result.map((r) => r.item.id));
      setReset(!reset);
    }
  };

  const onModify = (speciality) => {
    navigate(`/specialities/${speciality.id}`, speciality);
  };

  const onDelete = (specialityIds) => {
    specialitiesDeleteRequest(specialityIds);
  };

  const exportDataToXls = () => {
    return Array.isArray(filtered) ? (list || []).filter((r) => filtered.includes(r.Id)) : list;
  };

  return (
    <>
      <Tags exportFct={exportDataToXls} />
      <Filter list={list || []} onFilter={onFilter} keys={SPECIALITIES_FILTER_KEYS} />

      <DataTable
        items={filterAndOrderList(list, filtered)}
        reset={reset}
        loading={loading}
        itemClick={onModify}
        onDelete={onDelete}
        onModify={onModify}
        userLanguageId={userLanguageId}
      />
    </>
  );
};

export default Specialities;

import { connect } from 'react-redux';

import { AppKeyActions } from '../../../redux/appKeys';
import { ClinicActions } from '../../../redux/clinics';
import { LanguageActions } from '../../../redux/languages';
import { MedicalTeamActions } from '../../../redux/medicalTeams';
import { MedicActions } from '../../../redux/medics';
import { RoleActions } from '../../../redux/roles';
import { SpecialityActions } from '../../../redux/specialities';
import { SurveyTypesActions } from '../../../redux/surveyTypes';
import { normalizeString } from '../../../utils';
import { addDisplayTranslation } from '../../../utils/utils';
import AppKeysCreate from './AppKeysCreate';

const mapStateToProps = ({ auth, appKeys, medics, clinics, medicalTeams, roles, templates, surveyTypes, specialities, language }) => {
  return {
    list: appKeys.list || {},
    token: auth.token,
    medicOptions: Object.values(medics.list)
      .map((m) => ({
        value: m.user_id,
        label: `${m.firstname} ${m.lastname}`,
      }))
      .sort((a, b) => normalizeString(a.label) - normalizeString(b.label)),
    clinicOptions: Object.values(clinics.list)
      .map((m) => ({
        value: m.id,
        label: m.name,
      }))
      .sort((a, b) => normalizeString(a.label) - normalizeString(b.label)),
    medicalTeamOptions: Object.values(medicalTeams.list)
      .map((m) => ({
        value: m.id,
        label: m.title,
      }))
      .sort((a, b) => normalizeString(a.label) - normalizeString(b.label)),
    rolesOptions: Object.values(roles.list || {})
      .filter((el) => !el.is_backend)
      .map((el) => ({ value: el.id, label: el.name })),
    appKeyRoleOptions: Object.values(roles.list || {})
      .filter((el) => el.is_backend)
      .map((el) => ({ value: el.id, label: el.name })),
    templatesOptions: Object.values(templates.list)
      .map((el) => el.is_published)
      .map((el) => addDisplayTranslation(el, auth.languageId))
      .map((el) => ({ value: el.id, label: el?.displayTranslation?.title })),
    sideMenuRoutes: auth.sideMenuRoutes,
    surveyTypesOptions: Object.values(surveyTypes.list || {})
      .map((el) => addDisplayTranslation(el, auth.languageId))
      .map((el) => ({ value: el.id, label: el?.displayTranslation?.label })),
    specialitiesOptions: Object.values(specialities.list || {})
      .map((el) => addDisplayTranslation(el, auth.languageId))
      .map((el) => ({ value: el.id, label: el?.displayTranslation?.value })),
    languageOptions: Object.values(language.list || {}).map((lng) => ({
      value: lng.id,
      label: lng.name,
    })),
  };
};

const mapDispatchToProps = (dispatch) => ({
  appKeysRequest: (id) => dispatch(AppKeyActions.request(id)),
  appKeysCreateRequest: (creation) => dispatch(AppKeyActions.createRequest(creation)),
  appKeysUpdateRequest: (payload) => dispatch(AppKeyActions.updateRequest(payload)),
  medicsRequest: () => dispatch(MedicActions.medicsRequest()),
  clinicsRequest: () => dispatch(ClinicActions.clinicsRequest()),
  medicalTeamsRequest: () => dispatch(MedicalTeamActions.medicalTeamsRequest()),
  rolesRequest: (filter) => dispatch(RoleActions.rolesRequest(filter)),
  specialitiesRequest: (filter) => dispatch(SpecialityActions.specialitiesRequest(filter)),
  surveyTypesRequest: (filter) => dispatch(SurveyTypesActions.surveyTypesRequest(filter)),
  fetchAllLanguages: () => dispatch(LanguageActions.fetchAll()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppKeysCreate);

import '../style.scss';

import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { cloneElement, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { reach } from 'yup';

import { deepChildrenMap } from '../../../../utils';
import { getCircularReplacer } from '../../../screens/SurveysCreateV2/CreateForm/CreateForm';
import CustomFormController from '../innerWrapper/CustomFormController';

function Form({ defaultValues, children, onSubmit, onError, onChange, yupSchema, formClassName = 'row', id, updateData }, ref) {
  const [prevDefaultValues, setPrevDefaultValues] = useState();
  const formMethods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onSubmit',
    defaultValues: useMemo(() => {
      return defaultValues;
    }, [defaultValues]),
    resolver: yupResolver(yupSchema),
    shouldFocusError: true,
  });

  useEffect(() => {
    if (!_.isEqual(defaultValues, prevDefaultValues)) {
      setPrevDefaultValues(defaultValues);
      formMethods.reset({ ...defaultValues });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  const onErrors = (errors) => {
    // eslint-disable-next-line no-console
    console.error(errors);
    if (onError) onError(errors);
  };
  const onSubmits = (data) => {
    updateData(id, data);
    if (onSubmit) onSubmit(data);
  };

  const { handleSubmit, watch } = formMethods;

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmits, onErrors)} className={`custom-formV2 ${formClassName}`}>
          {children &&
            deepChildrenMap(children, (child, index) => {
              if (child && child.type === CustomFormController) {
                return cloneElement(child, {
                  ...child.props,
                  key: `${child.type.name}_${child.props.name || index}`,
                  isRequiredField: isRequiredField(yupSchema, child.props.name),
                });
              }
              return child;
            })}
          {!!process.env.REACT_APP_DEBUG_FORM && <pre>{JSON.stringify(watch(), getCircularReplacer(), 2)}</pre>}
        </form>
      </FormProvider>
    </>
  );
}

// export default forwardRef(Form);
export default Form;

Form.propTypes = {
  defaultValues: PropTypes.object,
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func,
  yupSchema: PropTypes.object,
  formClassName: PropTypes.string,
};

export const isRequiredField = (validationSchema, name) => {
  const r = reach(validationSchema, name)?.describe();
  return !!(r && r.tests && r.tests.find((el) => el.name === 'required'));
};

import { methods } from '../utils';
import { authenticatedMutation, authenticatedQuery, authenticatedService } from './middleware';
const { PUT, POST, DELETE } = methods;

const routes = {
  upsert: process.env.REACT_APP_BASE_API_URL + '/V2/templates',
  duplicateTemplate: (id) => process.env.REACT_APP_BASE_API_URL + `/V2/templates/${id}/duplicate`,
  template: (id) => process.env.REACT_APP_BASE_API_URL + `/V2/templates/${id}`,
};

const queries = {
  getAll: () => `
    query GetAllTemplates($lngId: Int = 1) {
      templates {
        id
        templates_translations(where: {language_id: {_eq: $lngId}}) {
          id
          language_id
          title
          description
        }
        type_id
        speciality_id
        is_published
        created_at
      }
    }
  `,
  getOne: () => `
    query GetOneTemplates($id: Int!, $lngId: Int = 1) {
      templates(where: {id: {_eq: $id}}) {
        id
        templates_translations(where: {language_id: {_eq: $lngId}}) {
          id
          language_id
          title
          description
          consent_pdf_template
          survey_model_files_id
        }
        type_id
        speciality_id
        side
        radius
        is_published
        step_templates(order_by: {step: {number: asc}}) {
          step {
            id
            number
            steps_translations(where: {language_id: {_eq: $lngId}}) {
              id
              language_id
              title
            }
            questions(order_by: {number: asc}) {
              id
              number
              questions_translations(where: {language_id: {_eq: $lngId}}) {
                id
                language_id
                question
                desc
                data
                rewording
                texte_pdf
                incompris
              }
              type_id
            }
          }
        }
      }
    }
  `,
};

const mutations = {
  deleteSome: () => `
    mutation DeleteSome($ids: [Int!]!) {
      qua:delete_questions(where: {step: {medic_template_steps: {medic_template: {store_id: {_in: $ids}}}}}) {
        returning {
          id
        }
      }
      sta:delete_steps(where: {medic_template_steps: {medic_template: {store_id: {_in: $ids}}}}) {
        returning {
          id
        }
      }
      delete_medic_template_steps(where: {medic_template: {store_id: {_in: $ids}}}) {
        returning {
          medic_template_id
        }
      }
      delete_medic_templates(where: {store_id: {_in: $ids}}) {
        returning {
          id
        }
      }
      qub: delete_questions(where: {step: {step_templates: {template: {id: {_in: $ids}}}}}) {
        returning {
          id
        }
      }
      stb: delete_steps(where: {step_templates: {template: {id: {_in: $ids}}}}) {
        returning {
          id
        }
      }
      delete_step_templates(where: {template_id: {_in: $ids}}) {
        returning {
          template_id
        }
      }
      delete_templates(where: {id: {_in: $ids}}) {
        returning {
          id
        }
      }
    }
  `,
  updatePublishedStatus: () => `
    mutation UpdatePublishedStatus($ids: [Int!]!, $is_published: Boolean = false) {
      update_templates(where: {id: {_in: $ids}}, _set: {is_published: $is_published}) {
        returning {
          id
        }
      }
    }
  `,
};

export default {
  getAll: () => authenticatedQuery(queries.getAll()),
  getDetail: (id, lngId) => authenticatedQuery(queries.getOne(), {}, { id, lngId }),
  createTemplate: (template) => authenticatedService(PUT, routes.upsert, template, {}, {}),
  templateUpdateRequest: (template) => authenticatedService(PUT, routes.upsert, template, {}, {}),
  updatePublishedStatus: (ids, is_published) => authenticatedMutation(mutations.updatePublishedStatus(), {}, { ids, is_published }),
  delete: (id) => authenticatedService(DELETE, routes.template(id), {}, {}),
  deleteSome: (ids) => authenticatedMutation(mutations.deleteSome(), {}, { ids }),
  upsertTemplate: (payload) => authenticatedService(POST, routes.upsert, payload, {}, {}),
  duplicateTemplate: (id) => authenticatedService(POST, routes.duplicateTemplate(id), {}, {}),
};

import { encode } from 'base-64';
import _ from 'lodash';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { AuthenticationService, MedicsService, PatientService, UserService } from '../../services';
import { USER_ROLE } from '../../utils/calimed-enum';
import { validEmailRegex } from '../../utils/regex';
import { CommonActions } from '../common';
import { LoaderActions } from '../loader';
import { SnackActions } from '../snackBar';
import { default as AuthActions, types } from './actions';

function* login({ username, password }) {
  yield put(LoaderActions.loading());

  const [error, response] = yield call(AuthenticationService.login, { username, password });
  if (response && response.request.responseURL) {
    const url = new URL(response.request.responseURL);
    const urlParams = new URLSearchParams(url.search);
    const code = urlParams.get('code');
    // eslint-disable-next-line no-unused-vars
    const [_tokenError, tokenResponse] = yield call(AuthenticationService.getToken, {
      username,
      password,
      code,
    });
    if (tokenResponse && tokenResponse.data.access_token) {
      const token = tokenResponse.data.access_token;
      const token_type = tokenResponse.data.token_type;
      const refresh_token = tokenResponse.data.refresh_token;

      const authorization = encode(username + ':' + password);
      yield put(AuthActions.loginSuccess(token_type, token, refresh_token, authorization));
    }
  }
  yield put(LoaderActions.loaded());
  /*
  {error: "invalid_credentials", message: "The user credentials were incorrect."}
  */
  if (error) {
    yield put(AuthActions.loginFailure());
    yield put(SnackActions.displayError('authentication_failed'));
    return;
  }
}

/**
 * Warning: This code is not used. The current refresh action is in middleware
 * @returns {Generator<*, string[]|*[], ?>}
 */
function* refresh() {
  const { refresh_token, authorization } = yield select((state) => state.auth);
  const [tokenError, tokenResponse] = yield call(AuthenticationService.refreshToken, {
    authorization,
    refresh_token,
  });
  if (tokenResponse && tokenResponse.data) {
    const { access_token: token, token_type, refresh_token: newRefreshToken } = tokenResponse.data;
    yield put(AuthActions.loginSuccess(token_type, token, newRefreshToken, authorization));
    return [null, `${token_type} ${token}`];
  } else return [tokenError, null];
}

function* logout() {
  yield put(CommonActions.resetReducers());
}

function* updateUser({ userData, email, role }) {
  const id = _.get(userData, 'id');
  if (!id) {
    yield put(SnackActions.displayError('password_modify_error'));
    return;
  }

  const [updateError, updateResponse] = yield call(UserService.update, id, userData);

  if (updateResponse && !updateError) {
    yield put(SnackActions.displayInfo('password_modify_success'));
    yield put(LoaderActions.loaded());
  } else {
    yield put(SnackActions.displayError('password_modify_error'));
    return;
  }

  // send email to patient with password
  if (email && validEmailRegex.test(email) && userData.password) {
    const mailData = {
      template: 'basic-mail',
      value: {
        email: `${email}`,
        code: `${userData.password}`,
      },
    };
    const [error, response] =
      role === USER_ROLE.PATIENT ? yield call(PatientService.produceMail, mailData) : yield call(MedicsService.produceMail, mailData);
    if (response && !error) {
      yield put(SnackActions.displayInfo('email_sent_success'));
    } else {
      yield put(SnackActions.displayError('email_sent_error'));
    }
  }
}

export function* renewToken() {
  const [tokenError, tokenResponse] = yield call(AuthenticationService.renewToken);
  if (!tokenError && tokenResponse && tokenResponse.data) {
    const { value: token, refresh_token } = tokenResponse.data;
    yield put(AuthActions.renewTokenSuccess(token, refresh_token));
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  takeLatest(types.LOGIN_REQUEST, login),
  takeLatest(types.LOGIN_REFRESH, refresh),
  takeLatest(types.LOGOUT, logout),

  takeLatest(types.UPDATE_USER, updateUser),
  takeLatest(types.RENEW_TOKEN, renewToken),
];

import { connect } from 'react-redux';

import { LanguageActions } from '../../../redux/languages';
import Language from './Language';

const mapStateToProps = ({ language }) => ({
  languageList: Object.values(language.list || {}),
  loading: language.loading,
});

const mapDispatchToProps = (dispatch) => ({
  languageDelete: (ids) => dispatch(LanguageActions.delete(ids)),
  fetchAllLanguages: () => dispatch(LanguageActions.fetchAll()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Language);

import './style.css';

import { Button } from 'antd';
import React, { useEffect, useState } from 'react';

import checkBrowser from '../../checkBrowser';

const ModalButtons = ({ firstText, secondText, onFirstClick, onSecondClick, hideSecond, htmlType = 'submit' }) => {
  const [style, setStyle] = useState('ec-modal-buttons-container');
  const [safari, setSafari] = useState(false);
  const nav = checkBrowser();

  useEffect(() => {
    switch (nav) {
      case 'Mozilla Firefox':
        setStyle('ec-modal-buttons-firefox-container');
        setSafari(false);
        break;
      case 'Apple Safari':
        setSafari(true);
        break;
      case 'Google Chrome':
        setStyle('ec-modal-buttons-container');
        setSafari(false);
        break;
      default:
        setStyle('ec-modal-buttons-container');
    }
  }, [nav]);

  const handleOnFirstClick = (e) => {
    if (onFirstClick) onFirstClick(e);
  };
  const handleOnSecondClick = (e) => {
    if (onSecondClick) onSecondClick(e);
  };
  return (
    <div className={style}>
      <Button color="black" htmlType={htmlType} style={{ width: '20%', marginRight: 10 }} onClick={handleOnFirstClick}>
        {firstText}
      </Button>
      {!hideSecond && (
        <Button type="primary" htmlType={htmlType} onClick={handleOnSecondClick} style={{ width: '20%' }}>
          {secondText}
        </Button>
      )}
    </div>
  );
};

export default ModalButtons;

import { connect } from 'react-redux';

import { SurveyTypesActions } from '../../../redux/surveyTypes';
import SurveyTypes from './SurveyTypes';

const mapStateToProps = ({ auth, surveyTypes }) => ({
  list: surveyTypes.list || {},
  loading: surveyTypes.loading,
  userLanguageId: auth.languageId,
});

const mapDispatchToProps = (dispatch) => ({
  surveyTypesRequest: (filter) => dispatch(SurveyTypesActions.surveyTypesRequest(filter)),
  surveyTypesDeleteRequest: (surveyType) => dispatch(SurveyTypesActions.surveyTypesDeleteRequest(surveyType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SurveyTypes);

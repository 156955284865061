import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CONTENT_TYPE_MAPPER, REVERS_CONTENT_TYPE_MAPPER } from '../../../../../utils/calimed-enum';
import { ModalButton } from '../../../../components/button';
import PDFViewer from '../../../../components/PDFViewer';
import { ACTIONS } from '../PDF/PDF';

const SurveyDocumentViewer = ({
  survey,
  action,
  token,
  onClearAction,
  canCancel,
  showBtn = true,
  hideSecond,
  hidePreview,
  firstText,
  onLastClick,
  firstButtonStyle,
  secondText,
  ioid,
  scale = 0.8,
  onFileLoaded,
  hidden,
  forceDocument,
  isFileRemoved,
}) => {
  const { t } = useTranslation();
  const [filename, setFilename] = useState();
  const [blob, setBlob] = useState();
  const [url, setUrl] = useState();
  const [pdf, setPdf] = useState();
  const [image, setImage] = useState();
  const [fileType, setFileType] = useState();

  useEffect(() => {
    if (forceDocument && forceDocument.file) {
      setFileType(forceDocument?.file?.type || null);
    }
  }, [forceDocument]);

  useEffect(() => {
    const fetchData = async (u) => {
      try {
        const res = await axios.get(u, { headers: { Authorization: `Bearer ${token}` }, responseType: 'blob' });
        const newFileType = res.headers['content-type'];
        setFileType(res.headers['content-type']);
        setFilename(`${filename}.${REVERS_CONTENT_TYPE_MAPPER[res.headers['content-type']]}`);
        setBlob(res.data);
        if (newFileType === CONTENT_TYPE_MAPPER.PDF) {
          setPdf(res.data);
          setImage();
        } else {
          let objectUrl = window.URL.createObjectURL(res.data);
          setImage(objectUrl);
          setPdf();
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('fetchData err :>> ', err);
      }
    };

    url && fetchData(url);
    onFileLoaded && onFileLoaded();
  }, [url]);

  useEffect(() => {
    let newFilename;
    let newUrl;

    if (!forceDocument && survey) {
      const prefixUrl = `${process.env.REACT_APP_SIGN_API_URL}/${survey.__typename}/${survey.id}`;
      switch (action) {
        case ACTIONS.BUILD_PDF:
          newFilename = `${survey.title}_consent`;
          newUrl = `${prefixUrl}/consentPdf`;
          break;
        case ACTIONS.BUILD_DRAW:
          newFilename = `${survey.title}_draw`;
          newUrl = `${prefixUrl}/drawing/${survey.survey_drawing_id}`;
          break;
        case ACTIONS.BUILD_ATTACHMENT:
          newFilename = `${survey.title}_attachment`;
          newUrl = `${prefixUrl}/attachment/${survey.survey_attachment_id}`;
          break;
        case ACTIONS.QUESTION_PDF:
          if (ioid && ioid > 0) {
            newFilename = `${survey.title}_ioid`;
            newUrl = `${prefixUrl}/ioid/${ioid}`;
          }
          break;
        default:
      }
    }
    if (newFilename !== filename) {
      setFilename(newFilename);
    }
    if (newUrl !== url) {
      setUrl(newUrl);
    }
  }, [action, survey, ioid]);

  const handleDownload = (e) => {
    e.preventDefault();
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(new Blob([blob], { type: fileType }));
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    setTimeout(function () {
      window.URL.revokeObjectURL(link);
    }, 200);
  };

  const handleClear = (e) => {
    e.preventDefault();
    setPdf();
    setImage();
    if (onClearAction) onClearAction();
  };

  return (
    <div className="w-100">
      {hidePreview ? null : fileType === CONTENT_TYPE_MAPPER.PDF && (forceDocument || pdf) ? (
        <PDFViewer scale={scale} documentData={!isFileRemoved && (forceDocument || { file: pdf })} onLastClick={onLastClick} hidden={hidden} />
      ) : image ? (
        <img src={image} alt="draw" style={{ width: '100%' }} />
      ) : null}
      {showBtn && (
        <div>
          <ModalButton
            firstText={firstText || t('download')}
            onFirstClick={handleDownload}
            hideSecond={!canCancel || hideSecond}
            secondText={canCancel && (secondText || t('return'))}
            onSecondClick={canCancel && handleClear}
            firstButtonStyle={firstButtonStyle}
          />
        </div>
      )}
    </div>
  );
};

SurveyDocumentViewer.propTypes = {
  survey: PropTypes.object,
  action: PropTypes.string,
  onClearAction: PropTypes.func,
};

export default SurveyDocumentViewer;

import PropTypes from 'prop-types';
import React from 'react';

function DynamicScale({ decreaseScale, increaseScale, scaleLabel }) {
  return (
    <div className="d-flex justify-content-around align-items-center ">
      <button onClick={decreaseScale} type="button">
        -
      </button>
      <span>Scale: {scaleLabel}</span>
      <button onClick={increaseScale} type="button">
        +
      </button>
    </div>
  );
}

DynamicScale.propTypes = {
  decreaseScale: PropTypes.func.isRequired,
  increaseScale: PropTypes.func.isRequired,
  scaleLabel: PropTypes.string.isRequired,
};

export default DynamicScale;

import { DeleteOutlined, DownOutlined, PlusOutlined, RightOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, Divider, Form, Input, Row, Select, Upload } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import { CONTENT_TYPE_MAPPER } from '../../../utils/calimed-enum';
import { DISPLAYED_IF_MAPPER } from '../../../utils/dataMappers';
import { getCircularReplacer } from '../../screens/SurveysCreateV2/CreateForm/CreateForm';
import PDFViewer from '../PDFViewer';
import { SurveyTypesFeeder } from './feed';
import SurveyTypesKeys from './SurveyTypesKeys';
import { TEMPLATES } from './surveyTypesPaneConfig';
import SurveyTypesTabs from './SurveyTypesTabs';
const { Option } = Select;

const { TextArea } = Input;
const GRID = 8;

const SurveyTypesForm = ({ onValidate, cronConfig, initialValues = {}, selectedLanguageId }) => {
  const { t } = useTranslation();
  const [languageId, setLanguageId] = useState();
  const [isCollapsed, setIsCollapsed] = useState({
    introduction_questions_after: {},
    introduction_questions_before: {},
  });
  const [activeTab, setActiveTab] = useState('0');
  const [isToggle, setIsToggle] = useState({});

  const [form] = Form.useForm();

  useEffect(() => {
    if (initialValues) {
      form.resetFields();
    }
  }, [initialValues]);

  useEffect(() => {
    if (languageId !== selectedLanguageId) {
      form.resetFields();
      setLanguageId(selectedLanguageId);
    }
  }, [selectedLanguageId]);

  const onFinish = (values) => {
    onValidate({
      ...values,
      event_alerts: (values?.event_alerts || []).map((event) => ({
        ...event,
        template_filename: TEMPLATES[event.template_filename - 1],
      })),
    });
  };

  const onFinishFailed = ({ errorFields }) => {
    if (errorFields && errorFields[0]) {
      const name = _.get(errorFields[0], 'name', []);
      if (name[0] && name[0].includes('cron_')) {
        const currentTabIndex = name[0].split('_')[1];
        if (currentTabIndex.length) setActiveTab(currentTabIndex);
      }
    }
  };

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'lightblue' : 'lightgrey',
    padding: GRID,
    // width: 250,
  });

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: GRID * 2,
    margin: `0 0 ${GRID}px 0`,
    paddingLeft: '20px',
    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'lightgrey',

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const handleCollapsed = (event, key, arrayKey) => {
    event.preventDefault();
    const newCollapsed = _.get(isCollapsed, arrayKey);
    _.set(newCollapsed, key, !_.get(newCollapsed, key) || false);
    setIsCollapsed({
      ...isCollapsed,
      [arrayKey]: newCollapsed,
    });
  };

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  /**
   * Moves an item from one list to another list.
   */
  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  };

  const handleDragEnd = (result) => {
    const { source, destination } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(form.getFieldValue(source.droppableId), source.index, destination.index);
      form.setFieldsValue({ [source.droppableId]: items });
    } else {
      const res = move(form.getFieldValue(source.droppableId), form.getFieldValue(destination.droppableId), source, destination);

      form.setFieldsValue({
        [destination.droppableId]: res[destination.droppableId].map((el) => ({
          ...el,
          is_after: source.destination === 'introduction_questions_after',
        })),
        [source.droppableId]: res[source.droppableId].map((el) => ({ ...el, is_after: source.droppableId === 'introduction_questions_after' })),
      });
    }
  };

  const handleRemovePdf = (event, key, path) => {
    event.preventDefault();
    const values = form.getFieldValue(key);
    _.set(values, [...path, 'pdf_file'], null);
    _.set(values, [...path, 'pdf_url'], null);
    _.set(values, [...path, 'pdf_checkbox_label'], null);
    _.set(values, [...path, 'loid'], null);
    form.setFieldsValue({ [key]: values });
  };

  const handleResetCheckboxLabel = (event, key, path) => {
    event.preventDefault();
    const values = form.getFieldValue(key);
    _.set(values, [...path, 'pdf_checkbox_label'], "J'ai compris [la convention de preuve](%pdf_url%)");
    form.setFieldsValue({ [key]: values });
  };

  const handleSelectTemplate = (e, index, toUpdate) => {
    let value;
    if (e && e.target) {
      ({ value } = e.target);
    } else {
      value = e;
    }
    const values = form.getFieldValue(toUpdate);
    _.set(values, [index], {
      ..._.get(values, index),
      ...SurveyTypesFeeder[value],
    });
    form.setFieldsValue({ [toUpdate]: values });
  };

  const handleToggle = (e, value) => {
    e.preventDefault();
    setIsToggle({ ...isToggle, [value]: !_.get(isToggle, value, false) });
  };

  const uploadProps = {
    maxCount: 1,
    accept: CONTENT_TYPE_MAPPER.PDF,
    beforeUpload: (file) => {
      const isGoodFileType = file.type === CONTENT_TYPE_MAPPER.PDF;
      if (!isGoodFileType) {
        // eslint-disable-next-line no-console
        console.error(`${file.name} is not a pdf file`);
      }
      return isGoodFileType || Upload.LIST_IGNORE;
    },
  };

  const getFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <Card style={{ margin: 5 }}>
      <div className="d-flex flex-row">
        <div className="w-25 mx-2">
          <SurveyTypesKeys />
        </div>
        <div className="p-2  w-75">
          <Form onFinish={onFinish} onFinishFailed={onFinishFailed} initialValues={selectedLanguageId && initialValues} layout="vertical" form={form}>
            <Form.Item name={['translation', 'id']} label="id" hidden={true}>
              <Input placeholder="id" />
            </Form.Item>
            <Form.Item name={['translation', 'language_id']} label="language_id" hidden={true}>
              <Input placeholder="language_id" maxLength={50} />
            </Form.Item>
            <Form.Item name={['translation', 'title']} label="Type" rules={[{ required: true, message: 'Catégorie Incorrecte' }]}>
              <Input placeholder="Type" maxLength={50} />
            </Form.Item>
            <Form.Item name={['translation', 'label']} label="Label seul">
              <Input placeholder="Label seul" maxLength={50} />
            </Form.Item>
            <Form.Item name={['translation', 'label_a']} label="Label (prefix un, l' une, etc...)">
              <Input placeholder="Label (prefix un, l' une, etc...)" maxLength={50} />
            </Form.Item>
            <Form.Item name={['translation', 'label_your']} label="Label (prefix votre, vos, etc...)">
              <Input placeholder="Label (prefix votre, vos, etc...)" maxLength={50} />
            </Form.Item>
            <Form.Item name={'is_clinical_study'} valuePropName="checked">
              <Checkbox>Si la case est cochée, ce type est pour les études cliniques</Checkbox>
            </Form.Item>
            <Form.Item name="can_withdraw" valuePropName="checked">
              <Checkbox>Retrait du consentement</Checkbox>
            </Form.Item>
            <Form.Item name="is_ip_displayed" valuePropName="checked">
              <Checkbox>Si la case est cochée, l'IP sur le consentement sera présent</Checkbox>
            </Form.Item>
            <Form.Item name="should_countersign" valuePropName="checked">
              <Checkbox>Si la case est cochée, une contre signature est nécessaire</Checkbox>
            </Form.Item>
            <Form.Item name={'edit_reason_on_refusal'} valuePropName="checked">
              <Checkbox>Si la case est cochée, le patient devra éditer la raison de son refus</Checkbox>
            </Form.Item>
            <DragDropContext onDragEnd={handleDragEnd}>
              {[
                {
                  array_title: 'Questions préliminaires',
                  array_key: 'introduction_questions_before',
                },
                {
                  array_title: 'Questions de conclusions',
                  array_key: 'introduction_questions_after',
                },
              ].map(({ array_key, array_title }) => (
                <div key={array_key}>
                  <Divider type="horizontal" onClick={(e) => handleToggle(e, array_key)}>
                    {_.get(isToggle, array_key, false) ? <RightOutlined /> : <DownOutlined />} {array_title}
                  </Divider>
                  <div className={`${_.get(isToggle, array_key, false) ? 'd-none' : ''} `}>
                    <Droppable droppableId={array_key}>
                      {(provided, snapshot) => (
                        <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                          <Form.List name={array_key.split('.')}>
                            {(fields, { add, remove }) => (
                              <>
                                {fields.map((field, index) => {
                                  const fieldId = form.getFieldValue([array_key, index, 'id']);
                                  const fieldUrl = form.getFieldValue([array_key, index, 'translation', 'pdf_url']);
                                  const fieldPdf = form.getFieldValue([array_key, index, 'translation', 'pdf_file']);
                                  const fieldLoid = form.getFieldValue([array_key, index, 'translation', 'loid']);
                                  const pdfUrl =
                                    fieldLoid && `${process.env.REACT_APP_SIGN_API_URL}/file/type/introductionQuestions/oids/${fieldLoid}`;
                                  return (
                                    <Draggable key={`${array_key}${field.key}${index}`} draggableId={`${array_key}${field.key}`} index={index}>
                                      {(provided2, snapshot2) => (
                                        <div
                                          ref={provided2.innerRef}
                                          {...provided2.draggableProps}
                                          {...provided2.dragHandleProps}
                                          style={getItemStyle(snapshot2.isDragging, provided2.draggableProps.style)}
                                        >
                                          <Divider type="horizontal" onClick={(e) => handleToggle(e, `${array_key}_${index}`)}>
                                            {form.getFieldValue([array_key, index, 'title']) || `Question ${index + 1}`}
                                          </Divider>
                                          <div className={`${_.get(isToggle, `${array_key}_${index}`, false) ? 'd-none' : ''} `}>
                                            <Select
                                              defaultValue="none"
                                              style={{ width: '100%' }}
                                              onChange={(e) => handleSelectTemplate(e, index, array_key)}
                                            >
                                              <Option value="none" disabled>
                                                None
                                              </Option>
                                              {Object.keys(SurveyTypesFeeder).map((el) => (
                                                <Option value={el} key={el}>
                                                  {el}
                                                </Option>
                                              ))}
                                            </Select>
                                            <Row justify="space-around">
                                              <Col flex="20px">
                                                <Button
                                                  type="primary"
                                                  icon={_.get(isCollapsed, [array_key, index]) ? <RightOutlined /> : <DownOutlined />}
                                                  onClick={(event) => handleCollapsed(event, field.key, array_key)}
                                                  htmlType="button"
                                                />
                                              </Col>
                                              <Col flex="auto">
                                                <Form.Item name={[index, 'translation', 'title']}>
                                                  <Input placeholder="Titre" />
                                                </Form.Item>
                                              </Col>
                                              {fields.length > 1 ? (
                                                <Col flex="20px">
                                                  <Button
                                                    type="danger"
                                                    className="dynamic-delete-button"
                                                    onClick={() => remove(field.name)}
                                                    icon={<DeleteOutlined />}
                                                    htmlType="button"
                                                  />
                                                </Col>
                                              ) : null}
                                            </Row>
                                            <Form.Item name={[index, 'displayed_if']} label="Afficher si">
                                              <Select style={{ width: '100%' }}>
                                                <Option value={null} disabled>
                                                  Afficher la question si condition
                                                </Option>
                                                {Object.values(DISPLAYED_IF_MAPPER).map((el) => (
                                                  <Option value={el.value} key={el.value}>
                                                    {el.label}
                                                  </Option>
                                                ))}
                                              </Select>
                                            </Form.Item>
                                            <div className={`${_.get(isCollapsed, [array_key, index]) ? 'd-none' : ''}`}>
                                              <Form.Item
                                                name={[index, 'translation', 'id']}
                                                label="id"
                                                // hidden={true}
                                              >
                                                <Input placeholder="id" readOnly />
                                              </Form.Item>
                                              <Form.Item
                                                name={[index, 'translation', 'language_id']}
                                                label="language_id"
                                                // hidden={true}
                                              >
                                                <Input placeholder="language_id" readOnly />
                                              </Form.Item>
                                              <Form.Item
                                                name={[index, 'translation', 'value']}
                                                label="Texte"
                                                rules={[{ required: true, message: 'Texte Obligatoire' }]}
                                              >
                                                <TextArea placeholder="Texte" />
                                              </Form.Item>
                                              <Form.Item name={[index, 'translation', 'text_success']} label="Texte succès">
                                                <TextArea placeholder="Texte succès" />
                                              </Form.Item>
                                              <Form.Item name={[index, 'translation', 'text_error']} label="Texte erreur">
                                                <TextArea placeholder="Texte erreur" />
                                              </Form.Item>
                                              <Form.Item name={[index, 'translation', 'first_button_label']} label="Texte bouton refuser">
                                                <Input placeholder="Texte case à cocher pdf" />
                                              </Form.Item>
                                              <Form.Item
                                                name={[index, 'translation', 'second_button_label']}
                                                label="Texte bouton valider"
                                                rules={[{ required: true, message: 'Texte Obligatoire' }]}
                                              >
                                                <Input placeholder="Texte case à cocher pdf" />
                                              </Form.Item>
                                              <Form.Item name={[index, 'translation', 'pdf_checkbox_label']} label="Texte case à cocher pdf">
                                                <Input
                                                  placeholder="Texte case à cocher pdf"
                                                  disabled={!((fieldPdf && fieldPdf !== 'deleted') || fieldUrl || fieldLoid)}
                                                />
                                              </Form.Item>
                                              <Form.Item name={[index, 'translation', 'loid']} label="loid" hidden={true}>
                                                <Input placeholder="loid" />
                                              </Form.Item>

                                              <Form.Item name={[index, 'translation', 'pdf_file']} label="Joindre un PDF" getValueFromEvent={getFile}>
                                                <Upload {...uploadProps}>
                                                  <Button icon={<UploadOutlined />} htmlType="button">
                                                    Click to Upload
                                                  </Button>
                                                </Upload>
                                              </Form.Item>
                                              <Button
                                                type="default"
                                                onClick={(e) => handleResetCheckboxLabel(e, array_key, [index, 'translation'])}
                                                style={{ marginTop: 16 }}
                                                disabled={!((fieldPdf && fieldPdf !== 'deleted') || fieldUrl || fieldLoid)}
                                                htmlType="button"
                                              >
                                                Réinitialiser Case à cocher
                                              </Button>
                                              {fieldId && (
                                                <div>
                                                  <Divider type="horizontal">Joindre un PDF</Divider>
                                                  <PDFViewer
                                                    documentData={_.omitBy(
                                                      {
                                                        url: fieldUrl || pdfUrl || null,
                                                        file: (fieldPdf && fieldPdf !== 'deleted' && fieldPdf) || null,
                                                      },
                                                      _.isNil,
                                                    )}
                                                  />
                                                  <Button
                                                    type="danger"
                                                    onClick={(e) => handleRemovePdf(e, array_key, [index, 'translation'])}
                                                    style={{ marginTop: 16 }}
                                                    htmlType="button"
                                                  >
                                                    Supprimer
                                                  </Button>
                                                  <Divider type="horizontal" />
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                })}
                                <Divider />
                                <Form.Item>
                                  <Button type="dashed" onClick={() => add()} style={{ width: '60%' }} htmlType="button">
                                    <PlusOutlined /> Ajouter une question
                                  </Button>
                                </Form.Item>
                              </>
                            )}
                          </Form.List>
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </div>
                </div>
              ))}
            </DragDropContext>
            <Divider type="horizontal" />
            <Divider type="horizontal" onClick={(e) => handleToggle(e, 'cron')}>
              {_.get(isToggle, 'cron', false) ? <RightOutlined /> : <DownOutlined />} Gestion des rappels
            </Divider>
            <div className={`${_.get(isToggle, 'event_alerts', false) ? 'd-none' : ''} `}>
              <SurveyTypesTabs formMethods={form} onTabClick={setActiveTab} config={cronConfig} name={'event_alerts'} />
            </div>
            <Divider type="horizontal" />
            <Form.Item style={{ textAlign: 'center' }}>
              <Button type="primary" htmlType="submit" style={{ width: '55%' }}>
                {t('save')}
              </Button>
            </Form.Item>
          </Form>
          {!!process.env.REACT_APP_DEBUG_FORM && <pre>{JSON.stringify(form.getFieldsValue(true), getCircularReplacer(), 2)}</pre>}
        </div>
      </div>
    </Card>
  );
};

export default SurveyTypesForm;

import './sms.css';

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { filterAndOrderList } from '../../../utils/utils';
import Filter from '../../components/filter';
import DataTable from './DataTable';

const SMS_FILTER_KEYS = ['name'];

const Sms = ({ smsRequest, list, loading }) => {
  const navigate = useNavigate();
  const [filtered, setFiltered] = useState();
  const [reset, setReset] = useState(false);

  useEffect(() => {
    smsRequest({});
  }, []);

  const sms = Object.values(list);

  const onFilter = (result) => {
    if (!result) setFiltered(null);
    else {
      setFiltered(result.map((s) => s.item.id));
      setReset(!reset);
    }
  };

  const onModify = (sms) => {
    navigate(`/sms/${sms.id}`, sms);
  };

  return (
    <>
      <Filter list={sms} onFilter={onFilter} keys={SMS_FILTER_KEYS} />
      <DataTable items={filterAndOrderList(sms, filtered)} reset={reset} loading={loading} itemClick={onModify} onModify={onModify} />
    </>
  );
};

export default Sms;

/**
 * GRAPHQL QUERIES
 */

export default {
  getConfig: `
    query getConfig {
      event_date_refs {
        id
        event_date_path
      }
      communication_modes {
        id
        mode
      }
      recipient_event_refs {
        id
        event_recipient_path
      }
      offset_event_alerts {
        id
        offset
      }
    }
  `,
  getOffsets: `
    query getOffsets {
      offset_event_alerts {
        id
        offset
      }
    }
  `,
};

import { connect } from 'react-redux';

import { ClinicsGroupsActions } from '../../../redux/clinicsGroups';
import ClinicsGroups from './ClinicsGroups';

const mapStateToProps = ({ clinicsGroups, auth }) => {
  return {
    clinicsGroups: Object.values(clinicsGroups.list) || {},
    loading: clinicsGroups.loading,
    authorizationToken: `${auth.token_type} ${auth.token}`,
  };
};

const mapDispatchToProps = (dispatch) => ({
  clinicsGroupsRequest: (filter) => dispatch(ClinicsGroupsActions.clinicsGroupsRequest(filter)),
  clinicsGroupDeleteRequest: (clinicsGroupId) => dispatch(ClinicsGroupsActions.clinicsGroupDeleteRequest(clinicsGroupId)),
  clinicsGroupsDeleteMultipleRequest: (listIds) => dispatch(ClinicsGroupsActions.clinicsGroupsDeleteRequest(listIds)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClinicsGroups);

import { Popover, Space, Tag } from 'antd';
import React from 'react';

export default function PatientTags({ type }) {
  return (
    ['create-patient', 'migration-user-email-to-username'].includes(type) && (
      <Space size={[0, 8]} wrap>
        <Popover content={'Madame USE_NAME'}>
          <Tag color="magenta">patient.titledName</Tag>
        </Popover>
        <Popover content={'06 12 34 56 78'}>
          <Tag color="magenta">patient.local_phone_number</Tag>
        </Popover>
        <Popover content={'yqhvoUjMU6'}>
          <Tag color="magenta">patient.user.username</Tag>
        </Popover>
        <Popover content={'True si le patient est rattachés à une équipe clinical study'}>
          <Tag color="red">isClinicalStudy</Tag>
        </Popover>
        <Popover content={"url permettant d'arriver sur la page login avec le username du patient"}>
          <Tag color="orange">patientLoginUrl</Tag>
        </Popover>
      </Space>
    )
  );
}

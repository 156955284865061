import { Button, Card, Divider, Form, Input, Select } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import { getUserProperty } from '../../../utils';
import ReactSelect from '../ReactSelect/ReactSelect';

const DSC = 'descending';

const ClinicsForm = ({ onValidate, initialValues, medics, cityList, fetchCityList, clearCityList }) => {
  const [form] = Form.useForm();
  const [postcode, setPostcode] = useState();
  const isPostcodeInForm = form.getFieldValue('postcode');

  const onFinish = (values) => {
    onValidate(values);
  };

  useEffect(() => {
    if (initialValues) {
      form.resetFields();
    }
  }, [initialValues]);

  function sortByText(a, b, order = DSC) {
    const diff = _.deburr(a.lastname.toLowerCase()).localeCompare(_.deburr(b.lastname.toLowerCase()));
    if (order === DSC) {
      return diff;
    }
    return -1 * diff;
  }

  const handlePostcodeChange = (value) => {
    if (value !== postcode) {
      // if new postcode, clear city
      setPostcode(value);
      form.setFieldsValue({ city_id: null, city: null });
    }
    if (value.length === 5) {
      // fetch city list or clear
      fetchCityList && fetchCityList(value);
    } else {
      clearCityList && clearCityList();
    }
  };

  const handleSelectCity = (value) => {
    if (value !== postcode) {
      const city = cityList.find((c) => c.id === value);
      setPostcode(city.postcode);
      form.setFieldsValue({
        city: city.name,
        postcode: city.postcode,
      });
    }
  };

  const onValuesChange = (changedValues) => {
    if ('postcode' in changedValues) {
      handlePostcodeChange(changedValues['postcode']);
    }
    if ('city_id' in changedValues) {
      handleSelectCity(changedValues['city_id']);
    }
  };

  const handleFinishFailed = ({ _values, errorFields }) => {
    // eslint-disable-next-line no-console
    console.error('errorFields :>> ', errorFields);
  };

  return (
    <Card style={{ margin: 25, width: '80%' }}>
      <Form
        onFinish={onFinish}
        onFinishFailed={handleFinishFailed}
        initialValues={initialValues}
        layout="vertical"
        form={form}
        onValuesChange={onValuesChange}
        scrollToFirstError
      >
        <Form.Item name="name" label="Nom Etablisement" rules={[{ required: true, message: 'Nom Incorrect' }]}>
          <Input placeholder="Nom Etablisement" maxLength={70} />
        </Form.Item>
        <Form.Item
          name="finess"
          label="Finess"
          rules={[
            { required: false, message: 'Finess Obligatoire' },
            () => ({
              validator(_rule, value) {
                const regexp = /^[0-9a-zA-Z]*$/;
                if (!value || value.length === 0) {
                  return Promise.resolve();
                } else if (value.length !== 9) {
                  return Promise.reject('Le Code Finess doit être composé de 9 caractères');
                } else if (!regexp.test(value)) {
                  return Promise.reject("Le format n'est pas correct");
                } else {
                  return Promise.resolve();
                }
              },
            }),
          ]}
        >
          <Input placeholder="Code Finess" maxLength={9} minLength={9} />
        </Form.Item>
        <Form.Item name="address" label="Adresse" rules={[{ required: false, message: 'Adresse Incorrecte' }]}>
          <Input placeholder="Adresse" maxLength={120} />
        </Form.Item>
        <Form.Item
          name="postcode"
          label="Code postal"
          rules={[
            { required: true, message: 'Code Postal Obligatoire' },
            () => ({
              validator(_rule, value) {
                const regexp = /^\d*$/;
                if (!value || value.length === 0) {
                  return Promise.resolve();
                } else if (value.length !== 5) {
                  return Promise.reject('Le Code postal doit être composé de 5 chiffres');
                } else if (!regexp.test(value)) {
                  return Promise.reject("Le format n'est pas correct");
                } else {
                  return Promise.resolve();
                }
              },
            }),
          ]}
        >
          <Input placeholder="Code Postal" maxLength={5} />
        </Form.Item>
        <Form.Item
          name="city"
          label="Ville"
          rules={[{ required: true, message: 'Ville Obligatoire' }]}
          style={{
            display: !isPostcodeInForm || !cityList || (Array.isArray(cityList) && !cityList.length) ? 'block' : 'none',
          }}
        >
          <Input placeholder="Ville" maxLength={70} />
        </Form.Item>
        <Form.Item
          name="city_id"
          label="Ville"
          rules={[{ required: false, message: 'Ville Obligatoire' }]}
          style={{
            display: !isPostcodeInForm || !cityList || (Array.isArray(cityList) && !cityList.length) ? 'none' : 'block',
          }}
        >
          <Select placeholder="Ville">
            {cityList.map((el) => (
              <Select.Option value={el.id} key={el.id}>
                {el.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="medic_id" label="Qui sont les practiciens ?" rules={[{ required: false, message: 'Practiciens(s) non spécifié(s)' }]}>
          <ReactSelect
            placeholder="Qui sont les practiciens ?"
            isMulti
            closeMenuOnSelect={false}
            name="medic_id"
            isSearchable
            options={medics
              .sort((a, b) => sortByText(a, b, DSC))
              .map((m) => ({
                value: m && m.id,
                label: getUserProperty(m, 'fullContactName'),
              }))}
          />
        </Form.Item>
        <Divider type="horizontal" />
        <Form.Item style={{ textAlign: 'center' }}>
          <Button type="primary" htmlType="submit" style={{ width: '55%' }}>
            Enregistrer
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default ClinicsForm;

import './style.css';

import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import Select from 'react-select';

import reactSelectStyle from './reactSelectStyle';

const InputSelect = ({
  name,
  options,
  isMulti = false,
  placeholder,
  disabled,
  isClearable,
  changeTheme,
  customStyles,
  isLoading,
  components,
  className,
  onChange: onChangeProps,
  control,
  displayAsText,
  readOnly,
  ...extraInputProps
}) => {
  const selectRef = useRef(null);
  const methods = useFormContext(); // retrieve all hook methods
  const {
    field: { onChange, value, ref, ...field }, // field : { onChange, onBlur, value, name: fieldName, ref, ...field },
    // field : { onChange, onBlur, value, name: fieldName, ref, ...field },
    fieldState: { error }, // fieldState: { invalid, isTouched, isDirty, error },
    // formState: { errors, isDirty, isSubmitting, touched, submitCount },
  } = useController({
    name,
    control: control || methods.control,
  });

  useEffect(() => {
    if (Array.isArray(options) && options.length) {
      let newValue = value;
      if (!value || (_.isObject(value) && _.isEmpty(value))) {
        // if no value but one option => select the option
        if (options.length === 1) {
          newValue = isMulti ? options : options[0];
        }
      } else if ((_.isString(value) || _.isInteger(value)) && !isMulti) {
        // if value is string or number then this is a ref => select ref from options
        // only if not isMulti
        if (_.isInteger(value)) {
          newValue = options.find((o) => parseInt(o.value) === parseInt(value));
        }
        if (_.isString(value)) {
          newValue = options.find((o) => `${o.value}` === `${value}`);
        }
      } else if (value) {
        // else if we have a value, we use it
        if (!isMulti) {
          newValue = options.find((el) => `${el.value}` === `${_.get(value, 'value')}`);
        }
      }
      newValue = newValue || (isMulti ? [] : {});
      if (!_.isEqual(value, newValue)) {
        onChange(newValue);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, options, isMulti, ref]);

  const formatGroupLabel = (data) => {
    return (
      <div
        className={data.isDisabled ? 'ec-select-group_style_disabled' : 'ec-select-group_style'}
        onClick={() => {
          if (isMulti) {
            if (data.isDisabled === false) handleChange(data.options);
            selectRef.current.blur();
          }
        }}
        aria-hidden="true"
      >
        <span>{data.label}</span>
        <span className={data.isDisabled ? 'ec-group-badge-styles_disabled' : 'ec-group-badge-styles'}>{data.options.length}</span>
      </div>
    );
  };

  const handleChange = (newValue) => {
    if (onChangeProps) onChangeProps(newValue);
    onChange(newValue);
  };

  return (
    <div className={`${className || ''} reactSelect`}>
      {displayAsText && <div className="selected-text-disabled">{_.get(value, 'label')}</div>}
      <Select
        name={name}
        ref={(e) => {
          ref(e);
          selectRef.current = e; // you can still assign to ref
        }}
        value={value || {}}
        onChange={handleChange}
        // onBlur={onBlur}
        className={`${displayAsText ? 'd-none' : 'visible'}`}
        options={options}
        isMulti={isMulti}
        styles={reactSelectStyle(customStyles || {})}
        formatGroupLabel={_.get(options, '0.options') ? formatGroupLabel : undefined}
        placeholder={placeholder || ''}
        isClearable={isClearable}
        theme={changeTheme}
        isOptionDisabled={(option) => option.disabled || option.isDisabled}
        noOptionsMessage={() => 'Pas de choix disponible.'}
        closeMenuOnSelect={true}
        {...extraInputProps}
        isLoading={isLoading}
        components={components}
        aria-invalid={error ? 'true' : 'false'}
        menuPosition="fixed"
        menuPlacement="auto"
        readOnly={readOnly}
        isDisabled={readOnly || disabled || false}
        {...field}
        // search input
        isSearchable={true}
        // inputId={inputId}
        // inputValue={inputValue || ''}
      />
    </div>
  );
};

InputSelect.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
    }),
  ).isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
  extraInputProps: PropTypes.object,
  isMulti: PropTypes.bool,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  isClearable: PropTypes.bool,
  changeTheme: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      borderRadius: PropTypes.number.isRequired,
      colors: PropTypes.object.isRequired,
      spacing: PropTypes.shape({
        baseUnit: PropTypes.number.isRequired,
        controlHeight: PropTypes.number.isRequired,
        menuGutter: PropTypes.number.isRequired,
      }).isRequired,
    }),
    PropTypes.shape({
      borderRadius: PropTypes.number.isRequired,
      colors: PropTypes.object.isRequired,
      spacing: PropTypes.shape({
        baseUnit: PropTypes.number.isRequired,
        controlHeight: PropTypes.number.isRequired,
        menuGutter: PropTypes.number.isRequired,
      }).isRequired,
    }),
    PropTypes.shape({
      borderRadius: PropTypes.number.isRequired,
      colors: PropTypes.object.isRequired,
      spacing: PropTypes.shape({
        baseUnit: PropTypes.number.isRequired,
        controlHeight: PropTypes.number.isRequired,
        menuGutter: PropTypes.number.isRequired,
      }),
    }),
    PropTypes.shape({
      borderRadius: PropTypes.number.isRequired,
      colors: PropTypes.object.isRequired,
      spacing: PropTypes.shape({
        baseUnit: PropTypes.number.isRequired,
        controlHeight: PropTypes.number.isRequired,
        menuGutter: PropTypes.number.isRequired,
      }),
    }),
  ]),
};

export default InputSelect;

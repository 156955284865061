import _ from 'lodash';

import { authenticatedMutation, authenticatedQuery } from './middleware';
/**
 * GRAPHQL QUERIES
 */
const queries = {
  questions: (id) => `
    query {
      questions(where: {step: {survey: {id: {_eq: ${id}}}}}) {
        number
        desc
        question
        data
        id
        step_id
        type_id
        question_type {
          id
          value
        }
      }
    }
  `,
};
/**
 * GRAPHQL MUTATION
 *
 */
const mutations = {
  questionsCreate: () => `
    mutation QuestionsCreate($questions: [questions_insert_input!]!) {
      insert_questions(objects: $questions) {
        returning {
          data
          created_at
          id
          desc
          number
          question
          type_id
          step_id
          question_type {
            id
            value
          }
        }
      }
    }
  `,
  questionsUpdate: () => `
    mutation QuestionsUpdate($id: Int!, $question: questions_set_input!) {
      update_questions(
        where: {id: {_eq: $id}}, 
        _set: $question
      ) {
        returning {
          data
          id
          desc
          number
          question
          type_id
          updated_at
          step_id
          question_type {
            id
            value
          }
        }
      }
    }
  `,
  questionsDelete: () => `
    mutation QuestionsDelete($id: Int!) {
      delete_questions(where: {id: {_eq: $id}}) {
        returning {
          id
        }
      }
    }
  `,
};
export default {
  questions: (id) => authenticatedQuery(queries.questions(id)),
  questionsCreate: (questions) => authenticatedMutation(mutations.questionsCreate(), {}, { questions }),
  questionsUpdate: (question) => authenticatedMutation(mutations.questionsUpdate(), {}, { id: question.id, question: _.omit(question, 'id') }),
  questionsDelete: (id) => authenticatedMutation(mutations.questionsDelete(), {}, { id }),
};

import { call, put, takeLatest } from 'redux-saga/effects';

import { ConsentsService } from '../../services';
import { SnackActions } from '../snackBar';
import { default as ConsentActions, types } from './actions';

function* consentsRequest({ filters }) {
  const id = (filters && filters.id) || null;
  const [error, response] = id ? yield call(ConsentsService.consent, id) : yield call(ConsentsService.consents);
  if (error) {
    yield put(SnackActions.displayError('consent_list_error'));
    yield put(ConsentActions.consentsFailure());
    return;
  }
  const { surveys } = response || {};
  if (surveys) yield put(ConsentActions.consentsSuccess(surveys));
  else {
    yield put(SnackActions.displayError('consent_list_error'));
    yield put(ConsentActions.consentsFailure());
  }
}

function* resumeSurvey({ id }) {
  const [error, response] = yield call(ConsentsService.resumeSurvey, id);

  if (error && !response) {
    yield put(SnackActions.displayError('consent_resume_error'));
  } else {
    yield put(SnackActions.displayInfo('consent_resume_success'));
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [takeLatest(types.CONSENTS_REQUEST, consentsRequest), takeLatest(types.RESUME_SURVEY, resumeSurvey)];

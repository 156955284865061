import _ from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import AppKeysForm from './AppKeysForm';

const AppKeysCreate = ({
  token,
  list,
  clinicsRequest,
  medicsRequest,
  medicOptions,
  clinicOptions,
  medicalTeamOptions,
  appKeysRequest,
  appKeysCreateRequest,
  appKeysUpdateRequest,
  medicalTeamsRequest,
  rolesRequest,
  rolesOptions,
  templateRequest,
  specialitiesRequest,
  specialitiesOptions,
  surveyTypesRequest,
  surveyTypesOptions,
  fetchAllLanguages,
  languageOptions,
  appKeyRoleOptions,
}) => {
  const { id } = useParams();

  useEffect(() => {
    if (id && !['appKeys', 'add'].includes(id) && appKeysRequest) appKeysRequest({ id });
  }, [id]);

  const initialValues = useMemo(() => {
    return (list && list[id]) || {};
  }, [id, list]);

  useEffect(() => {
    clinicsRequest();
    medicsRequest();
    medicalTeamsRequest();
    rolesRequest();
    specialitiesRequest();
    surveyTypesRequest();
    fetchAllLanguages();
  }, []);

  const handleValidate = (appKey) => {
    ['scope', 'cors_from'].forEach((el) => {
      if (Array.isArray(appKey[el])) {
        appKey[el] = '{"' + appKey[el].join('","') + '"}';
      }
    });
    if (appKey.credentials[0].details && appKey.credentials[0].details.lenth) {
      appKey.credentials[0].details = JSON.parse(appKey.credentials[0].details);
    }
    if (appKey.id) {
      appKeysUpdateRequest({ ...appKey });
    } else {
      appKeysCreateRequest(_.omit(appKey, ['id']));
    }
  };

  return (
    <>
      <AppKeysForm
        id={id && !['appKeys', 'add'].includes(id) ? id : null}
        onValidate={handleValidate}
        initialValues={
          initialValues && {
            ...initialValues,
            appkeys_roles: Array.isArray(initialValues?.appkeys_roles) ? initialValues.appkeys_roles.map((el) => el?.role?.id) : [],
            credentials: Array.isArray(initialValues?.credentials)
              ? initialValues.credentials.map((el) => ({ ...el, details: _.isObject(el.details) ? JSON.stringify(el.details) : el.details }))
              : initialValues.credentials,
          }
        }
        medicOptions={medicOptions}
        clinicOptions={clinicOptions}
        medicalTeamOptions={medicalTeamOptions}
        token={token}
        rolesOptions={rolesOptions}
        appKeyRoleOptions={appKeyRoleOptions}
        specialitiesOptions={specialitiesOptions}
        surveyTypesOptions={surveyTypesOptions}
        languageOptions={languageOptions}
      />
    </>
  );
};

export default AppKeysCreate;

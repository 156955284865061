/* Core exports */
import { connect } from 'react-redux';

import RequireAuth from './RequireAuth';

const mapStateToProps = ({ auth }) => {
  return {
    isUserLoggedIn: !!auth.token,
  };
};

export default connect(mapStateToProps, null)(RequireAuth);

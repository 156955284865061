/**
 * GRAPHQL MUTATIONS
 */
export default {
  deleteNotIn: `
    mutation DeleteNotIn($surveyTypeId: Int!,  $introductionQuestionIds: [Int!]!) {
      delete_introduction_questions_translations(where: {
        introduction_question: {
          survey_type_id: {_eq: $surveyTypeId}, 
          id: {_nin: $introductionQuestionIds}
      }, 
      }) {
        returning {
          id
        }
      }
    delete_introduction_questions(where: {
      survey_type_id: {_eq: $surveyTypeId}, 
      id: {_nin: $introductionQuestionIds}
    }) {
      returning {
        id
      }
    }
  }
  `,
  insertIntroductionQuestions: `
    mutation InsertIntroductionQuestions($introductionQuestion: [introduction_questions_insert_input!]!) {
      insert_introduction_questions(objects: $introductionQuestion, on_conflict: {constraint: introduction_questions_pkey, update_columns: [displayed_number, displayed_if, is_after, is_active]}) {
        returning {
          id
          __typename
        }
        __typename
      }
    }
  `,
  updateIntroductionQuestions: `
    mutation UpdateIntroductionQuestions($id: Int!, $introductionQuestion: introduction_questions_set_input!) {
      update_introduction_questions(
        _set: $introductionQuestion,
        where: {id: {_eq: $id}}
      ) {
        returning {
          id
        }
      }
    }
  `,
  updateIntroductionQuestionTranslation: `
    mutation UpdateIntroductionQuestionTranslation($id: Int!, $introductionQuestionTranslation: introduction_questions_translations_set_input!) {
      update_introduction_questions_translations(
        _set: $introductionQuestionTranslation,
        where: {id: {_eq: $id}}
      ) {
        returning {
          id
        }
      }
    }
  `,
  insertIntroductionQuestionTranslations: `
    mutation InsertIntroductionQuestionTranslations($introductionQuestionTranslation: [introduction_questions_translations_insert_input!]!) {
      insert_introduction_questions_translations(objects: $introductionQuestionTranslation, on_conflict: {constraint: introduction_questions_translation_pkey, update_columns: [first_button_label, language_id, loid, pdf_checkbox_label, pdf_url, second_button_label, text_error, text_success, title, value]}) {
        returning {
          id
        }
      }
    }
  `,
};

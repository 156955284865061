import _ from 'lodash';
import { call, put, takeLatest } from 'redux-saga/effects';

import { MedicalTeamsService, MedicsService, UserService } from '../../services';
import userRolesService from '../../services/userRolesService';
import { phoneNumberMapper } from '../../utils/dataMappers';
import { AuthActions } from '../auth';
import { LoaderActions } from '../loader';
import { SnackActions } from '../snackBar';
import { default as MedicActions, types } from './actions';

function* medicsRequest({ filters }) {
  const id = (filters && filters.id) || null;
  const [error, response] = id ? yield call(MedicsService.medics, id) : yield call(MedicsService.getMedics);
  if (error) {
    yield put(SnackActions.displayError('medic_list_error'));
    yield put(MedicActions.medicsFailure());
    return;
  }
  if (!error && response) {
    const { medics } = response;
    yield put(MedicActions.medicsSuccess(medics));
    // yield call(MedicsService.medicsSignedSurveys())
  } else {
    yield put(SnackActions.displayError('medic_list_error'));
    yield put(MedicActions.medicsFailure());
  }
}

function* medicUnattributeSurveys(medicID) {
  yield call(MedicsService.clearStoreTemplates, medicID);
}

function* medicsUpdateRequest({ medic }) {
  const allDataPayload = {
    id: medic.id,
    medicPayload: {
      ..._.pick(medic, [
        'is_premium',
        'email',
        'gender',
        'firstname',
        'lastname',
        'title',
        'rpps_number',
        'country',
        'phone_number',
        'user_id',
        'notif_mail',
        'notif_sms',
        'created_at',
        'updated_at',
        'is_secretary',
        'connector_medical_teams_id',
        'role',
        // 'clinical_trial_id',
      ]),
      ...phoneNumberMapper(medic.phone_number && medic.phone_number.replace(/\s/g, '')),
    },
    medicUserPayload: _.pick(medic.user, ['role', 'username', 'language_id']),
    medicSpecialities:
      (medic.speciality_id &&
        medic.speciality_id.map((speciality_id) => ({
          speciality_id: speciality_id,
          medic_id: medic.id,
        }))) ||
      {},
    medicalTeams:
      (medic.medical_teams_id &&
        medic.medical_teams_id.map((medical_teams_id) => ({
          medical_teams_id: medical_teams_id,
          medic_id: medic.id,
        }))) ||
      {},
    userRoles: _.get(medic, 'user.users_roles', []).map((id) => ({ role_id: id, user_id: medic.user_id })),
  };

  const [error, result] = yield call(MedicsService.medicsUpdate, allDataPayload);

  if (!error && result) {
    if (medic && medic.speciality_id && medic.speciality_id.length && medic.is_premium) {
    } else if (!medic.is_premium) {
      yield call(medicUnattributeSurveys, medic.id);
    }
    if (medic.clinic_id && Array.isArray(medic.clinic_id)) {
      const medicID = medic.id;
      const newData = medic.clinic_id.map((c) => ({ medic_id: medicID, clinic_id: c }));
      yield call(MedicsService.linkClinicsMedic, medicID, newData);
    }
    yield put(LoaderActions.loaded());
    yield put(SnackActions.displayInfo('medic_modify_success'));
  } else {
    yield put(LoaderActions.loaded());
    yield put(SnackActions.displayError('medic_modify_error'));
  }
  yield put(MedicActions.medicsUpdateSuccess({}));
  yield put(MedicActions.medicsRequest());
  yield put(LoaderActions.loaded());
  yield put(AuthActions.setRedirectUrl('/medics'));
}

function* medicsCreateRequest({ filters }) {
  yield put(LoaderActions.loading());
  filters.user.renew_pwd = false;
  const newFilter = {
    medic: {
      gender: filters.gender,
      firstname: filters.firstname,
      lastname: filters.lastname,
      email: filters.email,
      rpps_number: filters.rpps_number || null,
      phone_number: filters.phone_number ? filters.phone_number.replace(/\s/g, '') : null,
      country: filters.country || '',
      is_premium: filters.is_premium,
      is_secretary: filters.is_secretary,
      connector_medical_teams_id: filters.connector_medical_teams_id,
      role: filters.role,
      // clinical_trial_id: filters.clinical_trial_id,
    },
    user: {
      username: filters.user.username || filters.email,
      role: filters.user.role,
      users_roles: { role_id: filters.user.role_id, user_id: filters.user.id },
      language_id: filters.user.language_id,
    },
    clinics: filters.clinic_id || null,
    medicalTeams: filters.medical_teams_id || null,
    specialities: filters.speciality_id || null,
    usersRoles: filters?.user?.users_roles || null,
  };

  const [error, response] = yield call(MedicsService.medicsCreate, newFilter);

  if (!error && response) {
    const medic = response.data && response.data.medics.length > 0 && response.data.medics[0];
    if (medic) {
      // user Roles
      const userRoles = _.get(filters, 'user.users_roles', []).map((id) => ({ role_id: id, user_id: medic.user_id }));
      if (userRoles && userRoles.length > 0) {
        yield call(userRolesService.linkUserRoleToMedic, medic.user_id, userRoles);
      }
      const medicID = medic.id;
      // Specialty
      const medicSpecialities = filters.speciality_id && filters.speciality_id.map((idMap) => ({ speciality_id: idMap, medic_id: medicID }));
      if (medicSpecialities && medicSpecialities.length > 0) {
        yield call(MedicsService.linkPathSpeMedicsCreate, medicID, medicSpecialities);
      }

      if (Array.isArray(filters.medical_teams_id)) {
        // MedicTeams
        const medicTeams =
          filters.medical_teams_id &&
          filters.medical_teams_id.map((medical_teams_id) => ({
            medical_teams_id: medical_teams_id,
            medic_id: medicID,
          }));
        if (medicTeams && medicTeams.length > 0) {
          yield call(MedicalTeamsService.medicalTeamsMedicInsert, medicTeams);
        }
      }

      if (Array.isArray(filters.clinic_id)) {
        // Medic Clinics
        const clinicList =
          filters.clinic_id &&
          filters.clinic_id.map((c) => ({
            medic_id: medicID,
            clinic_id: c,
          }));
        if (clinicList && clinicList.length > 0) {
          yield call(MedicsService.linkClinicsMedic, medicID, clinicList);
        }
      }
    }
    yield put(LoaderActions.loaded());
    yield put(SnackActions.displayInfo('medic_create_success'));
    yield put(MedicActions.medicsCreateSuccess({}));
    yield put(MedicActions.medicsRequest({ id: filters.medics }));
    yield put(AuthActions.setRedirectUrl('/medics'));
  } else {
    yield put(LoaderActions.loaded());
    yield put(SnackActions.displayError('medic_create_error'));
  }
}

function* medicsDeleteRequest({ medicIds }) {
  yield put(LoaderActions.loading());
  const [error, result] = yield call(MedicsService.medicsDelete, medicIds);

  if (!error && result) {
    yield put(MedicActions.medicsDeleteSuccess(medicIds));
    yield put(SnackActions.displayInfo('medic_delete_success'));
  } else {
    yield put(SnackActions.displayError('medic_delete_error'));
  }

  yield put(MedicActions.stopLoader());
  yield put(LoaderActions.loaded());
}

function* medicsDeleteMultipleRequest({ id, archivedID }) {
  yield put(LoaderActions.loading());
  const result = yield id.map((i) => call(MedicsService.medicsDelete, i, archivedID));
  if (result) {
    yield put(LoaderActions.loaded());
    yield put(SnackActions.displayInfo('medic_delete_success'));
  } else {
    yield put(LoaderActions.loaded());
    yield put(SnackActions.displayError('medic_delete_error'));
  }
  yield id.map((i) => put(MedicActions.medicsDeleteSuccess(i)));
  yield put(LoaderActions.loaded());
  yield put(MedicActions.medicsRequest());
}

function* checkIfEmailExistsInDb({ email, userId }) {
  yield put(MedicActions.startEmailLoader());
  const [error, data] = yield call(UserService.doesEmailExist, email, userId);
  if (error || !data) {
    yield put(MedicActions.patientsEmailFailure());
    return;
  }
  if (data) {
    const countPatients = _.get(data, 'patients_aggregate.aggregate.count', 0);
    const countMedics = _.get(data, 'medics_aggregate.aggregate.count', 0);
    yield put(MedicActions.medicsEmailSuccess(countPatients + countMedics === 0));
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  takeLatest(types.MEDICS_REQUEST, medicsRequest),
  takeLatest(types.MEDICS_UPDATE_REQUEST, medicsUpdateRequest),
  takeLatest(types.MEDICS_CREATE_REQUEST, medicsCreateRequest),
  takeLatest(types.MEDICS_DELETE_REQUEST, medicsDeleteRequest),
  takeLatest(types.MEDICS_DELETE_MULTIPLE_REQUEST, medicsDeleteMultipleRequest),
  takeLatest(types.MEDICS_EMAIL_REQUEST, checkIfEmailExistsInDb),
];

import { connect } from 'react-redux';

import { TemplatesActions } from '../../../redux/templates';
import FindQuestionModal from './FindQuestionModal';

const mapStateToProps = ({ auth, templates }, props) => {
  const { localId } = props;
  const addTranslation = (t = {}, localId) => ({
    ...t,
    translation: t.translations[localId],
    steps: (t.steps || []).map((s) => ({
      ...s,
      translation: s.steps_translations[localId],
      questions: (s.questions || []).map((q) => {
        return {
          ...q,
          translation: q.questions_translations[localId],
        };
      }),
    })),
  });

  return {
    templates: Object.values(templates.list || {}).map((t) => addTranslation(t, localId)),
    selectedTemplate: templates.findQuestions && addTranslation(templates.findQuestions, localId),
  };
};

const mapDispatchToProps = (dispatch) => ({
  templateGetRequest: () => dispatch(TemplatesActions.templateGetRequest()),
  templateGetDetailRequest: (id, lngId) => dispatch(TemplatesActions.templateGetDetailRequest(id, lngId, 'findQuestions')),
  clearFindQuestion: () => dispatch(TemplatesActions.clearFindQuestion()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FindQuestionModal);

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translationFR2 from './fr-FR/fr-FR.js';
// import translationFR from './fr-FR/fr-FR.json';

// eslint-disable-next-line import/no-named-as-default-member
i18n
  .use(initReactI18next) // pass the i18n instance to react-i18next
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: process.env.NODE_ENV !== 'production',
    fallbackLng: 'fr',
    ns: ['hospi', 'clinicStudies', 'common'],
    defaultNS: 'common',
    // fallback, can be a string or an array of namespaces
    fallbackNS: 'common',
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    resources: { fr: translationFR2 },
  });

export default i18n;

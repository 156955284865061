import { connect } from 'react-redux';

import { AuthActions } from '../../../redux/auth';
import { SnackActions } from '../../../redux/snackBar';
import NoMatch from './NoMatch';

const mapStateToProps = ({ auth }) => {
  return {
    isUserLoggedIn: !!auth.token,
  };
};

const mapDispatchToProps = (dispatch) => ({
  displayWarning: (message) => dispatch(SnackActions.displayWarning(message)),
  setLoginRedirectUrl: (url) => dispatch(AuthActions.setLoginRedirectUrl(url)),
  clearLoginRedirectUrl: () => dispatch(AuthActions.clearLoginRedirectUrl()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NoMatch);

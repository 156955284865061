import { connect } from 'react-redux';

import { LanguageActions } from '../../../redux/languages';
import LanguageCreate from './LanguageCreate';

const mapStateToProps = ({ language }) => {
  return {
    languageList: language.list || {},
  };
};

const mapDispatchToProps = (dispatch) => ({
  languageRequest: (id) => dispatch(LanguageActions.request(id)),
  languageUpsert: (payload) => dispatch(LanguageActions.upsert(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LanguageCreate);

import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import SftpForm from './SftpCredentialForm';

const SftpCredentialCreate = ({
  token,
  clinicsRequest,
  medicOptions,
  clinicOptions,
  medicalTeamOptions,
  sftpRequest,
  sftpUpsertRequest,
  sftpList,
}) => {
  const { sftpCredentialId } = useParams();
  const id = sftpCredentialId && !['sftpCredentials', 'sftp', 'add'].includes(sftpCredentialId) ? sftpCredentialId : null;
  const initialValues = useMemo(() => {
    return (id && sftpList && sftpList[id]) || {};
  }, [id, sftpList]);

  useEffect(() => {
    if (id && sftpRequest) sftpRequest({ id });
  }, [id]);

  useEffect(() => {
    clinicsRequest && clinicsRequest();
  }, []);

  const handleValidate = (sftp) => {
    sftp && sftpUpsertRequest({ ...sftp });
  };

  return (
    <>
      <SftpForm
        onValidate={handleValidate}
        initialValues={initialValues}
        medicOptions={medicOptions}
        clinicOptions={clinicOptions}
        medicalTeamOptions={medicalTeamOptions}
        token={token}
      />
    </>
  );
};

export default SftpCredentialCreate;

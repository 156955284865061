import '../style.scss';

import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';

function InputRadio({ name, rules = { required: false }, options, className, containerClassName, defaultValue, isVertical, ...rest }) {
  const { control } = useFormContext(); // retrieve all hook methods
  const { field } = useController({
    name,
    control,
    rules: { ...rules },
    defaultValue: defaultValue || _.get(control, ['defaultValuesRef', 'current', name]) || '',
  });
  const classNameSuffix = isVertical ? '-vertical' : '';

  return (
    <fieldset id={name}>
      <div className={`${containerClassName || `input-radio-group${classNameSuffix}`}`}>
        {options.map((o) => (
          <label htmlFor={`${name}_${o.key}`} key={`${name}_${o.key}`} className={`${className || `input-radio-input-container${classNameSuffix}`}`}>
            <input
              type="radio"
              key={`radio_${name}`}
              id={`${name}_${o.key}`}
              {...field}
              {...rest}
              value={o.key}
              className={`${`input-radio-input${classNameSuffix}`}`}
            />
            {o.value}
          </label>
        ))}
      </div>
    </fieldset>
  );
}

InputRadio.propTypes = {
  register: PropTypes.func,
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
};

export default InputRadio;

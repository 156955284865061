import { Button, Collapse, Typography } from 'antd';
import * as loadash from 'lodash';
import React, { useState } from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { v1 as uuidv1 } from 'uuid';

import { normalizeString } from '../../../utils';
import ReactSelect from '../ReactSelect/ReactSelect';
import XLSExport from '../xlsExport';

const DSC = 'descending';
const { Panel } = Collapse;
const { Text } = Typography;

export const TAGS = {
  surveys: { TYPES: 'TYPES', SPECIALITIES: 'SPECIALITIES', OWNERS: 'OWNERS', FILTERS: 'FILTERS' },
  templates: { TYPES: 'TYPES', SPECIALITIES: 'SPECIALITIES', FILTERS: 'FILTERS' },
  medicTemplates: { TYPES: 'TYPES', SPECIALITIES: 'SPECIALITIES', OWNERS: 'OWNERS', FILTERS: 'FILTERS' },
  consents: {
    REFERENTS: 'REFERENTS',
    CLINICS: 'CLINICS',
    TYPES: 'TYPES',
    SPECIALITIES: 'SPECIALITIES',
    MEDIC_TEAM: 'MEDIC_TEAM',
  },
  medics: { SPECIALITIES: 'SPECIALITIES' },
};

const Tags = ({ data, onTags, tagIndex, extra, exportFct }) => {
  const [selected, setSelected] = useState({});

  const sortByText = (a, b, order = DSC) => {
    const valueA = a.firstname ? a.firstname + a.lastname : a.value;
    const valueB = b.firstname ? b.firstname + b.lastname : b.value;
    const diff = loadash.deburr(normalizeString(valueA)).localeCompare(loadash.deburr(normalizeString(valueB)));

    if (order === DSC) {
      return diff;
    }

    return -1 * diff;
  };

  const handleOnTags = (value, mode) => {
    const newSelectedValues = selected;
    newSelectedValues[mode] = value || [];
    setSelected(newSelectedValues);
    if (onTags) {
      onTags(value, mode);
    }
  };

  return (
    <Collapse style={{ marginBottom: 9 }}>
      {(extra || (data && data.length)) && (
        <Panel header="Filtres" key="1">
          {extra}

          {data &&
            data.length &&
            data.map((d) => (
              <Grid fluid style={{ padding: 0 }} key={uuidv1()}>
                <Row style={{ marginBottom: 9 }}>
                  <Col xl={1} lg={1} md={2} sm={2} xs={3} style={{ display: 'flex', alignItems: 'center' }}>
                    <Button value="all" onClick={() => handleOnTags(null, TAGS[tagIndex][d.tagIndex])}>
                      Tous
                    </Button>
                  </Col>
                  <Col xl={2} lg={4} md={4} sm={3} xs={5} style={{ display: 'flex', alignItems: 'center' }}>
                    <Text>{d.title} </Text>
                  </Col>
                  <Col xl={3} lg={5} md={5} sm={3} xs={4} style={{ display: 'flex', alignItems: 'center' }}>
                    <ReactSelect
                      placeholder={d.placeholder}
                      onChange={(value) => handleOnTags(value, TAGS[tagIndex][d.tagIndex])}
                      closeMenuOnSelect={true}
                      isMulti
                      value={selected[d.tagIndex]}
                      options={d.list
                        .sort((a, b) => sortByText(a, b, DSC))
                        .map((m) => ({
                          value: m.id,
                          label: m.value || m?.translation?.value || m?.translation?.label,
                        }))}
                    />
                  </Col>
                </Row>
              </Grid>
            ))}
        </Panel>
      )}
      {exportFct && (
        <Panel header="Export" key="2">
          <XLSExport toExport={exportFct} />
        </Panel>
      )}
    </Collapse>
  );
};

export default Tags;

import { connect } from 'react-redux';

import Menu from './Menu';

const mapStateToProps = ({ questionTypes }) => {
  return {
    questionTypes: Object.values(questionTypes.list || {}),
  };
};

export default connect(mapStateToProps, null)(Menu);

import _ from 'lodash';
import { createReducer } from 'reduxsauce';

import { types } from './actions';

const initialState = {
  list: {},
  loading: false,
  hasMore: true,
  isEmailAvailable: null,
  emailLoading: false,
};

const startLoader = (state = initialState) => ({ ...state, loading: true });

const stopLoader = (state = initialState) => ({ ...state, loading: false });

const medicsSuccess = (state = initialState, action) => {
  const { medics } = action;
  const { list } = state;
  const data = list;
  medics.forEach((medic) => {
    data[medic.id] = { ...data[medic.id], ...medic };
  });
  return { ...state, list: data, loading: false, hasMore: medics.length > 0 };
};

const medicDeleteSuccess = (state = initialState, action) => {
  const { medicIds } = action;
  const { list } = state;
  const newList = _.omit(list, medicIds);

  const medics = Object.values(list);
  const data = {};

  medics.forEach((medic) => {
    data[medic.id] = { ...data[medic.id], ...medic };
  });
  return { ...state, list: newList, loading: false, hasMore: medics.length > 0 };
};

/**
 *
 * @param state
 * @returns {{list: {}, loading: boolean}}
 */
const startEmailLoader = (state = initialState) => ({ ...state, emailLoading: true });

const medicsEmailFailure = (state = initialState) => {
  return { ...state, isEmailAvailable: false, emailLoading: false };
};

const medicsEmailSuccess = (state = initialState, action) => {
  const { message } = action;
  return { ...state, isEmailAvailable: message, emailLoading: false };
};

export default createReducer(initialState, {
  [types.STOP_LOADER]: stopLoader,

  [types.MEDICS_CREATE_REQUEST]: startLoader,
  [types.MEDICS_CREATE_SUCCESS]: stopLoader,

  [types.MEDICS_UPDATE_REQUEST]: startLoader,
  [types.MEDICS_UPDATE_SUCCESS]: stopLoader,

  [types.MEDICS_REQUEST]: startLoader,
  [types.MEDICS_SUCCESS]: medicsSuccess,
  [types.MEDICS_FAILURE]: stopLoader,

  [types.MEDICS_DELETE_REQUEST]: startLoader,
  [types.MEDICS_DELETE_MULTIPLE_REQUEST]: startLoader,
  [types.MEDICS_DELETE_SUCCESS]: medicDeleteSuccess,

  [types.MEDICS_EMAIL_REQUEST]: startEmailLoader,
  [types.MEDICS_EMAIL_SUCCESS]: medicsEmailSuccess,
  [types.MEDICS_EMAIL_FAILURE]: medicsEmailFailure,
});

import { connect } from 'react-redux';

import { MedicActions } from '../../../redux/medics';
import { SpecialityActions } from '../../../redux/specialities';
import { SurveyTypesActions } from '../../../redux/surveyTypes';
import Medics from './Medics';

const mapStateToProps = ({ medics, specialities, surveyTypes }) => ({
  list: Object.values(medics.list) || {},
  specialities: Object.values(specialities.list) || {},
  loading: medics.loading,
  types: Object.values(surveyTypes.list || {}),
});

const mapDispatchToProps = (dispatch) => ({
  specialitiesRequest: (filter) => dispatch(SpecialityActions.specialitiesRequest(filter)),
  medicsRequest: (filter) => dispatch(MedicActions.medicsRequest(filter)),
  medicsDeleteRequest: (medicIds) => dispatch(MedicActions.medicsDeleteRequest(medicIds)),
  medicsDeleteMultipleRequest: (medic, archivedID) => dispatch(MedicActions.medicsDeleteMultipleRequest(medic, archivedID)),
  surveyTypesRequest: (filter) => dispatch(SurveyTypesActions.surveyTypesRequest(filter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Medics);

import _ from 'lodash';
import { connect } from 'react-redux';

import InputSelect from './InputSelect';

const mapStateToProps = (state, props) => {
  // storeOptionPath is location to date in Redux -> city.cities
  // storeItemValue is the key of the object -> id for obejct city from array cities
  // storeItemLabel is a mask in order to give a label-> {{ name }}, {{ id }}
  const { storeOptionPath, storeItemValue, storeItemLabel, options } = props;
  const storeList = _.get(state, storeOptionPath);

  // get the label from storeItemLabel
  _.templateSettings.interpolate = /{{([\s\S]{0,1000}})}}/;
  const compiled = _.template(storeItemLabel);

  const reduxOptions = (options || (_.isObject(storeList) && _.values(storeList)) || (storeList && Array.isArray(storeList) && storeList) || []).map(
    (el) => ({
      value: _.get(el, storeItemValue, el.value || el.id || ''),
      label: (storeItemLabel && compiled(el)) || el.title || el.name || el.value || el.id,
    }),
  );
  return {
    options: options || reduxOptions,
  };
};

export default connect(mapStateToProps, null)(InputSelect);

import { createReducer } from 'reduxsauce';

import { types } from './actions';

const initialState = {
  list: {},
  loading: false,
  hasMore: true,
};

const startLoader = (state = initialState) => ({ ...state, loading: true });

const stopLoader = (state = initialState) => ({ ...state, loading: false });

const consentsSuccess = (state = initialState, action) => {
  const { consents } = action;
  const { list } = state;
  const data = list;
  consents.forEach((consent) => {
    data[consent.id] = consent;
  });
  return { ...state, list: data, loading: false, hasMore: consents.length > 0 };
};

const consentDeleteSuccess = (state = initialState, action) => {
  const { IDs } = action;
  let list = state.list;

  IDs.forEach((id) => {
    delete list[id];
  });

  return { ...state, list: list, loading: false };
};

const consentsClear = (state = initialState, action) => {
  return initialState;
};

const consentArchiveSuccess = (state = initialState, action) => {
  const { IDs, archived } = action;
  let list = state.list;

  IDs.forEach((id) => {
    list[id] = { ...list[id], is_archived: archived };
  });

  return { ...state, list: list };
};

export default createReducer(initialState, {
  [types.CONSENTS_CLEAR]: consentsClear,

  [types.CONSENTS_DELETE_SUCCESS]: consentDeleteSuccess,
  [types.CONSENTS_ARCHIVE_SUCCESS]: consentArchiveSuccess,

  [types.CONSENTS_REQUEST]: startLoader,
  [types.CONSENTS_SUCCESS]: consentsSuccess,
  [types.CONSENTS_FAILURE]: stopLoader,
});

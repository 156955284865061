import { createReducer } from 'reduxsauce';

import { types } from './actions';

const initialState = {
  list: {},
  loading: false,
  hasMore: true,
};

const startLoader = (state = initialState) => ({ ...state, loading: true });

const stopLoader = (state = initialState) => ({ ...state, loading: false });

const surveyCurrentDelete = (state = initialState) => ({ ...state, survey: {} });

const surveysSuccess = (state = initialState, action) => {
  const { surveys } = action;
  const { list } = state;
  const data = list;
  surveys.forEach((survey) => {
    data[survey.id] = survey;
  });
  return { ...state, list: data, loading: false, hasMore: surveys.length > 0 };
};

const surveyCurrentSuccess = (state = initialState, action) => {
  const { surveys } = action;
  const data = {};
  data[surveys.id] = surveys;
  return { ...state, survey: data, loadingSurvey: false };
};

const surveyDeleteSuccess = (state = initialState, action) => {
  const { survey } = action;
  const { list } = state;
  const data = list;
  if (Array.isArray(survey)) {
    survey.forEach((v) => {
      delete data[v];
    });
  } else {
    delete data[survey.id];
  }

  /*const surveys = Object.values(list)

    surveys.forEach(s => {
        data[s.id] = s
    })*/

  return { ...state, list: data, loading: false };
};

export default createReducer(initialState, {
  [types.SURVEYS_CREATE_REQUEST]: startLoader,
  [types.SURVEYS_CREATE_SUCCESS]: stopLoader,

  [types.SURVEYS_UPDATE_REQUEST]: startLoader,
  [types.SURVEYS_UPDATE_SUCCESS]: stopLoader,

  [types.SURVEYS_REQUEST]: startLoader,
  [types.SURVEYS_SUCCESS]: surveysSuccess,
  [types.SURVEYS_FAILURE]: stopLoader,

  [types.SURVEYS_DELETE_REQUEST]: startLoader,
  [types.SURVEYS_DELETE_SUCCESS]: surveyDeleteSuccess,

  [types.SURVEY_CURRENT_DELETE]: surveyCurrentDelete,

  [types.SURVEY_CURRENT_REQUEST]: startLoader,
  [types.SURVEY_CURRENT_SUCCESS]: surveyCurrentSuccess,
  [types.SURVEY_CURRENT_FAILURE]: stopLoader,
});

import './style.css';

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Card from '../../../../components/card';
import { InputCheckbox, InputText, SurveyFormController } from '../../../../components/formV3';
import InputSelectV2 from '../../../../components/formV3/controllers/InputSelectV2';
import InputTags from '../../../../components/formV3/controllers/InputTagsV3/InputTags';

const ConsentHeader = ({
  preview,
  disabled,
  medicalTeams,
  medics,
  specialitiesOptions,
  surveyModelFilesRequestAll,
  surveyTypesOptions,
  can,
  isStoreTemplate,
  surveyModelFileOptions,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    surveyModelFilesRequestAll();
  }, []);

  if (!specialitiesOptions && !surveyTypesOptions && !surveyModelFileOptions) {
    // if options are not fetched, wait
    return null;
  }

  return (
    <Card>
      <SurveyFormController name={['translation', 'title'].join('.')} isRequiredField={true} controlClassName="px-2 w-100">
        <InputText
          className={`ec-consentheader-title-Input${!can.editTitle ? '' : '-static'}`}
          disabled={!can.editTitle}
          placeholder={preview ? '' : 'Titre du formulaire'}
        />
      </SurveyFormController>
      <div className="d-flex flex-column flex-md-row my-2">
        <SurveyFormController name={'type_id'} controlClassName="px-2 w-100" label={'Type'}>
          <InputSelectV2
            disabled={!can.editSurveyType}
            options={surveyTypesOptions}
            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
            placeholder={"Pour quel type d'intervention"}
            className="custom-select-container"
          />
        </SurveyFormController>
        <SurveyFormController name={'speciality_id'} controlClassName="px-2 w-100" label={'Speciality'}>
          <InputSelectV2
            disabled={!can.editSpecialty}
            options={specialitiesOptions}
            placeholder={'Pour quel spécialité'}
            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
            className="custom-select-container"
          />
        </SurveyFormController>
      </div>
      <div className="my-2">
        <SurveyFormController name={'isRadius'}>
          <InputCheckbox label={t('radius_choice')} className="ec-checkbox-text-consent-header" />
        </SurveyFormController>
        <SurveyFormController name={'isSide'}>
          <InputCheckbox label={t('side_choice')} className="ec-checkbox-text-consent-header" />
        </SurveyFormController>
      </div>
      <SurveyFormController name={['translation', 'description'].join('.')}>
        <InputTags
          className={`ec-consentheader-description-container${can.editDescription ? '' : '-static'}`}
          disabled={disabled}
          placeholder={'Tag'}
        />
      </SurveyFormController>
      <div className="d-flex flex-column flex-md-row my-2">
        <SurveyFormController name={['translation', 'survey_model_files_id'].join('.')} label={'Template PDF pour le consentement (vide = default)'}>
          <InputSelectV2
            options={surveyModelFileOptions}
            isClearable
            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
            placeholder={t('surveyForm.consent_pdf_template.placeholder')}
            className="custom-select-container"
          />
        </SurveyFormController>
      </div>
      {!isStoreTemplate ? (
        <div className="d-flex flex-column flex-md-row my-2">
          <SurveyFormController name={'medical_teams_id'} label={'Equipe médicale'} controlClassName="px-2 w-100">
            <InputSelectV2
              options={medicalTeams.map((el) => ({
                value: el.id,
                label: el.title,
              }))}
              isClearable
              components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
              placeholder={t('surveyForm.medical_teams_id.placeholder')}
              className="custom-select-container"
            />
          </SurveyFormController>
          <SurveyFormController name={'medic_id'} label={'Propriétaire'} controlClassName="px-2 w-100">
            <InputSelectV2
              options={medics.map((el) => ({
                value: el.id,
                label: `${el.title} ${el.firstname} ${el.lastname}`,
              }))}
              isClearable
              components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
              placeholder={t('surveyForm.medical_teams_id.placeholder')}
              className="custom-select-container"
            />
          </SurveyFormController>
        </div>
      ) : (
        <div />
      )}
    </Card>
  );
};

export default ConsentHeader;

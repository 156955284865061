import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import CredentialForm from './CredentialForm';

const CredentialCreate = ({
  token,
  credentialsUpsert,
  credentialsRequest,
  usersRequest,
  userOptions,
  appKeysRequest,
  appKeyOptions,
  credentialList,
}) => {
  const { credentialId } = useParams();
  const id = credentialId && !['credentials', 'add'].includes(credentialId) ? credentialId : null;
  const initialValues = useMemo(() => {
    return (credentialList && credentialList[id]) || {};
  }, [id, credentialList]);

  useEffect(() => {
    if (id && credentialsRequest) {
      credentialsRequest(id);
    }
  }, [id]);

  useEffect(() => {
    appKeysRequest && appKeysRequest();
    usersRequest && usersRequest();
  }, []);

  const handleValidate = (Credential) => {
    Credential.details = JSON.parse(Credential.details);
    Credential && credentialsUpsert({ ...Credential });
  };

  return (
    <>
      <CredentialForm
        onValidate={handleValidate}
        initialValues={{
          ...initialValues,
          details: initialValues?.details ? JSON.stringify(initialValues?.details) : '',
        }}
        token={token}
        userOptions={userOptions}
        appKeyOptions={appKeyOptions}
      />
    </>
  );
};

export default CredentialCreate;

import { createReducer } from 'reduxsauce';

import { types } from './actions';

const initialState = {
  list: [],
  loading: false,
};

const startLoader = (state = initialState) => ({ ...state, loading: true });

const stopLoader = (state = initialState) => ({ ...state, loading: false });

const clear = (state = initialState, action) => {
  return initialState;
};

const fetchAllSuccess = (state = initialState, action) => {
  const { languages } = action;
  return { ...state, list: languages, loading: false };
};

export default createReducer(initialState, {
  [types.START_LOADER]: startLoader,
  [types.STOP_LOADER]: stopLoader,

  [types.FETCH_ALL_SUCCESS]: fetchAllSuccess,
  [types.CLEAR]: clear,
});

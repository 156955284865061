import { connect } from 'react-redux';

import { SpecialityActions } from '../../../redux/specialities';
import SpecialitiesCreate from './SpecialitiesCreate';

const mapStateToProps = ({ language, specialities }) => ({
  specialitiesList: specialities.list,
  specialities: Object.values(specialities.list || {}),
  languageList: Object.values(language.list || {}),
});

const mapDispatchToProps = (dispatch) => ({
  specialitiesRequest: (filter) => dispatch(SpecialityActions.specialitiesRequest(filter)),
  specialitiesCreateRequest: (creation) => dispatch(SpecialityActions.specialitiesCreateRequest(creation)),
  specialitiesUpdateRequest: (speciality) => dispatch(SpecialityActions.specialitiesUpdateRequest(speciality)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SpecialitiesCreate);

import { PlusCircleFilled } from '@ant-design/icons';
import React from 'react';

const AddButton = ({ onClick }) => {
  return (
    <a>
      <PlusCircleFilled onClick={onClick} style={{ color: '#1890ff', fontSize: '20px' }} />
    </a>
  );
};

export default AddButton;

import { createReducer } from 'reduxsauce';

import { types } from './actions';

const initialState = {
  config: {},
};

const cronConfigSuccess = (state = initialState, action) => {
  const { config } = action;

  return { ...state, config: config };
};

export default createReducer(initialState, {
  [types.CRON_CONFIG_SUCCESS]: cronConfigSuccess,
});

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Divider, Form, Input, Select } from 'antd';
import React, { useEffect } from 'react';

import { defaultRoles } from './defautValues';

const PERMISSIONS = {
  MANAGE: 'manage', // manage represents any action
  CREATE: 'create',
  READ: 'read',
  UPDATE: 'update',
  DELETE: 'delete',
  ACCESS: 'access',
};
const MODEL_NAMES = {
  ALL: 'all', // all represents any subject.
  PATIENT: 'patients',
  MEDICS: 'medics',
  FORM: 'FORM',
  FORM_STEP: 'FORM_STEP',
  FORM_REWORDING: 'FORM_REWORDING',
  FORM_QUESTION: 'FORM_QUESTION',
  CONSENT: 'CONSENT',
  STORE_PAGE: 'STORE_PAGE',
  PROFIL_PAGE: 'PROFIL_PAGE',
  MEDIC_TEMPLATES: 'medic_templates',
  STORE_TEMPLATES: 'templates',
  QUESTION_TYPE: 'question_types',
  PATIENT_SECURITY_NUMBER: 'security_number',
  HOME_FILTER: 'HOME_FILTER',
  PATIENT_FORM_FIELD: 'PATIENT_FORM_FIELD',
  CONSENT_COMPONENT: 'CONSENT_COMPONENT',
};

const patientFields = [
  // 'gender',
  // 'firstname',
  // 'lastname',
  // 'use_name',
  'security_number',
  // 'phone_number',
  // 'address',
  // 'city_id',
  // 'city',
  // 'postcode',
  // 'birthdate',
  // 'ipp',
  // 'internal_insi',
  // 'birthplace_id',
  // 'birthplace',
  // 'birthplace_postcode',
  // 'email',
];
const defInit = {
  name: '',
  permissions: [],
};

const RolesForm = ({ onValidate, initialValues }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (initialValues) {
      form.resetFields();
    }
  }, [initialValues]);

  const onFinish = (values) => {
    onValidate && onValidate(values);
  };

  const handleChange = (value) => {
    form.setFieldsValue({
      permissions: value ? defaultRoles[value].permissions : [],
    });
  };

  return (
    <>
      <Card style={{ margin: 25, width: '80%' }}>
        <Divider type="horizontal">Use a Template</Divider>
        <Select style={{ width: '100%' }} onChange={handleChange}>
          {Object.keys(defaultRoles).map((el, i) => (
            <Select.Option value={el} key={el}>
              {el}
            </Select.Option>
          ))}
        </Select>
        <Form onFinish={onFinish} initialValues={initialValues} layout="inline" form={form} name="basic">
          <Divider type="horizontal">Info</Divider>
          <Form.Item name="name" label="Nom" rules={[{ required: true, message: 'Nom Civil Obligatoire', whitespace: true }]}>
            <Input placeholder="Nom" maxLength={50} />
          </Form.Item>
          <Form.Item name={'is_backend'} valuePropName="checked">
            <Checkbox>Cocher si ce role est reservé au backend</Checkbox>
          </Form.Item>
          <Divider type="horizontal">Etude Clinique</Divider>
          <Form.Item name={'is_clinical_study'} valuePropName="checked">
            <Checkbox>Cocher si ce role est pour les études cliniques</Checkbox>
          </Form.Item>
          <Divider type="horizontal">permissions</Divider>
          <Form.List name={'permissions'}>
            {(fields, { add, remove }) => (
              <div>
                {fields.map((field, index) => (
                  <div key={`permissions${field.key}`}>
                    <Form.Item name={[index, 'inverted']} valuePropName="checked">
                      <Checkbox>Inversé</Checkbox>
                    </Form.Item>
                    <Form.Item name={[index, 'action']} required label="action">
                      <Select style={{ width: '100%' }} mode="multiple">
                        {Object.values(PERMISSIONS).map((el, i) => (
                          <Select.Option value={el} key={`permissions${field.key}_${el}`}>
                            {el}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item name={[index, 'subject']} required label="subject">
                      <Select style={{ width: '100%' }} mode="multiple">
                        {Object.values(MODEL_NAMES).map((el, i) => (
                          <Select.Option value={el} key={`permissions${field.key}_${el}`}>
                            {el}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item name={[index, 'conditions']} label="conditions">
                      <Input placeholder="conditions" />
                    </Form.Item>
                    <Form.Item name={[index, 'desc']} label="description">
                      <Input placeholder="description" />
                    </Form.Item>
                    <Button type="danger" onClick={() => remove(field.name)} style={{ marginTop: 16 }} icon={<MinusCircleOutlined />}>
                      Supprimer
                    </Button>
                    <Divider type="horizontal" />
                  </div>
                ))}
                <Button
                  type="dashed"
                  onClick={() => {
                    add();
                  }}
                  style={{ width: '60%' }}
                >
                  <PlusOutlined /> Add permissions
                </Button>
              </div>
            )}
          </Form.List>
          <Divider type="horizontal" />
          <Form.Item style={{ textAlign: 'center' }}>
            <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
              Enregistrer
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};

export default RolesForm;

import { normalizeString } from '../../../utils';
import { regexSecu, validEmailRegex } from '../../../utils/regex';

const validAlphaRegex = RegExp(/^[a-z '-]+$/i);
const validDigitRegex = RegExp(/^\d+$/);
const validPostalRegex = RegExp(/^(?:[0-8]\d|9[0-8])\d{3}$/);
const validPhoneRegex = /^((?:\+|00)?33(?:[\s.-])|0)([6-7])(?:[\s.-]{0,5})(\d{2})(?:[\s.-]{0,5})(\d{2})(?:[\s.-]{0,5})(\d{2})(?:[\s.-]{0,5})(\d{2})$/;
const validHomePhoneRegex =
  /^((?:\+|00)?33(?:[\s.-])|0)([1-5])(?:[\s.-]{0,5})(\d{2})(?:[\s.-]{0,5})(\d{2})(?:[\s.-]{0,5})(\d{2})(?:[\s.-]{0,5})(\d{2})$/g;

export const required = ({ value }) => {
  return value != null && true && value.length !== 0;
};

export const length = ({ value }, size) => {
  if (value) return value.length === size;
  else return true;
};

export const min = ({ value }, minSize) => {
  if (value) return value.length >= minSize;
  else return false;
};

export const max = ({ value }, maxSize) => {
  if (value) return value.length >= maxSize;
  else return false;
};

export const email = ({ value }) => {
  if (!value) return true;
  return validEmailRegex.test(value);
};

export const same = ({ value, form }, key) => {
  if (!value) return true;
  return value === form[key];
};

export const isValidPostal = ({ value }) => {
  if (!value) return true;
  return validPostalRegex.test(value);
};

export const isValidPhone = ({ value }) => {
  if (!value) return true;
  return validPhoneRegex.test(value);
};

export const isValidHomePhone = ({ value }) => {
  if (!value) return true;
  return validHomePhoneRegex.test(value);
};

export const isValidSecuNumFromRegex = ({ value }) => {
  if (value) {
    return regexSecu.test(value);
  }
};

export const isValidSecuKey = ({ value }) => {
  let numss = value && value.replace(/\s/g, '');
  if (!numss || !regexSecu.test(numss)) {
    return false;
  } else {
    const matchRegex = value.match(regexSecu); // [secu, sex, year, month, department, insee, range, key]
    const sex = matchRegex[1];
    const department = matchRegex[4];
    const key = matchRegex[7];
    const filter_sexe = /^[1-478]$/;
    if (!filter_sexe.test(sex)) {
      return false;
    }
    let deb13;
    if (['2A', '2B'].includes(department)) {
      numss = numss.substring(0, 6) + '0' + numss.substring(7, 15);
      deb13 = parseInt(numss.substring(0, 13));
      // Corse
      if (department === '2A') deb13 -= 1000000;
      if (department === '2B') deb13 -= 2000000;
    } else {
      deb13 = parseInt(numss.substring(0, 13));
    }

    const div97 = 97 - (deb13 % 97);
    let sdiv97 = String(div97);
    return key === ('0' + sdiv97).slice(-2);
  }
};

export const isEmailAvailable = ({ value }, availability, initialEmail) => {
  if (!value) return true;
  if (value === initialEmail) return true;
  return availability;
};

export const alpha = ({ value }) => {
  return !value ? true : validAlphaRegex.test(normalizeString(value));
};

export const digit = ({ value }) => {
  return !value ? true : validDigitRegex.test(value);
};
/* Create form rules */

export const modified = ({ value }, originalValue) => {
  return value != null && true && value !== originalValue && value !== '';
};

export const isTypeSelected = ({ value }) => {
  return value != null && true && value.filter((val) => val.isSelected).length > 0;
};

export const answered = ({ value }) => {
  return value !== null;
};

export const text_answered = ({ value }) => {
  return !!(value && typeof value !== 'object' && value !== '');
};

export const multiple_answered = ({ value }) => {
  return !!(value && Array.isArray(value) && value.filter((v) => v.isSelected).length);
};

import '../style.scss';

import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import shortid from 'shortid';

import ErrorMessage from '../errors/ErrorMessage';

function CustomFormController({ name, label, rules, controlClassName, labelClassName, classNameError, isRequiredField, children, ...rest }) {
  return (
    <div className={`custom-formV2-control ${controlClassName || ''}`}>
      {label && (
        <label
          className={`custom-formV2-control-label ${labelClassName || ''} ${
            isRequiredField || (_.get(rules, 'required.value') || _.get(rules, 'required', false)) === true ? 'required' : ''
          }`}
          htmlFor={name}
        >
          {label || ''}
        </label>
      )}
      <div className="custom-formV2-control-container">
        {children &&
          React.Children.map(children, (child, index) => {
            return child
              ? React.createElement(child.type, {
                  ...{
                    key: `custom-formV2-control_${name}`,
                    name,
                    rules,
                    ...rest,
                    ...child.props,
                  },
                })
              : child;
          })}
      </div>
      <ErrorMessage name={name} />
    </div>
  );
}

CustomFormController.propTypes = {
  register: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.shape({ message: PropTypes.string }),
  controlClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  classNameError: PropTypes.string,
  isRequiredField: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default CustomFormController;

import './style.css';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// UTILISER react-dnd POUR MEILLEUR EXPÉRIENCE

const Dropzone = ({ onChange }) => {
  const { t } = useTranslation();
  const [isDragIn, setIsDragIn] = useState(false);

  const handleDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const type = event.dataTransfer.getData('text/plain');
    if (onChange) onChange(type);
  };

  return (
    <div
      className="droppable consent-step-dropzone-container"
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onDragEnter={(e) => setIsDragIn(true)}
      onDragLeave={(e) => setIsDragIn(false)}
    >
      <p className="consent-step-dropzone-container-text">{isDragIn ? t('form_screen_drop_a_componant') : t('form_screen_slide_a_componant')} </p>
    </div>
  );
};

export default Dropzone;

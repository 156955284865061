import { CopyOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import React from 'react';

import ConfirmButton from '../ConfirmButton';

const DuplicateButton = ({ loading, onClick, onCancel, tooltipTitle = 'dupliquer', disabled }) => (
  <ConfirmButton
    loading={loading}
    type={'dashed'}
    shape={'circle'}
    icon={<CopyOutlined />}
    onClick={onClick}
    onCancel={onCancel}
    tooltipTitle={tooltipTitle}
    disabled={disabled}
  />
);

DuplicateButton.propTypes = {
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  onCancel: PropTypes.func,
};

export default DuplicateButton;

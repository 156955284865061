import { call, put, takeLatest } from 'redux-saga/effects';

import credentialService from '../../services/credentialService';
import { AuthActions } from '../auth';
import { LoaderActions } from '../loader';
import { SnackActions } from '../snackBar';
import { default as CredentialActions, types } from './actions';

function* request({ id }) {
  yield put(LoaderActions.loading());

  const [error, data] = id ? yield call(credentialService.getOne, id) : yield call(credentialService.getAll);
  if (!error && data) {
    yield put(CredentialActions.requestSuccess(data.credentials));
  } else {
    yield put(SnackActions.displayError('credential_request_error'));
  }
  yield put(LoaderActions.loaded());
}

function* upsert({ payload }) {
  yield put(LoaderActions.loading());

  const [error, data] = payload.id ? yield call(credentialService.update, payload) : yield call(credentialService.insert, payload);
  if (!error && data) {
    yield put(CredentialActions.upsertSuccess(payload));
  } else {
    yield put(SnackActions.displayError('credential_upsert_error'));
  }
  yield put(LoaderActions.loaded());
  if (!payload.id) {
    yield put(AuthActions.setRedirectUrl(`/credentials/${data.insert_credentials.returning[0].id}`));
  }
  yield put(AuthActions.setRedirectUrl('/credentials'));
}

function* deleteCredential({ ids }) {
  yield put(LoaderActions.loading());

  const [error, data] = yield call(credentialService.delete, ids);
  if (!error && data) {
    yield put(CredentialActions.deleteSuccess(ids));
  } else {
    yield put(SnackActions.displayError('credential_upsert_error'));
  }
  yield put(LoaderActions.loaded());
  yield put(AuthActions.setRedirectUrl('/credentials'));
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [takeLatest(types.REQUEST, request), takeLatest(types.UPSERT, upsert), takeLatest(types.DELETE, deleteCredential)];

import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import FormLanguageSelector from '../../components/FormLanguageSelector';
import SurveyTypesForm from '../../components/surveyTypesForm/SurveyTypesForm';
import { TEMPLATES } from '../../components/surveyTypesForm/surveyTypesPaneConfig';

const SurveyTypesCreate = ({
  fetchAllLanguages,
  surveyTypesCreateRequest,
  surveyTypesUpdateRequest,
  cronConfigRequest,
  surveyTypesList,
  cronConfig,
  languageList,
  surveyTypesRequest,
}) => {
  let { surveyTypeId } = useParams();
  const id = surveyTypeId && !['surveyTypes', 'add'].includes(surveyTypeId) ? surveyTypeId : null;

  const [searchParams] = useSearchParams();
  const [urlParams, setUrlParams] = useState();
  const form = useMemo(() => {
    return id && surveyTypesList && surveyTypesList[id];
  }, [id, surveyTypesList]);

  useEffect(() => {
    setUrlParams(Object.fromEntries([...searchParams]));
  }, [searchParams]);

  const selectedLng = useMemo(() => {
    if (urlParams && urlParams.editLocale && Array.isArray(languageList)) {
      return languageList.find((lng) => lng.language_code === urlParams.editLocale);
    }
    return languageList && languageList[0];
  }, [languageList, urlParams]);

  useEffect(() => {
    fetchAllLanguages();
    cronConfigRequest();
  }, []);

  useEffect(() => {
    if (!['add', 'surveyTypes'].includes(id)) surveyTypesRequest && surveyTypesRequest(id);
  }, [id]);

  const onValidate = (newSurveyType) => {
    const languageId = selectedLng.id;
    const questionsBefore = (newSurveyType.introduction_questions_before || []).map((el) => ({ ...el, is_after: false }));
    const questionsAfter = (newSurveyType.introduction_questions_after || []).map((el) => ({ ...el, is_after: true }));
    let newSurveyTypePayload = {
      should_countersign: newSurveyType.should_countersign,
      can_withdraw: newSurveyType.can_withdraw,
      is_ip_displayed: newSurveyType.is_ip_displayed,
      is_clinical_study: newSurveyType.is_clinical_study,
      edit_reason_on_refusal: newSurveyType.edit_reason_on_refusal,
    };
    const translation = _.omitBy(newSurveyType.translation, _.isNil);
    if (!_.isEmpty(translation)) {
      newSurveyTypePayload.translation = {
        ..._.omitBy(newSurveyType.translation, _.isNil),
        title: (newSurveyType.translation.title || '').trim(),
        language_id: newSurveyType.translation.language_id || languageId,
      };
    }
    if (Array.isArray(newSurveyType.event_alerts) && newSurveyType.event_alerts.length) {
      newSurveyTypePayload.event_alerts = [...newSurveyType.event_alerts];
    }

    let introductionQuestions = [...questionsBefore, ...questionsAfter];
    if (introductionQuestions.length) {
      newSurveyTypePayload.introduction_questions = introductionQuestions
        .map((q) => _.omitBy(q, _.isNil))
        .map((q, index) => {
          let translation = {
            ...q.translation,
            introduction_questions_id: q.id,
            language_id: q.language_id || newSurveyType.translation.language_id || languageId,
            pdf_checkbox_label:
              (q.translation.loid || q.translation.pdf_file || q.translation.pdf_url) &&
              q.translation.pdf_checkbox_label &&
              q.translation.pdf_checkbox_label !== ''
                ? q.translation.pdf_checkbox_label
                : null,
          };

          return {
            ..._.omit(q, 'translation'),
            is_active: true,
            displayed_number: index,
            translation: _.omitBy(translation, _.isNil),
          };
        });
    }

    if (form == null) {
      newSurveyTypePayload = {
        ..._.omit(newSurveyTypePayload, ['translation']),
        survey_types_translations: !_.isEmpty(newSurveyTypePayload.translation) ? { data: { ...newSurveyTypePayload.translation } } : null,
        introduction_questions:
          Array.isArray(newSurveyTypePayload.introduction_questions) && newSurveyTypePayload.introduction_questions.length
            ? {
                data: newSurveyTypePayload.introduction_questions.map((q) => ({
                  ...q,
                  introduction_questions_translations: {
                    data: {
                      ...q.introduction_questions_translations,
                    },
                  },
                })),
              }
            : null,
        event_alerts:
          Array.isArray(newSurveyTypePayload.event_alerts) && newSurveyTypePayload.event_alerts.length
            ? {
                data: newSurveyTypePayload.event_alerts.map((event) => ({
                  communication_mode_event_alerts: {
                    data: {
                      communication_mode_id: event.mode,
                    },
                  },
                  template_filename: event.template_filename,
                  event_date_ref: event.event_date_path,
                  event_recipient_ref: event.event_recipient_path,
                  offset_event_alert: {
                    data: {
                      offset: event.offset,
                    },
                  },
                })),
              }
            : null,
      };
      surveyTypesCreateRequest(_.omitBy(newSurveyTypePayload, _.isNil));
    } else {
      newSurveyTypePayload.id = form.id;
      surveyTypesUpdateRequest(newSurveyTypePayload);
    }
  };

  const introQuestions = (form?.introduction_questions || []).map((i) => {
    const translation = i.introduction_questions_translations && i.introduction_questions_translations[selectedLng?.id];
    return {
      id: i.id,
      survey_type_id: id || i?.survey_type_id || null,
      displayed_number: i.displayed_number,
      is_active: i.is_active,
      is_after: i.is_after,
      displayed_if: i.displayed_if,
      translation: {
        id: translation?.id || null,
        language_id: translation?.language_id || selectedLng?.id,
        introduction_questions_id: i.id || null,
        value: translation?.value || null,
        text_error: translation?.text_error || null,
        text_success: translation?.text_success || null,
        title: translation?.title || null,
        pdf_url: translation?.pdf_url || null,
        pdf_checkbox_label: translation?.pdf_checkbox_label || null,
        first_button_label: translation?.first_button_label || null,
        second_button_label: translation?.second_button_label || null,
        loid: translation?.loid || null,
      },
    };
  });

  return (
    <>
      <FormLanguageSelector />
      <SurveyTypesForm
        onValidate={onValidate}
        initialValues={{
          id: form?.id,
          can_withdraw: form?.can_withdraw,
          edit_reason_on_refusal: form?.edit_reason_on_refusal,
          is_clinical_study: form?.is_clinical_study,
          is_ip_displayed: form?.is_ip_displayed,
          should_countersign: form?.should_countersign,
          translation: {
            ...(form?.translations[selectedLng?.id] || null),
            language_id: form?.translations[selectedLng?.id]?.language_id || selectedLng?.id,
          },
          introduction_questions_before: introQuestions.filter((el) => el.is_after === false),
          introduction_questions_after: introQuestions.filter((el) => el.is_after === true),
          event_alerts: (form?.event_alerts || [])
            .sort((a, b) => a.id - b.id)
            .map((event) => ({
              id: event.id,
              mode: event.mode[0].communication_mode.id,
              // mode: event.mode[0].communication_mode.id,
              event_date_path: event.date.id,
              event_recipient_path: event.recipient.id,
              template_filename: TEMPLATES.indexOf(event.template_filename) + 1,
              offset: event.offset.offset.replace('+', ''),
              survey_type_id: id || event?.survey_type_id || null,
            })),
        }}
        // eslint-disable-next-line no-console
        onError={(err) => console.error(err)}
        surveyTypes={Object.values(surveyTypesList || {})}
        selectedLanguageId={selectedLng && selectedLng.id}
        cronConfig={cronConfig}
      />
    </>
  );
};

export default SurveyTypesCreate;

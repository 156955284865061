import './patients.css';

import { Divider, Table, Typography } from 'antd';
import { getYear } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { fromNow } from '../../../services/datefns';
import { phoneFormater, secuNumbFormater } from '../../../utils';
import { DeleteButton, ModifyButton } from '../../components/button';

const { Text } = Typography;

export const ACTIONS = {
  PUBLISH: 'PUBLISH',
  HIDE: 'HIDE',
  DELETE: 'DELETE',
  RESTORE: 'RESTORE',
};

const DataTable = ({ items, reset, loading, actionClick, itemClick, onDelete, onModify }) => {
  const [key, setKey] = useState(0);
  const { t } = useTranslation();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const index = 1;
  const [data, setData] = useState();
  const [pagination, setPagination] = useState({
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50', '100', '200', '500'],
    current: index,
  });

  useEffect(() => {
    setData(items ? [...items] : []);
  }, [items]);

  useEffect(() => {
    if (data && data.length !== key) {
      setKey(data.length);
      setSelectedRowKeys([]);
    }
  }, [data]);

  useEffect(() => {
    setPagination({ current: index });
  }, [reset]);

  const columns = [
    {
      title: 'Prénom',
      dataIndex: 'firstname',
      sorter: (a, b) => a.firstname.localeCompare(b.firstname),
      align: 'center',
      render: (text) => <span>{text}</span>,
    },
    {
      title: 'Nom de naissance',
      dataIndex: 'lastname',
      sorter: (a, b) => a.lastname.localeCompare(b.lastname),
      align: 'center',
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Nom d'usage",
      dataIndex: 'use_name',
      sorter: (a, b) => a.lastname.localeCompare(b.lastname),
      align: 'center',
      render: (text) => <span>{text}</span>,
    },
    {
      title: 'Sexe',
      dataIndex: 'gender',
      align: 'center',
      render: (text) => <span>{t(`gender_${text}`)}</span>,
    },
    {
      title: 'Téléphone',
      dataIndex: 'local_phone_number',
      align: 'center',
      onCell: () => {
        return {
          style: {
            maxWidth: 120,
          },
        };
      },
      render: (text) => <span>{phoneFormater(text)}</span>,
    },
    {
      title: 'Est tuteur',
      dataIndex: 'is_tutor',
      align: 'center',
      render: (text) => <span>{text === true ? 'oui' : 'non'}</span>,
    },
    {
      title: 'Numéro de sécurité sociale',
      dataIndex: 'security_number',
      align: 'center',
      onCell: () => {
        return {
          style: {
            maxWidth: 100,
          },
        };
      },
      render: (text) => <span>{secuNumbFormater(text)}</span>,
    },
    {
      title: 'Adresse',
      dataIndex: 'address',
      align: 'center',
      render: (text) => <span>{text}</span>,
    },
    {
      title: 'Âge',
      dataIndex: 'birthdate',
      sorter: (a, b) => {
        const birthdateA = a.birthdate === null || a.birthdate === undefined ? 0 : getYear(a.birthdate);
        const birthdateB = b.birthdate === null || b.birthdate === undefined ? 0 : getYear(b.birthdate);
        return birthdateA - birthdateB;
      },
      align: 'center',
      render: (birthdate) => {
        return <span>{fromNow(birthdate)}</span>;
      },
    },
    {
      title: '',
      key: 'action',
      width: 100,
      render: (item) => {
        return (
          <span className="ButtonPatients">
            <ModifyButton modify={() => onModify(item)} />
            <Divider type="vertical" />
            <DeleteButton deletion={() => onDelete([item && item.id])} />
          </span>
        );
      },
    },
  ];

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleAction = (actionType) => {
    if (actionClick) {
      actionClick(actionType, selectedRowKeys);
      setSelectedRowKeys([]);
    }
  };

  const handleOnRowClick = (rest, index) => {
    if (itemClick) itemClick(rest);
  };

  const handleReset = () => {
    setSelectedRowKeys([]);
  };

  const actionsRender = () => {
    return (
      <>
        <span>
          <span style={{ marginRight: 6 }}>
            <DeleteButton
              title={'Supprimer'}
              onClick={() => handleAction(ACTIONS.DELETE)}
              onCancel={() => handleReset()}
              deletion={() => onDelete(selectedRowKeys)}
            />
          </span>
          <Text strong>{selectedRowKeys.length} patient(s)</Text>
        </span>
        <br />
        <br />
      </>
    );
  };

  return (
    <div>
      {selectedRowKeys.length > 0 && actionsRender()}
      <Table
        size={'small'}
        showSorterTooltip={false}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={(data || []).map((item) => item && { ...item, key: item.id })}
        loading={loading}
        pagination={pagination}
        onRow={(rest, rowIndex) => {
          return {
            onDoubleClick: (event) => {
              handleOnRowClick(rest, rowIndex);
            },
          };
        }}
        onChange={(page) => {
          setPagination({ current: page.current });
        }}
      />
    </div>
  );
};

export default DataTable;

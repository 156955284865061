import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import LanguageForm from './LanguageForm';

const LanguageCreate = ({ languageList, languageRequest, languageUpsert }) => {
  const { languageId } = useParams();
  const id = languageId && !['languages', 'add'].includes(languageId) ? languageId : null;

  useEffect(() => {
    if (id && languageRequest) {
      languageRequest(id);
    }
  }, [id]);

  const initialValues = useMemo(() => {
    return (languageList && languageList[id]) || {};
  }, [id, languageList]);

  const handleValidate = (language) => {
    language && languageUpsert && languageUpsert({ ...language });
  };

  return (
    <>
      <LanguageForm
        onValidate={handleValidate}
        initialValues={{
          ...initialValues,
        }}
      />
    </>
  );
};

export default LanguageCreate;

import _ from 'lodash';
import { call, put, takeLatest } from 'redux-saga/effects';

import { CityService } from '../../services';
import { default as CityActions, types } from './actions';

const regexPostcode = /(\d{5})/g;

function* fetchCityList({ q, cityType }) {
  yield put(CityActions.fetchBegin());
  let found = q && q.match(regexPostcode);
  if (Array.isArray(found)) found = found.sort((a, b) => b.length - a.length);
  let postcode = (found && found.length && found[0]) || '';
  switch (postcode.length) {
    case 0:
      postcode = '%%';
      break;
    case 4:
      postcode = `0${postcode}`;
      break;
    case 5:
      break;
    default:
      postcode = `${postcode}%`;
  }
  const [error, response] = yield call(CityService.fetchCityListFromPostcode, { postcode });
  if (error || !response) {
    yield put(CityActions.fetchCityListFailure());
    return;
  }
  const cities = _.keyBy(response && response.cities, 'id');
  // if((response && response.cities)) response.cities = response.cities.map(el => ({...el, value: el.id, label: `${el.postcode}, ${el.name}`}))
  switch (cityType) {
    case 'birthplace':
      return yield put(CityActions.fetchBirthplaceListSuccess(cities));
    case 'city':
    default:
      return yield put(CityActions.fetchCityListSuccess(cities));
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [takeLatest(types.CITIES_FETCH_QUERY, fetchCityList)];

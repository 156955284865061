const PERMISSIONS = {
  MANAGE: 'manage',
  CREATE: 'create',
  READ: 'read',
  UPDATE: 'update',
  DELETE: 'delete',
  ACCESS: 'access',
};

const MODEL_NAMES = {
  PATIENT: 'PATIENT',
  FORM: 'FORM',
  FORM_STEP: 'FORM_STEP',
  FORM_REWORDING: 'FORM_REWORDING',
  FORM_QUESTION: 'FORM_QUESTION',
  CONSENT: 'CONSENT',
  STORE_PAGE: 'STORE_PAGE',
  PROFIL_PAGE: 'PROFIL_PAGE',
  MEDIC_TEMPLATES: 'medic_templates',
  STORE_TEMPLATES: 'templates',
  QUESTION_TYPE: 'question_types',
  PATIENT_SECURITY_NUMBER: 'security_number',
  HOME_FILTER: 'HOME_FILTER',
};

const defaultRoles = {
  medic: {
    permissions: [
      {
        action: PERMISSIONS.READ,
        subject: MODEL_NAMES.QUESTION_TYPE,
        conditions: JSON.stringify({
          value: {
            $nin: [
              // 'checkbox_full', 'pdf'
            ],
          },
        }),
      },
      { action: [PERMISSIONS.CREATE, PERMISSIONS.READ], subject: MODEL_NAMES.MEDIC_TEMPLATES },
      { action: PERMISSIONS.READ, subject: MODEL_NAMES.STORE_TEMPLATES },
      { action: PERMISSIONS.MANAGE, subject: MODEL_NAMES.PROFIL_PAGE },
      { action: PERMISSIONS.ACCESS, subject: MODEL_NAMES.STORE_PAGE },
      {
        action: [PERMISSIONS.DELETE, PERMISSIONS.UPDATE],
        subject: MODEL_NAMES.MEDIC_TEMPLATES,
        // eslint-disable-next-line no-template-curly-in-string
        conditions: JSON.stringify({ medic_id: '${user.id}' }),
      },
      {
        desc: 'user can only read following filters on home page',
        action: PERMISSIONS.READ,
        subject: MODEL_NAMES.HOME_FILTER,
        conditions: JSON.stringify({ value: { $nin: [''] } }),
      },
      {
        action: [PERMISSIONS.CREATE, PERMISSIONS.DELETE, PERMISSIONS.UPDATE],
        subject: [MODEL_NAMES.FORM, MODEL_NAMES.FORM_STEP, MODEL_NAMES.FORM_REWORDING, MODEL_NAMES.FORM_QUESTION, MODEL_NAMES.CONSENT],
      },
      {
        desc: 'user can create or delete security number',
        action: [PERMISSIONS.CREATE, PERMISSIONS.UPDATE],
        subject: [MODEL_NAMES.PATIENT_SECURITY_NUMBER],
      },
    ],
  },
  clinicalStudies: {
    permissions: [
      { action: PERMISSIONS.READ, subject: MODEL_NAMES.QUESTION_TYPE, conditions: JSON.stringify({ value: { $nin: [] } }) },
      { desc: 'user can read and create medic templates', action: [PERMISSIONS.CREATE, PERMISSIONS.READ], subject: MODEL_NAMES.MEDIC_TEMPLATES },
      { desc: 'user can read templates from store', action: PERMISSIONS.READ, subject: MODEL_NAMES.STORE_TEMPLATES },
      { desc: 'user can access to Profile page', action: PERMISSIONS.MANAGE, subject: MODEL_NAMES.PROFIL_PAGE },
      {
        desc: 'user can only read following filters on home page',
        action: PERMISSIONS.READ,
        subject: MODEL_NAMES.HOME_FILTER,
        conditions: JSON.stringify({ value: { $nin: [''] } }),
        // conditions: JSON.stringify({ value: { $nin: ['surgery'] } }),
      },
      {
        desc: 'user can delete or update is own forms',
        action: [PERMISSIONS.DELETE, PERMISSIONS.UPDATE],
        subject: MODEL_NAMES.MEDIC_TEMPLATES,
        // eslint-disable-next-line no-template-curly-in-string
        conditions: JSON.stringify({ medic_id: '${user.id}' }),
      },
      {
        desc: 'user can create update or deleted Forms',
        action: [PERMISSIONS.CREATE, PERMISSIONS.DELETE, PERMISSIONS.UPDATE],
        subject: [MODEL_NAMES.FORM, MODEL_NAMES.FORM_STEP, MODEL_NAMES.FORM_REWORDING, MODEL_NAMES.FORM_QUESTION, MODEL_NAMES.CONSENT],
      },
      {
        desc: 'user cannot create or delete security number',
        action: [PERMISSIONS.CREATE, PERMISSIONS.UPDATE],
        subject: [MODEL_NAMES.PATIENT_SECURITY_NUMBER],
        inverted: true,
      },
    ],
  },
  secretary: {
    permissions: [
      {
        action: PERMISSIONS.READ,
        subject: MODEL_NAMES.QUESTION_TYPE,
        conditions: JSON.stringify({
          value: {
            $nin: [
              // 'checkbox_full', 'pdf'
            ],
          },
        }),
      },
      { desc: 'user can read and create medic templates', action: [PERMISSIONS.CREATE, PERMISSIONS.READ], subject: MODEL_NAMES.MEDIC_TEMPLATES },
      { desc: 'user can read templates from store', action: PERMISSIONS.READ, subject: MODEL_NAMES.STORE_TEMPLATES },
      { desc: 'user can access to Profile page', action: PERMISSIONS.MANAGE, subject: MODEL_NAMES.PROFIL_PAGE },
      { desc: 'secretary cannot create medic_templates', action: PERMISSIONS.CREATE, subject: MODEL_NAMES.MEDIC_TEMPLATES, inverted: true },
      {
        desc: 'secretary cannot delete or update is own forms',
        action: [PERMISSIONS.DELETE, PERMISSIONS.UPDATE],
        subject: MODEL_NAMES.MEDIC_TEMPLATES,
        inverted: true,
        // eslint-disable-next-line no-template-curly-in-string
        conditions: JSON.stringify({ medic_id: '${user.id}' }),
      },
      {
        desc: 'user can only read following filters on home page',
        action: PERMISSIONS.READ,
        subject: MODEL_NAMES.HOME_FILTER,
        conditions: JSON.stringify({ value: { $nin: [''] } }),
      },
      {
        desc: 'secretary cannot create update or deleted Forms',
        action: [PERMISSIONS.CREATE, PERMISSIONS.DELETE, PERMISSIONS.UPDATE],
        subject: [MODEL_NAMES.FORM, MODEL_NAMES.FORM_STEP, MODEL_NAMES.FORM_REWORDING, MODEL_NAMES.FORM_QUESTION, MODEL_NAMES.CONSENT],
        inverted: true,
      },
      {
        desc: 'user can create or delete security number',
        action: [PERMISSIONS.CREATE, PERMISSIONS.UPDATE],
        subject: [MODEL_NAMES.PATIENT_SECURITY_NUMBER],
      },
    ],
  },
};

export { defaultRoles };

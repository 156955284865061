import { createAction } from '../../utils';

// Types
export const types = {
  MEDICALTEAMS_REQUEST: 'MEDICALTEAMS_REQUEST',
  MEDICALTEAMS_FAILURE: 'MEDICALTEAMS_FAILURE',
  MEDICALTEAMS_SUCCESS: 'MEDICALTEAMS_SUCCESS',

  MEDICALTEAMS_UPDATE_REQUEST: 'MEDICALTEAMS_UPDATE_REQUEST',
  MEDICALTEAMS_UPDATE_FAILURE: 'MEDICALTEAMS_UPDATE_FAILURE',
  MEDICALTEAMS_UPDATE_SUCCESS: 'MEDICALTEAMS_UPDATE_SUCCESS',

  MEDICALTEAMS_CREATE_REQUEST: 'MEDICALTEAMS_CREATE_REQUEST',
  MEDICALTEAMS_CREATE_FAILURE: 'MEDICALTEAMS_CREATE_FAILURE',
  MEDICALTEAMS_CREATE_SUCCESS: 'MEDICALTEAMS_CREATE_SUCCESS',

  MEDICALTEAMS_DELETE_REQUEST: 'MEDICALTEAMS_DELETE_REQUEST',
  MEDICALTEAMS_DELETE_SUCCESS: 'MEDICALTEAMS_DELETE_SUCCESS',
};

// Actions
export default {
  /* CREATE */
  medicalTeamsCreateRequest: (filters = {}) => createAction(types.MEDICALTEAMS_CREATE_REQUEST, { filters }),
  medicalTeamsCreateSuccess: () => createAction(types.MEDICALTEAMS_CREATE_SUCCESS),

  /* UPDATE */
  medicalTeamsUpdateRequest: (medicalTeam = {}) => createAction(types.MEDICALTEAMS_UPDATE_REQUEST, { medicalTeam }),
  medicalTeamsUpdateSuccess: () => createAction(types.MEDICALTEAMS_UPDATE_SUCCESS),

  /** REQUEST */
  medicalTeamsRequest: (filters = {}) => createAction(types.MEDICALTEAMS_REQUEST, { filters }),
  medicalTeamsSuccess: (medicalTeams) => createAction(types.MEDICALTEAMS_SUCCESS, { medicalTeams }),
  medicalTeamsFailure: () => createAction(types.MEDICALTEAMS_FAILURE),

  medicalTeamsDeleteRequest: (id) => createAction(types.MEDICALTEAMS_DELETE_REQUEST, { id }),
  medicalTeamsDeleteSuccess: (id) => createAction(types.MEDICALTEAMS_DELETE_SUCCESS, { id }),
};

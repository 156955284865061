import { createAction } from '../../utils';

// Types
export const types = {
  PATIENTS_REQUEST: 'PATIENTS_REQUEST',
  PATIENTS_FAILURE: 'PATIENTS_FAILURE',
  PATIENTS_SUCCESS: 'PATIENTS_SUCCESS',

  PATIENTS_UPDATE_REQUEST: 'PATIENTS_UPDATE_REQUEST',
  PATIENTS_UPDATE_FAILURE: 'PATIENTS_UPDATE_FAILURE',
  PATIENTS_UPDATE_SUCCESS: 'PATIENTS_UPDATE_SUCCESS',

  PATIENTS_CREATE_REQUEST: 'PATIENTS_CREATE_REQUEST',
  PATIENTS_CREATE_FAILURE: 'PATIENTS_CREATE_FAILURE',
  PATIENTS_CREATE_SUCCESS: 'PATIENTS_CREATE_SUCCESS',

  PATIENTS_DELETE_REQUEST: 'PATIENTS_DELETE_REQUEST',
  PATIENTS_DELETE_MULTIPLE_REQUEST: 'PATIENTS_DELETE_MULTIPLE_REQUEST',
  PATIENTS_DELETE_SUCCESS: 'PATIENTS_DELETE_SUCCESS',

  RESET_LIST: 'RESET_LIST',

  PATIENTS_EMAIL_REQUEST: 'PATIENTS_EMAIL_REQUEST',
  PATIENTS_EMAIL_SUCCESS: 'PATIENTS_EMAIL_SUCCESS',
  PATIENTS_EMAIL_FAILURE: 'PATIENTS_EMAIL_FAILURE',

  PATIENT_DETAIL_REQUEST: 'patient_PATIENT_DETAIL_REQUEST',
};

// Actions
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  /* CREATE */
  patientsCreateRequest: (patientData = {}) => createAction(types.PATIENTS_CREATE_REQUEST, { patientData }),
  patientsCreateSuccess: () => createAction(types.PATIENTS_CREATE_SUCCESS),

  /* UPDATE */
  patientsUpdateRequest: (patient = {}) => createAction(types.PATIENTS_UPDATE_REQUEST, { patient }),
  patientsUpdateSuccess: () => createAction(types.PATIENTS_UPDATE_SUCCESS),

  /** REQUEST */
  patientsRequest: (patientsIds = null) => createAction(types.PATIENTS_REQUEST, { patientsIds }),
  patientsSuccess: (patients, shouldMerge = false) => createAction(types.PATIENTS_SUCCESS, { patients, shouldMerge }),
  patientsFailure: () => createAction(types.PATIENTS_FAILURE),
  patientDetailRequest: (id) => createAction(types.PATIENT_DETAIL_REQUEST, { id }),

  patientsDeleteRequest: (id) => createAction(types.PATIENTS_DELETE_REQUEST, { id }),
  patientsDeleteMultipleRequest: (ids) => createAction(types.PATIENTS_DELETE_MULTIPLE_REQUEST, { ids }),
  patientsDeleteSuccess: (ids) => createAction(types.PATIENTS_DELETE_SUCCESS, { ids }),

  resetList: () => createAction(types.RESET_LIST, {}),

  checkIfEmailExistsInDb: (email, userId = null) => createAction(types.PATIENTS_EMAIL_REQUEST, { email, userId }),
  startEmailLoader: () => createAction(types.PATIENTS_EMAIL_REQUEST),
  patientsEmailSuccess: (message) => createAction(types.PATIENTS_EMAIL_SUCCESS, { message }),
  patientsEmailFailure: () => createAction(types.PATIENTS_EMAIL_FAILURE),
};

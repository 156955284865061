import { createReducer } from 'reduxsauce';

import { types } from './actions';

const initialState = {
  token: null,
  token_type: null,
  attempts: 0,
  user_blocked: false,
  languageId: 1,
  sideMenuRoutes: [],
};

// region Reducers
const loginAttempt = (state = initialState) => {
  const { attempts } = state;
  return { ...state, attempts: attempts + 1 };
};

const loginFailed = (state = initialState) => {
  const { attempts } = state;
  return { ...state, user_blocked: attempts >= 5 };
};

const loginSuccess = (state = initialState, action) => {
  const { token, refresh_token, token_type, authorization } = action;
  return { ...state, token, refresh_token, token_type, authorization, attempts: 0 };
};

const renewTokenSuccess = (state = initialState, action) => {
  const { token, refresh_token } = action;
  const newState = { ...state };
  if (token) {
    newState.token = token;
  }
  if (refresh_token) {
    newState.refresh_token = refresh_token;
  }
  return { ...newState };
};

const setSideMenuRoutes = (state = initialState, action) => {
  const { routes } = action;
  return {
    ...state,
    sideMenuRoutes: routes.reduce((acc, r) => {
      return r.sub ? [...acc, ...r.sub] : [...acc, r];
    }, []),
  };
};

const logout = () => {
  return { ...initialState };
};

const setLoginRedirectUrl = (state = { ...initialState }, action = {}) => {
  let { url } = action;
  return {
    ...state,
    loginRedirectUrl: url !== '/' ? url : null,
  };
};

const clearLoginRedirectUrl = (state = { ...initialState }) => {
  return {
    ...state,
    loginRedirectUrl: null,
  };
};

const setRedirectUrl = (state = { ...initialState }, action = {}) => {
  let { url } = action;
  return {
    ...state,
    redirectUrl: url,
  };
};

const clearRedirectUrl = (state = { ...initialState }) => {
  return {
    ...state,
    redirectUrl: null,
  };
};

export default createReducer(initialState, {
  [types.LOGIN_REQUEST]: loginAttempt,
  [types.LOGIN_REFRESH]: loginAttempt,
  [types.LOGOUT]: logout,
  [types.LOGIN_FAILURE]: loginFailed,
  [types.LOGIN_SUCCESS]: loginSuccess,
  [types.SET_SIDE_MENU_ROUTES]: setSideMenuRoutes,

  [types.RENEW_TOKEN_SUCCESS]: renewTokenSuccess,

  [types.SET_LOGIN_REDIRECT_URL]: setLoginRedirectUrl,
  [types.CLEAR_LOGIN_REDIRECT_URL]: clearLoginRedirectUrl,

  [types.SET_REDIRECT_URL]: setRedirectUrl,
  [types.CLEAR_REDIRECT_URL]: clearRedirectUrl,
});

import _ from 'lodash';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { SpecialitiesService } from '../../services';
import { AuthActions } from '../auth';
import { LoaderActions } from '../loader';
import { SnackActions } from '../snackBar';
import { default as SpecialityActions, types } from './actions';

function* specialitiesRequest({ filters }) {
  const { languageId } = yield select((state) => state.auth);
  const id = (filters && filters.id) || null;
  const [error, data] = id ? yield call(SpecialitiesService.specialities, id) : yield call(SpecialitiesService.getSpecialities);
  if (!error && data) {
    const specialities = data.specialities.map((sp) => {
      const selectedUserLanguage =
        (Array.isArray(sp.specialities_translations) && sp.specialities_translations.find((s) => s.language_id === languageId)) ||
        sp.specialities_translations.find((s) => s.language_id === 1) ||
        sp.specialities_translations[0];
      return {
        ...sp,
        translations: _.keyBy(sp.specialities_translations, 'language_id'),
        translation: selectedUserLanguage,
      };
    });
    yield put(SpecialityActions.specialitiesSuccess(specialities));
  } else {
    yield put(SnackActions.displayError('speciality_list_error'));
    yield put(SpecialityActions.specialitiesFailure());
  }
}

function* specialitiesUpdateRequest({ speciality }) {
  yield put(LoaderActions.loading());
  const specialities = _.omit(speciality, ['specialities_translations', 'translation']);
  const specialitiesTranslations = { ...speciality.translation, speciality_id: speciality.id };
  // update
  const [error, data] = yield call(SpecialitiesService.specialitiesUpdate, speciality.id, _.omit(specialities, ['id']), specialitiesTranslations);
  if (data && !error) {
    yield put(SnackActions.displayInfo('speciality_modify_success'));
    yield put(SpecialityActions.specialitiesUpdateSuccess({}));
    yield put(SpecialityActions.specialitiesRequest({}));
  } else {
    yield put(SnackActions.displayError('speciality_modify_error'));
  }
  yield put(LoaderActions.loaded());
  yield put(AuthActions.setRedirectUrl('/specialities'));
}

function* specialitiesCreateRequest({ speciality }) {
  yield put(LoaderActions.loading());
  const payload = {
    id: speciality.id,
    specialities_translations: {
      data: { ...speciality.translation },
    },
  };
  const [error, data] = yield call(SpecialitiesService.specialitiesCreate, payload);

  if (data && !error) {
    yield put(SnackActions.displayInfo('speciality_create_success'));
    yield put(SpecialityActions.specialitiesCreateSuccess({}));
    yield put(SpecialityActions.specialitiesRequest({ id: data.insert_specialities.returning[0].id }));
  } else {
    yield put(SnackActions.displayError('speciality_create_error'));
  }
  yield put(LoaderActions.loaded());
  yield put(AuthActions.setRedirectUrl('/specialities'));
}

function* specialitiesDeleteRequest({ ids }) {
  yield put(LoaderActions.loading());
  const [error, data] = yield call(SpecialitiesService.specialitiesDelete, ids);
  if (data && !error) {
    yield put(SnackActions.displayInfo('speciality_delete_success'));
    yield put(SpecialityActions.specialitiesDeleteSuccess(ids));
    yield put(SpecialityActions.specialitiesRequest({}));
  } else {
    yield put(SnackActions.displayError('speciality_delete_error'));
  }
  yield put(LoaderActions.loaded());
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  takeLatest(types.SPECIALITIES_REQUEST, specialitiesRequest),
  takeLatest(types.SPECIALITIES_UPDATE_REQUEST, specialitiesUpdateRequest),
  takeLatest(types.SPECIALITIES_CREATE_REQUEST, specialitiesCreateRequest),
  takeLatest(types.SPECIALITIES_DELETE_REQUEST, specialitiesDeleteRequest),
];

import { methods } from '../utils';
import { authenticatedMutation, authenticatedQuery, authenticatedService } from './middleware';
const { POST, DELETE } = methods;

const routes = {
  upsert: process.env.REACT_APP_BASE_API_URL + '/V2/medicTemplates',
  generatePDF: (id) => process.env.REACT_APP_SIGN_API_URL + `/pdf?survey_id=${id}`,
  duplicateTemplate: (id) => process.env.REACT_APP_BASE_API_URL + `/V2/medicTemplates/${id}/duplicate`,
  medicTemplate: (id) => process.env.REACT_APP_BASE_API_URL + `/V2/medicTemplates/${id}`,
};

const queries = {
  getAll: () => `
    query getAllMedicTemplates {
      medic_templates {
        id
        is_published
        store_id
        medic_templates_translations {
          language_id
          title
          description
        }
        medical_team {
          title
        }
        medic_id
        medic {
          id
          firstname
          lastname
        }
        type_id
        speciality_id
        side
        radius
        created_at
      }
    }
  `,
  getOne: () => `
    query GetOneMedicTemplates($id: Int!, $lngId: Int = 1) {
      medic_templates(where: {id: {_eq: $id}}) {
        id
        medic_templates_translations {
          id
          language_id
          title
          description
          survey_model_files_id
        }
        medic_id
        medical_teams_id
        type_id
        speciality_id
        side
        radius
        is_published
        survey_pdfs(where: {id: {_eq: $id}}) {
          id
          loid
          name
          language_id
          number
        }
        medic_template_steps(order_by: {step: {number: asc}}) {
          step {
            id
            number
            steps_translations(where: {language_id: {_eq: $lngId}}) {
              id
              language_id
              title
            }
            questions(order_by: {number: asc}) {
              id
              number
              questions_translations(where: {language_id: {_eq: $lngId}}) {
                id
                language_id
                question
                desc
                data
                rewording
                texte_pdf
                incompris
              }
              type_id
            }
          }
        }
      }
    }
  `,
};

const mutations = {
  deleteSome: () => `
    mutation DeleteSome($ids: [Int!]!) {
      delete_questions(where: {step: {medic_template_steps: {medic_template_id: {_in: $ids}}}}) {
        returning {
          id
        }
      }
      delete_steps(where: {medic_template_steps: {medic_template_id: {_in: $ids}}}) {
        returning {
          id
        }
      }
      delete_medic_template_steps(where: {medic_template_id: {_in: $ids}}) {
        returning {
          medic_template_id
        }
      }
      delete_survey_pdfs(where: {medic_template_id: {_in: $ids}}) {
        returning {
          id
        }
      }
      delete_medic_templates(where: {id: {_in: $ids}}) {
        returning {
          id
        }
      }
    }
  `,
  duplicateSome: () => `
    mutation DuplicateSome($ids: [Int!]!) {
      delete_questions(where: {step: {medic_template_steps: {medic_template_id: {_in: $ids}}}}) {
        returning {
          id
        }
      }
      delete_steps(where: {medic_template_steps: {medic_template_id: {_in: $ids}}}) {
        returning {
          id
        }
      }
      delete_medic_template_steps(where: {medic_template_id: {_in: $ids}}) {
        returning {
          medic_template_id
        }
      }
      delete_survey_pdfs(where: {medic_template_id: {_in: $ids}}) {
        returning {
          id
        }
      }
      delete_medic_templates(where: {id: {_in: $ids}}) {
        returning {
          id
        }
      }
    }
  `,
};

export default {
  getAll: () => authenticatedQuery(queries.getAll()),
  getOne: (id) => authenticatedQuery(queries.getOne(), {}, { id }),
  upsertTemplate: (payload) => authenticatedService(POST, routes.upsert, payload, {}, {}),
  deleteSome: (ids) => authenticatedMutation(mutations.deleteSome(), {}, { ids }),
  delete: (id) => authenticatedService(DELETE, routes.medicTemplate(id), {}, {}),
  duplicateSome: (ids) => authenticatedMutation(mutations.duplicateSome(), {}, { ids }),
  generatePDF: (surveyID) => authenticatedService(POST, routes.generatePDF(surveyID), { payload: { isConsent: false } }),

  duplicateTemplate: (id) => authenticatedService(POST, routes.duplicateTemplate(id), {}, {}),
};

import { createReducer } from 'reduxsauce';

import { types } from './actions';

const initialState = {
  list: {},
  loading: false,
  hasMore: true,
};

const startLoader = (state = initialState) => ({ ...state, loading: true });

const stopLoader = (state = initialState) => ({ ...state, loading: false });

const questionsSuccess = (state = initialState, action) => {
  const { questions } = action;
  const data = {};
  questions.forEach((question) => {
    data[question.id] = question;
  });
  return { ...state, list: data, loading: false, hasMore: questions.length > 0 };
};

const questionsCreateSuccess = (state = initialState, action) => {
  const { questions } = action;
  const { list } = state;

  const tmp = Object.values(list);
  const data = {};
  tmp.push(questions);
  tmp.forEach((question) => {
    data[question.id] = question;
  });

  return { ...state, list: data, loading: false, hasMore: questions.length > 0 };
};

const questionsUpdateSuccess = (state = initialState, action) => {
  const { questions } = action;
  const { list } = state;

  const data = { ...list };
  data[questions.id] = questions;

  return { ...state, list: data, loading: false, hasMore: questions.length > 0 };
};

const questionClear = (state = initialState, action) => {
  return initialState;
};

const questionsDeleteSuccess = (state = initialState, action) => {
  const { id } = action;
  const { list } = state;
  delete list[id];

  const questions = Object.values(list);
  const data = {};

  questions.forEach((question) => {
    data[question.id] = question;
  });
  return { ...state, list: data, loading: false, hasMore: questions.length > 0 };
};

export default createReducer(initialState, {
  [types.QUESTION_CLEAR]: questionClear,

  [types.QUESTIONS_CREATE_REQUEST]: startLoader,
  [types.QUESTIONS_CREATE_SUCCESS]: questionsCreateSuccess,

  [types.QUESTIONS_UPDATE_REQUEST]: startLoader,
  [types.QUESTIONS_UPDATE_SUCCESS]: questionsUpdateSuccess,

  [types.QUESTIONS_REQUEST]: startLoader,
  [types.QUESTIONS_SUCCESS]: questionsSuccess,
  [types.QUESTIONS_FAILURE]: stopLoader,

  [types.QUESTIONS_DELETE_REQUEST]: startLoader,
  [types.QUESTIONS_DELETE_SUCCESS]: questionsDeleteSuccess,
});

import { resettableReducer } from 'reduxsauce';

import { AppKeyReducers, AppKeySagas } from './appKeys';
import { AuthReducers, AuthSagas } from './auth';
import { CityReducers, CitySagas } from './city';
import { ClinicReducers, ClinicSagas } from './clinics';
import { ClinicsGroupsReducers, ClinicsGroupsSagas } from './clinicsGroups';
import { CommonTypes } from './common';
import { ConsentReducers, ConsentSagas } from './consents';
import { CredentialReducers, CredentialSagas } from './credential';
import { CronReducers, CronSagas } from './cron';
import { EmailsReducers, EmailsSagas } from './emails';
import { FormsReducers } from './forms';
import { InterventionReducers, InterventionSagas } from './interventions';
import { IntroductionQuestionReducers } from './introduction_questions';
import { LanguageReducers, LanguageSagas } from './languages';
import { LegalsReducers, LegalsSagas } from './legal';
import { LoaderReducers } from './loader';
import { MedicalTeamReducers, MedicalTeamSagas } from './medicalTeams';
import { MedicReducers, MedicSagas } from './medics';
import { MedicTemplateReducers, MedicTemplateSagas } from './medicTemplates';
import { PatientReducers, PatientSagas } from './patients';
import { QuestionBasicsReducers, QuestionBasicsSagas } from './questionBasics';
import { QuestionReducers, QuestionSagas } from './questions';
import { QuestionTypesReducers, QuestionTypesSagas } from './questionTypes';
import { RoleReducers, RoleSagas } from './roles';
import { SftpCredentialReducers, SftpCredentialSagas } from './sftpCredential';
import { SmsReducers, SmsSagas } from './sms';
import { SnackReducers, SnackSagas } from './snackBar';
import { SpecialityReducers, SpecialitySagas } from './specialities';
import { StepReducers, StepSagas } from './steps';
import { SurveyModelFilesReducers, SurveyModelFilesSagas } from './surveyModelFiles';
import { SurveyReducers, SurveySagas } from './surveys';
import { SurveyTypesReducers, SurveyTypesSagas } from './surveyTypes';
import { TemplateReducers, TemplateSagas } from './templates';
import { UserReducers, UserSagas } from './users';

// Specify reset action type rule
const resettable = resettableReducer(CommonTypes.RESET_REDUCERS);

// Merge our redux together
const reducers = {
  appKeys: resettable(AppKeyReducers),
  auth: resettable(AuthReducers),
  legals: resettable(LegalsReducers),
  city: resettable(CityReducers),
  clinics: resettable(ClinicReducers),
  clinicsGroups: resettable(ClinicsGroupsReducers),
  consents: resettable(ConsentReducers),
  credential: resettable(CredentialReducers),
  cron: resettable(CronReducers),
  forms: resettable(FormsReducers),
  interventions: resettable(InterventionReducers),
  IntroductionQuestion: resettable(IntroductionQuestionReducers),
  language: resettable(LanguageReducers),
  loader: resettable(LoaderReducers),
  medics: resettable(MedicReducers),
  medicalTeams: resettable(MedicalTeamReducers),
  medicTemplates: resettable(MedicTemplateReducers),
  patients: resettable(PatientReducers),
  questions: resettable(QuestionReducers),
  questionBasics: resettable(QuestionBasicsReducers),
  questionTypes: resettable(QuestionTypesReducers),
  roles: resettable(RoleReducers),
  sms: resettable(SmsReducers),
  emails: resettable(EmailsReducers),
  sftp: resettable(SftpCredentialReducers),
  snackBar: SnackReducers,
  specialities: resettable(SpecialityReducers),
  steps: resettable(StepReducers),
  surveys: resettable(SurveyReducers),
  surveyModelFiles: resettable(SurveyModelFilesReducers),
  surveyTypes: resettable(SurveyTypesReducers),
  templates: resettable(TemplateReducers),
  users: resettable(UserReducers),
};

export const rootSaga = [
  ...AuthSagas,
  ...SnackSagas,
  ...MedicSagas,
  ...PatientSagas,
  ...ClinicSagas,
  ...ClinicsGroupsSagas,
  ...CredentialSagas,
  ...SurveySagas,
  ...TemplateSagas,
  ...MedicTemplateSagas,
  ...ConsentSagas,
  ...InterventionSagas,
  ...MedicalTeamSagas,
  ...SurveyTypesSagas,
  ...QuestionTypesSagas,
  ...StepSagas,
  ...SpecialitySagas,
  ...QuestionSagas,
  ...QuestionBasicsSagas,
  ...LegalsSagas,
  ...CitySagas,
  ...CronSagas,
  ...LanguageSagas,
  ...RoleSagas,
  ...SmsSagas,
  ...EmailsSagas,
  ...AppKeySagas,
  ...SftpCredentialSagas,
  ...UserSagas,
  ...SurveyModelFilesSagas,
  // ...IntroductionQuestionSagas,
];

export default reducers;

import { createAction } from '../../utils';

// Types
export const types = {
  SURVEY_TYPES_REQUEST: 'SURVEY_TYPES_REQUEST',
  SURVEY_TYPES_FAILURE: 'SURVEY_TYPES_FAILURE',
  SURVEY_TYPES_SUCCESS: 'SURVEY_TYPES_SUCCESS',

  SURVEY_TYPES_UPDATE_REQUEST: 'SURVEY_TYPES_UPDATE_REQUEST',
  SURVEY_TYPES_UPDATE_FAILURE: 'SURVEY_TYPES_UPDATE_FAILURE',
  SURVEY_TYPES_UPDATE_SUCCESS: 'SURVEY_TYPES_UPDATE_SUCCESS',

  SURVEY_TYPES_CREATE_REQUEST: 'SURVEY_TYPES_CREATE_REQUEST',
  SURVEY_TYPES_CREATE_FAILURE: 'SURVEY_TYPES_CREATE_FAILURE',
  SURVEY_TYPES_CREATE_SUCCESS: 'SURVEY_TYPES_CREATE_SUCCESS',

  SURVEY_TYPES_DELETE_REQUEST: 'SURVEY_TYPES_DELETE_REQUEST',
  SURVEY_TYPES_DELETE_SUCCESS: 'SURVEY_TYPES_DELETE_SUCCESS',
};

// Actions
export default {
  /* CREATE */
  surveyTypesCreateRequest: (surveyTypes = {}) => createAction(types.SURVEY_TYPES_CREATE_REQUEST, { surveyTypes }),
  surveyTypesCreateSuccess: () => createAction(types.SURVEY_TYPES_CREATE_SUCCESS),

  /* UPDATE */
  surveyTypesUpdateRequest: (surveyType = {}) => createAction(types.SURVEY_TYPES_UPDATE_REQUEST, { surveyType }),
  surveyTypesUpdateSuccess: () => createAction(types.SURVEY_TYPES_UPDATE_SUCCESS),

  /** REQUEST */
  surveyTypesRequest: (filters = {}) => createAction(types.SURVEY_TYPES_REQUEST, { filters }),
  surveyTypesSuccess: (surveyTypes) => createAction(types.SURVEY_TYPES_SUCCESS, { surveyTypes }),
  surveyTypesFailure: () => createAction(types.SURVEY_TYPES_FAILURE),

  surveyTypesDeleteRequest: (id) => createAction(types.SURVEY_TYPES_DELETE_REQUEST, { id }),
  surveyTypesDeleteSuccess: (id) => createAction(types.SURVEY_TYPES_DELETE_SUCCESS, { id }),
};

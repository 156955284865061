import _ from 'lodash';

import { authenticatedMutation, authenticatedQuery } from './middleware';

/**
 * GRAPHQL QUERIES
 */
const queries = {
  getSurveyTypes: () => `
    query SurveyTypes {
      survey_types {
        id
        survey_types_translations {
          id
          language_id
          label
          label_a
          label_your
          title
        }
        should_countersign
        is_ip_displayed
        should_countersign
        is_clinical_study
        edit_reason_on_refusal
        can_withdraw
        introduction_questions(order_by: {displayed_number: asc}) {
          id
          is_active
          is_after
          displayed_number
          survey_type_id
          introduction_questions_translations {
            id
            language_id
            title
            text_success
            text_error
            introduction_questions_id
            pdf_url
            loid
          }
        }
      }
    }
  `,
  surveyTypes: () => `
    query SurveyTypes($id: Int!) {
      survey_types (where: {id: {_eq: $id}}) {
        id
        survey_types_translations {
          id
          language_id
          label
          label_a
          label_your
          title
        }
        should_countersign
        can_withdraw
        is_ip_displayed
        should_countersign
        is_clinical_study
        edit_reason_on_refusal
        introduction_questions(order_by: {displayed_number: asc}) {
          id
          is_active
          is_after
          displayed_number
          survey_type_id
          displayed_if
          introduction_questions_translations {
            id
            introduction_questions_id
            value
            title
            text_success
            text_error
            pdf_url
            language_id
            pdf_checkbox_label
            first_button_label
            second_button_label
            loid
          }
        }
        event_alerts {
          id
          template_filename
          mode: communication_mode_event_alerts {
            id
            communication_mode {
              mode
              id
            }
          }
          offset: offset_event_alert {
            offset
            id
          }
          recipient: recipient_event_ref {
            event_recipient_path
            id
          }
          date: eventDateRefByEventDateRef {
            event_date_path
            id
          }
        }
      }
    }
  `,
  getSurveyTypetranslationCount: () => `
    query GetSurveyTypetranslationCount($languageId: Int!, $surveyTypeId: Int!) {
      survey_types_translations_aggregate(where: {_and: {language_id: {_eq: $languageId}, survey_type_id: {_eq: $surveyTypeId}}}) {
        aggregate {
          count(distinct: true)
        }
      }
    }
  `,
};

/**
 * GRAPHQL MUTATION
 *
 */
const mutations = {
  surveyTypesCreate: () => `
    mutation SurveyTypesCreate($objects: [survey_types_insert_input!]!) {
      insert_survey_types(objects: $objects) {
        returning {
          id
        }
      }
    }
  `,
  surveyTypesUpdate: () => `
    mutation SurveyTypesUpdate($id: Int!, $value: survey_types_set_input!) {
      update_survey_types(
        where: {id: {_eq: $id}}, 
        _set: $value
      ) {
        returning {
          id
        }
      }
    }
  `,
  surveyTypesDelete: () => `
    mutation SurveyTypesDelete($id: Int!) {
      delete_questions(
        where: {
          step: {
            survey: { survey_type: { id: { _eq: $id } }, _and: { is_signed: { _eq: false } } }
          }
        }
      ) {
        returning {
          id
        }
      }
      delete_steps(
        where: {
          survey: { survey_type: { id: { _eq: $id } }, _and: { is_signed: { _eq: false } } }
        }
      ) {
        returning {
          id
        }
      }
      delete_surveys(
        where: { survey_type: { id: { _eq: $id } }, _and: { is_signed: { _eq: false } } }
      ) {
        returning {
          id
        }
      }
      update_surveys(where: { survey_type: { id: { _eq: $id } } }, _set: { is_archived: true }) {
        returning {
          id
        }
      }
      delete_introduction_questions_translations(where: {introduction_question: {survey_type_id: {_eq: $id}}}) {
        returning {
          id
        }
      }
      delete_introduction_questions(where: {survey_type: {id: {_eq: $id}}}) {
        returning {
          id
        }
      }
      delete_communication_mode_event_alerts(where: {event_alert: {survey_type_id: {_eq: $id}}}) {
        returning {
          id
        }
      }
      delete_event_alerts(where: {survey_type_id: {_eq: $id}}) {
        returning {
          id
        }
      }
      delete_survey_types_translations(where: { survey_type_id: {_eq: $id}})  {
        returning {
          id
        }
      }
      delete_survey_types(where: { id: { _eq: $id } }) {
        returning {
          id
        }
      }
    }
  `,
  surveyTypesUpdateTranslation: () => `
    mutation UpdateSurveyTypesTranslation($languageId: Int = 10, $surveyTypeId: Int = 10, $value: survey_types_translations_set_input!) {
      update_survey_types_translations(where: {_and: {survey_type_id: {_eq: $surveyTypeId}, language_id: {_eq: $languageId}}}, _set: $value) {
        returning {
          id
        }
      }
    }
  `,
  surveyTypesCreateTranslation: () => `
    mutation MyMutation($surveyTypesTranslation: [survey_types_translations_insert_input!]!) {
      insert_survey_types_translations(objects: $surveyTypesTranslation) {
        returning {
          id
        }
      }
    }
  `,
};

export default {
  getSurveyTypes: () => authenticatedQuery(queries.getSurveyTypes(), {}, {}),
  surveyTypes: (id = null) => authenticatedQuery(queries.surveyTypes(), {}, { id }),
  surveyTypesCreate: (surveyTypes) => authenticatedMutation(mutations.surveyTypesCreate(), {}, { objects: surveyTypes }),
  surveyTypesUpdate: (surveyTypes) =>
    authenticatedMutation(
      mutations.surveyTypesUpdate(),
      {},
      { value: _.omit(surveyTypes, ['id', 'introduction_questions', 'event_alerts']), id: surveyTypes.id },
    ),
  surveyTypesDelete: (id) => authenticatedMutation(mutations.surveyTypesDelete(), {}, { id }),
  surveyTypesUpdateTranslation: (surveyTypesTranslation, surveyTypeId, languageId) =>
    authenticatedMutation(mutations.surveyTypesUpdateTranslation(), {}, { value: _.omit(surveyTypesTranslation, ['id']), surveyTypeId, languageId }),
  getSurveyTypetranslationCount: (languageId, surveyTypeId) =>
    authenticatedQuery(queries.getSurveyTypetranslationCount(), {}, { languageId, surveyTypeId }),
  surveyTypesCreateTranslation: (surveyTypesTranslation) =>
    authenticatedQuery(mutations.surveyTypesCreateTranslation(), {}, { surveyTypesTranslation }),
};

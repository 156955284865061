import './surveys.css';

import { EyeOutlined } from '@ant-design/icons';
import { Button, Divider, Table, Typography } from 'antd';
import { format, parseISO } from 'date-fns';
import React, { useEffect, useState } from 'react';

import { parseDate } from '../../../services/datefns';
import { normalizeString } from '../../../utils';
import { DeleteButton, DuplicateButton, ModifyButton } from '../../components/button';
import EyeButton from '../../components/button/EyeButton';

const { Text } = Typography;

const DataTable = ({ items, reset, loading, actionClick, itemClick, onDelete, onModify, onDuplicate, onPublished, resetRowKeys }) => {
  const [key, setKey] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const index = 1;
  const [pagination, setPagination] = useState({
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50', '100', '200', '500'],
    current: index,
  });

  const data = items || [];

  if (data.length !== key) {
    setKey(data.length);
    setSelectedRowKeys([]);
  }

  useEffect(() => {
    setPagination({ ...pagination, current: 1 });
  }, [reset]);

  useEffect(() => {
    if (resetRowKeys) {
      setSelectedRowKeys([]);
    }
  }, [resetRowKeys]);

  const columns = [
    {
      title: 'Titre',
      dataIndex: 'title',
      sorter: (a, b) => a.title.localeCompare(b.title),
      align: 'center',
      render: (text) => <span>{text}</span>,
    },
    {
      title: 'Type du formulaire',
      dataIndex: 'survey_type',
      sorter: (a, b) => {
        const valueA = normalizeString((a.survey_type && a.survey_type.value) || '');
        const valueB = normalizeString((b.survey_type && b.survey_type.value) || '');

        return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
      },
      align: 'center',
      render: (text) => {
        if (text == null || text === undefined) {
          return <span>Non spécifié</span>;
        } else {
          return <span>{text.value}</span>;
        }
      },
    },
    {
      title: 'Assigné',
      dataIndex: 'medical_team',
      render: (assigned) => {
        return <span>{assigned && assigned.title ? `${assigned.title}` : 'Administrateur'}</span>;
      },
    },
    {
      title: 'Crée par',
      dataIndex: 'store_id',
      render: (storeID, row) => {
        return (
          <span>
            {row.medic ? `${`${row.medic.firstname} ${row.medic.lastname}`}${storeID === undefined || storeID > 0 ? ' (Store)' : ''}` : 'Store'}
          </span>
        );
      },
    },
    {
      title: "Date d'ajout",
      dataIndex: 'created_at',
      sorter: (a, b) => parseISO(a.created_at).getTime() - parseISO(b.created_at).getTime(),
      align: 'center',
      render: (text) => <span>{parseISO ? format(parseDate(text), 'dd-MM-yyyy') : ''}</span>,
    },
    {
      title: 'Publié',
      dataIndex: 'is_published',
      sorter: (a, b) => a.is_published > b.is_published,
      align: 'center',
      render: (text, record, index) =>
        !record.medic && (
          <span>
            <EyeButton confirm eye={text} onClick={() => handleOnPublished([`${record.__typename}#${record.id}`], !record.is_published)} />
          </span>
        ),
    },
    {
      title: '',
      key: 'action',
      width: 150,
      render: (item) => {
        return (
          <span className="ButtonSurveys">
            <ModifyButton modify={() => onModify(item)} tooltipTitle={'Modifier'} />
            <Divider type="vertical" />
            <DeleteButton deletion={() => onDelete(item)} tooltipTitle={'Supprimer'} />
            <Divider type="vertical" />
            <DuplicateButton onClick={() => onDuplicate(item)} tooltipTitle={'Dupliquer'} />
          </span>
        );
      },
    },
  ];

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleOnRowClick = (rest, index) => {
    if (itemClick) itemClick(rest);
  };

  const handleReset = () => {
    setSelectedRowKeys([]);
  };

  const handleOnPublished = (elements, published) => {
    if (onPublished) {
      onPublished(elements, published);
    }
  };

  const actionsRender = () => {
    return (
      <>
        <span>
          <span style={{ marginRight: 6 }}>
            <DeleteButton title={'Supprimer'} onCancel={() => handleReset()} deletion={() => onDelete(selectedRowKeys)} />
          </span>
          <Text strong>{selectedRowKeys.length} formulaire(s)</Text>
        </span>
        <br />
        <br />
        <span>
          <span style={{ marginRight: 6 }}>
            <Button shape="circle" type="primary" onClick={() => onPublished(selectedRowKeys, true)} icon={<EyeOutlined />} />
            {/* <Divider type="vertical" />
            <Button shape="circle" onClick={() => onPublished(selectedRowKeys, false)} icon={<EyeInvisibleOutlined />} /> */}
          </span>
          <Text strong> {data.filter((d) => !d.store_id && selectedRowKeys.includes(d.id)).length} formulaire(s) </Text>
        </span>
        <br />
        <br />
      </>
    );
  };

  return (
    <div>
      {selectedRowKeys.length > 0 && actionsRender()}
      <Table
        size={'small'}
        showSorterTooltip={false}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data.map((item) => ({ ...item, key: `${item.__typename}#${item.id}` }))}
        loading={loading}
        pagination={pagination}
        onRow={(rest, rowIndex) => {
          return {
            onDoubleClick: (event) => {
              handleOnRowClick(rest, rowIndex);
            },
          };
        }}
        onChange={(page) => {
          setPagination({ current: page.current });
        }}
      />
    </div>
  );
};

export default DataTable;

import { createReducer } from 'reduxsauce';

import { types } from './actions';

const initialState = {
  list: {},
  loading: false,
  hasMore: true,
};

const startLoader = (state = initialState) => ({ ...state, loading: true });

const stopLoader = (state = initialState) => ({ ...state, loading: false });

const clinicsGroupsSuccess = (state = initialState, action) => {
  const { clinicsGroup } = action;
  const { list } = state;
  const data = list;
  clinicsGroup.forEach((group) => {
    data[group.id] = group;
  });
  return { ...state, list: data, loading: false, hasMore: clinicsGroup.length > 0 };
};

const clinicDeleteSuccess = (state = initialState, action) => {
  const { id } = action;
  const { list } = state;
  delete list[id];
  const clinicsGroup = Object.values(list);
  const data = {};
  clinicsGroup.forEach((group) => {
    data[group.id] = group;
  });
  return { ...state, list: data, loading: false, hasMore: clinicsGroup.length > 0 };
};

export default createReducer(initialState, {
  [types.CLINICS_GROUP_CREATE_REQUEST]: startLoader,
  [types.CLINICS_GROUP_CREATE_SUCCESS]: stopLoader,

  [types.CLINICS_GROUP_UPDATE_REQUEST]: startLoader,
  [types.CLINICS_GROUP_UPDATE_SUCCESS]: stopLoader,

  [types.CLINICS_GROUPS_REQUEST]: startLoader,
  [types.CLINICS_GROUPS_SUCCESS]: clinicsGroupsSuccess,
  [types.CLINICS_GROUPS_FAILURE]: stopLoader,

  [types.CLINICS_GROUP_DELETE_REQUEST]: startLoader,
  [types.CLINICS_GROUPS_DELETE_SUCCESS]: startLoader,
  [types.CLINICS_GROUPS_DELETE_SUCCESS]: clinicDeleteSuccess,
});

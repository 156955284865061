import { Button, Card, Divider, Form, Input, Select } from 'antd';
import axios from 'axios';
import { PhoneNumberType } from 'google-libphonenumber';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { digitRegex } from '../../../utils/regex';
import { normalizeString, validEmailRegex } from '../../../utils/utils';
import * as Rule from '../../components/form/rule';
import PhoneInput from '../../components/formV3/controllers/PhoneInput';
import ReactDatePicker from '../../components/ReactDatePicker/ReactDatePicker';
import ReactSelect from '../../components/ReactSelect/ReactSelect';
import PaswdForm from '../PaswdForm';

const PatientsForm = ({
  onValidate,
  medics,
  patients,
  isEmailAvailable,
  checkIfEmailExistsInDb,
  cityList,
  birthplaceList,
  fetchCityList,
  clearCityList,
  medicalTeams,
  languageList,
  token,
  initialValues,
}) => {
  const { t } = useTranslation();
  const [isTutor, setIsTutor] = useState(false);
  const [isTutored, setIsTutored] = useState(false);
  const [postcode, setPostcode] = useState();
  const [birthplacePostcode, setBirthplacePostcode] = useState();
  const [medicList, setMedicList] = useState();
  const [form] = Form.useForm();

  useEffect(() => {
    if (initialValues) {
      setIsTutor(_.get(initialValues, 'is_tutor', false));
      setIsTutored(initialValues.tutorsByPatientId && initialValues.tutorsByPatientId.length > 0);
    }
  }, [initialValues]);

  useEffect(() => {
    if (initialValues) {
      form.resetFields();
    }
  }, [initialValues]);

  const onFinish = (values) => {
    onValidate(values);
  };

  const DSC = 'descending';

  function sortByText(a, b, order = DSC, key = 'lastname') {
    const diff = _.deburr(a[key].toLowerCase()).localeCompare(_.deburr(b[key].toLowerCase()));

    if (order === DSC) {
      return diff;
    }

    return -1 * diff;
  }

  const cityOptions = _.map(cityList, (city) => ({
    value: city.id,
    label: city.name,
  }));

  const birthplaceOptions = _.map(birthplaceList, (city) => ({
    value: city.id,
    label: city.name,
  }));

  const handlePostcodeChange = (value, cityType) => {
    switch (cityType) {
      case 'birthplace':
        if (value !== birthplacePostcode) {
          setBirthplacePostcode(value);
          form.setFieldsValue({ birthplace_id: null, birthplace: null });
        }
        break;
      case 'city':
      default:
        if (value !== postcode) {
          setPostcode(value);
          form.setFieldsValue({ city_id: null, city: null });
        }
        break;
    }
    if (value.length > 3) {
      fetchCityList && fetchCityList(value, cityType);
    } else {
      clearCityList && clearCityList();
    }
  };

  const onValuesChange = (changedValues) => {
    if ('birthplace_postcode' in changedValues) {
      handlePostcodeChange(changedValues['birthplace_postcode'], 'birthplace');
    }
    if ('postcode' in changedValues) {
      handlePostcodeChange(changedValues['postcode'], 'city');
    }
    if ('birthplace_id' in changedValues) {
      const newCity = birthplaceList.find((c) => c.id === changedValues['birthplace_id']);
      form.setFieldsValue({
        birthplace: newCity && newCity.name,
      });
    }
    if ('city_id' in changedValues) {
      const newCity = cityList.find((c) => c.id === changedValues['city_id']);
      form.setFieldsValue({
        city: newCity && newCity.name,
      });
    }
    if ('medical_teams_id' in changedValues) {
      const medicTeam = medicalTeams.find((c) => c.id === changedValues['medical_teams_id']);
      const medicTeamList = medicTeam && medicTeam.medical_teams_medics.map((el) => el.medic_id);
      const newMedicList = medics.filter((el) => medicTeamList.includes(el.id));
      setMedicList(newMedicList ? newMedicList.map((el) => _.pick(el, ['id', 'firstname', 'lastname'])) : null);
    }
    if ('is_tutor' in changedValues) {
      setIsTutor(changedValues['is_tutor']);
    }
  };

  const handleFinishFailed = ({ errorFields }) => {
    // eslint-disable-next-line no-console
    console.error('errorFields :>> ', errorFields);
  };

  return (
    <>
      <Card style={{ margin: 25, width: '80%' }}>
        <Form
          onFinish={onFinish}
          onFinishFailed={handleFinishFailed}
          initialValues={initialValues}
          layout="vertical"
          form={form}
          onValuesChange={onValuesChange}
        >
          <Form.Item name={['user', 'username']} label="Username" rules={[{ required: false, message: 'Nom Utilisateur' }]}>
            <Input placeholder="Nom Utilisateur" maxLength={50} disabled />
          </Form.Item>
          <Form.Item name={['language_id']} label="Langue" rules={[{ required: true, message: 'Langue non sélectionné' }]}>
            <Select
              placeholder="Langue"
              showSearch
              options={(languageList || []).map((lng) => ({
                value: lng.id,
                label: lng.name,
              }))}
            />
          </Form.Item>
          <Form.Item name="gender" label="Sexe" rules={[{ required: true, message: 'Sexe non sélectionné', whitespace: true }]}>
            <Select
              placeholder="Sexe"
              showSearch
              options={[
                { value: 'male', label: 'Homme' },
                { value: 'female', label: 'Femme' },
              ]}
            />
          </Form.Item>
          <Form.Item name="firstname" label="Prénom" rules={[{ required: true, message: 'Prénom Obligatoire', whitespace: true }]}>
            <Input placeholder="Prénom" maxLength={50} />
          </Form.Item>
          <Form.Item name="lastname" label="Nom de naissance" rules={[{ required: true, message: 'Nom Obligatoire' }]}>
            <Input placeholder="Nom de naissance" maxLength={50} />
          </Form.Item>
          <Form.Item name="use_name" label="Nom d'usage" rules={[{ required: false, message: 'Nom Civil Obligatoire', whitespace: true }]}>
            <Input placeholder="Nom d'usage" maxLength={50} />
          </Form.Item>
          <Form.Item
            name="phone_number"
            label="Téléphone mobile"
            type="regexp"
            rules={[
              { required: !isTutored, message: 'Numéro Obligatoire' },
              ({ getFieldValue }) => ({
                validator(tt, value) {
                  if (!value || value.length <= 3) {
                    // empty field
                    return Promise.resolve();
                  } else {
                    return axios
                      .post(
                        `${process.env.REACT_APP_BASE_API_URL}/V2/phone-number/is-valid-phone-number`,
                        { phoneNumber: value },
                        { headers: { Authorization: `Bearer ${token}` } },
                      )
                      .then((res) => res.data?.data || {})
                      .then((result) => {
                        const { isValidNumber, numberType } = result;
                        if (isValidNumber && PhoneNumberType.MOBILE === numberType) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(new Error('Numéro Portable Incorrect'));
                        }
                      })
                      .catch(() => {
                        return Promise.reject(new Error('Numéro Portable Incorrect'));
                      });
                  }
                },
              }),
            ]}
          >
            <PhoneInput placeholder="Téléphone mobile" />
          </Form.Item>
          <Form.Item
            name="birthplace_postcode"
            label="Code Postal Ville de Naissance"
            type="regexp"
            rules={[
              { required: false },
              () => ({
                validator(_rule, value) {
                  if (!value || value.length === 0) {
                    return Promise.resolve();
                  } else if (value.length !== 5) {
                    return Promise.reject('Le Code postal doit être composé de 5 chiffres');
                  } else if (!digitRegex.test(value)) {
                    return Promise.reject("Le format n'est pas correct");
                  } else {
                    return Promise.resolve();
                  }
                },
              }),
            ]}
          >
            <Input placeholder="Code Postal Ville de Naissance" maxLength={5} minLength={5} />
          </Form.Item>
          <Form.Item
            name="birthplace"
            label="Ville de Naissance"
            rules={[{ required: false, message: 'Ville de Naissance Obligatoire' }]}
            style={{
              display:
                !form.getFieldValue('birthplace_postcode') || !birthplaceOptions || (Array.isArray(birthplaceOptions) && !birthplaceOptions.length)
                  ? 'block'
                  : 'none',
            }}
          >
            <Input placeholder="Ville de naissance" maxLength={70} />
          </Form.Item>
          <Form.Item
            name="birthplace_id"
            label="Ville de Naissance"
            rules={[{ required: false, message: 'Ville de Naissance Obligatoire' }]}
            style={{
              display:
                !form.getFieldValue('birthplace_postcode') || !birthplaceOptions || (Array.isArray(birthplaceOptions) && !birthplaceOptions.length)
                  ? 'none'
                  : 'block',
            }}
          >
            <Select placeholder="Ville de naissance" showSearch options={birthplaceOptions || []} />
          </Form.Item>
          <Form.Item name="birthdate" label="Date de naissance" rules={[{ required: true, message: 'Date Obligatoire' }]}>
            <ReactDatePicker format={'dd-MM-yyyy'} placeholder="Date de naissance" style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            name="postcode"
            label="Code postal ville de résidence"
            type="regexp"
            rules={[
              { required: false },
              () => ({
                validator(_rule, value) {
                  if (!value || value.length === 0) {
                    return Promise.resolve();
                  } else if (value.length !== 5) {
                    return Promise.reject('Le Code postal doit être composé de 5 chiffres');
                  } else if (!digitRegex.test(value)) {
                    return Promise.reject("Le format n'est pas correct");
                  } else {
                    return Promise.resolve();
                  }
                },
              }),
            ]}
          >
            <Input placeholder="Code postal ville de résidence" maxLength={5} minLength={5} />
          </Form.Item>
          <Form.Item
            name="city"
            label="Ville de résidence"
            rules={[{ required: false, message: 'Ville Obligatoire' }]}
            style={{
              display: !form.getFieldValue('postcode') || !cityOptions || (Array.isArray(cityOptions) && !cityOptions.length) ? 'block' : 'none',
            }}
          >
            <Input placeholder="Ville de résidence" maxLength={70} />
          </Form.Item>
          <Form.Item
            name="city_id"
            label="Ville de résidence"
            rules={[{ required: false, message: 'Ville Obligatoire' }]}
            style={{
              display: !form.getFieldValue('postcode') || !cityOptions || (Array.isArray(cityOptions) && !cityOptions.length) ? 'none' : 'block',
            }}
          >
            <Select placeholder="Ville de résidence" showSearch options={cityOptions || []} />
          </Form.Item>
          <Form.Item
            name="security_number"
            hasFeedback
            label="Numéro de sécurité sociale"
            type="number"
            rules={[
              { required: false },
              () => ({
                validator(_rule, value) {
                  if (!value) {
                    return Promise.resolve();
                  } else if (!Rule.length({ value }, 15)) {
                    return Promise.reject(t('num_social_size'));
                  } else if (!Rule.isValidSecuNumFromRegex({ value })) {
                    return Promise.reject(t('num_social_format'));
                  } else if (!Rule.isValidSecuKey({ value })) {
                    return Promise.reject(t('num_social_key_not_valid'));
                  } else {
                    return Promise.resolve();
                  }
                },
              }),
            ]}
          >
            <Input placeholder="Numéro de sécurité sociale" maxLength={15} />
          </Form.Item>
          <Form.Item name="ipp" hasFeedback label="Numéro IPP" type="number" rules={[{ required: false }]}>
            <Input placeholder="Numéro IPP" maxLength={15} />
          </Form.Item>
          <Form.Item name="insi" hasFeedback label="Numéro INSI" type="number" rules={[{ required: false }]}>
            <Input placeholder="Numéro INSI" maxLength={15} />
          </Form.Item>
          <Form.Item name="internal_insi" hasFeedback label="Numéro INSI (interne Calimed)" type="number" rules={[{ required: false }]}>
            <Input placeholder="Numéro INSI" maxLength={15} />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            type="regexp"
            rules={[
              { required: true, message: 'Email Obligatoire' },
              () => ({
                validator(_rule, value) {
                  if (!value || (initialValues && initialValues['email'] === value)) {
                    return Promise.resolve();
                  }
                  if (validEmailRegex.test(value)) {
                    if (checkIfEmailExistsInDb) checkIfEmailExistsInDb(value, initialValues && initialValues.user_id);
                  } else {
                    return Promise.reject("n'est pas un email valide");
                  }
                  if (isEmailAvailable === false) {
                    return Promise.reject('Email déjà utilisée');
                  }
                  if (isEmailAvailable === true) {
                    return Promise.resolve();
                  }
                },
              }),
            ]}
          >
            <Input placeholder="Email" type={'email'} />
          </Form.Item>
          <Form.Item
            name="is_tutor"
            label="Est un représentant légal ?"
            type="bool"
            rules={[
              { required: true, message: 'Tutorat non spécifié' },
              () => ({
                validator(_rule, value) {
                  if (value === false || value === true) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject();
                  }
                },
              }),
            ]}
          >
            <Select
              placeholder="Est un représentant légal ?"
              showSearch
              options={[
                { value: true, label: 'Oui' },
                { value: false, label: 'Non' },
              ]}
            />
          </Form.Item>
          {isTutor && (
            <Form.Item name="patient_id" label="Qui est le patient ?" rules={[{ required: true, message: 'Patient non spécifié' }]}>
              <Select
                placeholder="Qui est le patient ?"
                showSearch
                options={(patients || [])
                  .sort((a, b) => sortByText(a, b, DSC))
                  .map((m) => ({
                    value: m.id,
                    label: `${m.firstname} ${m.lastname}`,
                  }))}
              />
            </Form.Item>
          )}
          <Form.Item
            name="medical_teams_id"
            label="A quelles équipes médicales est-il rattaché ?"
            rules={[{ required: true, message: 'Equipes médicales non spécifiée' }]}
          >
            <ReactSelect
              placeholder="A quelles équipes médicales est-il rattaché ?"
              closeMenuOnSelect={true}
              isSearchable
              options={(medicalTeams || [])
                .sort((a, b) => {
                  const nameA = normalizeString(a.title);
                  const nameB = normalizeString(b.title);
                  return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
                })
                .map((m) => ({
                  value: m && m.id,
                  label: m.title,
                }))}
            />
          </Form.Item>
          <Form.Item name="medic_id" label="Qui est le médecin traitant ?" rules={[{ required: true, message: 'Médecin non spécifié' }]}>
            <Select
              placeholder="Qui est le médecin traitant ?"
              showSearch
              options={(medicList || medics || [])
                .sort((a, b) => sortByText(a, b, DSC))
                .map((m) => ({
                  value: m.id,
                  label: `${m.firstname} ${m.lastname}`,
                }))}
            />
          </Form.Item>
          <Divider type="horizontal" />
          <Form.Item style={{ textAlign: 'center' }}>
            <Button type="primary" htmlType="submit" style={{ width: '55%' }}>
              Enregistrer
            </Button>
          </Form.Item>
        </Form>
      </Card>
      <PaswdForm initialValues={{ id: initialValues?.user?.id }} />
    </>
  );
};

export default PatientsForm;

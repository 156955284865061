import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Divider, Form, Input, Select } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

import { normalizeString } from '../../../utils';

const AppKeysForm = ({
  onValidate,
  initialValues,
  clinicOptions = [],
  medicOptions = [],
  medicalTeamOptions = [],
  token,
  rolesOptions,
  specialitiesOptions,
  surveyTypesOptions,
  languageOptions,
  appKeyRoleOptions,
  id,
}) => {
  const [form] = Form.useForm();
  const [client_secret, setSk] = useState();

  useEffect(() => {
    if (id && initialValues) {
      form.resetFields();
    }
  }, [id, initialValues]);

  const onFinish = (values) => {
    onValidate && onValidate(values);
  };

  const handleGenerate = async () => {
    const id = form.getFieldValue('id');
    const clientId = form.getFieldValue('client_id');
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_API_URL}/v2/appKey${id ? `/${id}` : ''}`,
      { client_id: clientId || null },
      { headers: { Authorization: `Bearer ${token}` } },
    );
    const data = res?.data;
    setSk(data?.client_secret);
    // navigate(`/app_keys/${data.id}`);
    form.setFieldValue('client_secret', data?.client_secret);
    form.setFieldValue('client_id', data?.client_id);
    form.setFieldValue('id', data?.id);
  };

  return (
    <>
      <Card style={{ margin: 25, width: '80%' }}>
        <Form onFinish={onFinish} initialValues={initialValues} layout="vertical" form={form} name="basic">
          <Divider type="horizontal">Info</Divider>
          <Form.Item name="id" label="id">
            <Input disabled />
          </Form.Item>
          <Form.Item name="name" label="Nom" rules={[{ required: true, message: 'Nom Obligatoire', whitespace: true }]}>
            <Input placeholder="Nom" maxLength={50} />
          </Form.Item>
          <Form.Item name={'medicTeamIds'} label="Equipe médicale">
            <Select
              style={{ width: '100%' }}
              showSearch
              filterOption={(input, option) => normalizeString(option?.children).indexOf(normalizeString(input)) >= 0}
              allowClear
              mode="multiple"
            >
              {medicalTeamOptions.map((el) => (
                <Select.Option value={el.value} key={`medicalTeam_${el.value}`}>
                  {el.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="clinic_id" label="Clinic">
            <Select
              style={{ width: '100%' }}
              showSearch
              filterOption={(input, option) => normalizeString(option?.children).indexOf(normalizeString(input)) >= 0}
              allowClear
            >
              {clinicOptions.map((el) => (
                <Select.Option value={el.value} key={`clinic_${el.value}`}>
                  {el.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="user_id" label="Pro">
            <Select
              style={{ width: '100%' }}
              showSearch
              filterOption={(input, option) => normalizeString(option?.children).indexOf(normalizeString(input)) >= 0}
              allowClear
            >
              {medicOptions.map((el) => (
                <Select.Option value={el.value} key={`medic_${el.value}`}>
                  {el.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Divider type="horizontal">Settings</Divider>
          <Form.Item name={['app_keys_settings', 0, 'id']} label="id">
            <Input disabled />
          </Form.Item>
          <Form.Item name={['app_keys_settings', 0, 'default_medic_role']} label="Role par default lors de la création d'un médecin">
            <Select
              style={{ width: '100%' }}
              showSearch
              filterOption={(input, option) => normalizeString(option?.children).indexOf(normalizeString(input)) >= 0}
              allowClear
            >
              {rolesOptions
                .filter((el) => !el.is_backend)
                .map((el) => (
                  <Select.Option value={el.value} key={`medic_role_${el.value}`}>
                    {el.label}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item name={['app_keys_settings', 0, 'default_medicalteam_role']} label="Role par default lors de la création d'une équipe médicale">
            <Select
              style={{ width: '100%' }}
              showSearch
              filterOption={(input, option) => normalizeString(option?.children).indexOf(normalizeString(input)) >= 0}
              allowClear
            >
              {rolesOptions
                .filter((el) => !el.is_backend)
                .map((el) => (
                  <Select.Option value={el.value} key={`medicalteam_role_${el.value}`}>
                    {el.label}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item name={['app_keys_settings', 0, 'default_medicalteam_settings', 'show_delay_reason']} valuePropName="checked">
            <Checkbox>Demander un justificatif lorsque le délai consultation/intervention est trop court</Checkbox>
          </Form.Item>
          <Form.Item name={['app_keys_settings', 0, 'default_speciality_id']} label="Spécialité par default (lors de la création d'un modèle)">
            <Select
              style={{ width: '100%' }}
              showSearch
              filterOption={(input, option) => normalizeString(option?.children).indexOf(normalizeString(input)) >= 0}
              allowClear
            >
              {specialitiesOptions.map((el) => (
                <Select.Option value={el.value} key={`speciality_${el.value}`}>
                  {el.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name={['app_keys_settings', 0, 'default_survey_type_id']} label="Type par default (lors de la création d'un modèle)">
            <Select
              style={{ width: '100%' }}
              showSearch
              filterOption={(input, option) => normalizeString(option?.children).indexOf(normalizeString(input)) >= 0}
            >
              {surveyTypesOptions.map((el) => (
                <Select.Option value={el.value} key={`speciality_${el.value}`}>
                  {el.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name={['app_keys_settings', 0, 'default_language_id']} label="Language par default">
            <Select placeholder="Langue" showSearch options={languageOptions} allowClear />
          </Form.Item>
          <Form.Item
            name={['app_keys_settings', 0, 'is_old_connector']}
            label="Création automatique de Clinical trial (si ancien app_key)"
            valuePropName="checked"
          >
            <Checkbox>Création automatique de Clinical trial (si ancien app_key)</Checkbox>
          </Form.Item>
          <Form.Item
            name={['app_keys_settings', 0, 'default_notif_email_patient']}
            label="Si décocher, un patient ne recoit pas d'email de EZ (Sauf OTP)"
            valuePropName="checked"
          >
            <Checkbox>Si décocher, un patient ne recoit pas d'email de EZ</Checkbox>
          </Form.Item>
          <Form.Item
            name={['app_keys_settings', 0, 'default_notif_sms_patient']}
            label="Si décocher, un patient ne recoit pas de SMS de EZ (Sauf OTP)"
            valuePropName="checked"
          >
            <Checkbox>Si décocher, un patient ne recoit pas de SMS de EZ</Checkbox>
          </Form.Item>
          <Divider type="horizontal">Crédentials</Divider>
          <Button type="primary" onClick={handleGenerate} htmlType="button">
            Générer clé privée
          </Button>
          <Form.Item name="client_id" label="Clé publique" rules={[{ required: true, message: 'Clé publique Obligatoire', whitespace: true }]}>
            <Input placeholder="Clé publique" maxLength={50} />
          </Form.Item>
          <div className={`${client_secret ? '' : 'd-none'}`}>
            Votre clé privée est <pre>{client_secret}</pre>
          </div>

          <Divider type="horizontal">CORS</Divider>
          <Form.List name={'cors_from'}>
            {(fields, { add, remove }) => (
              <div>
                {fields.map((field) => (
                  <div key={`cors_from_${field.key}`}>
                    <Form.Item {...field}>
                      <Input />
                    </Form.Item>
                    <Button
                      type="danger"
                      onClick={() => remove(field.name)}
                      // disabled={(initialValues && initialValues.pdf_name)}
                      style={{ marginTop: 16 }}
                      icon={<MinusCircleOutlined />}
                    >
                      Supprimer
                    </Button>
                    <Divider type="horizontal" />
                  </div>
                ))}
                <Button
                  type="dashed"
                  onClick={() => {
                    add();
                  }}
                  style={{ width: '60%' }}
                >
                  <PlusOutlined /> Add CORS
                </Button>
              </div>
            )}
          </Form.List>
          <Divider type="horizontal">Scope</Divider>
          <Form.List name={'scope'}>
            {(fields, { add, remove }) => (
              <div>
                {fields.map((field) => (
                  <div key={`scope_${field.key}`}>
                    <Form.Item {...field}>
                      <Input />
                    </Form.Item>
                    <Button
                      type="danger"
                      onClick={() => remove(field.name)}
                      // disabled={(initialValues && initialValues.pdf_name)}
                      style={{ marginTop: 16 }}
                      icon={<MinusCircleOutlined />}
                    >
                      Supprimer
                    </Button>
                    <Divider type="horizontal" />
                  </div>
                ))}
                <Button
                  type="dashed"
                  onClick={() => {
                    add();
                  }}
                  style={{ width: '60%' }}
                >
                  <PlusOutlined /> Add scope
                </Button>
              </div>
            )}
          </Form.List>

          <Divider type="horizontal">Role</Divider>
          <Form.Item name={['appkeys_roles', 0]} label="Détail du role" rules={[{ required: false, message: 'role non spécifiée' }]}>
            {/* <Form.Item name={['appkeys_roles', 0, 'role', 'id']} label="Détail du role" rules={[{ required: false, message: 'role non spécifiée' }]}> */}
            <Select style={{ width: '100%' }} allowClear>
              {appKeyRoleOptions.map((r) => (
                <Select.Option key={r.value} value={r.value}>
                  {r.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Divider type="horizontal">External credentials</Divider>
          <Form.Item name={['credentials', 0, 'id']} label="id" hidden>
            <Input disabled />
          </Form.Item>
          <Form.Item name={['credentials', 0, 'description']} label="description">
            <Input />
          </Form.Item>
          <Form.Item name={['credentials', 0, 'name']} label="name">
            <Input />
          </Form.Item>
          <Form.Item name={['credentials', 0, 'api_url']} label="URL vers API">
            <Input />
          </Form.Item>
          <Form.Item name={['credentials', 0, 'auth_url']} label="URL Authentification">
            <Input />
          </Form.Item>
          <Form.Item name={['credentials', 0, 'details']} label="Details d'Authentification">
            <Input />
          </Form.Item>
          <Form.Item name={['credentials', 0, 'type']} label="Type d'Authentification">
            <Input defaultValue={'login'} readOnly />
          </Form.Item>

          <Divider type="horizontal" />
          <Button
            type="dashed"
            onClick={() => {
              // form.setFieldsValue(newValues);
            }}
            style={{ width: '60%' }}
          >
            <PlusOutlined /> From Template
          </Button>
          <Divider type="horizontal" />
          <Form.Item style={{ textAlign: 'center' }}>
            <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
              Enregistrer
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};

export default AppKeysForm;

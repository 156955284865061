import { connect } from 'react-redux';

import { UserActions } from '../../../redux/users';
import PaswdForm from './PaswdForm';

const mapDispatchToProps = (dispatch) => ({
  updatePassword: (id, password) => dispatch(UserActions.updatePassword({ id, password })),
});

export default connect(null, mapDispatchToProps)(PaswdForm);

import { createAction } from '../../utils';

// Types
export const types = {
  RESET_REDUCED: 'forms_RESET_REDUCED',

  UPDATE_DATA: 'forms_UPDATE_DATA',
  OMIT_DATA: 'forms_OMIT_DATA',
  CLEAR_DATA: 'forms_CLEAR_DATA',
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  resetReducers: () => createAction(types.RESET_REDUCED),

  updateData: (key = null, data) => createAction(types.UPDATE_DATA, { key, data }),
  omitData: (key) => createAction(types.OMIT_DATA, { key }),
  clearData: () => createAction(types.CLEAR_DATA),
};

import { connect } from 'react-redux';

import { ClinicActions } from '../../../redux/clinics';
import { MedicalTeamActions } from '../../../redux/medicalTeams';
import { MedicActions } from '../../../redux/medics';
import { RoleActions } from '../../../redux/roles';
import { normalizeString } from '../../../utils';
import MedicalTeamsCreate from './MedicalTeamsCreate';

const mapStateToProps = ({ medicalTeams, medics, roles, clinics }) => ({
  medicsList: medics.list || {},
  medicalTeamsList: medicalTeams.list || {},
  rolesOptions: Object.values(roles.list || {}).map((el) => ({ value: el.id, label: el.name })),
  clinicOptions: Object.values(clinics.list || {})
    .sort((a, b) => {
      const nameA = normalizeString(a.name);
      const nameB = normalizeString(b.name);
      return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
    })
    .filter((m) => m?.id)
    .map((m) => ({
      value: m.id,
      label: m.name,
    })),
});

const mapDispatchToProps = (dispatch) => ({
  rolesRequest: (filter) => dispatch(RoleActions.rolesRequest(filter)),
  medicalTeamsCreateRequest: (creation) => dispatch(MedicalTeamActions.medicalTeamsCreateRequest(creation)),
  medicsRequest: (filter) => dispatch(MedicActions.medicsRequest(filter)),
  medicalTeamsUpdateRequest: (medicalTeam) => dispatch(MedicalTeamActions.medicalTeamsUpdateRequest(medicalTeam)),
  medicalTeamsRequest: (filter) => dispatch(MedicalTeamActions.medicalTeamsRequest(filter)),
  clinicsRequest: (filter) => dispatch(ClinicActions.clinicsRequest(filter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MedicalTeamsCreate);

import './medics.css';

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { filterAndOrderList } from '../../../utils/utils.js';
import Filter from '../../components/filter';
import { TAGS, Tags } from '../../components/tags';
import DataTable from './DataTable';

const MEDIC_FILTER_KEYS = ['full_name', 'firstname', 'lastname', 'rpps_number', 'phone_number', 'email', 'local_phone_number'];
const DSC = 'descending';

const Medics = ({ specialitiesRequest, specialities, medicsRequest, list, countries, loading, medicsDeleteRequest, surveyTypesRequest }) => {
  const navigate = useNavigate();
  const [filtered, setFiltered] = useState();
  const [reset, setReset] = useState(false);
  const [valueList, setValueList] = useState(list);
  const [activeFilters, setActiveFilters] = useState({
    specialities: { order: 0 },
  });
  const [tags, setTags] = useState([]);

  useEffect(() => {
    medicsRequest();
    specialitiesRequest({});
    surveyTypesRequest({});
  }, []);

  useEffect(() => {
    setList(list);
  }, [list]);

  useEffect(() => {
    const newTags = tags;
    const index = tags.findIndex((t) => t.tagIndex === 'SPECIALITIES');

    if (index >= 0) {
      newTags[index] = { ...newTags[index], list: specialities };
    } else {
      newTags.push({
        list: specialities,
        tagIndex: 'SPECIALITIES',
        title: 'Choix de la spécialité :',
        placeholder: 'Spécialité du praticien',
      });
    }
  }, [specialities && specialities.length]);

  const onFilter = (result) => {
    if (!result) {
      onTags([-1], 'none');
    } else {
      const newFiltered =
        Array.isArray(filtered) && filtered.length ? result.map((r) => r.item.id).filter((r) => filtered.includes(r)) : result.map((r) => r.item.id);
      setFiltered(newFiltered.length ? newFiltered : [-1]);
      setReset(!reset);
    }
  };

  const onModify = (medic) => {
    navigate(`/medics/${medic.id}`, medic);
  };

  const onDelete = (medic) => {
    if (Array.isArray(medic)) {
      medicsDeleteRequest(medic);
    } else {
      medicsDeleteRequest([medic.id]);
    }
  };

  const setList = (list) => setValueList((list || []).map((el) => ({ ...el, full_name: [el.firstname, el.lastname, el.use_name].join(' ') })));

  const exportMapper = (toExport) =>
    toExport.map((e) => ({
      id: e.id,
      Prénom: e.firstname,
      'Nom de famille': e.lastname,
      'Numéro RPPS': e.rpps_number,
      'Numéro de mobile': e.phone_number,
      Premium: e.is_premium ? 'Oui' : 'Non',
      Pays: e.country,
    }));

  const onTags = (value, mode) => {
    let newActivesFilters = activeFilters;
    if (!value || !value.length) {
      newActivesFilters[mode.toLowerCase()].order = 0;
    } else {
      const medicTags = TAGS['medics'];
      if (mode === medicTags.SPECIALITIES) {
        newActivesFilters.specialities.order = 1;
        newActivesFilters.specialities.filter = (values) => values.filter((s) => s.medic_specialities.some((ms) => value.includes(ms.speciality_id)));
      }
    }

    let newFiltered = valueList;
    const arrays = [newActivesFilters.specialities];
    arrays
      .sort((a, b) => a.order - b.order)
      .forEach((f) => {
        if (f.order > 0) {
          newFiltered = f.filter(newFiltered);
        }
      });

    setFiltered(newFiltered.length ? newFiltered.map((f) => f.id) : [-1]);
    setActiveFilters(newActivesFilters);
  };

  return (
    <>
      <Tags data={tags} tagIndex={'medics'} onTags={onTags} exportFct={() => exportMapper(filtered || valueList)} />
      <Filter list={valueList} onFilter={onFilter} keys={MEDIC_FILTER_KEYS} useExtendedSearch />

      <DataTable
        countries={countries}
        items={filterAndOrderList(valueList, filtered)}
        reset={reset}
        loading={loading}
        itemClick={onModify}
        onDelete={onDelete}
        onModify={onModify}
      />
    </>
  );
};

export default Medics;

import { createAction } from '../../utils';

// Types
export const types = {
  SFTP_REQUEST: 'SFTP_REQUEST',
  SFTP_FAILURE: 'SFTP_FAILURE',
  SFTP_SUCCESS: 'SFTP_SUCCESS',

  SFTP_UPDATE_REQUEST: 'SFTP_UPDATE_REQUEST',
  SFTP_UPDATE_FAILURE: 'SFTP_UPDATE_FAILURE',
  SFTP_UPDATE_SUCCESS: 'SFTP_UPDATE_SUCCESS',

  SFTP_UPSERT_REQUEST: 'SFTP_UPSERT_REQUEST',
  SFTP_UPSERT_FAILURE: 'SFTP_UPSERT_FAILURE',
  SFTP_UPSERT_SUCCESS: 'SFTP_UPSERT_SUCCESS',

  SFTP_DELETE_REQUEST: 'SFTP_DELETE_REQUEST',
  SFTP_DELETE_MULTIPLE_REQUEST: 'SFTP_DELETE_MULTIPLE_REQUEST',
  SFTP_DELETE_SUCCESS: 'SFTP_DELETE_SUCCESS',
};

// Actions
export default {
  /* UPSERT */
  upsertRequest: (sftpCredential = {}) => createAction(types.SFTP_UPSERT_REQUEST, { sftpCredential }),
  upsertSuccess: (sftpCredentials) => createAction(types.SFTP_UPSERT_SUCCESS, { sftpCredentials }),

  /** REQUEST */
  request: (filters = {}) => createAction(types.SFTP_REQUEST, { filters }),
  requestSuccess: (sftpCredentials) => createAction(types.SFTP_SUCCESS, { sftpCredentials }),
  requestFailure: () => createAction(types.SFTP_FAILURE),

  deleteRequest: (id) => createAction(types.SFTP_DELETE_REQUEST, { id }),
  deleteSuccess: (id) => createAction(types.SFTP_DELETE_SUCCESS, { id }),
};

import { connect } from 'react-redux';

import { CredentialActions } from '../../../redux/credential';
import Credential from './Credential';

const mapStateToProps = ({ credential }) => ({
  list: Object.values(credential.list || {}),
  loading: credential.loading,
});

const mapDispatchToProps = (dispatch) => ({
  credentialsRequest: () => dispatch(CredentialActions.request()),
  credentialsDeleteRequest: (id) => dispatch(CredentialActions.deleteRequest(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Credential);

import { connect } from 'react-redux';

import { RoleActions } from '../../../redux/roles';
import Roles from './Roles';

const mapStateToProps = ({ roles }) => ({
  list: roles.list || {},
  loading: roles.loading,
});

const mapDispatchToProps = (dispatch) => ({
  rolesRequest: (filters) => dispatch(RoleActions.rolesRequest(filters)),
  rolesDeleteRequest: (id) => dispatch(RoleActions.rolesDeleteRequest(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Roles);

import 'react-phone-input-2/lib/style.css';

import PropTypes from 'prop-types';
import React from 'react';
import ReactPhoneInput from 'react-phone-input-2';

import { checkMobilePhone } from '../../../../../utils/regex';

const PhoneInput = ({ placeholder, disabled, containerClassName, inputClassName, onChange, value }) => {
  return (
    <div className={`${containerClassName || 'inputFormItem-container'}`}>
      <ReactPhoneInput
        inputProps={{
          name: 'phone',
          required: true,
        }}
        inputClass={`${inputClassName || 'ec-form-phone-number-input'}`}
        onlyCountries={[
          'al',
          'dz',
          'ad',
          'au',
          'at',
          'by',
          'be',
          'ba',
          'bg',
          'ca',
          'cn',
          'hr',
          'cy',
          'cz',
          'dk',
          'ee',
          'fi',
          'fr',
          'de',
          'gr',
          'gp',
          'gy',
          'ie',
          'it',
          'jp',
          'xk',
          'lv',
          'li',
          'lu',
          'mk',
          'mt',
          'md',
          'mc',
          'me',
          'ma',
          'nl',
          'no',
          'pl',
          'pt',
          'ro',
          'sk',
          'si',
          'es',
          'se',
          'ch',
          'tr',
          'ua',
          'gb',
          'us',
          'va',
        ]}
        country={'fr'} // initial country
        preferredCountries={['fr']}
        disabled={disabled}
        containerStyle={{ height: '68px' }}
        inputStyle={{ height: '100%', width: '100%' }}
        isValid={(inputNumber, country) => {
          const { dialCode } = country;
          return checkMobilePhone(dialCode, inputNumber);
        }}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
      />
    </div>
  );
};

PhoneInput.propTypes = {
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  extraInputProps: PropTypes.object,
};

export default PhoneInput;

import { connect } from 'react-redux';

import { ClinicActions } from '../../../redux/clinics';
import Clinics from './Clinics';

const mapStateToProps = ({ clinics }) => ({
  list: clinics.list || {},
  loading: clinics.loading,
});

const mapDispatchToProps = (dispatch) => ({
  clinicsRequest: (filter) => dispatch(ClinicActions.clinicsRequest(filter)),
  clinicsDeleteRequest: (clinic) => dispatch(ClinicActions.clinicsDeleteRequest(clinic)),
  clinicsDeleteMultipleRequest: (clinic) => dispatch(ClinicActions.clinicsDeleteMultipleRequest(clinic)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Clinics);

import { Form, Input } from 'antd';
import Fuse from 'fuse.js';
import React, { useEffect, useState } from 'react';

import { removeAccents } from '../../../utils';
const { Search } = Input;

const Filter = ({ list, onFilter, keys, disabled, threshold = 0.3, useExtendedSearch = false }) => {
  const [value, setValue] = useState('');
  const [options, setOptions] = useState({});

  useEffect(() => {
    if (keys !== options.keys) {
      setOptions({
        isCaseSensitive: false,
        includeScore: true,
        includeMatches: true,
        minMatchCharLength: 3,
        shouldSort: true,
        keys: keys,
        tokenize: true,
        ignoreLocation: true,
        threshold: threshold,
        useExtendedSearch: useExtendedSearch,
        getFn: (obj, path) => {
          // return remove(Fuse.config.getFn.apply(this, arguments));
          // Use the default `get` function
          var value = Fuse.config.getFn(obj, path);
          // ... do something with `value`
          if (Array.isArray(value)) {
            return value.map((query) => removeAccents(query));
          }
          return removeAccents(value);
        },
      });
      setValue('');
    }
  }, [keys]);

  const fuse = new Fuse(list, options);

  const onSearch = (value) => {
    if (!value || value === '') {
      onFilter(null);
      return;
    }
    setValue(value);
    const result = fuse.search(removeAccents(value));
    onFilter(result);
  };

  const onSearchChange = (e) => {
    const value = e.target.value;
    if (!value.length) {
      onFilter('');
    }
    setValue(value);
  };

  return (
    <div>
      <Form className="FormFilter">
        <Form.Item>
          <Search
            placeholder="Saisissez votre recherche"
            value={value}
            onChange={onSearchChange}
            allowClear={true}
            onSearch={onSearch}
            enterButton
            disabled={disabled}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default Filter;

import { createAction } from '../../utils';

// Types
export const types = {
  INTERVENTIONS_CREATE_REQUEST: 'INTERVENTIONS_CREATE_REQUEST',
  INTERVENTIONS_CREATE_FAILURE: 'INTERVENTIONS_CREATE_FAILURE',
  INTERVENTIONS_CREATE_SUCCESS: 'INTERVENTIONS_CREATE_SUCCESS',

  INTERVENTIONS_UPDATE_REQUEST: 'INTERVENTIONS_UPDATE_REQUEST',
  INTERVENTIONS_UPDATE_FAILURE: 'INTERVENTIONS_UPDATE_FAILURE',
  INTERVENTIONS_UPDATE_SUCCESS: 'INTERVENTIONS_UPDATE_SUCCESS',

  INTERVENTIONS_DELETE_REQUEST: 'INTERVENTIONS_DELETE_REQUEST',
  INTERVENTIONS_DELETE_SUCCESS: 'INTERVENTIONS_DELETE_SUCCESS',

  INTERVENTIONS_ARCHIVE_REQUEST: 'INTERVENTIONS_ARCHIVE_REQUEST',

  INTERVENTIONS_CLEAR: 'INTERVENTIONS_CLEAR',

  INTERVENTIONS_PRODUCE_MAIL: 'INTERVENTIONS_PRODUCE_MAIL',
};

// Actions
export default {
  /* CREATE */
  interventionsCreateSuccess: () => createAction(types.INTERVENTIONS_CREATE_SUCCESS),

  /* UPDATE */
  interventionsUpdateRequest: (intervention = {}) => createAction(types.INTERVENTIONS_UPDATE_REQUEST, { intervention }),
  interventionsUpdateSuccess: () => createAction(types.INTERVENTIONS_UPDATE_SUCCESS),

  /* DELETE */
  interventionsDeleteRequest: (IDs) => createAction(types.INTERVENTIONS_DELETE_REQUEST, { IDs }),
  interventionsDeleteSuccess: (IDs) => createAction(types.INTERVENTIONS_DELETE_SUCCESS, { IDs }),

  interventionsArchiveRequest: (IDs, archived) => createAction(types.INTERVENTIONS_ARCHIVE_REQUEST, { IDs, archived }),

  /* CLEAR */
  interventionsClear: () => createAction(types.INTERVENTIONS_CLEAR),

  /* MAIL */
  produceMail: (mail = {}) => createAction(types.INTERVENTIONS_PRODUCE_MAIL, { mail }),
};

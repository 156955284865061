import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/style.scss';
// import 'antd/dist/antd.css';
import 'antd/dist/reset.css';
import './assets/style.css';
// import i18n (needs to be bundled ;))
import './services/i18n/i18n';

import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import emptyCache from './utils/cache';

emptyCache();

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);

/* Service utils */
/* Service utils */
import { ApolloClient, gql, HttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import axios from 'axios';

/** GRAPHQL */
const httpLink = new HttpLink({ uri: process.env.REACT_APP_HASURA_URL });
const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      // eslint-disable-next-line no-console
      console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
    );

  // eslint-disable-next-line no-console
  if (networkError) console.error(`[Network error]: ${networkError}`);
});

const client = new ApolloClient({
  link: errorLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
});

/** GRAPHQL - MUTATION */
export const mutation = ({ queryString, headers = {}, variables }) =>
  client
    .mutate({
      mutation: gql`
        ${queryString}
      `,
      variables,
      context: {
        headers: headers,
      },
    })
    .then((result) => {
      // eslint-disable-next-line no-console
      console.log({ result });
      return Promise.all([result.errors, result.data]);
    })
    .catch((error) => {
      return Promise.resolve([error]);
    });

/** GRAPHQL - QUERY */
export const query = ({ queryString, headers = {}, variables }) =>
  client
    .query({
      query: gql`
        ${queryString}
      `,
      variables,
      context: {
        headers: headers,
      },
      // ... other options
    })
    .then((result) => {
      return Promise.all([result.errors, result.data]);
    })
    .catch((error) => {
      return Promise.resolve([error]);
    });

/** Method types */
export const methods = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  PATCH: 'patch',
  DELETE: 'delete',
};

export const fetch = ({ method, url, data = {}, params = {}, headers = {}, responseType, uploadCallBack = (_e) => {} }) => {
  let instance = axios.create();

  instance.defaults.headers.common = {};
  const { cancelToken } = params;
  delete params.cancelToken;

  return instance(
    {
      method: method,
      url: url,
      data: data,
      params: params,
      cancelToken: cancelToken,
      responseType,
      headers,
      onUploadProgress: (progressEvent) => uploadCallBack(progressEvent),
    },
    { crossdomain: true },
  )
    .then((response) => {
      return Promise.all([null, response]);
    })
    .catch((error) => {
      // Fix proposed by TNU when error.response = undefined (for NetworkError)
      if (error.response) {
        return Promise.resolve([error]);
      }
      return Promise.resolve([{ response: { status: 500 } }]);
    });
};

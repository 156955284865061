import { connect } from 'react-redux';

import { SmsActions } from '../../../redux/sms';
import SmsCreate from './SmsCreate';

const mapStateToProps = ({ language, sms }) => ({
  smsList: sms.list || {},
  languageList: Object.values(language.list || {}),
});

const mapDispatchToProps = (dispatch) => ({
  smsRequest: (filter) => dispatch(SmsActions.smsRequest(filter)),
  smsUpdateRequest: (sms) => dispatch(SmsActions.smsUpdateRequest(sms)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SmsCreate);

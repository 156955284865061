import _ from 'lodash';

import { authenticatedMutation, authenticatedQuery } from './middleware';

export const SFTP_KEYS_MINI_FIELDS = `
  fragment sftpCredentialsMiniFragments on sftp_credentials {
    id
    created_at
    name
  }
`;

export const SFTP_KEYS_FIELDS = `
  fragment sftpCredentialsFragments on sftp_credentials {
    id
    created_at
    updated_at
    name
    host
    port
    username
    remote_directory
    is_active
    remote_directory
    is_active
    cdar2_template_filename
    fetch_hl7
    hl7_remote_directory
    push_oru
    oru_remote_directory
    clinic_id 
  }
`;

/**
 * GRAPHQL QUERIES
 */
const queries = {
  getAll: () => `
  ${SFTP_KEYS_MINI_FIELDS}
    query GetSftpCredentials {
      sftp_credentials {
        ...sftpCredentialsMiniFragments
      }
    }
  `,
  getById: () => `
  ${SFTP_KEYS_FIELDS}
    query GetSftpCredentialById($id: Int) {
      sftp_credentials(where: { id: { _eq: $id } }) {
        ...sftpCredentialsFragments
      }
    }
  `,
};

/**
 * GRAPHQL MUTATION
 *
 */

const mutations = {
  create: () => `
    ${SFTP_KEYS_FIELDS}
    mutation InsertSftpCredential($sftpCredential: [sftp_credentials_insert_input!]!) {
      insert_sftp_credentials(objects: $sftpCredential) {
        returning {
          ...sftpCredentialsFragments
        }
      }
    }
  `,
  update: () => `
    ${SFTP_KEYS_FIELDS}
    mutation UpdateSftpCredential($id: Int!, $sftpCredential: sftp_credentials_set_input!) {
      update_sftp_credentials(where: {id: {_eq: $id}}, _set: $sftpCredential) {
        returning {
          ...sftpCredentialsFragments
        }
      }
    }
  `,
  delete: () => `
    mutation DeleteSftpCredential($id: Int) {
      delete_sftp_credentials(where: {id: {_eq: $id}}) {
        returning {
          id
        }
      }
    }
  `,
  deleteMulti: () => `
    mutation DeleteMultiSftpCredentials($ids: [Int!]!) {
      delete_sftp_credentials(where: {id: {_in: $ids}}) {
        returning {
          id
        }
      }
    }
  `,
};

export default {
  getAll: () => authenticatedQuery(queries.getAll(), {}, {}),
  getById: (id = null) => authenticatedQuery(queries.getById(), {}, { id }),
  create: (sftpCredential) =>
    authenticatedMutation(
      mutations.create(),
      {},
      {
        sftpCredential: _.omit(sftpCredential, ['id', 'clinicsId']),
      },
    ),
  update: (sftpCredential) =>
    authenticatedMutation(
      mutations.update(),
      {},
      {
        id: sftpCredential.id,
        sftpCredential: _.omit(sftpCredential, ['id', 'clinicsId']),
      },
    ),
  delete: (id) => authenticatedMutation(mutations.delete(id), {}, { id }),
  deleteMulti: (ids) => authenticatedMutation(mutations.deleteMulti(), {}, { ids }),
};

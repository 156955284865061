import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import ClinicsGroupsForm from '../../components/clinicsGroupsForm/ClinicsGroupsForm';

const ClinicsGroupCreate = ({
  clinicsGroupsList,
  clinicsList,
  clinicsGroupCreateRequest,
  clinicsGroupUpdateRequest,
  clinicsGroupsRequest,
  clinicsUpdateRequest,
  clinicsRequest,
  authorizationToken,
}) => {
  const { clinicsGroupId } = useParams();
  const id = clinicsGroupId && !['clinicsGroups', 'add'].includes(clinicsGroupId) ? clinicsGroupId : null;
  const [initialValues, setInitialValues] = useState();
  const [clinics, setClinics] = useState([]);
  const form = useMemo(() => {
    return id ? clinicsGroupsList.find((c) => +c.id === +id) : null;
    // return id && clinicsGroupsList ? clinicsGroupsList.id : {};
  }, [id, clinicsGroupsList]);

  useEffect(() => {
    clinicsRequest();
  }, []);

  useEffect(() => {
    id ? clinicsGroupsRequest({ id: id }) : clinicsGroupsRequest();
  }, [id]);

  useEffect(() => {
    if (form) {
      if (Array.isArray(clinicsList) && clinicsList.length > 0) {
        setClinics(clinicsList.filter((c) => +c.clinic_group_id === +form.id));
      }
      setInitialValues({
        ...form,
        clinics: Array.isArray(clinics) && clinics.map((c) => c.id),
      });
    }
  }, [clinicsList, form]);

  const onValidate = (value) => {
    if (form == null) {
      clinicsGroupCreateRequest(value);
    } else {
      clinicsGroupUpdateRequest({ value, id: form.id });
    }
  };

  return (
    <>
      <ClinicsGroupsForm onValidate={onValidate} initialValues={initialValues} clinicsList={clinicsList} authorizationToken={authorizationToken} />
    </>
  );
};
export default ClinicsGroupCreate;

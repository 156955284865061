import { connect } from 'react-redux';

import { SpecialityActions } from '../../../redux/specialities';
import Specialities from './Specialities';

const mapStateToProps = ({ auth, specialities }) => ({
  specialities: Object.values(specialities.list || {}),
  loading: specialities.loading,
  userLanguageId: auth.languageId,
});

const mapDispatchToProps = (dispatch) => ({
  specialitiesRequest: (filter) => dispatch(SpecialityActions.specialitiesRequest(filter)),
  specialitiesDeleteRequest: (specialityIds) => dispatch(SpecialityActions.specialitiesDeleteRequest(specialityIds)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Specialities);

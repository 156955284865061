import { createAction } from '../../utils';

// Types
export const types = {
  ROLES_REQUEST: 'ROLES_REQUEST',
  ROLES_FAILURE: 'ROLES_FAILURE',
  ROLES_SUCCESS: 'ROLES_SUCCESS',

  ROLES_UPDATE_REQUEST: 'ROLES_UPDATE_REQUEST',
  ROLES_UPDATE_FAILURE: 'ROLES_UPDATE_FAILURE',
  ROLES_UPDATE_SUCCESS: 'ROLES_UPDATE_SUCCESS',

  ROLES_CREATE_REQUEST: 'ROLES_CREATE_REQUEST',
  ROLES_CREATE_FAILURE: 'ROLES_CREATE_FAILURE',
  ROLES_CREATE_SUCCESS: 'ROLES_CREATE_SUCCESS',

  ROLES_DELETE_REQUEST: 'ROLES_DELETE_REQUEST',
  ROLES_DELETE_MULTIPLE_REQUEST: 'ROLES_DELETE_MULTIPLE_REQUEST',
  ROLES_DELETE_SUCCESS: 'ROLES_DELETE_SUCCESS',
};

// Actions
export default {
  /* CREATE */
  rolesCreateRequest: (role = {}) => createAction(types.ROLES_CREATE_REQUEST, { role }),
  rolesCreateSuccess: () => createAction(types.ROLES_CREATE_SUCCESS),

  /* UPDATE */
  rolesUpdateRequest: (role = {}) => createAction(types.ROLES_UPDATE_REQUEST, { role }),
  rolesUpdateSuccess: () => createAction(types.ROLES_UPDATE_SUCCESS),

  /** REQUEST */
  rolesRequest: (filters = {}) => createAction(types.ROLES_REQUEST, { filters }),
  rolesSuccess: (roles) => createAction(types.ROLES_SUCCESS, { roles }),
  rolesFailure: () => createAction(types.ROLES_FAILURE),

  rolesDeleteRequest: (id) => createAction(types.ROLES_DELETE_REQUEST, { id }),
  rolesDeleteSuccess: (id) => createAction(types.ROLES_DELETE_SUCCESS, { id }),
};

import { createReducer } from 'reduxsauce';

import { types } from './actions';

const initialState = {
  cities: {},
  isCitiesLoading: false,
};

const fetchBegin = (state = initialState) => ({ ...state, isCitiesLoading: true });

const fetchCityListFailure = (state = initialState) => ({ ...state, isCitiesLoading: false });

const fetchCityListSuccess = (state = initialState, action) => {
  return { ...state, cities: action.cities, isCitiesLoading: false };
};

const fetchBirthplaceListSuccess = (state = initialState, action) => {
  return { ...state, birthplaceList: action.cities, isCitiesLoading: false };
};

const clearCityList = (state = initialState) => ({ ...state, ...initialState });

export default createReducer(initialState, {
  // [types.CITIES_FETCH_QUERY]: fetchBegin,
  [types.CITIES_FETCH_BEGIN]: fetchBegin,
  [types.CITIES_FETCH_SUCCESS]: fetchCityListSuccess,
  [types.CITIES_BIRTHPLACE_FETCH_SUCCESS]: fetchBirthplaceListSuccess,
  [types.CITIES_FETCH_FAILURE]: fetchCityListFailure,
  [types.CITIES_CLEAR_LIST]: clearCityList,
});

import _ from 'lodash';

import { authenticatedQuery } from './middleware';

/**
 * GRAPHQL QUERIES
 */
const queries = {
  getOne: () => `
    query GetOneCredential($id: Int!) {
      credentials(where: {id: {_eq: $id}}) {
        id
        created_at
        updated_at
        type
        name
        user_id
        description
        details
        client_id
        auth_url
        app_key_id
        api_url
      }
    }
  `,
  getAll: () => `
    query GetAllCredential {
      credentials {
        id
        created_at
        name
      }
    }
  `,
};

const mutations = {
  insert: () => `
    mutation InsertCredential($credentials: [credentials_insert_input!]!) {
      insert_credentials(objects: $credentials) {
        returning {
          id
        }
      }
    }
  `,
  update: () => `
    mutation UpdateCredential($id: Int!, $credential: credentials_set_input!) {
      update_credentials(where: {id: {_eq: $id}}, _set: $credential) {
        returning {
          id
        }
      }
    }
  `,
  delete: () => `
    mutation DeleteCredential($ids: [Int!]!) {
      delete_credentials(where: {id: {_in: $ids}}) {
        returning {
          id
        }
      }
    }
  `,
};

export default {
  getOne: (id) => authenticatedQuery(queries.getOne(), {}, { id }),
  getAll: () => authenticatedQuery(queries.getAll()),
  insert: (credentials) => authenticatedQuery(mutations.insert(), {}, { credentials }),
  update: (credential) => authenticatedQuery(mutations.update(), {}, { id: credential.id, credential: _.omit(credential, ['id', '__typename']) }),
  delete: (ids) => authenticatedQuery(mutations.delete(), {}, { ids: Array.isArray(ids) ? ids : [ids] }),
};

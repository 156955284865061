import 'dayjs/locale/fr';

import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import localeData from 'dayjs/plugin/localeData';
import updateLocale from 'dayjs/plugin/updateLocale';
import weekday from 'dayjs/plugin/weekday';

dayjs.extend(weekday);
dayjs.extend(localeData);

dayjs.extend(isBetween);

dayjs.extend(updateLocale);
dayjs.updateLocale('fr-FR', {
  weekStart: 0,
});
export default dayjs;

import { methods } from '../utils';
import { authenticatedMutation, authenticatedQuery, authenticatedService } from './middleware';

const { PUT, DELETE } = methods;
const routes = {
  rgpd: process.env.REACT_APP_BASE_API_URL + '/legal/rgpd',
  cgu: process.env.REACT_APP_BASE_API_URL + '/legal/cgu',
  proof: process.env.REACT_APP_BASE_API_URL + '/legal/proof',
  pdf: process.env.REACT_APP_BASE_API_URL + '/legal/introPdf',
};

/**
 * GRAPHQL QUERIES
 */
const queries = {
  cgu: () => `
    query GetCgu {
      cgu(where: {is_active: {_eq: true}}) {
        id
        value
        type
        language_id
        loid
        version
      }
    }
  `,
  rgpd: () => `
    query GetRgpd {
      rgpd(where: {is_active: {_eq: true}}) {
        id
        type
        language_id
        loid
        version
      }
    }
  `,
};
/**
 * GRAPHQL MUTATION
 */

const mutations = {
  resetCGU: () => `
    mutation resetCGU($roles: [enum_role_type!]!) {
      update_users(where: {role: {_in: $roles}}, _set: {accepted_cgu: false}) {
        returning {
          id
        }
      }
    }
  `,
  resetRGPD: () => `
    mutation resetRGPD($roles: [enum_role_type!]!) {
      update_users(where: {role: {_in: $roles}}, _set: {accepted_rgpd: false}) {
        returning {
          id
        }
      }
    }
  `,
  insertRGPD: () => `
    mutation InsertRGPD($language_id: Int!, $type: String!, $loid: Int!, $version: String!, $roles: [enum_role_type!]!) {
      update_rgpd(where: {language_id: {_eq: $language_id}, type: {_eq: $type}}, _set: {is_active: false}) {
        returning {
          id
        }
      }
      insert_rgpd(objects: {language_id: $language_id, type: $type, is_active: true, loid: $loid, version: $version}) {
        returning {
          id
        }
      }
      update_users(where: {language_id: {_eq: $language_id}, role: {_in: $roles}}, _set: {accepted_rgpd: false}) {
        returning {
          id
        }
      }
    }
  `,
  insertCGU: () => `
    mutation InsertCGU($language_id: Int!, $type: String!, $loid: Int!, $version: String!, $roles: [enum_role_type!]!) {
      update_cgu(where: {language_id: {_eq: $language_id}, type: {_eq: $type}}, _set: {is_active: false}) {
        returning {
          id
        }
      }
      insert_cgu(objects: {language_id: $language_id, type: $type, is_active: true, loid: $loid, version: $version}) {
        returning {
          id
        }
      }
      update_users(where: {language_id: {_eq: $language_id}, role: {_in: $roles}}, _set: {accepted_cgu: false}) {
        returning {
          id
        }
      }
    }
  `,
};

export default {
  insertCGU: (cgu) => authenticatedMutation(mutations.insertCGU(), {}, { ...cgu }),
  insertRGPD: (rgpd) => authenticatedMutation(mutations.insertRGPD(), {}, { ...rgpd }),
  uploadCGU: (formdata, type) => authenticatedService(PUT, routes.cgu, formdata, { type: type }),
  uploadRGPD: (formdata, type) => authenticatedService(PUT, routes.rgpd, formdata, { type: type }),
  uploadProof: (formData) => authenticatedService(PUT, routes.proof, formData),
  uploadPdf: (formData) => authenticatedService(PUT, routes.pdf, formData),
  deletePdf: (introductionQuestionTranslationId) => authenticatedService(DELETE, routes.pdf, { introductionQuestionTranslationId }),
  cgu: () => authenticatedQuery(queries.cgu()),
  resetCGU: (roles) => authenticatedMutation(mutations.resetCGU(), {}, { roles }),
  resetRGPD: (roles) => authenticatedMutation(mutations.resetRGPD(), {}, { roles }),

  rgpd: () => authenticatedQuery(queries.rgpd()),
};

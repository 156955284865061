import { format, startOfMonth, subMonths } from 'date-fns';

import { methods } from '../utils';
import { SURVEY_STATUS } from '../utils/calimed-enum';
import { authenticatedMutation, authenticatedQuery, authenticatedService } from './middleware';

const { POST } = methods;

/**
 * GRAPHQL QUERIES
 */
const queries = {
  getMedics: () => `
    query GetMedicList {
      medics {
        id
        is_premium
        is_secretary
        gender
        firstname
        lastname
        title
        email
        country
        rpps_number
        phone_number
        local_phone_number
        user_id
        created_at
        updated_at
        connector_medical_teams_id
        role
        clinical_trial_id
        medical_teams {
          id
          title
          owner_id
        }
        medical_teams_medics {
          medical_team {
            id
            owner_id
            title
          }
        }
        medic_specialities {
          speciality_id
        }
        clinic_medics {
          clinic_id
        }
        user {
          username
          role
        users_roles {
          role {
            id
            name
          }
        }
          language {
            id
            name
          }
        }
        surveysByReferentMedicId_aggregate(
          where: {
            status: {_in: ["${SURVEY_STATUS.ACCEPTED}", "${SURVEY_STATUS.COUNTERSIGNED}"]},
            is_signed: {_eq: true}, 
            updated_at: {
              _gte: "${format(subMonths(startOfMonth(new Date()), 1), 'yyyy/MM/dd')}", 
              _lt: "${format(startOfMonth(new Date()), 'yyyy/MM/dd')}"
            }
          }
        ) {
          aggregate {
            count
          }
        }
      }
    }
  `,
  medics: () => `
    query GetMedic($id: Int) {
      medics(where: {id: {_eq: $id}}) {
        id
        is_premium
        is_secretary
        gender
        firstname
        lastname
        title
        email
        country
        rpps_number
        phone_number
        user_id
        created_at
        updated_at
        connector_medical_teams_id
        role
        clinical_trial_id
        medical_teams {
          id
          title
          owner_id
        }
        medical_teams_medics {
          medical_team {
            id
            owner_id
            title
          }
        }
        medic_specialities {
          speciality_id
        }
        clinic_medics {
          clinic_id
        }
        user {
          username
          role
          language_id
          users_roles {
            role {
              id
              name
            }
          }
          language {
            id
            name
          }
        }
        surveysByReferentMedicId_aggregate(
          where: {
            status: {_in: ["${SURVEY_STATUS.ACCEPTED}", "${SURVEY_STATUS.COUNTERSIGNED}"]},
            is_signed: {_eq: true}, 
            updated_at: {
              _gte: "${format(subMonths(startOfMonth(new Date()), 1), 'yyyy/MM/dd')}", 
              _lt: "${format(startOfMonth(new Date()), 'yyyy/MM/dd')}"
            }
          }
        ) {
          aggregate {
            count
          }
        }
      }
    }
  `,
};
/**
 * GRAPHQL MUTATION
 */

const mutations = {
  linkPathSpeMedicsCreate: () => `
    mutation LinkPathSpeMedicsCreate(
      $medicId: Int!,
      $medicSpecialities: [medic_specialities_insert_input!]!
    ) {
      delete_medic_specialities(where: {medic_id: {_eq: $medicId}}) {
        returning {
          medic_id
        }
      }
      insert_medic_specialities(objects: $medicSpecialities) {
        returning {
          medic_id
        }
      }
    }
  `,
  medicsUpdate: () => `
    mutation UpdateMedic(
      $id: Int!
      $medicPayload: medics_set_input
      $medicSpecialities: [medic_specialities_insert_input!]!
      $medicalTeams: [medical_teams_medics_insert_input!]!
      $medicUserPayload: users_set_input
      $userRoles: [users_roles_insert_input!]!
    ) {
      delete_users_roles(where: {user: {medics: {id: {_eq: $id}}}}) {
        returning {
          id
        }
      }
      insert_users_roles(objects: $userRoles) {
        returning {
          id
        }
      }
      delete_medic_specialities(where: { medic_id: { _eq: $id } }) {
        returning {
          medic_id
        }
      }
      insert_medic_specialities(objects: $medicSpecialities) {
        returning {
          medic_id
        }
      }
      delete_medical_teams_medics(where: { medic_id: { _eq: $id } }) {
        returning {
          medic_id
        }
      }
      insert_medical_teams_medics(objects: $medicalTeams) {
        returning {
          medic_id
        }
      }
      update_users(where: { medics: { id: { _eq: $id } } }, _set: $medicUserPayload) {
        returning {
          id
        }
      }
      update_medics(where: { id: { _eq: $id } }, _set: $medicPayload) {
        returning {
          id
          is_premium
          is_secretary
          gender
          firstname
          lastname
          title
          email
          country
          rpps_number
          phone_number
          user_id
          created_at
          updated_at
          connector_medical_teams_id
          user {
            username
            role
          }
          medical_teams {
            id
            title
            owner_id
          }
          medic_specialities {
            speciality_id
          }
          medical_teams {
            id
            title
            owner_id
          }
          medical_teams_medics {
            medical_team {
              id
              owner_id
              title
            }
          }
        }
      }
    }
  `,
  // when deleting a pro
  // we keep patient and medic_template attached to the medical team
  medicsDelete: () => `
    mutation MedicsDelete($ids: [Int!]!) {
      delete_medic_specialities(where: {medic_id: {_in: $ids}}) {
        returning {
          medic_id
        }
      }
      delete_clinic_medics(where: {medic_id: {_in: $ids}}) {
        returning {
          medic_id
        }
      }
      delete_medical_teams_medics(where: {medic_id: {_in: $ids}}) {
        returning {
          medic_id
        }
      }
      update_medic_templates(where: {medic_id: {_in: $ids}}, _set: {medic_id: null}) {
        returning {
          id
        }
      }
      update_surveys_remove_assigned:update_surveys(where: {assigned_id: {_in: $ids}}, _set: {assigned_id: null}) {
        returning {
          id
        }
      }
      update_surveys_remove_creator:update_surveys(where: {creator_id: {_in: $ids}}, _set: {assigned_id: null}) {
        returning {
          id
        }
      }
      update_surveys_remove_referent:update_surveys(where: {referent_medic_id: {_in: $ids}}, _set: {assigned_id: null}) {
        returning {
          id
        }
      }
      delete_medic_surveys(where: {medic_id: {_in: $ids}}) {
        returning {
          medic_id
        }
      }
      delete_user_languages(where:{user : {medics: {id: {_in: $ids}} }}) {
        returning {
          id
        }
      }
      delete_users_roles(where: {user: {medics: {id: {_in: $ids}}}}) {
        returning {
          id
        }
      }
      delete_users(where: {medics: {id: {_in: $ids}}}) {
        returning {
          id
        }
      }
      delete_medics(where: {id: {_in: $ids}}) {
        returning {
          id
        }
      }
    }
    
  `,
  produceMail: (mail) => `
    mutation {
      produce(
        arg1: {
        topic: "messenger_mail",
        message: "{\\"template\\":\\"${mail.template}\\",\\"value\\": ${JSON.stringify(mail.value).replace(new RegExp('"', 'g'), '\\"')} }"
      }) {
        message
      }
    }
  `,
  updatePassword: () => `
  mutation UpdatePassword($userId: Int!, $password: String!) {
    update_users(
      where: {id: {_eq: $userId}}, 
      _set: {password: $password}
    ) {
        returning {
          id
        }
      }
    }
  `,
  linkClinicsMedic: () => `
    mutation LinkClinicsMedic(
      $medicId: Int!,
      $clinicList: [clinic_medics_insert_input!]!
    ) {
      delete_clinic_medics(where: {medic_id: {_eq: $medicId}}) {
        returning {
          medic_id
        }
      }
      insert_clinic_medics(objects: $clinicList) {
        returning {
          medic_id
          clinic_id
        }
      }
    }
  `,
  clearStoreTemplates: () => `
    mutation ClearStoreTemplates($medicID: Int!) {
      update_medic_templates (
        where: {
          store_id: {_gt: 0}, 
          _and: {medic_id: {_eq: $medicID}}
        },
        _set: {
          is_archived: true, 
          archived_date: "${format(new Date(), 'yyyy-MM-dd')}"
        }
      ) {
        returning {
          id
        }
      }
    }
  `,
};

const routes = {
  authMedics: process.env.REACT_APP_BASE_API_URL + '/V2/medics',
};

export default {
  getMedics: () => authenticatedQuery(queries.getMedics(), {}, {}),
  medics: (id) => authenticatedQuery(queries.medics(), {}, { id }),
  medicsCreate: (medic) => authenticatedService(POST, routes.authMedics, [medic], {}, {}),
  linkPathSpeMedicsCreate: (medicId, medicSpecialities) =>
    authenticatedMutation(mutations.linkPathSpeMedicsCreate(), {}, { medicId, medicSpecialities }),
  linkClinicsMedic: (medicId, clinicList) => authenticatedMutation(mutations.linkClinicsMedic(), {}, { medicId, clinicList }),
  medicsUpdate: ({ id, medicPayload, medicUserPayload, medicSpecialities, medicalTeams, userRoles }) =>
    authenticatedMutation(mutations.medicsUpdate(), {}, { id, medicPayload, medicUserPayload, medicSpecialities, medicalTeams, userRoles }),
  medicsDelete: (medicIds) => authenticatedMutation(mutations.medicsDelete(), {}, { ids: medicIds }),
  produceMail: (mail) => authenticatedMutation(mutations.produceMail(mail)),
  updatePassword: (medic) =>
    authenticatedMutation(
      mutations.updatePassword(),
      {},
      {
        userId: medic.user.id,
        password: medic.user.password,
      },
    ),
  clearStoreTemplates: (medicID) => authenticatedMutation(mutations.clearStoreTemplates(), {}, { medicID }),
};

import './consents.css';

import { Collapse, DatePicker, Radio, Row, Typography } from 'antd';
import locale from 'antd/es/date-picker/locale/fr_FR';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ShortId from 'shortid';

import { parseDate } from '../../../services/datefns';
import dayjs from '../../../services/dayjs';
import { SURVEY_STATUS } from '../../../utils/calimed-enum';
import { filterAndOrderList, isNullOrWhitespace } from '../../../utils/utils';
import Filter from '../../components/filter';
import { TAGS, Tags } from '../../components/tags';
import DataTable from './DataTable';

const { Text } = Typography;
const { RangePicker } = DatePicker;
const dateFormat = 'DD/MM/YYYY';
const CONSENT_FILTER_KEYS = ['title', 'patient.firstname', 'patient.lastname', 'intervention.number'];
const ALL_BUT_SIGNED = 'all_but_signed';

const Consents = ({
  consentsRequest,
  medicsRequest,
  clinicsRequest,
  surveyTypesRequest,
  surveySpecialitiesRequest,
  consents,
  medics,
  clinics,
  types,
  specialities,
  loading,
  interventionsDeleteRequest,
  interventionsArchivedRequest,
  medicalTeamsRequest,
  medicalTeams,
  resumeSurvey,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [valueList, setValueList] = useState([]);
  const [tags, setTags] = useState([]);
  const [filtered, setFiltered] = useState();
  const [reset, setReset] = useState(false);
  const [activeFilters, setActiveFilters] = useState({
    referents: { order: 0 },
    clinics: { order: 0 },
    types: { order: 0 },
    specialities: { order: 0 },
    date: { order: 0 },
    date_consult: { order: 0 },
    status: { order: 0 },
    medic_team: { order: 0 },
  });

  useEffect(() => {
    if (consentsRequest) {
      consentsRequest({});
    }
    if (medicsRequest) {
      medicsRequest();
    }
    if (clinicsRequest) {
      clinicsRequest({});
    }
    if (surveyTypesRequest) {
      surveyTypesRequest({});
    }
    if (surveySpecialitiesRequest) {
      surveySpecialitiesRequest({});
    }
    medicalTeamsRequest && medicalTeamsRequest();
  }, []);

  useEffect(() => {
    setValueList(consents);
  }, [consents]);

  useEffect(() => {
    const newTags = tags;
    const TAG_INDEX = 'MEDIC_TEAM';
    const index = tags.findIndex((t) => t.tagIndex === TAG_INDEX);
    const formatedList = medicalTeams.map((m) => ({ ...m, value: m.title }));
    if (index >= 0) {
      newTags[index] = { ...newTags[index], list: formatedList };
    } else {
      newTags.push({
        list: formatedList,
        tagIndex: TAG_INDEX,
        title: "Choix de l'équipe médicale:",
        placeholder: 'Equipe médicale',
      });
    }
    setTags(newTags);
  }, [medicalTeams && medicalTeams.length]);

  useEffect(() => {
    const newTags = tags;
    const TAG_INDEX = 'REFERENTS';
    const index = tags.findIndex((t) => t.tagIndex === TAG_INDEX);
    const formatedMedics = medics.map((m) => ({ ...m, value: m.firstname + ' ' + m.lastname }));
    if (index >= 0) {
      newTags[index] = { ...newTags[index], list: formatedMedics };
    } else {
      newTags.push({
        list: formatedMedics,
        tagIndex: TAG_INDEX,
        title: 'Choix du praticien réferent:',
        placeholder: 'Praticien référent',
      });
    }
    setTags(newTags);
  }, [medics && medics.length]);

  useEffect(() => {
    const newTags = tags;
    const TAG_INDEX = 'CLINICS';
    const index = tags.findIndex((t) => t.tagIndex === TAG_INDEX);
    const formatedClinics = clinics.map((c) => ({ ...c, value: c.name }));
    if (index >= 0) {
      newTags[index] = { ...newTags[index], list: formatedClinics };
    } else {
      newTags.push({
        list: formatedClinics,
        tagIndex: TAG_INDEX,
        title: "Choix de l'établissement:",
        placeholder: 'Etablissement',
      });
    }
    setTags(newTags);
  }, [clinics && clinics.length]);

  useEffect(() => {
    const newTags = tags;
    const TAG_INDEX = 'TYPES';
    const index = tags.findIndex((t) => t.tagIndex === TAG_INDEX);
    if (index >= 0) {
      newTags[index] = { ...newTags[index], list: types };
    } else {
      newTags.push({
        list: types,
        tagIndex: TAG_INDEX,
        title: 'Choix du type de formulaire:',
        placeholder: 'Type du formulaire',
      });
    }
    setTags(newTags);
  }, [types && types.length]);

  useEffect(() => {
    const newTags = tags;
    const TAG_INDEX = 'SPECIALITIES';
    const index = tags.findIndex((t) => t.tagIndex === TAG_INDEX);
    if (index >= 0) {
      newTags[index] = { ...newTags[index], list: specialities };
    } else {
      newTags.push({
        list: specialities,
        tagIndex: TAG_INDEX,
        title: 'Choix de la spécialité de formulaire:',
        placeholder: 'Spécialité du formulaire',
      });
    }
    setTags(newTags);
  }, [specialities && specialities.length]);

  const onFilter = (result) => {
    if (!result) setFiltered(null);
    else {
      setFiltered(result.map((r) => r.item.id));
      setReset(!reset);
    }
  };

  const onModify = (consent) => {
    navigate(`/consents/${consent.id}`, consent);
  };

  const onDelete = (consent) => {
    if (Array.isArray(consent)) {
      interventionsDeleteRequest(consent);
    } else {
      interventionsDeleteRequest([consent.id]);
    }
  };

  const onArchived = (elements, archived) => {
    if (interventionsArchivedRequest) {
      interventionsArchivedRequest(elements, archived);
    }
  };

  const onTags = (allowList, mode) => {
    const { referents, clinics, types, specialities, date, date_consult, status, medic_team } = activeFilters;
    const orders = [referents.order, clinics.order, types.order, specialities.order, date.order, date_consult.order, status.order, medic_team.order];
    let newActivesFilters = activeFilters;
    if (!allowList || !allowList.length) {
      newActivesFilters[mode.toLowerCase()].order = 0;
    } else {
      const consentTags = TAGS['consents'];
      switch (mode) {
        case consentTags.REFERENTS:
          newActivesFilters.referents = {
            filter: (values) => (values || []).filter((s) => s && s.referent_medic_id && allowList.includes(parseInt(s.referent_medic_id))),
            order: Math.max(...orders.splice(0, 1)) + 1,
          };
          break;
        case consentTags.CLINICS:
          newActivesFilters.clinics = {
            filter: (values) => (values || []).filter((s) => s.clinic_id && allowList.includes(s.clinic_id)),
            order: Math.max(...orders.splice(1, 1)) + 1,
          };
          break;
        case consentTags.TYPES:
          newActivesFilters.types = {
            filter: (values) => (values || []).filter((s) => s.type_id && allowList.includes(s.type_id)),
            order: Math.max(...orders.splice(2, 1)) + 1,
          };
          break;
        case consentTags.SPECIALITIES:
          newActivesFilters.specialities = {
            filter: (values) => (values || []).filter((s) => s.speciality_id && allowList.includes(s.speciality_id)),
            order: Math.max(...orders.splice(3, 1)) + 1,
          };
          break;
        case 'DATE':
          newActivesFilters.date = {
            filter: (values) =>
              (values || []).filter(
                (s) => !isNullOrWhitespace(s?.intervention?.date) && dayjs(s.intervention.date).isBetween(allowList[0], allowList[1]),
              ),
            order: Math.max(...orders.splice(4, 1)) + 1,
          };
          break;
        case 'DATE_CONSULT':
          newActivesFilters.date_consult = {
            filter: (values) =>
              (values || []).filter(
                (s) => !isNullOrWhitespace(s?.intervention?.date_consult) && dayjs(s.intervention.date_consult).isBetween(allowList[0], allowList[1]),
              ),
            order: Math.max(...orders.splice(5, 1)) + 1,
          };
          break;
        case 'STATUS':
          if (allowList === ALL_BUT_SIGNED) {
            newActivesFilters.status = {
              filter: (values) => (values || []).filter((s) => ![SURVEY_STATUS.ACCEPTED, SURVEY_STATUS.COUNTERSIGNED].includes(s.status)),
              order: Math.max(...orders.splice(6, 1)) + 1,
            };
          } else {
            newActivesFilters.status = {
              filter: (values) => (values || []).filter((s) => allowList.includes(s.status)),
              order: Math.max(...orders.splice(6, 1)) + 1,
            };
          }
          break;
        case consentTags.MEDIC_TEAM:
          newActivesFilters.types = {
            filter: (values) => (values || []).filter((s) => s.medical_teams_id && allowList.includes(parseInt(s.medical_teams_id))),
            order: Math.max(...orders.splice(7, 1)) + 1,
          };
          break;
        default:
      }
    }

    let newFiltered = [...consents];

    Object.values(newActivesFilters)
      .filter((f) => f.order > 0)
      .sort((a, b) => a.order - b.order)
      .forEach((f) => {
        newFiltered = f.filter(newFiltered);
      });

    setValueList(newFiltered);
    setActiveFilters(newActivesFilters);
  };

  const extraTags = (
    <>
      <Row style={{ marginBottom: 9 }}>
        <Text style={{ width: 200, marginRight: 5 }}> Date de RDV : </Text>
        <RangePicker
          locale={locale}
          format={dateFormat}
          presets={[
            { label: "Aujourd'hui", value: [dayjs().startOf('day'), dayjs().endOf('day')] },
            { label: 'Semaine', value: [dayjs().startOf('week'), dayjs().endOf('week')] },
            { label: 'Mois', value: [dayjs().startOf('month'), dayjs().endOf('month')] },
          ]}
          onChange={(date) => onTags(date, 'DATE_CONSULT')}
        />
      </Row>
      <Row style={{ alignItems: 'center' }}>
        <Text style={{ width: 200, marginRight: 5 }}> Date d'intervention : </Text>
        <RangePicker
          locale={locale}
          format={dateFormat}
          presets={[
            { label: "Aujourd'hui", value: [dayjs().startOf('day'), dayjs().endOf('day')] },
            { label: 'Semaine', value: [dayjs().startOf('week'), dayjs().endOf('week')] },
            { label: 'Mois', value: [dayjs().startOf('month'), dayjs().endOf('month')] },
          ]}
          onChange={(date) => onTags(date, 'DATE')}
        />
      </Row>
      <br />
      <div style={{ marginBottom: 10 }}>
        <Radio.Group defaultValue="all" buttonStyle="solid">
          <Radio.Button value="all" onClick={() => onTags(null, 'STATUS')}>
            Tous
          </Radio.Button>
          <Radio.Button value="inProgress" onClick={() => onTags([SURVEY_STATUS.SENT], 'STATUS')}>
            En cours
          </Radio.Button>
          <Radio.Button value="validated" onClick={() => onTags([SURVEY_STATUS.ACCEPTED], 'STATUS')}>
            Signé
          </Radio.Button>
          <Radio.Button value="refused" onClick={() => onTags([SURVEY_STATUS.REFUSED], 'STATUS')}>
            Refusé
          </Radio.Button>
          <Radio.Button value="countersigned" onClick={() => onTags([SURVEY_STATUS.COUNTERSIGNED], 'STATUS')}>
            Contre Signé
          </Radio.Button>
          <Radio.Button value="all_but_signed" onClick={() => onTags(ALL_BUT_SIGNED, 'STATUS')}>
            Non Signé
          </Radio.Button>
        </Radio.Group>
      </div>
    </>
  );

  const exportMapper = (toExport) =>
    toExport.map((e) => {
      const surveyType = types.find((t) => t.id === e.type_id);
      const speciality = specialities.find((s) => s.id === e.speciality_id);
      const clinic = clinics.find((c) => c.id === e.clinic_id);
      const referent = medics.find((m) => m.id === e.referent_medic_id);
      return {
        id: e.id,
        Titre: e.title || '',
        Type: surveyType?.translation?.title || '',
        Spécialité: speciality?.translation?.value || '',
        Status: t(e.status),
        Archivé: e.is_archived ? t('Yes') : t('No'),
        Description: e.description || '',
        Commentaire: e.comment || '',
        Patient: e.patient.lastname + ' ' + e.patient.firstname,
        Clinique: clinic?.name || '',
        'Praticien référent': referent?.lastname + ' ' + referent?.firstname,
        'Date RDV': e.intervention.date_consult ? format(parseDate(e.intervention.date_consult), 'dd/MM/yyyy') : null,
        'Date intervention': e.intervention.date ? format(parseDate(e.intervention.date), 'dd/MM/yyyy') : null,
        'Date archivage': e.is_archived ? format(parseDate(e.is_archived), 'dd/MM/yyyy') : null,
      };
    });

  const exportDataToXls = () => exportMapper(Array.isArray(filtered) ? valueList.filter((r) => filtered.includes(r.id)) : valueList);

  return (
    <>
      <Collapse style={{ marginBottom: 9 }}>
        <Tags onTags={onTags} data={tags} tagIndex={'consents'} extra={extraTags} exportFct={exportDataToXls} />
      </Collapse>
      <Filter list={valueList} onFilter={onFilter} keys={CONSENT_FILTER_KEYS} />

      <DataTable
        key={ShortId.generate()}
        items={filterAndOrderList(valueList, filtered)}
        reset={reset}
        loading={loading}
        itemClick={onModify}
        onDelete={onDelete}
        onModify={onModify}
        onArchived={onArchived}
        resumeSurvey={resumeSurvey}
      />
    </>
  );
};

export default Consents;

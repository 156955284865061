import { Button, Card, Divider, Form, Input } from 'antd';
import React from 'react';

const QuestionTypesForm = ({ onValidate, values }) => {
  const onFinish = (values) => {
    onValidate(values);
  };

  return (
    <Card style={{ margin: 25, width: '80%' }}>
      <Form onFinish={onFinish} initialValues={values} layout="vertical">
        <Form.Item name="value" label="Question" rules={[{ required: true, message: 'Question Incorrecte' }]}>
          <Input placeholder="Question" maxLength={50} />
        </Form.Item>
        <Divider type="horizontal" />
        <Form.Item style={{ textAlign: 'center' }}>
          <Button type="primary" htmlType="submit" style={{ width: '55%' }}>
            Enregistrer
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default QuestionTypesForm;

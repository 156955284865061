import _ from 'lodash';

import { authenticatedMutation, authenticatedQuery } from './middleware';

export const APP_KEYS_MINI_FIELDS = `
  fragment appKeysMiniFragments on app_keys {
    id
    created_at
    name
  }
  `;

export const APP_KEYS_FIELDS = `
  fragment appKeysFragments on app_keys {
    id
    created_at
    deleted_at
    updated_at
    name
    client_id
    sk_path
    scope
    user_id
    clinic_id
    cors_from
    config
    appkeys_roles {
      role {
        id
        name
      }
    }
    app_keys_medical_teams {
      medical_team {
        id
        title
      }
    }
    app_keys_settings {
      id
      app_key_id
      default_medic_role
      default_medicalteam_role
      default_medicalteam_settings
      default_speciality_id
      default_survey_type_id
      default_language_id
      default_clinic_id
      is_old_connector
      default_notif_email_patient
      default_notif_sms_patient
    }
    credentials {
      id
      description
      name
      type
      auth_url
      api_url
      details
      user_id
      client_id
      app_key_id
    }
  }
`;
/**
 * GRAPHQL QUERIES
 */
const queries = {
  getAll: () => `
  ${APP_KEYS_MINI_FIELDS}
    query GetAppKeys {
      app_keys {
        ...appKeysMiniFragments
      }
    }
  `,
  getById: () => `
  ${APP_KEYS_FIELDS}
    query AppKeys($id: Int) {
      app_keys(where: { id: { _eq: $id } }) {
        ...appKeysFragments
      }
    }
  `,
};

/**
 * GRAPHQL MUTATION
 *
 */

const mutations = {
  create: () => `
    ${APP_KEYS_FIELDS}
    mutation InsertAppKey($appKey: [app_keys_insert_input!]!) {
      insert_app_keys(objects: $appKey) {
        returning {
          ...appKeysFragments
        }
      }
    }
  `,
  update: () => `
    ${APP_KEYS_FIELDS}
    mutation UpdateAppKey($id: Int!, $appKey: app_keys_set_input!, $appKeysMedicalTeams: [app_keys_medical_teams_insert_input!]!, $appkeysRoles: [appkeys_roles_insert_input!] = [], $credentials: [credentials_insert_input!] = [], $appKeysSettings: [app_keys_settings_insert_input!] = []) {
      update_app_keys(where: {id: {_eq: $id}}, _set: $appKey) {
        returning {
          ...appKeysFragments
        }
      }
      delete_app_keys_medical_teams(where: {app_key_id: {_eq: $id}}) {
        returning {
          id
        }
      }
      insert_app_keys_medical_teams(objects: $appKeysMedicalTeams) {
        returning {
          id
        }
      }
      delete_appkeys_roles(where: {appkey_id: {_eq: $id}}) {
        returning {
          id
        }
      }
      insert_appkeys_roles(objects: $appkeysRoles) {
        returning {
          id
        }
      }
      insert_credentials(objects: $credentials, on_conflict: {constraint: credentials_pkey, update_columns: [description, name, api_url, auth_url, details]}) {
        returning {
          id
        }
      }
      insert_app_keys_settings(objects: $appKeysSettings, on_conflict: {constraint: app_keys_settings_pkey, update_columns: [default_medic_role, default_medicalteam_role, default_medicalteam_settings, default_speciality_id, default_survey_type_id, default_language_id, default_clinic_id, is_old_connector, default_notif_email_patient, default_notif_sms_patient]}) {
        returning {
          id
        }
      }
    }    
  `,
  delete: () => `
    mutation DeleteAppKey($id: Int) {
      delete_app_keys_medical_teams(where: {app_key_id: {_eq: $id}}) {
        returning {
          id
        }
      }

      delete_appkeys_roles(where: {appkey_id: {_eq: $id}}) {
        returning {
          id
        }
      }

      delete_app_keys(where: {id: {_eq: $id}}) {
        returning {
          id
        }
      }
    }
  `,
  deleteMulti: () => `
    mutation DeleteMultiAppKey($ids: [Int!]!) {
      delete_app_keys_medical_teams(where: {app_key_id: {_in: $ids}}) {
        returning {
          id
        }
      }

      delete_appkeys_roles(where: {appkey_id: {_in: $ids}}) {
        returning {
          id
        }
      }

      delete_app_keys(where: {id: {_in: $ids}}) {
        returning {
          id
        }
      }
    }
  `,
  createAppKeyMedicalTeams: () => `
    mutation CreateAppKeyMedicalTeams($appKeysMedicalTeams: [app_keys_medical_teams_insert_input!]!) {
      insert_app_keys_medical_teams(objects: $appKeysMedicalTeams) {
        returning {
          id
        }
      }
    }
  `,
};

export default {
  getAll: () => authenticatedQuery(queries.getAll(), {}, {}),
  getById: (id = null) => authenticatedQuery(queries.getById(), {}, { id }),
  create: (appKey) =>
    authenticatedMutation(
      mutations.create(),
      {},
      {
        appKey: _.omit(appKey, ['id', 'medicTeamIds']),
      },
    ),
  update: (appKey) =>
    authenticatedMutation(
      mutations.update(),
      {},
      {
        id: appKey.id,
        appKey: _.omit(appKey, ['id', 'medicTeamIds', 'appkeys_roles', 'app_keys_settings', 'credentials']),
        appKeysMedicalTeams: (appKey.medicTeamIds || [])
          .filter((el) => !!el)
          .map((id) => ({
            app_key_id: appKey.id,
            medical_teams_id: id,
          })),
        appkeysRoles: (appKey.appkeys_roles || [])
          .filter((el) => !!el)
          .map((id) => ({
            appkey_id: appKey.id,
            role_id: id,
          })),
        appKeysSettings: (appKey.app_keys_settings || [])
          .map((el) => _.omitBy(el, _.isNil))
          .map((el) => ({
            ...el,
            default_medicalteam_settings: _.omitBy(el.default_medicalteam_settings, _.isNil),
          }))
          .map((el) => {
            return _.size(el.default_medicalteam_settings) > 0 ? el : _.omit(el, 'default_medicalteam_settings');
          })
          .filter((el) => _.size(el) > 0)
          // .filter((el) => !!el)
          .map((el) => {
            return el.id && el.id > 0
              ? {
                  ...el,
                  app_key_id: appKey.id,
                  id: el.id,
                }
              : { ..._.omit(el, 'id'), app_key_id: appKey.id };
          }),
        credentials: (appKey.credentials || [])
          .map((el) => _.omitBy(el, _.isNil))
          .filter((el) => _.size(el) > 0)
          .filter((el) => !!el)
          .map((el) => {
            return el.id && el.id > 0
              ? {
                  ...el,
                  app_key_id: appKey.id,
                  id: el.id,
                }
              : { ..._.omit(el, 'id'), app_key_id: appKey.id };
          }),
      },
    ),
  delete: (id) => authenticatedMutation(mutations.delete(id), {}, { id }),
  deleteMulti: (ids) => authenticatedMutation(mutations.deleteMulti(), {}, { ids }),
  createAppKeyMedicalTeams: (appKeysMedicalTeams) => authenticatedMutation(mutations.createAppKeyMedicalTeams(), {}, { appKeysMedicalTeams }),
};

import './style.css';

import { Button, Card, Divider, Form, Input } from 'antd';
import { differenceInDays } from 'date-fns';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import ShortId from 'shortid';

import { parseDate } from '../../../services/datefns';
import { normalizeString } from '../../../utils';
import Editor from '../../components/Editor';
import ReactDatePicker from '../../components/ReactDatePicker/ReactDatePicker';
import ReactSelect from '../../components/ReactSelect/ReactSelect';

const { TextArea } = Input;

const DSC = 'descending';

const FIELDS = {
  filteredSurveys: [],
  filteredTeams: [],
  filteredMedics: [],
  filteredClinics: [],
  filteredPatients: [],
  filteredTutors: [],
};

const DATE_WARNINGS = ['Une urgence médicalement justifiable', 'Une demande spécifique du patient', 'Un accord entre les deux parties'];

const SIDE = ['Gauche', 'Droit'];

const RADIUS = [
  { label: 'Main', keys: ['Auriculaire', 'Annulaire', 'Majeur', 'Index', 'Pouce'] },
  { label: 'Pied', keys: ['Hallux', 'Secundus', 'Tertius', 'Quartus', 'Quintus'] },
];

const ConsentsForm = ({ onValidate, values, patients, surveyTypeOptions, surveys, clinics, teams, medics, edition, onPrevClick }) => {
  const [disabled, setDisabled] = useState(false);
  const [filteredFields, setFilteredsFields] = useState(FIELDS);
  const [showDateWarning, setShowDateWarning] = useState(false);
  const [showRadiusSide, setShowRadiusSide] = useState({ side: false, radius: false });
  const [disabledRadius, setDisabledRadius] = useState(-1);
  const [form] = Form.useForm();

  useEffect(() => {
    if (values) {
      let filters = FIELDS;
      let newTeams = [];
      if (values.creator_id > 0) {
        const id = values.creator_id;
        filters = {
          ...filters,
          filteredTeams: teams.filter((t) => t.owner_id === id),
          filteredClinics: clinics.filter((cl) => cl.clinic_medics.map((m) => m.medic_id).includes(id)),
          filteredPatients: patients.filter((p) => p.medic_id === id),
          filteredSurveys: surveys.filter((s) => s.medic_id === id),
        };
      }
      if (values.medic_surveys && values.medic_surveys.length) {
        newTeams = values.medic_surveys.map((ms) => ms.medic_id);
        filters = {
          ...filters,
          filteredMedics: medics.filter((m) => newTeams.includes(m.id)),
        };
      }
      setFilteredsFields(filters);
      setDisabled(true);
      values.radius = values.radius && JSON.parse(values.radius);
      values.side = values.side && JSON.parse(values.side);
      setShowRadiusSide({
        radius: values.radius !== 'off' && values.radius != null,
        side: values.side !== 'off' && values.side != null,
      });
      form.setFieldsValue({
        ...values,
        number: values.intervention.number,
        template_id: values.template_id,
        date: parseDate(values.intervention.date),
        date_consult: parseDate(values.intervention.date_consult),
        teams: newTeams,
        referent_id: values.referent_medic_id,
        side: values.side,
        radius: Array.isArray(values.radius) && values.radius.map((el) => el.label),
      });
    }
  }, [values]);

  const onFinish = (values) => {
    onValidate && onValidate(values);
  };

  function sortByText(a, b, order = DSC, key = 'value') {
    const diff = _.deburr(_.get(a, key, '').toLowerCase()).localeCompare(_.deburr(_.get(b, key, '').toLowerCase()));

    if (order === DSC) {
      return diff;
    }

    return -1 * diff;
  }

  const sortUsers = (a, b) => {
    const nameA = normalizeString(a.lastname);
    const nameB = normalizeString(b.lastname);
    return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
  };

  const handleValueChange = (changedValues, allValues) => {
    if ('type_id' in changedValues) {
      const { teams } = allValues;
      if (Array.isArray(teams) && teams.length) {
        setFilteredsFields({
          ...filteredFields,
          filteredSurveys: surveys.filter((s) => teams.includes(s.medic_id) && s.type_id === changedValues['type_id']),
        });
      }
    }
    if ('creator_id' in changedValues) {
      if (allValues.teams && allValues.teams.length) {
        form.setFieldsValue({
          ...allValues,
          teams: undefined,
          clinic_id: undefined,
          patient_id: undefined,
          referent_id: undefined,
          type_id: undefined,
          template_id: undefined,
        });
      }
      setFilteredsFields({
        ...filteredFields,
        filteredTeams: teams.filter((t) => t.owner_id === changedValues['creator_id']),
        filteredClinics: clinics.filter((cl) => cl.clinic_medics.map((m) => m.medic_id).includes(changedValues['creator_id'])),
        filteredPatients: patients.filter((p) => p.medic_id === changedValues['creator_id']),
        filteredSurveys: surveys.filter((s) => s.medic_id === changedValues['creator_id']),
      });
    }
    if ('teams' in changedValues && allValues.teams && allValues.teams.length) {
      if (!allValues.teams.includes(allValues.referent_id)) {
        form.setFieldsValue({ ...allValues, referent_id: undefined });
      }
      setFilteredsFields({
        ...filteredFields,
        filteredMedics: medics.filter((m) => allValues['teams'].includes(m.id)),
      });
    }
    if ('date' in changedValues) {
      const { date_consult } = allValues;

      setShowDateWarning(date_consult && differenceInDays(parseDate(changedValues['date']), parseDate(date_consult)) < 15);
    }
    if ('date_consult' in changedValues) {
      const { date } = allValues;
      setShowDateWarning(date && differenceInDays(parseDate(date), parseDate(changedValues['date_consult'])) < 15);
    }
    if ('template_id' in changedValues) {
      const survey = surveys.find((s) => s.id === changedValues['template_id']);
      if (survey) {
        setShowRadiusSide({ radius: survey.radius === 'on', side: survey.side === 'on' });
      }
    }
    if ('patient_id' in changedValues) {
      const patient = patients.find((p) => p.id === changedValues['patient_id']);
      if (patient) {
        setFilteredsFields({
          ...filteredFields,
          filteredTutors: patient.tutorsByPatientId.map((t) => t.signatory),
        });
      }
    }
  };

  const getRadiusSelectValue = (value) => {
    let ret = [];
    value.forEach((s, index) => {
      if (typeof s === 'string' && s.includes('radius-')) {
        const selected = s.split('-')[1];
        const radius = RADIUS.filter((r) => r.label === selected)[0];
        setDisabledRadius(radius.label === 'Main' ? 1 : 0);
        radius.keys.forEach((k) => {
          if (ret.indexOf(k) === -1) {
            ret.push(k);
          }
        });
      } else {
        const radius = RADIUS.filter((r) => r.keys.includes(s))[0];
        if (index === 0) {
          setDisabledRadius(radius.label === 'Main' ? 1 : 0);
        }
        if (ret.indexOf(s) === -1) {
          ret.push(s);
        }
      }
    });
    if (!value.length) {
      setDisabledRadius(-1);
    }
    return ret;
  };

  const handleOnPrevClick = () => {
    if (onPrevClick) {
      onPrevClick();
    }
  };

  const { filteredSurveys, filteredTeams, filteredMedics, filteredClinics, filteredPatients, filteredTutors } = filteredFields;
  return (
    <>
      <Card style={{ margin: 25, width: '80%' }} key={ShortId.generate()}>
        <Form
          onFinish={onFinish}
          initialValues={values}
          layout="vertical"
          form={form}
          // onFieldsChange={onFieldsChange}
          onValuesChange={handleValueChange}
        >
          <Form.Item name="title" label="Titre" rules={[{ required: true, message: 'Titre Incorrect' }]}>
            <Input placeholder="Titre" maxLength={50} disabled={disabled} />
          </Form.Item>
          <Form.Item name="description" label="Description">
            <TextArea placeholder="Description" autoSize={{ minRows: 5, maxRows: 14 }} maxLength={20000} disabled={disabled} />
          </Form.Item>
          <Form.Item name="comment" label="Commentaire">
            <Editor readOnly={disabled} placeholder="Commentaire..." />
          </Form.Item>
          <Form.Item name="date_consult" label="Date du rendez-vous" rules={[{ required: true, message: 'Date de rendez-vous spécifiée' }]}>
            <ReactDatePicker
              format={'dd-MM-yyyy hh:mm'}
              placeholder="Date du rendez-vous"
              style={{ width: '100%' }}
              disabled={disabled}
              showTimeSelect
            />
          </Form.Item>
          <Form.Item name="date" label="Date de l'intervention" rules={[{ required: true, message: "Date d'intervention spécifiée" }]}>
            <ReactDatePicker
              format={'dd-MM-yyyy hh:mm'}
              placeholder="Date de l'intervention"
              style={{ width: '100%' }}
              disabled={disabled}
              showTimeSelect
            />
          </Form.Item>
          {showDateWarning && (
            <Form.Item name="date_warning" label="Raison du non délai de 15 jours" rules={[{ required: true, message: 'Raison requise' }]}>
              <ReactSelect
                placeholder="Sélectionner une raison"
                isSearchable
                options={DATE_WARNINGS.map((dt) => ({
                  value: dt,
                  label: dt,
                }))}
                disabled={disabled}
              />
            </Form.Item>
          )}
          <Form.Item name="number" label="Numéro de l'intervention">
            <Input placeholder="Numéro de l'intervention" disabled />
          </Form.Item>
          <Form.Item name="creator_id" label="Praticien propriétaire" rules={[{ required: true, message: 'Praticien propriétaire non spécifiée' }]}>
            <ReactSelect
              placeholder="Qui est le praticien propriétaire ?"
              isSearchable
              options={medics
                .filter((m) => m.medical_teams.map((md) => md.owner_id).includes(m.id))
                .sort((a, b) => sortUsers(a, b))
                .map((m) => ({
                  value: m.id,
                  label: m.firstname + ' ' + m.lastname,
                }))}
              disabled={disabled}
            />
          </Form.Item>
          <Form.Item name="teams" label="Praticiens" rules={[{ required: true, message: 'Praticiens non spécifiée' }]}>
            <ReactSelect
              placeholder={
                (disabled ? medics : filteredTeams).length
                  ? 'Qui sont les praticiens associés ?'
                  : 'Choisissez premièrement un praticien propriétaire'
              }
              isMulti
              isSearchable
              options={
                disabled
                  ? medics.map((m) => ({
                      value: m.id,
                      label: m.firstname + ' ' + m.lastname,
                    }))
                  : filteredTeams
                      .sort((a, b) => sortByText(a, b, DSC, 'title'))
                      .map((team) => ({
                        label: team.title,
                        options:
                          team.medical_teams_medics &&
                          team.medical_teams_medics
                            .map((el) => el.medic)
                            .sort((a, b) => sortUsers(a, b))
                            .map((m) => ({
                              value: m.id,
                              label: m.firstname + ' ' + m.lastname,
                            })),
                      }))
              }
              disabled={disabled}
            />
          </Form.Item>
          <Form.Item name="referent_id" label="Praticien référent" rules={[{ required: true, message: 'Praticien référent non spécifiés' }]}>
            <ReactSelect
              placeholder={(disabled ? medics : filteredMedics).length ? 'Qui est le praticien référent ?' : 'Choisissez premièrement des praticiens'}
              isSearchable
              options={(disabled ? medics : filteredMedics)
                .sort((a, b) => sortUsers(a, b))
                .map((m) => ({
                  value: m.id,
                  label: m.firstname + ' ' + m.lastname,
                }))}
              disabled={disabled}
            />
          </Form.Item>
          <Form.Item
            name="clinic_id"
            label="Quel est le lieu d'intervention ?"
            rules={[{ required: true, message: "Lieu d'intervention non spécifié" }]}
          >
            <ReactSelect
              placeholder={
                (disabled ? clinics : filteredClinics).length
                  ? "Quel est le lieu d'intervention ?"
                  : 'Choisissz premiérement un praticien propriétaire'
              }
              isSearchable
              options={(disabled ? clinics : filteredClinics)
                .sort((a, b) => sortByText(a, b, DSC, 'name'))
                .map((m) => ({
                  value: m.id,
                  label: m.name,
                }))}
              disabled={disabled}
            />
          </Form.Item>
          <Form.Item name="patient_id" label="Qui est le patient associé ?" rules={[{ required: true, message: 'Patient non spécifié' }]}>
            <ReactSelect
              placeholder={filteredPatients.length ? 'Qui est le patient associé ?' : 'Choisissez premièrement un praticien chef'}
              isSearchable
              options={filteredPatients
                .sort((a, b) => sortUsers(a, b))
                .map((m) => ({
                  value: m.id,
                  label: m.firstname + ' ' + m.lastname,
                }))}
              disabled={disabled}
            />
          </Form.Item>
          {filteredTutors.length ? (
            <Form.Item name="tutored_id" label="Choix du tuteur" rules={[{ required: true, message: 'Tuteur non spécifié' }]}>
              <ReactSelect
                placeholder={'Choisissez le(s) tuteur(s)'}
                isMulti
                isSearchable
                options={filteredTutors
                  .sort((a, b) => sortUsers(a, b))
                  .map((m) => ({
                    value: m.id,
                    label: m.firstname + ' ' + m.lastname,
                  }))}
                disabled={disabled}
              />
            </Form.Item>
          ) : null}
          <Form.Item name="type_id" label="Type de formulaire">
            <ReactSelect
              placeholder={filteredSurveys.length ? 'Choisissez un type de formulaire' : 'Choisissez premièrement des praticiens'}
              isSearchable
              options={(filteredSurveys.length ? surveyTypeOptions : []).sort((a, b) => sortByText(a, b, DSC, 'label'))}
              disabled={disabled}
            />
          </Form.Item>
          <Form.Item name="template_id" label="Quel est le formulaire associé ?" rules={[{ required: true, message: 'Formulaire non spécifié' }]}>
            <ReactSelect
              placeholder={
                (disabled ? surveys : filteredSurveys).length ? 'Quel est le formulaire associé ?' : 'Choisissez premièrement des praticiens'
              }
              isSearchable
              options={(disabled ? surveys : filteredSurveys)
                .map((m) => ({
                  value: m.id,
                  label: m?.translation?.title || '',
                }))
                .sort((a, b) => sortByText(a, b, DSC, 'label'))}
              disabled={disabled}
            />
          </Form.Item>
          {showRadiusSide.side === true && (
            <Form.Item name="side" label="Selection du coté" rules={[{ required: true, message: 'Coté non spécifié' }]}>
              <ReactSelect
                placeholder="Choisissez un/des coté(s) à opérer"
                isMulti
                isSearchable
                options={SIDE.map((m) => ({
                  value: m,
                  label: m,
                }))}
                disabled={disabled}
              />
            </Form.Item>
          )}
          {showRadiusSide.radius === true && (
            <Form.Item
              name="radius"
              label="Selection du rayon"
              rules={[{ required: true, message: 'Rayon non spécifié' }]}
              getValueFromEvent={getRadiusSelectValue}
            >
              <ReactSelect
                placeholder="Choisissez un/des rayons à opérer"
                isMulti
                isSearchable
                options={RADIUS.map((m) => ({
                  value: m.label,
                  // isDisabled: disabledRadius === index,
                  options: m.keys.map((k) => ({
                    // isDisabled: disabledRadius === index,
                    value: k,
                    label: k,
                  })),
                }))}
                disabled={disabled}
              />
            </Form.Item>
          )}
          <Divider type="horizontal" />
          <Form.Item style={{ textAlign: 'center' }}>
            {!edition ? (
              <Button type="primary" htmlType="submit" style={{ width: '55%' }}>
                Enregistrer
              </Button>
            ) : (
              <Button type="primary" onClick={handleOnPrevClick} style={{ width: '55%' }}>
                {' '}
                Revenir à l'écran des consentements{' '}
              </Button>
            )}
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};

export default ConsentsForm;

import _, { initial } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import RolesForm from './RolesForm';
import { MODEL_NAMES, PERMISSIONS } from './RolesForm/RolesForm';

const RolesCreate = ({ rolesList, rolesRequest, rolesCreateRequest, rolesUpdateRequest }) => {
  const { roleId } = useParams();
  const id = roleId && !['roles', 'add'].includes(roleId) ? roleId : null;
  const initialValues = useMemo(() => {
    const selectedRole = rolesList && rolesList[id];
    if (selectedRole) {
      const disallowedQuestionTypesRole = (selectedRole?.permission || []).find((el) => el.desc === 'banned_questionTypes');
      if (disallowedQuestionTypesRole) {
        selectedRole.banned_questionTypes = _.get(disallowedQuestionTypesRole, ['conditions', 'value', '$in'], []);
      }
      const can_medic_templatesRole = (selectedRole?.permission || []).find((el) => el.desc === 'permissions_on_medicTemplates');
      if (can_medic_templatesRole) {
        selectedRole.permissions_on_medicTemplates = _.get(can_medic_templatesRole, ['action'], []);
      }
    }
    return selectedRole || null;
  }, [id, rolesList]);

  const formatedInitialValues = initialValues
    ? {
        ...initialValues,
        permissions: (initialValues?.permissions || []).map((p) => ({
          ...p,
          conditions: p.conditions || null,
        })),
      }
    : {};

  useEffect(() => {
    if (id && rolesRequest) rolesRequest({ id });
  }, [id]);

  const handleValidate = (role) => {
    role.permissions.push({
      inverted: true,
      action: PERMISSIONS.READ,
      subject: MODEL_NAMES.QUESTION_TYPE,
      conditions: { value: { $in: role.banned_questionTypes || [] } },
      desc: 'banned_questionTypes',
    });

    role.permissions.push({
      inverted: false,
      action: role.permissions_on_medicTemplates,
      subject: MODEL_NAMES.MEDIC_TEMPLATES,
      desc: 'permissions_on_medicTemplates',
    });

    role.permissions.push({
      inverted: role.can_access_profile,
      action: PERMISSIONS.ACCESS,
      subject: MODEL_NAMES.MEDIC_TEMPLATES,
      conditions: null,
      desc: 'can_access_profile',
    });

    role.permissions.push({
      inverted: true,
      action: PERMISSIONS.ACCESS,
      subject: MODEL_NAMES.HOSTNAME_PATH,
      conditions: { value: { $in: role.banned_frontUrls || [] } },
      desc: 'banned_frontUrls',
    });

    role.permissions.push({
      inverted: true,
      action: role.owner_permissions_on_medicTemplates,
      subject: MODEL_NAMES.HOSTNAME_PATH,
      conditions: { medic_id: '${medic.id}' },
      desc: 'banned_frontUrls',
    });
    role = _.omit(role, ['banned_questionTypes', 'permissions_on_medicTemplates']);
    if (initialValues == null) {
      rolesCreateRequest(role);
    } else {
      rolesUpdateRequest({ ...role, id: initialValues.id });
    }
  };

  return <RolesForm onValidate={handleValidate} initialValues={formatedInitialValues} />;
};

export default RolesCreate;

import { cronMutations, cronQueries } from './GraphQLRequest';
import { authenticatedQuery } from './middleware';

export default {
  getConfig: () => authenticatedQuery(cronQueries.getConfig),
  getOffsets: () => authenticatedQuery(cronQueries.getOffsets),
  createOffsets: (offsets) => authenticatedQuery(cronMutations.createOffsets, {}, { offsets }),
  createEvent: (event) => authenticatedQuery(cronMutations.addOneEvent, {}, { event }),
  updateEvent: (eventId, addedCommunicationMode, event) =>
    authenticatedQuery(cronMutations.updateOneEvent, {}, { eventId, addedCommunicationMode, event }),

  deleteSome: (ids) => authenticatedQuery(cronMutations.deleteSome, {}, { ids }),
  deleteNotIn: (ids, surveyTypeId) => authenticatedQuery(cronMutations.deleteNotIn, {}, { ids, surveyTypeId }),
  deleteFromSurveyTypeId: (surveyTypeId) => authenticatedQuery(cronMutations.deleteFromSurveyTypeId, {}, { surveyTypeId }),
  updateOffsetFromEventId: (eventId, surveyTypeId, offset) =>
    authenticatedQuery(cronMutations.updateOffsetFromEventId, {}, { eventId, surveyTypeId, offset }),
};

import _ from 'lodash';
import { createReducer } from 'reduxsauce';

import { types } from './actions';

const initialState = {
  list: {},
  loading: false,
  hasMore: true,
};

const startLoader = (state = initialState) => ({ ...state, loading: true });

const stopLoader = (state = initialState) => ({ ...state, loading: false });

const requestSuccess = (state = initialState, action) => {
  const { newList } = action;
  // const { list } = state;
  return { ...state, list: _.keyBy(newList, 'id') };
};

const upsertSuccess = (state = initialState, action) => {
  const { newItem } = action;
  const { list } = state;
  return { ...state, list: _.merge(list, newItem) };
};

const deleteSuccess = (state = initialState, action) => {
  const { id } = action;
  const { list } = state;
  return { ...state, list: _.omit(list, id) };
};

const makeDefaultSuccess = (state = initialState, action) => {
  const { id } = action;
  const { list } = state;
  return {
    ...state,
    list: _.mapValues(list, (o) => {
      return {
        ...o,
        is_default: o.id === id,
      };
    }),
  };
};

export default createReducer(initialState, {
  [types.REQUEST]: startLoader,
  [types.REQUEST_SUCCESS]: requestSuccess,
  [types.REQUEST_FAILURE]: stopLoader,

  [types.UPSERT_SUCCESS]: upsertSuccess,
  [types.DELETE_SUCCESS]: deleteSuccess,
  [types.MAKE_DEFAULT_SUCCESS]: makeDefaultSuccess,
});

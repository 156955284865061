/* eslint-disable no-template-curly-in-string */
import * as yup from 'yup';

import { QUESTION_TYPES } from '../../../../utils/dataMappers';

yup.setLocale({
  mixed: {
    required: 'Le champs est requis',
  },
  string: {
    required: 'Le champs est requis',
  },
  number: {
    required: 'Le champs est requis',
    min: 'Deve ser maior que ${min}',
  },
  boolean: {
    required: 'Le champs est requis',
  },
  date: {
    required: 'Le champs est requis',
  },
  array: {
    required: 'Le champs est requis',
  },
  object: {
    required: 'Le champs est requis',
  },
});

const yupSelectListElement = yup.object().shape({
  value: yup.string(),
  label: yup.string(),
});

const yupQuestionData = (type) => {
  switch (type) {
    case QUESTION_TYPES.dropdown_list:
      // dropdown_list
      return yup.object().shape({
        list: yup.array().of(yupSelectListElement).min(1).required(),
      });
    case QUESTION_TYPES.multiple_choice:
    case QUESTION_TYPES.checkbox_full:
      // multiple_choice or checkbox_full
      return yup.object().shape({
        list: yup.array().of(yupSelectListElement).min(1).required(),
        answer: yup.array().of(yup.string()).nullable(),
      });
    case QUESTION_TYPES.text_answer:
      // text_answer
      return yup.object().shape({ answer: yup.array().of(yup.string()).nullable() });
    case QUESTION_TYPES.pdf:
      // pdf
      return yup.mixed().test('fileList', 'No files', (value) => {
        return (value && value.pdf && value.pdf.type === 'application/pdf') || (value.oid && value.oid > 0);
      });
    case QUESTION_TYPES.validation:
      // validation
      return yup.object().shape({
        first: yup.string().required(),
        second: yup.string().required(),
      });
    default:
  }
};

const yupQuestion = yup.lazy((question) => {
  return yup.object().shape({
    translation: yup.object().shape({
      question: yup.string().trim().min(1, 'surveyForm.steps.questions.question.rules.required'),
      comment: yup.string('surveyForm.steps.comment.rules.format'),
      data: yupQuestionData(question?.type_name),
      desc: yup.string().trim().min(1, 'surveyForm.steps.questions.desc.rules.required'),
      rewording: yup.string().nullable(),
      asked_more_info: yup.boolean().nullable(),
      texte_pdf: yup.string().nullable(),
      incompris: yup.string().nullable(),
    }),
    // .required('surveyForm.steps.comment.rules.required')
    step_id: yup.string(),
    type_id: yup.string(),
    number: yup.number(),
  });
});

const yupStep = yup
  .object()
  .shape(
    {
      number: yup.number().required(),
      survey_id: yup.string(),
      translation: yup.object().shape({
        title: yup.string('surveyForm.steps.title.rules.format').required('surveyForm.steps.title.rules.required').trim(),
      }),
      questions: yup.array().of(yupQuestion).min(1, 'surveyForm.steps.questions.rules.min'),
    },
    'surveyForm.type.rules.format',
  )
  .nullable();

const yupSchemaMedicTemplate = yup.object().shape({
  id: yup.string().nullable(),
  translation: yup.object().shape({
    id: yup.string().nullable(),
    language_id: yup.string().nullable(),
    title: yup.string('surveyForm.title.format.required').required('surveyForm.title.rules.required').trim(),
    description: yup
      .array()
      .of(
        yup.object().shape({
          value: yup.string(),
        }),
      )
      .nullable(),
  }),
  isRadius: yup.boolean(),
  isSide: yup.boolean(),
  medic_id: yup.string().required().trim().min(1),
  medical_teams_id: yup.string().required().trim().min(1),
  type_id: yup.string().required().trim().min(1),
  speciality_id: yup.string().required().trim().min(1),
  is_published: yup.boolean(),
  steps: yup.array().of(yupStep).min(1, 'surveyForm.steps.rules.min'),
});

const yupSchemaStore = yup.object().shape({
  translation: yup.object().shape({
    id: yup.string().nullable(),
    language_id: yup.string().nullable(),
    title: yup.string('surveyForm.title.format.required').required('surveyForm.title.rules.required').trim(),
    description: yup
      .array()
      .of(
        yup.object().shape({
          value: yup.string(),
        }),
      )
      .nullable(),
    consent_pdf_template: yup.string().required().trim().min(1),
  }),
  isRadius: yup.boolean(),
  isSide: yup.boolean(),
  type_id: yup.string().required().trim().min(1),
  speciality_id: yup.string().required().trim().min(1),
  is_published: yup.boolean(),
  steps: yup.array().of(yupStep).min(1, 'surveyForm.steps.rules.min'),
});

export default yupSchemaMedicTemplate;
export { yupSchemaMedicTemplate, yupSchemaStore };

import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import QuestionTypesForm from '../../components/questionTypesForm/QuestionTypesForm';

const QuestionTypesCreate = ({ questionTypesList, questionTypesCreateRequest, questionTypesUpdateRequest }) => {
  const { questionTypeId } = useParams();
  const id = questionTypeId && !['questionTypes', 'add'].includes(questionTypeId) ? questionTypeId : null;

  const form = useMemo(() => {
    return id && questionTypesList && questionTypesList[id];
  }, [id, questionTypesList]);

  const onValidate = (value) => {
    if (form == null) {
      questionTypesCreateRequest(value);
    } else {
      value.id = form.id;
      questionTypesUpdateRequest(value);
    }
  };

  return (
    <>
      <QuestionTypesForm
        onValidate={onValidate}
        values={form}
        // eslint-disable-next-line no-console
        onError={(err) => console.error(err)}
        questionTypes={Object.values(questionTypesList)}
      />
    </>
  );
};

export default QuestionTypesCreate;

import { createAction } from '../../utils';

// Types
export const types = {
  START_LOADER: 'languages_START_LOADER',
  STOP_LOADER: 'languages_STOP_LOADER',
  FETCH_ALL: 'languages_FETCH_ALL',
  REQUEST: 'languages_REQUEST',
  UPSERT: 'languages_UPSERT',
  DELETE: 'languages_DELETE',
  DELETE_SUCCESS: 'languages_DELETE_SUCCESS',
  FETCH_ALL_SUCCESS: 'languages_FETCH_ALL_SUCCESS',
  CLEAR: 'languages_CLEAR',
};

// Actions
export default {
  startLoader: () => createAction(types.START_LOADER),
  stopLoader: () => createAction(types.STOP_LOADER),

  fetchAll: () => createAction(types.FETCH_ALL),
  request: (id) => createAction(types.REQUEST, { id }),
  delete: (ids) => createAction(types.DELETE, { ids }),
  deleteSuccess: (ids) => createAction(types.DELETE_SUCCESS, { ids }),
  fetchAllSuccess: (languages, toBeMerged = false) => createAction(types.FETCH_ALL_SUCCESS, { languages, toBeMerged }),
  upsert: (payload) => createAction(types.UPSERT, { payload }),
  clear: () => createAction(types.CLEAR),
};

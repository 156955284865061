import { PATIENT_CORE_FIELDS, PATIENT_MINIMAL_FIELDS } from './patientFragments';
import { SURVEYS_CORE_FIELDS } from './surveyFragments';

/**
 * GRAPHQL QUERIES
 */
export default {
  patient: `
    ${SURVEYS_CORE_FIELDS}
    ${PATIENT_CORE_FIELDS}
    query Patient($id: Int!) {
      patients(where: {
        id: {_eq: $id}
      }) {
        ...patientsCoreFields
        tutorsByPatientId {
          signatory {
            ...patientsCoreFields
          }
        }
        surveys(order_by: {intervention: {number: desc}}) {
          ...surveysCoreFields
        }
      }
    }
  `,
  getPatients: `
    ${PATIENT_MINIMAL_FIELDS}
    query GetMedicPatients {
      patients_aggregate {
        aggregate {
          count(distinct: false)
        }
      }
      patients {
        ...patientsMinimalFields
        tutorsByPatientId {
          signatory {
            ...patientsMinimalFields
          }
        }
      }
    }
  `,
  patients: `
    ${PATIENT_MINIMAL_FIELDS}
    query MedicPatients($_gte: timestamptz!, $ids: [Int!]) {
      patients(where: {
        is_tutor: {_eq: false},
        id: {_in: $ids}
      }) {
        ...patientsMinimalFields
        surveys(
          order_by: {intervention: {date: desc}}, 
          where: {intervention: {date: {_gte: $_gte}}},
          # limit: 1
        ) {
          id
          title
          is_signed
          status
          intervention {
            date
            number
          }
        }
        tutorsByPatientId {
          signatory {
            ...patientsMinimalFields
          }
        }
      }
    }
  `,
};

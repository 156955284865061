import _ from 'lodash';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { CronService, FilesService, IntroductionQuestionService, SurveyTypesService } from '../../services';
import { isNullOrWhitespace } from '../../utils/utils';
import { AuthActions } from '../auth';
import { LoaderActions } from '../loader';
import { SnackActions } from '../snackBar';
import { default as SurveyTypeActions, types } from './actions';

function* surveyTypesRequest({ filters }) {
  const { languageId } = yield select((state) => state.auth);
  const id = (filters && filters.id) || null;
  const [error, response] = id ? yield call(SurveyTypesService.surveyTypes, id) : yield call(SurveyTypesService.getSurveyTypes);
  if (error && !response) {
    yield put(SnackActions.displayError('surveyType_list_error'));
    yield put(SurveyTypeActions.surveyTypesFailure());
  } else {
    const data = response.survey_types.map((type) => {
      const translation =
        (Array.isArray(type.survey_types_translations) && type.survey_types_translations.find((s) => s.language_id === languageId)) ||
        type.survey_types_translations.find((s) => s.language_id === 1) ||
        type.survey_types_translations[0];
      return {
        ..._.omit(type, 'survey_types_translations', 'introduction_questions'),
        translations: _.keyBy(type.survey_types_translations || [], 'language_id'),
        translation,
        introduction_questions: (type.introduction_questions || []).map((intro) => {
          const iTranslation =
            (Array.isArray(intro.introduction_questions_translations) &&
              intro.introduction_questions_translations.find((s) => s.language_id === languageId)) ||
            intro.introduction_questions_translations.find((s) => s.language_id === 1) ||
            intro.introduction_questions_translations[0];
          return {
            ...intro,
            translation: iTranslation,
            introduction_questions_translations: _.keyBy(intro.introduction_questions_translations, 'language_id'),
          };
        }),
      };
    });
    yield put(SurveyTypeActions.surveyTypesSuccess(data));
  }
}

function* surveyTypeCronUpsertHelper(events, surveyTypeId) {
  const cronWithId = (events || []).filter((el) => el.id);
  // delete cron that have been deleted
  if (cronWithId && cronWithId.length) {
    yield call(
      CronService.deleteNotIn,
      cronWithId.map((el) => el.id),
      surveyTypeId,
    );
  } else {
    // delete all cron from surveyTypeId
    yield call(CronService.deleteFromSurveyTypeId, surveyTypeId);
  }

  // update cron that have id
  // update offest for cron that have id
  if (cronWithId && cronWithId.length) {
    yield all(cronWithId.map((cron) => call(CronService.updateOffsetFromEventId, cron.id, surveyTypeId, cron.offset)));
    yield all(
      cronWithId.map((cron) =>
        call(
          CronService.updateEvent,
          cron.id,
          {
            // insert_communication_mode_event_alerts
            event_alert_id: cron.id,
            communication_mode_id: cron.mode,
          },
          {
            // update_event_alerts
            // owner_id: cron.owner_id,
            survey_type_id: surveyTypeId,
            template_filename: cron.template_filename,
            event_date_ref: cron.event_date_path,
            event_recipient_ref: cron.event_recipient_path,
            // event_offset_id: cron.event_offset_id,
            // communication_mode_event_alerts: {
            //   data: { communication_mode_id: cron.mode },
            // },
          },
        ),
      ),
    );
  }

  // create cron that does not have id
  const cronWithoutId = (events || []).filter((el) => !el.id);
  if (cronWithoutId && cronWithoutId.length) {
    let savedOffsets;
    const [createOffsetsError, createOffsetsResponse] = yield call(
      CronService.createOffsets,
      cronWithoutId.map((el) => ({ offset: el.offset })),
    );
    if (createOffsetsError || !createOffsetsResponse?.insert_offset_event_alerts?.returning) {
      yield put(SnackActions.displayError('surveyType_cron_upsert_error'));
      return;
    } else {
      savedOffsets = [...createOffsetsResponse.insert_offset_event_alerts.returning];
    }
    yield call(
      CronService.createEvent,
      cronWithoutId.map((el, index) => ({
        template_filename: el.template_filename,
        event_recipient_ref: el.event_recipient_path,
        event_date_ref: el.event_date_path,
        survey_type_id: surveyTypeId,
        communication_mode_event_alerts: {
          data: { communication_mode_id: el.mode },
        },
        event_offset_id: savedOffsets[index].id,
      })),
    );
  }
}

function* surveyTypeTranslationUpsertHelper({ translation, surveyTypeId }) {
  const languageId = translation.language_id;
  const [err, data] = yield call(SurveyTypesService.getSurveyTypetranslationCount, languageId, surveyTypeId);
  if (err) return err;
  if (data?.survey_types_translations_aggregate?.aggregate.count > 0) {
    // update
    yield call(SurveyTypesService.surveyTypesUpdateTranslation, translation, surveyTypeId, languageId);
  } else {
    // create
    yield call(SurveyTypesService.surveyTypesCreateTranslation, { ...translation, survey_type_id: surveyTypeId });
  }
}

function* introQuestionTranslationUpsertHelper({ introductionQuestions, surveyTypeId }) {
  // delete all questions translation attached to surveyType but with id not listed
  // delete all questions  attached to surveyType but with id not listed
  const introductionquestionsIds = introductionQuestions.filter((el) => el.id && !isNullOrWhitespace(el.id)).map((el) => el.id);

  const introductionquestionsTranslationIds = (introductionQuestions || [])
    .map((el) => el.translation)
    .filter((el) => el.id && !isNullOrWhitespace(el.id))
    .map((el) => el.id);
  if (introductionquestionsTranslationIds.length || introductionquestionsIds.length) {
    yield call(IntroductionQuestionService.deleteNotIn, surveyTypeId, introductionquestionsIds);
  }
  if (Array.isArray(introductionQuestions) && introductionQuestions.length) {
    // for each intro question, check if file and exchange file for oid
    const filesToUpload = introductionQuestions
      .map((introduction_question) => {
        const {
          translation: { pdf_file },
        } = introduction_question;
        const originFileObj = Array.isArray(pdf_file) && pdf_file.length && pdf_file[0];
        if (originFileObj) {
          return originFileObj?.originFileObj || false;
        }
        return false;
      })
      .filter((el) => !!el);
    if (Array.isArray(filesToUpload) && filesToUpload.length) {
      let imagesCalls = yield all(filesToUpload.map((file) => call(FilesService.sendFile, file)));
      imagesCalls = imagesCalls.reduce((acc, [_err, res], index) => ({ ...acc, [filesToUpload[index].uid]: res.data.oid }), {});
      introductionQuestions = introductionQuestions.map((q) => {
        const translation = q?.translation;
        if (translation?.pdf_file) {
          const pdfUid = q?.translation?.pdf_file[0].uid;
          translation.loid = imagesCalls[pdfUid];
          q.translation = _.omit(translation, ['pdf_file']);
        }
        return q;
      });
    }

    // upsert intro questions
    const [errorIntroquestion, responseIntroquestion] = yield call(
      IntroductionQuestionService.insertIntroductionQuestions,
      introductionQuestions.map((el) => ({ ..._.omit(el, ['translation']), survey_type_id: surveyTypeId })),
    );
    const savedIntroquestion = _.get(responseIntroquestion, ['insert_introduction_questions', 'returning']);
    // insert new introduction_questions_translations
    yield call(
      IntroductionQuestionService.insertIntroductionQuestionTranslations,
      introductionQuestions.map((el, index) => {
        const saved = savedIntroquestion[index];
        return { ...el.translation, introduction_questions_id: saved.id };
      }),
    );
  }
}

function* surveyTypesUpdateRequest({ surveyType }) {
  yield put(LoaderActions.loading());
  // update survey type
  yield call(SurveyTypesService.surveyTypesUpdate, _.omit(surveyType, ['event_alerts', 'introduction_questions', 'translation', 'value']));

  const { introduction_questions: introductionQuestions, event_alerts, translation } = surveyType;

  // delete all translation attached to surveyType but with id not listed
  // Translation
  yield surveyTypeTranslationUpsertHelper({ translation, surveyTypeId: surveyType.id });

  // CRON
  yield surveyTypeCronUpsertHelper(event_alerts, surveyType.id);

  // IntroQuestions
  yield introQuestionTranslationUpsertHelper({ introductionQuestions, surveyTypeId: surveyType.id });

  yield put(LoaderActions.loaded());
  yield put(SnackActions.displayInfo('surveyType_modify_success'));
  yield put(SurveyTypeActions.surveyTypesUpdateSuccess({}));
  yield put(SurveyTypeActions.surveyTypesRequest({}));
  yield put(LoaderActions.loaded());
  yield put(AuthActions.setRedirectUrl('/surveyTypes'));
}

function* surveyTypesCreateRequest({ surveyTypes }) {
  yield put(LoaderActions.loading());
  const [error, data] = yield call(SurveyTypesService.surveyTypesCreate, surveyTypes);
  if (data && !error) {
    yield put(SnackActions.displayInfo('surveyType_create_success'));
    const returnedId = _.get(data, 'insert_survey_types.returning.0.id');
    yield put(SurveyTypeActions.surveyTypesRequest({ id: returnedId }));
    yield put(AuthActions.setRedirectUrl(`/surveyTypes/${returnedId}`));
    yield put(AuthActions.setRedirectUrl('/surveyTypes'));
  } else {
    yield put(SnackActions.displayError('surveyType_create_error'));
  }
  yield put(LoaderActions.loaded());
}

function* surveyTypesDeleteRequest({ id }) {
  yield put(LoaderActions.loading());
  const result = yield call(SurveyTypesService.surveyTypesDelete, id);
  if (result) {
    yield put(SnackActions.displayInfo('surveyType_delete_success'));
    yield put(SurveyTypeActions.surveyTypesDeleteSuccess(id));
    yield put(SurveyTypeActions.surveyTypesRequest({}));
    yield put(AuthActions.setRedirectUrl('/surveyTypes'));
  } else {
    yield put(SnackActions.displayError('surveyType_delete_error'));
  }
  yield put(LoaderActions.loaded());
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  takeLatest(types.SURVEY_TYPES_REQUEST, surveyTypesRequest),
  takeLatest(types.SURVEY_TYPES_UPDATE_REQUEST, surveyTypesUpdateRequest),
  takeLatest(types.SURVEY_TYPES_CREATE_REQUEST, surveyTypesCreateRequest),
  takeLatest(types.SURVEY_TYPES_DELETE_REQUEST, surveyTypesDeleteRequest),
];

import { Button, Form, Input } from 'antd';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ReactSelect from '../ReactSelect/ReactSelect';
import { TEMPLATES } from './surveyTypesPaneConfig';

/*
  First Form.Item id here only to stock id attributed to existing crons
*/

const SurveyTypesPane = ({ formMethods, index, name, onDeletePane, config }) => {
  const { t } = useTranslation();
  const [smsMode, setSmsMode] = useState(false);
  const [patientMode, setPatientMode] = useState(false);

  useEffect(() => {
    // used on edit form, to disabled field from initial values
    // only trigger once
    verifyModes(formMethods.getFieldValue([name, index, 'mode']));
  }, [formMethods.getFieldValue([name, index, 'mode'])]);

  useEffect(() => {
    // used on edit form, to disabled field from initial values
    verifyTemplate(formMethods.getFieldValue([name, 'template_filename']));
  }, [formMethods.getFieldValue([name, 'template_filename'])]);

  const communicationOptions = useMemo(() => {
    return ((config && config.communication_modes) || []).map((curr) => ({
      value: curr.id,
      label: t(curr?.mode?.replaceAll('.', '_')),
    }));
  }, [config && config.communication_mode]);

  const recipientOptions = useMemo(() => {
    return ((config && config.recipient_event_refs) || []).map((curr) => ({
      value: curr.id,
      label: t(curr?.event_recipient_path?.replaceAll('.', '_')),
    }));
  }, [config && config.recipient_event_refs]);

  const recipientPatientId = _.get(
    (recipientOptions || []).find((el) => el.label === "Patient de l'intervention"),
    'value',
  );

  const verifyModes = (modes) => {
    const smsId = _.get(
      communicationOptions.find((el) => el.label === 'SMS'),
      'value',
    );
    if (modes && modes === smsId) {
      setSmsMode(true);
      setFormValue('event_recipient_path', recipientPatientId);
      setFormValue('template_filename', null);
    } else {
      setSmsMode(false);
    }
  };

  const onTemplateChange = (template) => {
    verifyTemplate(template);
  };

  const verifyTemplate = (template) => {
    switch (TEMPLATES[template - 1]) {
      case 'resume-started-survey-mail':
      case 'resume-survey-mail':
        setFormValue('event_recipient_path', recipientPatientId);
        setPatientMode(true);
        break;
      case 'warning-survey-medic-mail':
        const recipients = _.get(config, 'recipient_event_refs', []).map((recipient) => {
          return recipient.value === recipientPatientId ? { ...recipient, isDisabled: true } : recipient;
        });
        _.set(config, 'recipient_event_refs', recipients);
        if (formMethods.getFieldValue([name, index, 'event_recipient_path']) === recipientPatientId) setFormValue('event_recipient_path', null);
        setPatientMode(false);
        break;
      default: {
        setPatientMode(false);
        break;
      }
    }
  };

  const setFormValue = (valueName, value) => {
    const formValues = formMethods.getFieldsValue();
    _.set(formValues, [name, index, valueName], value);
    formMethods.setFieldsValue(formValues);
  };

  const onModeChange = (value) => {
    verifyModes(value);
  };

  return (
    <>
      <Form.Item hidden name={[index, 'id']}>
        <Input style={{ display: 'none' }} />
      </Form.Item>
      <Form.Item name={[index, 'mode']} label={t('cron_communication_label')} rules={[{ required: true, message: t('cron_communication_required') }]}>
        <ReactSelect placeholder={t('cron_communication_placeholder')} isSearchable options={communicationOptions} onChange={onModeChange} />
      </Form.Item>
      <Form.Item
        name={[index, 'template_filename']}
        label={t('template_filename_label')}
        rules={[{ required: !smsMode, message: t('template_filename_required') }]}
      >
        <ReactSelect
          closeMenuOnSelect
          isSearchable
          options={TEMPLATES.map((t, index) => ({ value: index + 1, label: t }))}
          disabled={smsMode}
          onChange={onTemplateChange}
        />
      </Form.Item>
      <Form.Item name={[index, 'event_date_path']} label={t('cron_date_label')} rules={[{ required: true, message: t('cron_date_required') }]}>
        <ReactSelect
          placeholder={t('cron_date_placeholder')}
          closeMenuOnSelect={true}
          isSearchable
          options={((config && config.event_date_refs) || []).map((curr) => ({
            value: curr.id,
            label: t(curr?.event_date_path?.replaceAll('.', '_')),
          }))}
        />
      </Form.Item>
      <Form.Item name={[index, 'offset']} label={t('cron_offset_label')} rules={[{ required: true, message: t('cron_offset_required') }]}>
        <Input type="number" placeholder={t('cron_offset_placeholder')} />
      </Form.Item>
      <Form.Item
        name={[index, 'event_recipient_path']}
        label={t('cron_recipient_label')}
        rules={[{ required: true, message: t('cron_recipient_required') }]}
      >
        <ReactSelect placeholder={t('cron_recipient_placeholder')} isSearchable disabled={patientMode || smsMode} options={recipientOptions} />
      </Form.Item>
      <Button type="primary" danger onClick={() => onDeletePane(index)}>
        {t('delete')}
      </Button>
    </>
  );
};

export default SurveyTypesPane;

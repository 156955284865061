/**
 * GRAPHQL MUTATIONS
 */

// eslint-disable-next-line import/no-anonymous-default-export

export default {
  update: `
    mutation UpdateUser($id: Int!, $userData: users_set_input!) {
      update_users(where: {id: {_eq: $id}}, _set: $userData) {
        returning {
          id
        }
      }
    }
  `,
};

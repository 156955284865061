import _ from 'lodash';
import { createReducer } from 'reduxsauce';

import { types } from './actions';

const initialState = {
  list: {},
  loading: false,
  hasMore: true,
};

const startLoader = (state = initialState) => ({ ...state, loading: true });

const stopLoader = (state = initialState) => ({ ...state, loading: false });

const specialitiesSuccess = (state = initialState, action) => {
  const { specialities } = action;
  const { list } = state;
  return { ...state, list: { ...list, ..._.keyBy(specialities, 'id') }, loading: false, hasMore: specialities.length > 0 };
};

const specialityDeleteSuccess = (state = initialState, action) => {
  const { id } = action;
  const { list } = state;
  const newList = _.omit(list, id);
  const specialities = Object.values(newList);
  return { ...state, list: newList, loading: false, hasMore: specialities.length > 0 };
};

export default createReducer(initialState, {
  [types.SPECIALITIES_CREATE_REQUEST]: startLoader,
  [types.SPECIALITIES_CREATE_SUCCESS]: stopLoader,

  [types.SPECIALITIES_UPDATE_REQUEST]: startLoader,
  [types.SPECIALITIES_UPDATE_SUCCESS]: stopLoader,

  [types.SPECIALITIES_REQUEST]: startLoader,
  [types.SPECIALITIES_SUCCESS]: specialitiesSuccess,
  [types.SPECIALITIES_FAILURE]: stopLoader,

  [types.SPECIALITIES_DELETE_REQUEST]: startLoader,
  [types.SPECIALITIES_DELETE_SUCCESS]: specialityDeleteSuccess,
});

import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import React, { useEffect, useState } from 'react';

const EyeButton = ({ confirm, loading, eye, onClick }) => {
  const [isInvisible, setIsInvisible] = useState(eye);
  const [defaultVisible, setDefaultVisible] = useState(eye);

  useEffect(() => {
    setIsInvisible(eye);
    setDefaultVisible(eye);
  }, [eye]);

  const handleClick = () => {
    if (onClick) onClick();
  };

  const handleMouseOver = () => {
    setIsInvisible(!defaultVisible);
  };

  const handleMouseOut = () => {
    setIsInvisible(defaultVisible);
  };

  return (
    <div onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
      {confirm && (
        <Popconfirm title="Êtes-vous sûr ?" okText="Oui" cancelText="Non" onConfirm={handleClick}>
          <Button shape="circle" icon={isInvisible ? <EyeOutlined /> : <EyeInvisibleOutlined />} loading={loading} />
        </Popconfirm>
      )}
      {!confirm && <Button shape="circle" icon={isInvisible ? <EyeOutlined /> : <EyeInvisibleOutlined />} loading={loading} onClick={handleClick} />}
    </div>
  );
};

export default EyeButton;

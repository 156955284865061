import { Button, Card, Checkbox, Divider, Form, Input, Select } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import { getUserProperty } from '../../../utils';
import ReactSelect from '../ReactSelect/ReactSelect';

const MedicalTeamsForm = ({ onValidate, values, medics, rolesOptions, clinicOptions }) => {
  const [medicalTeams, setMedicalTeams] = useState([]);
  const [medicsById, setMedicsById] = useState([]);
  const [ownerId, setOwnerId] = useState(null);
  const [form] = Form.useForm();
  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    setMedicsById(_.keyBy(medics, 'id'));
  }, [medics]);

  useEffect(() => {
    if (values) {
      const newMedicalTeams = values.medical_teams_medics;
      setMedicalTeams(newMedicalTeams);
      const newInitialValues = {
        ...values,
        medic_id: Array.isArray(newMedicalTeams) && newMedicalTeams.map((m) => m.medic_id),
      };
      if (!_.isEqual(initialValues, newInitialValues)) setInitialValues(newInitialValues);
    }
  }, [values]);

  useEffect(() => {
    if (initialValues) {
      form.resetFields();
    }
  }, [initialValues]);

  const onFinish = (values) => {
    onValidate(values);
  };

  const { TextArea } = Input;

  const DSC = 'descending';

  function sortByText(a, b, order = DSC) {
    if (a && b) {
      const diff = _.deburr(a.lastname.toLowerCase()).localeCompare(_.deburr(b.lastname.toLowerCase()));

      if (order === DSC) {
        return diff;
      }

      return -1 * diff;
    }
  }

  const handleValueChange = (changedValues) => {
    if (changedValues && 'medic_id' in changedValues) {
      // get medics from medicsById based on id list inside changedValues['medic_id']
      const medic_idList = changedValues['medic_id'];
      const newMedicalTeams =
        medic_idList && medic_idList.length
          ? _.chain(medicsById)
              .pick(changedValues['medic_id'])
              .map((el) => ({ medic: el }))
              .value()
          : [];
      if (newMedicalTeams.filter((v) => v.id === ownerId).length === 0) {
        form.resetFields(['owner_id']);
      }
      setMedicalTeams(newMedicalTeams);
    }
    if (changedValues && 'owner_id' in changedValues) {
      setOwnerId(changedValues['owner_id']);
    }
  };

  return (
    <Card style={{ margin: 25, width: '80%' }}>
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={initialValues}
        layout="vertical"
        // onFieldsChange={handleOnChange}
        onValuesChange={handleValueChange}
      >
        <Form.Item name="title" label="Nom de l'équipe" rules={[{ required: true, message: 'Nom Incorrect' }]}>
          <Input placeholder="Nom de l'équipe" maxLength={50} data-testid="MedicalTeamsForm-input-title" />
        </Form.Item>
        <Form.Item name="description" label="Description">
          <TextArea placeholder="Description" autoSize={{ minRows: 5, maxRows: 14 }} maxLength={20000} />
        </Form.Item>
        <Form.Item name="medic_id" label="Qui sont les membres ?" rules={[{ required: true, message: 'Membre(s) non spécifié(s)' }]}>
          <ReactSelect
            placeholder="Qui sont les membres ?"
            isMulti
            closeMenuOnSelect={false}
            name="medic_id"
            isSearchable
            options={
              Array.isArray(medics) &&
              medics
                .sort((a, b) => sortByText(a, b, DSC))
                .map((m) => ({
                  value: m.id,
                  label: getUserProperty(m, 'fullContactName'),
                }))
            }
          />
        </Form.Item>
        <Form.Item name="owner_id" label="Qui est le propriétaire ?" rules={[{ required: true, message: 'Propriétaire non spécifié' }]}>
          <ReactSelect
            placeholder="Qui est le propriétaire ?"
            name="owner_id"
            isSearchable
            options={
              Array.isArray(medicalTeams)
                ? medicalTeams
                    .sort((a, b) => sortByText(a.medic, b.medic, DSC))
                    .map((m) => ({
                      value: m.medic && m.medic.id,
                      label: getUserProperty(m.medic, 'fullContactName'),
                    }))
                : []
            }
          />
        </Form.Item>
        <Divider type="horizontal">Role</Divider>
        <Form.Item name={'role_id'} label="Détail du role" rules={[{ required: true, message: 'role non spécifiée' }]}>
          <Select
            style={{ width: '100%' }}
            // mode="multiple"
          >
            {rolesOptions.map((r) => (
              <Select.Option key={r.value} value={r.value}>
                {r.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="clinic_id" label="A quel établissement est-il rattaché ?">
          <ReactSelect placeholder="A quel établissement est-il rattaché ?" closeMenuOnSelect={true} isSearchable options={clinicOptions} />
        </Form.Item>
        <Divider type="horizontal">Paramètres de l'équipe de médicale</Divider>
        <Form.Item name={['medical_teams_settings', '0', 'show_delay_reason']} valuePropName="checked">
          <Checkbox>Demander un justificatif lorsque le délai consultation/intervention est trop court</Checkbox>
        </Form.Item>
        <Form.Item name={['medical_teams_settings', '0', 'is_security_number_hiden']} valuePropName="checked">
          <Checkbox>Masquer le numéro de sécurité sociale</Checkbox>
        </Form.Item>
        <Divider type="horizontal">Connecteur</Divider>
        <Form.Item name="external_id" label="Id externe" rules={[]}>
          <Input placeholder="Id externe" maxLength={50} disabled />
        </Form.Item>
        <Divider type="horizontal" />
        <Form.Item style={{ textAlign: 'center' }}>
          <Button type="primary" htmlType="submit" style={{ width: '55%' }} data-testid="MedicalTeamsForm-submit-btn">
            Enregistrer
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default MedicalTeamsForm;

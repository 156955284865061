import 'react-datepicker/dist/react-datepicker.css';

import { isDate, toDate } from 'date-fns';
import fr from 'date-fns/locale/fr';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';

import { parseDate } from '../../../services/datefns';

registerLocale('fr', fr);

function ReactDatePicker({ placeholder, value, format = 'dd-MM-yyyy', onChange, disabled, showTimeSelect, ...props }) {
  const [startDate, setStartDate] = useState();
  useEffect(() => {
    if (value) {
      const newVal = toDate(parseDate(value));
      setStartDate(newVal);
    }
  }, [value]);

  const handleChange = (date, action) => {
    if (isDate(date)) {
      setStartDate(date);
      onChange?.(date);
    }
  };

  return (
    <DatePicker
      locale="fr"
      placeholderText={placeholder}
      selected={startDate}
      onChange={handleChange} //only when value has changed
      dateFormat={format}
      className="ant-input"
      showTimeSelect={showTimeSelect}
      disabled={disabled}
    />
  );
}

ReactDatePicker.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date)]),
  onChange: PropTypes.func,
};

export default ReactDatePicker;

import { connect } from 'react-redux';

import { LanguageActions } from '../../../redux/languages';
import FormLanguageSelector from './FormLanguageSelector';

const mapStateToProps = ({ language }) => {
  return {
    languageList: Object.values(language.list || {}),
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchAllLanguages: () => dispatch(LanguageActions.fetchAll()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormLanguageSelector);

import './style.scss';

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { filterAndOrderList } from '../../../utils/utils';
import Filter from '../../components/filter';
import { Tags } from '../../components/tags';
import DataTable from './DataTable';

const ROLES_FILTER_KEYS = ['name'];

const Sftp = ({ list, loading, sftpCredentialsRequest, sftpCredentialsDeleteRequest }) => {
  const navigate = useNavigate();
  const [filtered, setFiltered] = useState();
  const [reset, setReset] = useState(false);

  useEffect(() => {
    sftpCredentialsRequest && sftpCredentialsRequest();
  }, []);

  const sftpCredentials = (list && Object.values(list)) || [];

  const onFilter = (result) => {
    if (!result) setFiltered(null);
    else {
      setFiltered(result.map((r) => r.item.id));
      setReset(!reset);
    }
  };

  const onModify = (sftpCredential) => {
    navigate(`/sftpCredentials/${sftpCredential.id}`, sftpCredential);
  };

  const onDelete = (sftpCredential) => {
    sftpCredentialsDeleteRequest(Array.isArray(sftpCredential) ? sftpCredential : sftpCredential.id);
  };

  const exportDataToXls = () => {
    return Array.isArray(filtered) ? sftpCredentials.filter((r) => filtered.includes(r.Id)) : sftpCredentials;
  };

  return (
    <>
      <Tags exportFct={exportDataToXls} />
      <Filter list={sftpCredentials} onFilter={onFilter} keys={ROLES_FILTER_KEYS} />

      <DataTable
        items={filterAndOrderList(sftpCredentials, filtered)}
        reset={reset}
        loading={loading}
        itemClick={onModify}
        onDelete={onDelete}
        onModify={onModify}
      />
    </>
  );
};

export default Sftp;

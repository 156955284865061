import _ from 'lodash';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { EmailsService } from '../../services';
import { AuthActions } from '../auth';
import { LoaderActions } from '../loader';
import { SnackActions } from '../snackBar';
import { default as EmailsActions, types } from './actions';

function* emailsRequest({ filters }) {
  const { languageId } = yield select((state) => state.auth);
  const id = (filters && filters.id) || null;
  const [error, data] = id ? yield call(EmailsService.getById, id) : yield call(EmailsService.getAll);
  if (!error && data) {
    const emails = data.email_templates.map((et) => {
      const selectedUserLanguage =
        (Array.isArray(et.email_templates_translations) && et.email_templates_translations.find((m) => m.language_id === languageId)) ||
        et.email_templates_translations.find((m) => m.language_id === 1) ||
        et.email_templates_translations[0];
      return {
        ...et,
        email_templates_translations: _.keyBy(et.email_templates_translations, 'language_id'),
        translation: selectedUserLanguage,
      };
    });
    yield put(EmailsActions.emailsSuccess(emails));
  } else {
    yield put(SnackActions.displayError('emails_list_error'));
    yield put(EmailsActions.emailsFailure());
  }
}

function* emailsUpdateRequest({ email }) {
  yield put(LoaderActions.loading());
  const [error, data] = yield call(EmailsService.emailsUpdate, email);
  if (!error && data) {
    yield put(SnackActions.displayInfo('email_modify_success'));
    yield put(EmailsActions.emailsUpdateSuccess({}));
    yield put(EmailsActions.emailsRequest({}));
    yield put(AuthActions.setRedirectUrl('/emails'));
  } else {
    yield put(SnackActions.displayError('email_modify_error'));
  }
  yield put(LoaderActions.loaded());
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [takeLatest(types.EMAILS_REQUEST, emailsRequest), takeLatest(types.EMAILS_UPDATE_REQUEST, emailsUpdateRequest)];

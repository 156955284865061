/*import { Editor as TinymceReact } from '@tinymce/tinymce-react';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { EditorState } from 'draft-js';
let defaultPlugins = [
  'advlist autolink lists link image charmap preview',
  'searchreplace visualblocks fullscreen',
  'insertdatetime media table code paste help wordcount textpattern ',
];
let defaultMenubar = 'file edit view insert format tools table tc';
let defaultToolbar = [
  'undo redo',
  'uploadimage media',
  'link',
  'bold italic backcolor',
  'alignleft aligncenter',
  'alignright alignjustify',
  'bullist numlist outdent indent',
  'removeformat',
  'help language',
  'formatselect',
].join('|');
let defaultOptions = {
  // height: 500,
  branding: false,
  selector: 'textarea',
  menubar: true,
  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
  language: 'fr_FR',
};
let apiKey = process.env.REACT_APP_TINYMCE_KEY;*/

import createVideoPlugin from '@draft-js-plugins/video';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Editor as EditorDraft } from 'react-draft-wysiwyg';

const videoPlugin = createVideoPlugin();

function Editor({ value, onChange, plugins, menubar, toolbar, options = {}, readonly, placeholder, ...props }) {
  /*const editorRef = useRef(null);
  let initOptions = Object.assign({}, defaultOptions, options);*/
  const [isLoading, setIsLoading] = useState(true);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (isLoading && value) {
      const contentBlock = htmlToDraft(value);

      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
      }
      setIsLoading(false);
    }
  }, [value, isLoading]);

  /**
   * Handle editor change
   * @param {EditorState} state
   * @returns {void}
   */
  const handleEditorChange = (state) => {
    setEditorState(state);
    onChange && onChange(draftToHtml(convertToRaw(state.getCurrentContent())));
  };

  return (
    <EditorDraft
      wrapperClassName="wrapper-class"
      editorClassName="editor-class"
      toolbarClassName="toolbar-class"
      placeholder={placeholder}
      editorState={editorState}
      onEditorStateChange={handleEditorChange}
      localization={{ locale: 'fr' }}
      {...props}
      readonly={readonly}
      plugins={[videoPlugin]}
    />
    /*<TinymceReact
      {...props}
      onInit={(evt, editor) => (editorRef.current = editor)}
      apiKey={apiKey}
      init={{
        placeholder,
        readonly,
        plugins: typeof plugins !== 'undefined' ? plugins : defaultPlugins,
        menubar: typeof menubar !== 'undefined' ? menubar : defaultMenubar,
        toolbar: typeof toolbar !== 'undefined' ? toolbar : defaultToolbar,
        ...initOptions,
      }}
      onEditorChange={onChange}
      value={value}
    />*/
  );
}

Editor.propTypes = {
  plugins: PropTypes.array,
  toolbar: PropTypes.string,
  menubar: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default Editor;

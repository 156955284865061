import { createAction } from '../../utils';

// Types
export const types = {
  CLINICS_REQUEST: 'CLINICS_REQUEST',
  CLINICS_FAILURE: 'CLINICS_FAILURE',
  CLINICS_SUCCESS: 'CLINICS_SUCCESS',

  CLINICS_UPDATE_REQUEST: 'CLINICS_UPDATE_REQUEST',
  CLINICS_UPDATE_FAILURE: 'CLINICS_UPDATE_FAILURE',
  CLINICS_UPDATE_SUCCESS: 'CLINICS_UPDATE_SUCCESS',

  CLINICS_CREATE_REQUEST: 'CLINICS_CREATE_REQUEST',
  CLINICS_CREATE_FAILURE: 'CLINICS_CREATE_FAILURE',
  CLINICS_CREATE_SUCCESS: 'CLINICS_CREATE_SUCCESS',

  CLINICS_DELETE_REQUEST: 'CLINICS_DELETE_REQUEST',
  CLINICS_DELETE_SUCCESS: 'CLINICS_DELETE_SUCCESS',

  CLINICS_DELETE_MULTIPLE_REQUEST: 'CLINICS_DELETE_MULTIPLE_REQUEST',
};

// Actions
export default {
  /* CREATE */
  clinicsCreateRequest: (filters = {}) => createAction(types.CLINICS_CREATE_REQUEST, { filters }),
  clinicsCreateSuccess: () => createAction(types.CLINICS_CREATE_SUCCESS),

  /* UPDATE */
  clinicsUpdateRequest: (clinic = {}) => createAction(types.CLINICS_UPDATE_REQUEST, { clinic }),
  clinicsUpdateSuccess: () => createAction(types.CLINICS_UPDATE_SUCCESS),

  /** REQUEST */
  clinicsRequest: (filters = {}) => createAction(types.CLINICS_REQUEST, { filters }),
  clinicsSuccess: (clinics) => createAction(types.CLINICS_SUCCESS, { clinics }),
  clinicsFailure: () => createAction(types.CLINICS_FAILURE),

  clinicsDeleteRequest: (id) => createAction(types.CLINICS_DELETE_REQUEST, { id }),
  clinicsDeleteMultipleRequest: (id) => createAction(types.CLINICS_DELETE_MULTIPLE_REQUEST, { id }),
  clinicsDeleteSuccess: (id) => createAction(types.CLINICS_DELETE_SUCCESS, { id }),
};

import { createReducer } from 'reduxsauce';

import { types } from './actions';

const initialState = {
  list: {},
  loading: false,
  hasMore: true,
};

const startLoader = (state = initialState) => ({ ...state, loading: true });

const stopLoader = (state = initialState) => ({ ...state, loading: false });

const clinicsSuccess = (state = initialState, action) => {
  const { clinics } = action;
  const { list } = state;
  const data = list;
  clinics.forEach((clinic) => {
    data[clinic.id] = clinic;
  });
  return { ...state, list: data, loading: false, hasMore: clinics.length > 0 };
};

const clinicDeleteSuccess = (state = initialState, action) => {
  const { id } = action;
  const { list } = state;
  delete list[id];

  const clinics = Object.values(list);
  const data = {};

  clinics.forEach((clinic) => {
    data[clinic.id] = clinic;
  });
  return { ...state, list: data, loading: false, hasMore: clinics.length > 0 };
};

export default createReducer(initialState, {
  [types.CLINICS_CREATE_REQUEST]: startLoader,
  [types.CLINICS_CREATE_SUCCESS]: stopLoader,

  [types.CLINICS_UPDATE_REQUEST]: startLoader,
  [types.CLINICS_UPDATE_SUCCESS]: stopLoader,

  [types.CLINICS_REQUEST]: startLoader,
  [types.CLINICS_SUCCESS]: clinicsSuccess,
  [types.CLINICS_FAILURE]: stopLoader,

  [types.CLINICS_DELETE_REQUEST]: startLoader,
  [types.CLINICS_DELETE_MULTIPLE_REQUEST]: startLoader,
  [types.CLINICS_DELETE_SUCCESS]: clinicDeleteSuccess,
});

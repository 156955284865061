import { createAction } from '../../utils';

// Types
export const types = {
  MEDIC_TEMPLATE_GET_REQUEST: 'MEDIC_TEMPLATE_GET_REQUEST',
  MEDIC_TEMPLATE_GET_SUCCESS: 'MEDIC_TEMPLATE_GET_SUCCESS',
  MEDIC_TEMPLATE_GET_FAILURE: 'MEDIC_TEMPLATE_GET_FAILURE',

  MEDIC_TEMPLATE_GET_DETAIL_REQUEST: 'MEDIC_TEMPLATE_GET_DETAIL_REQUEST',
  MEDIC_TEMPLATE_GET_DETAIL_SUCCESS: 'MEDIC_TEMPLATE_GET_DETAIL_SUCESS',
  MEDIC_TEMPLATE_GET_DETAIL_FAILURE: 'MEDIC_TEMPLATE_GET_DETAIL_FAILURE',

  MEDIC_TEMPLATE_UPSERT_REQUEST: 'MEDIC_TEMPLATE_UPSERT_REQUEST',
  MEDIC_TEMPLATE_UPSERT_SUCCESS: 'MEDIC_TEMPLATE_UPSERT_SUCCESS',
  MEDIC_TEMPLATE_UPSERT_FAILURE: 'MEDIC_TEMPLATE_UPSERT_FAILURE',

  MEDIC_TEMPLATE_DELETE_REQUEST: 'MEDIC_TEMPLATE_DELETE_REQUEST',
  MEDIC_TEMPLATE_DELETE_SUCCESS: 'MEDIC_TEMPLATE_DELETE_SUCCESS',
  MEDIC_TEMPLATE_DELETE_FAILURE: 'MEDIC_TEMPLATE_DELETE_FAILURE',

  MEDIC_TEMPLATE_GENERATE_PDF_REQUEST: 'MEDIC_TEMPLATE_GENERATE_PDF_REQUEST',
  MEDIC_TEMPLATE_GENERATE_PDF_SUCCESS: 'MEDIC_TEMPLATE_GENERATE_PDF_SUCCESS',
  MEDIC_TEMPLATE_GENERATE_PDF_FAILURE: 'MEDIC_TEMPLATE_GENERATE_PDF_FAILURE',

  MEDIC_TEMPLATE_RESET_GENERATED_URL: 'MEDIC_TEMPLATE_RESET_GENERATED_URL',

  MEDIC_TEMPLATE_DUPLICATE_REQUEST: 'MEDIC_TEMPLATE_DUPLICATE_REQUEST',
  MEDIC_TEMPLATE_DUPLICATE_SUCCESS: 'MEDIC_TEMPLATE_DUPLICATE_SUCCESS',
  MEDIC_TEMPLATE_DUPLICATE_FAILURE: 'MEDIC_TEMPLATE_DUPLICATE_FAILURE',

  CLEAR_SELECTED_TEMPLATE: 'medicTemplates_CLEAR_SELECTED_TEMPLATE',
};

export default {
  medicTemplateGet: () => createAction(types.MEDIC_TEMPLATE_GET_REQUEST),
  medicTemplateGetSuccess: (templates) => createAction(types.MEDIC_TEMPLATE_GET_SUCCESS, { templates }),
  medicTemplateGetFailure: () => createAction(types.MEDIC_TEMPLATE_GET_FAILURE),

  medicTemplateDetail: (id) => createAction(types.MEDIC_TEMPLATE_GET_DETAIL_REQUEST, { id }),
  medicTemplateDetailSuccess: (templates) => createAction(types.MEDIC_TEMPLATE_GET_DETAIL_SUCCESS, { templates }),
  medicTemplateDetailFailure: () => createAction(types.MEDIC_TEMPLATE_GET_DETAIL_FAILURE),

  medicTemplateUpsert: (template) => createAction(types.MEDIC_TEMPLATE_UPSERT_REQUEST, { template }),
  medicTemplateUpsertSuccess: (templateID) => createAction(types.MEDIC_TEMPLATE_UPSERT_SUCCESS, { templateID }),
  medicTemplateUpsertFailure: () => createAction(types.MEDIC_TEMPLATE_UPSERT_FAILURE),

  medicTemplateDelete: (IDs) => createAction(types.MEDIC_TEMPLATE_DELETE_REQUEST, { IDs }),
  medicTemplateDeleteSuccess: (IDs) => createAction(types.MEDIC_TEMPLATE_DELETE_SUCCESS, { IDs }),
  medicTemplateDeleteFailure: () => createAction(types.MEDIC_TEMPLATE_DELETE_FAILURE),

  medicGeneratePDF: (survey) => createAction(types.MEDIC_TEMPLATE_GENERATE_PDF_REQUEST, { survey }),
  medicGeneratePDFSuccess: (url) => createAction(types.MEDIC_TEMPLATE_GENERATE_PDF_SUCCESS, { url }),
  medicGeneratePDFFailure: () => createAction(types.MEDIC_TEMPLATE_GENERATE_PDF_FAILURE),

  medicResetGeneratedURL: () => createAction(types.MEDIC_TEMPLATE_RESET_GENERATED_URL),

  medicTemplateDuplicateRequest: (id) => createAction(types.MEDIC_TEMPLATE_DUPLICATE_REQUEST, { id }),
  medicTemplateDuplicateSuccess: (IDs) => createAction(types.MEDIC_TEMPLATE_DUPLICATE_SUCCESS, { IDs }),
  medicTemplateDuplicateFailure: () => createAction(types.MEDIC_TEMPLATE_DUPLICATE_FAILURE),

  clearSelectedTemplate: () => createAction(types.CLEAR_SELECTED_TEMPLATE),
};

import { call, put, takeLatest } from 'redux-saga/effects';

import { SurveyModelFilesService } from '../../services';
import { SnackActions } from '../snackBar';
import { default as SurveyModelFilesActions, types } from './actions';

function* request({ id }) {
  const [error, data] = id ? yield call(SurveyModelFilesService.requestOne, id) : yield call(SurveyModelFilesService.request);
  if (data && data.survey_model_files && !error) {
    const { survey_model_files } = data;
    yield put(SurveyModelFilesActions.requestSuccess(survey_model_files));
  } else {
    yield put(SnackActions.displayError('survey_list_error'));
    yield put(SurveyModelFilesActions.requestFailure());
  }
}

function* upsert({ payload }) {
  const isUpdate = payload.id;
  const [error, data] = isUpdate ? yield call(SurveyModelFilesService.update, payload) : yield call(SurveyModelFilesService.insert, payload);
  const dataResult = data && (isUpdate ? data.update_survey_model_files : data.insert_survey_model_files);
  if (dataResult && !error) {
    yield put(
      SurveyModelFilesActions.upsertSuccess({
        ...payload,
        id: dataResult?.returning[0].id,
      }),
    );
    yield put(SnackActions.displayInfo('survey_modify_success'));
  } else {
    yield put(SnackActions.displayError('survey_list_error'));
    yield put(SurveyModelFilesActions.upsertFailure());
  }
}

function* deleteFile({ id }) {
  const [error, data] = yield call(SurveyModelFilesService.delete, id);
  if (data && data.delete_survey_model_files && !error) {
    yield put(SurveyModelFilesActions.deleteSuccess(id));
  } else {
    yield put(SnackActions.displayError('survey_list_error'));
    yield put(SurveyModelFilesActions.deleteFailure());
  }
}

function* makeDefault({ id }) {
  const [error, data] = yield call(SurveyModelFilesService.makeDefault, id);
  if (!error && data) {
    yield put(SurveyModelFilesActions.makeDefaultSuccess(id));
  } else {
    yield put(SnackActions.displayError('survey_list_error'));
    yield put(SurveyModelFilesActions.makeDefaultFailure());
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  takeLatest(types.REQUEST, request),
  takeLatest(types.UPSERT, upsert),
  takeLatest(types.DELETE, deleteFile),
  takeLatest(types.MAKE_DEFAULT, makeDefault),
];

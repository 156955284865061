import './style.scss';

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { filterAndOrderList } from '../../../utils/utils';
import Filter from '../../components/filter';
import { Tags } from '../../components/tags';
import DataTable from './DataTable';

const ROLES_FILTER_KEYS = ['name'];

const Credential = ({ list, loading, credentialsRequest, credentialsDeleteRequest }) => {
  const navigate = useNavigate();
  const [filtered, setFiltered] = useState();
  const [reset, setReset] = useState(false);

  useEffect(() => {
    credentialsRequest && credentialsRequest();
  }, []);

  const onFilter = (result) => {
    if (!result) setFiltered(null);
    else {
      setFiltered(result.map((r) => r.item.id));
      setReset(!reset);
    }
  };

  const onModify = (sftpCredential) => {
    navigate(`/credentials/${sftpCredential.id}`, sftpCredential);
  };

  const onDelete = (ids) => {
    credentialsDeleteRequest(ids);
  };

  const exportDataToXls = () => {
    return Array.isArray(filtered) ? list.filter((r) => filtered.includes(r.Id)) : list;
  };

  return (
    <>
      <Tags exportFct={exportDataToXls} />
      <Filter list={list} onFilter={onFilter} keys={ROLES_FILTER_KEYS} />

      <DataTable
        items={filterAndOrderList(list, filtered)}
        reset={reset}
        loading={loading}
        itemClick={onModify}
        onDelete={onDelete}
        onModify={onModify}
      />
    </>
  );
};

export default Credential;

import { ExpandAltOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Card, Divider, Form, Input, Select, Upload } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import signApi from '../../../services/axios/signApi';
import { CONTENT_TYPE_MAPPER } from '../../../utils/calimed-enum';
import ReactSelect from '../../components/ReactSelect/ReactSelect';

const ClinicsGroupsForm = ({ onValidate, initialValues, clinicsList, authorizationToken }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [selectedFile, setSelectedFile] = useState();
  const [imageOid, setImageOid] = useState();

  const uploadProps = {
    maxCount: 1,
    accept: CONTENT_TYPE_MAPPER.IMAGES,
    action: '/file',
    beforeUpload: (file) => {
      const isGoodFileType = file.type === CONTENT_TYPE_MAPPER.JPEG;
      if (!isGoodFileType) {
        // eslint-disable-next-line no-console
        console.error(`${file.name} is not an image format`);
      }
      return isGoodFileType || Upload.LIST_IGNORE;
    },
    onChange: ({ file }) => {
      setSelectedFile({
        file: file?.originFileObj,
      });
    },
    headers: {
      Authorization: authorizationToken,
    },
    onSuccess: (oid) => {
      setImageOid(oid);
    },
    customRequest: (options) => {
      const data = new FormData();
      data.append('file', options.file);
      data.append('file_insert_mode', 'attachment');
      const config = {
        headers: options.headers,
      };
      signApi
        .post(options.action, data, config)
        .then((res) => {
          options.onSuccess(res.data.oid);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
        });
    },
  };

  const onFinish = (values) => {
    if (imageOid) {
      values.logo = imageOid.toString();
    }
    onValidate(values);
  };

  useEffect(() => {
    if (initialValues) {
      form.resetFields();
    }
  }, [initialValues]);

  const handleFinishFailed = ({ _values, errorFields }) => {
    // eslint-disable-next-line no-console
    console.error('errorFields :>> ', errorFields);
  };

  return (
    <Card style={{ margin: 25, width: '80%' }}>
      <Form onFinish={onFinish} onFinishFailed={handleFinishFailed} initialValues={initialValues} layout="vertical" form={form} scrollToFirstError>
        <Form.Item name="name" label="Nom Groupe Cliniques" rules={[{ required: true, message: 'Nom Incorrect' }]}>
          <Input placeholder="Nom Groupe Cliniques" maxLength={70} />
        </Form.Item>
        <Form.Item name="clinics" label="Quelles cliniques sont rattachées à ce groupe ?">
          <ReactSelect
            placeholder="Quelles cliniques sont rattachées à ce groupe ?"
            isMulti
            closeMenuOnSelect={true}
            isSearchable
            options={(clinicsList || []).map((c) => ({ value: c.id, label: c.name }))}
          />
        </Form.Item>
        <Form.Item name="logo" label="Logo">
          <Upload {...uploadProps}>
            <Button icon={<UploadOutlined />} htmlType="button">
              {t('attach_image')}
            </Button>
          </Upload>
        </Form.Item>
        <Divider type="horizontal" />
        <Form.Item style={{ textAlign: 'center' }}>
          <Button type="primary" htmlType="submit" style={{ width: '55%' }}>
            Enregistrer
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default ClinicsGroupsForm;

import { createAction } from '../../utils';

// Types
export const types = {
  CRON_CONFIG_REQUEST: 'CRON_CONFIG_REQUEST',
  CRON_CONFIG_SUCCESS: 'CRON_CONFIG_SUCCESS',
  CRON_CONFIG_FAILURE: 'CRON_CONFIG_FAILURE',
};

// Actions
export default {
  cronConfigRequest: () => createAction(types.CRON_CONFIG_REQUEST),
  cronConfigSuccess: (config) => createAction(types.CRON_CONFIG_SUCCESS, { config }),
  cronConfigFailure: () => createAction(types.CRON_CONFIG_FAILURE),
};

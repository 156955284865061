export const FRONT_AREA = {
  PRO: 'pro',
  PATIENT: 'patient',
};

export const SURVEY_STATUS = {
  ACCEPTED: 'accepted',
  DRAFT: 'draft',
  REFUSED: 'refused',
  SENT: 'sent',
  COUNTERSIGNED: 'countersigned',
  NOT_SIGNED: 'not_signed',
};

export const USER_ROLE = {
  MEDIC: 'medic',
  ADMIN_CLINIC: 'admin_clinic',
  PATIENT: 'patient',
  CALIMED: 'calimed',
  TESTER: 'tester',
};

export const TRIAL_ROLES = {
  TRIAL_PROMOTER: 'trial_promoter',
  INVESTIGATOR: 'investigator',
  EASY_CONSENT: 'easyconsent',
};

export const MEDIC_ROLES = [USER_ROLE.CALIMED, USER_ROLE.MEDIC, USER_ROLE.TESTER, USER_ROLE.ADMIN_CLINIC];

export const TEMPLATE_TYPE = {
  MEDIC: 'medic',
  STORE: 'store',
};

export const TYPE_CONST = {
  CONTACT: 'contact',
  INFO: 'info',
  TUTOR: 'tutor',
  PATIENT: 'patient',
};

export const SURVEY_TYPES = {
  validation: 'validation',
  text_answer: 'text_answer',
  dropdown_list: 'dropdown_list',
  multiple_choice: 'multiple_choice',
  pdf: 'pdf',
  checkbox_full: 'checkbox_full',
};

export const CONTENT_TYPE_MAPPER = {
  PDF: 'application/pdf',
  PNG: 'image/png',
  JPG: 'image/jpg',
  JPEG: 'image/jpeg',
  IMAGES: 'image/png, image/jpeg',
  DOCX: 'application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
};

export const REVERS_CONTENT_TYPE_MAPPER = {
  [CONTENT_TYPE_MAPPER.PDF]: 'pdf',
  [CONTENT_TYPE_MAPPER.PNG]: 'png',
  [CONTENT_TYPE_MAPPER.JPG]: 'jpg',
  [CONTENT_TYPE_MAPPER.JPEG]: 'jpeg',
};

export const CRUD_MAPPER = {
  CREATE: 'create',
  UPDATE: 'update',
  READ: 'read',
  DELETE: 'delete',
};

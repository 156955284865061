import _ from 'lodash';

import { authenticatedMutation, authenticatedQuery } from './middleware';

/**
 * GRAPHQL QUERIES
 */
const queries = {
  getAll: () => `
  query getEmails {
    email_templates {
      id
      type
      email_templates_translations {
        id
        language_id
        email_template_id
        subject
        body
        hidden_text
        intro_paragraph
        btn_title
        btn_href
        link_title
        link_href
        before_sign_paragraph
        language {
          language_code
          country_code_flag
          name
        }
      }
    }
  }
  `,
  getById: () => `
    query Emails($id: Int) {
      email_templates(where: { id: { _eq: $id } }) {
        id
        type
        email_templates_translations {
          id
          language_id
          email_template_id
          subject
          body
          hidden_text
          intro_paragraph
          btn_title
          btn_href
          link_title
          link_href
          before_sign_paragraph
          language {
            language_code
            country_code_flag
            name
          }
        }
      }
    }
  `,
};
/**
 * GRAPHQL MUTATION
 *
 */

const mutations = {
  emailsUpdate: () => `
    mutation UpdateEmail($id: Int!, $emailTemplatesTranslations: [email_templates_translations_insert_input!]!, $emailTemplates: email_templates_set_input!) {
      update_email_templates(where: {id: {_eq: $id}}, _set: $emailTemplates) {
        returning {
          id
        }
      }
      insert_email_templates_translations(objects: $emailTemplatesTranslations, on_conflict: {constraint: email_templates_translations_pkey, update_columns: [subject, body, hidden_text, intro_paragraph, btn_title, btn_href, link_title, link_href, before_sign_paragraph]}) {
        returning {
          id
          __typename
        }
        __typename
      }
    }
  `,
};

export default {
  getAll: () => authenticatedQuery(queries.getAll(), {}, {}),
  getById: (id = null) => authenticatedQuery(queries.getById(), {}, { id }),
  emailsUpdate: (email) =>
    authenticatedMutation(
      mutations.emailsUpdate(),
      {},
      {
        id: email.id,
        emailTemplatesTranslations: email.email_templates_translations,
        emailTemplates: _.omit(email, ['id', 'email_templates_translations']),
      },
    ),
};

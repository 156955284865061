import './snackBar.css';

import React from 'react';
import { useTranslation } from 'react-i18next';

export const SnackBar = ({ isShown, message, level, forceHide }) => {
  const { t } = useTranslation();
  return (
    <div id="infoView" className={isShown ? 'display-info' : 'hide-info'} style={{ backgroundColor: level }}>
      <div>{t(message)}</div>
    </div>
  );
};

import { Button, Popconfirm, Tooltip } from 'antd';
import React from 'react';

const ConfirmButton = ({ title, confirmTitle, confirm, loading, type, shape, icon, onClick, onCancel, disabled, ghost, tooltipTitle, danger }) => {
  const handleClick = () => {
    if (onClick) onClick();
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };
  return (
    <>
      {disabled && (
        <Tooltip title={tooltipTitle || 'Suppression impossible'}>
          <Button disabled={disabled} ghost={ghost} type={type} shape={shape} icon={icon} loading={loading} danger={danger} htmlType="button">
            {title}
          </Button>
        </Tooltip>
      )}
      {!disabled && confirm && (
        <Popconfirm title={confirmTitle || 'Êtes-vous sûr ?'} okText="Oui" cancelText="Non" onConfirm={handleClick} onCancel={handleCancel}>
          <Button disabled={disabled} ghost={ghost} type={type} shape={shape} icon={icon} loading={loading} danger={danger} htmlType="button">
            {title}
          </Button>
        </Popconfirm>
      )}
      {!disabled && !confirm && (
        <Tooltip title={tooltipTitle}>
          <Button
            disabled={disabled}
            ghost={ghost}
            type={type}
            shape={shape}
            icon={icon}
            loading={loading}
            onClick={handleClick}
            danger={danger}
            htmlType="button"
          >
            {title}
          </Button>
        </Tooltip>
      )}
    </>
  );
};

export default ConfirmButton;

import _ from 'lodash';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { TemplatesService } from '../../services';
import { AuthActions } from '../auth';
import { LoaderActions } from '../loader';
import { SnackActions } from '../snackBar';
import { default as TemplatesActions, types } from './actions';

const prepareFormData = (template) => {
  const form = new FormData();
  template.steps = template.steps.map((s, sIndex) => {
    s.questions = s.questions.map((q, qIndex) => {
      if (q.translation?.data?.pdf) {
        form.append(`file-question-${sIndex}-${qIndex}`, q.translation.data.pdf);
        return {
          ...q,
          translation: {
            ...q.translation,
            data: { isPDFType: true },
          },
        };
      }
      return q;
    });
    return s;
  });

  // JSON stringify and split keeps types as boolean
  const payload = JSON.stringify(template).match(/.{1,900000}/g);
  Array.isArray(payload) && payload.forEach((chunk, index) => form.append(`payload_${index}`, chunk));
  form.append('upsert_mode', 'template');
  form.append('source_of_truth', true);

  return form;
};

function* templateGetRequest() {
  yield put(LoaderActions.loading());
  const [error, data] = yield call(TemplatesService.getAll);

  if (!error && data?.templates) {
    yield put(TemplatesActions.templateGetSuccess(data.templates));
  } else {
    yield put(TemplatesActions.templateGetFailure());
  }
  yield put(LoaderActions.loaded());
}

function* templateGetDetailRequest({ id, lngId, keyDestination }) {
  yield put(LoaderActions.loading());
  const [error, data] = yield call(TemplatesService.getDetail, id, lngId);
  if (!error && data) {
    yield put(TemplatesActions.templateGetDetailSuccess(data.templates, keyDestination));
  } else {
    yield put(TemplatesActions.templateGetDetailFailure());
  }
  yield put(LoaderActions.loaded());
}

function* upsertRequest({ template }) {
  yield put(LoaderActions.loading());
  const form = prepareFormData({ ...template });
  const [error, response] = yield call(TemplatesService.upsertTemplate, form);
  if (!error && response.status === 201) {
    yield call(templateGetRequest);
    yield put(SnackActions.displayInfo('survey_create_success'));
    yield put(AuthActions.setRedirectUrl(`/templates/${_.get(response, 'data.template.id')}`));
    yield put(AuthActions.setRedirectUrl('/templates/'));
  } else {
    yield put(TemplatesActions.templateCreateFailure());
    yield put(SnackActions.displayError('surveys_create_error'));
  }
  yield put(LoaderActions.loaded());
}

function* templateCreateRequest({ template }) {
  yield put(LoaderActions.loading());

  const form = prepareFormData(template);

  /*const [error, response] = yield call(TemplatesService.test, form)*/

  const [error, response] = yield call(TemplatesService.createTemplate, form);
  yield put(LoaderActions.loaded());

  if (!error && response.status === 200) {
    /*yield put(TemplatesActions.templateCreateSuccess({...template, id: response.data[0]}))*/
    yield call(templateGetRequest);
    yield put(SnackActions.displayInfo('survey_create_success'));
    // yield put(goBack());
    yield put(AuthActions.setRedirectUrl(`/templates/${_.get(response, 'data.0')}`));
    yield put(AuthActions.setRedirectUrl('/templates/'));
    return;
  }
  // if (error)
  yield put(TemplatesActions.templateCreateFailure());
  if (error.message === 'Network error: Failed to fetch') {
    yield put(SnackActions.displayError('surveys_create_error_large_file'));
  } else {
    yield put(SnackActions.displayError('surveys_create_error'));
  }
}

function* templateUpdateRequest({ template }) {
  yield put(LoaderActions.loading());
  const form = prepareFormData(template);

  const [error, response] = yield call(TemplatesService.templateUpdateRequest, form);
  if (error || response.status !== 200) {
    yield put(TemplatesActions.templatesUpdateFailure());
    if (error.message === 'Network error: Failed to fetch') {
      yield put(SnackActions.displayError('template_update_error_large_file'));
    } else {
      yield put(SnackActions.displayError('template_update_error'));
    }
  } else {
    const [error2, response2] = yield call(TemplatesService.getDetail, template.id);
    if (!error2) {
      let updatedTemplate = response2 && response2.templates[0];
      yield put(TemplatesActions.templatesUpdateSuccess(updatedTemplate));
    } else {
      yield put(TemplatesActions.templatesUpdateSuccess(template));
    }
    yield put(SnackActions.displayInfo('template_update_success'));
    yield put(AuthActions.setRedirectUrl('/templates'));
  }
  yield put(LoaderActions.loaded());
}

function* templateUpdatePublishedRequest({ IDs, status }) {
  yield put(LoaderActions.loading());
  const [templateError, templateResponse] = yield call(TemplatesService.updatePublishedStatus, IDs, status);

  if (templateError || !templateResponse) {
    yield put(SnackActions.displayError('template_published_update_failure'));
    yield put(LoaderActions.loaded());
    return;
  }

  yield put(TemplatesActions.templatesUpdatePublishedSuccess(IDs, status));
  yield put(SnackActions.displayInfo('template_published_update_success'));
  yield put(LoaderActions.loaded());
}

function* templateDeleteRequest({ templates }) {
  yield put(LoaderActions.loading());
  const response = yield all(templates.map((templateId) => call(TemplatesService.delete, templateId)));
  if (response.some((el) => !!el[0])) {
    yield put(SnackActions.displayError('survey_delete_error'));
    yield put(LoaderActions.loaded());
  } else {
    yield put(TemplatesActions.templateDeleteSuccess(templates));
    yield put(SnackActions.displayInfo('survey_delete_success'));
    yield put(LoaderActions.loaded());
  }
}

function* templateDuplicateRequest({ id }) {
  yield put(LoaderActions.loading());
  const [error, response] = yield call(TemplatesService.duplicateTemplate, id);
  if (error || !response) {
    yield put(SnackActions.displayError('survey_duplicate_error'));
    yield put(LoaderActions.loaded());
  } else {
    yield put(AuthActions.setRedirectUrl(`/templates/${response?.data?.template?.id || ''}`));
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  takeLatest(types.TEMPLATE_GET_REQUEST, templateGetRequest),
  takeLatest(types.TEMPLATE_GET_DETAIL_REQUEST, templateGetDetailRequest),
  takeLatest(types.TEMPLATE_CREATE_REQUEST, templateCreateRequest),
  takeLatest(types.TEMPLATE_UPDATE_REQUEST, templateUpdateRequest),
  takeLatest(types.TEMPLATE_UPDATE_PUBLISHED_REQUEST, templateUpdatePublishedRequest),
  takeLatest(types.TEMPLATE_DELETE_REQUEST, templateDeleteRequest),
  takeLatest(types.TEMPLATE_DUPLICATE_REQUEST, templateDuplicateRequest),
  takeLatest(types.UPSERT_REQUEST, upsertRequest),
];

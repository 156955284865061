import { call, takeLatest } from 'redux-saga/effects';

import introductionQuestionsService from '../../services/introductionQuestionsService';
import { types } from './actions';

function* update({ introduction_questions, surveyType }) {
  // delete all questions translation attached to surveyType but with id not listed
  // delete all questions  attached to surveyType but with id not listed
  const introductionquestionsIds = introduction_questions.filter((el) => el.id).map((el) => el.id);
  const introductionquestionsTranslationIds = introduction_questions
    .reduce((acc, el) => [...acc, ...el.introduction_questions_translations], [])
    .filter((el) => el.id)
    .map((el) => el.id);
  yield call(introductionQuestionsService.deleteNotIn, surveyType, introductionquestionsTranslationIds, introductionquestionsIds);

  // update question
  yield call(introductionQuestionsService.updateTranslation);
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [takeLatest(types.UPDATE, update)];

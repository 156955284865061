import { connect } from 'react-redux';

import { RoleActions } from '../../../redux/roles';
import RolesCreate from './RolesCreate';

const mapStateToProps = ({ roles }) => {
  return {
    rolesList: roles.list || {},
  };
};

const mapDispatchToProps = (dispatch) => ({
  rolesRequest: (filter) => dispatch(RoleActions.rolesRequest(filter)),
  rolesCreateRequest: (creation) => dispatch(RoleActions.rolesCreateRequest(creation)),
  rolesUpdateRequest: (role) => dispatch(RoleActions.rolesUpdateRequest(role)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RolesCreate);

import { methods } from '../utils';
import { authenticatedService } from './middleware';

const { GET, POST } = methods;
const routes = {
  files: process.env.REACT_APP_SIGN_API_URL + '/file',
  readIntroductionQuestions: (loid) => process.env.REACT_APP_SIGN_API_URL + `/file/type/introductionQuestions/oids/${loid}`,
};

export default {
  sendFile: (file) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('file_insert_mode', 'intro_questions');
    return authenticatedService(POST, routes.files, formData);
  },
  readIntroductionQuestions: (loid) => authenticatedService(GET, routes.readIntroductionQuestions(loid)),
};

import { methods } from '../utils';
import { authenticatedQuery, authenticatedService } from './middleware';

const { POST } = methods;

const routes = {
  resumeSurvey: (id) => process.env.REACT_APP_BASE_API_URL + `/V2/surveys/${id}/resume`,
};

/**
 * GRAPHQL QUERIES
 */
const queries = {
  consents: () => `
    query {
      surveys(where: { is_template: { _eq: false } }) {
        id
        is_signed
        status
        title
        type_id
        speciality_id
        is_archived
        archived_date
        description
        comment
        patient {
          id
          firstname
          lastname
        }
        clinic_id
        medical_teams_id
        referent_medic_id
        intervention {
          id
          date
          date_consult
          number
        }
      }
    }
  `,
};

export default {
  consents: () => authenticatedQuery(queries.consents()),

  resumeSurvey: (id) => authenticatedService(POST, routes.resumeSurvey(id)),
};

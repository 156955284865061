import { createAction } from '../../utils';

// Types
export const types = {
  START_LOADER: 'introduction_questions_START_LOADER',
  STOP_LOADER: 'introduction_questions_STOP_LOADER',
  FETCH_ALL: 'introduction_questions_FETCH_ALL',
  FETCH_ALL_SUCCESS: 'introduction_questions_FETCH_ALL_SUCCESS',
  CLEAR: 'introduction_questions_CLEAR',
};

// Actions
export default {
  startLoader: () => createAction(types.START_LOADER),
  stopLoader: () => createAction(types.STOP_LOADER),

  fetchAll: () => createAction(types.FETCH_ALL),
  fetchAllSuccess: (introduction_questions) => createAction(types.FETCH_ALL_SUCCESS, { introduction_questions }),
  clear: () => createAction(types.CLEAR),
};

import _ from 'lodash';
import { createReducer } from 'reduxsauce';

import { types } from './actions';

const initialState = {
  list: {},
  loading: false,
  hasMore: true,
};

const startLoader = (state = initialState) => ({ ...state, loading: true });

const stopLoader = (state = initialState) => ({ ...state, loading: false });

const surveyTypesSuccess = (state = initialState, action) => {
  const { surveyTypes } = action;
  const { list } = state;
  return { ...state, list: { ...list, ..._.keyBy(surveyTypes || [], 'id') }, loading: false, hasMore: surveyTypes.length > 0 };
};

const surveyTypeDeleteSuccess = (state = initialState, action) => {
  const { id } = action;
  const { list } = state;
  delete list[id];

  const surveyTypes = Object.values(list);
  const data = {};

  surveyTypes.forEach((surveyType) => {
    data[surveyType.id] = surveyType;
  });
  return { ...state, list: data, loading: false, hasMore: surveyTypes.length > 0 };
};

export default createReducer(initialState, {
  [types.SURVEY_TYPES_CREATE_REQUEST]: startLoader,
  [types.SURVEY_TYPES_CREATE_SUCCESS]: stopLoader,

  [types.SURVEY_TYPES_UPDATE_REQUEST]: startLoader,
  [types.SURVEY_TYPES_UPDATE_SUCCESS]: stopLoader,

  [types.SURVEY_TYPES_REQUEST]: startLoader,
  [types.SURVEY_TYPES_SUCCESS]: surveyTypesSuccess,
  [types.SURVEY_TYPES_FAILURE]: stopLoader,

  [types.SURVEY_TYPES_DELETE_REQUEST]: startLoader,
  [types.SURVEY_TYPES_DELETE_SUCCESS]: surveyTypeDeleteSuccess,
});

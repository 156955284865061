import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import MsWordTemplateForm from './MsWordTemplateForm';

function MsWordTemplateCreate({ surveyModelFilesList, getDetail, upsert }) {
  const handleFinish = (payload) => {
    upsert(payload);
  };
  const { msWordTemplateId } = useParams();
  const id = msWordTemplateId && !['msWordTemplates', 'add'].includes(msWordTemplateId) ? msWordTemplateId : null;

  const initialValues = useMemo(() => {
    return id && surveyModelFilesList && surveyModelFilesList[id];
  }, [id, surveyModelFilesList]);

  useEffect(() => {
    if (id) {
      getDetail(id);
    }
  }, [id]);

  return <MsWordTemplateForm onFinish={handleFinish} initialValues={initialValues} />;
}

MsWordTemplateCreate.propTypes = {};

export default MsWordTemplateCreate;

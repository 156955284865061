import { Children, cloneElement, isValidElement } from 'react';

/**
 * Check if it is a valid ReactElement with children
 * @param element element to check
 * @returns {boolean}
 */
export const hasChildren = (element) => isValidElement(element) && Boolean(element.props.children);

/**
 * Check if it is a complex element. an Element is define "complex" when his children have children
 * @param element element to check
 * @returns {*}
 */
export const hasComplexChildren = (element) =>
  isValidElement(element) &&
  hasChildren(element) &&
  Children.toArray(element.props.children).reduce((response, child) => response || isValidElement(child), false);

/**
 * Map function apply for all children and children of children
 * @param children
 * @param deepMapFn
 * @returns {unknown[]}
 */
export const deepChildrenMap = (children, deepMapFn) => {
  return Children.toArray(children).map((child, index, mapChildren) => {
    if (isValidElement(child) && hasComplexChildren(child)) {
      // Clone the child that has children and map them too
      return deepMapFn(
        cloneElement(child, {
          ...child.props,
          children: deepChildrenMap(child.props.children, deepMapFn),
        }),
      );
    }
    return deepMapFn(child, index, mapChildren);
  });
};

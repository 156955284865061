import { format } from 'date-fns';
import _ from 'lodash';

import { methods } from '../utils';
import { authenticatedMutation, authenticatedService } from './middleware';
const { GET, PUT } = methods;

const routes = {
  getNumber: process.env.REACT_APP_BASE_API_URL + '/knex/intervention_number',
  surveywithPDF: (loid) => process.env.REACT_APP_SIGN_API_URL + `/surveys/create?loid=${loid}`,
};

/**
 * GRAPHQL QUERIES
 */

/**
 * GRAPHQL MUTATION
 *
 */
const mutations = {
  interventionsCreate: () => `
    mutation InterventionsCreate($interventions: [interventions_insert_input!]!) {
      insert_interventions(objects: $interventions) {
        returning {
          id
          created_at
          date
          date_consult
          number
          type
          surveys {
            id
            intervention_id
            patient_id
            type_id
            title
            date_warning
            status
            description
            template_id
            is_template
            is_signed
            comment
          }
        }
      }
    }
  `,
  interventionsUpdate: () => `
    mutation InterventionsUpdate($interventionId: Int!, $intervention: interventions_set_input!) {
      update_interventions(
        where: {
          id: {_eq: $interventionId}
        }, 
        _set: $intervention
      ){
        returning {
          id
          date
          date_consult
          updated_at
        }
      }
    }
  `,
  interventionsDelete: () => `
    mutation InterventionsDelete($IDs: [Int!]!) {
      delete_medic_surveys(where: {survey: {id: {_in: $IDs}}}) {
        returning {
          survey {
            id
          }
        }
      }
      delete_questions_translations(where: {questionByQuestionId: {step: {survey_steps: {survey_id: {_in: $IDs}}}}}) {
        returning {
          id
        }
      }
      delete_questions(where: {step: {survey_steps: {survey_id: {_in: $IDs}}}}) {
        returning {
          id
        }
      }
      delete_steps_translations(where: { step: {survey_steps: {survey_id: {_in: $IDs}}}}) {
        returning {
          id
        }
      }
      delete_steps(where: {survey_steps: {survey_id: {_in: $IDs}}}) {
        returning {
          id
        }
      }
      delete_survey_steps(where: {survey_id: {_in: $IDs}}) {
        returning {
          survey_id
        }
      }
      delete_survey_pdfs(where: {survey_id: {_in: $IDs}}) {
        returning {
          id
        }
      }
      delete_interventions(where: {surveys: {id: {_in: $IDs}}}) {
        returning {
          id
        }
      }
      delete_surveys(where: {id: {_in: $IDs}}) {
        returning {
          id
          intervention_id
        }
      }
    }
  
  `,
  interventionsArchive: () => `
    mutation SurveyArchive($IDs: [Int!]!, $isArchived: Boolean) {
      update_surveys(where: {id: {_in: $IDs}}, _set: {is_archived: $isArchived, archived_date: "${format(new Date(), 'yyyy-MM-dd')}"}) {
        returning {
          id
        }
      }
    }
    `,
  produceMail: (mail) => `
    mutation {
      produce(arg1: {
        topic: "messenger_mail",
        message: "{\\"template\\":\\"${mail.template}\\",\\"value\\": ${JSON.stringify(mail.value).replace(new RegExp('"', 'g'), '\\"')} }"
      }) 
      {
        message
      }
    }
  `,
};

export default {
  // interventionsCreate: (interventions) =>
  //   authenticatedMutation(
  //     mutations.interventionsCreate(),
  //     {},
  //     {
  //       interventions: { ...interventions, status: 'draft', is_signed: false, is_template: false },
  //     },
  //   ),
  interventionsCreate: (intervention) => authenticatedService(PUT, routes.upsert, intervention),
  getInterventionNNumber: () => authenticatedService(GET, routes.getNumber),
  interventionsUpdate: (intervention) =>
    authenticatedMutation(mutations.interventionsUpdate(), {}, { interventionId: intervention.id, intervention: _.omit(intervention, 'id') }),
  interventionsDelete: (IDs) => authenticatedMutation(mutations.interventionsDelete(), {}, { IDs }),
  interventionsArchive: (IDs, isArchived) => authenticatedMutation(mutations.interventionsArchive(), {}, { IDs, isArchived }),
  produceMail: (mail) => authenticatedMutation(mutations.produceMail(mail)),
  upsertWithPDF: (surveys, loid) => authenticatedService(PUT, routes.surveywithPDF(loid), { payload: surveys }),
};
